import React, { useEffect, useState } from "react"
import axios from "axios"
import { useTranslation } from "react-i18next"
import Storage from "../../../../utils/storage"

import Loader from "../../../Loader/Loader"
import Alert from "../../../Common/Alert"

const MachineBranch = ({
  currentDepotID,
  currentBranchName,
  setNewDepot,
  setBranchIsLoading
}) => {
  const { t } = useTranslation()

  const userState = Storage.get("userState") ?? null
  const depotId = userState?.internal?.depot?.id ?? 0
  const regionId = userState?.internal?.region?.regionId ?? 3

  const [loading, setLoading] = useState(false)
  const [depots, setDepots] = useState([])
  const [error, setError] = useState(false)
  const [errorCode, setErrorCode] = useState("")
  const [errorMsg, setErrorMsg] = useState("")

  const onChangeDepot = (event) => {
    event.preventDefault()
    const id = event.target.value
    const { selectedIndex } = event.nativeEvent.target
    const name = event.nativeEvent.target[selectedIndex].text

    setNewDepot({
      id,
      name
    })
  }

  useEffect(() => {
    let unmounted = false
    setLoading(true)
    setBranchIsLoading(true)

    const body = {
      regionId: {
        logicalOperator: "And",
        predicate: "Equal",
        value: regionId ?? 3
      }
    }

    const requestDepots = async () => {
      await axios
        .post("/api/hll/internalDepots", {
          body: body
        })
        .then((response) => {
          if (!unmounted) {
            const result = response?.data?.data ? response.data.data : false
            setDepots(result)
            if (result.length === 0) {
              setErrorMsg(t("Can't find any depots. Try another region"))
            }
          }
        })
        .catch((err) => {
          //console.warn(err, "Error: api/hll/customerOrders")
          setErrorCode(err?.response?.data?.CorrelationId)
          setError(true)
        })
        .finally(() => {
          setLoading(false)
          setBranchIsLoading(false)
        })
    }

    requestDepots()

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionId, t, setBranchIsLoading])

  const internalDepots = depots ? depots : null

  return (
    <>
      {loading && (
        <div className="d-flex align-items-center justify-content-center my-3 flex-grow-1">
          <Loader />
        </div>
      )}
      {errorMsg && !loading && (
        <Alert className="text-center my-4" message={errorMsg} />
      )}
      {error && (
        <Alert
          className="text-center my-4"
          message={`${t(
            "Something went wrong, please refresh current page."
          )} ${t("Error code:")} ${errorCode ? errorCode : ""}`}
        />
      )}

      {!loading && !error && internalDepots.length > 0 && (
        <div className="row">
          <div className="col-12">
            <p>
              {t(
                "You are logged in to a depot other than the one where the machine is located today according to the system. Check that the depot move proposed below is correct or change to the correct depot."
              )}
            </p>
          </div>
        </div>
      )}

      {!loading && !error && internalDepots.length > 0 && (
        <div className="row mb-4">
          <div className="col-12">
            <label className="form-label field__label text-uppercase font-xs">
              {t("The individual's current depot")}
            </label>
            <input
              value={currentBranchName ? currentBranchName : currentDepotID}
              className="form-control form-control-modal rounded-0 disabled"
              disabled
              readOnly
            />
          </div>
        </div>
      )}
      {!loading && !error && internalDepots.length > 0 && (
        <div className="row">
          <div className="col-12">
            <label className="form-label field__label text-uppercase font-xs">
              {t("Move individual to depot")}
            </label>
            <select
              className="form-select form-control-modal rounded-0"
              defaultValue={depotId}
              onChange={onChangeDepot}>
              {internalDepots
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((item, i) => (
                  <option key={i} value={item?.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )}
    </>
  )
}

export default MachineBranch
