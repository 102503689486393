const sv = {
  "My pages": "Mitt HLL",
  "My HLL": "Mitt HLL",
  Hi: "Hej",
  "Email address": "E-postadress",
  Password: "Lösenord",
  Login: "Logga in",
  Reset: "Återställ",
  "Lost your password?": "Glömt lösenord?",
  "Create account": "Skapa konto",
  "Enter your company code": "Ange din företagskod",
  "I already have an account": "Jag har redan ett konto",
  "Enter the e-mail address": "Ange e-postadress",
  "Invalid email address": "Ogiltig e-postadress",
  "Enter the password": "Ange lösenord",
  "Don't have an account?": "Har du inte ett konto?",
  "Already have an account?": "Har du redan ett konto?",
  "The username or password provided were incorrect!":
    "Användarnamnet eller lösenordet är felaktigt!",
  "Enter your email and we will send you a link to reset your password shortly.":
    "Ange din e-post så skickar vi en länk för att återställa ditt lösenord inom kort.",
  "Check your email and click the link to confirm your email address to continue.":
    "Kontrollera din e-post och klicka på länken för att bekräfta din e-postadress för att fortsätta.",
  "User not found, try again.": "Användaren hittades inte, försök igen.",
  "Back to login": "Tillbaka till logga in",
  "Add widget": "Lägg till widget",
  "Log out": "Logga ut",
  Dashboard: "Start",
  Rentals: "Uthyrningar",
  Projects: "Projekt",
  Economy: "Ekonomi",
  Machines: "Maskiner",
  Depots: "Depåer",
  Depot: "Depå",
  Returns: "Returer",
  Sales: "Försäljning",
  "Sales channels": "Försäljningskanaler",
  Jan: "Jan",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Apr",
  May: "Maj",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Aug",
  Sep: "Sep",
  Oct: "Okt",
  Nov: "Nov",
  Dec: "Dec",
  Overview: "Översikt",
  Project: "Projekt",
  "Create a new project": "Skapa nytt projekt",
  Name: "Namn",
  "Quantity of machines": "Antal maskiner",
  "Created at": "Skapat",
  Status: "Status",
  "Total cost": "Total kostnad",
  Search: "Sök",
  search: "sok",
  "Number of projects": "Antal projekt",
  Previous: "Föregående",
  Next: "Nästa",
  "per page": "per sida",
  "Can't find anything": "Hittar ingenting",
  "Can't find any projects": "Hittar inga projekt",
  "Can't find any orders": "Hittar inga ordrar",
  All: "Alla",
  Rented: "Hyrda",
  Returned: "Returnerat",
  "Project overview": "Projektöversikt",
  "Article number": "Artikelnummer",
  Type: "Typ",
  "Rented at": "Uthyrt",
  "Returned at": "Returnerade",
  Amount: "Antal",
  Equipments: "Utrustning",
  Accessories: "Tillbehör",
  "Book return": "Boka retur",
  "Return information": "Returinformation",
  "Something went wrong with adding your return. Try again.":
    "Något gick fel när du lade till din retur. Försök igen.",
  "Search address": "Sök adress",
  "Send return": "Skicka retur",
  Send: "Skicka",
  Close: "Stäng",
  Cancel: "Avbryt",
  Continue: "Fortsätt",
  "What day should we pick up?": "Vilken dag ska vi hämta?",
  "Where should it be picked up?": "Vart ska det hämtas?",
  "Indicate which day and between which times we can come and pick up your return.":
    "Ange vilken dag och mellan vilka tider vi kan komma och hämta din retur.",
  "The following items will be returned.":
    "Följande artiklar kommer att returneras.",
  "Thanks! We have received your return. Check your email for more information.":
    "Tack! Vi har mottagit din retur. Kontrollera din e-post för mer information.",
  "Show on map": "Visa på karta",
  "Choose your depot": "Välj din depå",
  "Our depots": "Våra depåer",
  "Remove depot": "Ta bort depå",
  "Choose new depot": "Välj ny depå",
  "My depot": "Min depå",
  "Latest project": "Senaste projekt",
  "View all projects": "Visa alla projekt",
  "Amount of machines": "Antal maskiner",
  Date: "Datum",
  From: "Från",
  To: "Till",
  Phone: "Telefon",
  "Enter your phone number": "Ange telefonnummer",
  "There is no user record corresponding to this identifier. The user may have been deleted.":
    "Det finns ingen användare med dessa uppfifter. Användaren kan ha tagits bort.",
  "The password is invalid or the user does not have a password.":
    "Lösenordet är ogiltigt eller användaren har inget lösenord.",
  "Machines/accessories": "Maskiner/tillbehör",
  "Check the machines/accessories in the list that you want to return":
    "Klicka för de maskiner/tillbehör i listan som du vill returnera",
  "Enter your email": "Ange din e-post",
  "First- and lastname": "För- och efternamn",
  "Choose a password": "Välj lösenord",
  "By tapping Continue, you are indicating that you agree to the":
    "Genom att trycka på Fortsätt godkänner du våra",
  "Terms of use": "Användarvillkor",
  "Company code": "Företagskod",
  "Invalid company code": "Ogitlig företagskod",
  "Create account and login": "Skapa konto och logga in",
  "Unfortunately, you are not affiliated with a company.":
    "Du är tyvärr inte kopplad till ett företag.",
  "The email address is already in use by another account.":
    "E-postadressen används redan av ett annat konto.",
  "Please contact HLL to get a company code.":
    "Vänligen kontakta HLL för att få en företagskod.",
  or: "eller",
  "Create new account": "Skapa nytt konto",
  "Give feedback": "Lämna feedback",
  Summary: "Sammanfattning",
  "Further details": "Ytterligare detaljer",
  "Thanks! We have received your feedback.":
    "Tack! Vi har mottagit din feedback.",
  "404 - Page not found": "404 - Hittar inte sidan",
  "Profile picture": "Profilbild",
  "My profile": "Min profil",
  "User managment": "Användarhantering",
  "Request failed with status code 404":
    "Begäran misslyckades med statuskod: 404",
  "Opening hours": "Öppettider",
  dashboard: "oversikt",
  projects: "projekt",
  returns: "returer",
  depots: "depaer",
  "No search done": "Ingen sökning gjord",
  "Choose company and depot": "Välj bolag & depå",
  "Switch company and depot": "Byt bolag & depå",
  "Choose region and depot": "Välj region & depå",
  "Switch region and depot": "Byt region & depå",
  Companies: "Bolag",
  Choose: "Välj",
  Save: "Spara",
  unnamed: "ej-namngiven",
  "Something went wrong, please refresh current page.":
    "Något gick fel, prova att ladda om sidan.",
  "Cash customer": "Kontantkund",
  "The client is a cash customer and any returns needs to be handled with a computer.":
    "Kunden är kontantkund och retur måste genomföras vid dator.",
  Back: "Tillbaka",
  "Click to reload!": "Klicka för att ladda om",
  "Session expire": "Sessionen har löpt ut",
  "Error code": "Felkod",
  customerType: "kundTyp",
  "Direct payment": "Kontantkund",
  "Can't find any depots. Try another company":
    "Hittar inga depåer. Försök med ett annat bolag",
  "Can't find any depots. Try another region":
    "Hittar inga depåer. Försök med en annan region",
  Return: "Retur",
  Page: "Sida",
  of: "av",
  "Return management": "Returhantering",
  "Search for fleet model id, order number or client name":
    "Sök på individnr, ordernr eller kundnamn",
  "Amount to return": "Antal i retur",
  Individual: "Individ",
  Individuals: "Individer",
  Machine: "Maskin",
  Article: "Artikel",
  "Article(s)": "Artikel(ar)",
  Leased: "Uthyrt",
  "Send back": "Returnera",
  "Add return": "Lägg till retur",
  "Change return": "Ändra retur",
  "No accessories found": "Du har inte lagt till några tillbehör ännu.",
  "No accessories or services found": "Inga tillbehör eller tjänster hittades.",
  "No addons found": "Inga tillägg hittades.",
  Note: "Notering",
  "Nr of business days": "Antal arbetsdagar (mån-fre)",
  "Nr of weekdays": "Antal veckodagar (mån-sön)",
  Purchase: "Köp",
  "Consumed amount": "Förbrukning",
  Included: "Inkluderat",
  Unknown: "Okänd",
  Addons: "Tillägg",
  "Regret return": "Ångra retur",
  Rent: "Hyra",
  "Rent (Mon-Fri)": "Hyra (Mån-Fre)",
  "Rent (Mon-Thu)": "Hyra (Mån-Tor)",
  "Rent (Mon-Sun)": "Hyra (Mån-Sön)",
  "Partial purchase": "Delköp",
  "Monthly rent": "Månadshyra",
  "Delivery note text": "Följesedelstext",
  "Create print": "Skapa utskrift",
  "Remove addon": "Ta bort tillägg",
  "Fetching addons...": "Hämtar tillägg...",
  summary: "summering",
  "Returned articles": "Returnerade artiklar",
  Remove: "Ta bort",
  "Sending...": "Skickar...",
  Update: "Uppdatera",
  "This order is locked or used by someone else.":
    "Denna order är låst eller används av någon annan.",
  "Please contact HLL for more information.":
    "Kontakta HLL för mer information.",
  "Submit return": "Skicka in retur",
  Submit: "Skicka in",
  "The return has been completed": "Returen har genomförts",
  "The return is complete with number": "Returen är färdig med nummer",
  confirmation: "bekraftelse",
  fail: "fel",
  "Return a new order": "Returnera en ny order",
  "Add Article/Fleet Model": "Lägg till artikel/individ",
  "Search for Article or Fleet Model": "Sök efter artikel eller individ",
  "Select addons": "Välj tillbehör till",
  "Insurance case": "Försäkringsfall",
  "This order is an insurance case": "Den här ordern är ett försäkringsfall",
  "Returned individuals/articles": "Returnerade individer/artiklar",
  "Return failed": "Returen misslyckades",
  "The following error(s) occurred": "Följande fel inträffade",
  "No articles or fleet models found": "Inga artiklar eller individer hittades",
  "New order": "Ny order",
  "Agreed price": "ÖK Pris",
  "Enter discount in percentage, e.g. -25, or actual price in SEK":
    "Ange rabatt i procent, t.ex. -25, eller pris i kr",
  "Return status": "Status på din retur",
  "Your order status": "Status på din order",
  "The order is complete with number": "Din order är skapad med nummer",
  "Credit limit": "Kreditlimit",
  "Something went wrong": "Något gick fel",
  "The customer lacks sufficient credit limit":
    "Kunden saknar tillräckligt kreditutrymme",
  Loading: "Laddar",
  Debiting: "Debitering",
  Price: "Pris",
  "You are not authenticated": "Du är inte autentiserad",
  "Any addons are on the same order number.":
    "Eventuella tillägg ligger på samma ordernummer.",
  "Close and edit your order": "Stäng och redigera din retur",
  "Something went wrong, try again.": "Något gick fel, försök igen.",
  "Change return date for all orders": "Ändra returdatum för alla orderrader",
  "Please note that any changed dates will be overwritten":
    "OBS! Redan ändrade datum skrivs över.",
  "Identification requirement": "Krav på legitimation vid hyra?",
  "Require ID for rentals and returns": "Kräv legitimation vid hyra och retur",
  Permissions: "Hantering av behörighet",
  "Only admin is allowed to add persons":
    "Tillåt endast admin att hantera behörighet?",
  "Job title": "Befattning",
  "Date of birth": "Födelsedatum",
  "User account": "Användarkonto",
  Email: "E-post",
  Invite: "Bjud in",
  "Allowed to rent": "Behöriga att hyra",
  "Add person": "Lägg till person",
  "Something went wrong with addons!":
    "Det gick inte att lägga på de nya orderraderna!",
  "The customer is blocked. The customer is not allowed to place orders":
    "Kunden är spärrad. Kunden tillåts inte lägga order",
  Edit: "Redigera",
  "Delete contact": "Radera kontakt",
  "Invite as user of My Pages": "Lägg till som användare i Mitt HLL",
  "Do you really want to delete the contact":
    "Vill du verkligen ta bort kontakten",
  "This action can't be undone.": "OBS! Denna åtgärd går inte att ångra.",
  "First name": "Förnamn",
  "Last name": "Efternamn",
  Title: "Befattning",
  "Date of Birth": "Födelsedatum",
  "Phone 1": "Telefon 1",
  "Phone 2": "Telefon 2",
  "Phone 3": "Telefon 3",
  Base: "Standard",
  "Client Admin": "Kundadmin",
  "Edit contact": "Redigera person",
  "Add contact": "Lägg till person",
  Confirm: "Bekräfta",
  "First name is required": "Förnamn är obligatoriskt",
  "Last name is required": "Efternamn är obligatoriskt",
  "Date of Birth is required": "Födelsedatum är obligatoriskt",
  "Email is required": "E-postadress är obligatoriskt",
  "Phone 1 is required": "Telefon 1 är obligatoriskt",
  "The contact has been saved!": "Kontaktperson sparad!",
  "Rental Permissions": "Behöriga att hyra",
  "rental-permissions": "behoriga-att-hyra",
  Users: "Användare",
  users: "anvandare",
  Competence: "Behörighet",
  "Do you want to require identification for rentals and returns?":
    "Vill du kräva legitimation vid hyra och retur?",
  "Do you want to remove the identification for rentals and returns requirement?":
    "Vill du ta bort krav på legitimation vid hyra och retur?",
  "This option only allows the admin of your company to assign or remove people from permission to rent. HLL cannot make any changes to the list.":
    "Detta val tillåter endast admin på ditt företag att tilldela eller ta ifrån personer behörighet att hyra. HLL kan inte göra några ändringar i listan.",
  "In addition to the customer admin of your company, this choice also allows HLL to assign and remove people from permisson to rent.":
    "Detta val tillåter utöver kundadmin på ditt företag även HLL att tilldela och ta ifrån personer behörighet att hyra.",
  "Add user": "Lägg till användare",
  "Invite user": "Bjud in användare",
  "The user has been saved!": "Användare sparad!",
  Information: "Information",
  "Extra return copy": "Extra returkopia",
  "Extra return copies": "Extra returkopior",
  "Customer admin": "Kundadmin",
  "Send return copy": "Skicka returkopia",
  "Send return copies": "Skicka returkopior",
  "Return copies are submitted": "Returkopior är inskickade",
  "Something went wrong, the user may already exist!?":
    "Något gick fel, användaren kanske redan finns? Testa igen.",
  Active: "Aktiva",
  Ongoing: "Pågående",
  "YYYY-MM-DD": "ÅÅÅÅ-MM-DD",
  "You must fill in your complete social security number":
    "Du måste fylla i fullständigt födelsedatum",
  "You have identification requirements and therefore HLL needs to have a date of birth to check at the time of rental.":
    "Vid hyra verifieras alltid behörighet genom legitimationskontroll.",
  "Delete user": "Radera användare",
  "Do you really want to delete the user":
    "Vill du verkligen ta bort användaren",
  "This option only allows the customer admin of your company to assign or remove people from permission to rent. HLL cannot make any changes to the list.":
    "Detta tillåter endast kundadmin på ditt företag att hantera vem som kan hyra. HLL kan då inte längre göra ändringar.",
  machines: "maskiner",
  Updated: "Uppdaterad",
  Availability: "Tillgänglighet",
  "Can't find any machines": "Hittar inga maskiner",
  "Search by individual number": "Sök på individnummer",
  "The individual needs to be returned in order to change availability!":
    "Individen behöver returneras för att kunna ändra tillgänglighet!",
  "Edit machine": "Ändra maskin",
  "Individual number": "Individnummer",
  "Group description": "Gruppbeskrivning",
  Available: "Tillgänglig",
  "On control": "Kontrolleras",
  "Not Available": "Ej tillgänglig",
  "This action can't be undone. Nothing going to be saved!":
    "OBS! Denna åtgärd går inte att ångra. Ingen data kommer sparas!",
  "Control note": "Kontrollnotering",
  "Create new note": "Skapa ny notering",
  "Create new control note": "Skapa ny kontrollnotering",
  "Save note": "Spara notering",
  "The note has been saved!": "Kontrollnoteringen har sparats!",
  "Replacement of depot": "Byte av depå",
  "Inspection is approaching": "Besiktning närmar sig",
  "The individual's current depot": "Individens nuvarande depå",
  "Move individual to depot": "Flytta individ till depå",
  "You are logged in to a depot other than the one where the machine is located today according to the system. Check that the depot move proposed below is correct or change to the correct depot.":
    "Du är inloggad på annan depå än den där maskinen är placerad på idag enligt systemet. Kontrollera att nedan föreslagna depåflytt är korrekt eller ändra till rätt depå.",
  "The machine will be inspected soon, are you sure it will be made available for rent?":
    "Maskinen ska besiktas snart, är du säker på att den ska göras tillgänglig för uthyrning?",
  "A note is made in the system that the machine is made available if you continue.":
    "En notering görs i systemet att maskinen görs tillgänglig om du fortsätter.",
  "latest on": "senast den",
  Inspection: "Kontrollbesiktning",
  "Yearly inspection": "Årligt återkommande",
  "Fire inspection": "Brandskydd/Revision",
  "Exhaust test": "Avgastest",
  "Depot change": "Byte av depå",
  "Change implemented": "Ändring genomförd",
  "Search new machine": "Sök ny maskin",
  Message: "Meddelande",
  "Back to the machine": "Tillbaka till maskinen",
  "Something went wrong when saving the control note, please try again or leave empty to proceed.":
    "Något gick fel när kontrollnoteringen skulle sparas. Försök igen eller lämna tomt för att fortsätta.",
  "Fill in check note before saving (optional).":
    "Fyll i kontrollnotering innan du sparar (valfritt).",
  "Persons authorized to rent from Hyreslandslaget":
    "Personer med behörighet att hyra hos Hyreslandslaget",
  "Assign permission to rent": "Tilldela behörighet att hyra",
  "When renting, authorization is always verified through identification check.":
    "Vid hyra verifieras alltid behörighet genom legitimationskontroll.",
  "Remove identification requirements when renting?":
    "Ta bort krav på legitimation vid hyra?",
  "HLL checks that the person's name is included in Permissions to rent, but does not check identification.":
    "HLL kontrollerar att personens namn finns med i listan Behöriga att hyra, men gör ingen legitimationskontroll.",
  "machine-transfer": "maskinflytt",
  "Machine transfer": "Maskinflytt",
  "incoming-machines": "inkommande-maskiner",
  "Incoming machines": "Inkommande maskiner",
  "Moves to": "Flyttas till",
  "Move machines/articles": "Flytta maskiner/artiklar",
  "Add Machine/article": "Lägg till maskin/artikel",
  "Move all to the following depot": "Flytta alla till följande depå",
  "You haven't added any machines/articles to transfer yet.":
    "Du har inte lagt till några maskiner/artiklar som ska flyttas ännu.",
  "No machines/articles found": "Inga maskiner/artiklar hittades",
  "The individual/article is already at this depot, choose another depot to start moving depots.":
    "Individen/artikeln finns redan på den här depån, välj annan depå för att påbörja depåflytt.",
  "has now been moved to": "är nu flyttad till",
  "Something went wrong when moving to a new depot, please try again.":
    "Något gick fel vid flytt till ny depå, vänligen försök igen.",
  "NB! The machine/article is not available for transfer right now":
    "OBS! Maskinen/artikeln är ej tillgänglig och kan därför inte flyttas",
  "No depot selected": "Ingen depå vald",
  "Select depot": "Välj depå",
  "Please confirm the following transfers of machines":
    "Bekräfta att du vill flytta följande maskiner",
  Removed: "Borttagen",
  Added: "Tillagd",
  "Depot changed to": "Depå ändrad till",
  "Transfer to": "Flyttas till",
  "Your transfer has been started": "Din flytt är påbörjad",
  "Transfer note is being printed": "Flyttlapp skrivs nu ut.",
  "Current depot": "Aktuell depå",
  "No incoming machines found.": "Inga inkommande maskiner hittades.",
  by: "av",
  "NB! Unable to add machine/article":
    "OBS! Maskin går ej att lägga till just nu",
  "Preview machines": "Förhandsgranska maskiner",
  "Machines acknowledged": "Maskiner kvitterade",
  Preview: "Förhandsgranska",
  "Can't find any opening hours for selected depot.":
    "Hittar inga öppettider för vald depå.",
  "No contact persons with permissions found":
    "Inga personer med behörighet att hyra hittades",
  "company-profile": "foretagsprofil",
  billing: "fakturering",
  "billing-method": "faktureringsmetod",
  "billing-address": "fakturaadress",
  "Change customer account": "Byt kundkonto",
  "We see that you have several accounts with HLL, which account do you want to log in to into?":
    "Vi ser att du har flera konton hos HLL, vilket konto vill du logga in på?",
  "You have no permission": "Du har ingen behörighet",
  Logout: "Logga ut",
  "Please contact an administrator.": "Vänligen kontakta en administratör.",
  "Company Profile": "Företagsprofil",
  "Company name": "Företagsnamn",
  "Corporate identity number": "Organisationsnummer",
  "Client number": "Kundnummer",
  "Mobile phone number": "Mobilnummer",
  "Required field": "Obligatoriskt fält",
  "Company information": "Företagsuppgifter",
  Billing: "Fakturering",
  "Zip code": "Postnr",
  "Billing address": "Fakturaadress",
  "Billing method": "Faktureringsmetod",
  "Address 1": "Adress 1",
  "Address 2": "Adress 2",
  "Address 3": "Adress 3",
  City: "Stad",
  Country: "Land",
  "Type of invoicing": "Typ av fakturering",
  "Name/Company": "Namn/Företag",
  "If you want to update any information, get in touch with us at":
    "Om du vill uppdatera några uppgifter så hör av dig till oss på",
  "Transfer note nr": "Flyttlappsnummer",
  "NB! The machine/artikeln is locked and being transfered by another user right now":
    "OBS! Maskinen/artikeln är låst och går ej att lägga till just nu",
  "Customer account": "Kontonamn",
  "Billing frequency": "Faktureringsfrekvens",
  "Three times per month": "3 ggr/månad",
  "Two times per month": "2 ggr/månad",
  "One time per month": "1 ggr/månad",
  "One time middle of month": "1 ggr/månad",
  "Postal address": "Postadress",
  "AdressLine 1": "Adressrad 1",
  "AdressLine 2": "Adressrad 2",
  "AdressLine 3": "Adressrad 3",
  "Search machine": "Sök efter maskin",
  "New project": "Nytt projekt",
  create: "skapa",
  edit: "redigera",
  "General information": "Allmän information",
  "Create new project": "Skapa nytt projekt",
  "Name of the project": "Namn på projektet",
  "Project number": "Projektnummer",
  Address: "Adress",
  "Search for your address": "Sök efter din adress",
  "Delete project": "Ta bort projekt",
  "Do you really want to delete the project":
    "Vill du verkligen ta bort projektet",
  "Sorry, this project can not be deleted as there are already ongoing or previous order on the project":
    "Tyvärr, detta projekt går inte att ta bort då det redan finns pågående eller tidigare order på projektet",
  to: "till",
  overview: "översikt",
  "Sorry, this project cannot be edited as there are already ongoing or previous order on the project":
    "Tyvärr, detta projekt går inte att redigera då det redan finns pågående eller tidigare order på projektet",
  "Edit project": "Redigera projekt",
  "Addressline 1": "Adressrad 1",
  "Addressline 2": "Adressrad 2",
  "Addressline 3": "Adressrad 3",
  project: "projekt",
  "Search after incoming machine": "Sök efter inkommande maskin",
  "The project is deleted": "Projektet är nu borttaget",
  "The project is created": "Projektet är nu skapat",
  "The project is updated": "Projektet är nu uppdaterat",
  "Acknowledge machines": "Kvittera maskinerna",
  "You must enter a name or a number":
    "Du måste skriva ett namn eller ett nummer",
  "Your company profile is updated": "Din företagsprofil är nu uppdaterad",
  "Block project": "Spärra projekt",
  "Unblock project": "Avspärra projekt",
  "The project is blocked": "Projektet är nu spärrat",
  "The project is unblocked": "Projektet är nu avspärrat",
  "Remember my choice": "Kom ihåg mitt val av konto",
  "Something went wrong when trying to fetch tenants":
    "Något gick fel vid inhämtning av underavdelningar",
  "Do you want to block this project for new orders? It is always possible to remove the barrier in the future":
    "Vill du spärra det här projektet för nya ordrar? Det går alltid att ta bort spärren i framtiden",
  "Do you want to block this project for new orders? It is always possible to add the lock in the future":
    "Vill du avspärra det här projektet för nya ordrar? Det går alltid att lägg till spärren i framtiden",
  "Create new order": "Skapa ny order",
  Client: "Kund",
  "Choose client": "Välj kund",
  "Choose project": "Välj projekt",
  "Search and select customer": "Sök och välj kund",
  Reference: "Referens",
  "Choose reference": "Välj referens",
  "Search order": "Sök order",
  New: "Nya",
  Existing: "Befintliga",
  "The client is a cash customer and any orders needs to be handled with a computer.":
    "Kunden är kontantkund och ordrar måste genomföras vid dator.",
  "Search Machine/article": "Sök maskin/artikel",
  "Name of article": "Benämning av artikel",
  "Add to order": "Lägg till på order",
  "No reference found": "Ingen referens hittades",
  "Order rows created": "Orderrader skapade",
  "Send extra order copy": "Skicka extra orderkopia",
  "Social security number": "Personnummer",
  "Change reference": "Byt referens",
  "Change date for all machines/articles":
    "Ändra datum på alla maskiner/artiklar",
  "Create new reference": "Skapa ny referens",
  "Order Number": "Ordernummer",
  "Letter (A-Ö)": "Bokstav (A-Ö)",
  "Trpt/customer known": "Trpt/kund känd",
  "Existing order rows": "Befintliga orderrader",
  "New order rows": "Nya orderrader",
  "Order information": "Orderinformation",
  "Send to selected addresses": "Skicka till valda adresser",
  "Order copies are submitted!": "Orderkopior har nu skickats!",
  "Add row": "Lägg till ny rad",
  "An error occurred": "Ett fel uppstod",
  "Order info": "Orderinfo.",
  "Control of machine": "Kontroll av individ",
  "This machine need to be checked according to the system":
    "Denna maskin skall enligt systemet kontrolleras!",
  "Save and continue": "Spara och fortsätt",
  "The reference has been created!": "Referensen har skapats!",
  "Unfortunately, it was not possible to add new order lines":
    "Något gick fel! Tyvärr gick det inte lägga på nya orderrader",
  "Pickup by": "Upphämtare",
  "Choose insurance option": "Välj försäkring",
  "No insurance": "Ingen försäkring",
  Insurance: "Försäkring",
  "document-recipients": "dokument-mottagare",
  "The orders machines and addons": "Orderns maskiner och tillbehör",
  "Document recipients": "Dokumentmottagare",
  "Add a recipient": "Lägg till mottagare",
  "Mailing settings for outstanding rents":
    "Utskicksinställningar för utestående hyror",
  "Day of mailing": "Dag för utskick",
  "Frequency of mailing": "Frekvens på utskick",
  "Report settings": "Rapportinställningar",
  Monday: "Måndag",
  Tuesday: "Tisdag",
  Wednesday: "Onsdag",
  Thursday: "Torsdag",
  Friday: "Fredag",
  Saturday: "Lördag",
  Sunday: "Söndag",
  "Every week": "Varje vecka",
  "Even week": "Jämn vecka",
  "Odd week": "Udda vecka",
  "The last of the month": "Den sista i månaden",
  "All projects in the same mailing": "Alla projekt i samma utskick",
  Receiver: "Mottagare",
  "Document type": "Dokumenttyp",
  "Delete recipient": "Ta bort mottagare",
  "Outstanding rents": "Utstående hyror",
  "Return slip": "Retursedel",
  "Delivery note": "Följesedel",
  "Update recipient": "Uppdatera mottagare",
  "Add recipient": "Lägg till mottagare",
  "Document types": "Dokumenttyper",
  "Marked as client recipient": "Satt som kundmottagare",
  "Document recipients updated": "Dokumentmottagare uppdaterade",
  "Recipient is already registered with this e-mail address":
    "Det finns redan en mottagare med denna e-postadress.",
  "/company-profile/document-recipients": "/foretagsprofil/dokument-mottagare",
  "Edit client recipients": "Redigera kundmottagare",
  "Edit document recipients": "Redigera dokumentmottagare",
  "If you want to edit persons that are document recipients on a client level, go to your company profile.":
    "För att redigare vilka personer som är dokumentmottagare på kundnivån går du till din företagsprofil.",
  "The price cannot be SEK 0": "Priset kan inte vara 0 kr",
  "Customer has blocked the ability to add new contacts":
    "Kund har spärrat möjlighet att lägga till nya kontakter",
  "Error occurred": "Fel uppstod",
  "Delete document recipient?": "Bekfräfta borttagning",
  "Please confirm the deletion of document recipient:":
    "Vänligen bekfräfta borttagning av mottagaren:",
  "This project is blocked for new orders":
    "Det här projektet är spärrat för nya ordrar",
  "No access": "Ingen åtkomst",
  "You don't have the correct permissions for this page":
    "Ditt användarkonto har inte rätt behörigheter för att komma åt den här sidan.",
  "Back to home": "Tillbaka till startsidan",
  "Change billing method": "Ändra faktureringsmetod",
  "Change billing address": "Ändra fakturaadress",
  "Confirm email address": "Bekräfta e-postadress",
  "You are using Svefaktura today and we advise you to switch to Peppol.":
    "Du kör idag Svefaktura och vi vill gärna tipsa att byta till Peppol.",
  "The email addresses do not match.": "E-postadresserna stämmer inte överens.",
  "Billing method is updated": "Faktureringsmetod uppdaterad",
  "You must specify at least one permission.":
    "Du måste ange minst en behörighet.",
  "Billing address is updated": "Fakturaadress uppdaterad",
  "Update available": "Uppdatering tillgänglig",
  "There is a new version of Mitt HLL": "Det finns en ny version av Mitt HLL",
  "Reload now": "Ladda om nu",
  "If the company is connected to the peppol register, you will automatically receive the invoices from HLL in peppol format.":
    "Är företaget anslutet i peppolregistret så kommer ni automatiskt att få fakturorna från HLL i peppolformat.",
  "If the company is connected to the peppol register, you will receive the invoices from HLL in peppol format.":
    "Är företaget anslutet i peppolregistret så kommer ni att få fakturorna från HLL i peppolformat.",
  "If you want to switch to another invoice format, contact info@hllab.se":
    "Vill ni byta till ett annat fakturaformat kontaktar du info@hllab.se",
  "If you want to switch to another invoice format, contact info@hllab.se. If the company is connected to the peppol register, you will automatically receive the invoices from HLL in peppol format.":
    "Vill ni byta till ett annat fakturaformat kontaktar du info@hllab.se. Är företaget anslutet i peppolregistret kommer ni automatiskt att få fakturorna från HLL i peppolformat.",
  "User invited": "Användare inbjuden",
  "C/o": "C/o",
  "Select as favorite project": "Välj som favoritprojekt",
  "Remove as favorite project": "Ta bort som favoritprojekt",
  Favorites: "Favoriter",
  "Favorite projects": "Favoritprojekt",
  "The project is now removed from favorites":
    "Projektet är nu borttaget från favoriter",
  "The project is now added as a favorite":
    "Projektet är nu tillagd som favorit",
  "Remind me to return this machine":
    "Påminn mig om att lämna tillbaka den här maskinen",
  Time: "Tid",
  Reminders: "Påminnelser",
  "Reminder deleted": "Påminnelse raderad",
  "Reminder is updated": "Påminnelse är uppdaterad",
  "Reminder is added": "Påminnelse är tillagd",
  "Planned end date": "Planerat slutdatum",
  "Set a planned end date": "Sätt ett planerat slutdatum",
  "My planned end dates": "Mina planerade slutdatum",
  "End date has passed": "Slutdatum har passerat",
  "View my favorite projects": "Visa mina favoritprojekt",
  "Load more": "Ladda fler",
  "Transported to": "Transporteras till",
  "Show more information about the Article/Fleet Model":
    "Visa mer information om artikel/individ",
  "End date is required": "Sludatum är obligatoriskt",
  "End date": "Slutdatum",
  "Last date the user account is active":
    "Sista datum som användarkontot är aktivt",
  "Last date the permission is active": "Sista datum som behörigheten är aktiv",
  "The user is no longer authorized to rent":
    "Användaren är ej längre behörig att hyra",
  Yes: "Ja",
  No: "Nej",
  "Expiry date": "Sista giltighetsdag",
  "We have found a user account linked to this email address, do you also want to set an expiration date on the user":
    "Vi har hittat ett användarkonto kopplat till den här mejladressen, vill du även sätta sista giltighetsdag på användaren",
  "The contact and the user has been saved!":
    "Kontaktperson och användare är nu sparad!",
  "We have found a person who is authorized to rent associated with this email address, would you also like to enter an expiration date on that person":
    "Vi har hittat en person som är behörig att hyra kopplat till denna e-postadress, vill du också ange ett utgångsdatum på den personen",
  "Inactive account": "Inaktivt konto",
  Inventory: "Inventering",
  inventory: "inventering",
  "Being inventoried right now": "Inventeras just nu",
  "Help to find": "Hjälp till att hitta",
  "Help find individuals": "Hjälp till att hitta individer",
  Start: "Starta",
  "Together we will fix it": "Tillsammans så fixar vi det",
  "Are you at the right depot?": "Är du på rätt depå?",
  "Yes! Here we go": "Ja! Nu kör vi",
  find: "hitta",
  Find: "Hitta",
  Create: "Skapa",
  "Find machines": "Hitta individer",
  "Right now there are no active inventories":
    "Just nu finns det inget aktiva inventeringar",
  "Right now there are no ongoing inventories":
    "Just nu finns det inget pågående inventeringar",
  "Right now there are no missing machines":
    "Just nu finns det inga saknade individer",
  Add: "Lägg till",
  "Can't find anything. Try again": "Hittar inget. Testa igen",
  "Search the list": "Sök i listan",
  Filter: "Filtrera",
  "Show machines": "Visa maskiner",
  "Yay! Good work!": "Yay! Bra jobbat!",
  "All machines are found": "Alla maskiner är hittade",
  "Have you found?": "Har du hittat?",
  "Plan machines you are going to book a return on, machines with planned end dates are visible on the start page.":
    "Planera maskiner du ska boka retur på, maskiner med planerade slutdatum syns på startsidan.",
  "Book the return of the machine directly here in Mitt HLL or contact your nearest depot.":
    "Boka retur på maskinen direkt här i Mitt HLL eller kontakta din närmaste depå.",
  "Create new inventory": "Skapa ny inventering",
  "Name of inventory": "Namn på inventering",
  "Start time": "Starttid",
  "Create inventory": "Skapa inventering",
  "Please enter the name of the inventory to complete":
    "Vänligen ange namnet på inventeringen för att slutföra",
  "You must enter a name": "Du måste ange ett namn",
  "You must enter a date": "Du måste ange ett datum",
  "Regions and depots": "Regioner och depåer",
  "Add all": "Lägg till alla",
  "Add selection": "Lägg till urval",
  Planned: "Planerad",
  Searching: "Efterarbete",
  Completed: "Slutförda",
  "Select regions or depots": "Välj regioner eller depåer",
  "You must specify at least one depot.": "Du måste ange minst en depå.",
  "Mark all": "Markera alla",
  "Uncheck all": "Avmarkera alla",
  "Depots updated successfully": "Depåer uppdaterade",
  "Depots could not be updated. Try again.":
    "Depåer kunde inte uppdateras. Försök igen.",
  "Save and add": "Spara och lägg till",
  "Inventory updated successfully": "Inventeringen uppdaterad",
  "Inventory could not be updated. Try again.":
    "Inventeringen kunde inte uppdateras. Försök igen.",
  "Inventory created successfully": "Inventeringen skapad",
  "Inventory could not be created. Try again.":
    "Inventeringen kunde inte skapas. Försök igen.",
  "Start date": "Startdatum",
  Delete: "Radera",
  "Inventory deleted successfully": "Inventeringen raderad",
  "Inventory could not be deleted. Try again.":
    "Inventeringen kunde inte raderas. Försök igen.",
  "Start the control phase": "Starta kontrollfasen",
  "Inventory updated successfully, and starting the control phase.":
    "Inventeringen har uppdaterats och kontrollfasen påbörjas.",
  "Delete a scheduled inventory": "Radera en planerad inventering",
  "Complete the control phase": "Slutför kontrollfasen",
  "Inventory updated successfully, and complete the control phase.":
    "Inventeringen har uppdaterats och kontrollfasen slutförs.",
  "Following information will be created":
    "Följande information kommer att skapas",
  "You create an inventory with the below information and selection":
    "Du skapar en inventering med nedan information och urval",
  "Create a new inventory": "Skapa en ny inventering",
  "Yes! Create new inventory": "Ja! Skapa ny inventering",
  "Delete inventory": "Radera inventering",
  "Warning: This action is not reversible. Please be certain.":
    "Varning: Denna åtgärd går inte att ångra. Är du säker på att du vill fortsätta?",
  "Are you absolutely sure you want to save?":
    "Är du helt säker på att du vill spara?",
  "I confirm the start of the inventory according to the chosen time and selection.":
    "Jag bekräftar start av inventering enligt vald tid och urval.",
  "Enter the inventory name": "Ange namnet på inventeringen",
  "to continue": "för att fortsätta",
  "The machine is now registered": "Maskinen är nu registrerad",
  "Unfortunately, the machine could not be registered, please try again.":
    "Tyvärr kunde inte maskinen registreras, försök igen.",
  in: "i",
  registration: "registrering",
  "Save and transfer": "Spara och flytta",
  "The machine has now been transferred": "Maskinen har nu flyttats",
  "The machine will be moved to": "Maskinen kommer att flyttas till",
  "Can't find any machines in your selection":
    "Hittar inga maskiner i ditt urval",
  Missed: "Saknade",
  Found: "Hittade",
  Inventories: "Inventeringar",
  "No name": "Inget namn",
  "No number": "Inget nummer",
  "Can't find a machine with this number. Try again.":
    "Hittar ingen individ med detta nummer. Försök igen.",
  "Fill in the individual number": "Fyll i individnummer",
  "Go to list of missing machines": "Gå till lista över saknade individer",
  "You can change or delete a scheduled inventory up to 30 minutes before the start time.":
    "Du kan ändra eller radera en planerad inventering fram till 30 minuter innan starttiden.",
  "Edit inventory": "Redigera inventering",
  "You edit an inventory with the below information and selection":
    "Du redigerar en inventering med nedan information och urval",
  "Back to inventory administration":
    "Tillbaka till inventeringsadministration",
  "Back to inventory": "Tillbaka till inventering",
  "The machine is inventoried to": "Individen inventeras till",
  "Retrieves your list of inventoried individuals":
    "Hämtar din lista över inventerade individer",
  "Retrieves list of missing individuals":
    "Hämtar lista över saknade individer",
  "During transport": "Under transport",
  "Submit inventory": "Inventera",
  "During transport to": "Under transport till",
  Transported: "Transporteras",
  "Submit inventory and transfer": "Inventera och flytta",
  "Unfortunately, this machine cannot be inventoried.":
    "Tyvärr kan inte denna maskin inventeras.",
  "You have not yet registered any machines":
    "Du har inte registrerat några maskiner ännu",
  "Select date": "Välj datum",
  "Enter a name": "Ange ett namn",
  Sorting: "Sortering",
  "Name A-Ö": "Namn A-Ö",
  "Name Ö-A": "Namn Ö-A",
  "Number (Ascending)": "Nummer (Stigande)",
  "Number (Descending)": "Nummer (Fallande)",
  "Total inventory": "Totalinventering",
  on: "på",
  "all depots": "alla depåer",
  "Retrieving inventories": "Hämtar inventeringar",
  "Fetching registrations": "Hämtar registreringar",
  "An error occurred and you are not authenticated":
    "Ett fel uppstod och du är inte autentiserad",
  "An error occurred! Click to reload":
    "Ett fel uppstod! Klicka för att ladda om",
  Regions: "Regioner",
  "Max 20 characters": "Max 20 tecken",
  "Max 40 characters": "Max 40 tecken",
  "Search for machine": "Sök efter maskin",
  "Search result": "Sökresultat",
  "No search result": "Inget sökresultat",
  customer: "kund",
  staff: "personal",
  "View machines": "Visa maskiner",
  machine: "maskin",
  group: "grupp",
  "Showing model": "Visar modell",
  pc: "st",
  Model: "Modell",
  "This is a dev enviroment": "Detta är en utvecklingsmiljö",
  "All prices are contractual prices that may differ from the final invoice.":
    "Alla priser är avtalspriser som kan skilja sig mot slutfakturan.",
  "All prices are your contract prices, excluding VAT, excluding insurance and may differ from the final invoice.":
    "Alla priser är era avtalspriser, exkl moms, exkl försäkring och kan skilja sig mot slutfakturan.",
  "Fleetitem information": "Individinformation",
  "Rented by": "Hyrd av",
  "Purchased by": "Köpt av",
  "Technical information": "Teknisk information",
  Document: "Dokument",
  Movies: "Filmer",
  "No specifications available": "Inga specifikationer tillgängliga",
  "No documents available": "Inga dokument tillgängliga",
  "No movies available": "Inga filmer tillgängliga",
  model: "modell",
  Show: "Visa",
  "The following accessories can be added when you rent and pick up your machine. Some are recommended and added automatically when you rent.":
    "Följande tillbehör finns att addera när du hyr och hämtar upp din maskin. Några är rekommenderade och läggs till automatiskt när du hyr.",
  Recommended: "Rekommenderat",
  Models: "Modeller",
  "Show model": "Visa modell",
  Categories: "Kategorier",
  Settings: "Inställningar",
  Shortcuts: "Genvägar",
  "Enable shortcuts on dashboard for faster navigation.":
    "Aktivera genvägar på startsidan för snabbare navigering.",
  "HLL Employees": "HLL Anställda",
  lists: "listor",
  Lists: "Listor",
  machinelists: "maskinlistor",
  "Machine lists": "Maskinlistor",
  "Create new machine list": "Skapa ny maskinlista",
  "Save and close": "Spara och stäng",
  "Copy to the rental cart": "Kopiera till hyrkorgen",
  "Loading price": "Laddar pris",
  "Image coming soon": "Bild kommer snart",
  "Min 3 characters": "Minst 3 tecken",
  "Private, only your user can see this": "Privat, bara du kan se den här",
  "Make the list private": "Gör listan privat",
  "List created successfully": "Listan har skapats",
  "Add machines by searching or browsing our collection":
    "Lägg till maskiner genom att söka eller bläddra i vårt sortiment",
  "Add more machines by searching or browsing our collection":
    "Lägg till fler maskiner genom att söka eller bläddra i vårt sortiment",
  "Add machines": "Lägg till maskiner",
  "Machine list": "Maskinlista",
  "Save to machine list": "Spara till maskinlista",
  "Machine list deleted": "Maskinlista raderad",
  "Machine added to machine list": "Maskin tillagd i maskinlista",
  "Machine list updated": "Maskinlista uppdaterad",
  "Cost overview": "Kostnadsöversikt",
  "Updated quantity": "Uppdaterad kvantitet",
  "Quantity cannot be less than 1": "Kvantitet kan inte vara mindre än 1",
  "Service costs": "Servicekostnader",
  "Delete machine list": "Radera maskinlista",
  "Search hits": "sökträffar",
  "No machines found": "Inga maskiner hittades",
  "No categories found": "Inga kategorier hittades",
  "Search results on": "Sökresultat på",
  "No search results found": "Inga sökresultat hittades",
  "When you rent from us, you can get a machine from different manufacturers. But of course they are equivalent in terms of specifications. Select from the list if you want to see info about a particular model.":
    "När du hyr från oss så kan du få en maskin från olika fabrikat. Men de är såklart likvärdiga när det gäller specifikationer. Välj i listan om du vill se info om en viss modell.",
  "Our machines": "Våra maskiner",
  "Machines and accessories": "Maskiner och tillbehör",
  "The list was created by another person at your company, are you sure you want to delete the list?":
    "Listan är skapad av en annan person på ditt bolag, är du säker att du vill radera listan?",
  "Create private or shared machine lists. Private list means that it's content will not be accessible to users other than you.":
    "Skapa privata eller delade maskinlistor. Privat lista innebär att dess innehåll inte kommer vara tillgängligt för andra användare än dig.",
  "Private list": "Privat lista",
  "Go to map": "Gå till karta",
  "Articles and consumables": "Artiklar och förbrukningsvaror",
  Transport: "Transport",
  "Missing accessory connection": "Saknar tillbehörskoppling",
  "Select machine list": "Välj maskinlista",
  Finish: "Avsluta"
}

export default sv
