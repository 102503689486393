import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import moment from "moment"
import uuid from "react-uuid"

const AddonModal = ({
  referenceId,
  orderId,
  open,
  setModalOpen,
  modalVisibilityHandler,
  modalSaveHandler,
  amount,
  data,
  isEdit
}) => {
  const { t } = useTranslation()
  const [checkedItems, setCheckedItems] = useState([])
  const getPrice = (data) => {
    const { offers } = data

    if (!offers || offers.length === 0) {
      return 0
    }

    return offers[0].price
  }

  const handleChange = ({ target: { name, checked, value } }) => {
    const tempCheckedItems = checkedItems
    const findIndex = checkedItems.findIndex((item) => item.id === name)
    const parsedValue = JSON.parse(value)

    const offers = parsedValue?.item?.offers ?? []
    const articleId = parsedValue?.item?.articleId ?? null
    const defaultOfferObj =
      offers.length !== 0 && offers.filter((offer) => offer.isDefault === true)

    //Checking orderRowType
    let orderRowType = defaultOfferObj ? defaultOfferObj[0].type : 0
    if (orderRowType === 0 || orderRowType === 6) {
      orderRowType = 1
    }

    if (orderRowType === 5) {
      orderRowType = 0
    }

    const data = {
      internalId: uuid(),
      id: name,
      articleId,
      quantity: parsedValue.quantity ? parsedValue.quantity : 1,
      orderId,
      referenceId,
      description: "",
      isChecked: checked,
      item: parsedValue?.item,
      price: getPrice(parsedValue?.item),
      agreedPrice: {
        amount: 0,
        type: "kr"
      },
      internalType: "ARTICLE",
      subType: "ADDON",
      orderRowType: orderRowType,
      startTime: moment().format("YYYY-MM-DD[T]hh:mm:ss.SSS[Z]"),
      debitType: defaultOfferObj ? defaultOfferObj[0].debitType : null,
      newName: "",
      offerType: defaultOfferObj ? defaultOfferObj : [],
      isMisc: false
    }

    if (findIndex > -1) {
      tempCheckedItems[findIndex] = data
    } else {
      tempCheckedItems.push(data)
    }

    setCheckedItems([...tempCheckedItems])
  }

  useEffect(() => {
    if (data && data.length > 0 && !isEdit) {
      let preSelectedItems = []

      data.forEach((item) => {
        if (!item.isPreSelected) {
          return
        }

        const offers = item?.item?.offers ?? []
        const defaultOfferObj =
          offers.length !== 0 &&
          offers.filter((offer) => offer.isDefault === true)

        //Checking orderRowType
        let orderRowType = defaultOfferObj ? defaultOfferObj[0].type : 0
        if (orderRowType === 0 || orderRowType === 6) {
          orderRowType = 1
        }

        if (orderRowType === 5) {
          orderRowType = 0
        }

        preSelectedItems.push({
          internalId: uuid(),
          id: item.item.id,
          articleId: item.item.articleId,
          quantity: item?.quantity ? item.quantity : 1,
          orderId,
          referenceId,
          description: "",
          isChecked: true,
          item: item.item,
          price: getPrice(item.item),
          agreedPrice: {
            amount: 0,
            type: "kr"
          },
          internalType: "ARTICLE",
          subType: "ADDON",
          orderRowType: orderRowType,
          startTime: moment().format("YYYY-MM-DD[T]hh:mm:ss.SSS[Z]"),
          debitType: defaultOfferObj ? defaultOfferObj[0].debitType : null,
          newName: "",
          offerType: defaultOfferObj ? defaultOfferObj : null,
          isMisc: false
        })
      })
      setCheckedItems(preSelectedItems)
    }
  }, [data, isEdit, orderId, referenceId])

  const handleClose = () => setModalOpen(false)

  return (
    <Modal
      show={open}
      centered
      animation={false}
      backdrop="static"
      keyboard={false}
      onHide={() => {
        modalVisibilityHandler(false)
      }}
      className="modal-order addon-modal">
      <Modal.Header>
        <h2 className="header-title mt-3 mb-3 text-center">{t("Addons")}</h2>
      </Modal.Header>

      <Modal.Body className="is-scrollable">
        {data &&
          data.length > 0 &&
          data.map((item) => {
            if (!item?.item?.id) {
              return null
            }

            return (
              <div key={item.item.id} className="mb-3">
                <div className="d-flex justify-content-between align-items-center pb-3 mb-3 border-bottom addon">
                  <label htmlFor={item.item.id} className="pr-5">
                    <strong className="d-block">{item.item.name}</strong>
                  </label>
                  <input
                    type="checkbox"
                    className="form-check-input list-checkbox list-checkbox-theme"
                    id={item.item.id}
                    name={item.item.id}
                    value={JSON.stringify(item)}
                    defaultChecked={checkedItems.find(
                      (cb) => cb.id === item.item.id && cb.isChecked
                    )}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )
          })}
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex align-items-center justify-content-between flex-row-reverse">
          <button
            className="btn btn-primary w-100 d-block"
            onClick={() => {
              modalSaveHandler(checkedItems, amount === 0 ? 1 : amount)
            }}>
            {t("Save")}
          </button>

          <button
            className="btn btn-outline-secondary w-100 d-block mr-3"
            onClick={handleClose}>
            {t("Close")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AddonModal
