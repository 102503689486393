import React, { useEffect, useState, useRef } from "react"
import axios from "axios"
import Actions from "../Common/Actions"
import Loader from "../../Loader/Loader"
import Content from "../Common/Content"
import Items from "../Common/Items"
import Storage from "../../../utils/storage"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { depotState, viewState, machineState } from "../Store"
import Summary from "../Common/Summary"
import Confirmation from "../Common/Confirmation"
import Toast from "../../Common/Toast"
import { useTranslation } from "react-i18next"

const List = () => {
  const view = useRecoilValue(viewState)
  const machines = useRecoilValue(machineState)
  const setDepots = useSetRecoilState(depotState)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  const [modalType, setModalType] = useState("search")
  const [transferData, setTransferData] = useState([])

  const userState = Storage.get("userState") ?? null
  const regionId = userState?.internal?.region?.regionId ?? 3
  const { t } = useTranslation()
  const onUnMount = useRef()

  const handleModalHide = () => {
    setModalOpen(false)
  }

  const handleModalShow = (type) => {
    setModalOpen(true)
    setModalType(type)
  }

  onUnMount.current = () => {
    if (machines.length > 0) {
      const deleteMachineLock = async (id) => {
        await axios.post("/api/hll/deleteFleetItemLock", {
          id
        })
      }

      machines.forEach((machine) => deleteMachineLock(machine.id))
    }
  }

  useEffect(() => {
    return () => {
      onUnMount.current()
    }
  }, [])

  useEffect(() => {
    let unmounted = false
    setLoading(true)

    const request = async () => {
      await axios
        .post("/api/hll/internalDepots", {
          body: {
            regionId: {
              logicalOperator: "And",
              predicate: "Equal",
              value: regionId ?? 3
            }
          }
        })
        .then((response) => {
          if (!unmounted) {
            if (response.data.data && response.data.data.length > 0) {
              const sortedDepots = response.data.data.sort((a, b) =>
                a.name > b.name ? 1 : -1
              )

              setDepots(sortedDepots)
            } else {
              setError("Something went wrong")
            }
          }
        })
        .catch((err) => {
          console.error(err?.response?.data?.CorrelationId)
          setError(
            `Something went wrong. Correlation ID: ${err?.response?.data?.CorrelationId}`
          )
        })
        .finally(() => {
          setLoading(false)
        })
    }

    request()

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionId, setDepots])

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center my-3 flex-grow-1">
        <Loader />
      </div>
    )
  }

  if (error) {
    return (
      <div className="d-flex align-items-center justify-content-center my-3 flex-grow-1">
        {error}
      </div>
    )
  }

  return (
    <>
      <Toast />

      <Content
        modalOpen={modalOpen}
        handleModalHide={handleModalHide}
        type={modalType}
        regionId={regionId}
      />

      <div className="container-fluid container-fluid-max-width has-action-footer px-0 py-0 py-lg-2 px-lg-2">
        {view === "list" && (
          <Items
            emptyText={t(
              "You haven't added any machines/articles to transfer yet."
            )}
            isEmpty={true}
          />
        )}
        {view === "confirmation" && <Confirmation />}
        {view === "summary" && <Summary transferData={transferData} />}
        <Actions
          handleModalShow={handleModalShow}
          setTransferData={setTransferData}
        />
      </div>
    </>
  )
}

export default List
