import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import axios from "axios"

import { usePermissionCheck } from "../../utils/hooks"
import BillingMethodForm from "./Forms/BillingMethodForm/Form"
import BillingAddressForm from "./Forms/BillingAddressForm/Form"
import { billingMethodViewTemplate } from "./Forms/BillingMethodForm/FormTemplate"

import { billingAddressViewTemplate } from "./Forms/BillingAddressForm/FormTemplate"
import Loader from "../Loader/Loader"
import Toast from "../Common/Toast"

const Billing = ({ customer }) => {
  const { t } = useTranslation()
  const hasEditPermission = usePermissionCheck(
    "MinaSidor.FakturametodAdministrera"
  )
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [customerBillingMethod, setCustomerBillingMethod] = useState(null)
  const customerID = customer?.id
  const customerBillingFrequency = customer?.billingFrequency

  useEffect(() => {
    let unmounted = false
    const getCustomerBillingMethod = async () => {
      setLoading(true)
      setError(false)
      setErrorMsg("")

      await axios
        .post("/api/hll/customerBillingMethod", {
          customerID: customerID
        })
        .then((response) => {
          if (!unmounted) {
            setCustomerBillingMethod(response?.data)
          }
        })
        .catch((err) => {
          console.warn(err, "Error: /api/hll/customerBillingMethod")
          setError(true)
          setErrorMsg(t("Something went wrong, please refresh current page."))
        })
        .finally(() => {
          setLoading(false)
        })
    }

    getCustomerBillingMethod()

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerID, t])

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-12">
          <div className="header">
            <div className="header-body border-bottom-0 pb-0">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="header-pretitle">
                    {t("My pages")} - {t("Company Profile")}
                  </h6>
                  <h1 className="header-title text-truncate mb-4">
                    {t("Billing")}
                  </h1>
                </div>
                <div className="col-auto"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h2 className="card-header-title text-truncate">
                {t("Billing method")}
              </h2>
            </div>

            {error && errorMsg && !loading && (
              <div className="card-body">{errorMsg}</div>
            )}

            {loading && (
              <div className="card-body">
                <Loader />
              </div>
            )}
            {!loading && customerBillingMethod && (
              <BillingMethodForm
                template={billingMethodViewTemplate}
                billingMethod={customerBillingMethod}
                billingFrequency={customerBillingFrequency}
                hasEditPermission={hasEditPermission}
                setCustomerBillingMethod={setCustomerBillingMethod}
                customerID={customerID}
              />
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h2 className="card-header-title text-truncate">
                {t("Billing address")}
              </h2>
            </div>

            {error && errorMsg && !loading && (
              <div className="card-body">{errorMsg}</div>
            )}

            {loading && (
              <div className="card-body">
                <Loader />
              </div>
            )}
            {!loading && customerBillingMethod && (
              <BillingAddressForm
                template={billingAddressViewTemplate}
                billingMethod={customerBillingMethod}
                hasEditPermission={hasEditPermission}
                setCustomerBillingMethod={setCustomerBillingMethod}
                customerID={customerID}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Billing
