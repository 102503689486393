import React from "react"

function Confetti({ className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 22"
      stroke="currentColor"
      style={{ width: 105, height: 105 }}
      className={className}>
      <path
        d="M15.1094 11.1981C15.963 10.3463 17.1196 9.86792 18.3255 9.86792C19.5314 9.86792 20.688 10.3463 21.5416 11.1981"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7874 6.89154C11.2099 6.46922 11.5451 5.96778 11.7738 5.41587C12.0025 4.86395 12.1203 4.27238 12.1203 3.67496C12.1203 3.07753 12.0025 2.48596 11.7738 1.93405C11.5451 1.38213 11.2099 0.880691 10.7874 0.458374"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5688 7.56163L19.7854 6.49097"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4248 5.4194L15.4973 2.2019"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.30127 4.37341C7.36205 4.37341 7.42034 4.39756 7.46332 4.44053C7.50629 4.48351 7.53044 4.5418 7.53044 4.60258"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.07202 4.60258C7.07202 4.5418 7.09617 4.48351 7.13914 4.44053C7.18212 4.39756 7.24041 4.37341 7.30119 4.37341"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.30119 4.83183C7.24041 4.83183 7.18212 4.80768 7.13914 4.76471C7.09617 4.72173 7.07202 4.66344 7.07202 4.60266"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.53044 4.60266C7.53044 4.66344 7.50629 4.72173 7.46332 4.76471C7.42034 4.80768 7.36205 4.83183 7.30127 4.83183"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1763 7.12341C14.237 7.12341 14.2953 7.14756 14.3383 7.19053C14.3813 7.23351 14.4054 7.2918 14.4054 7.35258"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.947 7.35258C13.947 7.2918 13.9712 7.23351 14.0141 7.19053C14.0571 7.14756 14.1154 7.12341 14.1762 7.12341"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1762 7.58183C14.1154 7.58183 14.0571 7.55768 14.0141 7.51471C13.9712 7.47173 13.947 7.41344 13.947 7.35266"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4054 7.35266C14.4054 7.41344 14.3813 7.47173 14.3383 7.51471C14.2953 7.55768 14.237 7.58183 14.1763 7.58183"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.843 12.6234C17.9038 12.6234 17.9621 12.6476 18.0051 12.6905C18.048 12.7335 18.0722 12.7918 18.0722 12.8526"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6138 12.8526C17.6138 12.7918 17.6379 12.7335 17.6809 12.6905C17.7239 12.6476 17.7822 12.6234 17.8429 12.6234"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8429 13.0818C17.7822 13.0818 17.7239 13.0577 17.6809 13.0147C17.6379 12.9717 17.6138 12.9134 17.6138 12.8527"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0722 12.8527C18.0722 12.9134 18.048 12.9717 18.0051 13.0147C17.9621 13.0577 17.9038 13.0818 17.843 13.0818"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3013 3.45679C18.362 3.45679 18.4203 3.48093 18.4633 3.52391C18.5063 3.56689 18.5304 3.62518 18.5304 3.68595"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.072 3.68595C18.072 3.62518 18.0962 3.56689 18.1391 3.52391C18.1821 3.48093 18.2404 3.45679 18.3012 3.45679"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3012 3.91508C18.2404 3.91508 18.1821 3.89094 18.1391 3.84796C18.0962 3.80498 18.072 3.74669 18.072 3.68591"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5304 3.68591C18.5304 3.74669 18.5063 3.80498 18.4633 3.84796C18.4203 3.89094 18.362 3.91508 18.3013 3.91508"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.92266 13.0772C9.86816 14.0227 10.9457 14.7587 11.9181 15.1234C12.8906 15.4881 13.6783 15.4515 14.1081 15.0217C14.5379 14.592 14.5745 13.8042 14.2098 12.8317C13.8451 11.8593 13.1091 10.7818 12.1636 9.8363C11.2181 8.8908 10.1406 8.15476 9.16812 7.79008C8.19566 7.42541 7.40789 7.46198 6.97812 7.89175C6.54835 8.32153 6.51178 9.10929 6.87645 10.0818C7.24112 11.0542 7.97717 12.1317 8.92266 13.0772V13.0772Z"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.883 15.1939L2.27705 21.396C2.0432 21.5211 1.77532 21.5675 1.51301 21.5286C1.25071 21.4897 1.00788 21.3674 0.820421 21.1798C0.632961 20.9923 0.510813 20.7494 0.472029 20.4871C0.433245 20.2247 0.479882 19.9569 0.605047 19.7231L6.8063 8.11719"
        stroke="#EB6400"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Confetti
