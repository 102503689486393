import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import ActionFooter from "../../Common/ActionFooter"
import { updateFormTemplate } from "../../ContactPersons/FormTemplate"
import PermissionForm from "../../ContactPersons/Form"
import { useRecoilState } from "recoil"
import { newOrderInformation, modalView } from "../Store"
import CreateProjectForm from "../Form/CreateProject/CreateProject"
import CreateProjectQuery from "../Common/Queries/CreateProject"
import toast from "react-hot-toast"
import { useForm, Controller } from "react-hook-form"
import axios from "axios"
import { useNavigate } from "react-router-dom"

import ModalSheet from "../../Common/ModalSheet"
import IdRequiredForm from "../Form/IdRequiredForm"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const ModalCreateForm = ({
  show,
  setShowModalCreateForm,
  handleModalNavigation
}) => {
  const { t } = useTranslation()
  const [modalViewState, setModalViewState] = useRecoilState(modalView)
  const navigate = useNavigate()
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [loading, setLoading] = useState(false)
  const [errorState, setErrorState] = useState(false)
  const [formatedFormData, setFormatedFormData] = useState(null)
  const [showModalSheet, setShowModalSheet] = useState(false)
  const [newOrderInformationState, setNewOrderInformationState] =
    useRecoilState(newOrderInformation)

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    shouldUnregister: true
  })

  const handleForm = {
    register,
    errors,
    formData: {
      customerId: newOrderInformationState?.customer?.id
    },
    action: "",
    control,
    Controller,
    setError,
    setValue,
    clearErrors
  }

  const handleHideModal = () => {
    setModalViewState(
      modalViewState === "createProject" ? "project" : "reference"
    )
    setShowModalCreateForm(false)
  }

  const handleProjectSubmit = async () => {
    setLoading(true)
    setErrorState(false)
    try {
      const res = await CreateProjectQuery(formatedFormData)
      setNewOrderInformationState((oldOrderInformationState) => {
        return {
          ...oldOrderInformationState,
          project: res
        }
      })
      toast.success(t("The project is created"))
      setShowModalCreateForm(false)
      setModalViewState("reference")
      handleModalNavigation("reference")
    } catch (err) {
      //console.log(err)
      setErrorState(true)
      setShowModalCreateForm(false)
      setModalViewState("project")
      toast.error(t("Something went wrong, try again."))
    } finally {
      setLoading(false)
    }
  }

  const handleReferenceSubmit = async (data) => {
    let { action, id, ...body } = data
    const requiresIdForRentalCustomer =
      newOrderInformationState?.customer?.requiresIdForRentalCustomer

    setLoading(true)
    setErrorState(false)

    let referenceCreated = false

    await axios
      .post("/api/hll/customerRentalContactAdd", {
        body
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          setNewOrderInformationState((oldOrderInformationState) => {
            return {
              ...oldOrderInformationState,
              reference: response.data
            }
          })

          if (requiresIdForRentalCustomer) {
            setModalViewState("reference")
            setShowModalCreateForm(false)
            handleModalNavigation("reference")
          } else {
            setModalViewState("machineSearch")
          }

          toast.success(t("The reference has been created!"))
          referenceCreated = true
        }
      })
      .catch((error) => {
        console.log("error", error)
        setErrorState(true)
        setModalViewState("reference")
        setShowModalCreateForm(false)
        toast.error(t("Something went wrong, try again."))
      })
      .finally(() => {
        setLoading(false)
        if (referenceCreated && !requiresIdForRentalCustomer) {
          setTimeout(() => {
            navigate(`/order/${t("create")}`)
          }, 500)
        }
      })
  }

  return (
    <>
      <Modal
        show={show}
        fullscreen="true"
        onHide={() => handleHideModal()}
        backdrop="static"
        animation={false}
        className={`modal-scrollable`}>
        <Modal.Header className="d-block p-3">
          <div className="position-relative">
            <h3 className="header-title mt-3 mb-0 text-center">
              {modalViewState === "createProject" && t("Create new project")}
              {modalViewState === "createReference" &&
                t("Create new reference")}
            </h3>
            <button
              onClick={() => handleHideModal()}
              className="bg-transparent position-absolute p-0 text-body"
              style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
              <CustomSvgIcon
                name="close"
                width={16}
                height={16}
                className="text-black"
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center p-0 bg-white is-scrollable mx-0 pb-5">
          {modalViewState === "createProject" && (
            <CreateProjectForm
              setDisabledSubmit={setDisabledSubmit}
              setFormatedFormData={setFormatedFormData}
              handleSubmit={handleProjectSubmit}
              loading={loading}
              setError={setErrorState}
            />
          )}
          {modalViewState === "createReference" && (
            <div className="container-fluid container-fluid-max-width mt-4 text-left">
              <form
                className="py-3 modal-email-notification mb-3"
                onSubmit={handleSubmit(handleReferenceSubmit)}
                id="form-create-reference">
                <div className="row">
                  <PermissionForm
                    formTemplate={updateFormTemplate}
                    handleForm={handleForm}
                    requiresIdForRentalCustomer={
                      newOrderInformationState?.customer
                        ?.requiresIdForRentalCustomer
                    }
                    userGroups={null}
                  />
                </div>
              </form>
            </div>
          )}
        </Modal.Body>
        <ModalSheet show={showModalSheet}>
          {modalViewState === "createReference" && (
            <IdRequiredForm setShowModalSheet={setShowModalSheet} />
          )}
        </ModalSheet>
        <ActionFooter>
          <button
            className={`btn btn-outline-secondary w-100 d-block mr-3${
              loading ? " btn-loading" : ""
            }`}
            disabled={loading}
            onClick={() => handleHideModal()}>
            {t("Close")}
          </button>
          {modalViewState === "createReference" ? (
            <button
              form="form-create-reference"
              type="submit"
              className="btn btn-primary w-100 d-block"
              disabled={loading}>
              {t("Save and continue")}
            </button>
          ) : (
            <button
              className={`btn btn-primary w-100 d-block${
                disabledSubmit ? " btn-disabled" : ""
              }`}
              onClick={() => handleProjectSubmit()}
              disabled={loading || errorState || disabledSubmit}>
              {t("Continue")}
            </button>
          )}
        </ActionFooter>
      </Modal>
    </>
  )
}

export default ModalCreateForm
