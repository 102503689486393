import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { getMachineAncestors } from "../../../../utils/hooks"
import parser from "html-react-parser"
import Storage from "../../../../utils/storage"

export default function BreadCrumb({ machineGroupData = null }) {
  const { t } = useTranslation()
  const machineAncestorsStorage = Storage.get(`machineAncestors`, null, "local")

  const capitialize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const id = machineGroupData?.id

  // Fetch current machine group ancestors
  const { data: ancestors } = useQuery({
    queryKey: [`machineAncestors`],
    queryFn: async () => {
      const data = await getMachineAncestors(id)
      Storage.set(`machineAncestors`, data, "local")
      return data
    },
    enabled: id > 0,
    refetchOnWindowFocus: false,
    initialData: machineAncestorsStorage || []
  })

  return (
    <div className="bg-white border-bottom rounded-0">
      <div className="container-fluid container-fluid-max-width">
        <div className="hll-breadcrumb py-3">
          <div className="scrollable-breadcrumb">
            <div className="hll-breadcrumb__item">
              <Link to={`/${t("customer")}`} className="text-primary">
                {capitialize(t("customer"))}
              </Link>
              <span className="mx-2">/</span>
            </div>
            <div className="hll-breadcrumb__item">
              <Link
                to={`/${t("customer")}/${t("machines")}`}
                className="text-primary">
                {capitialize(t("machines"))}
              </Link>
              <span className="mx-2">/</span>
            </div>
            {ancestors &&
              ancestors?.length > 0 &&
              ancestors?.map((ancestor, index) => {
                const lastItem = index === ancestors.length - 1

                return (
                  <div className="hll-breadcrumb__item" key={index}>
                    {lastItem ? (
                      <span>{parser(ancestor?.name)}</span>
                    ) : (
                      <>
                        <Link
                          to={`/${t("customer")}/${t("machines")}/${
                            ancestor?.hierarchicalSlug
                          }`}
                          className="text-primary"
                          onClick={() =>
                            Storage.set(
                              `machineAncestors`,
                              ancestors?.slice(0, index + 1),
                              "local"
                            )
                          }>
                          {ancestor?.name}
                        </Link>
                        <span className="mx-2">/</span>
                      </>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
