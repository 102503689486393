import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Storage from "../../../utils/storage"
import axios from "axios"

import Loader from "../../Loader/Loader"
import Alert from "../../Common/Alert"

import useMachineContext from "../../../context/Machine/Store"

import toast from "react-hot-toast"

import Toast from "../../Common/Toast"
import { dateAndTimeNow } from "../../../utils/helpers"

const MachineItemDataRow = ({
  wrapperClass = "col-12",
  labelClass = "text-uppercase font-xs pb-0 mb-1",
  label,
  status,
  branchId = null,
  branchName = null,
  availability
}) => {
  const { t } = useTranslation()
  const { machineItem } = useMachineContext()

  const userState = Storage.get("userState") ?? null
  const depotId = branchId ?? 0
  const regionId = userState?.internal?.region?.regionId ?? 3

  const [newDepot, setNewDepot] = useState({
    id: branchId,
    name: branchName
  })

  const [process, setProcess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [confirmationLoading, setConfirmationLoading] = useState(false)
  const [depots, setDepots] = useState([])
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  const onChangeDepot = (event) => {
    event.preventDefault()
    const id = event.target.value
    const { selectedIndex } = event.nativeEvent.target
    const name = event.nativeEvent.target[selectedIndex].text

    if (id !== depotId) {
      setError(false)
      setProcess(true)
      setErrorMsg("")
      setNewDepot({
        id,
        name
      })
    } else {
      setProcess(false)
      setError(true)
      setErrorMsg(
        t(
          "The individual is already at this depot, choose another depot to start moving depots."
        )
      )
      setNewDepot({
        id: branchId,
        name: branchName
      })
    }
  }

  const Switcher = () => {
    const handleSave = async () => {
      setConfirmationLoading(true)

      await axios
        .post("/api/hll/fleetItemEdit", {
          body: {
            id: machineItem.id,
            fleetModelId: machineItem.fleetModelId,
            name: machineItem.name,
            number: machineItem.number,
            branchId: newDepot.id,
            status: machineItem.status,
            availability,
            branchName: newDepot.name
          }
        })
        .then((response) => {
          const { fleetModelName, branchName, name, number, availability } =
            response.data
          const msg = `${fleetModelName} ${t(
            "has now been moved to"
          )} ${branchName}`
          toast.success(msg)

          window.dataLayer.push({
            event: "HLL_machine_status_change",
            itemName: name,
            itemNumber: number,
            availability,
            depot: branchName,
            createdAt: dateAndTimeNow(),
            userType: "internal"
          })

          setProcess(false)
        })
        .catch((error) => {
          //console.error(error)
          const errorMsg = t(
            "Something went wrong when moving to a new depot, please try again."
          )
          toast.error(errorMsg)
        })
        .finally(() => {
          setConfirmationLoading(false)
        })
    }

    return (
      <div className="d-block d-sm-flex align-items justify-content-between">
        <div className="flex-grow-1">
          <select
            className="form-select form-control-modal rounded-0 w-100"
            defaultValue={newDepot?.id ? newDepot.id : depotId}
            onChange={onChangeDepot}>
            {internalDepots
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((item, i) => (
                <option key={i} value={item?.id}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div>
          {process && !confirmationLoading && (
            <button
              className="btn btn-white btn-outline mt-3 mt-sm-0 w-100 ml-0 ml-sm-3"
              disabled={!process}
              onClick={handleSave}>
              {t("Move individual to depot")}
            </button>
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    let unmounted = false
    setLoading(true)

    const body = {
      regionId: {
        logicalOperator: "And",
        predicate: "Equal",
        value: regionId ?? 3
      }
    }

    const requestDepots = async () => {
      await axios
        .post("/api/hll/internalDepots", {
          body: body
        })
        .then((response) => {
          if (!unmounted) {
            const result = response?.data?.data ? response.data.data : false
            setDepots(result)
            if (result.length === 0) {
              setErrorMsg(t("Can't find any depots. Try another region"))
            }
          }
        })
        .catch((err) => {
          //console.warn(err, "Error: api/hll/customerOrders")
          setErrorMsg(t("Something went wrong, please refresh current page."))
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (status >= 1) {
      requestDepots()
    }

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionId, t, status])

  const internalDepots = depots ? depots : null

  return (
    <div className={wrapperClass}>
      <Toast />
      <p className={labelClass}>{`${label}/${t(
        "The individual's current depot"
      )} `}</p>
      <div className="row">
        <div className="col-12 col-lg-6 pb-4">
          {loading && <Loader className="mt-3" />}
          {error && errorMsg && !loading && (
            <Alert
              className="text-left alert alert-light rounded-0"
              message={errorMsg}
            />
          )}
          {!loading && internalDepots && (
            <>
              <Switcher />
            </>
          )}
          {confirmationLoading && <Loader className="mt-3" />}
        </div>
      </div>
    </div>
  )
}

export default MachineItemDataRow
