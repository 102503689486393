import React from "react"
import { useTranslation } from "react-i18next"
import Inspection from "../Inspection"

const MachineInspections = ({ inspection }) => {
  const { t } = useTranslation()

  return (
    <>
      {inspection.length !== 0 && (
        <>
          <div className="row">
            <div className="col-12">
              <p>
                {t(
                  "The machine will be inspected soon, are you sure it will be made available for rent?"
                )}
              </p>
              <p>
                {t(
                  "A note is made in the system that the machine is made available if you continue."
                )}
              </p>
            </div>
          </div>

          <div className="row mt-3">
            <Inspection data={inspection} />
          </div>
        </>
      )}
    </>
  )
}

export default MachineInspections
