import React from "react"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"

const ModalConfirm = ({ show, data, handleDelete, handleHideConfirmModal }) => {
  const { t } = useTranslation()

  return (
    <Modal
      show={show}
      onHide={() => handleHideConfirmModal()}
      centered
      animation={false}>
      <Modal.Header className="justify-content-center">
        <h3 className="header-title mt-3 mb-0 text-center">
          {t("Delete document recipient?")}
        </h3>
      </Modal.Header>
      <Modal.Body className="text-center pt-0">
        {t("Please confirm the deletion of document recipient:")}
        <div>{data?.email}</div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-white"
          onClick={() => handleHideConfirmModal()}>
          {t("Cancel")}
        </button>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => handleDelete(data)}>
          {t("Confirm")}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirm
