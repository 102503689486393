import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchData } from "../../../context/ReturnOrder/Store"
import { Form, FormControl } from "react-bootstrap"
import { isMobile } from "react-device-detect"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const SearchForm = ({ onSubmitHandler, onBlurHandler }) => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const [searchData] = useSearchData()
  const [inputText, setInputText] = useState(searchData.searchText)
  const [inputMode, setInputMode] = useState("numeric")
  const inputStyle = isMobile
    ? { paddingLeft: "50px", paddingRight: "50px" }
    : { paddingRight: "50px" }

  const handleInputChange = (event) => {
    setInputText(event.target.value)
  }

  return (
    <div className="row align-items-center">
      <div className="col">
        <Form
          autoComplete="off"
          noValidate={false}
          className="position-relative returns-search-form"
          onSubmit={(event) => {
            event.preventDefault()
            onSubmitHandler(event)
          }}>
          {inputMode === "numeric" ? (
            <FormControl
              className="form-control form-control-lg"
              type="text"
              inputMode={inputMode}
              name="search"
              placeholder={t("Search")}
              onChange={handleInputChange}
              value={inputText}
              ref={inputRef}
              required
              enterKeyHint="Search"
              autoCapitalize="none"
              autoComplete="off"
              spellCheck={false}
              style={inputStyle}
              autoCorrect="off"
              onBlur={(event) =>
                onBlurHandler(event?.target?.value ? event.target.value : "")
              }
            />
          ) : (
            <FormControl
              className="form-control form-control-lg"
              type="text"
              inputMode={inputMode}
              name="search"
              placeholder={t("Search")}
              onChange={handleInputChange}
              value={inputText}
              required
              enterKeyHint="Search"
              autoCapitalize="none"
              autoComplete="off"
              spellCheck={false}
              style={inputStyle}
              autoCorrect="off"
            />
          )}

          {isMobile && (
            <button
              className="bg-transparent text-primary position-absolute"
              style={{ top: "50%", left: 10, transform: "translateY(-50%)" }}
              type="button"
              onClick={() => {
                setInputMode(inputMode === "numeric" ? "search" : "numeric")
              }}>
              <i
                className={`fe fe-${inputMode === "numeric" ? "hash" : "type"}`}
                style={{ fontSize: 20 }}></i>
            </button>
          )}

          <button
            className="bg-transparent text-primary position-absolute"
            style={{
              top: "50%",
              right: 20,
              transform: "translateY(-50%)",
              width: 20,
              height: 20
            }}
            type="submit">
            <CustomSvgIcon
              name="search"
              width={20}
              height={20}
              className={`text-muted`}
            />
          </button>
        </Form>
      </div>
    </div>
  )
}

export default SearchForm
