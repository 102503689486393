import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import swedish from "./languages/sv"

const resources = {
  sv: {
    translation: swedish
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "sv",

    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false,

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
