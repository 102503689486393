import React from "react"
import { useOidcUser, useOidc } from "@axa-fr/react-oidc"
import { useTranslation } from "react-i18next"
import Logo from "../../assets/images/HLL_notext.svg"
import Storage from "../../utils/storage"
import NavAccount from "../../components/Header/NavAccount"
import { useRecoilValue, useRecoilState } from "recoil"
import { orderInformation, view, newOrderInformation } from "./Store"
import { useLocation, useNavigate } from "react-router-dom"
import { useOidcIdToken } from "@axa-fr/react-oidc"
import CustomSvgIcon from "../Common/CustomSvgIcon"

function OrderHeader({ handleModal }) {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()
  const { idToken } = useOidcIdToken()
  const { logout } = useOidc()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const orderInformationState = useRecoilValue(orderInformation)
  const [newOrderInformationState, setNewOrderInformationState] =
    useRecoilState(newOrderInformation)
  const [viewState, setViewState] = useRecoilState(view)

  const logoutCallback = React.useCallback(async () => {
    const logoutUrl = `${process.env.REACT_APP_AUTH_URL}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.REACT_APP_PUBLIC_URL}`
    await logout()
    document.location.href = logoutUrl
  }, [idToken, logout])

  const perm = oidcUser?.perm
  const sub = oidcUser?.sub

  const handleLogOut = () => {
    Storage.remove("userGroups")
    Storage.remove("customer", "local")
    Storage.remove("subsidiaries", "local")
    Storage.remove("userState", "local")
    Storage.remove("internalCompanies", "local")
    Storage.remove("internalRegions", "local")
    Storage.remove("depots", "local")
    logoutCallback()
  }

  const { customer, project } = newOrderInformationState

  const renderHeader = () => {
    let title = t("Order")
    let subTitle = ""

    if (pathname === `/order/${t("create")}`) {
      title = customer?.name && customer.name
      subTitle = `${project?.name}${
        project?.name ? ` ${project?.number}` : `${project?.number}`
      }`
    } else if (pathname === "/order") {
      title = "Order"
      subTitle = ""
    } else {
      title =
        viewState === "summary"
          ? t("Order rows created")
          : orderInformationState?.customerName
      subTitle = ""
    }

    return (
      <>
        {title && (
          <p
            className="mb-0 text-reset font-weight-bold d-block text-truncate"
            style={{ maxWidth: "200px" }}>
            {title}
          </p>
        )}
        {subTitle && (
          <p className="mb-0 text-reset d-block text-truncate">{subTitle}</p>
        )}
      </>
    )
  }

  const handleGoBack = () => {
    setNewOrderInformationState({
      customer: null,
      project: null,
      reference: null
    })
    setViewState("createOrder")
    navigate(`/${t("order")}`)
  }

  return (
    <div className="app-header header bg-dark-theme shadow js-app-header">
      <div className="container-fluid container-fluid-max-width d-flex align-items-center justify-content-between h-100 py-1">
        <div className="d-flex justify-content-start">
          {pathname === "/order/skapa" ? (
            <button
              className="border-0 bg-secondary rounded position-relative d-flex align-items-center btn-go-back justify-content-center"
              onClick={() => handleGoBack()}>
              <span className="d-flex align-items-center p-2">
                <CustomSvgIcon
                  width={26}
                  height={26}
                  name="chevron-left"
                  className="text-white"
                />
              </span>
            </button>
          ) : (
            <button
              style={{ border: 0, background: "transparent", outline: "none" }}
              className="d-flex align-items-center ml-0"
              aria-label={t("Order")}
              onClick={() => handleGoBack()}>
              <img src={Logo} alt="Hyreslandslagets logotyp" className="logo" />
            </button>
          )}
        </div>
        <div
          className="header-main-title text-center position-absolute"
          style={{
            maxWidth: "50%"
          }}>
          {renderHeader()}
          {viewState === "viewOrder" && orderInformationState?.siteName}
        </div>

        <div className="d-flex justify-content-end">
          <NavAccount
            perm={perm}
            profile={oidcUser}
            sub={sub}
            handleLogOut={handleLogOut}
            oidcUser={oidcUser}
            handleModal={handleModal}
          />
        </div>
      </div>
    </div>
  )
}

export default OrderHeader
