import React from "react"
import { Toaster } from "react-hot-toast"

const Toast = ({ position = "bottom-center" }) => {
  return (
    <Toaster
      position={position}
      toastOptions={{
        style: {
          border: "1px solid #eee",
          fontSize: 14,
          borderRadius: 3,
          boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.03)"
        }
        // iconTheme: {
        //   primary: "#eb6400" <-- HLL Primary Color
        // }
      }}
    />
  )
}

export default Toast
