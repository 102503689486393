import React, { useEffect } from "react"
import { useOidcAccessToken } from "@axa-fr/react-oidc"
import { useCookies } from "react-cookie"
const PageLoader = () => {
  const { accessToken } = useOidcAccessToken()
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies([])

  useEffect(() => {
    setCookie("HLL.JWT.AuthToken", accessToken, { path: "/" })
  }, [accessToken, setCookie])

  return (
    <div className="app">
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div
          className="spinner-border spinner-border-md text-primary"
          role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  )
}

export default PageLoader
