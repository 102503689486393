import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSetRecoilState, useRecoilState } from "recoil"
import { modalView, newOrderInformation } from "../Store"

export default function InsuranceOptionForm({ setShowInsuranceOption }) {
  const { t } = useTranslation()
  const [controlled, setControlled] = useState(false)
  const setModalViewState = useSetRecoilState(modalView)
  const [
    newOrderInformationState,
    setNewOrderInformationState
  ] = useRecoilState(newOrderInformation)

  const handleChangeInsuranceOption = (event) => {
    const value = event.target.value
    setNewOrderInformationState((oldOrderInformationState) => {
      return {
        ...oldOrderInformationState,
        project: {
          ...newOrderInformationState?.project,
          insuranceOption: Number(value)
        }
      }
    })
    setControlled(true)
    setShowInsuranceOption(false)
  }

  return (
    <div className="p-3">
      <form>
        <div className="row row my-4">
          <div className="col text-left">
            <label
              className="field__label form-label mt-1 mb-1 text-uppercase"
              htmlFor="insuranceOption">
              {t("Choose insurance option")}
            </label>
            <select
              className="form-select mx-auto w-100"
              style={{ width: "auto" }}
              id="insuranceOption"
              defaultValue=""
              onChange={handleChangeInsuranceOption}>
              <option value="" disabled>
                {t("Choose")}
              </option>
              <option value="1">{t("No insurance")}</option>
              <option value="2">{t("Insurance")}</option>
            </select>
          </div>
        </div>
        <div className="d-flex align-items-center mt-3">
          <button
            className="btn btn-primary w-100 d-block"
            disabled={!controlled}
            onClick={() => {
              setModalViewState("reference")
            }}>
            {t("Continue")}
          </button>
        </div>
      </form>
    </div>
  )
}
