import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
export default function ProductCard({ title, image, number, objectID, slug }) {
  const { t } = useTranslation()

  return (
    <article className="product-card rounded p-4">
      <figure className="product-card-image-frame d-flex justify-content-center mx-auto my-4">
        {image && (
          <img
            src={image}
            alt={title ? title : ""}
            className="product-card-image img-fluid rounded"
          />
        )}
      </figure>
      <header>
        <small className="d-block product-card-sku text-muted text-left mb-1">
          <Link
            className="is-clickable text-black"
            to={{
              pathname: `/${t("customer")}/${t("machine")}/${t(
                "group"
              )}/${objectID}/${slug}`
            }}>
            {`#${number}`}
          </Link>
        </small>
        <h2 className="h4 mb-0 hyphenate text-left">{title}</h2>
      </header>
    </article>
  )
}
