import React, { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"

import axios from "axios"

const FeedbackForm = () => {
  const { t } = useTranslation()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [returnStatus, showReturnStatus] = useState(false)
  const [returnMessage, showReturnMessage] = useState("")

  const handleCancelModal = () => {
    setShow(false)
  }

  const handleShowFeedbackModal = () => setShow(true)

  const submitFeedback = async (e) => {
    e.preventDefault()
    setLoading(true)

    const mailData = {
      email: e.target.elements.email.value,
      summary: e.target.elements.summary.value,
      details: e.target.elements.details.value
    }

    try {
      let request = await axios
        .post("/api/postmark/feedback", mailData)
        .then((res) => {
          //console.log(res, 'res: /api/postmark/feedback"');
          setLoading(false)
          showReturnMessage(t("Thanks! We have received your feedback."))
          showReturnStatus(true)
          return res
        })
      return request.status === 200 ? true : false
    } catch (error) {
      setLoading(false)
      showReturnMessage(
        t("Something went wrong with sending you mail...Try again.")
      )
      showReturnStatus(true)
    }
  }

  return (
    <Fragment>
      <div
        className="d-flex align-items-center link-site-nav"
        onClick={handleShowFeedbackModal}
        style={{ cursor: "pointer" }}>
        <span
          className={`fe fe-message-square text-primary mr-3`}
          style={{ fontSize: 25 }}></span>
        <span className="font-weight-bold">{t("Give feedback")}</span>
      </div>

      <Modal show={show} onHide={handleCancelModal} centered animation={false}>
        <Modal.Header>
          <h2 className="header-title">{t("Feedback")}</h2>
        </Modal.Header>

        {returnMessage && (
          <Modal.Body>
            <p>{t(returnMessage)}</p>
          </Modal.Body>
        )}

        <form onSubmit={submitFeedback}>
          <Modal.Body>
            <div className="row">
              {!returnStatus && (
                <div className="col-12 mb-3">
                  <label
                    htmlFor="email"
                    className="field__label form-label mb-1 text-uppercase">
                    {t("Email address")}
                  </label>
                  <input type="email" name="email" required />
                </div>
              )}

              {!returnStatus && (
                <div className="col-12 mb-3">
                  <label
                    htmlFor="summary"
                    className="field__label form-label mb-1 text-uppercase">
                    {t("Summary")}
                  </label>
                  <input type="text" name="summary" required />
                </div>
              )}

              {!returnStatus && (
                <div className="col-12 mb-3">
                  <label
                    htmlFor="details"
                    className="field__label form-label mb-1 text-uppercase">
                    {t("Further details")}
                  </label>
                  <textarea name="details" rows={7} />
                </div>
              )}
            </div>
          </Modal.Body>

          {!returnStatus && (
            <Modal.Footer>
              <button
                className="btn btn-outline-dark"
                onClick={handleCancelModal}>
                {t("Cancel")}
              </button>
              <button type="submit" className="btn btn-primary">
                {!loading && t("Send")}

                {loading && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"></span>
                )}
              </button>
            </Modal.Footer>
          )}
        </form>
      </Modal>
    </Fragment>
  )
}

export default FeedbackForm
