import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Alert from "../../../components/Common/Alert"

import { useForm } from "react-hook-form"

import Icon from "../../Common/Icon"

import axios from "axios"

import Loader from "../../../components/Loader/Loader"

import ActionFooter from "../../Common/ActionFooter"

function ResponseModal({
  modalOpen,
  rentalResponseData,
  newRentalResponseData,
  handleOnModalClose,
  error,
  errorCode,
  errorMessage
}) {
  const { t } = useTranslation()
  const { returnCreateRequestValidationInfo } = rentalResponseData
  const { customerOrderCreateRequestValidationInfo } = newRentalResponseData
  const [extraEmailVisible, setExtraEmailVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notificationResponseData, setNotificationResponseData] = useState("")
  const [notificationType, setNotificationType] = useState("")

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data) => {
    //console.log(data, "submitData")
    let formattedData = {}
    setLoading(true)
    setNotificationResponseData(null)

    let emails = [data.returnEmail]

    if (typeof data.returnEmailExtra !== "undefined") {
      emails.push(data.returnEmailExtra)
    }

    formattedData = {
      returnNoteNotification: {
        returnNoteNumber: data?.returnNoteNumber,
        branchId: data?.branchId,
        orderId: data?.orderId,
        recipientEmailAddresses: emails
      }
    }

    await axios
      .post(`/api/hll/createReturnNoteNotification`, {
        body: formattedData
      })
      .then((response) => {
        // console.log(response, "response")
        if (response.data && response.data.requestResult === 0) {
          setNotificationType("success")
          setNotificationResponseData(t("Return copies are submitted"))
        }
      })
      .catch((error) => {
        console.error(error)
        setNotificationType("error")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      show={modalOpen}
      centered
      animation={false}
      backdrop="static"
      keyboard={false}
      fullscreen="true"
      className="modal-order is-scrollable">
      <Modal.Header>
        <div className="position-relative">
          <h3 className="header-title mt-3 mb-0 text-center">
            {t("Your order status")}
          </h3>
        </div>
      </Modal.Header>
      <Modal.Body style={{ minHeight: 300 }}>
        <div className="scroller-view px-3 text-center">
          {Object.keys(rentalResponseData).length > 0 && (
            <>
              <h4 className="mb-3">{t("Returns")}</h4>
              {rentalResponseData.requestResult === 0 && (
                <>
                  <div className="mb-4">
                    {t("The return is complete with number")}{" "}
                    <strong>{rentalResponseData?.return?.id}</strong>
                    {loading && <Loader />}
                    {notificationResponseData !== "" && (
                      <div
                        className={`my-3 ${
                          notificationType === "success"
                            ? "text-success"
                            : "text-danger"
                        }`}>
                        {notificationResponseData}
                      </div>
                    )}
                    {notificationResponseData === "" && (
                      <form
                        className="py-3 modal-email-notification mt-5 mb-3"
                        onSubmit={handleSubmit(onSubmit)}>
                        {extraEmailVisible ? (
                          <h4>{t("Extra return copies")}</h4>
                        ) : (
                          <h4>{t("Extra return copy")}</h4>
                        )}
                        <div className="input-group">
                          <input
                            {...register("returnNoteNumber")}
                            type="hidden"
                            name="returnNoteNumber"
                            className="form-control list-search"
                            value={rentalResponseData?.return?.id}
                          />
                        </div>
                        <input
                          {...register("branchId")}
                          type="hidden"
                          name="branchId"
                          value={rentalResponseData?.return?.branchId}
                        />
                        <input
                          {...register("orderId")}
                          type="hidden"
                          name="orderId"
                          value={rentalResponseData?.return?.orderId}
                        />
                        <div className="input-group">
                          <input
                            name="returnEmail"
                            type="email"
                            placeholder={t("Email")}
                            className={`form-control list-search${
                              errors.returnEmail ? " is-invalid " : ""
                            }`}
                            aria-invalid={errors.returnEmail ? "true" : "false"}
                            {...register("returnEmail", { required: true })}
                          />
                        </div>

                        <div
                          className={`input-group mt-3${
                            !extraEmailVisible ? " d-none" : ""
                          }`}>
                          <input
                            name="returnEmailExtra"
                            type="email"
                            placeholder={t("Email")}
                            disabled={!extraEmailVisible}
                            className={`form-control list-search${
                              errors.returnEmailExtra ? " is-invalid " : ""
                            }`}
                            aria-invalid={
                              errors.returnEmailExtra ? "true" : "false"
                            }
                            {...register("returnEmailExtra")}
                          />

                          {!extraEmailVisible ? (
                            <div style={{ width: 24 }} className="ml-2"></div>
                          ) : (
                            <button
                              type="button"
                              className="bg-transparent p-0 ml-2"
                              onClick={() => setExtraEmailVisible(false)}>
                              <Icon name="minusCircle" />
                            </button>
                          )}
                        </div>

                        <div className="mt-3">
                          <button
                            type="button"
                            className="d-flex w-100 align-items-center justify-content-center btn btn-outline-secondary"
                            disabled={loading || extraEmailVisible}
                            onClick={() => {
                              setExtraEmailVisible(true)
                            }}
                            style={{
                              fontWeight: 600,
                              fontSize: 13,
                              height: 50
                            }}>
                            <Icon name="plusCircle" />
                            <span className="ml-2">{t("Add row")}</span>
                          </button>
                        </div>

                        <div className="input-group mt-3">
                          <button
                            className="btn btn-primary w-100"
                            type="submit">
                            {extraEmailVisible
                              ? t("Send return copies")
                              : t("Send to selected addresses")}
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </>
              )}
              {returnCreateRequestValidationInfo.orderRowInfos && (
                <>
                  <div className="text-danger">
                    {returnCreateRequestValidationInfo.orderRowInfos.map(
                      (item, index) => {
                        return <p key={index}>{item?.description}</p>
                      }
                    )}
                  </div>
                </>
              )}
            </>
          )}

          {newRentalResponseData.requestResult === 0 && (
            <>
              <div className="mb-4">
                {t("Any addons are on the same order number.")}
              </div>
            </>
          )}
          {newRentalResponseData.requestResult === 1 && (
            <>
              <div className="mb-4">
                <p>{t("Something went wrong with addons!")}</p>
                {customerOrderCreateRequestValidationInfo.orderRowInfos && (
                  <>
                    <div className="text-danger">
                      {customerOrderCreateRequestValidationInfo.orderRowInfos.map(
                        (item, index) => {
                          return <p key={index}>{item?.description}</p>
                        }
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          {error && errorMessage !== "" && (
            <Alert className="text-danger" message={errorMessage} />
          )}

          {error && errorCode && (
            <Alert
              className="text-danger"
              message={`CorrelationId: ${errorCode}`}
            />
          )}

          {rentalResponseData.requestResult === 1 && (
            <>
              <div className="my-4">
                {returnCreateRequestValidationInfo.rentalInfos && (
                  <>
                    <div className="text-danger">
                      {returnCreateRequestValidationInfo.rentalInfos.map(
                        (item, index) => {
                          return <p key={index}>{item?.description}</p>
                        }
                      )}
                    </div>
                  </>
                )}
                <button
                  className="btn btn-light w-100 d-block"
                  onClick={handleOnModalClose}>
                  {t("Close and edit your order")}
                </button>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ActionFooter>
          <a
            href={`/${t("returns")}`}
            className="btn btn-primary w-100 d-block">
            {t("Return a new order")}
          </a>
        </ActionFooter>
      </Modal.Footer>
    </Modal>
  )
}

export default ResponseModal
