import React from "react"

const Loader = ({ className = "" }) => {
  if (className === "") {
    className = "d-flex align-items-center justify-content-center"
  }

  return (
    <div className={`${className}`}>
      <div
        className="spinner-border spinner-border-sm text-primary"
        role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default Loader
