const namespace = "HLL"

export default class Storage {
  static get(key, defaultValue, method) {
    // HLL state is always localStorage...
    if (key === "state" || key === "userState") {
      method = "local"
    }

    const storage = this.getStorage(method)
    let value = storage.getItem(`${namespace}.${key}`)

    if (value === undefined || value === null || value === "undefined") {
      value = defaultValue
    } else {
      value = JSON.parse(value)
    }

    return value
  }

  static set(key, value, method) {
    const storage = this.getStorage(method)
    return storage.setItem(`${namespace}.${key}`, JSON.stringify(value))
  }

  static remove(key, method) {
    const storage = this.getStorage(method)
    storage.removeItem(`${namespace}.${key}`)
  }

  static getStorage(method) {
    if (method === undefined) {
      method = "session"
    }

    switch (method) {
      case "local":
        return window.localStorage
      case "session":
        return window.sessionStorage
      default:
        return window.sessionStorage
    }
  }
}
