import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import { newOrderRows, newOrderInformation } from "./Store"
import SummaryHeader from "./SummaryHeader"
import Item from "./Common/Item"
import { previewItemMeta } from "./helpers"
import Storage from "../../utils/storage"
import { dateAndTimeNow } from "../../utils/helpers"

const NewOrderSummary = ({ data }) => {
  const { t } = useTranslation()
  const newOrderRowsState = useRecoilValue(newOrderRows)
  const newOrderInformationState = useRecoilValue(newOrderInformation)
  const userState = Storage.get("userState") ?? null
  const depot = userState?.internal?.depot?.name ?? ""

  useEffect(() => {
    if (data && newOrderRowsState && newOrderRowsState.length > 0) {
      window.dataLayer.push({
        event: "HLL_create_order",
        depot,
        orderId: data?.customerOrder?.customerOrderHead?.customerOrderId,
        orderNumber:
          data?.customerOrder?.customerOrderHead?.customerOrderNumber,
        createdAt: dateAndTimeNow(),
        userType: "internal",
        items: newOrderRowsState.map((orderRow) => {
          return {
            itemId: orderRow?.id,
            itemName: orderRow?.reference?.itemName,
            quantity: orderRow?.quantity
          }
        })
      })
    }
  }, [
    data,
    data?.customerOrderNumber,
    depot,
    newOrderInformationState,
    newOrderInformationState?.customerOrderId,
    newOrderRowsState
  ])

  return (
    <>
      <SummaryHeader data={data?.customerOrder?.customerOrderHead} />

      <h3 className="my-3 px-3">{t("The orders machines and addons")}</h3>

      <div className="bg-white">
        {newOrderRowsState &&
          newOrderRowsState.map((item, index) => (
            <Item
              key={index}
              index={index}
              title={item?.reference?.itemName}
              meta={previewItemMeta(item)}
              item={item}
              context="preview"
            />
          ))}
      </div>
    </>
  )
}

export default NewOrderSummary
