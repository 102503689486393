import React, { useState } from "react"
import { usePermissionCheck, useCompanyCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"
import ContactPersons from "../../components/ContactPersons/ContactPersons"
import Storage from "../../utils/storage"

const RentalPermissions = () => {
  const hasPermission = usePermissionCheck("MinaSidor.BehorigatthyraAdmin")
  const hasHLLInternPermission = usePermissionCheck("MinaSidor.SkapaRetur")
  const companyPermissions = useCompanyCheck()

  const [customer] = useState(
    Storage.get("customer") ? Storage.get("customer") : false
  )

  const { companyType } = customer

  if (!hasPermission && !companyPermissions) {
    return <PageNotFound noPermission={true} />
  }

  if (hasHLLInternPermission) {
    return <PageNotFound noPermission={true} />
  }

  if (companyType === 1) {
    return <PageNotFound />
  }

  return <ContactPersons />
}

export default RentalPermissions
