import React, { useEffect } from "react"
import { useRecoilValue } from "recoil"
import { machineState, pageTypeState, errorState } from "../Store"
import { useTranslation } from "react-i18next"
import SummaryItem from "./SummaryItem"
import { dateAndTimeNow, groupMachinesByBranch } from "../../../utils/helpers"
import Storage from "../../../utils/storage"

const Summary = ({ transferData }) => {
  const { t } = useTranslation()
  const pageType = useRecoilValue(pageTypeState)
  let machinesFromState = useRecoilValue(machineState)
  const errorText = useRecoilValue(errorState)
  const userState = Storage.get("userState") ?? null
  const depot = userState?.internal?.depot?.name ?? ""

  machinesFromState = machinesFromState.filter((machine) => {
    if (pageType === "incoming") {
      return machine.checkedAsIncoming
    }

    return machine
  })

  const machines = groupMachinesByBranch(machinesFromState)

  useEffect(() => {
    let formattedItems = []

    if (pageType === "transfer") {
      for (let index = 0; index < machines.length; index++) {
        formattedItems.push({
          toDepot: machines[index]?.newBranchName,
          items: []
        })

        for (let index2 = 0; index2 < machines[index].items.length; index2++) {
          formattedItems[index].items.push({
            itemName: machines[index]?.items[index2]?.name,
            itemNumber: machines[index]?.items[index2]?.number,
            quantity: machines[index]?.items[index2]?.quantity
          })
        }
      }

      window.dataLayer.push({
        event: "HLL_machine_transfer",
        depot,
        createdAt: dateAndTimeNow(),
        items: formattedItems,
        userType: "internal"
      })
    } else {
      for (let index = 0; index < machines.length; index++) {
        formattedItems.push({
          fromDepot: machines[index]?.newBranchName,
          items: []
        })

        for (let index2 = 0; index2 < machines[index].items.length; index2++) {
          formattedItems[index].items.push({
            itemName: machines[index]?.items[index2]?.name,
            itemNumber: machines[index]?.items[index2]?.number
              ? machines[index]?.items[index2]?.number
              : machines[index]?.items[index2]?.articleNumber,
            collectedAmount: machines[index]?.items[index2]?.amountToAcknowledge
          })
        }
      }

      window.dataLayer.push({
        event: "HLL_machine_collect",
        depot,
        createdAt: dateAndTimeNow(),
        items: formattedItems,
        userType: "internal"
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depot, pageType])

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h3 className="text-center mb-5 d-flex flex-column">
            <>
              <span
                className={`fe fe-${
                  errorText !== "" ? "info" : "check-circle"
                } text-primary mb-3`}
                style={{ fontSize: "50px" }}></span>
              <span>
                {errorText !== ""
                  ? errorText
                  : pageType === "transfer"
                  ? t("Your transfer has been started")
                  : t("Machines acknowledged")}
              </span>
            </>
          </h3>
        </div>
        {errorText === "" && (
          <div className="col-12">
            {machines &&
              machines.map((machine, index) => {
                const transferNoteNr =
                  pageType === "transfer" ? transferData[index]?.number : ""
                return (
                  <SummaryItem
                    key={index}
                    machine={machine}
                    transferNoteNr={transferNoteNr}
                  />
                )
              })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Summary
