import React, { useEffect, useState } from "react"
import useTransportOrderContext from "../../context/TransportOrder/Store"
import { useTranslation } from "react-i18next"
import { useOidcUser } from "@axa-fr/react-oidc"
import { useLocation, useNavigate } from "react-router-dom"
import { Dropdown } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"

import CustomDropDownToggle from "../../components/Common/CustomDropDownToggle"
import CustomDropdownMenu from "../../components/Common/CustomDropdownMenu"

import axios from "axios"
import moment from "moment"

import TransportOrderModal from "./TransportOrderModal"

import SkeletonLoader from "../../components/Common/SkeletonLoader"

import Storage from "../../utils/storage"

import { usePermissionCheck, useEnviromentCheck } from "../../utils/hooks"
import DeleteProjectModal from "./DeleteProjectModal"
import NotEditableProjectModal from "./NotEditableProjectModal"
import BlockProjectModal from "./BlockProjectModal"

import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"

import { useParams } from "react-router-dom"
import toast from "react-hot-toast"
import { dateAndTimeNow } from "../../utils/helpers"

import { setUserSettings } from "../../utils/hooks"
import CustomSvgIcon from "../../components/Common/CustomSvgIcon"

const ProjectHeader = ({
  data,
  dataLoading,
  setDataLoading,
  activeType,
  navLinkHandler,
  setAllChecked,
  setCheckedItems
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { oidcUser } = useOidcUser()
  const customer = Storage.get("customer", null, "local") ?? null
  const userState = Storage.get("userState", null, "local") ?? null
  const isStagingEnviroment = useEnviromentCheck()
  const { orderData, setOrderData } = useTransportOrderContext()
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location?.pathname
  const [showTransportOrderModal, setShowTransportOrderModal] = useState(false)
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false)
  const [showNotEditableProjectModal, setShowNotEditableProjectModal] =
    useState(false)

  const [showBlockProjectModal, setBlockProjectModal] = useState(false)

  const [returnStatus, showReturnStatus] = useState(false)
  const [returnMessage, showReturnMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [updatingUserState, setUpdatingUserState] = useState(false)
  const [favoriteProjects, setFavoriteProjects] = useState(
    userState?.projects?.favorites ?? []
  )

  const { meta, all, rented, returned, purchase } = data
  const { name, number, address } = meta
  const hasAdminSitePermission = usePermissionCheck(
    "MinaSidor.PlatserAdministrera"
  )
  const hasCompanyPermission = usePermissionCheck("MinaSidor.DotterbolagLäs")

  const handleShowTransportOrderModal = () => setShowTransportOrderModal(true)
  const handleShowDeleteProjectModal = () => setShowDeleteProjectModal(true)
  const handleShowNotEditableProjectModal = () =>
    setShowNotEditableProjectModal(true)

  const handleShowBlockProjectModal = () => setBlockProjectModal(true)

  const handleCancel = () => {
    setOrderData([])
    setAllChecked(false)
    setCheckedItems([])
    setShowTransportOrderModal(false)
    setShowDeleteProjectModal(false)
    setShowNotEditableProjectModal(false)
    setShowNotEditableProjectModal(false)
    showReturnStatus(false)
  }

  const handleBlockProject = async (condition) => {
    setDataLoading(true)
    setBlockProjectModal(false)

    const formattedData = data?.meta
    formattedData.isBlockedForOrder = condition

    await axios
      .post(`/api/hll/editProject`, {
        id,
        body: formattedData
      })
      .then((response) => {
        if (response) {
          if (condition === true) {
            toast.success(t("The project is blocked"))
          } else {
            toast.success(t("The project is unblocked"))
          }
        }
      })
      .catch((error) => {
        console.error(error)
        toast.error(t("Something went wrong, try again."))
      })
      .finally(() => {
        setDataLoading(false)
      })
  }

  const handleSendBookingReturn = async (e, userData) => {
    e.preventDefault()
    setLoading(true)

    const { latitude, longitude, address, date, timeFrom, timeTo, phone } =
      userData
    const dateFormat = date.toLocaleDateString("sv-SE")

    const orderItems = orderData.map((item) => ({
      id: item?.id,
      fleetModelId: item?.reference?.id,
      name: item?.reference?.itemName
    }))

    const dateTime = moment(`${dateFormat} ' ' ${timeTo}`, "YYYY-MM-DD HH:mm")
    const company = `${customer.name} - ${customer.corporateIdentityNumber}`
    const displayName = oidcUser?.name
    const email = oidcUser?.preferred_username

    let data = {
      id: Math.random().toString(36).substr(2, 22),
      deliveryAddress: {
        adressLine1: address || null,
        coordinates: {
          lat: latitude.toString(),
          long: longitude.toString()
        }
      },
      customerName: company || null,
      contactPerson: displayName || null,
      contactPersonPhone: phone,
      fleetItems: orderItems,
      scheduledAt: dateTime.format("YYYY-MM-DDTHH:mm:ssZ"),
      additionalInformation: "HLL Retur - Mitt HLL",
      team: {
        id: "16403",
        name: "Webbokningar"
      },
      transportOrderType: "RETURN"
    }

    try {
      let request = await axios
        .post("/api/hll/transportOrder", {
          data: data
        })
        .then(async (res) => {
          //console.log("res", res)

          const orderID = res.data
          const orderData = data

          const { deliveryAddress, contactPerson, customerName } = orderData

          const customerEmail = email // Get from currentUser

          let mailData = {
            orderID: orderID,
            contactPerson: contactPerson,
            customerName: customerName,
            customerEmail: customerEmail,
            address: deliveryAddress.adressLine1,
            date: date,
            timeFrom: timeFrom,
            timeTo: timeTo,
            phone: phone,
            equipments: orderItems
          }

          try {
            let request = await axios
              .post("/api/postmark/transportOrder", mailData)
              .then((res) => {
                //console.log(res, 'res: /api/postmark/transportOrder"');
                setLoading(false)
                showReturnMessage(
                  t(
                    "Thanks! We have received your return. Check your email for more information."
                  )
                )
                showReturnStatus(true)

                window.dataLayer.push({
                  event: "HLL_project_create_return",
                  company: customer?.name,
                  orderId: orderID,
                  date,
                  timeFrom,
                  timeTo,
                  equipment: orderItems,
                  createdAt: dateAndTimeNow(),
                  userType: "customer"
                })

                return res
              })
            return request.status === 200 ? true : false
          } catch (error) {
            setLoading(false)
            showReturnMessage(
              t("Something went wrong with sending you mail...Try again.")
            )
            showReturnStatus(true)
          }

          //console.log(res, 'res: /api/hll/transportOrder');
          return res
        })
      return request.status === 200 ? true : false
    } catch (error) {
      setLoading(false)
      showReturnMessage(
        t("Something went wrong with adding your return. Try again.")
      )
      showReturnStatus(true)
    }
  }

  const renderBlockedTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("This project is blocked for new orders")}
    </Tooltip>
  )

  const handleSaveFavoriteProjects = (id) => {
    const item = data?.meta
    const exists = favoriteProjects.find((item) => item?.id === id)
    if (exists) {
      setFavoriteProjects((current) => current.filter((item) => item.id !== id))
      toast.success(t("The project is now removed from favorites"))
    } else {
      setFavoriteProjects((currentArr) => [...currentArr, item])
      toast.success(t("The project is now added as a favorite"))

      window.dataLayer.push({
        event: "HLL_favorite_projects",
        company: customer?.name,
        projectName: `${item?.name} ${item?.number}`,
        userType: "customer"
      })
    }

    setUpdatingUserState(true)
  }

  useEffect(() => {
    if (updatingUserState) {
      setUserSettings({
        ...userState,
        projects: {
          favorites: favoriteProjects
        }
      })
      Storage.set(
        "userState",
        {
          ...userState,
          projects: {
            favorites: favoriteProjects
          }
        },
        "local"
      )
      setUpdatingUserState(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteProjects, updatingUserState, userState])

  const { isEditable, isBlockedForOrder } = data?.meta

  return (
    <>
      {orderData.length > 0 && (
        <TransportOrderModal
          handleCancel={handleCancel}
          show={showTransportOrderModal}
          handleSendBookingReturn={handleSendBookingReturn}
          orderData={orderData}
          returnStatus={returnStatus}
          returnMessage={returnMessage}
          loading={loading}
        />
      )}

      <DeleteProjectModal
        handleCancel={handleCancel}
        show={showDeleteProjectModal}
        setShowDeleteProjectModal={setShowDeleteProjectModal}
        isEditable={isEditable}
      />

      <NotEditableProjectModal
        handleCancel={handleCancel}
        show={showNotEditableProjectModal}
      />

      <BlockProjectModal
        handleCancel={handleCancel}
        show={showBlockProjectModal}
        isBlockedForOrder={isBlockedForOrder}
        handleBlockProject={handleBlockProject}
      />

      <div className="row">
        <div className="col-12">
          <div className="header">
            <div className="header-body d-md-flex align-items-center mb-4 mb-md-0 border-bottom-0 pb-0">
              <div className="col">
                <div className="mb-3 mb-sm-0">
                  <h6 className="header-pretitle">{`${t("My pages")} - ${t(
                    "Project overview"
                  )}`}</h6>
                  <h1 className="header-title text-truncate">
                    {dataLoading ? (
                      <SkeletonLoader className="py-0 pt-2" />
                    ) : (
                      <>
                        {number && number}
                        {name && ` ${name}`}
                      </>
                    )}
                  </h1>

                  <div className="d-flex align-items-center mt-2">
                    <div>
                      {dataLoading ? (
                        <SkeletonLoader className="py-0" />
                      ) : (
                        <p className="mb-0">
                          {`${address?.adressLine1 ?? address?.adressLine1}, ${
                            address?.postalCode ?? address?.postalCode
                          }, ${address?.city ?? address?.city}`}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {!dataLoading && (
                <div className="col-12 col-md-auto mt-2 mt-md-0">
                  <div className="d-flex align-items-md-center justify-content-md-center">
                    {isBlockedForOrder && (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderBlockedTooltip}>
                        <div className="text-primary mr-3">
                          <CustomSvgIcon width={16} height={16} name="lock" />
                        </div>
                      </OverlayTrigger>
                    )}
                    {orderData.length > 0 && (
                      <button
                        className="btn btn-primary ml-md-auto"
                        onClick={handleShowTransportOrderModal}>
                        {t("Book return")} {`(${orderData.length} st)`}
                      </button>
                    )}
                    {orderData.length === 0 && (
                      <button
                        className="btn btn-light ml-md-auto"
                        disabled={true}>
                        {t("Book return")}
                      </button>
                    )}

                    {hasAdminSitePermission && (
                      <Dropdown className="ml-3 ml-3 dropdown d-flex align-items-center">
                        <Dropdown.Toggle as={CustomDropDownToggle}>
                          <span className="d-block svg-icon-wrapper">
                            <CustomSvgIcon
                              name="dot-navigation"
                              width={24}
                              height={24}
                              className={`text-muted`}
                            />
                          </span>
                        </Dropdown.Toggle>

                        <DropdownMenu
                          as={CustomDropdownMenu}
                          className="position-fixed"
                          style={{ zIndex: "999" }}>
                          {isEditable ? (
                            <DropdownItem
                              onClick={() =>
                                navigate(`${currentPath}/${t("edit")}`)
                              }>
                              {t("Edit")}
                            </DropdownItem>
                          ) : (
                            <DropdownItem
                              onClick={() =>
                                handleShowNotEditableProjectModal()
                              }>
                              {t("Edit")}
                            </DropdownItem>
                          )}

                          {isBlockedForOrder ? (
                            <DropdownItem
                              onClick={() => handleShowBlockProjectModal()}>
                              {t("Unblock project")}
                            </DropdownItem>
                          ) : (
                            <DropdownItem
                              onClick={() => handleShowBlockProjectModal()}>
                              {t("Block project")}
                            </DropdownItem>
                          )}

                          <DropdownItem
                            onClick={() => handleSaveFavoriteProjects(id)}>
                            {favoriteProjects.find(
                              (i) =>
                                i.id === id && i?.customerId === customer?.id
                            )
                              ? t("Remove as favorite project")
                              : t("Select as favorite project")}
                          </DropdownItem>

                          {hasCompanyPermission && isStagingEnviroment && (
                            <DropdownItem
                              onClick={() =>
                                navigate(
                                  `${currentPath}/${t("document-recipients")}`,
                                  { state: { name, number } }
                                )
                              }>
                              {t("Document recipients")}
                            </DropdownItem>
                          )}

                          <DropdownItem
                            className="text-danger"
                            onClick={() => handleShowDeleteProjectModal()}>
                            {t("Delete project")}
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  </div>
                </div>
              )}
            </div>
            {!dataLoading && (
              <div className="header-body pt-0">
                <div className="row align-items-center">
                  <div className="col">
                    <ul className="nav nav-tabs nav-overflow header-tabs">
                      <li className="nav-item">
                        <a
                          href="#!"
                          id="rented-tab"
                          className={`d-flex nav-link text-nowrap align-items-center${
                            activeType === "rented" ? " active" : ""
                          }`}
                          data-name="rented"
                          onClick={navLinkHandler}>
                          {t("Leased")}
                          <span className="ml-2 badge bg-light disabled">
                            {rented.length}
                          </span>
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          href="#!"
                          id="returned-tab"
                          className={`d-flex nav-link text-nowrap align-items-center${
                            activeType === "returned" ? " active" : ""
                          }`}
                          data-name="returned"
                          onClick={navLinkHandler}>
                          {t("Returned")}
                          <span className="ml-2 badge bg-light disabled">
                            {returned.length}
                          </span>
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          href="#!"
                          id="purchase-tab"
                          className={`d-flex nav-link text-nowrap align-items-center${
                            activeType === "purchase" ? " active" : ""
                          }`}
                          data-name="purchase"
                          onClick={navLinkHandler}>
                          {t("Purchase")}
                          <span className="ml-2 badge bg-light disabled">
                            {purchase.length}
                          </span>
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          href="#!"
                          id="all-tab"
                          className={`d-flex nav-link text-nowrap align-items-center${
                            activeType === "all" ? " active" : ""
                          }`}
                          data-name="all"
                          onClick={navLinkHandler}>
                          {t("All")}
                          <span className="ml-2 badge bg-light disabled">
                            {all.length}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectHeader
