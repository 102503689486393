import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ActionFooter from "../../Common/ActionFooter"
import {
  machineState,
  pageTypeState,
  viewState,
  errorState,
  incomingMachineState
} from "../Store"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import axios from "axios"
import Storage from "../../../utils/storage"
import { useNavigate } from "react-router-dom"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const Actions = ({ handleModalShow, setTransferData }) => {
  const { t } = useTranslation()
  const [machines, setMachines] = useRecoilState(machineState)
  const [view, setView] = useRecoilState(viewState)
  const setErrorState = useSetRecoilState(errorState)
  const pageType = useRecoilValue(pageTypeState)
  const incomingMachines = useRecoilValue(incomingMachineState)
  const [loading, setLoading] = useState(false)
  const userState = Storage.get("userState") ?? null
  const navigate = useNavigate()

  let machinesFromState = useRecoilValue(machineState)

  machinesFromState = machinesFromState.filter((machine) => {
    if (pageType === "incoming") {
      return machine?.checkedAsIncoming && machine?.amountToAcknowledge > 0
    }

    return machine
  })

  function continueBtnIsDisabled() {
    if (pageType === "incoming") {
      return (
        machines.filter((machine) => machine.checkedAsIncoming).length === 0
      )
    }

    if (machines.length === 0) {
      return true
    }

    const emptyBranches = machines.filter(
      (machine) => machine.newBranchName === ""
    )

    return emptyBranches.length > 0 ? true : false
  }

  const handleConfirm = async () => {
    setLoading(true)
    setErrorState("")

    const date = new Date()

    // Transfer
    if (pageType === "transfer") {
      let body = []
      let transferBranches = []

      for (let machineItem in machinesFromState) {
        const item = machinesFromState[machineItem]

        if (item?.internalType === "ARTICLE") {
          transferBranches.push({
            fromBranch: userState?.internal?.depot?.id,
            toBranch: item.newBranchId
          })
        } else {
          transferBranches.push({
            fromBranch: item.branchId,
            toBranch: item.newBranchId
          })
        }
      }

      let filteredTransferBranches = [
        ...new Map(
          transferBranches.map((item) => [item["toBranch"], item])
        ).values()
      ]

      for (let branch in filteredTransferBranches) {
        const branchItem = filteredTransferBranches[branch]

        body.push({
          fromBranch: {
            id: branchItem.fromBranch
          },
          toBranch: { id: branchItem.toBranch },
          createdAt: `${date.toLocaleDateString(
            "sv-se"
          )}T${date.toLocaleTimeString("sv-se")}Z`,
          fleetItemsToTransfer: machinesFromState
            .filter(
              (machine) =>
                machine.internalType === "INDIVIDUAL" &&
                machine.branchId === branchItem.fromBranch &&
                machine.newBranchId === branchItem.toBranch
            )
            .map((fleetItem, index) => {
              return {
                sort: index + 1,
                fleetItemId: fleetItem.id,
                unit: fleetItem?.unit ?? "st",
                amount: fleetItem.quantity
              }
            }),
          articlesToTransfer: machinesFromState
            .filter(
              (machine) =>
                machine.internalType === "ARTICLE" &&
                machine.branchId === branchItem.fromBranch &&
                machine.newBranchId === branchItem.toBranch
            )
            .map((article, index) => {
              return {
                sort: index + 1,
                fleetModelId: article.fleetModelId,
                unit: article?.unit ?? "st",
                amount: article.quantity,
                articleNumber: article?.number,
                articleId: article?.id
              }
            })
        })
      }

      await axios
        .post("/api/hll/equipmentTransfer", {
          body
        })
        .then((response) => {
          if (response.data && response.status === 200) {
            setTransferData(response.data)
          }
        })
        .catch((error) => {
          setErrorState(
            `Something went wrong. Correlation ID: ${error.response.data.CorrelationId}`
          )
        })
        .finally(() => {
          setLoading(false)
          setView("summary")
        })
    }
    // Incoming
    else {
      const incomingTransfers = incomingMachines

      let filteredIncomingMachines = []
      let body = []

      incomingTransfers.forEach((transfer) => {
        let fleetItems = []
        let articles = []
        let hasMachineInState = false
        let hasArticleInState = false

        transfer.fleetItemsToTransfer.forEach((fleetItem) => {
          const machineInState =
            machinesFromState.find(
              (item) =>
                item.fleetItemId === fleetItem?.fleetItemId &&
                transfer.id === item?.equipmentTransferId
            ) ?? null

          if (machineInState) {
            hasMachineInState = true
          }

          fleetItems.push({
            equipmentTransferId: fleetItem?.equipmentTransferId,
            sort: fleetItem?.sort,
            fleetItemNumber: fleetItem?.fleetItemNumber,
            fleetItemId: fleetItem?.fleetItemId,
            name: fleetItem?.name,
            unit: fleetItem?.unit,
            fleetModelId: fleetItem?.fleetModelId,
            recivedAmount: hasMachineInState
              ? Number(fleetItem?.recivedAmount) +
                Number(machineInState?.amountToAcknowledge ?? 0)
              : 0,
            inTransfer: fleetItem?.inTransfer,
            sentToBranch: fleetItem?.sentToBranch,
            note: fleetItem?.note
          })
        })

        transfer.articlesToTransfer.forEach((article) => {
          const articleInState =
            machinesFromState.find(
              (item) =>
                item.articleId === article?.articleId &&
                transfer.id === item?.equipmentTransferId
            ) ?? null

          if (articleInState) {
            hasArticleInState = true
          }

          articles.push({
            equipmentTransferId: article?.equipmentTransferId,
            sort: article?.sort,
            articleNumber: article?.articleNumber,
            articleId: article?.articleId,
            name: article?.name,
            unit: article?.unit,
            fleetModelId: article?.fleetModelId,
            amount: article?.amount,
            recivedAmount: articleInState
              ? Number(article?.recivedAmount) +
                Number(articleInState?.amountToAcknowledge ?? 0)
              : 0,
            sentToBranch: article?.sentToBranch,
            note: article?.note
          })
        })

        if (hasMachineInState || hasArticleInState) {
          filteredIncomingMachines.push({
            ...transfer,
            fleetItemsToTransfer: fleetItems,
            articlesToTransfer: articles
          })
        }
      })

      body = filteredIncomingMachines

      if (body) {
        const incomingRequest = async (item) => {
          await axios
            .post("/api/hll/updateEquipmentTransfer", {
              body: item
            })
            .catch((error) => {
              console.error(error)
              setErrorState(
                `Something went wrong. Correlation ID: ${error.response.data.CorrelationId}`
              )
            })
            .finally(() => {
              setLoading(false)
              setView("summary")
            })
        }

        body.forEach((item) => {
          incomingRequest(item)
        })
      }
    }
  }

  return (
    <ActionFooter>
      {pageType === "transfer" && view === "list" && (
        <>
          <button
            className="border rounded text-primary bg-white p-0 d-flex align-items-center justify-content-center"
            style={{ width: 40, height: 40 }}
            onClick={() => handleModalShow("search")}>
            {pageType === "transfer" ? (
              <CustomSvgIcon name="add" width={20} height={20} />
            ) : (
              <CustomSvgIcon name="search" width={20} height={20} />
            )}
          </button>

          <button
            className={`border rounded text-primary bg-white d-flex align-items-center justify-content-center p-0 ml-3${
              machines.length === 0 ? " btn-disabled" : ""
            }`}
            style={{ width: 40, height: 40 }}
            disabled={machines.length === 0}
            onClick={() => handleModalShow("depot")}>
            <CustomSvgIcon name="depo" width={20} height={20} />
          </button>
        </>
      )}
      {view === "list" && (
        <button
          className="btn btn-primary ml-auto"
          disabled={continueBtnIsDisabled()}
          onClick={() => setView("confirmation")}>
          {pageType === "transfer" ? t("Move machines/articles") : t("Preview")}
        </button>
      )}
      {view === "confirmation" && (
        <div className="row">
          <div className="col-6">
            <button
              disabled={loading}
              className="btn btn-outline-secondary w-100"
              onClick={() => {
                setView("list")
                setErrorState("")
              }}>
              {t("Back")}
            </button>
          </div>
          <div className="col-6">
            <button
              disabled={loading}
              className="btn btn-primary w-100"
              onClick={handleConfirm}>
              {loading ? (
                <div
                  className="spinner-grow spinner-grow-sm text-white position-relative"
                  role="status"
                  style={{ top: "-2px" }}>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : pageType === "transfer" ? (
                t("Confirm")
              ) : (
                t("Acknowledge machines")
              )}
            </button>
          </div>
        </div>
      )}
      {view === "summary" && (
        <button
          className="btn btn-primary w-100"
          onClick={() => {
            if (pageType === "transfer") {
              setView("list")
              setMachines([])
            } else {
              navigate(0)
            }
          }}>
          {t("Close")}
        </button>
      )}
    </ActionFooter>
  )
}

export default Actions
