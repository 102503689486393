import React, { useState, useEffect } from "react"
import axios from "axios"
import { useOidcUser } from "@axa-fr/react-oidc"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import MachineBranch from "./Steps/MachineBranch"
import MachineControlNotes from "./Steps/MachineControlNotes"
import MachineInspections from "./Steps/MachineInspections"
import useMachineContext from "../../../context/Machine/Store"
import Storage from "../../../utils/storage"
import ActionFooter from "../../Common/ActionFooter"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const ConfirmModal = ({
  open,
  setModalOpen,
  activeStepIndex,
  inspection,
  currentDepotID,
  currentBranchName,
  setNewDepot,
  nextStepIndex,
  onSavedHandler,
  currentAvailability,
  newDepot
}) => {
  const { t } = useTranslation()
  const [stepIndex, setStepIndex] = useState(0)
  const [branchIsLoading, setBranchIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [controlNote, setControlNote] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const { machineItem } = useMachineContext()
  const { oidcUser } = useOidcUser()
  const userState = Storage.get("userState") ?? null

  useEffect(() => {
    setStepIndex(activeStepIndex)
  }, [activeStepIndex])

  const handleControlNoteChange = (event) => {
    setControlNote(event.target.value)
  }

  const handleSave = async () => {
    setLoading(true)

    let controlNotePost = null

    if (controlNote !== "") {
      const date = new Date()
      const createdAt = `${date.toLocaleDateString(
        "sv-se"
      )}T${date.toLocaleTimeString("sv-se")}Z`
      const createdBy = oidcUser?.name

      controlNotePost = await axios.post("/api/hll/inspectionNote", {
        id: machineItem.id,
        body: {
          createdAt,
          createdBy,
          inspectionInfo: controlNote
        }
      })

      window.dataLayer.push({
        event: "HLL_machine_control_note_created",
        itemName: machineItem?.name,
        itemNumber: machineItem?.number,
        depot: userState?.internal?.depot?.name,
        createdAt,
        userType: "internal"
      })
    }

    if (controlNotePost && controlNotePost?.status !== 200) {
      setErrorMessage(
        t(
          "Something went wrong when saving the control note, please try again or leave empty to proceed."
        )
      )
      setLoading(false)
      return
    }

    await axios
      .post("/api/hll/fleetItemEdit", {
        body: {
          id: machineItem.id,
          fleetModelId: machineItem.fleetModelId,
          name: machineItem.name,
          number: machineItem.number,
          branchId: newDepot.id,
          status: machineItem?.status < 0 ? 1 : machineItem.status, // If status is less than 0 we need to set status to 1
          availability: currentAvailability,
          branchName: newDepot.name
        }
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          const { availability, branchName } = response.data

          onSavedHandler({
            availability,
            branchName
          })
        } else {
          onSavedHandler({
            hasError: true,
            errorMessage: t("Something went wrong")
          })
        }
      })
      .catch((error) => {
        console.error(error)
        onSavedHandler({
          hasError: true,
          errorMessage: `Error code: ${error.response.data.CorrelationId}`
        })
      })
      .finally(() => {
        setLoading(false)
        setModalOpen(false)
        setStepIndex(activeStepIndex)
      })
  }

  const handleHide = () => {
    setModalOpen(false)
    setStepIndex(activeStepIndex)
    setControlNote("")
  }

  const modalHeader = [
    t("Replacement of depot"),
    t("Inspection is approaching"),
    t("Create new control note")
  ]

  return (
    <Modal
      show={open}
      centered
      animation={false}
      backdrop="static"
      keyboard={false}
      fullscreen="true"
      className={"modal-scrollable"}>
      <Modal.Header className="d-block p-3">
        <div className="position-relative">
          <h3 className="header-title mt-3 mb-0 text-center">
            {modalHeader[stepIndex]}
          </h3>
          <button
            onClick={handleHide}
            className="bg-transparent position-absolute p-0 text-body"
            style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
            <CustomSvgIcon
              name="close"
              width={16}
              height={16}
              className="text-black"
            />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        {stepIndex === 0 && (
          <MachineBranch
            currentDepotID={currentDepotID}
            currentBranchName={currentBranchName}
            setNewDepot={setNewDepot}
            setBranchIsLoading={setBranchIsLoading}
          />
        )}
        {stepIndex === 1 && <MachineInspections inspection={inspection} />}
        {stepIndex === 2 && (
          <MachineControlNotes onChangeHandler={handleControlNoteChange} />
        )}
        {errorMessage && <p className="mt-3 text-danger">{errorMessage}</p>}
      </Modal.Body>
      <ActionFooter>
        <button
          className="btn btn-outline-secondary w-100 d-block mr-3"
          onClick={() => handleHide()}
          disabled={loading || branchIsLoading}>
          {t("Close")}
        </button>
        {stepIndex === 2 ? (
          <button
            className="btn btn-primary w-100 d-block"
            onClick={handleSave}
            disabled={loading}>
            {loading ? (
              <div
                className="spinner-grow spinner-grow-sm text-white"
                role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              t("Save")
            )}
          </button>
        ) : (
          <button
            className="btn btn-primary w-100 d-block"
            onClick={() => setStepIndex(stepIndex === 1 ? 2 : nextStepIndex)}
            disabled={branchIsLoading}>
            {t("Continue")}
          </button>
        )}
      </ActionFooter>
    </Modal>
  )
}

export default ConfirmModal
