import React, { useEffect, useState } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"

import { usePermissionCheck } from "../../utils/hooks"

import PageNotFound from "../PageNotFound/PageNotFound"

import ProjectEdit from "../../components/ProjectEdit/ProjectEdit"

const EditProject = () => {
  const { id } = useParams()
  const hasAdminSitePermission = usePermissionCheck(
    "MinaSidor.PlatserAdministrera"
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    setLoading(true)
    setError(false)

    const requestProject = async () => {
      await axios
        .post("/api/hll/project", { id })
        .then((response) => {
          setData(response?.data)
        })
        .catch((err) => {
          //console.log(err, "Error: api/hll/project")
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    requestProject()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const res = data ? data : null

  if (!hasAdminSitePermission) {
    return <PageNotFound noPermission={true} />
  }

  return <ProjectEdit data={res} loading={loading} error={error} />
}

export default EditProject
