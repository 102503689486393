import React, { useEffect, useState } from "react"
import * as ReactDOMClient from "react-dom/client"
import "./i18n"
import { BrowserRouter as Router } from "react-router-dom"
import { OidcProvider, OidcSecure } from "@axa-fr/react-oidc"

import oidcConfigs from "./configs/oidc"

import PageLoader from "./components/PageLoader/PageLoader"
import SessionLost from "./pages/SessionLost/SessionLost"
import NotAuthenticated from "./pages/NotAuthenticated/NotAuthenticated"
import ErrorOnAuthenticate from "./pages/ErrorOnAuthenticate/ErrorOnAuthenticate"
import Routes from "./Router/Routes"
import { getMaintenance } from "./utils/hooks"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import Maintenance from "./pages/Maintenance/Maintenance"

import TagManager from "react-gtm-module"

import "./App.scss"

import NotificationContainer from "./utils/notification"

// Create a react query client.
const queryClient = new QueryClient()

const App = () => {
  const [maintenanceMode, setMaintenanceMode] = useState(false)
  const [ready, setReady] = useState(false)
  const [maintenanceContent, setMaintenanceContent] = useState("")

  const checkMaintenance = async () => {
    const response = await getMaintenance()
    if (response?.status !== 200) {
      setMaintenanceMode(false)
      setMaintenanceContent("")
      setReady(true)
    }

    setMaintenanceMode(response?.maintenanceMode)
    setMaintenanceContent(response?.maintenanceContent)
    setReady(true)
  }

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-WD8F2T9" })
  }, [])

  useEffect(() => {
    checkMaintenance()
  }, [])

  if (!maintenanceMode && ready) {
    return (
      <>
        <OidcProvider
          configuration={oidcConfigs}
          authenticatingComponent={PageLoader}
          authenticatingErrorComponent={ErrorOnAuthenticate}
          callbackSuccessComponent={PageLoader}
          callbackErrorComponent={NotAuthenticated}
          loadingComponent={PageLoader}
          sessionLostComponent={SessionLost}>
          <OidcSecure>
            <NotificationContainer />
            <QueryClientProvider client={queryClient}>
              <Router>
                <Routes />
              </Router>
            </QueryClientProvider>
          </OidcSecure>
        </OidcProvider>
      </>
    )
  }

  return <Maintenance content={maintenanceMode && maintenanceContent} />
}

const container = document.getElementById("root")
// Create a root.
const root = ReactDOMClient.createRoot(container)
root.render(<App />)
