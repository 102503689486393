import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useSetRecoilState } from "recoil"
import { currentMachineList } from "../../Store"
import {
  priceWithCurrencyHelper,
  priceUnitHelper,
  offerTypeMap
} from "../../../../../utils/helpers"

import CustomSvgIcon from "../../../../Common/CustomSvgIcon"

export default function AccordionData({
  machineGroupId,
  accordionState,
  id,
  name,
  quantity,
  cost,
  orderRowType,
  selected,
  priceData = null,
  setIsUpdating
}) {
  const { t } = useTranslation()
  const setCurrentMachineListState = useSetRecoilState(currentMachineList)

  const [quantityState, setQuantityState] = useState(0)

  const handleQuantity = (type, view) => {
    setQuantityState(type === "add" ? quantityState + 1 : quantityState - 1)

    if (view === "accessories") {
      setCurrentMachineListState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          machines: prevState.data.machines.map((machine) => {
            if (machine?.id === machineGroupId) {
              return {
                ...machine,
                acf: {
                  ...machine.acf,
                  accessories:
                    machine?.acf?.accessories &&
                    machine?.acf?.accessories.map((accessory) =>
                      accessory?.id === id
                        ? {
                            ...accessory,
                            quantity:
                              type === "add"
                                ? parseFloat(quantityState + 1)
                                : parseFloat(quantityState - 1)
                          }
                        : accessory
                    )
                }
              }
            } else {
              return machine
            }
          })
        }
      }))
    } else {
      setCurrentMachineListState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          machines: prevState.data.machines.map((machine) => {
            if (machine?.id === machineGroupId) {
              return {
                ...machine,
                acf: {
                  ...machine.acf,
                  services:
                    machine?.acf?.services &&
                    machine?.acf?.services.map((service) =>
                      service?.id === id
                        ? {
                            ...service,
                            quantity:
                              type === "add"
                                ? parseFloat(quantityState + 1)
                                : parseFloat(quantityState - 1)
                          }
                        : service
                    )
                }
              }
            } else {
              return machine
            }
          })
        }
      }))
    }

    setIsUpdating(true)
  }

  useEffect(() => {
    setQuantityState(quantity ? quantity : 0)
  }, [quantity])

  return (
    <div className="border-bottom py-1 py-lg-2">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <span className="d-flex align-items-center">
            {name && <span className="d-none d-lg-block mr-2">{name}</span>}
            {name && <small className="d-block d-lg-none mr-2">{name}</small>}
          </span>
        </div>

        <div className="d-flex align-items-center justify-content-center ml-4">
          <div className="cart-card__actions">
            <div className="d-flex align-items-center justify-content-center">
              <span className="d-none d-lg-block">
                {priceWithCurrencyHelper(cost)}
                {priceData &&
                  priceUnitHelper(
                    priceData?.priceUnit ? priceData?.priceUnit : ""
                  )}
              </span>
              <small className="d-block d-lg-none">
                {priceWithCurrencyHelper(cost)}
                {priceData &&
                  priceUnitHelper(
                    priceData?.priceUnit ? priceData?.priceUnit : ""
                  )}
              </small>
            </div>
            <div className="py-2 px-3 bg-lighter rounded d-flex align-items-center justify-content-center">
              <button
                disabled={quantityState === 0}
                className={`d-flex align-items-center bg-transparent p-0 m-0$`}
                onClick={() => {
                  handleQuantity("remove", accordionState?.view)
                }}>
                <CustomSvgIcon
                  name="remove"
                  width={16}
                  height={16}
                  className="text-black"
                />
              </button>
              <span className={`mx-2 bg-transparent`}>{quantityState}</span>
              <button
                className="d-flex align-items-center bg-transparent p-0 m-0"
                onClick={() => {
                  handleQuantity("add", accordionState?.view)
                }}>
                <CustomSvgIcon
                  name="add"
                  width={16}
                  height={16}
                  className="text-black"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-start pb-2">
        <div>
          {orderRowType && (
            <span className="badge bg-muted">
              {offerTypeMap(orderRowType, "niceName")}
            </span>
          )}
        </div>
        <div className="px-3">
          {selected ? (
            <small>
              <strong>{t("Recommended")}</strong>
            </small>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
