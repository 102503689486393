import React from "react"

export default function OffCanvas({
  show = false,
  setShow = null,
  header = null,
  width = "",
  children
}) {
  return (
    <>
      <nav
        className={`nav-off-canvas bg-white${
          width !== "" ? ` ${width} ` : " "
        }h-100 text-body position-fixed js-app-off-canvas${
          show ? " is-open" : ""
        }`}>
        <div className="nav-off-canvas-inside position-relative">
          <div className="nav-off-canvas-header d-flex justify-content-center align-items-center">
            <div>
              <h3 className="mb-0">{header && header}</h3>
            </div>
            <button
              onClick={() => {
                setShow(!show)
              }}
              className="border-0 bg-white d-block close-nav-button text-body position-absolute"
              style={{ right: 0, marginRight: "7px" }}>
              <span className="fe fe-x" style={{ fontSize: 25 }}></span>
            </button>
          </div>
          {children}
        </div>
      </nav>
    </>
  )
}
