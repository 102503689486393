import React, { useState, useRef, useEffect } from "react"
import axios from "axios"
import { newOrderRows, debitTypes } from "../../components/Order/Store"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  offerTypeMap,
  removeItemAtIndex,
  replaceItemAtIndex
} from "../../utils/helpers"
import { useTranslation } from "react-i18next"
import DatePicker, { registerLocale } from "react-datepicker"
import sv from "date-fns/locale/sv"
import moment from "moment"
import "moment-timezone"

import { checkMissingPrice } from "./helpers"
import CustomSvgIcon from "../Common/CustomSvgIcon"

registerLocale("sv", sv)

const OrderRowInput = ({
  item,
  newOrderRowIndex,
  itemNewName,
  setItemNewName
}) => {
  const { t } = useTranslation()
  const { internalType, description, isMisc } = item
  const [newOrderRowsState, setNewOrderRowsState] = useRecoilState(newOrderRows)
  const debitTypesState = useRecoilValue(debitTypes)
  const [startDate, setStartDate] = useState(moment(item.startTime).toDate())
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const index = newOrderRowsState.findIndex(
    (row) => row.orderRowId === item.orderRowId
  )
  const inputDescriptionRef = useRef()
  const inputNameRef = useRef()
  const inputAgreedPriceRef = useRef()
  const datePickerRef = useRef()
  const offerTypeRef = useRef()
  const [newPrice, setNewPrice] = useState(
    newOrderRowsState?.agreedPrice
      ? {
          amount: newOrderRowsState?.agreedPrice?.amount,
          type: newOrderRowsState?.agreedPrice?.type
        }
      : item.agreedPrice
  )
  const [defaultOrderRowType, setDefaultOrderRowType] = useState(
    item.orderRowType
  )
  const [defaultDebitType, setDefaultDebitType] = useState(item.debitType)
  const days = (type, nr = 7) => moment()[type](nr, "d").toDate()

  const missingPrice = checkMissingPrice(item)

  const handleRemove = async () => {
    const updatedOrderRows = removeItemAtIndex(
      newOrderRowsState,
      newOrderRowIndex
    )
    setNewOrderRowsState(updatedOrderRows)

    if (item.internalType === "INDIVIDUAL") {
      await axios.post("/api/hll/deleteFleetItemLock", {
        id: item.id
      })
    }
  }

  const handleUpdate = (newValues) => {
    const orderRows = replaceItemAtIndex(newOrderRowsState, index, {
      ...item,
      ...newValues
    })

    setNewOrderRowsState(orderRows)
  }

  const handleKeyUp = (event, refElement) => {
    if (event.key === "Enter") {
      refElement.blur()
    }
  }

  useEffect(() => {
    if (item) {
      setStartDate(moment(item.startTime).toDate())
    }
  }, [item])

  return (
    <div className="p-3 pt-0">
      {description && internalType === "INDIVIDUAL" && (
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
          <div className="col">
            <label
              htmlFor="description"
              className="field__label d-block form-label mb-1 text-uppercase">
              {t("Note")}
            </label>
            <input
              ref={inputDescriptionRef}
              type="text"
              name="description"
              defaultValue={description}
              className="h-100 form-control disabled"
              readOnly
            />
          </div>
        </div>
      )}

      {isMisc && internalType === "ARTICLE" && (
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
          <div className="col">
            <label
              htmlFor="name"
              className="field__label d-block form-label mb-1 text-uppercase">
              {t("Name")}{" "}
              <span className="text-muted text-gray-700">{`(${t(
                "Benämning av artikel"
              )})`}</span>
            </label>
            <input
              ref={inputNameRef}
              type="text"
              name="name"
              defaultValue={itemNewName}
              className="h-100 form-control"
              autoComplete="off"
              autoCorrect="off"
              spellCheck={false}
              onKeyUp={(event) => handleKeyUp(event, inputNameRef.current)}
              onChange={({ target: { value } }) => {
                handleUpdate({
                  newName: value,
                  reference: {
                    ...item.reference,
                    itemName: value
                  }
                })
                setItemNewName(value)
              }}
            />
          </div>
        </div>
      )}

      <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
        <div className="col">
          <label
            htmlFor="newPrice"
            className="field__label form-label mt-1 mb-1 text-uppercase col-12">
            {t("Agreed price")}
          </label>
        </div>
        <div className="row g-0">
          <div className={`${isMisc ? "col-12" : "col-9"}`}>
            <input
              ref={inputAgreedPriceRef}
              type="text"
              inputMode="numeric"
              name="newPrice"
              autoComplete="off"
              autoCorrect="off"
              spellCheck={false}
              className="form-control form-control-sm rounded-0"
              value={newPrice.amount}
              onKeyUp={(event) =>
                handleKeyUp(event, inputAgreedPriceRef.current)
              }
              onChange={({ target: { value } }) => {
                let formattedValue = value

                if (formattedValue < 0) {
                  formattedValue = Math.abs(value)
                }

                if (newPrice.type === "%" && value >= 100) {
                  formattedValue = 100
                }

                handleUpdate({
                  agreedPrice: {
                    amount: formattedValue === "" ? 0 : Number(formattedValue),
                    type: newPrice.type
                  },
                  price:
                    newPrice.type === "%"
                      ? -Math.abs(formattedValue)
                      : Number(formattedValue)
                })

                setNewPrice({
                  ...newPrice,
                  amount: formattedValue
                })
              }}
            />
            {missingPrice && (
              <small className="text-danger">
                {t("The price cannot be SEK 0")}
              </small>
            )}
          </div>
          {!isMisc && (
            <div className="col-3 pl-2">
              <select
                defaultValue={newPrice?.type ?? "kr"}
                className="form-select rounded-0"
                style={{ height: 50 }}
                onChange={({ target: { value } }) => {
                  let amount = newPrice.amount

                  if (value === "%" && amount > 100) {
                    amount = 0
                  }

                  handleUpdate({
                    agreedPrice: {
                      amount,
                      type: value
                    },
                    price: value === "%" ? -Math.abs(amount) : Number(amount)
                  })

                  setNewPrice({
                    amount,
                    type: value
                  })
                }}>
                <option value="kr">kr</option>
                <option value="%">%</option>
              </select>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
        <div className="col">
          <label
            htmlFor="date"
            className="field__label d-block form-label mb-1 text-uppercase">
            {t("Date")}
          </label>
          <div className="position-relative">
            <DatePicker
              selected={startDate}
              dateFormat="yyyy-MM-dd"
              minDate={days("subtract")}
              maxDate={days("add")}
              locale="sv"
              ref={datePickerRef}
              onClickOutside={() => setDatePickerOpen(false)}
              onFocus={() => setDatePickerOpen(true)}
              open={datePickerOpen}
              readOnly={true}
              className="rounded-0"
              onChange={(date) => {
                setDatePickerOpen(false)
                setStartDate(date)
                handleUpdate({
                  startTime: moment(date).format("YYYY-MM-DD[T]00:00:00[Z]")
                })
              }}
            />
            <CustomSvgIcon
              width={16}
              height={16}
              name="calender"
              className={`text-muted pointer-none`}
              style={{
                position: "absolute",
                right: 20,
                top: 12
              }}
            />
          </div>
        </div>
      </div>

      {item.offers && item.offers.length > 0 && (
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
          <div className={`col${defaultOrderRowType === 1 ? "-6" : ""}`}>
            <label
              htmlFor="type"
              className="field__label d-block form-label mb-1 text-uppercase">
              {t("Type")}
            </label>
            <select
              name="type"
              className="form-select rounded-0"
              disabled={item.offers.length === 1}
              style={{ height: 50 }}
              defaultValue={defaultOrderRowType}
              onChange={({ target: { value } }) => {
                const updatedOffer = item.offers.filter(
                  (offer) => offer.type === Number(value)
                )

                let orderRowType = Number(value)

                //Checking orderRowType
                if (orderRowType === 0 || orderRowType === 6) {
                  orderRowType = 1
                }

                if (orderRowType === 5) {
                  orderRowType = 0
                }

                handleUpdate({
                  orderRowType: orderRowType,
                  offerType: updatedOffer,
                  internalPrice: updatedOffer[0]?.price ?? 0,
                  priceUnit: updatedOffer[0]?.priceUnit
                })
                setDefaultOrderRowType(Number(value))
              }}
              ref={offerTypeRef}>
              {item.offers.map((offer, index) => (
                <option
                  key={index}
                  value={offer?.type}
                  disabled={
                    (offer.type === 2 || offer.type === 3) &&
                    internalType === "INDIVIDUAL"
                  }>
                  {offerTypeMap(offer?.type, "niceName")}
                </option>
              ))}
              {item.offers.filter((offer) => offer.type === 4).length === 0 && (
                <option value="4">{offerTypeMap(4, "niceName")}</option>
              )}
            </select>
          </div>
          <div
            className={
              item.offers && item.offers.length > 0 ? "col-6 pl-2" : "col"
            }>
            <label
              htmlFor="debitType"
              className="field__label d-block form-label mb-1 text-uppercase">
              {t("Debiting")}
            </label>
            <select
              name="debitType"
              className="form-select rounded-0"
              style={{ height: 50 }}
              defaultValue={JSON.stringify(defaultDebitType)}
              onChange={({ target: { value } }) => {
                handleUpdate({ debitType: JSON.parse(value) })
                setDefaultDebitType(JSON.parse(value))
              }}
              ref={offerTypeRef}
              disabled={
                defaultOrderRowType === 0 ||
                defaultOrderRowType === 1 ||
                defaultOrderRowType === 6
                  ? false
                  : true
              }>
              {debitTypesState.map((type, index) => (
                <option key={index} value={JSON.stringify(type)}>
                  {type?.description}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      <button
        onClick={handleRemove}
        className="btn btn-danger w-100 d-flex align-items-center justify-content-center">
        <span className="fe fe-trash-2 mr-2"></span>
        <span>{t("Remove")}</span>
      </button>
    </div>
  )
}

export default OrderRowInput
