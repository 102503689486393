import moment from "moment"
import React, { useRef, useEffect } from "react"
import uuid from "react-uuid"
import { availabilityColorMap } from "../../../utils/helpers"

function NewRentalItem({
  item,
  onItemClickHandler,
  isDisabled,
  onCheckedHandler,
  checkedItems,
  showModalSheet
}) {
  const cbRef = useRef()

  useEffect(() => {
    if (!checkedItems.find((checkedItem) => checkedItem.id === item.id)) {
      cbRef.current.checked = false
    }
  }, [checkedItems, item.id])

  const usesAvaliability = item?.availabilityIsUsed
  const availability = item?.availability

  let disabled = false

  if (showModalSheet) {
    disabled = true
  }

  if (usesAvaliability && availability === 0) {
    disabled = true
  }

  return (
    <div
      className={`pb-3 mb-3 border-bottom addon${
        disabled ? " is-disabled" : ""
      }`}
      style={{ cursor: "pointer" }}>
      <label
        htmlFor={item.id}
        style={{ cursor: "pointer" }}
        className="d-block">
        <div className="d-flex items-center justify-content-between">
          <strong className="pr-5">{item.name}</strong>
          <div className="d-flex align-items-center">
            {usesAvaliability && (
              <span
                className={`machine-item-availability d-block mr-2 flex-shrink-0 availability--${availabilityColorMap(
                  availability
                )}`}></span>
            )}
            <input
              id={item.id}
              type="checkbox"
              className="form-check-input list-checkbox list-checkbox-theme"
              disabled={isDisabled || disabled}
              ref={cbRef}
              value={JSON.stringify(item)}
              onChange={({ target: { checked, value } }) => {
                onCheckedHandler(checked, {
                  internalId: uuid(),
                  internalType: item.internalType,
                  id: item.id,
                  name: item.name,
                  data: value,
                  isNew: true,
                  quantity: 1,
                  description: "",
                  startTime: moment().format("YYYY-MM-DD[T]hh:mm:ss.SSS[Z]"),
                  number: item.number,
                  isBalancedManaged: item?.isBalancedManaged
                    ? item.isBalancedManaged
                    : false,
                  isMisc:
                    item?.isMisc && typeof item.isMisc !== "undefined"
                      ? item.isMisc
                      : false,
                  orderReturnInfo: item?.orderReturnInfo
                })

                if (checked && item.fleetModelId) {
                  onItemClickHandler(item)
                }
              }}
            />
          </div>
        </div>
        <div className="bg-light rounded border border-light border-1 mt-3">
          <span className="d-block text-muted text-gray-700 py-2 px-3 text-left">
            {item.number}
          </span>
        </div>
      </label>
    </div>
  )
}

export default NewRentalItem
