import React from "react"
const Footer = () => {
  return (
    <>
      <div className="mb-5 py-4" style={{ minHeight: 50 }}></div>
    </>
  )
}

export default Footer
