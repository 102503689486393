import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom"
import CreateOrder from "../../components/Order/CreateOrder"
import { usePermissionCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"
import { newOrderInformation } from "../../components/Order/Store"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { debitTypes, modalView } from "../../components/Order/Store"
import axios from "axios"

export default function NewOrder() {
  const { t } = useTranslation()
  const hasPermission = usePermissionCheck("MinaSidor.SkapaOrder")
  const newOrderInformationState = useRecoilValue(newOrderInformation)
  const setDebitTypesState = useSetRecoilState(debitTypes)
  const modalViewState = useRecoilValue(modalView)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  // Show alert if you wanna reload or not!
  useEffect(() => {
    window.onbeforeunload = function () {
      return true
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [])

  useEffect(() => {
    let unmounted = false

    const requestDebitTypes = async () => {
      await axios.post("/api/hll/debitTypes").then((response) => {
        if (!unmounted) {
          setDebitTypesState(response.data ?? null)
        }
      })
    }

    requestDebitTypes()

    return () => {
      unmounted = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, modalViewState])

  if (!hasPermission) {
    return <PageNotFound noPermission={true} />
  }

  if (!newOrderInformationState?.customer) {
    return navigate(t("/order"))
  }

  return <CreateOrder />
}
