import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const SearchResultCard = ({ data }) => {
  const { t } = useTranslation()
  const { title, category, image, objectID, slug } = data

  return (
    <li className="search-results-card is-horizontal" tabIndex="0">
      <div className="search-results-media is-small">
        {image && <img src={image} alt={title ? title : ""} />}
      </div>
      <div className="search-results-text">
        <Link
          replace={true}
          reloadDocument={true}
          className="search-results-cover-link"
          to={{
            pathname: `/${t("customer")}/${t("machine")}/${t(
              "group"
            )}/${objectID}/${slug}`
          }}>
          <p className="search-results-title">{title && title}</p>
        </Link>
        {category && <p className="search-results-subtitle">{category}</p>}
      </div>
    </li>
  )
}

export default SearchResultCard
