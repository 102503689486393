import React from "react"
const Depot = ({ id, name, handleDepotChange, selectedDepot }) => {
  return (
    <button
      className="d-flex align-items-center justify-content-between mb-3 pb-3 px-0 w-100 bg-transparent text-left machine-transfer-depot text-body"
      onClick={() => {
        handleDepotChange(id, name)
      }}
      disabled={selectedDepot === id}>
      <span>{name}</span>
      <span
        className={`p-0 rounded-circle border border-primary text-center${
          selectedDepot === id ? " bg-primary" : " bg-white"
        }`}
        style={{
          fontSize: 16,
          width: 22,
          height: 22,
          lineHeight: 1.3
        }}>
        {selectedDepot === id && (
          <span className="fe fe-check text-white"></span>
        )}
      </span>
    </button>
  )
}

export default Depot
