import React from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"

import Loader from "../../components/Loader/Loader"

import Alert from "../../components/Common/Alert"

const DepotModal = ({
  depots,
  handleChangeDepot,
  handleClose,
  show,
  loading,
  error,
  errorMessage
}) => {
  const { t } = useTranslation()

  return (
    <Modal show={show} onHide={handleClose} centered animation={false}>
      <Modal.Header className="justify-content-center modal-header">
        <h2 className="header-title mt-3 mb-4 text-center">
          {t("Our depots")}
        </h2>
      </Modal.Header>

      <Modal.Body>
        {!loading && depots && (
          <div className="row">
            <div className="field col-12 mb-3">
              <select className="form-select" onChange={handleChangeDepot}>
                <option value="">{t("Choose your depot")}</option>
                {depots &&
                  depots
                    .filter((depot) => depot.name !== "")
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item, i) => (
                      <option key={i} value={JSON.stringify(item)}>
                        {item.name}
                      </option>
                    ))}
              </select>
            </div>
          </div>
        )}

        {loading && <Loader />}

        {error && (
          <Alert
            className="text-center my-4"
            message={
              errorMessage
                ? `${errorMessage}`
                : t("Something went wrong, please refresh current page.")
            }
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-white" onClick={handleClose}>
          {t("Close")}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DepotModal
