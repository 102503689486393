import { useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import parser from "html-react-parser"
import ImageComingSoon from "../../../../assets/images/image-coming-soon.png"
import { motion } from "framer-motion"

export default function ProductItem({
  itemData,
  id,
  title: { rendered: title },
  slug,
  acf: { image: imageUrl, article_number: sku },
  isAddingToList,
  setShowMachineListModal,
  setMachineGroupDataState
}) {
  const { t } = useTranslation()
  const itemRef = useRef(null)

  const [isHovering, setIsHovering] = useState(false)

  return (
    <>
      <article
        ref={itemRef}
        className="position-relative"
        data-id={`machine-category-${id}`}
        onMouseEnter={() => {
          setIsHovering(true)
        }}
        onMouseLeave={() => {
          setIsHovering(false)
        }}>
        <div
          className={`d-flex flex-column machine-category-grid__item is-machine-item rounded position-relative`}
          style={{
            overflow: "hidden"
          }}>
          <header className="p-3 p-lg-4">
            <small className="d-block machine-grid__item__sku text-muted text-left mb-1">
              <Link
                className="text-black"
                to={{
                  pathname: `/${t("customer")}/${t("machine")}/${t(
                    "group"
                  )}/${id}/${slug}`
                }}>
                {`#${sku}`}
              </Link>
            </small>
            <h2 className="h4 mb-0 hyphenate text-left">
              <Link
                className="text-black"
                to={{
                  pathname: `/${t("customer")}/${t("machine")}/${t(
                    "group"
                  )}/${id}/${slug}`
                }}>
                {parser(title)}
              </Link>
            </h2>
            <div className="position-relative d-none d-lg-block">
              <motion.div
                initial={{
                  opacity: 0,
                  height: 0
                }}
                animate={{
                  opacity: isHovering ? 1 : 0,
                  height: isHovering ? 60 : 0
                }}
                exit={{ opacity: 0, height: 0 }}>
                {isHovering && (
                  <div
                    className={`bg-white d-flex align-items-center justify-content-center mt-3 pt-3 border-top border-gray-200`}>
                    <button
                      disabled={isAddingToList}
                      onClick={() => {
                        setShowMachineListModal(true)
                        setMachineGroupDataState(itemData)
                      }}
                      className="btn btn-primary btn-block d-flex align-items-center justify-content-center">
                      {t("Save to machine list")}
                    </button>
                  </div>
                )}
              </motion.div>
            </div>
            <div className="position-relative d-block d-lg-none">
              <div
                className={`bg-white d-flex align-items-center justify-content-center mt-3 pt-3 border-top border-gray-200`}>
                <button
                  disabled={isAddingToList}
                  onClick={() => {
                    setShowMachineListModal(true)
                    setMachineGroupDataState(itemData)
                  }}
                  className="btn btn-primary btn-block btn-small d-flex align-items-center justify-content-center text-center">
                  {t("Save to machine list")}
                </button>
              </div>
            </div>
          </header>

          {imageUrl && (
            <figure className="m-0 mx-auto p-4 p-lg-0">
              <Link
                className="text-black"
                to={{
                  pathname: `/${t("customer")}/${t("machine")}/${t(
                    "group"
                  )}/${id}/${slug}`
                }}>
                <img
                  src={imageUrl}
                  alt={parser(title)}
                  className="img-fluid rounded text-center mx-auto"
                />
              </Link>
            </figure>
          )}
          {!imageUrl && (
            <figure className="m-0 mx-auto p-4 p-lg-0 text-center">
              <Link
                className="text-black"
                to={{
                  pathname: `/${t("customer")}/${t("machine")}/${t(
                    "group"
                  )}/${id}/${slug}`
                }}>
                <img
                  src={ImageComingSoon}
                  alt="Hyreslandslagets logotyp"
                  className="logo"
                />
              </Link>
            </figure>
          )}
        </div>
      </article>
    </>
  )
}
