import axios from "axios"

const SearchCustomer = async (inputValue, regionId) => {
  let data = []
  const body = {
    customerName: {
      logicalOperator: "Or",
      predicate: "Contains",
      value: inputValue
    },
    regionId: {
      logicalOperator: "And",
      predicate: "Equal",
      value: regionId
    }
  }

  await axios
    .post("/api/hll/customerQuery", {
      body
    })
    .then((response) => {
      data = response?.data?.data ? response.data.data : []
    })
    .catch((err) => {
      data = err
    })

  return data
}

export default SearchCustomer
