import React, { useState, useRef, useCallback } from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import mapIcon from "../../hll-map-pin@2x.png"
import Loader from "../../components/Loader/Loader"

import DatePicker, { registerLocale } from "react-datepicker"
import sv from "date-fns/locale/sv"
import "react-datepicker/dist/react-datepicker.css"

import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api" //https://www.youtube.com/watch?v=WZcxJGmLbSo

import ActionFooter from "../../components/Common/ActionFooter"

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from "use-places-autocomplete"

import mapStyles from "../../utils/GoogleMaps/style-light"
import CustomSvgIcon from "../../components/Common/CustomSvgIcon"

const libraries = ["places"]
const mapDefaults = {
  width: "100%",
  height: "400px",
  address: "Vandagatan 10,  164 74 Kista",
  lat: 59.4210972,
  lng: 17.912222,
  zoom: 14
}

registerLocale("sv", sv)

const TransportOrderModal = ({
  orderData,
  handleSendBookingReturn,
  handleCancel,
  show,
  returnStatus,
  returnMessage,
  loading
}) => {
  const { t } = useTranslation()

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: "sv_SE"
  })

  const [pickUpData, setpickUpData] = useState({
    latitude: mapDefaults.lat,
    longitude: mapDefaults.lng,
    address: mapDefaults.address,
    date: new Date(),
    timeFrom: "07:00",
    timeTo: "16:00",
    phone: ""
  })

  const handleDateChange = (date) => {
    setpickUpData({
      ...pickUpData,
      date: new Date(date)
    })
  }

  const handleFromTimeChange = (e) => {
    setpickUpData({
      ...pickUpData,
      timeFrom: e.target.value
    })
  }

  const handleToTimeChange = (e) => {
    setpickUpData({
      ...pickUpData,
      timeTo: e.target.value
    })
  }

  const handlePhoneChange = (e) => {
    setpickUpData({
      ...pickUpData,
      phone: e.target.value
    })
  }

  const mapOptions = {
    panControl: false,
    mapTypeControl: false,
    scrollwheel: false,
    disabledDefaultUI: true,
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    styles: mapStyles
  }

  const mapContainerStyle = {
    width: mapDefaults.width,
    height: mapDefaults.height
  }

  const mapCenter = {
    lat: pickUpData.latitude,
    lng: pickUpData.longitude
  }

  const [marker, setMarker] = useState({
    lat: pickUpData.latitude,
    lng: pickUpData.longitude
  })

  const mapRef = useRef()
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, [])

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng })
    mapRef.current.setZoom(14)
  }, [])

  if (!isLoaded) {
    return null
  }

  if (loadError) {
    return null
  }

  return (
    <Modal
      show={show}
      onHide={handleCancel}
      backdrop="static"
      centered
      animation={false}
      fullscreen="true"
      className={`modal-scrollable transport-order-modal`}>
      <form onSubmit={(e) => handleSendBookingReturn(e, pickUpData)}>
        {!loading && (
          <Modal.Header className="d-block p-3">
            <div className="position-relative">
              <h2 className="header-title mt-3 mb-0 text-center">
                {t("Return information")}
              </h2>
              <button
                onClick={handleCancel}
                className="bg-transparent position-absolute p-0 text-body"
                style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
                <CustomSvgIcon
                  name="close"
                  width={16}
                  height={16}
                  className="text-black"
                />
              </button>
            </div>
          </Modal.Header>
        )}
        <Modal.Body>
          {!returnStatus && !loading && (
            <h3 className="header-title mb-3">
              {t("What day should we pick up?")}
            </h3>
          )}
          {!returnStatus && !loading && (
            <p>
              {t(
                "Indicate which day and between which times we can come and pick up your return."
              )}
            </p>
          )}

          {returnMessage && <p className="text-center">{t(returnMessage)}</p>}

          {!returnStatus && (
            <div className="row">
              {!loading && (
                <div className="field col-12 col-md-4 mb-3">
                  <label
                    htmlFor="date"
                    className="field__label form-label mb-1 text-uppercase">
                    {t("Date")}
                  </label>
                  <DatePicker
                    name="date"
                    selected={new Date(pickUpData.date)}
                    onChange={(date) => handleDateChange(date)}
                    required
                    disabled={loading}
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                  />
                </div>
              )}

              {!loading && (
                <div className="field col-12 col-md-4 mb-3">
                  <label
                    htmlFor="fromTime"
                    className="field__label form-label mb-1 text-uppercase">
                    {t("From")}
                  </label>
                  <input
                    type="time"
                    name="fromTime"
                    value={pickUpData.timeFrom}
                    onChange={(e) => handleFromTimeChange(e)}
                    required
                    disabled={loading}
                    className="form-control"
                  />
                </div>
              )}

              {!loading && (
                <div className="field col-12 col-md-4 mb-3">
                  <label
                    htmlFor="toTime"
                    className="field__label form-label mb-1 text-uppercase">
                    {t("To")}
                  </label>
                  <input
                    type="time"
                    name="toTime"
                    value={pickUpData.timeTo}
                    onChange={(e) => handleToTimeChange(e)}
                    required
                    disabled={loading}
                    className="form-control"
                  />
                </div>
              )}

              {!loading && (
                <div className="col-12 mb-3 mt-3">
                  <label
                    htmlFor="phone"
                    className="field__label form-label mb-1 text-uppercase">
                    {t("Phone")}
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    value={pickUpData.phone}
                    onChange={(e) => handlePhoneChange(e)}
                    placeholder={t("Enter your phone number")}
                    required
                    disabled={loading}
                    className="form-control"
                  />
                </div>
              )}

              <div className="col-12 mb-3 mt-3">
                {!loading && (
                  <h3 className="header-title mb-3">
                    {t("Where should it be picked up?")}
                  </h3>
                )}
                {!loading && (
                  <Search
                    panTo={panTo}
                    pickUpData={pickUpData}
                    setpickUpData={setpickUpData}
                    setMarker={setMarker}
                    className="form-control"
                  />
                )}
                {!loading && (
                  <GoogleMap
                    id="map"
                    language="sv-SE"
                    mapContainerStyle={mapContainerStyle}
                    zoom={mapDefaults.zoom}
                    center={mapCenter}
                    options={mapOptions}
                    onLoad={onMapLoad}>
                    {Object.keys(marker).length !== 0 && (
                      <Marker
                        position={{
                          lat: pickUpData.latitude,
                          lng: pickUpData.longitude
                        }}
                        icon={{
                          url: mapIcon
                        }}
                      />
                    )}
                  </GoogleMap>
                )}
              </div>
            </div>
          )}
          {loading && (
            <div className="d-block mt-3 text-center">
              <Loader />
            </div>
          )}

          {!returnStatus && !loading && (
            <p className="d-block mt-3">
              {t("The following items will be returned.")}
            </p>
          )}
          {!returnStatus && !loading && (
            <ul className="list-group list-group-lg scroll-view mt-3">
              {orderData.length > 0 &&
                orderData.map((item, i) => {
                  return (
                    <li key={i} className="list-group-item">
                      <div className="d-flex align-items-center">
                        <span>{item?.reference?.itemName}</span>
                        <span className="ml-2 badge bg-light disabled">
                          {item?.quantity}
                        </span>
                      </div>
                    </li>
                  )
                })}
            </ul>
          )}
        </Modal.Body>
        <ActionFooter>
          {!loading && (
            <button
              className="btn btn-outline-secondary w-100 d-block mr-3"
              onClick={handleCancel}>
              {t("Close")}
            </button>
          )}
          {!returnStatus && !loading && (
            <button type="submit" className="btn btn-primary w-100 d-block">
              {t("Send return")}
            </button>
          )}
        </ActionFooter>
      </form>
    </Modal>
  )
}

const Search = ({ panTo, pickUpData, setpickUpData, setMarker }) => {
  const { t } = useTranslation()

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      location: {
        lat: () => pickUpData.latitude,
        lng: () => pickUpData.longitude
      },
      radius: 200 * 1000
    }
  })

  const handleInput = (e) => {
    setValue(e.target.value)
  }

  const handleClick = async (e) => {
    e.preventDefault()
    const address = e.target.dataset["value"]
    setValue(address, false)
    clearSuggestions()

    try {
      const results = await getGeocode({ address })
      const { lat, lng } = await getLatLng(results[0])
      setpickUpData({
        ...pickUpData,
        address: address,
        latitude: lat,
        longitude: lng
      })
      setMarker({
        lat: lat,
        lng: lng
      })
      panTo({ lat, lng })
    } catch (error) {
      console.log("😱 Error: ", error)
    }
  }

  return (
    <div className="position-relative mb-2">
      <input
        type="search"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={t("Search address")}
        required={true}
        className="form-control"
      />
      <ul className="google-maps-suggestions">
        {status === "OK" &&
          data.map(({ place_id, description }) => (
            <li key={place_id} className="google-maps-suggestions--item">
              <a
                href="#!"
                className="nav-link"
                data-value={description}
                onClick={(e) => handleClick(e)}>
                {description}
              </a>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default TransportOrderModal
