import React from "react"

function Money({ className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      className={className}
      style={{ width: 22, height: 22 }}>
      <defs>
        <filter id="a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g filter="url(#a)" fill="none" fillRule="evenodd">
        <path
          d="M8.0867 7.6667c0 3.4517 2.7982 6.25 6.25 6.25 3.4517 0 6.25-2.7983 6.25-6.25 0-3.4518-2.7983-6.25-6.25-6.25-3.4518 0-6.25 2.7982-6.25 6.25h0z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <text
          fontFamily="HelveticaNeue-Medium, Helvetica Neue"
          fontSize="8"
          fontWeight="400"
          fill="currentColor"
          transform="translate(1 1)">
          <tspan x="10" y="9">
            kr
          </tspan>
        </text>
        <path
          d="M8.1083 8.105c-2.6024-.1854-5.0462 1.2652-6.1297 3.6386-1.0835 2.3734-.5787 5.1702 1.2661 7.015 1.8449 1.8449 4.6416 2.3497 7.015 1.2661 2.3734-1.0835 3.824-3.5273 3.6386-6.1297"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <text
          fontFamily="HelveticaNeue-Medium, Helvetica Neue"
          fontSize="8"
          fontWeight="400"
          fill="currentColor"
          transform="translate(1 1)">
          <tspan x="3" y="16">
            kr
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default Money
