import React from "react"

const MachineItemDataRow = ({
  wrapperClass = "col-12",
  labelClass = "text-uppercase font-xs pb-0 mb-1",
  label,
  value,
  valueClass = "text-muted",
  children
}) => {
  return (
    <div className={wrapperClass}>
      <p className={labelClass}>{label}</p>
      {children ? children : <p className={valueClass}>{value && value}</p>}
    </div>
  )
}

export default MachineItemDataRow
