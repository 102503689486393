import React, { useEffect } from "react"
import { usePermissionCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"
import OrderWrapper from "../../components/Order/OrderWrapper"
import { useSetRecoilState } from "recoil"
import { view } from "../../components/Order/Store"

const Order = () => {
  const hasPermission = usePermissionCheck("MinaSidor.SkapaOrder")
  const setViewState = useSetRecoilState(view)

  useEffect(() => {
    setViewState("createOrder")
  }, [setViewState])

  // // Show alert if you wanna reload or not!
  // useEffect(() => {
  //   window.onbeforeunload = function () {
  //     return true
  //   }

  //   return () => {
  //     window.onbeforeunload = null
  //   }
  // }, [])

  if (!hasPermission) {
    return <PageNotFound noPermission={true} />
  }

  return (
    <div className="container-fluid container-fluid-max-width">
      <div className="pt-4">
        <OrderWrapper />
      </div>
    </div>
  )
}

export default Order
