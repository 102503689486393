import React from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

function InsuranceCase() {
  const { t } = useTranslation()

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-8 col-sm-7 col-md-6 col-lg-4 col-xl-3 py-5 text-center">
          <div className="mb-4">
            <span
              className="fe fe-life-buoy text-primary"
              style={{ fontSize: 50 }}></span>
          </div>
          <h1 className="h3 mb-2">{t("Insurance case")}</h1>
          <p className="mb-4">{t("This order is an insurance case")}</p>
          <p>
            <Link
              to={`/${t("order")}`}
              className="btn btn-primary btn-sm font-weight-bold px-4 py-2">
              {t("Back")}
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default InsuranceCase
