import React, { useEffect } from "react"
import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc"
import { useTranslation } from "react-i18next"
import Logo from "../../assets/images/HLL_notext.svg"

import Storage from "../../utils/storage"
import { useOidcIdToken } from "@axa-fr/react-oidc"
import { useCookies } from "react-cookie"

const SessionLost = () => {
  const { t } = useTranslation()
  const { logout } = useOidc()
  const { idToken } = useOidcIdToken()
  const { accessToken } = useOidcAccessToken()

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies([])

  const logoutCallback = React.useCallback(async () => {
    const logoutUrl = `${process.env.REACT_APP_AUTH_URL}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.REACT_APP_PUBLIC_URL}`
    await logout()
    document.location.href = logoutUrl
  }, [idToken, logout])

  useEffect(() => {
    const timer = window.setInterval(() => {
      Storage.remove("userGroups")
      Storage.remove("customer", "local")
      Storage.remove("subsidiaries", "local")
      Storage.remove("userState", "local")
      Storage.remove("internalCompanies", "local")
      Storage.remove("internalRegions", "local")
      Storage.remove("depots", "local")
      logoutCallback()
    }, 3000)
    return () => {
      // Return callback to run on unmount.
      window.clearTimeout(timer)
    }
  }, [logout, logoutCallback])

  useEffect(() => {
    setCookie("HLL.JWT.AuthToken", accessToken, { path: "/" })
  }, [accessToken, setCookie])

  return (
    <div>
      <div className="container my-5 text-center">
        <div className="row mb-5">
          <div className="col-12">
            <figure className="d-block m-0 pb-5">
              <img
                src={Logo}
                alt="Hyreslandslagets logotyp"
                style={{ width: "50px" }}
                className="logo"
              />
            </figure>
            <h1>{t("Session expire")}</h1>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => (window.location.href = "/")}>
              {t("Click to reload!")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionLost
