import React from "react"
import { useTranslation } from "react-i18next"

const ValidationInfo = ({ setShowModalSheet, data }) => {
  const { t } = useTranslation()
  const getItemNames = (rows) => {
    let names = []

    rows.forEach((row) => {
      if (!row.reference.itemName) {
        return
      }
      names.push(row.reference.itemName)
    })

    return names.length > 0 ? names.join(", ") : ""
  }

  return (
    <div>
      <strong>{t("Error occurred")}</strong>

      {data?.customerOrderCreateRequestValidationInfo?.orderHeadInfos &&
        data?.customerOrderCreateRequestValidationInfo?.orderHeadInfos.map(
          (row, index) => {
            return (
              <div className="mb-2" key={index}>
                <div>
                  Felkod: {row.errorCode} ({row.description})
                </div>
              </div>
            )
          }
        )}

      {data?.customerOrderCreateRequestValidationInfo?.orderRowInfos &&
        data?.customerOrderCreateRequestValidationInfo?.orderRowInfos.map(
          (row, index) => {
            return (
              <div className="mb-2" key={index}>
                <div>
                  Felkod: {row.errorCode} ({row.description})
                </div>
                {getItemNames(row.orderRows)}
              </div>
            )
          }
        )}

      <button
        className="btn btn-primary w-100 d-block mt-4"
        onClick={() => setShowModalSheet(false)}>
        {t("Close")}
      </button>
    </div>
  )
}

export default ValidationInfo
