import React from "react"

function SkeletonLoader({
  className = "py-4",
  subClassName = "",
  rounded = true
}) {
  return (
    <div className={`loading-cell ${className}`}>
      <div
        className={`loading-bar${!rounded ? " rounded-0" : ""}${
          subClassName ? ` ${subClassName}` : ""
        }`}></div>
    </div>
  )
}

export default SkeletonLoader
