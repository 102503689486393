import React from "react"
import { Link } from "react-router-dom"
import { singleUrl } from "../../utils/helpers"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"

import Moment from "react-moment"
import "moment-timezone"
import uuid from "react-uuid"

import { useTranslation } from "react-i18next"
import { useMediaQuery } from "react-responsive"
import Storage from "../../utils/storage"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const Item = ({
  data = [],
  favoriteProjects,
  setFavoriteProjects,
  setUpdatingUserState
}) => {
  const { t } = useTranslation()
  const customer = Storage.get("customer", null, "local") ?? null

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 991px)"
  })

  const blockedForOrderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("This project is blocked for new orders")}
    </Tooltip>
  )

  const favouriteProjectTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Select as favorite project")}
    </Tooltip>
  )

  const handleSaveFavoriteProjects = async (id) => {
    const item = data && data.find((item) => item?.id === id)

    const exists =
      favoriteProjects && favoriteProjects.find((item) => item?.id === id)
    if (exists) {
      setFavoriteProjects((current) => current.filter((item) => item.id !== id))
    } else {
      setFavoriteProjects((currentArr) => [...currentArr, item])

      window.dataLayer.push({
        event: "HLL_favorite_projects",
        company: customer?.name,
        projectName: `${item?.name} ${item?.number}`,
        userType: "customer"
      })
    }
    setUpdatingUserState(true)
  }

  return (
    <>
      {data.map((item) => {
        const isActive = favoriteProjects.find(
          (f) => f?.id === item.id && item?.customerId === customer?.id
        )

        return (
          <tr key={uuid()}>
            {!isDesktopOrLaptop && (
              <td className="col-1">
                <div className="d-flex align-items-center justify-content-center">
                  {item?.isBlockedForOrder && (
                    <div className="text-primary">
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 100, hide: 100 }}
                        overlay={blockedForOrderTooltip}>
                        <CustomSvgIcon
                          width={16}
                          height={16}
                          name="lock"
                          className="mr-2"
                        />
                      </OverlayTrigger>
                    </div>
                  )}
                  <div>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 100, hide: 100 }}
                      overlay={favouriteProjectTooltip}>
                      <button
                        className={`fe fe-star bg-transparent${
                          isActive ? ` text-primary` : " text-gray-500"
                        }`}
                        onClick={() => handleSaveFavoriteProjects(item?.id)}
                      />
                    </OverlayTrigger>
                  </div>
                </div>
              </td>
            )}
            <td>
              <Link
                to={{
                  pathname: singleUrl(
                    `${t("projects")}`,
                    item.customerId,
                    item.id
                  ),
                  customerId: item.customerId,
                  id: item.id,
                  name: item.name,
                  number: item.number,
                  isBlockedForOrder: item.isBlockedForOrder,
                  isEditable: item.isEditable,
                  activeStatus: item.activeStatus,
                  siteType: item.siteType
                }}
                className="item-title name">
                <div>
                  {item?.number && item.number}
                  {item?.name && ` ${item.name}`}
                </div>
                <div>
                  <small>
                    {item?.address?.adressLine1
                      ? item.address.adressLine1
                      : null}
                  </small>
                </div>
              </Link>
            </td>
            <td>
              <span className="item-created-at text-reset">
                <Moment
                  locale="sv"
                  dateTime={item.startTime}
                  format="YYYY-MM-DD">
                  {item.startTime}
                </Moment>
              </span>
            </td>

            {isDesktopOrLaptop && (
              <td className="col-1">
                <div className="d-flex align-items-center justify-content-center">
                  {item?.isBlockedForOrder && (
                    <div className="text-primary">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 100, hide: 100 }}
                        overlay={blockedForOrderTooltip}>
                        <CustomSvgIcon
                          width={16}
                          height={16}
                          name="lock"
                          className="mr-2"
                        />
                      </OverlayTrigger>
                    </div>
                  )}
                  <div>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 100, hide: 100 }}
                      overlay={favouriteProjectTooltip}>
                      <button
                        className={`fe fe-star bg-transparent${
                          isActive ? ` text-primary` : " text-gray-500"
                        }`}
                        onClick={() => handleSaveFavoriteProjects(item?.id)}
                      />
                    </OverlayTrigger>
                  </div>
                </div>
              </td>
            )}
          </tr>
        )
      })}
    </>
  )
}

export default Item
