import React from "react"
import { useRecoilValue } from "recoil"
import { newInventoryFind } from "../../Store"
import Storage from "../../../../utils/storage"

export default function Tags() {
  const internalRegions = Storage.get("internalRegions", [], "local") ?? []
  const newInventoryFindState = useRecoilValue(newInventoryFind)

  const { filters } = newInventoryFindState

  return (
    <>
      <div className="horizontal-scroll-wrapper">
        {filters?.selectedCompanies &&
          filters?.selectedCompanies?.map((id) => {
            const internalRegion = internalRegions.find(
              (region) => region?.regionId === id
            )

            return (
              <div
                key={id}
                className="badge bg-light text-body mr-2 p-2 text-truncate"
                style={{ maxWidth: "200px" }}>
                {internalRegion?.regionName}
              </div>
            )
          })}
      </div>
    </>
  )
}
