import { useTranslation } from "react-i18next"
import CustomSvgIcon from "../../Common/CustomSvgIcon"
import parser from "html-react-parser"
import ActionFooter from "../../Common/ActionFooter"
import ImageComingSoon from "../../../assets/images/image-coming-soon.png"

export default function Filter({
  machineGroupData,
  machineFilterState,
  machineModelState,
  currentModelSelected,
  setCurrentModelSelected,
  showFilter,
  setShowFilter
}) {
  const { t } = useTranslation()

  const accessories = machineGroupData?.acf?.accessories ?? []
  const models = machineModelState?.models ?? []

  return (
    <nav
      className={`nav-off-canvas machine-filter bg-white h-100 text-body position-fixed js-app-off-canvas${
        showFilter ? " is-open" : ""
      }`}>
      <div className="nav-off-canvas-inside position-relative">
        <div className="nav-off-canvas-header d-flex justify-content-center align-items-center">
          <div>
            <h3 className="mb-0">{machineFilterState?.header}</h3>
          </div>
          <button
            onClick={() => {
              setShowFilter(!showFilter)
            }}
            className="border-0 bg-white d-block close-nav-button text-body position-absolute"
            style={{ right: 0, marginRight: "7px" }}>
            <CustomSvgIcon
              name="close"
              width={16}
              height={16}
              className="text-black"
            />
          </button>
        </div>
        {machineFilterState?.state === "accessories" && (
          <div className="bg-light p-4">
            {t(
              "The following accessories can be added when you rent and pick up your machine. Some are recommended and added automatically when you rent."
            )}
          </div>
        )}

        {machineFilterState?.state === "models" && (
          <div className="bg-light p-4">
            {t(
              "When you rent from us, you can get a machine from different manufacturers. But of course they are equivalent in terms of specifications. Select from the list if you want to see info about a particular model."
            )}
          </div>
        )}

        <div className="nav-off-canvas-body position-relative p-0">
          <div className="nav-off-canvas-nav-list">
            {machineFilterState?.state === "accessories" &&
              accessories.length > 0 &&
              accessories.map((item, index) => {
                const lastIndex = index === accessories.length - 1
                return (
                  <div
                    className={`d-block p-3${
                      !lastIndex ? " border-bottom" : ""
                    }`}
                    key={item.id}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>{item?.name}</span>
                      {item?.selected && (
                        <span>
                          <strong>{t("Recommended")}</strong>
                        </span>
                      )}
                    </div>
                  </div>
                )
              })}
            {machineFilterState?.state === "models" &&
              models.length > 0 &&
              models.map((item, index) => {
                const lastIndex = index === accessories.length - 1
                const isActive = currentModelSelected?.id === item?.id
                return (
                  <div
                    className={`d-block${!lastIndex ? " border-bottom" : ""}`}
                    key={item.id}>
                    <button
                      className="btn btn-block btn-transparent p-3 m-0 machine-filter__item"
                      onClick={() => {
                        setCurrentModelSelected(item)
                      }}>
                      <div className="d-flex align-items-center justify-content-between">
                        <figure className="m-0 p-0 machine-filter__image-wrapper pr-2">
                          {item?.acf?.image ? (
                            <img
                              src={item?.acf?.image}
                              alt={
                                item?.title?.rendered
                                  ? parser(item?.title?.rendered)
                                  : ""
                              }
                            />
                          ) : (
                            <img
                              src={ImageComingSoon}
                              alt={t("Image coming soon")}
                              className="logo"
                            />
                          )}
                        </figure>
                        <span className="mr-auto">
                          <strong>
                            {item?.title?.rendered ??
                              parser(item?.title?.rendered)}
                          </strong>
                        </span>

                        {isActive ? (
                          <CustomSvgIcon
                            width={20}
                            height={20}
                            name="check-circle-selected-filled"
                            className={`text-primary with-multiple-paths mt-2`}
                          />
                        ) : (
                          <CustomSvgIcon
                            width={20}
                            height={20}
                            name="check-circle"
                            className={`text-primary with-multiple-paths mt-2`}
                          />
                        )}
                      </div>
                    </button>
                  </div>
                )
              })}
          </div>
        </div>
        {machineFilterState?.state !== "accessories" && (
          <ActionFooter className="d-flex align-items-center justify-content-center">
            <button
              className={`btn btn-primary d-block w-100`}
              onClick={() => {
                setShowFilter(!showFilter)
              }}>
              {t("Show model")}
            </button>
          </ActionFooter>
        )}
      </div>
    </nav>
  )
}
