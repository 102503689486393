import { useState, useEffect, useMemo } from "react"
import { useOidcUser } from "@axa-fr/react-oidc"
import axios from "axios"
import Storage from "./storage"
import onScan from "onscan.js"

export function useFetchData(endpoint, payload) {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    let unmounted = false

    const fetchData = async () => {
      try {
        setLoading(true)
        setError(false)

        const response = await axios.post(endpoint, payload)

        if (!unmounted) {
          setData(response.data)
        }
      } catch (err) {
        console.log(err)
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchData()

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { data, loading, error }
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export function usePermissionCheck(permission) {
  const { oidcUser } = useOidcUser()
  const perm = oidcUser?.perm

  //console.log(perm, "perm")

  if (!perm) {
    return false
  }

  if (perm.includes(permission)) {
    return true
  }

  return false
}

export function useEnviromentCheck() {
  let stagingEnviroment = false

  if (process.env.REACT_APP_AUTH_URL.includes("test")) {
    stagingEnviroment = true
  }

  return stagingEnviroment
}

export function useCompanyCheck() {
  const customer = Storage.get("customer") ?? null

  const companyType = customer?.companyType

  if (companyType !== 1) {
    return true
  }

  return false
}

export function useSortableData(items, config = null) {
  const [sortConfig, setSortConfig] = useState(config)

  const sortedItems = useMemo(() => {
    let sortableItems = [...items]
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [items, sortConfig])

  const handleSort = (key) => {
    let direction = "ascending"
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending"
    }
    setSortConfig({ key, direction })
  }

  return { items: sortedItems, handleSort }
}

export function useScript(url) {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = url
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

export function useBarcodeScanner({ handleScan }) {
  useEffect(() => {
    onScan.attachTo(document, {
      onScan: function (data) {
        handleScan(data)
      },
      scanButtonKeyCode: 0,
      keyCodeMapper: function (oEvent) {
        // Look for special key codes or other event properties
        // specific to your scanner:
        switch (oEvent.which) {
          case 186:
            if (oEvent.shiftKey) return ":"
            break
          case 187:
            return "="
          case 189:
            if (oEvent.shiftKey) return "_"
            return "-"
          case 190:
            return "."
          case 191:
            if (oEvent.shiftKey) return "?"
            return "/"
          default:
            break
        }

        // Fall back to the default decoder in all other cases:
        return onScan.decodeKeyEvent(oEvent)
      }
    })

    return () => {
      onScan.detachFrom(document)
    }
  }, [handleScan])
}

export async function setUserSettings(data) {
  const payload = {
    data
  }

  try {
    const response = await axios.post("/api/hll/setUserSettings", payload)

    return response
  } catch (err) {
    return err?.response
  }
}

export async function getUserSettings() {
  try {
    const response = await axios.post("/api/hll/getUserSettings")
    return response
  } catch (err) {
    return err?.response
  }
}

export async function getInternalCompanies(forUserOnly = true) {
  try {
    const response = await axios.post("/api/hll/internalCompanies", {
      forUserOnly
    })
    return response?.data
  } catch (err) {
    console.log(err)
    return []
  }
}

export async function getInternalRegions(forUserOnly = true) {
  try {
    const response = await axios.post("/api/hll/internalRegions", {
      forUserOnly
    })
    return response?.data
  } catch (err) {
    console.log(err)
    return []
  }
}

export async function getDepots() {
  try {
    const response = await axios.post("/api/hll/depots")
    return response?.data?.data
  } catch (err) {
    console.log(err)
    return []
  }
}

export async function getInventories() {
  try {
    const response = await axios.get("/api/hll/inventoryOccasion")
    return response?.data
  } catch (err) {
    return err?.response
  }
}

export async function getFleetItems(ids, limit = 1000) {
  const payload = {
    body: {
      id: {
        logicalOperator: "And",
        predicate: "Equal",
        values: ids
      }
    },
    offset: 0,
    limit: limit
  }
  try {
    const response = await axios.post("/api/hll/fleetItemQuery", payload)
    return response?.data
  } catch (err) {
    return err?.response
  }
}

export async function getUserAccountGroups(customerId) {
  const payload = {
    customerId
  }
  try {
    const response = await axios.post("/api/hll/userAccountGroups", payload)
    return response?.data
  } catch (err) {
    return err?.response
  }
}

export async function getFleetModel(fleetModelId) {
  const payload = {
    fleetModelId
  }
  try {
    const response = await axios.post("/api/hll/fleetModel", payload)
    return response?.data
  } catch (err) {
    return err?.response
  }
}

export async function getFleetModelCustomerPriceWithoutDetails(
  customerId,
  ids
) {
  const payload = {
    customerId,
    ids
  }

  try {
    const response = await axios.post(
      "/api/hll/fleetModelCustomerPriceWithoutDetails",
      payload
    )

    if (ids && ids?.length === 1) {
      return response?.data[0]
    }

    return response?.data
  } catch (err) {
    return err?.response
  }
}

export async function getMaintenance() {
  const today = new Date()
  let maintenanceMode = false
  let maintenanceContent = ""

  try {
    const response = await axios.post("/api/hll/maintenance")
    const { theme_settings } = response?.data

    if (!theme_settings) {
      // Check if theme_settings exists
      return
    }

    const {
      hll_my_pages_maintenance_type,
      hll_my_pages_maintenance,
      hll_my_pages_maintenance_content,
      hll_my_pages_maintenance_startdate,
      hll_my_pages_maintenance_enddate
    } = theme_settings

    const startDate = new Date(hll_my_pages_maintenance_startdate)
    const endDate = new Date(hll_my_pages_maintenance_enddate)

    // If manual trigger from WP.
    if (
      hll_my_pages_maintenance_type === "manual" &&
      hll_my_pages_maintenance
    ) {
      maintenanceMode = true
      maintenanceContent = hll_my_pages_maintenance_content
    } else if (hll_my_pages_maintenance_type === "scheduled") {
      // If scheduled trigger from WP.
      if (today > startDate && today < endDate) {
        maintenanceMode = true
        maintenanceContent = hll_my_pages_maintenance_content
      } else {
        maintenanceMode = false
        maintenanceContent = ""
      }
    } else {
      maintenanceMode = false
      maintenanceContent = ""
    }

    return {
      maintenanceMode,
      maintenanceContent
    }
  } catch (err) {
    return err?.response
  }
}

export async function getMachineCategories(params) {
  const payload = {
    params
  }

  try {
    const response = await axios.post("/api/wp/machineCategories", payload)
    return response?.data
  } catch (err) {
    return err?.response
  }
}

export async function getMachineCategory(id) {
  const payload = {
    id
  }

  try {
    const response = await axios.post("/api/wp/machineCategory", payload)
    return response?.data
  } catch (err) {
    return err?.response
  }
}

export async function getMachines(params) {
  const payload = {
    params
  }

  try {
    const response = await axios.post("/api/wp/machines", payload)
    return response?.data
  } catch (err) {
    return err?.response
  }
}

export async function getMachine(id) {
  const payload = {
    id
  }

  try {
    const response = await axios.post("/api/wp/machine", payload)
    return response?.data
  } catch (err) {
    return err?.response
  }
}

export async function getMachineAncestors(id) {
  const payload = {
    id
  }

  try {
    const response = await axios.post("/api/wp/machine/ancestors", payload)
    return response?.data
  } catch (err) {
    return err?.response
  }
}

export async function getSiteQuery(body = null, limit, offset) {
  if (!body) return null

  const payload = {
    body,
    offset,
    limit
  }

  try {
    const response = await axios.post("/api/hll/siteQuery", payload)
    return response?.data?.data
  } catch (err) {
    return err?.response
  }
}

export async function getMachineListPriceCalculation(
  customerId,
  machines = null
) {
  if (!machines) return null

  const payload = {
    customerId,
    machines
  }

  try {
    const response = await axios.post(
      "/api/hll/machineListPriceCalculation",
      payload
    )
    return response?.data
  } catch (err) {
    console.log(err)
    return err?.response
  }
}

export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}

export function useAdminPermissionCheck() {
  const { oidcUser } = useOidcUser()
  const preferredUsername = oidcUser?.preferred_username

  if (!preferredUsername) {
    return false
  }

  if (preferredUsername === "niklas.forsberg@grandpublic.se") {
    return true
  }

  if (preferredUsername === "nickeforsberg@gmail.com") {
    return true
  }

  return false
}

export async function getOnBoarding(roles) {
  const payload = {
    roles
  }

  try {
    const response = await axios.post("/api/hll/onBoarding", payload)
    return response?.data
  } catch (err) {
    return err?.response
  }
}
