import React from "react"
import { motion, AnimatePresence } from "framer-motion"

import { modalSheetBounceTransition } from "../../utils/helpers"

const ModalSheet = ({
  show,
  roundedBig = false,
  maxWidth = true,
  shadow = true,
  children
}) => {
  let className = "text-center modal-sheet"

  if (roundedBig) {
    className += " rounded-top rounded-top-big"
  }

  if (maxWidth) {
    className += " modal-sheet-max-width"
  }

  if (!shadow) {
    className += " no-shadow"
  }

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          key="modalSheet"
          className={className}
          initial={{ opacity: 0, y: "100%" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "100%" }}
          transition={modalSheetBounceTransition}>
          <div className="p-3">{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ModalSheet
