import uuid from "react-uuid"
import { calculateAgreedPrice, getInspectionDiff } from "../../utils/helpers"

export function mapOrderRow(item, internalType, offers = [], quantity) {
  // type = INDIVIDUAL, ARTICLE, ACCESSORY

  const date = new Date()

  const defaultOffer =
    offers.length > 0 && offers.filter((offer) => offer.isDefault === true)

  // orderRowType based on offers
  let orderRowType = defaultOffer[0]?.type
  if (orderRowType === 0 || orderRowType === 6) {
    orderRowType = 1
  }
  if (orderRowType === 5) {
    orderRowType = 0
  }

  return {
    // -----------------------
    // post data
    id: item.id,
    startTime: `${date.toLocaleDateString("sv-se")}T${date.toLocaleTimeString(
      "sv-se"
    )}Z`,
    debitType:
      defaultOffer && defaultOffer.length > 0
        ? defaultOffer[0].debitType
        : null,
    reference: {
      id: item?.articleId ? item?.articleId : item.id,
      number: internalType === "ACCESSORY" ? item.articleNumber : item.number,
      itemName: item.name,
      type: internalType !== "INDIVIDUAL" ? "ARTICLE" : internalType
    },
    description: "",
    quantity: quantity ? quantity : 1,
    // discount: 0,
    // sort: 1,
    orderRowType: orderRowType,
    price: 0, // Send agreedPrice if exists else 0
    minimumRentalDays:
      defaultOffer && defaultOffer.length > 0
        ? defaultOffer[0].minimumRentalDays
        : 0,
    // returnNoteNumber: "",
    // orderReturnInfo: "",
    // isInvoiced: true, // får vi ej med från API:et
    // endTime: "",
    // siteId: item?.branchId ?? "",
    // -----------------------
    // internal app handling:
    orderReturnInfo: item?.orderReturnInfo ?? "",
    offers,
    internalType,
    isMisc: item?.isMisc ?? false,
    orderRowId: uuid(),
    agreedPrice: {
      amount: 0,
      type: "kr"
    },
    internalPrice:
      defaultOffer && defaultOffer.length > 0 ? defaultOffer[0].price : 0,
    newName: "",
    priceUnit:
      defaultOffer && defaultOffer.length > 0 ? defaultOffer[0].priceUnit : "",
    offerType: defaultOffer && defaultOffer.length > 0 ? defaultOffer : []
  }
}

export function hasUpcomingInspections(item) {
  let found = false

  if (!item?.inspection) {
    return found
  }

  for (let index = 0; index < item.inspection.length; index++) {
    const inspectionDiff = getInspectionDiff(item.inspection[index])

    if (inspectionDiff?.days >= -30) {
      found = true
    }
  }

  return found
}

export function previewItemMeta(item) {
  let date = new Date(item.startTime)
  let agreedPrice = 0
  let formattedPrice = 0

  if (item.agreedPrice.amount !== "" && item.agreedPrice.amount > 0) {
    agreedPrice = calculateAgreedPrice(item.agreedPrice, item.internalPrice)
  } else if (item.offerType && item.offerType.length > 0) {
    agreedPrice = item.offerType[0].price
  }

  if (agreedPrice < 0) {
    formattedPrice = `${agreedPrice} %`
  } else if (agreedPrice === 0) {
    formattedPrice = `${agreedPrice} kr${
      item.priceUnit ? `/${item.priceUnit}` : ""
    }`
  } else {
    formattedPrice = agreedPrice
      ? `${agreedPrice} kr${item.priceUnit ? `/${item.priceUnit}` : ""}`
      : ""
  }

  return [
    item.reference.number,
    formattedPrice,
    date.toLocaleDateString("sv-se"),
    item?.orderRowTyp
  ]
}

export function formatBodyForPost(state) {
  let formattedBody = []

  state.forEach(
    ({
      id,
      startTime,
      debitType,
      reference,
      description,
      quantity,
      orderRowType,
      price,
      minimumRentalDays
    }) =>
      formattedBody.push({
        id,
        startTime,
        debitType,
        reference,
        description,
        quantity,
        orderRowType,
        price,
        minimumRentalDays
      })
  )

  return formattedBody
}

export function calculateOrderTotalPerRow(row) {
  let price = row.internalPrice

  if (row.price > 0 && row.agreedPrice.type === "kr") {
    price = row.price
  } else if (row.price < 0 && row.agreedPrice.type === "%") {
    const discount = row.internalPrice * (Math.abs(row.price) / 100)
    price = row.internalPrice - Math.round(discount)
  }

  return Number(price) * Number(row.quantity)
}

export function checkMissingPrice(item) {
  if (item.orderRowType === 4) {
    return false
  }

  if (item?.agreedPrice?.amount === 0 && item.internalPrice === 0) {
    return true
  }

  return false
}
