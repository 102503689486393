import CustomSvgIcon from "../../../../Common/CustomSvgIcon"
export default function Actions({
  loading,
  handleDelete,
  handleQuantity,
  machineGroupQuantityState,
  rentalPrice
}) {
  return (
    <div className="cart-card__actions">
      <div className="py-2 px-3 bg-lighter rounded d-flex align-items-center justify-content-center">
        <button
          disabled={machineGroupQuantityState === 1 || loading}
          className={`d-flex align-items-center bg-transparent p-0 m-0${
            machineGroupQuantityState === 1 ? " disabled" : ""
          }`}
          onClick={() => {
            handleQuantity("remove", machineGroupQuantityState)
          }}>
          <CustomSvgIcon
            name="remove"
            width={16}
            height={16}
            className="text-black"
          />
        </button>
        <span className={`mx-2 bg-transparent${loading ? " is-disabled" : ""}`}>
          {machineGroupQuantityState}
        </span>
        <button
          disabled={loading}
          className="d-flex align-items-center bg-transparent p-0 m-0"
          onClick={() => handleQuantity("add", machineGroupQuantityState)}>
          <CustomSvgIcon
            name="add"
            width={16}
            height={16}
            className="text-black"
          />
        </button>
      </div>

      <div className="cart-card__price py-2 px-3 bg-lighter rounded d-flex align-items-center">
        {rentalPrice}
      </div>

      <button
        disabled={loading}
        className={`py-2 px-3 bg-lighter rounded d-flex align-items-center justify-content-center${
          loading ? " is-disabled" : ""
        }`}
        onClick={handleDelete}>
        <CustomSvgIcon
          name="bin"
          width={16}
          height={16}
          className="text-primary"
        />
      </button>
    </div>
  )
}
