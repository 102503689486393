import i18n from "../../i18n"

export const tableHeader = [
  {
    title: i18n.t("Name"),
    name: "firstName",
    alignment: "left"
  },
  {
    title: i18n.t("Email"),
    name: "email",
    alignment: "left"
  },
  {
    title: i18n.t("Competence"),
    name: "permission",
    alignment: "left"
  },
  {
    title: "",
    name: "userActions",
    alignment: "left"
  }
]

export function hasAuthorizationToRent(contacts, email) {
  return contacts.find((contact) => contact?.email === email)
}
