import React, { useEffect, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import axios from "axios"
import toast from "react-hot-toast"
import Loader from "../Loader/Loader"

export default function OutStandingRentSettingsForm({
  projectData,
  setProjectData
}) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    shouldUnregister: true,
    mode: "all"
  })

  const handleOnChange = useCallback(
    async (data) => {
      setLoading(true)

      const day = Number(data?.day)
      const frequency = Number(data?.frequency)

      const body = {
        ...projectData,
        siteSettings: {
          oustandingRentNotificationFrequency: {
            day,
            frequency
          }
        }
      }

      await axios
        .post("/api/hll/updateProject", {
          siteId: projectData.id,
          body
        })
        .then((response) => {
          if (response.status === 200) {
            setProjectData(response?.data)
            toast.success(t("Document recipients updated"))
          }
        })
        .catch((error) => {
          console.error(error)
          toast.error(t("Something went wrong, try again."))
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [projectData, setProjectData, t]
  )

  useEffect(() => {
    const subscription = watch(handleSubmit(handleOnChange))
    return () => subscription.unsubscribe()
  }, [handleOnChange, handleSubmit, watch])

  return (
    <form>
      <fieldset disabled={loading}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between">
                  <h2
                    className="card-header-title text-truncate"
                    style={{ fontSize: "14px" }}>
                    {t("Mailing settings for outstanding rents")}
                  </h2>
                  {loading && <Loader />}
                </div>
              </div>
              <div className={`card-body${loading ? " is-disabled" : ""}`}>
                <div className="mb-3">
                  <label
                    htmlFor="day"
                    className="field__label form-label mt-1 mb-1 text-uppercase">
                    {t("Day of mailing")}
                  </label>
                  <select
                    id="day"
                    className="form-select form-control-modal"
                    defaultValue={
                      projectData?.siteSettings
                        ?.oustandingRentNotificationFrequency?.day
                    }
                    onChange={(e) => {
                      setValue("day", e.target.value)
                      handleOnChange()
                    }}
                    {...register("day", { required: true })}>
                    <option value="1">{t("Monday")}</option>
                    <option value="2">{t("Tuesday")}</option>
                    <option value="3">{t("Wednesday")}</option>
                    <option value="4">{t("Thursday")}</option>
                    <option value="5">{t("Friday")}</option>
                    <option value="6">{t("Saturday")}</option>
                    <option value="0">{t("Sunday")}</option>
                  </select>
                  {errors["day"] && (
                    <span className="text-danger d-block font-xs mt-2">
                      {errors["day"].message}
                    </span>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="frequency"
                    className="field__label form-label mt-1 mb-1 text-uppercase">
                    {t("Frequency of mailing")}
                  </label>
                  <select
                    id="frequency"
                    className="form-select form-control-modal"
                    defaultValue={
                      projectData?.siteSettings
                        ?.oustandingRentNotificationFrequency?.frequency
                    }
                    onChange={(e) => {
                      setValue("frequency", e.target.value)
                      handleOnChange()
                    }}
                    {...register("frequency", { required: true })}>
                    <option value="1">{t("Every week")}</option>
                    <option value="2">{t("Even week")}</option>
                    <option value="3">{t("Odd week")}</option>
                    <option value="4">{t("The last of the month")}</option>
                  </select>
                  {errors["frequency"] && (
                    <span className="text-danger d-block font-xs mt-2">
                      {errors["frequency"].message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  )
}
