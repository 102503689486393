import axios from "axios"
import { itemStatus } from "../../../../utils/helpers"

const SearchMachine = async (
  t,
  inputValue,
  customerOrderId,
  userState,
  regionId,
  setLoading,
  setError
) => {
  let searchData = []
  setLoading(true)

  let fleetItemBody = {
    fleetModelId: {
      logicalOperator: "Or",
      predicate: "StartsWith",
      value: inputValue
    },
    number: {
      logicalOperator: "Or",
      predicate: "StartsWith",
      value: inputValue
    },
    name: {
      logicalOperator: "Or",
      predicate: "Contains",
      value: inputValue
    },
    branchId: {
      logicalOperator: "And",
      predicate: "Equal",
      value: userState?.internal?.depot?.id ?? 0
    },
    regionId: {
      logicalOperator: "And",
      predicate: "Equal",
      value: regionId
    },
    status: {
      logicalOperator: "And",
      predicate: "Equal",
      value: itemStatus("Branch")
    },
    QueryContext: 2
  }

  // if (customerOrderId) {
  //   fleetItemBody.orderId = {
  //     logicalOperator: "And",
  //     predicate: "NotEqual",
  //     value: customerOrderId
  //   }
  // }

  const fleetItemQuery = axios.post("/api/hll/fleetItemQuery", {
    limit: 1000,
    offset: 0,
    body: fleetItemBody
  })

  const articleQuery = axios.post("/api/hll/articleQuery", {
    body: {
      articleId: {
        logicalOperator: "Or",
        predicate: "StartsWith",
        value: inputValue
      },
      articleNumber: {
        logicalOperator: "Or",
        predicate: "Contains",
        value: inputValue
      },
      articleName: {
        logicalOperator: "Or",
        predicate: "Contains",
        value: inputValue
      },
      regionId: {
        logicalOperator: "And",
        predicate: "Equal",
        value: regionId
      }
    }
  })

  await axios
    .all([fleetItemQuery, articleQuery])
    .then(
      axios.spread((...responses) => {
        const fleetItems = responses[0].data.data ?? []
        const articles = responses[1].data.data ?? []
        if (fleetItems.length === 0 && articles.length === 0) {
          return t("No machines/articles found")
        } else {
          const mapFleetItems = fleetItems.map((item) => {
            return {
              ...item,
              internalType: "INDIVIDUAL"
            }
          })

          const mapArticles = articles.map((item) => {
            return {
              ...item,
              internalType: "ARTICLE"
            }
          })
          const data = mapFleetItems.concat(mapArticles)
          searchData = data
        }
      })
    )
    .catch((err) => {
      //console.log(err)
      setError(
        `${t("Something went wrong")}: ${err?.response?.data?.CorrelationId}`
      )
    })
    .finally(() => {
      setLoading(false)
    })

  return searchData
}

export default SearchMachine
