import React, { useEffect, useState, useCallback, useRef } from "react"
import axios from "axios"
import { useOidcUser } from "@axa-fr/react-oidc"
import { useTranslation } from "react-i18next"
import { inventoryView, newInventory } from "../../components/Inventory/Store"
import { useSetRecoilState, useRecoilState } from "recoil"
import ActionFooter from "../../components/Common/ActionFooter"
import ModalSheet from "../../components/Common/ModalSheet"
import Overlay from "../../components/Inventory/Common/Overlay"
import SearchForm from "../../components/Inventory/Common/Registration/SearchForm"
import Storage from "../../utils/storage"
import Registration from "../../components/Inventory/Common/Registration/Registration"
import { useLocation } from "react-router-dom"
import Loader from "../../components/Loader/Loader"
import toast from "react-hot-toast"
import { getLastPartFromUrl, dateAndTimeNow } from "../../utils/helpers"
import { useOnClickOutside, usePermissionCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"

import { machineStatus } from "../../utils/helpers"
import CustomSvgIcon from "../../components/Common/CustomSvgIcon"

export default function InventoryRegistration() {
  const searchModalSheetWrapperRef = useRef()
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()
  const hasInventoryPermission = usePermissionCheck("MinaSidor.Inventering")

  const oidcUserID = oidcUser?.sub

  let { state, pathname } = useLocation()
  const setViewState = useSetRecoilState(inventoryView)
  const userState = Storage.get("userState") ?? null
  const [newInventoryState, setNewInventoryState] = useRecoilState(newInventory)
  const [showSearchModalSheet, setShowSearchModalSheet] = useState(false)
  const [showModalSheet, setShowModalSheet] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [fleetItemData, setFleetItemData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const [error, setError] = useState(null)
  const [dataAdded, setDataAdded] = useState(false)
  const [inventoryReady, setInventoryReady] = useState(false)
  const [inTransport, setInTransport] = useState(false)
  const inputSearchRef = useRef()

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(searchModalSheetWrapperRef, () => {
    setShowSearchModalSheet(false)
    setStatus(null)
    // isMobile && document.body.classList.remove("virtual-keyboard-active")
    // isIOS && document.body.classList.remove("is-ios")
  })

  const handleSendInventoryForRegistration = useCallback(
    async (fleetItem, reOpen = false) => {
      setLoading(true)
      await axios
        .post("/api/hll/inventoryRegistrationAdd", {
          body: {
            inventoryOccasionId:
              getLastPartFromUrl(pathname) ??
              newInventoryState?.inventoryOccasionId,
            fleetItemNumber: fleetItem?.number,
            branchId:
              parseInt(userState?.internal?.depot?.id) ??
              parseInt(newInventoryState?.depot?.id),
            createdBy: `${oidcUser?.name} - ${oidcUser?.preferred_username}`
          }
        })
        .then((response) => {
          if (response?.status !== 200) {
            setStatus(null)
            setError(null)
            toast.error(
              t(
                "Unfortunately, the machine could not be registered, please try again."
              )
            )

            window?.dataLayer?.push({
              event: "inventory_registration_error",
              company: userState?.internal?.region?.regionName ?? "",
              depot: userState?.internal?.depot?.name ?? "",
              createdAt: dateAndTimeNow(),
              userType: "internal"
            })

            return
          }
          setNewInventoryState((previousInventoryState) => {
            // -> Set newInventoryState
            return {
              ...previousInventoryState,
              registrations: [
                fleetItem,
                ...previousInventoryState?.registrations
              ]
            }
          })

          window?.dataLayer?.push({
            event: "inventory_registration_success",
            company: userState?.internal?.region?.regionName ?? "",
            depot: userState?.internal?.depot?.name ?? "",
            createdAt: dateAndTimeNow(),
            userType: "internal"
          })

          setDataAdded(true)
        })
        .catch((error) => {
          console.log(error)
          setStatus("error")
          setError(t("Something went wrong, try again."))
          //toast.error(t("Something went wrong, try again."))
        })
        .finally(() => {
          setLoading(false)
          setShowModalSheet(false)

          if (reOpen) {
            setShowSearchModalSheet(true) // -> Reopen search modal
          }
        })
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      newInventoryState?.depot?.id,
      newInventoryState?.inventoryOccasionId,
      oidcUser?.name,
      oidcUser?.preferred_username,
      setNewInventoryState,
      t
    ]
  )

  const handleDepotTransfer = () => {
    setShowModalSheet(false)

    toast.success(t("Your transfer has been started"), {
      duration: 1000
    })

    handleSendInventoryForRegistration(fleetItemData, true)
  }

  // Handle search
  const handleSearch = async (value) => {
    setError(null)
    setStatus(null)
    setSearchText(value)
    setLoading(true)

    await axios
      .post("/api/hll/fleetItemQuery", {
        limit: 1,
        offset: 0,
        body: {
          number: {
            logicalOperator: "And",
            predicate: "Equal",
            value: value // -> Tex FleetItemNumber
          }
        }
      })
      .then((res) => {
        if (res.status === 200 && res?.data) {
          const data = res?.data?.data[0]

          if (data) {
            if (
              parseInt(data?.branchId) ===
                parseInt(userState?.internal?.depot?.id) &&
              !data?.inTransport
            ) {
              // -> If the machine is in the same depot as the user
              handleSendInventoryForRegistration(data)
            } else if (data?.inTransport) {
              // -> If the machine is in transport
              setFleetItemData(data)
              setLoading(false)
              setShowSearchModalSheet(false)
              setShowModalSheet(true)
              setInTransport(true)
            } else {
              setFleetItemData(data)
              setLoading(false)
              setShowSearchModalSheet(false)
              setShowModalSheet(true)
              setInTransport(false)
            }
          } else {
            setStatus("noResult")
            setLoading(false)
            console.log("noResult")
            setInTransport(false)
          }
        }
      })
      .catch((error) => {
        console.log(error)
        setStatus("error")
        setError(error?.message)
        setLoading(false)
        setInTransport(false)
      })
  }

  // Handle clear
  const handleClear = () => {
    setSearchText("")
    setError(null)
    setStatus(null)
  }

  // Request current inventory save it to recoil state
  const requestInventory = useCallback(async () => {
    setLoading(true)
    setInventoryReady(false)
    await axios
      .post("/api/hll/inventoryRegistration", {
        inventoryOccasionId:
          state?.inventoryOccasionId ?? getLastPartFromUrl(pathname),
        userAccountId: oidcUserID
      })
      .then((response) => {
        setNewInventoryState((previousInventoryState) => {
          return {
            ...previousInventoryState,
            registrations: response?.data ?? []
          }
        })
      })
      .catch((err) => {
        console.log("error", err)
      })
      .finally(() => {
        setLoading(false)
        setInventoryReady(true)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUserID, pathname, setNewInventoryState, state?.inventoryOccasionId])

  // Set view state to newInventory
  useEffect(() => {
    if (hasInventoryPermission) {
      setViewState("newInventory")
      requestInventory()

      setNewInventoryState((previousInventoryState) => {
        return {
          ...previousInventoryState,
          pageHeader: state?.name,
          inventoryOccasionId: state?.inventoryOccasionId
        }
      })
    }
  }, [
    hasInventoryPermission,
    requestInventory,
    setNewInventoryState,
    setViewState,
    state
  ])

  // Set isReady
  useEffect(() => {
    setTimeout(() => {
      if (dataAdded) {
        toast.success(t("The machine is now registered"), {
          duration: 1000
        })
        handleClear()
        setDataAdded(false)
      }
    }, 500)
  }, [dataAdded, t])

  // Set virtual keyboard active class
  // useEffect(() => {
  //   if (!isKeyboardOpen) {
  //     isMobile && document.body.classList.remove("virtual-keyboard-active")
  //     isIOS && document.body.classList.remove("is-ios")
  //   } else {
  //     isMobile && document.body.classList.add("virtual-keyboard-active")
  //     isIOS && document.body.classList.add("is-ios")
  //   }
  // })

  if (!hasInventoryPermission) {
    return <PageNotFound />
  }

  if (!inventoryReady) {
    return (
      <div className="container-fluid container-fluid-max-lg">
        <div className="row">
          <div className="col-12 text-center">
            <Loader className="mt-5 mb-2" />
            <p>{t("Retrieves your list of inventoried individuals")}</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {showSearchModalSheet && <Overlay />}
      {showModalSheet && <Overlay />}

      {newInventoryState?.registrations &&
        newInventoryState?.registrations.length > 0 && ( // -> If registrations exists
          <div className="container-fluid container-fluid-max-width">
            <Registration data={newInventoryState?.registrations} />
          </div>
        )}

      {newInventoryState?.registrations &&
        newInventoryState?.registrations.length === 0 && ( // -> If no registrations exists
          <div className="container-fluid container-fluid-max-lg mt-5">
            <div className="row">
              <div className="col-12 text-center">
                <p>{t("You have not yet registered any machines")}</p>
              </div>
            </div>
          </div>
        )}

      <ActionFooter>
        <button
          disabled={loading || !inventoryReady}
          className="btn btn-primary w-100 d-block"
          onClick={() => setShowSearchModalSheet(true)}>
          <div className="d-flex align-items-center justify-content-center">
            <CustomSvgIcon
              width={16}
              height={16}
              name="add"
              className={`mr-2`}
            />
            <span>{t("Add")}</span>
          </div>
        </button>
      </ActionFooter>

      <ModalSheet
        show={showModalSheet && !showSearchModalSheet}
        roundedBig={true}
        maxWidth={false}
        shadow={false}>
        <div className="position-relative m-0">
          <div className="row mb-3">
            <div className="col-12">
              <div className="mb-3">
                {inTransport ? (
                  <>
                    <h2 className="mb-0">{t("Transported")}</h2>
                    <p className="text-truncate text-muted d-block">
                      {`${t("During transport to")} ${
                        fleetItemData?.branchName
                          ? fleetItemData?.branchName
                          : ""
                      }`}
                    </p>
                  </>
                ) : fleetItemData?.status < 1 ? (
                  <h2 className="mb-0">
                    {machineStatus(fleetItemData?.status)}
                  </h2>
                ) : (
                  <>
                    <h2 className="mb-0">{t("Machine transfer")}</h2>
                    <p className="text-truncate text-muted d-block">
                      {`${t("Transfer to")} ${
                        userState?.internal?.depot?.name ??
                        userState?.internal?.depot?.name
                      }`}
                    </p>
                  </>
                )}
              </div>
              {fleetItemData && (
                <p className="text-truncate text-body d-block mb-0">
                  <strong>
                    {fleetItemData?.number && fleetItemData?.number}
                    {" - "}
                    {fleetItemData?.name && fleetItemData?.name}
                  </strong>
                </p>
              )}
              {!inTransport && fleetItemData?.status > 0 && (
                <small className="d-block text-danger mt-3 mb-0">
                  {t(
                    "Warning: This action is not reversible. Please be certain."
                  )}
                </small>
              )}

              {fleetItemData?.status > -4 && fleetItemData?.status < 1 && (
                <small className="d-block text-danger mt-3 mb-0">
                  {t("Unfortunately, this machine cannot be inventoried.")}
                </small>
              )}

              {fleetItemData?.status < -3 && (
                <small className="d-block text-danger mt-3 mb-0">
                  {t(
                    "Warning: This action is not reversible. Please be certain."
                  )}
                </small>
              )}
            </div>
          </div>
          <div className="text-center mt-4 border-top pt-4">
            <div className="d-flex align-items-center justify-content-between">
              {!inTransport && (
                <button
                  disabled={loading}
                  className="btn btn-outline-secondary w-100 mr-3"
                  onClick={() => {
                    setShowSearchModalSheet(true)
                    setShowModalSheet(false)
                  }}>
                  {t("Cancel")}
                </button>
              )}
              {inTransport ? (
                <button
                  disabled={loading}
                  className="btn btn-primary w-100"
                  onClick={() =>
                    handleSendInventoryForRegistration(fleetItemData)
                  }>
                  {t("Submit inventory")}
                </button>
              ) : (
                fleetItemData?.status > 0 && (
                  <button
                    disabled={loading || fleetItemData?.status < 1}
                    className="btn btn-primary w-100"
                    onClick={() => handleDepotTransfer()}>
                    {t("Submit inventory and transfer")}
                  </button>
                )
              )}

              {fleetItemData?.status < -3 && (
                <button
                  disabled={loading}
                  className="btn btn-primary w-100"
                  onClick={() => handleDepotTransfer()}>
                  {t("Submit inventory and transfer")}
                </button>
              )}
            </div>
          </div>
        </div>
      </ModalSheet>

      <div ref={searchModalSheetWrapperRef}>
        <ModalSheet
          show={showSearchModalSheet && inventoryReady && !showModalSheet}
          roundedBig={true}
          maxWidth={false}
          shadow={false}>
          <div className="position-relative m-0">
            <SearchForm
              searchText={searchText}
              setSearchText={setSearchText}
              onSubmitHandler={handleSearch}
              disabled={loading || dataAdded}
              status={status}
              setStatus={setStatus}
              error={error}
              setError={setError}
              loading={loading}
              dataAdded={dataAdded}
              inputRef={inputSearchRef}
              show={showSearchModalSheet && inventoryReady && !showModalSheet}
            />
          </div>
        </ModalSheet>
      </div>
    </>
  )
}
