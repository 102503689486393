import React from "react"

const DepotSelect = ({
  item,
  label,
  selectedLabel,
  handleDepotChange,
  depots
}) => {
  return (
    <>
      <label
        htmlFor="date"
        className="field__label d-block form-label mb-1 text-uppercase">
        {label}
      </label>
      <select
        className="form-select"
        style={{ height: 50 }}
        value={item.newBranchId}
        onChange={handleDepotChange}>
        <option value="0">{selectedLabel}</option>

        {depots &&
          depots.map(({ id, name }) => {
            return (
              <option value={id} key={id}>
                {name}
              </option>
            )
          })}
      </select>
    </>
  )
}

export default DepotSelect
