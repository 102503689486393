import React, { useEffect, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import Storage from "../../../../utils/storage"

import axios from "axios"
import toast from "react-hot-toast"
import Loader from "../../../Loader/Loader"

export default function OustandingRentSettingsForm({
  customerData,
  setCustomerData
}) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const { customerSettings } = customerData

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    shouldUnregister: true,
    mode: "all"
  })

  const handleOnChange = useCallback(
    async (data) => {
      setLoading(true)

      const day = Number(data?.day)
      const frequency = Number(data?.frequency)
      const oustandingRentNotificationForAllSites = Boolean(
        data?.oustandingRentNotificationForAllSites
      )

      const body = {
        ...customerData,
        customerSettings: {
          oustandingRentNotificationForAllSites,
          oustandingRentNotificationFrequency: {
            day,
            frequency
          }
        }
      }

      await axios
        .post("/api/hll/updateCustomer", {
          body
        })
        .then((response) => {
          if (response.status === 200) {
            Storage.set("customer", response?.data, "local")
            setCustomerData(response?.data)
            toast.success(t("Your company profile is updated"))
          }
        })
        .catch((error) => {
          console.error(error)
          toast.error(t("Something went wrong, try again."))
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [customerData, setCustomerData, t]
  )

  useEffect(() => {
    const subscription = watch(handleSubmit(handleOnChange))
    return () => subscription.unsubscribe()
  }, [handleOnChange, handleSubmit, watch])

  return (
    <form>
      <fieldset disabled={loading}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="card-header-title text-truncate">
                    {t("Mailing settings for outstanding rents")}
                  </h2>
                  {loading && <Loader />}
                </div>
              </div>
              <div className={`card-body${loading ? " is-disabled" : ""}`}>
                <div className="row">
                  <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <label
                      htmlFor="day"
                      className="field__label form-label mt-1 mb-1 text-uppercase">
                      {t("Day of mailing")}
                    </label>
                    <select
                      id="day"
                      className="form-select form-control-modal"
                      defaultValue={
                        customerSettings?.oustandingRentNotificationFrequency
                          ?.day
                      }
                      onChange={(e) => {
                        setValue("day", e.target.value)
                        handleOnChange()
                      }}
                      {...register("day", { required: true })}>
                      <option value="1">{t("Monday")}</option>
                      <option value="2">{t("Tuesday")}</option>
                      <option value="3">{t("Wednesday")}</option>
                      <option value="4">{t("Thursday")}</option>
                      <option value="5">{t("Friday")}</option>
                      <option value="6">{t("Saturday")}</option>
                      <option value="0">{t("Sunday")}</option>
                    </select>
                    {errors["day"] && (
                      <span className="text-danger d-block font-xs mt-2">
                        {errors["day"].message}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <label
                      htmlFor="frequency"
                      className="field__label form-label mt-1 mb-1 text-uppercase">
                      {t("Frequency of mailing")}
                    </label>
                    <select
                      id="frequency"
                      className="form-select form-control-modal"
                      defaultValue={
                        customerSettings?.oustandingRentNotificationFrequency
                          ?.frequency
                      }
                      onChange={(e) => {
                        setValue("frequency", e.target.value)
                        handleOnChange()
                      }}
                      {...register("frequency", { required: true })}>
                      <option value="1">{t("Every week")}</option>
                      <option value="2">{t("Even week")}</option>
                      <option value="3">{t("Odd week")}</option>
                      <option value="4">{t("The last of the month")}</option>
                    </select>
                    {errors["frequency"] && (
                      <span className="text-danger d-block font-xs mt-2">
                        {errors["frequency"].message}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <label
                      htmlFor="oustandingRentNotificationForAllSites"
                      className="field__label form-label mt-1 mb-1 text-uppercase">
                      {t("Report settings")}
                    </label>
                    <div className="d-flex align-items-center mt-2">
                      <input
                        id="oustandingRentNotificationForAllSites"
                        type="checkbox"
                        checked={
                          customerSettings?.oustandingRentNotificationForAllSites
                        }
                        className="form-check-input list-checkbox mt-0 flex-shrink-0"
                        onChange={(e) => {
                          setValue(
                            "oustandingRentNotificationForAllSites",
                            e.target.value
                          )
                          handleOnChange()
                        }}
                        {...register("oustandingRentNotificationForAllSites")}
                      />
                      <p className="ml-3 mb-0">
                        {t("All projects in the same mailing")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  )
}
