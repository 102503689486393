import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import Storage from "../../utils/storage"
import { useOidcUser, useOidc } from "@axa-fr/react-oidc"

import axios from "axios"

import Loader from "../Loader/Loader"
import Alert from "../Common/Alert"
import PageLoader from "../PageLoader/PageLoader"
import { setUserSettings } from "../../utils/hooks"
import { useOidcIdToken } from "@axa-fr/react-oidc"

const ClientCompanyModal = ({
  tenants,
  setTenants,
  customer,
  setCustomer,
  showClientCompanyModal,
  setShowClientCompanyModal,
  switchClientAccounts,
  setSwitchClientAccounts
}) => {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()
  const { idToken } = useOidcIdToken()
  const { logout } = useOidc()
  const oid = oidcUser?.tid
  const perm = oidcUser?.perm
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [tenant, setTenant] = useState(null)
  const [rememberTentant, setRememberTenant] = useState(false)
  const [permission, setPermission] = useState(false)

  const userState = Storage.get("userState") ?? null
  const tenantId = userState?.tenantId ?? null

  const logoutCallback = React.useCallback(async () => {
    const logoutUrl = `${process.env.REACT_APP_AUTH_URL}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.REACT_APP_PUBLIC_URL}`
    await logout()
    document.location.href = logoutUrl
  }, [idToken, logout])

  const handleLogOut = () => {
    Storage.remove("userGroups")
    Storage.remove("customer", "local")
    Storage.remove("subsidiaries", "local")
    Storage.remove("userState", "local")
    Storage.remove("internalCompanies", "local")
    Storage.remove("internalRegions", "local")
    Storage.remove("depots", "local")
    logoutCallback()
  }

  const handleReloadWithError = () => {
    Storage.set(
      "userState",
      {
        ...userState,
        tenantId: null
      },
      "local"
    )
    setUserSettings({
      ...userState,
      tenantId: null
    })
    window.location.reload()
  }

  const setCustomerData = async (tenantId) => {
    setLoading(true)
    setSwitchClientAccounts(false)

    await axios
      .post("/api/hll/customer", {
        customerId: tenantId
      })
      .then((response) => {
        if (response?.data) {
          Storage.set("customer", response?.data, "local")
          setCustomer(response?.data)

          if (rememberTentant) {
            Storage.set(
              "userState",
              {
                ...userState,
                tenantId: tenant
              },
              "local"
            )
            setUserSettings({
              ...userState,
              tenantId: tenant
            })
          }

          window.location.reload()
        }
      })
      .catch(() => {
        setShowClientCompanyModal(true)
        setLoading(false)
        setError(true)
        setErrorMsg(t("Something went wrong when trying to fetch tenants"))
      })
  }

  const handleChangeTenant = ({ target: { value } }) => {
    if (!value) {
      setTenant(null)
      return
    }

    const parsedTenant = JSON.parse(value)
    setTenant(parsedTenant?.id)
  }

  const handleSetCompany = async (companyObj) => {
    const tenant = companyObj
    const parsedTenant = JSON.parse(tenant)

    setLoading(true)

    await axios
      .post("/api/hll/customer", {
        customerId: parsedTenant?.id
      })
      .then((response) => {
        if (response?.data) {
          Storage.set("customer", response?.data, "local")
          setCustomer(response?.data)
          setShowClientCompanyModal(false)
          window.location.reload()
        }
      })
      .catch((error) => {
        setError(true)
        setErrorMsg(`Error code: ${error.response.data.CorrelationId}`)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    let unmounted = false

    const requestTenant = async () => {
      setPermission(true)
      setLoading(true)

      await axios
        .post("/api/hll/tenant", {
          customerId: oid
        })
        .then((response) => {
          if (!unmounted && response.data) {
            const hasSubsidiaries = response.data?.hasSubsidiaries
            const subsidiaries = response.data?.organisationTree?.subsidiaries

            Storage.set("subsidiaries", subsidiaries, "local")

            if (hasSubsidiaries && subsidiaries.length === 1) {
              const companyObj = subsidiaries?.[0]
              setTenants(companyObj)
              handleSetCompany(JSON.stringify(companyObj))
              setLoading(false)
            } else if (hasSubsidiaries) {
              if (switchClientAccounts || tenantId === null) {
                setShowClientCompanyModal(true)
                setLoading(false)
              } else {
                setShowClientCompanyModal(false)
                setCustomerData(tenantId)
              }

              setTenants(response.data)
            }
          }
        })
        .catch((error) => {
          //console.log(error)
          setPermission(false)
          setError(true)
          setErrorMsg(t("You have no permission"))
          setLoading(false)
          setShowClientCompanyModal(true)
        })
    }

    if (!customer && oid && !perm.includes("MinaSidor.SkapaRetur")) {
      requestTenant()
    }

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oid,
    perm,
    customer,
    setShowClientCompanyModal,
    setTenants,
    tenantId,
    switchClientAccounts
  ])

  if (error && errorMsg && permission) {
    return (
      <div className="container mt-3">
        <div className="alert alert-danger rounded-0" role="alert">
          {errorMsg}
        </div>
      </div>
    )
  }

  if (loading) {
    return <PageLoader />
  }

  return (
    <Modal
      show={showClientCompanyModal}
      centered
      animation={false}
      onHide={() => {}}
      style={{ zIndex: 1045 }}>
      <Modal.Header className="justify-content-center">
        {!loading && (
          <h3 className="header-title mt-3 mb-0 text-center">
            {tenants?.name && tenants.name}
          </h3>
        )}
      </Modal.Header>
      <Modal.Body className="text-center pt-0">
        {loading && <Loader className="my-5" />}

        {error && errorMsg && !loading && (
          <>
            {permission && (
              <>
                <Alert className="text-center my-4" message={errorMsg} />
                <button
                  className="btn btn-primary"
                  onClick={handleReloadWithError}>
                  {t("Click to reload!")}
                </button>
              </>
            )}

            {!permission && (
              <>
                <h1>{t("You have no permission")}</h1>
                <p>
                  {t("Please contact an administrator.")}{" "}
                  <a href="mailto:info@hllab.se">info@hllab.se</a>
                </p>
                <button className="btn btn-primary mr-3" onClick={handleLogOut}>
                  {t("Log out")}
                </button>
              </>
            )}
          </>
        )}

        {!loading && !error && (
          <p>
            {t(
              "We see that you have several accounts with HLL, which account do you want to log in to into?"
            )}
          </p>
        )}
        {!loading && !error && tenants?.organisationTree?.subsidiaries && (
          <div className="field col-12 mb-3">
            <select
              className="form-select mx-auto w-100"
              style={{ width: "auto" }}
              onChange={handleChangeTenant}>
              <option value="">{t("Choose")}</option>
              {tenants?.organisationTree?.subsidiaries.map((item, i) => (
                <option key={i} value={JSON.stringify(item)}>
                  {item?.name}
                </option>
              ))}
            </select>

            <div className="mt-3 text-left d-flex">
              <input
                type="checkbox"
                className="form-check-input list-checkbox flex-shrink-0"
                id="rememberTenant"
                onChange={() => setRememberTenant(!rememberTentant)}
              />
              <label htmlFor="rememberTenant" className="ml-2 font-we">
                {t("Remember my choice")}
              </label>
            </div>

            <div className="mt-4">
              <button
                type="button"
                disabled={!tenant}
                className="btn btn-primary w-100"
                onClick={() => setCustomerData(tenant)}>
                {t("OK")}
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ClientCompanyModal
