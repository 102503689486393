import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"

import Alert from "../../components/Common/Alert"
import Loader from "../../components/Loader/Loader"

import axios from "axios"
import toast from "react-hot-toast"

export default function DeleteProjectModal({
  handleCancel,
  show,
  setShowDeleteProjectModal,
  isEditable
}) {
  const { t } = useTranslation()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const navigate = useNavigate()

  const handleDeleteProject = async () => {
    setLoading(true)
    setIsDisabled(true)

    await axios
      .post(`/api/hll/deleteProject`, {
        id
      })
      .then((response) => {
        if (response) {
          setShowDeleteProjectModal(false)
          toast.success(t("The project is deleted"))
          navigate(`/${t("project")}`)
        }
      })
      .catch((error) => {
        console.error(error)
        setError(true)
        toast.error(t("Something went wrong, try again."))
      })
      .finally(() => {
        setLoading(false)
        setIsDisabled(false)
      })
  }

  return (
    <Modal show={show} centered animation={false}>
      <Modal.Header>
        <h2 className="header-title">{t("Delete project")}</h2>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loader />}
        {!loading && isEditable && (
          <p>{t("Do you really want to delete the project")}?</p>
        )}
        {!loading && !isEditable && (
          <p>
            {t(
              "Sorry, this project can not be deleted as there are already ongoing or previous order on the project"
            )}
            .
          </p>
        )}
        {error && (
          <Alert
            message={t("Something went wrong, please refresh current page.")}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-dark" onClick={handleCancel}>
          {t("Cancel")}
        </button>
        {isEditable && (
          <button
            disabled={isDisabled}
            className={`btn btn-primary${isDisabled ? " disabled" : ""}`}
            onClick={handleDeleteProject}>
            {t("Continue")}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
