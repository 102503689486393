import React, { useEffect, useRef, useState } from "react"
import { Dropdown } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import { useTranslation } from "react-i18next"
import CustomDropDownToggle from "../Common/CustomDropDownToggle"
import CustomDropdownMenu from "../Common/CustomDropdownMenu"
import { useMediaQuery } from "react-responsive"
import Moment from "react-moment"
import "moment-timezone"
import CustomSvgIcon from "../Common/CustomSvgIcon"

function Contact({
  data,
  index,
  data: {
    id,
    firstName,
    lastName,
    title,
    email,
    phone1,
    phone2,
    dateOfBirth,
    endDate
  },
  hasUserAccount,
  updateAction,
  updatedContact,
  onHandleInvite,
  onHandleEdit,
  onHandleDelete,
  setShowModalSheet,
  setModalSheetData
}) {
  const { t } = useTranslation()
  const tableRowRef = useRef()
  const [isBlocked, setIsBlocked] = useState(false)

  const phoneMarkup = () => {
    let markup = ""

    if (phone1) {
      markup += phone1
    }

    if (phone2) {
      markup += `<br />${phone2}`
    }

    return { __html: markup }
  }

  useEffect(() => {
    if (
      updatedContact &&
      updatedContact?.id === id &&
      updateAction === "delete"
    ) {
      setTimeout(() => {
        tableRowRef.current.parentNode.removeChild(tableRowRef.current)
      }, 500)
    }
  }, [updatedContact, updateAction, id])

  useEffect(() => {
    if (!endDate) {
      setIsBlocked(false)
    } else {
      const now = new Date()

      if (now > new Date(endDate)) {
        setIsBlocked(true)
      } else {
        setIsBlocked(false)
      }
    }
  }, [endDate])

  return (
    <>
      <tr ref={tableRowRef} className={index}>
        {useMediaQuery({ query: "(max-width: 990px)" }) && (
          <td>
            <button
              className="bg-transparent d-flex align-items-center"
              onClick={() => {
                setShowModalSheet(true)
                setModalSheetData(data)
              }}>
              <span className="d-block svg-icon-wrapper">
                <CustomSvgIcon
                  name="dot-navigation"
                  width={22}
                  height={24}
                  className={`text-muted`}
                />
              </span>
            </button>
          </td>
        )}
        <td
          className={`${
            isBlocked ? "is-disabled text-danger" : ""
          }`}>{`${firstName} ${lastName}`}</td>
        <td className={`${isBlocked ? "is-disabled text-danger" : ""}`}>
          {title}
        </td>
        <td className={`${isBlocked ? "is-disabled text-danger" : ""}`}>
          {email}
        </td>
        <td
          className={`${isBlocked ? "is-disabled text-danger" : ""}`}
          dangerouslySetInnerHTML={phoneMarkup()}></td>
        <td className={`${isBlocked ? "is-disabled text-danger" : ""}`}>
          {dateOfBirth && dateOfBirth}
        </td>
        <td
          className={`text-center${
            isBlocked ? " is-disabled text-danger" : ""
          }`}>
          {hasUserAccount ? (
            <CustomSvgIcon
              width={20}
              height={20}
              name="check-circle-selected-filled"
              className={`text-success with-multiple-paths mt-2`}
            />
          ) : (
            !isBlocked && (
              <button
                type="button"
                className="btn btn-white d-block w-100"
                onClick={() => onHandleInvite(data)}>
                {t("Invite")}
              </button>
            )
          )}

          {endDate && (
            <div className="mt-2">
              <small>
                {`${t("Expiry date")}: `}
                <Moment locale="sv" dateTime={endDate} format="YYYY-MM-DD">
                  {endDate}
                </Moment>
              </small>
            </div>
          )}
        </td>

        {useMediaQuery({ query: "(min-width: 991px)" }) && (
          <td>
            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle as={CustomDropDownToggle}>
                <span className="d-block svg-icon-wrapper">
                  <CustomSvgIcon
                    name="dot-navigation"
                    width={22}
                    height={24}
                    className={`text-muted`}
                  />
                </span>
              </Dropdown.Toggle>

              <DropdownMenu
                as={CustomDropdownMenu}
                className="position-fixed"
                style={{ zIndex: "999" }}>
                {!hasUserAccount && !isBlocked && (
                  <DropdownItem onClick={() => onHandleInvite(data)}>
                    {t("Invite")}
                  </DropdownItem>
                )}
                <DropdownItem onClick={() => onHandleEdit(data)}>
                  {t("Edit")}
                </DropdownItem>
                <DropdownItem onClick={() => onHandleDelete(data)}>
                  {t("Delete contact")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </td>
        )}
        {useMediaQuery({ query: "(max-width: 990px)" }) && <td></td>}
      </tr>
    </>
  )
}

export default Contact
