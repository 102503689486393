import React from "react"
import { useTranslation } from "react-i18next"

const Form = ({
  template,
  handleForm,
  isDisabled,
  nameRequired,
  numberRequired,
  setNameRequired,
  setNumberRequired
}) => {
  const { t } = useTranslation()
  const { register, errors, formData, action, getValues } = handleForm
  const { fields } = template

  const renderFields = (fields) => {
    return fields.map((field) => {
      const { type, title, name, instructions, scope, fieldClassName } = field

      let { required } = field

      let defaultValue =
        typeof formData[name] !== "undefined" ? formData[name] : ""

      if (scope === "address") {
        defaultValue =
          typeof formData[scope][name] !== "undefined"
            ? formData[scope][name]
            : ""
      }

      const validation = () => {
        const nameValue = getValues("name")
        const numberValue = getValues("number")

        if (nameValue !== "" && numberValue === "") {
          setNameRequired({
            value: true,
            message: t("Required field")
          })
          setNumberRequired(null)
        } else if (nameValue === "" && numberValue !== "") {
          setNameRequired(null)
          setNumberRequired({
            value: true,
            message: t("Required field")
          })
        } else if (nameValue === "" && numberValue === "") {
          setNameRequired({
            value: true,
            message: t("You must enter a name or a number")
          })
          setNumberRequired({
            value: true,
            message: t("You must enter a name or a number")
          })
        } else {
          setNameRequired({
            value: true,
            message: t("You must enter a name or a number")
          })
          setNumberRequired({
            value: true,
            message: t("You must enter a name or a number")
          })
        }
      }

      const onChangeValidation = () => {
        validation()
      }

      if (name === "name") {
        required = nameRequired
      } else if (name === "number") {
        required = numberRequired
      }

      let className =
        typeof fieldClassName !== "undefined"
          ? fieldClassName
          : "col-12 col-md-6 mb-3"

      switch (type) {
        case "action":
          return (
            <input
              key={name}
              type="hidden"
              name={name}
              defaultValue={action}
              {...register(name)}
            />
          )

        case "hidden":
          return (
            <input
              key={name}
              type="hidden"
              name={name}
              defaultValue={defaultValue}
              {...register(name)}
            />
          )

        case "text":
        case "email":
          return (
            <div className={className} key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {required && "*"}
              </label>
              <input
                type={type}
                id={name}
                name={name}
                disabled={isDisabled}
                defaultValue={defaultValue}
                className={`form-control form-control-modal rounded-0${
                  isDisabled ? " disabled" : ""
                }`}
                {...register(name, {
                  required,
                  onChange: () => onChangeValidation()
                })}
              />
              {required && errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}
              {instructions && (
                <span className="text-muted font-xs mt-2">{instructions}</span>
              )}
            </div>
          )

        default:
          return <div className="col-12">Invalid field</div>
      }
    })
  }

  return renderFields(fields)
}

export default Form
