import React, { useState, useEffect } from "react"
import { singleUrl } from "../../utils/helpers"
import { useTranslation } from "react-i18next"
import CustomSvgIcon from "../Common/CustomSvgIcon"

function ListItem({
  id,
  name,
  number,
  status,
  availability,
  orderReturnInfo,
  clickHandler,
  usesAvaliability
}) {
  const { t } = useTranslation()
  const [currentAvailability, setCurrentAvailability] = useState(
    parseInt(availability)
  )

  const ListItemIcon = () => {
    return (
      <CustomSvgIcon
        width={16}
        height={16}
        name="chevron-right"
        className="order-item-icon"
      />
    )
  }

  const availabilityColorMapping = {
    "-1": "control",
    0: "not-available",
    1: "available"
  }

  useEffect(() => {
    const disabledStatusArr = [-6, -7, -8, -9, -10]
    if (status < 0 && status >= -4) {
      //  Status 0,-1,-2,-3,-4 === 0
      setCurrentAvailability(parseInt(0))
    }

    if (disabledStatusArr.includes(parseInt(status))) {
      setCurrentAvailability(parseInt(0))
    }
  }, [status])

  return (
    <button
      onClick={() => clickHandler(singleUrl(`${t("machines")}`, id, name))}
      className={`d-block w-100 p-3 bg-white machine-item`}
      data-availability={availability}>
      <div className="row d-flex align-items-center justify-content-between">
        <div className="col">
          <div className="row">
            <div className="col">
              <span className="d-block font-weight-bold text-left">
                {name && name}
              </span>
            </div>
          </div>
        </div>
        <div className="col-auto">
          <div className="d-flex align-items-center">
            {usesAvaliability && (
              <span
                className={`machine-item-availability text-muted ml-2 availability--${availabilityColorMapping[currentAvailability]}`}
              />
            )}

            <ListItemIcon />
          </div>
        </div>
      </div>

      <div className="bg-light rounded border border-light border-1 mt-3">
        <div className="row d-flex align-items-center justify-content-between g-0">
          <div className="col">
            <span className="d-block text-muted text-gray-700 py-2 px-3 text-left">
              {number && number}
            </span>
          </div>
          <div className="col-auto px-2">
            <div className="d-flex align-items-center justify-content-between">
              {orderReturnInfo && (
                <CustomSvgIcon
                  width={16}
                  height={16}
                  name="file"
                  className="text-muted"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </button>
  )
}

export default ListItem
