import { atom } from "recoil"
import Storage from "../../utils/storage"
import i18n from "../../i18n"

const userState = Storage.get("userState", null, "local") ?? null
const internalRegions = Storage.get("internalRegions", null, "local") ?? []
const depots = Storage.get("depots", null, "local") ?? []

export const inventoryView = atom({
  key: "inventoryViewState",
  default: "initInventory"
})

export const inventory = atom({
  key: "inventoryState",
  default: {
    pageHeader: i18n.t("Inventory"),
    depot: userState?.internal?.depot ?? null,
    inventories: [],
    searching: []
  }
})

export const newInventory = atom({
  key: "newInventoryState",
  default: {
    pageHeader: i18n.t("Inventory"),
    depot: userState?.internal?.depot ?? null,
    inventoryOccasionId: null,
    registrations: []
  }
})

export const newInventoryFind = atom({
  key: "newInventoryFindState",
  default: {
    pageHeader: i18n.t("Find machines"),
    foundMachines: [],
    inventoryRecordData: [],
    fleetItems: [],
    recordIds: [],
    filters: {
      onlyNotFoundFleetItems: true,
      inventories: [],
      internalRegions: internalRegions,
      selectedCompanies:
        internalRegions.map((region) => {
          return region?.regionId
        }) ?? []
    },
    filterQueryData: null
  }
})

export const inventoryAdministration = atom({
  key: "inventoryAdministrationState",
  default: {
    pageHeader: i18n.t("Administration"),
    inventories: []
  }
})

export const inventoryAdministrationCreate = atom({
  key: "inventoryAdministrationCreateState",
  default: {
    pageHeader: i18n.t("Create new inventory"),
    inventory: {
      id: null,
      name: "",
      start: null,
      branchFilters: [],
      fleetModelFilters: []
    },
    internalRegions: internalRegions,
    depots: depots,
    branchFilterList: []
  }
})

export const inventoryAdministrationEdit = atom({
  key: "inventoryAdministrationEditState",
  default: {
    pageHeader: i18n.t("Inventory"),
    inventory: {
      id: null,
      name: null,
      start: null,
      branchFilters: [],
      fleetModelFilters: []
    },
    internalRegions: internalRegions,
    depots: depots,
    branchFilterList: []
  }
})
