import React from "react"
import Search from "../../components/Machines/Search/Search"
import { usePermissionCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"
import Storage from "../../utils/storage"

const Machines = () => {
  const userState = Storage.get("userState") ?? null
  const usesAvaliability =
    userState?.internal?.region?.settings?.usesAvaliability

  const hasMachineAdminPermission = usePermissionCheck(
    "MinaSidor.ProduktExemplarAdministrera"
  )
  if (!hasMachineAdminPermission) {
    return <PageNotFound noPermission={true} />
  }

  return (
    <>
      <div className="container-fluid container-fluid-max-width">
        <Search usesAvaliability={usesAvaliability} />
      </div>
    </>
  )
}

export default Machines
