import React, { useEffect, useState } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useOidcUser } from "@axa-fr/react-oidc"
import { usePermissionCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"
import LockedOrder from "../../components/Order/LockedOrder"
import DirectPayment from "../../components/Order/DirectPayment"
import InsuranceCase from "../../components/Order/InsuranceCase"
import Order from "../../components/Order/Order"
import Loader from "../../components/Loader/Loader"
import Alert from "../../components/Common/Alert"
import { useSetRecoilState } from "recoil"
import {
  orderInformation,
  existingOrderRows,
  view,
  creditLimit,
  debitTypes
} from "../../components/Order/Store"
import CustomerCreditLimit from "../../components/Order/Common/Queries/CustomerCreditLimit"

const EditOrder = () => {
  const { t } = useTranslation()
  const hasPermission = usePermissionCheck("MinaSidor.UthyrningarLäs")
  const { id } = useParams()
  const { oidcUser } = useOidcUser()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState(null)
  const setOrderInformationState = useSetRecoilState(orderInformation)
  const setCreditLimitState = useSetRecoilState(creditLimit)
  const setExistingOrderRows = useSetRecoilState(existingOrderRows)
  const setViewState = useSetRecoilState(view)
  const setDebitTypesState = useSetRecoilState(debitTypes)

  let errorCode = ""

  useEffect(() => {
    let unmounted = false
    setLoading(true)
    setOrderInformationState(null)

    let responseData = {
      data: null,
      creditLimit: null
    }

    const requestDebitTypes = async () => {
      await axios.post("/api/hll/debitTypes").then((response) => {
        if (!unmounted) {
          setDebitTypesState(response.data ?? null)
        }
      })
    }

    const requestCustomerOrder = async () => {
      await axios
        .post("/api/hll/customerOrder", {
          customerOrderId: id
        })
        .then(async (response) => {
          if (!unmounted) {
            responseData.data = response.data ?? null
            responseData.creditLimit = await CustomerCreditLimit(
              responseData.data.customerOrderHead.customerId
            )
          }
        })
        .then(() => {
          setData(responseData.data)
          setOrderInformationState(responseData.data?.customerOrderHead ?? null)
          setExistingOrderRows(responseData.data?.orderRows ?? [])
          setViewState("viewOrder")
          setCreditLimitState(responseData.creditLimit ?? null)
        })
        .catch((err) => {
          console.warn(err, "Error: api/hll/customerOrders")
          // setErrorCode(err?.response?.data?.CorrelationId)
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    requestCustomerOrder()
    requestDebitTypes()

    return () => {
      unmounted = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  // Show alert if you wanna reload or not!
  useEffect(() => {
    window.onbeforeunload = function () {
      return true
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [])

  if (!hasPermission) {
    return <PageNotFound noPermission={true} />
  }

  if (loading) {
    return <Loader className="mt-5 mx-auto text-center" />
  }

  if (!data) {
    if (error) {
      return (
        <Alert
          className="text-center p-5"
          message={`${t(
            "Something went wrong, please refresh current page."
          )}${errorCode}`}
        />
      )
    } else {
      return null
    }
  }

  if (data?.customerOrderHead?.customerType === 1) {
    return <DirectPayment {...data} />
  }

  if (data?.customerOrderHead?.siteType === 5) {
    return <InsuranceCase {...data} />
  }

  const { isLocked, lockedBy, lockedFromClientApp } =
    data?.customerOrderHead?.lock
  const preferredUsername = oidcUser?.preferred_username
  // Låst av någon annan användare!
  if (isLocked && lockedBy !== preferredUsername.toLowerCase()) {
    console.log("Låst av någon annan användare!")
    return <LockedOrder {...data} />
  }

  // Låst av samma användare i HYRse
  if (
    isLocked &&
    lockedBy === preferredUsername.toLowerCase() &&
    lockedFromClientApp !== "HLL.MinaSidor"
  ) {
    console.log("Låst av samma användare i HYRse")
    return <LockedOrder {...data} />
  }

  return <Order />
}

export default EditOrder
