import React from "react"
import { useOidcUser, useOidc } from "@axa-fr/react-oidc"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Logo from "../../assets/images/HLL_notext.svg"
import useMachineContext from "../../context/Machine/Store"
import { useLocation, useNavigate } from "react-router-dom"
import Storage from "../../utils/storage"
import { useOidcIdToken } from "@axa-fr/react-oidc"

import NavAccount from "../../components/Header/NavAccount"
import CustomSvgIcon from "../Common/CustomSvgIcon"

function Header({ handleModal }) {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()
  const { idToken } = useOidcIdToken()
  const { logout } = useOidc()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { machineItem } = useMachineContext()

  const logoutCallback = React.useCallback(async () => {
    const logoutUrl = `${process.env.REACT_APP_AUTH_URL}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.REACT_APP_PUBLIC_URL}`
    await logout()
    document.location.href = logoutUrl
  }, [idToken, logout])

  const sub = oidcUser?.sub
  const perm = oidcUser?.perm

  const handleLogOut = () => {
    Storage.remove("userGroups")
    Storage.remove("customer", "local")
    Storage.remove("subsidiaries", "local")
    Storage.remove("userState", "local")
    Storage.remove("internalCompanies", "local")
    Storage.remove("internalRegions", "local")
    Storage.remove("depots", "local")
    logoutCallback()
  }

  if (pathname === "/machines" || pathname === "/maskiner") {
    return (
      <div className="app-header header bg-dark-theme shadow js-app-header">
        <div className="container-fluid container-fluid-max-width d-flex align-items-center justify-content-between h-100 py-1">
          <div className="d-flex justify-content-start">
            <Link
              to={`/${t("machines")}`}
              className="d-block ml-0"
              aria-label={t("Machines")}>
              <img src={Logo} alt="Hyreslandslagets logotyp" className="logo" />
            </Link>
          </div>
          <div
            className="header-main-title text-center position-absolute"
            style={{
              maxWidth: "50%"
            }}>
            <p className="mb-0 text-reset font-weight-bold">{t("Machines")}</p>
          </div>

          <div className="d-flex justify-content-end">
            <NavAccount
              perm={perm}
              profile={oidcUser}
              sub={sub}
              handleLogOut={handleLogOut}
              handleModal={handleModal}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="app-header header bg-dark-theme shadow js-app-header">
      <div className="container-fluid container-fluid-max-width d-flex align-items-center justify-content-between py-1">
        <div className="d-flex justify-content-start align-items-center">
          {pathname.includes(t("confirmation")) ? (
            <a href={`/${t("machines")}`}>
              <span className="d-flex align-items-center p-2">
                <CustomSvgIcon
                  width={26}
                  height={26}
                  name="chevron-left"
                  className="text-white"
                />
              </span>
            </a>
          ) : (
            <button
              className="border-0 bg-secondary rounded position-relative d-flex align-items-center btn-hamburger justify-content-center"
              onClick={() => {
                navigate(-1)
              }}>
              <span className="d-flex align-items-center p-2">
                <CustomSvgIcon
                  width={26}
                  height={26}
                  name="chevron-left"
                  className="text-white"
                />
              </span>
            </button>
          )}
        </div>
        <div
          className="header-main-title text-center position-absolute"
          style={{
            maxWidth: "50%"
          }}>
          <p className="mb-0 text-reset font-weight-bold">
            {t("Edit machine")}
          </p>
          <p className="mb-0 text-reset">
            {machineItem?.number && machineItem.number}
          </p>
        </div>
        <div className="d-flex justify-content-end">
          <NavAccount
            perm={perm}
            profile={oidcUser}
            sub={sub}
            logout={logout}
            oidcUser={oidcUser}
            handleModal={handleModal}
          />
        </div>
      </div>
    </div>
  )
}

export default Header
