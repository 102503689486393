import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import { useOidcUser } from "@axa-fr/react-oidc"
import { encode } from "base-64"

import CustomSvgIcon from "../../../Common/CustomSvgIcon"
import { useRecoilValue } from "recoil"
import { currentMachineList } from "../Store"

export default function ConfirmationModal({
  showModal,
  setShowConfirmDeleteModal,
  handleDelete,
  loading
}) {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()
  const currentMachineListState = useRecoilValue(currentMachineList)

  return (
    <Modal show={showModal} centered animation={false}>
      <Modal.Header className="d-block border-bottom">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="header-title mb-0 text-left">
            {t("Delete machine list")}
          </h3>
          <button
            onClick={() => {
              setShowConfirmDeleteModal(false)
            }}
            disabled={loading}
            className="position-relative bg-transparent p-0 text-body"
            style={{
              top: "3px"
            }}>
            <CustomSvgIcon
              name="close"
              width={16}
              height={16}
              className="text-black"
            />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="text-center py-3 px-4 bg-white is-scrollable mx-0">
        <h3>{currentMachineListState?.data?.name}</h3>
        {currentMachineListState?.data?.userid !==
          `${encode(oidcUser?.tid)}${encode(oidcUser?.sub)}` && (
          <p>
            {t(
              "The list was created by another person at your company, are you sure you want to delete the list?"
            )}
          </p>
        )}
        <p>{t("This action can't be undone.")}</p>
      </Modal.Body>
      <Modal.Footer className="d-block border-top py-3 px-4">
        <div className="d-flex align-items-center justify-content-center">
          <button
            disabled={loading}
            onClick={() => {
              setShowConfirmDeleteModal(false)
            }}
            className={`btn btn-outline-secondary d-block mr-3 w-100 p-3`}>
            {t("Close")}
          </button>
          <button
            disabled={loading}
            type="submit"
            className={`btn btn-danger d-block w-100 p-3`}
            onClick={handleDelete}>
            {loading ? (
              <div
                className="spinner-border spinner-border-sm text-white"
                role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              t("Delete")
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
