import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Storage from "../../utils/storage"
import { usePermissionCheck } from "../../utils/hooks"
import NavItem from "./NavItem"
import { useLocation, NavLink } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { globalState } from "../../Store"

import FeedbackForm from "../FeedbackForm/FeedbackForm"
import CompanyProfile from "./SecondaryNavigations/CompanyProfile"
import {
  InternalNavigationSchema,
  CustomerNavigationSchema,
  CompanyPermissionNavigationSchema,
  AdminNavigationSchema
} from "../../utils/navigation"

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg"
import { ReactComponent as SwitchHllInternalRegionDepotIcon } from "../../assets/icons/user-house.svg"
import { ReactComponent as SwitchHllCustomerAccountIcon } from "../../assets/icons/user-house.svg"
import { ReactComponent as LogOutIcon } from "../../assets/icons/logout.svg"
import { ReactComponent as UserProfileIcon } from "../../assets/icons/user.svg"
import { ReactComponent as ChevronRightIcon } from "../../assets/icons/chevron-right.svg"
import CustomSvgIcon from "../Common/CustomSvgIcon"

function NavAccount({
  profile,
  handleLogOut,
  handleModal,
  customer,
  setCustomer,
  showClientCompanyModal,
  setSwitchClientAccounts
}) {
  const { t } = useTranslation()
  const location = useLocation()

  const setGlobalState = useSetRecoilState(globalState)

  const userState = Storage.get("userState") ?? null
  const internalNavSchema = InternalNavigationSchema("default", null)
  const customerNavSchema = CustomerNavigationSchema("default", null)
  const companyPermissionNavSchema = CompanyPermissionNavigationSchema(
    "default",
    null
  )

  // make profile picture bigger
  const profilePictureUrl = profile?.picture
    ? profile.picture.replace("?s=32", "?s=80")
    : null

  const adminNavSchema = AdminNavigationSchema()
  const [showNav, setShowNav] = useState(false)
  const [showCompanyProfileSecondaryNav, setShowCompanyProfileSecondaryNav] =
    useState(false)
  const regionName = userState?.internal?.region?.regionName
    ? userState?.internal?.region?.regionName
    : false
  const depot = userState?.internal?.depot?.name
    ? userState?.internal?.depot.name
    : false

  const hasHLLInternPermission = usePermissionCheck("MinaSidor.SkapaRetur")

  const hasCompanyAdminPermission = usePermissionCheck(
    "MinaSidor.DotterbolagLäs"
  )

  const subsidiaries = Storage.get("subsidiaries", "", "local") ?? []

  const handleCloseNav = () => {
    setShowNav(false)
    setShowCompanyProfileSecondaryNav(false)
  }
  const handleShowNav = () => setShowNav(true)

  const handleOpenClientCompanyModal = () => {
    if (!showClientCompanyModal) {
      setCustomer(null)
      setShowNav(false)
      Storage.remove("customer", "local")
      setSwitchClientAccounts(true)
    }
  }

  const handleOpenCompanyProfileSecondaryNav = () => {
    setShowCompanyProfileSecondaryNav(true)
  }

  const handleCloseCompanyProfileSecondaryNav = () => {
    setShowCompanyProfileSecondaryNav(false)
  }

  useEffect(() => {
    const closeNav = (e) => {
      if (e.keyCode === 27) {
        handleCloseNav()
      }
    }

    window.addEventListener("keydown", closeNav)

    return () => window.removeEventListener("keydown", closeNav)
  }, [])

  useEffect(() => {
    handleCloseNav() // Close nav on route change
  }, [location])

  return (
    <div className="ml-auto">
      <div className="row align-items-center justify-content-end pl-4 pr-3">
        {profilePictureUrl && (
          <div className="col-auto d-none d-lg-block">
            <figure className="avatar avatar-sm m-0">
              <img
                src={profilePictureUrl}
                alt={t("Profile picture")}
                className="avatar-img rounded-circle"
              />
            </figure>
          </div>
        )}

        <div className="col ml-n3 d-none d-lg-block">
          <h3 className="m-0 text-white">{profile?.name && profile.name}</h3>

          {hasHLLInternPermission && regionName && (
            <span className="p-0 m-0 text-gray-500 d-flex align-items-center justify-content-start flex-column">
              <span className="w-100">{regionName && regionName}</span>
              <small className="w-100" style={{ marginTop: "-2px" }}>
                {depot && depot}
              </small>
            </span>
          )}

          {!hasHLLInternPermission && customer && (
            <span className="p-0 m-0 text-gray-500 d-flex align-items-center justify-content-start flex-column">
              <span className="w-100">{customer?.name}</span>
              <small className="w-100" style={{ marginTop: "-2px" }}>
                {customer?.accountName ? `${customer?.accountName} ` : ""}
                {customer?.number ? `${customer?.number}` : ""}
              </small>
            </span>
          )}
        </div>

        <div className="col-auto col-gap row-gap d-flex ml-lg-4 px-0 align-items-center justify-content-end">
          <div className="col-auto">
            <div className="d-flex align-items-center justify-content-center col-gap row-gap">
              {!hasHLLInternPermission && (
                <NavLink
                  className="header-nav-btn"
                  end
                  to={`${t("customer")}/${t("machinelists")}`}>
                  <span className="d-block text-white svg-icon-wrapper">
                    <CustomSvgIcon
                      className="text-white"
                      name="file"
                      width={20}
                      height={20}
                    />
                  </span>
                </NavLink>
              )}

              <button
                className="header-nav-btn"
                onClick={() => {
                  setGlobalState((prevState) => ({
                    ...prevState,
                    searchIsVisible: !prevState.searchIsVisible
                  }))
                }}>
                <span className="d-block text-white svg-icon-wrapper">
                  <CustomSvgIcon
                    className="text-white"
                    name="search"
                    width={20}
                    height={20}
                  />
                </span>
              </button>

              <button className="header-nav-btn" onClick={handleShowNav}>
                <span className="d-block text-white svg-icon-wrapper">
                  <CustomSvgIcon
                    className="text-white"
                    name="menu"
                    width={24}
                    height={24}
                  />
                </span>
              </button>
            </div>
          </div>

          <nav
            className={`nav-off-canvas bg-white h-100 text-body position-fixed js-app-off-canvas${
              showNav ? " is-open" : ""
            }`}>
            <div className="nav-off-canvas-inside position-relative h-100">
              <div className="nav-off-canvas-header d-flex justify-content-between align-items-center">
                <div>
                  <h3 className="m-0">{profile?.name && profile.name}</h3>
                  {hasHLLInternPermission && regionName && (
                    <span className="p-0 m-0 text-gray-600 d-flex align-items-center justify-content-start flex-column">
                      <span className="w-100">{regionName && regionName}</span>
                      <small className="w-100">{depot && depot}</small>
                    </span>
                  )}

                  {!hasHLLInternPermission && customer && (
                    <span className="p-0 m-0 text-gray-600 d-flex align-items-center justify-content-start flex-column">
                      <span className="w-100">{customer?.name}</span>
                      <small className="w-100">
                        {customer?.accountName
                          ? `${customer?.accountName} `
                          : ""}
                        {customer?.number ? ` ${customer?.number}` : ""}
                      </small>
                    </span>
                  )}
                </div>
                <button
                  onClick={handleCloseNav}
                  className="border-0 bg-white d-block close-nav-button text-body"
                  style={{ marginRight: "-6px" }}>
                  <span
                    className={`d-block svg-icon-wrapper text-black`}
                    style={{ width: 22, height: 22 }}>
                    <CloseIcon />
                  </span>
                </button>
              </div>
              <div
                className={`nav-off-canvas-body py-4${
                  showCompanyProfileSecondaryNav ? " is-secondary" : ""
                }`}>
                <ul className="nav-off-canvas-nav-list m-0">
                  {internalNavSchema &&
                    internalNavSchema.length > 0 &&
                    internalNavSchema.map((item, index) => {
                      if (!item.visible) {
                        return null
                      }

                      return (
                        <li key={index}>
                          <NavItem
                            text={item?.text}
                            to={item?.link}
                            icon={item?.icon}
                            clickHandler={null}
                          />
                        </li>
                      )
                    })}
                  {customerNavSchema &&
                    customerNavSchema.length > 0 &&
                    customerNavSchema.map((item, index) => {
                      if (!item.visible) {
                        return null
                      }
                      return (
                        <li key={index}>
                          <NavItem
                            text={item?.text}
                            to={item?.link}
                            icon={item?.icon}
                            clickHandler={null}
                          />
                        </li>
                      )
                    })}
                </ul>

                {!hasHLLInternPermission && (
                  <ul className="nav-off-canvas-nav-list nav-user-management mt-3">
                    {companyPermissionNavSchema &&
                      companyPermissionNavSchema.length > 0 &&
                      companyPermissionNavSchema.map((item, index) => {
                        if (!item.visible) {
                          return null
                        }
                        return (
                          <li key={index}>
                            <NavItem
                              text={item?.text}
                              to={item?.link}
                              icon={item?.icon}
                              clickHandler={null}
                            />
                          </li>
                        )
                      })}

                    {hasCompanyAdminPermission && (
                      <li>
                        <button
                          className="link-site-nav w-100"
                          onClick={handleOpenCompanyProfileSecondaryNav}>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <span
                                className="d-block text-primary mr-3 svg-icon-wrapper"
                                style={{ width: 25, height: 25 }}>
                                <UserProfileIcon />
                              </span>

                              <span className="font-weight-bold">
                                {t("Company Profile")}
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="align-self-end">
                                <span
                                  className={`d-block svg-icon-wrapper text-primary text-black`}
                                  style={{
                                    width: 16,
                                    height: 16,
                                    marginTop: "-9px"
                                  }}>
                                  <ChevronRightIcon />
                                </span>
                              </div>
                            </div>
                          </div>
                        </button>
                      </li>
                    )}
                  </ul>
                )}

                <ul className="nav-off-canvas-nav-list m-0">
                  {!hasHLLInternPermission && (
                    <>
                      <li>
                        <a
                          href="https://www.hyreslandslaget.se/villkor/"
                          target="_blank"
                          className="d-flex align-items-center link-site-nav"
                          rel="noopener noreferrer">
                          <span
                            className="fe fe-external-link mr-3 text-primary"
                            style={{ fontSize: 25 }}></span>
                          <span className="font-weight-bold">
                            Hyres-, försäkringsvilkor
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.hyreslandslaget.se/policy/"
                          target="_blank"
                          className="d-flex align-items-center link-site-nav"
                          rel="noopener noreferrer">
                          <span
                            className="fe fe-external-link mr-3 text-primary"
                            style={{ fontSize: 25 }}></span>
                          <span className="font-weight-bold">
                            Integritetspolicy
                          </span>
                        </a>
                      </li>
                    </>
                  )}
                  {!hasHLLInternPermission && (
                    <li>
                      <FeedbackForm />
                    </li>
                  )}
                </ul>

                {adminNavSchema && adminNavSchema.length > 0 && (
                  <ul className="nav-off-canvas-nav-list nav-user-management mt-3">
                    {adminNavSchema.map((item, index) => {
                      if (!item.visible) {
                        return null
                      }
                      return (
                        <li key={index}>
                          <NavItem
                            text={item?.text}
                            to={item?.link}
                            icon={item?.icon}
                            clickHandler={null}
                          />
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>

              <CompanyProfile
                show={showCompanyProfileSecondaryNav}
                close={handleCloseCompanyProfileSecondaryNav}
                handleCloseNav={handleCloseNav}
              />

              <div className="nav-off-canvas-footer position-absolute w-100 d-flex justify-content-between">
                {hasHLLInternPermission && (
                  <button
                    className="bg-white text-primary d-flex align-items-center"
                    onClick={() => {
                      handleModal()
                      setShowNav(false)
                    }}>
                    <span
                      className={`d-block svg-icon-wrapper text-primary mr-3`}
                      style={{ width: 25, height: 25 }}>
                      <SwitchHllInternalRegionDepotIcon />
                    </span>
                    <span className="font-weight-bold link-site-nav text-left">
                      {regionName && depot
                        ? t("Switch region and depot")
                        : t("Choose region and depot")}
                    </span>
                  </button>
                )}
                {!hasHLLInternPermission && subsidiaries.length > 1 && (
                  <button
                    className="bg-white text-primary d-flex align-items-center p-0"
                    title={t("Change customer account")}
                    onClick={handleOpenClientCompanyModal}>
                    <span
                      className={`d-block svg-icon-wrapper text-primary mr-3`}
                      style={{ width: 25, height: 25 }}>
                      <SwitchHllCustomerAccountIcon />
                    </span>

                    <span className="font-weight-bold link-site-nav">
                      {t("Change customer account")}
                    </span>
                  </button>
                )}
                <button
                  className="bg-white text-primary d-flex align-items-center p-0"
                  onClick={handleLogOut}
                  title={t("Log out")}>
                  <span
                    className={`d-block svg-icon-wrapper text-primary mr-3`}
                    style={{ width: 25, height: 25 }}>
                    <LogOutIcon />
                  </span>

                  <span className="font-weight-bold link-site-nav">
                    {t("Log out")}
                  </span>
                </button>
              </div>
            </div>
          </nav>
          <div
            className={`nav-off-canvas-backdrop w-100 h-100 position-fixed${
              showNav ? " is-visible" : ""
            }`}
            onClick={handleCloseNav}></div>
        </div>
      </div>
    </div>
  )
}

export default NavAccount
