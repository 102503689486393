import React, { useState } from "react"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import PermissionForm from "./Form"
import Loader from "../Loader/Loader"
import toast from "react-hot-toast"
import Storage from "../../utils/storage"
import { dateAndTimeNow } from "../../utils/helpers"
import moment from "moment"
import "moment-timezone"
import { hasAuthorizationToRent } from "./helpers"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const ModalForm = ({
  show,
  onHandleHide,
  title,
  submitText,
  formTemplate,
  formData,
  action,
  userGroups,
  contacts
}) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors }
  } = useForm({
    shouldUnregister: true,
    mode: "all"
  })

  const handleForm = {
    register,
    errors,
    formData,
    action,
    setValue,
    setError,
    clearErrors,
    control
  }

  const [loading, setLoading] = useState(false)
  const [responseData, setResponseData] = useState(null)
  const [findingRentalContact, setFindingRentalContact] = useState(false)
  const [rentalContactData, setRentalContactData] = useState(null)

  const customer = Storage.get("customer", null, "local") ?? null

  const handleHide = () => {
    setFindingRentalContact(false)
    setResponseData(null)
    onHandleHide(responseData)
  }

  // Update validTo date on userAcccount if edit end date
  const handleUpdateContact = async () => {
    const data = responseData ?? null
    const rentalContact = rentalContactData
    if (!data) {
      return
    }

    if (!rentalContact) {
      return
    }

    rentalContact.endDate = responseData?.validTo ?? null

    setLoading(true)

    await axios
      .post("/api/hll/customerRentalContactEdit", {
        body: rentalContact
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          toast.success(t("The contact and the user has been saved!"))

          window.dataLayer.push({
            event: "HLL_contact_person_edit",
            company: customer?.name,
            createdAt: dateAndTimeNow(),
            userType: "customer"
          })

          handleHide()
        }
      })
      .catch((error) => {
        console.error(error)
        toast.error(t("Something went wrong, try again."))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSubmit = async (data) => {
    const { action } = data
    const endpoint = {
      invite: "customerUserInvite",
      edit: "customerUserEdit"
    }

    if (!action) {
      return null
    }

    if (!endpoint) {
      return null
    }

    if (data?.userAccountGroups.length === 0) {
      setError("userAccountGroups", {
        type: "custom",
        message: t("You must specify at least one permission.")
      })
      return null // do not continue if error or empty userAccountGroups
    }

    // We can't send the action property to the endpoint.
    delete data.action

    // Check if user as a validTo date
    if (!data?.validTo) {
      data.validTo = null // set key to null if is not exists
    } else {
      // Formating date for API
      data.endDate = moment(data?.endDate).format("YYYY-MM-DD[T]00:00:00[Z]")
    }

    let formattedData = data

    // Format data for user invititation
    if (action === "invite") {
      formattedData = {
        forRegistrationNumber: customer
          ? customer?.corporateIdentityNumber
          : "",
        userAccount: {
          ...data,
          id: "",
          userAccountGroups: data.userAccountGroups
        }
      }
    }

    // Format data for user edit
    if (action === "edit") {
      formattedData = data
    }

    setFindingRentalContact(false)
    setLoading(true)
    setResponseData(null)

    await axios
      .post(`/api/hll/${endpoint[action]}`, {
        body: formattedData
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          window.dataLayer.push({
            event: `HLL_user_${action}`,
            company: customer?.name,
            createdAt: dateAndTimeNow(),
            userType: "customer"
          })

          setResponseData(response.data)

          if (
            action === "edit" &&
            formattedData?.validTo &&
            hasAuthorizationToRent(contacts, formattedData?.email)
          ) {
            setFindingRentalContact(true)
            setRentalContactData(
              hasAuthorizationToRent(contacts, formattedData?.email)
            )
            return null
          } else {
            toast.success(t("The user has been saved!"))
            handleHide()
          }
        }
      })
      .catch((error) => {
        console.error(error)
        if (action === "invite") {
          toast.error(t("Something went wrong, the user may already exist!?"))
        } else {
          toast.error(t("Something went wrong, try again."))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal show={show} onHide={handleHide} centered animation={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header className="justify-content-center">
          <h3 className="h1 header-title mt-3 mb-4 text-center">
            {findingRentalContact ? t("Allowed to rent") : title && title}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {findingRentalContact ? (
              <div className="text-center mx-auto">
                <p
                  className="d-flex align-items-center
     justify-content-center mb-0">
                  <CustomSvgIcon
                    width={16}
                    height={16}
                    name="check-circle-selected-filled"
                    className={`text-success with-multiple-paths mr-2`}
                  />
                  <strong>{responseData?.email}</strong>
                </p>
                <p>{`${t(
                  "We have found a person who is authorized to rent associated with this email address, would you also like to enter an expiration date on that person"
                )}?`}</p>
              </div>
            ) : (
              <PermissionForm
                formTemplate={formTemplate}
                handleForm={handleForm}
                userGroups={userGroups}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading && <Loader />}
          {findingRentalContact ? (
            <>
              <button
                className="btn btn-white"
                type="button"
                onClick={() => {
                  toast.success(t("The user has been saved!"))
                  handleHide()
                }}
                disabled={loading}>
                {t("No")}
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={loading}
                onClick={handleUpdateContact}>
                {t("Yes")}
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-white"
                type="button"
                onClick={handleHide}
                disabled={loading}>
                {t("Close")}
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={loading}>
                {submitText}
              </button>
            </>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ModalForm
