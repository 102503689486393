import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Carousel from "react-bootstrap/Carousel"
import { motion, AnimatePresence } from "framer-motion"
import parse from "html-react-parser"
import Storage from "../../utils/storage"
import { setUserSettings } from "../../utils/hooks"

import CustomSvgIcon from "../Common/CustomSvgIcon"

const OnBoarding = ({ data, ready }) => {
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = useState(0)
  const [show, setShow] = useState(false)
  const [onboardingPosts, setUserOnboardingPosts] = useState([])
  const userState = Storage.get("userState") ?? null

  const bounceTransition = {
    y: {
      duration: 1.0,
      type: "spring"
    }
  }

  const handleIndicator = (selectedIndex) => {
    setActiveIndex(selectedIndex)
  }

  const handlePrevious = () => {
    setActiveIndex(activeIndex - 1)
  }

  const handleNext = () => {
    setActiveIndex(activeIndex + 1)
  }

  // Close onboarding
  const handleClose = () => {
    setUserSettings({
      ...userState,
      onBoarded: true
    })

    Storage.set(
      "userState",
      {
        ...userState,
        onBoarded: true
      },
      "local"
    )
    setShow(false)
  }

  const indicators = (activeIndex) => {
    if (!onboardingPosts) {
      return
    }

    return (
      <div className="d-flex align-items-center">
        <div className="onboarding-indicators">
          {onboardingPosts.map((post, index) => {
            const active = activeIndex === index

            return (
              <button
                type="button"
                key={`post-${post?.id}`}
                onClick={() => handleIndicator(index)}
                className={`d-inline-block onboarding-indicator text-muted${
                  active ? " bg-primary" : ""
                }`}></button>
            )
          })}
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (ready && data && data.length) {
      setUserOnboardingPosts(data)
      setShow(true)
    }
  }, [data, ready, userState])

  useEffect(() => {
    if (show) {
      document.body.classList.add("overflow-hidden")
    }

    return function cleanup() {
      document.body.classList.remove("overflow-hidden")
    }
  }, [show])

  // If no data, don't show onboarding
  if (!show) {
    return null
  }

  // If user has already onboarded, don't show onboarding
  if (userState?.onBoarded) {
    return null
  }

  return (
    <>
      {show && (
        <AnimatePresence>
          <motion.div
            key="overlay"
            className="onboarding-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
          />
        </AnimatePresence>
      )}
      {show && (
        <AnimatePresence>
          <motion.div
            key="onboarding"
            className="onboarding"
            initial={{ opacity: 0, y: 0, x: "-50%" }}
            animate={{ opacity: 1, y: "-50%", x: "-50%" }}
            exit={{ opacity: 0, y: 0, x: "-50%" }}
            transition={bounceTransition}>
            <div className="container-fluid p-0 h-100">
              {onboardingPosts && onboardingPosts.length && (
                <div className="container-md p-0 h-100">
                  <>
                    <button
                      onClick={() => handleClose()}
                      className="close-button d-flex align-items-center justify-content-center bg-white rounded-circle position-absolute p-0 text-body">
                      <CustomSvgIcon
                        name="close"
                        width={12}
                        height={12}
                        className="text-black"
                      />
                    </button>

                    <Carousel
                      activeIndex={activeIndex}
                      interval={null}
                      indicators={false}
                      controls={false}
                      slide={false}>
                      {onboardingPosts.map((post, index) => {
                        const key = `post-${post?.id}`
                        const title = post?.title?.rendered
                        const subTitle = post?.acf?.sub_title
                        const content = post?.content?.rendered
                        const image =
                          post["_embedded"]?.["wp:featuredmedia"]?.[0]
                        const imageSrc = image?.source_url
                        const imageAlt = image?.alt_text

                        const last = data?.length - 1 === index
                        const first = index === 0

                        return (
                          <Carousel.Item
                            key={key}
                            className="position-relative">
                            <div>
                              <header className="onboarding-header">
                                <div className="onboarding-image-area">
                                  <div className="aspect-ratio-box">
                                    <div className="aspect-ratio-box-inside">
                                      <div className="flexbox-centering">
                                        {imageSrc && (
                                          <img
                                            className="img-fluid"
                                            src={imageSrc}
                                            alt={imageAlt ? imageAlt : ""}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </header>

                              <section className="onboarding-content container-fluid p-5">
                                {subTitle && (
                                  <small className="d-block text-uppercase mb-2 onboarding-sub-title text-muted">
                                    {subTitle}
                                  </small>
                                )}
                                <h2 className="h1">{title && title}</h2>
                                {content && parse(content)}
                              </section>

                              <footer className="onboarding-footer border-top">
                                <div className="container-fluid py-4">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                      <button
                                        disabled={first}
                                        className={`btn btn-outline-primary d-inline-block px-4`}
                                        onClick={() => handlePrevious()}
                                        style={{
                                          opacity: first ? 0 : 1
                                        }}>
                                        {t("Back")}
                                      </button>
                                    </div>

                                    <div className="d-none d-md-block">
                                      {indicators(activeIndex)}
                                    </div>

                                    <div>
                                      {!last && (
                                        <button
                                          disabled={last}
                                          className={`btn btn-outline-primary d-inline-block px-4 ml-3${
                                            last ? " is-disabled" : ""
                                          }`}
                                          onClick={() => handleNext()}>
                                          {t("Next")}
                                        </button>
                                      )}

                                      {last && (
                                        <button
                                          className={`btn btn-primary d-block d-md-inline-block px-4 ml-3`}
                                          onClick={() => handleClose()}>
                                          {t("Finish")}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-center d-md-none mt-5">
                                    <div>{indicators(activeIndex)}</div>
                                  </div>
                                </div>
                              </footer>
                            </div>
                          </Carousel.Item>
                        )
                      })}
                    </Carousel>
                  </>
                </div>
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </>
  )
}

export default OnBoarding
