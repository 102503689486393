import React, { useState } from "react"

import axios from "axios"
import { useForm } from "react-hook-form"
import { billingMethodFormTemplate } from "./FormTemplate"
import {
  billingMethodTypes,
  billingMethodTypeDisabledLogic
} from "../../../../utils/helpers"

import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Loader from "../../../Loader/Loader"
import toast from "react-hot-toast"

import { billingMethodInstructions } from "./infoText"
import CustomSvgIcon from "../../../Common/CustomSvgIcon"

const BillingMethodModal = ({
  show,
  handleHideModal,
  billingMethod,
  customerID,
  setCustomerBillingMethod
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const { fields } = billingMethodFormTemplate

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    shouldUnregister: true,
    mode: "all"
  })

  const onSubmit = async (data) => {
    let formattedData = {}

    if (
      data?.pdfInvoiceReceiverAddress !==
      data?.pdfInvoiceReceiverAddressConfirmation
    ) {
      setError("pdfInvoiceReceiverAddressConfirmation", {
        type: "custom",
        message: t("The email addresses do not match.")
      })
      return
    }
    setErrorMsg("")
    setLoading(true)
    data.type = parseInt(data.type) // Make type as INT
    delete data?.pdfInvoiceReceiverAddressConfirmation // removing email confirmation from data
    formattedData = data

    await axios
      .post("/api/hll/updateCustomerBillingMethod", {
        customerID: customerID,
        body: formattedData
      })
      .then((response) => {
        handleHideModal()
        setCustomerBillingMethod(response?.data)
        toast.success(t("Billing method is updated"))
      })
      .catch((err) => {
        console.warn(err, "Error: /api/hll/updateCustomerBillingMethod")
        setErrorMsg(t("Something went wrong, try again."))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const renderFields = (fields, billingMethod) => {
    return fields.map((field) => {
      const {
        title,
        type,
        name,
        fieldClassName,
        required,
        disabled,
        readOnly
      } = field

      let hidden = false

      let className =
        typeof fieldClassName !== "undefined"
          ? fieldClassName
          : "col-12 col-md-6 mb-3"

      let defaultValue = billingMethod[name]
      const billingTypes = billingMethodTypes()

      //Check if Peppol and hide pdfInvoiceReceiverAddress
      if (billingMethod["type"] === 2 && name === "pdfInvoiceReceiverAddress") {
        hidden = true
      }

      //Check if Peppol and hide pdfInvoiceReceiverAddressConfirmation
      if (
        billingMethod["type"] === 2 &&
        name === "pdfInvoiceReceiverAddressConfirmation"
      ) {
        hidden = true
      }

      //Check if Sveafaktura and hide pdfInvoiceReceiverAddress
      if (billingMethod["type"] === 3 && name === "pdfInvoiceReceiverAddress") {
        hidden = true
      }

      //Check if Sveafaktura and hide pdfInvoiceReceiverAddressConfirmation
      if (
        billingMethod["type"] === 3 &&
        name === "pdfInvoiceReceiverAddressConfirmation"
      ) {
        hidden = true
      }

      const instructions = billingMethodInstructions(billingMethod["type"])

      switch (type) {
        case "select":
          if (hidden) {
            return null
          }
          return (
            <div className={className} key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {required && "*"}
              </label>
              <select
                type={type}
                id={name}
                name={name}
                disabled={disabled}
                readOnly={readOnly}
                defaultValue={defaultValue}
                className={`form-control form-control-modal rounded-0${
                  disabled || readOnly ? " disabled" : ""
                }`}
                {...register(name, { required })}>
                {billingTypes &&
                  billingTypes?.map((type) => {
                    const isDisabled = billingMethodTypeDisabledLogic(
                      defaultValue,
                      type?.id
                    )

                    return (
                      <option
                        key={type?.id}
                        value={type?.id}
                        disabled={isDisabled}>
                        {type?.name}
                      </option>
                    )
                  })}
              </select>
              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}
              {instructions && (
                <div className={`mt-3 mb-0 alert alert-warning`}>
                  <span className={`text-muted font-xs`}>{instructions}</span>
                </div>
              )}
            </div>
          )
        case "text":
        case "email":
          if (hidden) {
            return null
          }
          return (
            <div className={className} key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {required && "*"}
              </label>
              <input
                type={type}
                id={name}
                name={name}
                disabled={disabled}
                readOnly={readOnly}
                defaultValue={defaultValue}
                className={`form-control form-control-modal rounded-0${
                  disabled || readOnly ? " disabled" : ""
                }`}
                {...register(name, { required })}
              />
              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}
            </div>
          )

        case "textField":
          return (
            <div className={className} key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
              </label>
              <div id={name} className="text-read-only-box">
                defaultValue
              </div>
            </div>
          )

        default:
          return null
      }
    })
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        handleHideModal()
      }}
      centered={true}
      backdrop="static"
      animation={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header className="d-block p-4 bkg-gray-100">
          <div className="position-relative">
            <h3 className="header-title mb-0 text-center">
              {t("Change billing method")}
            </h3>
            <button
              onClick={() => handleHideModal()}
              className="bg-transparent position-absolute p-0 text-body"
              style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
              <CustomSvgIcon
                name="close"
                width={16}
                height={16}
                className="text-black"
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center p-4 bg-white is-scrollable mx-0">
          {loading && <Loader />}
          {!loading && (
            <div className="row">{renderFields(fields, billingMethod)}</div>
          )}
          {errorMsg !== "" && <p className="text-danger">{errorMsg}</p>}
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top radius-1">
          <button
            disabled={loading}
            className="btn btn-outline-secondary"
            onClick={() => handleHideModal()}>
            {t("Cancel")}
          </button>
          {billingMethod["type"] !== 2 && billingMethod["type"] !== 3 && (
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary">
              {t("Save")}
            </button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default BillingMethodModal
