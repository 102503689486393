import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import useOrderContext from "../../context/ReturnOrder/Store"
import OrderActions from "../../components/ReturnOrder/OrderActions"
import moment from "moment"
import axios from "axios"
import Storage from "../../utils/storage"
import {
  calculateAgreedPrice,
  dateAndTimeNow,
  offerTypeMap
} from "../../utils/helpers"
import SummaryItem from "../../components/Returns/Summary/SummaryItem"
import ResponseModal from "../../components/Returns/Summary/ResponseModal"
import uuid from "react-uuid"
import Loader from "../../components/Loader/Loader"
import { Tabs, Tab } from "react-bootstrap"

const Summary = () => {
  const { t } = useTranslation()
  const {
    rentalData,
    newRentalsData,
    orderHeader,
    resetRental,
    resetNewRental
  } = useOrderContext()
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [rentalResponseData, setRentalResponseData] = useState({})
  const [newRentalResponseData, setNewRentalResponseData] = useState({})
  const userState = Storage.get("userState") ?? null
  const addons = newRentalsData.filter((rental) => !rental?.isNew)
  const newOrders = newRentalsData.filter((rental) => rental.isNew)
  const showOrderActions = rentalData.length > 0 || newRentalsData.length > 0

  const [error, setError] = useState(false)
  const [errorCode, setErrorCode] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [activeTab, setActiveTab] = useState("rentalData")
  const [creditData, setCreditData] = useState(null)
  const customerId = orderHeader?.customerId

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleSave = async () => {
    let returnedRentals = []
    let newRentals = []

    rentalData.forEach((data) => {
      returnedRentals.push({
        id: data.id,
        description: data.description,
        quantity: data.quantity,
        startTime: data.startTime,
        endTime: data.endTime,
        siteId: data.siteId,
        price: data.price,
        discount: data.discount,
        orderRowType: data.orderRowType,
        reference: { ...data.reference },
        minimumRentalDays: data.minimumRentalDays,
        sort: data.sort
      })
    })

    const returnedRentalsBody = {
      return: {
        id: uuid(),
        orderId: orderHeader.customerOrderId,
        branchId: userState?.internal?.depot?.id ?? 0,
        returnedRentals: returnedRentals.length > 0 ? returnedRentals : null
      }
    }

    newRentalsData.forEach((data) => {
      let price = 0

      if (data.agreedPrice.amount !== "" && data.agreedPrice.amount > 0) {
        price = calculateAgreedPrice(data.agreedPrice, price)
      }

      newRentals.push({
        id: uuid(),
        description: data.description,
        quantity: data.quantity,
        startTime: data?.startTime
          ? data?.startTime
          : moment().format("YYYY-MM-DD[T]hh:mm:ss.SSS[Z]"),
        endTime: null,
        siteId: null,
        price: data.offerType && data.offerType.length > 0 ? price : 0,
        discount: 0,
        orderRowType: data.orderRowType,
        reference: {
          id: data?.articleId && data.articleId ? data.articleId : data.id,
          type: data.internalType,
          fleetModelName: data?.name ? data.name : data.item.name,
          itemName:
            typeof data.newName !== "undefined" && data.newName !== ""
              ? data.newName
              : data.item.name,
          number: data?.number ? data?.number : data.item.articleNumber,
          isBalanceManaged: data?.isBalanceManaged
            ? data?.isBalanceManaged
            : false
        },
        debitType: data.debitType ? data.debitType : null,
        minimumRentalDays:
          data.offerType && data.offerType.length > 0
            ? data.offerType[0].minimumRentalDays
            : 0,
        sort: data.sort
      })
    })

    let newRentalsBody = {
      customerOrder: {
        customerOrderHead: {
          customerOrderNumber: null, //customerOrderNumber ska läggas till från retur requestet -> row 157
          branchId: userState?.internal?.depot?.id ?? null,
          customerId: customerId
        },
        orderRows: newRentals
      },
      metaData: {
        useCustomerOrderTemplate: true,
        customerOrderTemplateId: orderHeader?.customerOrderId
      }
    }

    setLoading(true)
    setRentalResponseData({})
    setNewRentalResponseData({})

    //createReturn
    await axios
      .post("/api/hll/createReturn", {
        body: returnedRentalsBody
      })
      .then(async (response) => {
        const rentalResponse = response?.data ?? {}
        let newRentalResponse = {}

        if (response.data && response.data.requestResult === 0) {
          resetRental()

          // Add event for existing returns
          let items = []

          rentalResponse.return.returnedRentals.forEach((rental) => {
            items.push({
              itemId: rental.id,
              itemName: rental?.reference?.itemName,
              quantity: rental.quantity,
              price: rental.price
            })
          })

          window.dataLayer.push({
            event: "HLL_create_return",
            depot: userState?.internal?.depot?.name ?? "",
            orderId: rentalResponse.return.id,
            orderNumber: rentalResponse.return.id,
            items,
            createdAt: dateAndTimeNow(),
            userType: "internal"
          })

          if (newRentalsData.length > 0) {
            newRentalsBody.customerOrder.customerOrderHead.customerOrderNumber =
              response.data.return.id

            await axios
              .post("/api/hll/customerOrderCreateRequest", {
                body: newRentalsBody,
                bypassSocialSecurityNumber: true // byPass of SocialSecurityNumber check for return order
              })
              .then((response) => {
                if (response.data) {
                  if (response.data.requestResult === 0) {
                    resetNewRental()
                  }

                  newRentalResponse = response.data

                  // Add event for orders on the return
                  let items = []
                  let totalValue = 0

                  newRentalResponse.customerOrder.orderRows.forEach(
                    (rental) => {
                      items.push({
                        itemId: rental.id,
                        itemName: rental?.reference?.itemName,
                        quantity: rental.quantity,
                        price: rental.price
                      })

                      totalValue = totalValue + rental.price
                    }
                  )

                  window.dataLayer.push({
                    event: "HLL_create_return_order",
                    depot: userState?.internal?.depot?.name ?? "",
                    orderId:
                      newRentalResponse?.customerOrder?.customerOrderHead
                        ?.customerOrderNumber,
                    orderNumber:
                      newRentalResponse?.customerOrder?.customerOrderNumber
                        ?.customerOrderNumber,
                    items,
                    totalValue,
                    createdAt: dateAndTimeNow(),
                    userType: "internal"
                  })
                }
              })
              .catch((err) => {
                console.error(err)
                setErrorCode(err?.response?.data?.CorrelationId)
                setErrorMessage(
                  t("Unfortunately, it was not possible to add new order lines")
                )
                setError(true)
              })
          }
        }

        setRentalResponseData(rentalResponse)
        setNewRentalResponseData(newRentalResponse)

        if (rentalResponse || newRentalResponse) {
          setModalOpen(true)
        }
      })
      .catch((err) => {
        console.error(err)
        setErrorCode(err?.response?.data?.CorrelationId)
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    let unmounted = false

    const requestCreditLimit = async () => {
      setLoading(true)

      await axios
        .post("/api/hll/creditLimit", {
          customerId
        })
        .then((response) => {
          if (!unmounted) setCreditData(response?.data ? response.data : null)
        })
        .catch((err) => {
          setErrorCode(` Error code: ${err?.response?.data?.CorrelationId}`)
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (customerId) {
      requestCreditLimit()
    }

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId])

  return (
    <div className="container-fluid container-fluid-max-width px-0 px-lg-2 has-action-footer">
      {!rentalResponseData?.errors && (
        <ResponseModal
          modalOpen={modalOpen}
          rentalResponseData={rentalResponseData}
          newRentalResponseData={newRentalResponseData}
          handleOnModalClose={handleModalClose}
          error={error}
          errorCode={errorCode}
          errorMessage={errorMessage}
        />
      )}

      {loading && <Loader className="mt-5 mx-auto text-center" />}

      {!loading && (
        <Tabs
          justify
          fill
          id="uncontrolled-tab"
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
          className="bg-white">
          {rentalData.length > 0 && (
            <Tab
              eventKey="rentalData"
              tabClassName="w-100 bg-transparent"
              title={
                <div className="d-flex align-items-center justify-content-center">
                  {t("Existing")}
                  {rentalData.length > 0 && (
                    <span className="ml-2 badge bg-light">
                      {rentalData.length}
                    </span>
                  )}
                </div>
              }>
              {rentalResponseData?.errors && (
                <div className="text-danger p-3">
                  {`${rentalResponseData?.title} Error code: ${rentalResponseData?.traceId}`}
                </div>
              )}
              {error && errorCode !== "" && (
                <div className="text-danger p-3">
                  {`CorrelationId: ${errorCode}`}
                </div>
              )}
              {rentalData.map((item) => {
                return (
                  <SummaryItem
                    key={item.id}
                    name={
                      item?.newName && item.newName !== ""
                        ? item.newName
                        : item.reference.itemName
                    }
                    quantity={item.quantity}
                    identifier={item.reference.number}
                    meta={moment(item.endTime).format("YYYY-MM-DD")}
                    description={item.description}
                    orderReturnInfo={item?.orderReturnInfo}
                  />
                )
              })}
            </Tab>
          )}
          {addons.length > 0 && (
            <Tab
              eventKey="addons"
              tabClassName="w-100 bg-transparent"
              title={
                <div className="d-flex align-items-center justify-content-center">
                  {t("Addons")}
                  {addons.length > 0 && (
                    <span className="ml-2 badge bg-light">{addons.length}</span>
                  )}
                </div>
              }>
              {addons.map((addon) => {
                const { number, articleNumber } = addon.item

                let agreedPrice = 0

                if (
                  addon.agreedPrice.amount !== "" &&
                  addon.agreedPrice.amount > 0
                ) {
                  agreedPrice = calculateAgreedPrice(
                    addon.agreedPrice,
                    addon.price
                  )
                } else if (addon.offerType && addon.offerType.length > 0) {
                  agreedPrice = addon.offerType[0].price
                }

                let name = addon?.name ? addon?.name : addon.item.name
                if (addon?.newName && addon.newName !== "") {
                  name = addon.newName
                }

                return (
                  <SummaryItem
                    key={addon.internalId}
                    name={name}
                    quantity={addon.quantity}
                    identifier={
                      typeof articleNumber !== "undefined"
                        ? articleNumber
                        : number
                    }
                    price={agreedPrice}
                    priceUnit={
                      addon.offerType && addon.offerType.length > 0
                        ? addon.offerType[0]?.priceUnit ?? ""
                        : ""
                    }
                    meta={`${moment(addon.startTime).format(
                      "YYYY-MM-DD"
                    )} ${offerTypeMap(addon.orderRowType, "niceName")}`}
                    description={addon.description}
                  />
                )
              })}
            </Tab>
          )}
          {newOrders.length > 0 && (
            <Tab
              eventKey="neworder"
              tabClassName="w-100 bg-transparent"
              title={
                <div className="d-flex align-items-center justify-content-center">
                  {t("New order")}
                  {newOrders.length > 0 && (
                    <span className="ml-2 badge bg-light">
                      {newOrders.length}
                    </span>
                  )}
                </div>
              }>
              {newOrders.map((order) => {
                const { number, articleNumber } = order.item
                let agreedPrice = 0

                if (
                  order.agreedPrice.amount !== "" &&
                  order.agreedPrice.amount > 0
                ) {
                  agreedPrice = calculateAgreedPrice(
                    order.agreedPrice,
                    order.price
                  )
                } else if (order.offerType && order.offerType.length > 0) {
                  agreedPrice = order.offerType[0].price
                }

                let name = order?.name ? order?.name : order.item.name
                if (order?.newName && order.newName !== "") {
                  name = order.newName
                }

                return (
                  <SummaryItem
                    key={order.internalId}
                    name={name}
                    quantity={order.quantity}
                    identifier={
                      typeof articleNumber !== "undefined"
                        ? articleNumber
                        : number
                    }
                    meta={`${moment(order.startTime).format(
                      "YYYY-MM-DD"
                    )} ${offerTypeMap(order.orderRowType, "niceName")}`}
                    description={order.description}
                    price={agreedPrice}
                    priceUnit={
                      order.offerType && order.offerType.length > 0
                        ? order.offerType[0]?.priceUnit ?? ""
                        : ""
                    }
                    orderReturnInfo={order?.orderReturnInfo}
                  />
                )
              })}
            </Tab>
          )}
        </Tabs>
      )}

      {showOrderActions && (
        <OrderActions
          btnNextDisabled={loading || rentalResponseData?.errors || error}
          btnNextText={loading ? `${t("Loading")}...` : t("Submit return")}
          isSummary={true}
          btnNextClickHandler={handleSave}
          creditData={creditData}
          newRentalsData={newRentalsData}
          loading={loading}
        />
      )}
    </div>
  )
}

export default Summary
