import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

export default function BreadCrumb({ machineGroupData = null }) {
  const { t } = useTranslation()

  const capitialize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  return (
    <div className="bg-white border-bottom rounded-0">
      <div className="container-fluid container-fluid-max-width">
        <div className="hll-breadcrumb py-3">
          <div className="scrollable-breadcrumb">
            <div className="hll-breadcrumb__item">
              <Link to={`/${t("customer")}`} className="text-primary">
                {capitialize(t("customer"))}
              </Link>
              <span className="mx-2">/</span>
            </div>
            <div className="hll-breadcrumb__item">
              <span>{capitialize(t("machines"))}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
