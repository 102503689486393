import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { newOrderInformation, modalView } from "../Store"
import { socialSecurityValidation } from "../../../utils/helpers"

export default function IdRequiredForm({ setShowModalSheet }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const setNewOrderInformationState = useSetRecoilState(newOrderInformation)
  const newOrderInformationState = useRecoilValue(newOrderInformation)
  const [ssnValue, setSSNValue] = useState("")
  const [controlled, setControlled] = useState(false)
  const [inputText, setInputText] = useState("")
  const [bypassSocialSecurityNumber, setBypassSocialSecurityNumber] = useState(
    newOrderInformationState?.customer?.requiresIdForRentalCustomer
      ? false
      : true
  )
  const setModalViewState = useSetRecoilState(modalView)

  const handleInputChange = (event) => {
    const value = event.target.value
    setInputText(value)
  }

  const handleSSNValidation = (e) => {
    const value = e.target.value
    const validation = socialSecurityValidation(value)
    setSSNValue(value)

    if (validation) {
      setControlled(true)
      setNewOrderInformationState((oldOrderInformationState) => {
        return {
          ...oldOrderInformationState,
          pickupBy: {
            name: inputText ? inputText : "",
            socialSecurityNumber: value ? value : ""
          }
        }
      })
    } else {
      setControlled(false)
      setNewOrderInformationState((oldOrderInformationState) => {
        return {
          ...oldOrderInformationState,
          pickupBy: {
            name: "",
            socialSecurityNumber: ""
          }
        }
      })
    }
  }

  const handleChangeReference = () => {
    setShowModalSheet(false)
    setNewOrderInformationState((oldOrderInformationState) => {
      return {
        ...oldOrderInformationState,
        reference: null
      }
    })
  }

  const handleBypassSocialSecurityNumber = () => {
    setBypassSocialSecurityNumber(true)
  }

  const { reference } = newOrderInformationState

  useEffect(() => {
    if (bypassSocialSecurityNumber) {
      setControlled(true)
      setNewOrderInformationState((oldOrderInformationState) => {
        return {
          ...oldOrderInformationState,
          bypassSocialSecurityNumber
        }
      })
    }
  }, [bypassSocialSecurityNumber, setNewOrderInformationState])

  useEffect(() => {
    if (newOrderInformationState?.customer?.requiresIdForRentalCustomer) {
      setBypassSocialSecurityNumber(false)
    } else {
      setBypassSocialSecurityNumber(true)
    }
  }, [newOrderInformationState?.customer?.requiresIdForRentalCustomer])

  return (
    <div className="p-3">
      <form>
        <div className="row">
          <div className="col text-left">
            <label
              htmlFor="name"
              className="field__label form-label mt-1 mb-1 text-uppercase">
              {t("Name")} (Upphämtare)
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={inputText}
              onChange={handleInputChange}
              className="form-control form-control-modal rounded-0"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col text-left">
            <label
              htmlFor="ssn"
              className="field__label form-label mt-1 mb-1 text-uppercase">
              {t("Social security number")}
            </label>
            <input
              type="text"
              id="ssn"
              name="ssn"
              required
              value={ssnValue}
              placeholder="XXXXXXXX-XXXX"
              onChange={(event) => handleSSNValidation(event)}
              className="form-control form-control-modal rounded-0"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col text-left">
            <label
              htmlFor="reference"
              className="field__label form-label mt-1 mb-1 text-uppercase">
              {t("Reference")}
            </label>
            <input
              type="text"
              id="reference"
              name="reference"
              required
              readOnly
              defaultValue={
                reference
                  ? `${reference?.firstName} ${reference?.lastName}`
                  : ""
              }
              className="form-control form-control-modal rounded-0 disabled"
            />
          </div>
        </div>
        <div className="row row my-4">
          <div className="col text-left">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="bypassSocialSecurityNumber"
                onChange={() => handleBypassSocialSecurityNumber()}
              />
              <label
                className="form-check-label"
                htmlFor="bypassSocialSecurityNumber">
                {t("Trpt/customer known")}
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center mt-3">
          <button
            className="btn btn-outline-secondary w-100 d-block mr-3"
            onClick={() => {
              handleChangeReference()
            }}>
            {t("Change reference")}
          </button>
          <button
            className="btn btn-primary w-100 d-block"
            disabled={!controlled}
            onClick={() => {
              setModalViewState("machineSearch")
              navigate(`/order/${t("create")}`)
            }}>
            {t("Continue")}
          </button>
        </div>
      </form>
    </div>
  )
}
