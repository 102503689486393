import React, { useState } from "react"
import { useRecoilValue, useRecoilState } from "recoil"
import { depotState, machineState } from "../Store"
import Depot from "./Depot"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const Depots = ({ handleModalHide, show, title }) => {
  const depots = useRecoilValue(depotState)
  const [machineTransfers, setMachineTransfers] = useRecoilState(machineState)
  const { t } = useTranslation()
  const [selectedDepot, setSelectedDepot] = useState("")

  const handleDepotChange = (id, name) => {
    let tempMachineTransfers = machineTransfers.map((data) => {
      let newMachineTransfers = { ...data }

      newMachineTransfers.newBranchId = id
      newMachineTransfers.newBranchName = name

      return newMachineTransfers
    })

    setMachineTransfers(tempMachineTransfers)
    setSelectedDepot(id)

    handleModalHide(false)

    toast.success(`${t("Depot changed to")} ${name}`)
  }

  return (
    <>
      <Modal
        show={show}
        fullscreen="true"
        onHide={handleModalHide}
        backdrop="static"
        animation={false}
        className={`modal-scrollable modal-no-footer no-search`}>
        <Modal.Header className="d-block p-3">
          <div className="position-relative">
            <h3 className="header-title mt-3 mb-0 text-center">
              {title && title}
            </h3>
            <button
              onClick={() => handleModalHide()}
              className="bg-transparent position-absolute p-0 text-body"
              style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
              <CustomSvgIcon
                name="close"
                width={16}
                height={16}
                className="text-black"
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center p-0 bg-white is-scrollable mx-0">
          <div className="p-3">
            {depots &&
              depots.map((depot) => {
                return (
                  <Depot
                    handleDepotChange={handleDepotChange}
                    selectedDepot={selectedDepot}
                    key={depot.id}
                    {...depot}
                  />
                )
              })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Depots
