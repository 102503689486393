import React from "react"
import { Badge } from "react-bootstrap"
import { NavLink } from "react-router-dom"

const NavItem = ({ to, text, icon, clickHandler, badgeValue = "" }) => {
  const iconComponent = () => {
    if (typeof icon === "string" && icon.includes("fe")) {
      return (
        <span
          className={`fe ${icon} text-primary mr-3`}
          style={{ fontSize: 25 }}></span>
      )
    } else {
      return (
        <span
          className="d-block text-primary mr-3 svg-icon-wrapper"
          style={{ width: 25, height: 25 }}>
          {icon}
        </span>
      )
    }
  }

  return (
    <NavLink
      activeclassname="text-primary"
      to={to}
      end
      onClick={clickHandler}
      className={({ isActive }) =>
        isActive
          ? "text-primary d-flex align-items-center link-site-nav"
          : "d-flex align-items-center link-site-nav"
      }>
      {iconComponent()}
      <span className="font-weight-bold position-relative pr-2">
        {text}
        {badgeValue && (
          <Badge
            className="position-absolute bg-light"
            style={{ left: "100%", top: "-5px" }}>
            10
          </Badge>
        )}
      </span>
    </NavLink>
  )
}

export default NavItem
