import React from "react"
import { useTranslation } from "react-i18next"
import { useOidcUser, useOidc } from "@axa-fr/react-oidc"
import Storage from "../../utils/storage"

import Logo from "../../assets/images/HLL_notext.svg"

import { useOidcIdToken } from "@axa-fr/react-oidc"

const NoPermisson = () => {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()
  const { logout } = useOidc()
  const { idToken } = useOidcIdToken()

  const logoutCallback = React.useCallback(async () => {
    const logoutUrl = `${process.env.REACT_APP_AUTH_URL}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.REACT_APP_PUBLIC_URL}`
    await logout()
    document.location.href = logoutUrl
  }, [idToken, logout])

  const handleLogOut = () => {
    Storage.remove("userGroups")
    Storage.remove("customer", "local")
    Storage.remove("subsidiaries", "local")
    Storage.remove("userState", "local")
    Storage.remove("internalCompanies", "local")
    Storage.remove("internalRegions", "local")
    Storage.remove("depots", "local")
    logoutCallback()
  }

  return (
    <>
      <div className="container my-5 text-center">
        <div className="row mb-5">
          <div className="col-12">
            <figure className="d-block m-0 pb-5">
              <img
                src={Logo}
                alt="Hyreslandslagets logotyp"
                style={{ width: "50px" }}
                className="logo"
              />
            </figure>
            <h6 className="header-pretitle">{`${t("Hi")} ${
              oidcUser?.name
            }`}</h6>
            <h1>{t("You have no permission")}</h1>
            <p>{t("Please contact an administrator.")}</p>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleLogOut}>
              {t("Logout")}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoPermisson
