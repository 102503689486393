import React, { useContext, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Accordion, AccordionContext } from "react-bootstrap"
import Icon from "../Common/Icon"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import sv from "date-fns/locale/sv"
import { offerTypeMap, returnPriceOfferMeta } from "../../utils/helpers"
import CustomAccordionToggle from "../Common/CustomAccordionToggle"
import useOrderContext from "../../context/ReturnOrder/Store"
import CustomSvgIcon from "../Common/CustomSvgIcon"

registerLocale("sv", sv)

function AddonItem({
  quantity,
  updateAddonHandler,
  orderId,
  referenceId,
  description,
  item,
  internalId,
  name,
  number,
  agreedPrice,
  orderRowType,
  internalType,
  startTime,
  debitType,
  isMisc,
  offerType,
  newName,
  orderReturnInfo
}) {
  const { articleNumber, offers } = item
  const inputNoteRef = useRef()
  const inputAgreedPriceRef = useRef()
  const offerTypeRef = useRef()
  const debitTypeRef = useRef()
  const datePickerRef = useRef()
  const inputNameRef = useRef()
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState(moment(startTime).toDate())
  const { deleteNewRental, debitTypes } = useOrderContext()
  const currentEventKey = useContext(AccordionContext)
  const [amount, setAmount] = useState(quantity)
  const [note, setNote] = useState(description)
  const [newPrice, setNewPrice] = useState(agreedPrice)
  const [defaultOrderRowType, setDefaultOrderRowType] = useState(orderRowType)
  const [defaultDebitType, setDefaultDebitType] = useState(debitType)
  const isAccordionActive = currentEventKey === internalId
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [itemName, setItemName] = useState(newName)

  const days = (type, nr = 7) => moment()[type](nr, "d").toDate()
  const formatDate = () => {
    return `${datePickerRef.current.input.value}T00:00:00Z`
  }
  const addonNumber = typeof number !== "undefined" ? number : articleNumber

  const handleAmountClick = (type) => {
    const updatedAmount =
      type === "increment" ? Number(amount) + 1 : Number(amount) - 1

    setAmount(updatedAmount)

    handleInputChange({
      target: {
        value: updatedAmount
      }
    })
  }

  const handleInputChange = ({ target: { value } }) => {
    let updatedAmount = value

    if (updatedAmount < 0) {
      updatedAmount = 1
    }

    setAmount(updatedAmount)

    updateAddonHandler(
      internalId,
      updatedAmount,
      orderId,
      item,
      referenceId ?? "",
      note,
      newPrice,
      formatDate(),
      defaultOrderRowType,
      defaultDebitType,
      defaultOffer,
      itemName
    )
  }

  const handleKeyUp = (event, refElement) => {
    if (event.key === "Enter") {
      refElement.blur()
    }
  }

  const [defaultOffer, setDefaultOffer] = useState(offerType)
  return (
    <div className="accordion--row order-item">
      <div
        className={`d-block p-0 w-100 border-0 text-left text-reset${
          isAccordionActive ? " state--active" : ""
        }`}>
        <div className="p-3 bg-white">
          <CustomAccordionToggle
            eventKey={internalId}
            className="d-flex w-100 justify-content-between text-body">
            <div className="pr-3 font-weight-bold">
              {isMisc && newName !== "" ? newName : name}
            </div>
            <div className="ml-auto">
              {isAccordionActive ? (
                <CustomSvgIcon width={16} height={16} name="chevron-up" />
              ) : (
                <CustomSvgIcon width={16} height={16} name="chevron-down" />
              )}
            </div>
          </CustomAccordionToggle>

          <div className="d-flex align-items-center justify-content-center mt-3">
            <div className="bg-light px-3 py-2 rounded border border-light border-1 w-100">
              <div className="row d-flex align-items-center justify-content-between g-0">
                <div className="col">
                  <div className="text-muted text-gray-700 text-left py-1">
                    {orderReturnInfo && (
                      <CustomSvgIcon
                        width={16}
                        height={16}
                        name="file"
                        className="text-muted mr-3"
                      />
                    )}
                    <span>{addonNumber}</span>
                  </div>
                </div>
                <div className="col-auto pl-2">
                  <span className="d-block text-muted text-gray-700 text-left py-1 border-left border-1 border-white pl-2 text-right">
                    {defaultOffer && defaultOffer.length > 0
                      ? returnPriceOfferMeta(defaultOffer, false)
                      : "0 kr"}
                  </span>
                </div>
              </div>
            </div>

            {internalType === "ARTICLE" && (
              <div className="bg-white d-flex justify-content-between align-items-center rounded border border-light border-1 w-100 ml-2">
                <button
                  className="bg-transparent px-3"
                  onClick={() => handleAmountClick("decrement")}
                  disabled={amount <= 0}>
                  <Icon name="minusCircle" />
                </button>
                <div>
                  <div className="input-group position-relative">
                    <input
                      inputMode="decimal"
                      type="number"
                      value={amount}
                      className="h-100 form-control p-0 text-center appearence-none border-start border-end border-light border-1 border-top-0 border-bottom-0 rounded-0"
                      onChange={handleInputChange}
                      autoCorrect="off"
                      spellCheck={false}
                    />
                    {defaultOffer && (
                      <div
                        className="input-group-text position-absolute top-50 end-0 translate-middle-y border-0 p-0 pr-2"
                        style={{ zIndex: 10 }}>
                        {defaultOffer?.priceUnit ?? ""}
                      </div>
                    )}
                  </div>
                </div>
                <button
                  className="bg-transparent px-3"
                  onClick={() => handleAmountClick("increment")}>
                  <Icon name="plusCircle" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Accordion.Collapse eventKey={internalId}>
        <div className="p-3 pt-0 text-left bg-white">
          {isMisc && (
            <div className="mb-3">
              <label
                htmlFor="name"
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {t("Name")}
              </label>
              <input
                type="text"
                name="name"
                className="form-control form-control-sm rounded-0"
                value={itemName}
                ref={inputNameRef}
                autoComplete="off"
                autoCorrect="off"
                spellCheck={false}
                onKeyUp={(event) => handleKeyUp(event, inputNameRef.current)}
                onChange={({ target: { value } }) => {
                  updateAddonHandler(
                    internalId,
                    amount,
                    orderId,
                    item,
                    referenceId ?? "",
                    note,
                    newPrice,
                    formatDate(),
                    defaultOrderRowType,
                    defaultDebitType,
                    defaultOffer,
                    value
                  )
                  setItemName(value)
                }}
              />
            </div>
          )}

          {orderReturnInfo && <div className="mb-3">{orderReturnInfo}</div>}

          <div className="mb-3">
            <label
              htmlFor="note"
              className="field__label form-label mb-1 text-uppercase">
              {t("Note")}
            </label>
            <input
              autoComplete="off"
              autoCorrect="off"
              spellCheck={false}
              onKeyUp={(event) => handleKeyUp(event, inputNoteRef.current)}
              type="text"
              name="note"
              className="form-control form-control-sm rounded-0"
              value={note}
              onChange={({ target: { value } }) => {
                updateAddonHandler(
                  internalId,
                  amount,
                  orderId,
                  item,
                  referenceId ?? "",
                  value,
                  newPrice,
                  formatDate(),
                  defaultOrderRowType,
                  defaultDebitType,
                  defaultOffer,
                  itemName
                )
                setNote(value)
              }}
              ref={inputNoteRef}
            />
            <div className="row mt-3">
              <label
                htmlFor="newPrice"
                className="field__label form-label mt-1 mb-1 text-uppercase col-12">
                {t("Agreed price")}
              </label>
              <div className="col-9 pr-2">
                <input
                  type="text"
                  inputMode="numeric"
                  name="newPrice"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck={false}
                  className="form-control form-control-sm rounded-0"
                  onKeyUp={(event) =>
                    handleKeyUp(event, inputAgreedPriceRef.current)
                  }
                  defaultValue={newPrice.amount}
                  onChange={({ target: { value } }) => {
                    let formattedValue = Number(value)

                    if (
                      !Number.isInteger(formattedValue) ||
                      formattedValue === 0
                    ) {
                      formattedValue = ""
                    }

                    updateAddonHandler(
                      internalId,
                      amount,
                      orderId,
                      item,
                      referenceId ?? "",
                      note,
                      {
                        amount: formattedValue,
                        type: newPrice.type
                      },
                      formatDate(),
                      defaultOrderRowType,
                      defaultDebitType,
                      defaultOffer,
                      itemName
                    )

                    setNewPrice({
                      ...newPrice,
                      amount: formattedValue
                    })
                  }}
                  ref={inputAgreedPriceRef}
                />
              </div>
              <div className="col-3 pl-0">
                <select
                  defaultValue={newPrice.type}
                  className="form-select rounded-0"
                  style={{ height: 50 }}
                  onChange={(event) => {
                    updateAddonHandler(
                      internalId,
                      amount,
                      orderId,
                      item,
                      referenceId ?? "",
                      note,
                      {
                        amount: newPrice.amount,
                        type: event.target.value
                      },
                      formatDate(),
                      defaultOrderRowType,
                      defaultDebitType,
                      defaultOffer,
                      itemName
                    )

                    setNewPrice({
                      ...newPrice,
                      type: event.target.value
                    })
                  }}>
                  <option value="kr">kr</option>
                  <option value="%">%</option>
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div
              className={`col${
                debitTypes && debitTypes.length > 0 ? "-12 mb-4" : "pr-2"
              }`}>
              <label
                htmlFor="date"
                className="field__label d-block form-label mb-1 text-uppercase">
                {t("Date")}
              </label>
              <div className="position-relative">
                <DatePicker
                  selected={startDate}
                  ref={datePickerRef}
                  onChange={(date) => {
                    setStartDate(date)

                    updateAddonHandler(
                      internalId,
                      amount,
                      orderId,
                      item,
                      referenceId ?? "",
                      note,
                      newPrice,
                      moment(date).format("YYYY-MM-DD[T]00:00:00[Z]"),
                      defaultOrderRowType,
                      defaultDebitType,
                      defaultOffer,
                      itemName
                    )

                    setDatePickerOpen(false)
                  }}
                  dateFormat="yyyy-MM-dd"
                  minDate={days("subtract")}
                  maxDate={days("add")}
                  locale="sv"
                  onClickOutside={() => setDatePickerOpen(false)}
                  onFocus={() => setDatePickerOpen(true)}
                  open={datePickerOpen}
                  readOnly={true}
                />

                <CustomSvgIcon
                  name="calender"
                  width={16}
                  height={16}
                  className={`text-muted pointer-none`}
                  style={{
                    position: "absolute",
                    right: 20,
                    top: 15
                  }}
                />
              </div>
            </div>

            {offers && offers.length > 0 && (
              <div className={`col${defaultOrderRowType === 1 ? "-6" : ""}`}>
                <label
                  htmlFor="type"
                  className="field__label d-block form-label mb-1 text-uppercase">
                  {t("Type")}
                </label>
                <select
                  name="type"
                  className="form-select rounded-0"
                  disabled={offers.length === 1}
                  style={{ height: 50 }}
                  defaultValue={defaultOrderRowType}
                  onChange={({ target: { value } }) => {
                    let orderRowType = value
                    const updatedOffer = offers.filter(
                      (offer) => offer.type === Number(value)
                    )

                    if (value === 0 || value === 6) {
                      orderRowType = 1
                    }

                    if (value === 5) {
                      orderRowType = 0
                    }

                    updateAddonHandler(
                      internalId,
                      amount,
                      orderId,
                      item,
                      referenceId ?? "",
                      note,
                      updatedOffer.length === 0
                        ? {
                            amount: 0,
                            type: "kr"
                          }
                        : newPrice,
                      formatDate(),
                      Number(orderRowType), // -> orderRowType
                      defaultDebitType,
                      updatedOffer,
                      itemName
                    )
                    setDefaultOffer(updatedOffer)
                    setDefaultOrderRowType(Number(orderRowType))
                  }}
                  ref={offerTypeRef}>
                  {offers.map((offer, index) => (
                    <option
                      key={index}
                      value={Number(offer?.type)}
                      disabled={
                        (offer.type === 2 || offer.type === 3) &&
                        internalType === "INDIVIDUAL"
                      }>
                      {offerTypeMap(offer?.type, "niceName")}
                    </option>
                  ))}
                  {offers.filter((offer) => offer.type === 4).length === 0 && (
                    <option value="4">{offerTypeMap(4, "niceName")}</option>
                  )}
                </select>
              </div>
            )}

            <div className={offers && offers.length > 0 ? "col-6 pl-2" : "col"}>
              <label
                htmlFor="debitType"
                className="field__label d-block form-label mb-1 text-uppercase">
                {t("Debiting")}
              </label>
              <select
                name="type"
                className="form-select rounded-0"
                disabled={
                  defaultOrderRowType === 0 ||
                  defaultOrderRowType === 1 ||
                  defaultOrderRowType === 6
                    ? false
                    : true
                }
                style={{ height: 50 }}
                defaultValue={JSON.stringify(defaultDebitType)}
                onChange={({ target: { value } }) => {
                  updateAddonHandler(
                    internalId,
                    amount,
                    orderId,
                    item,
                    referenceId ?? "",
                    note,
                    newPrice,
                    formatDate(),
                    defaultOrderRowType,
                    JSON.parse(value),
                    defaultOffer,
                    itemName
                  )

                  setDefaultDebitType(JSON.parse(value))
                }}
                ref={debitTypeRef}>
                {debitTypes.map((type, index) => (
                  <option key={index} value={JSON.stringify(type)}>
                    {type?.description}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-12 mt-4 d-flex">
              <button
                className="btn w-100 btn-danger"
                onClick={() => deleteNewRental({ internalId })}>
                {t("Remove")}
              </button>
            </div>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  )
}

export default AddonItem
