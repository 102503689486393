import { useState } from "react"
import { useTranslation } from "react-i18next"
import parser from "html-react-parser"
import toast from "react-hot-toast"
import { useSetRecoilState } from "recoil"
import { currentMachineList } from "../../Store"

import ImageComingSoon from "../../../../../assets/images/image-coming-soon.png"
import Accessories from "./Accessories"
import Services from "./Services"
import AccordionData from "./AccordionData"
import Actions from "./Actions"
import { priceWithCurrencyHelper } from "../../../../../utils/helpers"

export default function MachineListItem({
  loading,
  isPriceCalculationFetching,
  acf,
  id,
  title,
  quantity,
  cost,
  setIsUpdating
}) {
  const { t } = useTranslation()

  const setCurrentMachineListState = useSetRecoilState(currentMachineList)

  const [machineGroupQuantityState, setMachineGroupQuantityState] =
    useState(quantity)

  const [accordionState, setAccordionState] = useState({
    view: "",
    data: []
  })

  const image = acf?.image ?? null

  const handleQuantity = (type) => {
    // If the quantity is 1 and the type is remove, show error message
    if (machineGroupQuantityState === 1 && type === "remove") {
      toast.error(t("Quantity cannot be less than 1"))
      setMachineGroupQuantityState(1)
      setCurrentMachineListState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          machines: prevState.data.machines.map((machine) =>
            machine.id === id
              ? {
                  ...machine,
                  quantity: 1
                }
              : machine
          )
        }
      }))

      return
    }

    // Update the quantity in the machine group
    setMachineGroupQuantityState((prevState) =>
      type === "add" ? prevState + 1 : prevState - 1
    )

    // Update the quantity in the machine list of currentlist id
    setCurrentMachineListState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        machines: prevState.data.machines.map((machine) =>
          machine.id === id
            ? {
                ...machine,
                quantity:
                  type === "add" ? machine.quantity + 1 : machine.quantity - 1
              }
            : machine
        )
      }
    }))
    setIsUpdating(true)
  }

  const handleDelete = () => {
    setCurrentMachineListState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        machines: prevState.data.machines.filter((machine) => machine.id !== id)
      }
    }))
    setAccordionState({
      view: "",
      data: []
    })
    setIsUpdating(true)
  }

  const accessories = acf?.accessories ?? []
  const services = acf?.services ?? []

  const rentalPrice = isPriceCalculationFetching ? (
    <small className="d-block">{`${t("Loading price")}...`}</small>
  ) : (
    priceWithCurrencyHelper(cost)
  )

  let showInfoPanel = false
  if (
    (accessories && accessories.length > 0) ||
    (services && services.length > 0)
  ) {
    showInfoPanel = true
  }

  return (
    <>
      <div className={`cart-card${loading ? " is-disabled" : ""}`}>
        <figure className="cart-card__image">
          {image ? (
            <img src={image} alt={title?.rendered} className="img-fluid" />
          ) : (
            <img
              src={ImageComingSoon}
              alt={title?.rendered}
              className="img-fluid logo"
            />
          )}
        </figure>
        <div className="cart-card__details">
          <h2 className="cart-card__title">{parser(title?.rendered ?? "")}</h2>
          <Actions
            loading={loading}
            handleDelete={handleDelete}
            handleQuantity={handleQuantity}
            machineGroupQuantityState={machineGroupQuantityState}
            rentalPrice={rentalPrice}
          />
        </div>

        {showInfoPanel && (
          <div className="cart-card__info">
            {accessories && accessories.length > 0 && (
              <Accessories
                data={accessories}
                accordionState={accordionState}
                setAccordionState={setAccordionState}
                loading={loading}
              />
            )}
            {services && services.length > 0 && (
              <Services
                data={services}
                accordionState={accordionState}
                setAccordionState={setAccordionState}
                loading={loading}
              />
            )}
          </div>
        )}

        {accordionState?.data?.view !== "" &&
          accordionState?.data &&
          accordionState?.data.length > 0 && (
            <div className="cart-card--accordion-panel">
              <h3 className="field__label text-uppercase mb-1">
                {accordionState?.view === "accessories"
                  ? t("Accessories")
                  : t("Service costs")}
              </h3>
              {accordionState?.data.map((item, index) => {
                return (
                  <AccordionData
                    key={index}
                    accordionState={accordionState}
                    machineGroupId={id}
                    setIsUpdating={setIsUpdating}
                    {...item}
                  />
                )
              })}
            </div>
          )}
      </div>
    </>
  )
}
