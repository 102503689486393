import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import Modal from "./Modal"

const Form = ({
  template,
  billingMethod,
  hasEditPermission,
  setCustomerBillingMethod,
  customerID
}) => {
  const { t } = useTranslation()
  const { fields } = template
  const [showModal, setShowModal] = useState(false)

  const handleHideModal = () => {
    setShowModal(false)
  }

  const renderFields = (fields) => {
    return fields.map((field, index) => {
      const { title, type, name, instructions, fieldClassName } = field

      let className =
        typeof fieldClassName !== "undefined"
          ? fieldClassName
          : "col-12 col-md-6 mb-3"

      let defaultValue = billingMethod?.billingAddress[name]

      switch (type) {
        case "textField":
          return (
            <div className={className} key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
              </label>
              <div id={name} className="text-read-only-box">
                {defaultValue}
              </div>
              {instructions && (
                <span className="text-muted font-xs mt-2">{instructions}</span>
              )}
            </div>
          )
        default:
          return null
      }
    })
  }

  return (
    <>
      <Modal
        show={showModal}
        handleHideModal={handleHideModal}
        billingMethod={billingMethod}
        setCustomerBillingMethod={setCustomerBillingMethod}
        customerID={customerID}
      />
      <div className="card-body">
        <div className="row">{renderFields(fields)}</div>
      </div>
      {hasEditPermission && (
        <div className="card-footer bkg-gray-100">
          <div className="d-flex align-items-center justify-content-start justify-content-md-end">
            <button
              className="btn btn-primary"
              onClick={() => setShowModal(true)}>
              {t("Edit")}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Form
