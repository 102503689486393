import React, { useEffect, useRef, useState } from "react"
import { useOidcUser } from "@axa-fr/react-oidc"
import { Dropdown } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import { useTranslation } from "react-i18next"
import CustomDropDownToggle from "../Common/CustomDropDownToggle"
import CustomDropdownMenu from "../Common/CustomDropdownMenu"
import { useMediaQuery } from "react-responsive"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const User = ({
  data,
  data: { id, name, firstName, lastName, email, userAccountGroups, validTo },
  updateAction,
  updatedUser,
  onHandleEdit,
  onHandleDelete,
  setShowModalSheet,
  setModalSheetData
}) => {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()
  const tableRowRef = useRef()
  const [isBlocked, setIsBlocked] = useState(false)

  const groupList =
    userAccountGroups &&
    userAccountGroups.map((group) => {
      return group?.name
    })

  useEffect(() => {
    if (updatedUser && updatedUser?.id === id && updateAction === "delete") {
      setTimeout(() => {
        tableRowRef.current.parentNode.removeChild(tableRowRef.current)
      }, 500)
    }
  }, [updatedUser, updateAction, id])

  useEffect(() => {
    if (!validTo) {
      setIsBlocked(false)
    } else {
      const now = new Date()

      if (now > new Date(validTo)) {
        setIsBlocked(true)
      } else {
        setIsBlocked(false)
      }
    }
  }, [validTo])
  return (
    <tr ref={tableRowRef}>
      {useMediaQuery({ query: "(max-width: 990px)" }) && (
        <td>
          <button
            className="bg-transparent d-flex align-items-center"
            onClick={() => {
              setShowModalSheet(true)
              setModalSheetData(data)
            }}>
            <span className="d-block svg-icon-wrapper">
              <CustomSvgIcon
                name="dot-navigation"
                width={22}
                height={24}
                className={`text-muted`}
              />
            </span>
          </button>
        </td>
      )}
      <td className={`${isBlocked ? "is-disabled text-danger" : ""}`}>
        {name ? name : `${firstName} ${lastName}`}
      </td>
      <td className={`${isBlocked ? "is-disabled text-danger" : ""}`}>
        <span
          className="d-inline-block text-truncate"
          style={{ maxWidth: "80%" }}>
          {email && email}
        </span>
      </td>
      <td className={`${isBlocked ? "is-disabled text-danger" : ""}`}>
        {isBlocked ? t("Inactive account") : groupList && groupList.join(", ")}
      </td>
      {useMediaQuery({ query: "(min-width: 991px)" }) && (
        <td>
          <Dropdown className="d-flex align-items-center">
            <Dropdown.Toggle as={CustomDropDownToggle}>
              <span className="d-block svg-icon-wrapper">
                <CustomSvgIcon
                  name="dot-navigation"
                  width={22}
                  height={24}
                  className={`text-muted`}
                />
              </span>
            </Dropdown.Toggle>

            <DropdownMenu
              as={CustomDropdownMenu}
              className="position-fixed"
              style={{ zIndex: "999" }}>
              <DropdownItem onClick={() => onHandleEdit(data)}>
                {t("Edit")}
              </DropdownItem>

              <DropdownItem
                disabled={email === oidcUser?.preferred_username}
                onClick={() => onHandleDelete(data)}>
                {t("Delete user")}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </td>
      )}
      {useMediaQuery({ query: "(max-width: 990px)" }) && <td></td>}
    </tr>
  )
}

export default User
