import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ActionFooter from "../Common/ActionFooter"
import { useSetRecoilState } from "recoil"
import { newOrderInformation, modalView } from "./Store"
import ModalOrderSearch from "./Modal/ModalOrderSearch"
import ModalCreateOrder from "./Modal/ModalCreateOrder"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const OrderWrapper = () => {
  const { t } = useTranslation()
  const [showOrderSearchModal, setShowOrderSearchModal] = useState(false)
  const [showModalCreateOrder, setShowModalCreateOrder] = useState(false)
  const setModalViewState = useSetRecoilState(modalView)
  const setNewOrderInformationState = useSetRecoilState(newOrderInformation)

  const handleHideModal = () => {
    setShowOrderSearchModal(false)
    setShowModalCreateOrder(false)
    setNewOrderInformationState({
      customer: null,
      project: null,
      reference: null
    })
  }

  return (
    <>
      <h1 className="h2">{t("Order")}</h1>

      <button
        className="btn btn-primary w-100 d-block"
        onClick={() => {
          setModalViewState("customer")
          setShowModalCreateOrder(true)
        }}>
        {t("Create new order")}
      </button>

      <ModalOrderSearch
        show={showOrderSearchModal}
        handleHideModal={handleHideModal}
      />

      <ModalCreateOrder
        show={showModalCreateOrder}
        setShowModalCreateOrder={setShowModalCreateOrder}
        handleHideModal={handleHideModal}
      />

      <ActionFooter>
        <button
          className="border rounded text-primary bg-white p-0"
          style={{ fontSize: 20, width: 40, height: 40 }}
          onClick={() => {
            setShowOrderSearchModal(true)
            setModalViewState("orderSearch")
          }}>
          <CustomSvgIcon width={16} height={16} name="file" />
        </button>
      </ActionFooter>
    </>
  )
}

export default OrderWrapper
