import React from "react"

const CustomDropDownToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    type="button"
    ref={ref}
    className="bg-white"
    onClick={(e) => {
      onClick(e)
    }}>
    {children}
  </button>
))

export default CustomDropDownToggle
