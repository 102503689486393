import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useOrderContext from "../../context/ReturnOrder/Store"
import { Tabs, Tab, Accordion } from "react-bootstrap"
import OrderItem from "./OrderItem"
import OrderActions from "./OrderActions"
import AddonItem from "./AddonItem"
import { useLocation, useNavigate } from "react-router-dom"
import NewRentalModal from "./NewRental/NewRentalModal"
import { useOidcUser } from "@axa-fr/react-oidc"
import LockedOrder from "../Returns/LockedOrder"
import RentalDateModal from "./RentalDateModal"
import moment from "moment"

import { orderRowTypeMap } from "../../utils/helpers"

function Order({ customerOrderHead, orderRows, orderId }) {
  const { t } = useTranslation()
  const {
    rentalData,
    setOrderHeader,
    setNewRental,
    newRentalsData,
    resetNewRental
  } = useOrderContext()
  const [orderRowsAmount, setOrderRowsAmount] = useState(0)
  const [startDate, setStartDate] = useState(moment().toDate())
  const [newRentalOpen, setNewRentalOpen] = useState(false)
  const [dateModalOpen, setDateModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState("orderRows")
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { oidcUser } = useOidcUser()

  useEffect(() => {
    setOrderHeader({
      customerOrderNumber: customerOrderHead.customerOrderNumber,
      customerOrderId: customerOrderHead.customerOrderId,
      customerName: customerOrderHead.customerName,
      customerId: customerOrderHead.customerId,
      customerStatus: customerOrderHead.customerStatus
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setOrderRowsAmount(rentalData.length)

    if (rentalData.length === 0) {
      resetNewRental()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentalData])

  const handleAddRentalBtnClick = () => {
    setNewRentalOpen(true)
  }

  const handleDateClick = () => {
    setDateModalOpen(true)
  }

  const handleDateModalSave = (date) => {
    setDateModalOpen(false)
    setStartDate(date)
  }

  const handleDateModalHide = () => {
    setDateModalOpen(false)
  }

  const handleAddRentalHide = () => {
    setNewRentalOpen(false)
  }

  const handleNewRentalSave = (items) => {
    setNewRental(items)
    handleAddRentalHide()
    setActiveTab("addons")
  }

  const handleAddonUpdate = (
    internalId,
    quantity,
    orderId,
    item,
    referenceId,
    description,
    agreedPrice,
    date,
    orderRowType,
    debit,
    offerType,
    newName
  ) => {
    //Checking orderRowType
    if (orderRowType === 0 || orderRowType === 6) {
      orderRowType = 1
    }

    if (orderRowType === 5) {
      orderRowType = 0
    }

    setNewRental([
      {
        internalId,
        quantity,
        orderId,
        item,
        referenceId,
        description,
        agreedPrice,
        startTime: date,
        orderRowType,
        debitType: debit ? debit : null,
        offerType,
        newName
      }
    ])
  }

  const handleNextBtnClick = () => {
    navigate(`${pathname}/${t("summary")}`)
  }

  useEffect(() => {
    window.onbeforeunload = function () {
      return true
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [])

  const { isLocked, lockedBy } = customerOrderHead?.lock
  const preferredUsername = oidcUser?.preferred_username
  if (isLocked && lockedBy !== preferredUsername.toLowerCase()) {
    return <LockedOrder {...customerOrderHead} />
  }

  const sortedRentals = orderRows.sort((a, b) => (a.sort > b.sort ? 1 : -1))

  return (
    <>
      <div className="container-fluid container-fluid-max-width px-0 px-lg-2 has-action-footer">
        <NewRentalModal
          open={newRentalOpen}
          onHideHandler={handleAddRentalHide}
          onSaveHandler={handleNewRentalSave}
        />
        <RentalDateModal
          open={dateModalOpen}
          onHideHandler={handleDateModalHide}
          onSaveHandler={handleDateModalSave}
        />
        <Tabs
          justify
          fill
          id="uncontrolled-tab"
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
          className="bg-white">
          <Tab
            eventKey="orderRows"
            tabClassName="w-100 bg-transparent"
            title={
              <div className="d-flex align-items-center justify-content-center">
                {t("Returns")}
                <span className="ml-2 badge bg-light">
                  {`${orderRowsAmount} av ${orderRows.length}`}
                </span>
              </div>
            }>
            <Accordion defaultActiveKey="0">
              {sortedRentals.map((rental) => {
                return (
                  <OrderItem
                    key={rental.id}
                    orderId={orderId}
                    initialDate={startDate}
                    {...rental}
                  />
                )
              })}
            </Accordion>
          </Tab>
          <Tab
            eventKey="addons"
            tabClassName="w-100 bg-transparent"
            title={
              <div className="d-flex align-items-center justify-content-center">
                {t("Addons")}
                <span className="ml-2 badge bg-light">
                  {newRentalsData.length}
                </span>
              </div>
            }>
            <div className="text-center">
              <Accordion defaultActiveKey="0">
                {newRentalsData && newRentalsData.length > 0 ? (
                  newRentalsData.map((addon) => {
                    if (!addon?.id) {
                      return null
                    }
                    return (
                      <AddonItem
                        updateAddonHandler={handleAddonUpdate}
                        key={addon.internalId}
                        id={addon.id}
                        name={addon?.name ? addon?.name : addon.item.name}
                        orderId={orderId}
                        orderRowType={
                          addon?.orderRowType
                            ? addon?.orderRowType
                            : orderRowTypeMap(0)
                        }
                        agreedPrice={addon?.agreedPrice ?? ""}
                        offerType={addon?.offerType ?? []}
                        newName={addon?.newName ?? ""}
                        {...addon}
                      />
                    )
                  })
                ) : (
                  <div className="py-4">{t("No addons found")}</div>
                )}
              </Accordion>
            </div>
          </Tab>
        </Tabs>
      </div>
      <OrderActions
        btnNextDisabled={orderRowsAmount === 0 && newRentalsData.length === 0}
        btnNextText={t("Next")}
        btnNextClickHandler={handleNextBtnClick}
        btnAddRentalClickHandler={handleAddRentalBtnClick}
        btnDateClickHandler={handleDateClick}
        activeTab={activeTab}
      />
    </>
  )
}

export default Order
