import axios from "axios"

const CustomerCreditLimit = async (id) => {
  let data = []

  await axios
    .post("/api/hll/creditLimit", {
      customerId: id
    })
    .then((response) => {
      data = response?.data ? response.data : []
    })

  return data
}

export default CustomerCreditLimit
