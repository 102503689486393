import React from "react"
import { useTranslation } from "react-i18next"

const MachineControlNotes = ({ onChangeHandler }) => {
  const { t } = useTranslation()

  return (
    <div className="col-12">
      <p>{t("Fill in check note before saving (optional).")}</p>

      <label
        htmlFor="note"
        className="field__label form-label text-uppercase font-xs pb-0 mb-2">
        {t("Control note")}
      </label>
      <textarea
        id="note"
        className="form-control"
        onChange={onChangeHandler}
        rows="5"
      />
    </div>
  )
}

export default MachineControlNotes
