import { useTranslation } from "react-i18next"
import CustomSvgIcon from "../../../../Common/CustomSvgIcon"

export default function Accessories({
  data,
  accordionState,
  setAccordionState,
  loading
}) {
  const { t } = useTranslation()

  return (
    <button
      disabled={loading}
      className={`cart-card__accordion-trigger py-2 px-3 rounded d-flex align-items-center justify-content-between${
        loading ? " is-disabled" : ""
      } ${accordionState?.view === "accessories" ? "is-open" : ""}`}
      onClick={() => {
        setAccordionState({
          view: accordionState?.view === "accessories" ? "" : "accessories",
          data: accordionState?.view === "accessories" ? [] : data
        })
      }}>
      <span className="d-block mr-3">{`${t("Accessories")} (${data?.length}${t(
        "pc"
      )})`}</span>
      <CustomSvgIcon
        name={
          accordionState?.view === "accessories" ? "chevron-up" : "chevron-down"
        }
        width={16}
        height={16}
      />
    </button>
  )
}
