import React, { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"

import Modal from "./Modal"

import {
  billingMethodTypeMapping,
  billingFrequencyMapping
} from "../../../../utils/helpers"

const Form = ({
  template,
  billingMethod,
  billingFrequency,
  hasEditPermission,
  setCustomerBillingMethod,
  customerID
}) => {
  const { t } = useTranslation()
  const { fields } = template
  const [showModal, setShowModal] = useState(false)

  const handleHideModal = () => {
    setShowModal(false)
  }

  const renderFields = (fields) => {
    return fields.map((field, index) => {
      const { title, type, name, instructions, fieldClassName } = field

      let className =
        typeof fieldClassName !== "undefined"
          ? fieldClassName
          : "col-12 col-md-6 mb-3"

      let defaultValue = billingMethod[name]
      let hidden = false

      if (name === "type") {
        defaultValue = billingMethodTypeMapping(billingMethod[name])
      } else if (name === "billingFrequency") {
        defaultValue = billingFrequencyMapping(billingFrequency)
      }

      //Check if Peppol and hide pdfInvoiceReceiverAddress
      if (billingMethod["type"] === 2 && name === "pdfInvoiceReceiverAddress") {
        hidden = true
      }

      //Check if Sveafaktura and hide pdfInvoiceReceiverAddress
      if (billingMethod["type"] === 3 && name === "pdfInvoiceReceiverAddress") {
        hidden = true
      }

      switch (type) {
        case "textField":
          if (hidden) {
            return null
          }

          return (
            <Fragment key={name}>
              <div className={className}>
                <label
                  htmlFor={name}
                  className="field__label form-label mt-1 mb-1 text-uppercase">
                  {title}
                </label>
                <div id={name} className="text-read-only-box">
                  {defaultValue}
                </div>
                {instructions && (
                  <span className="text-muted font-xs mt-2">
                    {instructions}
                  </span>
                )}
              </div>
              {index === 0 && (
                <div className="col-12 col-md-6 d-flex align-items-center mb-3 mb-md-0">
                  {billingMethod["type"] === 3 && ( //Check if Sveafaktura
                    <>
                      <span className="mr-2">
                        <i className="fe fe-info" />
                      </span>
                      <span>
                        {t(
                          "You are using Svefaktura today and we advise you to switch to Peppol."
                        )}
                      </span>
                    </>
                  )}
                </div>
              )}
            </Fragment>
          )
        default:
          return null
      }
    })
  }

  return (
    <>
      <Modal
        show={showModal}
        handleHideModal={handleHideModal}
        billingMethod={billingMethod}
        customerID={customerID}
        setCustomerBillingMethod={setCustomerBillingMethod}
      />
      <div className="card-body">
        <div className="row">{renderFields(fields)}</div>
      </div>
      {hasEditPermission && (
        <div className="card-footer bkg-gray-100">
          <div className="d-flex align-items-center justify-content-start justify-content-md-end">
            <button
              className="btn btn-primary"
              onClick={() => setShowModal(true)}>
              {t("Edit")}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Form
