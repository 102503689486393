import React from "react"
import { useRecoilValue } from "recoil"
import { orderInformation, newOrderInformation, view } from "./Store"
import { useTranslation } from "react-i18next"

const OrderInformation = () => {
  const viewState = useRecoilValue(view)
  const orderInformationState = useRecoilValue(
    viewState === "previewExistingOrder" || viewState === "viewOrder"
      ? orderInformation
      : newOrderInformation
  )
  const { t } = useTranslation()

  return (
    <>
      <div>
        <span className="d-block text-uppercase font-xs mb-1">
          {t("Client")}
        </span>
        {orderInformationState?.customerName}
      </div>
      <div className="mt-3">
        <span className="d-block text-uppercase font-xs mb-1">
          {t("Client number")}
        </span>
        {orderInformationState?.customerNumber}
      </div>
      {orderInformationState?.siteName &&
        orderInformationState?.siteName !== "" && (
          <div className="mt-3">
            <span className="d-block text-uppercase font-xs mb-1">
              {t("Project")}
            </span>
            {orderInformationState?.siteName}
          </div>
        )}
      <div className="mt-3">
        <span className="d-block text-uppercase font-xs mb-1">
          {t("Reference")}
        </span>
        {orderInformationState?.orderedBy?.name ?? t("No reference found")}
      </div>
      <div className="mt-3">
        <span className="d-block text-uppercase font-xs mb-1">
          {t("Order Number")}
        </span>
        {orderInformationState?.customerOrderNumber}
      </div>
    </>
  )
}

export default OrderInformation
