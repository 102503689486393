import React from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export default function BlockProjectModal({
  handleCancel,
  show,
  isBlockedForOrder,
  handleBlockProject
}) {
  const { t } = useTranslation()

  return (
    <Modal show={show} centered animation={false}>
      <Modal.Header>
        {isBlockedForOrder ? (
          <h2 className="header-title">{t("Unblock project")}</h2>
        ) : (
          <h2 className="header-title">{t("Block project")}</h2>
        )}
      </Modal.Header>
      <Modal.Body>
        {isBlockedForOrder ? (
          <p>
            {t(
              "Do you want to block this project for new orders? It is always possible to add the lock in the future"
            )}
            .
          </p>
        ) : (
          <p>
            {t(
              "Do you want to block this project for new orders? It is always possible to remove the barrier in the future"
            )}
            .
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-dark" onClick={handleCancel}>
          {t("Cancel")}
        </button>
        <button
          className={`btn btn-primary`}
          onClick={() => handleBlockProject(!isBlockedForOrder)}>
          {t("Continue")}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
