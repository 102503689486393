import React from "react"

export default function Specification({ name, unit, value, type }) {
  const formatedValue = Intl.NumberFormat("sv-SE", {
    style: "decimal",
    maximumFractionDigits: 2
  }).format(value)

  return (
    <div className="technical-specification-grid__item border-bottom d-flex align-items-center justify-content-between py-3 px-4">
      <span className="mr-auto">{name}</span>
      <span className="ml-auto">
        <div className="d-flex align-items-center">
          <strong>
            <span className="mr-2">{isNaN(value) ? value : formatedValue}</span>
            <span>{unit && unit}</span>
          </strong>
        </div>
      </span>
    </div>
  )
}
