import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, FormControl } from "react-bootstrap"
import { isMobile } from "react-device-detect"
import CustomSvgIcon from "./CustomSvgIcon"

const SearchForm = ({ onSubmitHandler, onBlurHandler, disabled }) => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const [searchText, setSearchText] = useState("")
  const [inputMode, setInputMode] = useState("numeric")
  const inputStyle = isMobile
    ? { paddingLeft: "50px", paddingRight: "50px" }
    : { paddingRight: "50px" }

  const handleInputChange = (event) => {
    setSearchText(event.target.value)
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <Form
      autoComplete="off"
      className="position-relative"
      onSubmit={(event) => {
        const searchInput = event?.target?.elements.search
        event.preventDefault()
        onSubmitHandler(searchText)
        searchInput.blur()
      }}>
      {inputMode === "numeric" ? (
        <FormControl
          className={`form-control form-control-lg py-2${
            disabled ? " disabled" : ""
          }`}
          name="search"
          type="text"
          inputMode={inputMode}
          placeholder={t("Search")}
          ref={inputRef}
          onChange={handleInputChange}
          value={searchText}
          required
          autoFocus={true}
          enterKeyHint="Search"
          autoCapitalize="none"
          autoComplete="off"
          spellCheck={false}
          style={inputStyle}
          autoCorrect="off"
          disabled={disabled}
          onBlur={(event) =>
            onBlurHandler(event?.target?.value ? event.target.value : "")
          }
        />
      ) : (
        <FormControl
          className={`form-control form-control-lg py-2${
            disabled ? " disabled" : ""
          }`}
          name="search"
          type="text"
          inputMode={inputMode}
          placeholder={t("Search")}
          ref={inputRef}
          onChange={handleInputChange}
          value={searchText}
          required
          autoFocus={true}
          enterKeyHint="Search"
          autoCapitalize="none"
          autoComplete="off"
          spellCheck={false}
          style={inputStyle}
          autoCorrect="off"
          disabled={disabled}
        />
      )}

      {isMobile && (
        <button
          className={`bg-transparent text-primary position-absolute${
            disabled ? " disabled" : ""
          }`}
          style={{ top: "50%", left: 10, transform: "translateY(-50%)" }}
          type="button"
          disabled={disabled}
          onClick={() => {
            setInputMode(inputMode === "search" ? "numeric" : "search")
            inputRef.current.focus()
          }}>
          <i
            className={`fe fe-${inputMode === "search" ? "type" : "hash"}`}
            style={{ fontSize: 20 }}></i>
        </button>
      )}

      <button
        className={`bg-transparent text-primary position-absolute${
          disabled ? " disabled" : ""
        }`}
        style={{
          top: "50%",
          right: 20,
          transform: "translateY(-50%)",
          width: 20,
          height: 20
        }}
        type="submit"
        disabled={disabled}>
        <CustomSvgIcon
          name="search"
          width={20}
          height={20}
          className={`text-muted`}
        />
      </button>
    </Form>
  )
}

export default SearchForm
