import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useOidcUser } from "@axa-fr/react-oidc"
import axios from "axios"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"

import Storage from "../../utils/storage"
import CustomSvgIcon from "../Common/CustomSvgIcon"

function Settings({
  onHandleIdRequirement,
  onHandlePermissions,
  reFetchSettings,
  setRequiresIdForRentalCustomer
}) {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()
  const oid = oidcUser?.tid

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const customer = Storage.get("customer", null, "local") ?? null

  const handleChange = (event) => {
    const { id, checked } = event.target

    if (id === "requiresIdForRentalCustomer") {
      onHandleIdRequirement(data, checked)
    } else {
      onHandlePermissions(data, checked)
    }
  }

  const renderRequiresIdTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t(
        "You have identification requirements and therefore HLL needs to have a date of birth to check at the time of rental."
      )}
    </Tooltip>
  )

  const renderAdminTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t(
        "This option only allows the admin of your company to assign or remove people from permission to rent. HLL cannot make any changes to the list."
      )}
    </Tooltip>
  )

  useEffect(() => {
    let unmounted = false

    const request = async () => {
      setLoading(true)

      await axios
        .post("/api/hll/getCustomer", {
          customerId: customer ? customer?.id : null
        })
        .then((response) => {
          if (!unmounted && response.data) {
            setData(response.data)
            setRequiresIdForRentalCustomer(
              response?.data?.requiresIdForRentalCustomer
            )
          }
        })
        .catch((error) => {
          console.error(`Error code: ${error.response.data.CorrelationId}`)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (oid || reFetchSettings) {
      request()
    }

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oid, reFetchSettings])

  return (
    <>
      <div className="col-lg-6 col-xl-4">
        <div className="card mb-2 mb-lg-0 shadow-none">
          <div className="card-body d-flex align-items-center">
            <div>
              <h6 className="text-muted text-uppercase mb-3">
                {t("Identification requirement")}
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderRequiresIdTooltip}>
                  <span className="fe fe-info ml-2"></span>
                </OverlayTrigger>
              </h6>

              <div className="d-flex align-items-center">
                <input
                  id="requiresIdForRentalCustomer"
                  type="checkbox"
                  disabled={loading}
                  checked={data?.requiresIdForRentalCustomer ?? false}
                  className="form-check-input list-checkbox mt-0 flex-shrink-0"
                  onChange={(event) => {
                    handleChange(event)
                  }}
                />
                <label
                  htmlFor="requiresIdForRentalCustomer"
                  className="d-block d-md-flex ml-2">
                  {t("Require ID for rentals and returns")}
                </label>
              </div>
            </div>

            <span
              className="fe fe-eye ml-auto text-primary"
              style={{ fontSize: 24 }}></span>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-xl-4">
        <div className="card mb-2 mb-lg-0 shadow-none">
          <div className="card-body d-flex align-items-center">
            <div>
              <h6 className="text-muted text-uppercase mb-3">
                {t("Permissions")}
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderAdminTooltip}>
                  <span className="fe fe-info ml-2"></span>
                </OverlayTrigger>
              </h6>

              <div className="d-flex align-items-center">
                <input
                  id="onlyCustomerCanCreate"
                  type="checkbox"
                  disabled={loading}
                  checked={data?.onlyCustomerCanCreateRentalContact ?? false}
                  className="form-check-input list-checkbox mt-0 flex-shrink-0"
                  onChange={(event) => {
                    handleChange(event)
                  }}
                />
                <label
                  htmlFor="onlyCustomerCanCreate"
                  className="ml-2 d-block d-md-flex ml-2">
                  {t("Only admin is allowed to add persons")}
                </label>
              </div>
            </div>

            <CustomSvgIcon
              name="lock"
              width={22}
              height={22}
              className="ml-auto"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings
