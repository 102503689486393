import React, { useState, useEffect } from "react"
import { TransportOrderProvider } from "../../context/TransportOrder/Store"
import axios from "axios"
import Header from "./Header"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Alert from "../../components/Common/Alert"

import Toaster from "../../components/Common/Toast"

import Content from "./Content"

import { usePermissionCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"

const Project = () => {
  const { t } = useTranslation()
  const [projectLoading, setProjectLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [data, setData] = useState({
    meta: [],
    all: [],
    rented: [],
    returned: [],
    purchase: []
  })
  const [error, setError] = useState(false)
  const [activeType, setActiveType] = useState("rented")

  const [allChecked, setAllChecked] = useState(false)
  const [checkedItems, setCheckedItems] = useState([])

  const { id } = useParams()

  useEffect(() => {
    setProjectLoading(true)
    setError(false)

    const requestProject = async () => {
      await axios
        .post("/api/hll/project", { id })
        .then(async (response) => {
          setData((previous) => ({ ...previous, meta: response?.data }))

          let endpoints = [
            "/api/hll/projectRentals",
            "/api/hll/projectPurchase"
          ]
          setDataLoading(true)

          await axios
            .all(endpoints.map((endpoint) => axios.post(endpoint, { id })))
            .then(
              axios.spread(({ data: rentals }, { data: purchase }) => {
                const allData = [...rentals, ...purchase]

                // Rented don't have any endTime yet
                const rented = rentals
                  .filter((item) => item?.endTime === null)
                  .sort((a, b) => (a["sort"] > b["sort"] ? 1 : -1))

                // Returned always have a endTime
                const returned = rentals
                  .filter((item) => item?.endTime !== null)
                  .sort((a, b) => (a["sort"] > b["sort"] ? 1 : -1))

                setData((previous) => ({
                  ...previous,
                  all: allData.sort((a, b) => (a["sort"] > b["sort"] ? 1 : -1)),
                  rented,
                  returned,
                  purchase
                }))

                if (
                  rented.length > 0 &&
                  returned.length > 0 &&
                  purchase.length > 0
                ) {
                  setActiveType("rented")
                } else if (rented && rented.length > 0) {
                  setActiveType("rented")
                } else if (returned && returned.length > 0) {
                  setActiveType("returned")
                } else if (purchase && purchase.length > 0) {
                  setActiveType("purchase")
                }
              })
            )
            .catch((err) => {
              console.log(err, "Error: api/hll/projectRentals")
              setError(true)
            })
            .finally(() => {
              setDataLoading(false)
            })
        })
        .catch((err) => {
          console.log(err, "Error: api/hll/project")
          setError(true)
        })
        .finally(() => {
          setProjectLoading(false)
        })
    }

    requestProject()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setActiveType])

  const navLinkHandler = (e) => {
    e.preventDefault()
    let name = e.target.dataset["name"]

    if (e.target.tagName === "SPAN") {
      name = e.target.parentNode.dataset["name"]
    }
    setActiveType(name)
  }

  const hasHLLInternPermission = usePermissionCheck("MinaSidor.SkapaRetur")
  const hasCustomerSitePermission = usePermissionCheck(
    "MinaSidor.KundsPlatsLäs"
  )

  if (!hasCustomerSitePermission || hasHLLInternPermission) {
    return <PageNotFound noPermission={true} />
  }

  return (
    <TransportOrderProvider>
      <Toaster />
      <div className="container-fluid container-fluid-max-width">
        <Header
          data={data}
          dataLoading={projectLoading}
          setDataLoading={setProjectLoading}
          activeType={activeType}
          navLinkHandler={navLinkHandler}
          setAllChecked={setAllChecked}
          setCheckedItems={setCheckedItems}
        />

        <div className="row mb-5">
          <div className="col-12">
            <Content
              data={data}
              loading={dataLoading || projectLoading}
              activeType={activeType}
              allChecked={allChecked}
              setAllChecked={setAllChecked}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
            />
            {error && (
              <div className="row">
                <div className="col-12">
                  <Alert
                    message={t(
                      "Something went wrong, please refresh current page."
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </TransportOrderProvider>
  )
}

export default Project
