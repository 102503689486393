import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import Loader from "../../../Loader/Loader"

export default function ModalUpdate({
  show,
  data,
  handleHideEditModal,
  handleUpdateDocumentRecipient,
  loading,
  existingEmail
}) {
  const { t } = useTranslation()
  const ref = useRef()

  const [disabled, setDisabled] = useState(true)
  const [checked, setChecked] = useState(false)

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm({
    shouldUnregister: true,
    mode: "all"
  })

  const handleChange = () => {
    const documentTypes = getValues("documentTypes")
    if (documentTypes.length > 0) {
      setDisabled(false)
    } else if (!documentTypes || documentTypes.length === 0) {
      setDisabled(true)
    } else {
      setDisabled(Object.keys(errors).length !== 0 || loading)
    }
  }

  return (
    <Modal show={show} onHide={handleHideEditModal} centered animation={false}>
      <form
        onSubmit={handleSubmit(handleUpdateDocumentRecipient)}
        ref={ref}
        onChange={handleChange}>
        <Modal.Header className="justify-content-center">
          <h3 className="header-title mt-3 mb-4 text-center">
            {data ? t("Update recipient") : t("Add recipient")}
          </h3>
        </Modal.Header>
        {loading && (
          <>
            <Modal.Body>
              <div className="row">
                <div className="col-12">
                  <Loader />
                </div>
              </div>
            </Modal.Body>
          </>
        )}
        {!loading && (
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <label
                  htmlFor="email"
                  className="field__label form-label mt-1 mb-1 text-uppercase">
                  {t("Email")}
                </label>
                <input
                  type="hidden"
                  name="oldEmail"
                  value={data?.email}
                  {...register("oldEmail")}
                />
                <input
                  id="email"
                  type="email"
                  className="form-control"
                  required
                  defaultValue={data?.email}
                  aria-invalid={errors?.email ? "true" : "false"}
                  {...register(
                    "email",
                    {
                      onChange: (e) => {
                        handleChange(e)
                      }
                    },
                    { required: true }
                  )}
                />
                {errors?.email && (
                  <span className="text-danger d-block font-xs mt-2">
                    {t("Invalid email address")}
                  </span>
                )}
                {existingEmail && (
                  <span className="text-danger d-block font-xs mt-2">
                    {t(
                      "Recipient is already registered with this e-mail address"
                    )}
                  </span>
                )}
              </div>
              <div className="col-12 mt-4">
                <label className="field__label form-label mt-1 mb-1 text-uppercase">
                  {t("Document types")}
                </label>
                <div className="align-items-center d-sm-flex">
                  <div className="d-flex align-items-center">
                    <input
                      id="deliveryNote"
                      name="deliveryNote"
                      type="checkbox"
                      value={1}
                      defaultChecked={data?.documentTypes.includes(1)}
                      className="form-check-input list-checkbox mt-0 flex-shrink-0"
                      {...register("documentTypes", {
                        onChange: (e) => {
                          handleChange(e)
                          setChecked(!checked)
                        }
                      })}
                    />
                    <label htmlFor="deliveryNote" className="ml-2 mb-0">
                      {t("Delivery note")}
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-3 my-sm-0 mx-sm-4">
                    <input
                      id="returnSlip"
                      name="returnSlip"
                      type="checkbox"
                      value={2}
                      defaultChecked={data?.documentTypes.includes(2)}
                      className="form-check-input list-checkbox mt-0 flex-shrink-0"
                      {...register("documentTypes", {
                        onChange: (e) => {
                          handleChange(e)
                          setChecked(!checked)
                        }
                      })}
                    />
                    <label htmlFor="returnSlip" className="ml-2 mb-0">
                      {t("Return slip")}
                    </label>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      id="outstandingRents"
                      name="outstandingRents"
                      type="checkbox"
                      value={3}
                      defaultChecked={data?.documentTypes.includes(3)}
                      className="form-check-input list-checkbox mt-0 flex-shrink-0"
                      {...register("documentTypes", {
                        onChange: (e) => {
                          handleChange(e)
                          setChecked(!checked)
                        }
                      })}
                    />
                    <label htmlFor="outstandingRents" className="ml-2 mb-0">
                      {t("Outstanding rents")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
          {!loading && (
            <>
              <button
                className="btn btn-white"
                type="button"
                disabled={loading}
                onClick={handleHideEditModal}>
                {t("Close")}
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={disabled}>
                {t("Save")}
              </button>
            </>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  )
}
