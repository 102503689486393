import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { getMachineAncestors } from "../../../utils/hooks"
import parser from "html-react-parser"

export default function BreadCrumb({ machineGroupData }) {
  const { t } = useTranslation()

  const capitialize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const firstProductCat = machineGroupData?.product_cat?.[0]

  // Fetch current machine group ancestors
  const { data: ancestors, isSuccess } = useQuery({
    queryKey: [`singleMachineAncestors-${firstProductCat}`],
    queryFn: async () => {
      const data = await getMachineAncestors(firstProductCat)
      return data
    },
    cacheTime: Infinity,
    refetchOnWindowFocus: true
  })

  return (
    <div className="bg-white border-bottom rounded-0">
      <div className="container-fluid container-fluid-max-width">
        {isSuccess && (
          <div className="hll-breadcrumb py-3">
            <div className="scrollable-breadcrumb">
              <div className="hll-breadcrumb__item">
                <Link to={`/${t("customer")}`} className="text-primary">
                  {capitialize(t("customer"))}
                </Link>
                <span className="mx-2">/</span>
              </div>
              <div className="hll-breadcrumb__item">
                <Link
                  to={`/${t("customer")}/${t("machines")}`}
                  className="text-primary">
                  {capitialize(t("machines"))}
                </Link>
                <span className="mx-2">/</span>
              </div>
              {ancestors &&
                ancestors?.length > 0 &&
                ancestors?.map((ancestor, index) => {
                  return (
                    <div className="hll-breadcrumb__item" key={index}>
                      <Link
                        to={`/${t("customer")}/${t("machines")}/${
                          ancestor?.hierarchicalSlug
                        }`}
                        className="text-primary">
                        {ancestor?.name}
                      </Link>
                      <span className="mx-2">/</span>
                    </div>
                  )
                })}
              <div className="hll-breadcrumb__item">
                {machineGroupData?.title?.rendered && (
                  <span>{parser(machineGroupData?.title?.rendered)}</span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
