import i18n from "../../../../i18n"

export function billingMethodInstructions(id) {
  const map = {
    0: i18n.t(
      "If the company is connected to the peppol register, you will automatically receive the invoices from HLL in peppol format."
    ),
    1: i18n.t(
      "If the company is connected to the peppol register, you will receive the invoices from HLL in peppol format."
    ),
    2: i18n.t(
      "If you want to switch to another invoice format, contact info@hllab.se"
    ),
    3: i18n.t(
      "If you want to switch to another invoice format, contact info@hllab.se. If the company is connected to the peppol register, you will automatically receive the invoices from HLL in peppol format."
    )
  }

  return map[id] ?? ""
}
