import React from "react"

export default function Alert({ className = "", message = "" }) {
  let classes = className.includes("text-white") ? "" : "text-muted"

  if (className !== "") {
    classes += ` ${className}`
  }

  return <div className={classes}>{message}</div>
}
