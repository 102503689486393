import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"
import { useOidcUser } from "@axa-fr/react-oidc"
import { encode } from "base-64"
import CustomSvgIcon from "../../Common/CustomSvgIcon"
import SkeletonLoader from "../../Common/SkeletonLoader"
import { currentMachineList } from "./Store"
import { useSetRecoilState } from "recoil"

import MachineItem from "./Machines/Item"

const MACHINE_ITEM_LIMIT = 3

export default function Item({
  listData,
  data,
  isLoading,
  showSheet,
  setShowSheet
}) {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()
  const { userid, name, machines, private: isPrivate } = data ?? {}

  const setCurrentMachineListState = useSetRecoilState(currentMachineList)

  if (isLoading) {
    return (
      <div className="customer-machine-list-grid__item card">
        <div className="d-flex flex-column p-0">
          <header className="d-flex justify-content-between align-items-center w-100 p-4 pb-3">
            <div className="d-flex align-items-center justify-content-start w-100">
              <h2 className="text-primary font-weight-bold m-0 w-100">
                <SkeletonLoader className="p-0" rounded={false} />
              </h2>
            </div>
          </header>
          <div className="p-4 pt-0 w-100">
            <div className="machine-grid">
              <div className="machine-grid__item border rounded d-flex align-items-center justify-content-center"></div>
              <div className="machine-grid__item border rounded d-flex align-items-center justify-content-center"></div>
              <div className="machine-grid__item border rounded d-flex align-items-center justify-content-center"></div>
            </div>
          </div>
          <footer className="border-top w-100 mt-auto py-3 px-4">
            <div className="row">
              <div className="col-6">
                <SkeletonLoader className="p-0" rounded={false} />
              </div>
              <div className="col-6">
                <SkeletonLoader className="p-0" rounded={false} />
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }

  if (
    isPrivate &&
    userid !== `${encode(oidcUser?.tid)}${encode(oidcUser?.sub)}`
  ) {
    return null
  }

  return (
    <div className="customer-machine-list-grid__item card">
      <motion.button
        className="btn btn-block d-flex flex-column bg-white rounded p-0"
        onClick={() => {
          setShowSheet(!showSheet)
          setCurrentMachineListState(listData)
        }}>
        <header className="d-flex justify-content-between align-items-center w-100 p-4 pb-3">
          <div className="d-flex align-items-center justify-content-start">
            <h2 className="text-primary font-weight-bold m-0">{name}</h2>
          </div>
        </header>
        <div className="p-4 pt-0 w-100">
          <div className="machine-grid">
            {machines && machines?.length === 0 ? (
              <>
                <div className="machine-grid__item border rounded d-flex align-items-center justify-content-center"></div>
                <div className="machine-grid__item border rounded d-flex align-items-center justify-content-center"></div>
                <div className="machine-grid__item border rounded d-flex align-items-center justify-content-center"></div>
              </>
            ) : (
              machines &&
              machines?.length > 0 &&
              machines.slice(0, MACHINE_ITEM_LIMIT)?.map((item, index) => {
                return <MachineItem key={index} {...item} />
              })
            )}
          </div>
        </div>
        <footer className="border-top w-100 mt-auto py-3 px-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              {isPrivate && (
                <>
                  <CustomSvgIcon
                    name={"lock"}
                    width={16}
                    height={16}
                    className="text-primary"
                  />
                  <small className="ml-2">{t("Private list")}</small>
                </>
              )}
            </div>
            <div className="count">
              {machines && (
                <span className="text-body text-xs">
                  {`${machines?.length} ${t("pc")}`}
                </span>
              )}
            </div>
          </div>
        </footer>
      </motion.button>
    </div>
  )
}
