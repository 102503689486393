import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import PageHeader from "../Common/PageHeader"
import { useOidcUser } from "@axa-fr/react-oidc"
import { useTranslation } from "react-i18next"
import Settings from "./Settings"
import ModalForm from "./Modal"
import ModalConfirm from "./ModalConfirm"
import { inviteFormTemplate, updateFormTemplate } from "./FormTemplate"
import Storage from "../../utils/storage"
import Pagination from "../Pagination/Pagination"
import ContactPersonsTable from "./ContactPersonsTable"
import Toast from "../Common/Toast"
import { useMediaQuery } from "react-responsive"
import ModalSheet from "../Common/ModalSheet"
import { useOnClickOutside } from "../../utils/hooks"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const ContactPersons = () => {
  const outsideClickHandlerRef = useRef()
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()

  const [showModalForm, setShowModalForm] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [contacts, setContacts] = useState([])
  const [initContacts, setInitContacts] = useState([])
  const [users, setUsers] = useState([])
  const [updatedContact, setUpdatedContact] = useState(null)
  const [updateAction, setUpdateAction] = useState("")
  const [reFetch, setReFetch] = useState(false)
  const [reFetchSettings, setReFetchSettings] = useState(false)
  const [searchText, setSearchText] = useState("")
  const userGroups = Storage.get("userGroups") ?? null
  const perPage = 10
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPosts, setCurrentPosts] = useState([])
  const [isBlocked, setIsBlocked] = useState(false)
  const [showModalSheet, setShowModalSheet] = useState(false)
  const [modalSheetData, setModalSheetData] = useState(null)

  const customer = Storage.get("customer", null, "local") ?? null

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(outsideClickHandlerRef, () => {
    setShowModalSheet(false)
    setModalSheetData(null)
  })

  useEffect(() => {
    const indexOfLast = currentPage * perPage
    const indexOfFirst = indexOfLast - perPage

    if (contacts.length > 0) {
      setCurrentPosts(contacts.slice(indexOfFirst, indexOfLast))
    }
  }, [contacts, currentPage])

  const [requiresIdForRentalCustomer, setRequiresIdForRentalCustomer] =
    useState(false)

  const defaultModalFormState = {
    title: "",
    subTitle: "",
    formTemplate: null,
    submitText: t("Save"),
    formData: null,
    action: ""
  }
  const [modalFormState, setModalFormState] = useState(defaultModalFormState)

  const defaultModalConfirmState = {
    data: null,
    modalTitle: "",
    heading: "",
    text: "",
    action: ""
  }
  const [modalConfirmState, setModalConfirmState] = useState(
    defaultModalConfirmState
  )

  const paginate = (currentPage, pageNumber) => setCurrentPage(pageNumber)
  const nextPage = () => setCurrentPage(currentPage + 1)
  const previousPage = () => setCurrentPage(currentPage - 1)

  // Search on change
  const handleSearchChange = (e) => {
    e.preventDefault()
    const value = e.target.value

    setSearchText(value)
    filterData(value)
  }

  // Filter data based on search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim()
    if (lowercasedValue === "") {
      setContacts(initContacts)
    } else {
      const filteredData = search(lowercasedValue)
      setContacts(filteredData)
      setCurrentPage(1)
    }
  }

  const search = (searchText) => {
    let arrayOfMatchedObjects = contacts.filter((object) => {
      return JSON.stringify(object)
        .toString()
        .toLowerCase()
        .includes(searchText)
    })
    return arrayOfMatchedObjects
  }

  // Handle form modal hide
  const handleModalFormHide = (responseData = null) => {
    setShowModalForm(false)
    setModalFormState(defaultModalFormState)
    setReFetch(!reFetch)
  }

  // Handle confirm modal hide
  const handleModalConfirmHide = (responseData, action = "") => {
    setShowModalConfirm(false)
    setModalConfirmState(defaultModalConfirmState)

    if (responseData) {
      if (action === "delete") {
        setUpdatedContact(responseData)
        setUpdateAction(action)
      } else {
        setReFetchSettings(!reFetchSettings)
      }
    }
  }

  // Add
  const handleAdd = () => {
    setShowModalForm(true)
    setModalFormState({
      title: t("Add contact"),
      subTitle: t("Assign permission to rent"),
      formTemplate: updateFormTemplate,
      submitText: t("Save"),
      formData: {
        customerId: customer ? customer?.id : null
      },
      action: "add"
    })
    setShowModalSheet(false)
  }

  // Invite
  const handleInvite = (data) => {
    setShowModalForm(true)
    setModalFormState({
      title: t("Invite as user of My Pages"),
      formTemplate: inviteFormTemplate,
      submitText: t("Invite"),
      formData: data,
      action: "invite"
    })
    setShowModalSheet(false)
  }

  // Edit
  const handleEdit = (data) => {
    setShowModalForm(true)
    setModalFormState({
      title: t("Edit contact"),
      formTemplate: updateFormTemplate,
      submitText: t("Save"),
      formData: data,
      action: "edit"
    })
    setShowModalSheet(false)
  }

  // Delete
  const handleDelete = (data) => {
    setShowModalConfirm(true)
    setModalConfirmState({
      data,
      modalTitle: t("Delete contact"),
      heading: `${t("Do you really want to delete the contact")} ${
        data.firstName
      } ${data.lastName}?`,
      text: t("This action can't be undone."),
      action: "delete"
    })
    setShowModalSheet(false)
  }

  // ID requirement
  const handleIdRequirement = (data, checked) => {
    setShowModalConfirm(true)
    setModalConfirmState({
      data: {
        ...data,
        requiresIdForRentalCustomer: checked
      },
      modalTitle: checked
        ? t("Identification requirement")
        : t("Remove identification requirements when renting?"),
      heading: checked
        ? t(
            "When renting, authorization is always verified through identification check."
          )
        : t(
            "HLL checks that the person's name is included in Permissions to rent, but does not check identification."
          ),
      action: "idRequirement"
    })
    setRequiresIdForRentalCustomer(checked)
    setShowModalSheet(false)
  }

  // onlyCustomerCanCreate
  const handlePermissions = (data, checked) => {
    setShowModalConfirm(true)
    setModalConfirmState({
      data: {
        ...data,
        onlyCustomerCanCreateRentalContact: checked
      },
      modalTitle: t("Permissions"),
      heading: checked
        ? t(
            "This option only allows the customer admin of your company to assign or remove people from permission to rent. HLL cannot make any changes to the list."
          )
        : t(
            "In addition to the customer admin of your company, this choice also allows HLL to assign and remove people from permisson to rent."
          ),
      action: "onlyCustomerCanCreate"
    })
    setShowModalSheet(false)
  }

  useEffect(() => {
    let unmounted = false

    const request = async () => {
      setLoading(true)

      const contactsRequest = axios.post("/api/hll/customerRentalContacts", {
        customerId: customer?.id
      })

      const usersRequest = axios.post("/api/hll/customerUserAccounts", {
        tenantId: oidcUser?.tid
      })

      await axios
        .all([contactsRequest, usersRequest])
        .then(
          axios.spread((...responses) => {
            if (!unmounted) {
              setContacts(responses[0].data ?? [])
              setInitContacts(responses[0].data ?? [])
              setUsers(responses[1].data ?? [])
            }
          })
        )
        .catch((error) => {
          console.log(error, "error")
          console.error(`Error code: ${error.response.data.CorrelationId}`)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (reFetch) {
      setUpdatedContact(null)
      setUpdateAction("")
    }

    if (reFetch || oidcUser?.tid) {
      request()
    }

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id, oidcUser?.tid, reFetch])

  useEffect(() => {
    if (showModalSheet) {
      document.body.classList.add("disable-scroll")
    } else {
      document.body.classList.remove("disable-scroll")
    }
  }, [showModalSheet])

  useEffect(() => {
    if (!modalSheetData?.endDate) {
      setIsBlocked(false)
    } else {
      const now = new Date()

      if (now > new Date(modalSheetData?.endDate)) {
        setIsBlocked(true)
      } else {
        setIsBlocked(false)
      }
    }
  }, [modalSheetData?.endDate])

  return (
    <>
      <ModalConfirm
        show={showModalConfirm}
        onHandleHide={handleModalConfirmHide}
        {...modalConfirmState}
      />
      <ModalForm
        show={showModalForm}
        onHandleHide={handleModalFormHide}
        userGroups={userGroups}
        requiresIdForRentalCustomer={requiresIdForRentalCustomer}
        users={users}
        {...modalFormState}
      />
      <Toast />
      <div className="container-fluid container-fluid-max-width">
        <div className="data-list with-filters">
          <PageHeader
            preTitle={t("My pages")}
            title={t("Allowed to rent")}
            subTitle={t("Persons authorized to rent from Hyreslandslaget")}
            button={{
              text: t("Add person")
            }}
            onClickHandler={handleAdd}
          />

          <div className="row mb-3 mb-lg-4">
            <Settings
              reFetchSettings={reFetchSettings}
              onHandleIdRequirement={handleIdRequirement}
              onHandlePermissions={handlePermissions}
              setRequiresIdForRentalCustomer={setRequiresIdForRentalCustomer}
            />
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card shadow-none">
                <div className="card-body p-0">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                          <input
                            className="form-control list-search"
                            type="search"
                            placeholder={t("Search")}
                            value={searchText}
                            onChange={handleSearchChange}
                            required
                          />
                          <span className="input-group-text">
                            <CustomSvgIcon
                              name="search"
                              width={20}
                              height={20}
                              className={`text-muted`}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="position-relative"
                    style={{ transform: "translate3d(0,0,0)", zIndex: 2 }}>
                    <div className="table-responsive">
                      <ContactPersonsTable
                        contacts={currentPosts}
                        loading={loading}
                        users={users}
                        updateAction={updateAction}
                        updatedContact={updatedContact}
                        handleInvite={handleInvite}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        setShowModalSheet={setShowModalSheet}
                        setModalSheetData={setModalSheetData}
                      />
                    </div>
                  </div>
                  {!loading && contacts.length > 10 && (
                    <div className="card-footer d-flex justify-content-between">
                      <Pagination
                        perPage={perPage}
                        total={contacts.length}
                        paginate={paginate}
                        currentPage={currentPage}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        prevBtnClassName="d-flex page-link pl-0 pr-4"
                        nextBtnClassName="d-flex page-link pl-4 pr-0"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={outsideClickHandlerRef}>
        {useMediaQuery({ query: "(max-width: 990px)" }) && showModalSheet && (
          <>
            <div
              className="modal-backdrop show"
              onClick={() => setShowModalSheet(false)}></div>
            <ModalSheet show={showModalSheet} shadow={true}>
              <div className="py-5 text-center mx-auto">
                <h3>{`${modalSheetData.firstName} ${modalSheetData?.lastName}`}</h3>

                {!isBlocked && (
                  <button
                    className="d-block bg-transparent text-center mx-auto text-primary"
                    onClick={() => handleInvite(modalSheetData)}>
                    {t("Invite")}
                  </button>
                )}

                <button
                  className="d-block bg-transparent text-center mx-auto text-primary"
                  onClick={() => handleEdit(modalSheetData)}>
                  {t("Edit")}
                </button>
                <button
                  className="d-block bg-transparent text-center mx-auto text-primary"
                  onClick={() => handleDelete(modalSheetData)}>
                  {t("Delete contact")}
                </button>
              </div>
            </ModalSheet>
          </>
        )}
      </div>
    </>
  )
}

export default ContactPersons
