import React from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export default function DeleteProjectModal({ handleCancel, show }) {
  const { t } = useTranslation()

  return (
    <Modal show={show} centered animation={false}>
      <Modal.Header>
        <h2 className="header-title">{t("Edit project")}</h2>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t(
            "Sorry, this project cannot be edited as there are already ongoing or previous order on the project"
          )}
          .
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-dark" onClick={handleCancel}>
          {t("Cancel")}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
