import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Form from "./Form/Form"
import { formTemplate } from "./Form/FormTemplate"
import { useForm } from "react-hook-form"

import SkeletonLoader from "../../components/Common/SkeletonLoader"
import Alert from "../../components/Common/Alert"
import { dateAndTimeNow, singleUrl } from "../../utils/helpers"
import { Link } from "react-router-dom"

import Storage from "../../utils/storage"

import axios from "axios"
import moment from "moment"
import Toaster from "../Common/Toast"
import toast from "react-hot-toast"

export default function ProjectEdit({ data, loading, error }) {
  const { t } = useTranslation()
  const customer = Storage.get("customer", null, "local") ?? null
  const [isReady, setIsReady] = useState(false)
  const [editLoader, setEditLoader] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const [nameRequired, setNameRequired] = useState(null)
  const [numberRequired, setNumberRequired] = useState(null)

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors }
  } = useForm({
    shouldUnregister: true
  })

  const handleForm = {
    register,
    errors,
    setError,
    getValues,
    formData: data
  }

  const onSubmit = async (data) => {
    let formattedData = {}

    setEditLoader(true)
    setIsDisabled(true)
    setIsReady(false)

    formattedData = {
      name: data?.name,
      number: data?.number,
      customerId: customer?.id,
      startTime: moment().format("YYYY-MM-DD[T]hh:mm:ss.SSS[Z]"),
      address: {
        adressLine1: data?.adressLine1 ?? "",
        adressLine2: data?.adressLine2 ?? "",
        adressLine3: data?.adressLine3 ?? "",
        postalCode: data?.postalCode ?? "",
        city: data?.city ?? "",
        country: data?.country ?? ""
      }
    }

    await axios
      .post(`/api/hll/editProject`, {
        id: data?.id,
        body: formattedData
      })
      .then((response) => {
        if (response.data) {
          toast.success(t("The project is updated"))

          window.dataLayer.push({
            event: "HLL_project_edit",
            company: customer?.name,
            projectName: response?.data?.name,
            createdAt: dateAndTimeNow(),
            userType: "customer"
          })

          setIsReady(true)
        }
      })
      .catch((error) => {
        console.error(error)
        toast.error(t("Something went wrong, try again."))
        setIsReady(false)
        setIsDisabled(false)
      })
      .finally(() => {
        setEditLoader(false)
      })
  }

  useEffect(() => {
    if (!data?.isEditable) {
      setIsDisabled(true)
    } else if (data?.isBlockedForOrder) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [data])

  useEffect(() => {
    if (data) {
      const nameValue = data["name"]
      const numberValue = data["number"]

      if (nameValue !== "" && numberValue === "") {
        setNameRequired(true)
        setNumberRequired(false)
      } else if (nameValue === "" && numberValue !== "") {
        setNameRequired(null)
        setNumberRequired(true)
      } else if (nameValue === "" && numberValue === "") {
        setNameRequired(true)
        setNumberRequired(true)
      } else {
        setNameRequired(true)
        setNumberRequired(true)
      }
    }
  }, [data])

  useEffect(() => {
    setTimeout(() => {
      if (isReady) {
        setIsDisabled(false)
        window.location.reload()
      }
    }, 1000)
  }, [isReady])

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid container-fluid-max-width">
          <div className="row">
            <div className="col-12">
              <div className="header">
                <div className="header-body border-bottom-0 pb-0">
                  <div className="row align-items-center">
                    {error && (
                      <div className="row">
                        <div className="col-12">
                          <h1 className="mb-0">
                            <Alert
                              message={t(
                                "Something went wrong, please refresh current page."
                              )}
                            />
                          </h1>
                        </div>
                      </div>
                    )}

                    {loading && <SkeletonLoader />}

                    {!loading && data && (
                      <>
                        <div className="col">
                          <h6 className="header-pretitle">{`${t(
                            "My pages"
                          )} - ${t("Edit")}`}</h6>
                          <h1 className="header-title text-truncate">
                            {data?.number && data.number}
                            {data?.name && ` ${data.name}`}
                          </h1>
                        </div>
                        <div className="col-auto">
                          {data && (
                            <>
                              <Link
                                to={{
                                  pathname: singleUrl(
                                    `${t("project")}`,
                                    data?.customerId,
                                    data?.id
                                  ),
                                  customerId: data?.customerId,
                                  id: data?.id,
                                  name: data?.name,
                                  number: data?.number
                                }}>
                                <button className="btn btn-outline-secondary mr-3 ml-auto mt-5 mt-md-0">
                                  {t("Back")} {t("to")} {t("overview")}
                                </button>
                              </Link>
                              <button
                                className={`btn btn-primary ml-auto mt-5 mt-md-0${
                                  isDisabled ? " disabled" : ""
                                }`}
                                disabled={isDisabled}>
                                {editLoader ? (
                                  <div
                                    className="spinner-grow spinner-grow-sm text-white position-relative"
                                    role="status"
                                    style={{ top: "-2px" }}>
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                ) : (
                                  t("Save")
                                )}
                              </button>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="row my-5">
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-lg-8 order-1">
                  <div className="card">
                    <div className="card-header">
                      <h2 className="card-header-title text-truncate">
                        {t("General information")}
                      </h2>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        {loading && <SkeletonLoader />}
                        {!loading && data && (
                          <Form
                            template={formTemplate}
                            handleForm={handleForm}
                            isDisabled={isDisabled}
                            nameRequired={nameRequired}
                            setNameRequired={setNameRequired}
                            numberRequired={numberRequired}
                            setNumberRequired={setNumberRequired}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 order-2">
                  {loading && <SkeletonLoader />}
                  {!loading && (
                    <>
                      <h4>{t("Edit project")}</h4>
                      <p>
                        Projekt motsvarar de arbetsplatser som maskiner
                        levereras till och styr hur märkningen på fakturor ska
                        se ut. Här kan du enkelt redigera ett projekt genom att
                        ändra informationen i formuläret.
                      </p>
                      <p>
                        Det är obligatoriskt att ange antingen namn eller nummer
                        på projektet, det går även att ange båda två. Denna
                        information syns sedan på fakturan. Den adress som anges
                        kommer användas som leveransadress för kommande hyror,
                        det är därför viktigt att den är korrekt.
                      </p>
                      <p>
                        <small>
                          <strong>
                            Det går endast att redigera projekt som saknar
                            tidigare hyror.
                          </strong>
                        </small>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
