import React, { useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import DatePicker, { registerLocale } from "react-datepicker"
import sv from "date-fns/locale/sv"
import moment from "moment"
import "moment-timezone"
import { newOrderRows } from "../Store"
import { useRecoilState } from "recoil"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

registerLocale("sv", sv)

const ChangeDateForm = ({ setShowModalSheet }) => {
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState(moment().toDate())
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const datePickerRef = useRef()
  const days = (type, nr = 7) => moment()[type](nr, "d").toDate()
  const [newOrderRowsState, setNewOrderRowsState] = useRecoilState(newOrderRows)

  return (
    <div>
      <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
        <div className="col">
          <label
            htmlFor="date"
            className="field__label d-block form-label mb-1 text-uppercase">
            {t("Change date for all machines/articles")}
          </label>
          <div className="position-relative">
            <DatePicker
              selected={startDate}
              dateFormat="yyyy-MM-dd"
              minDate={days("subtract")}
              maxDate={days("add")}
              locale="sv"
              ref={datePickerRef}
              onClickOutside={() => setDatePickerOpen(false)}
              onFocus={() => setDatePickerOpen(true)}
              open={datePickerOpen}
              readOnly={true}
              className="rounded-0"
              onChange={(date) => {
                setDatePickerOpen(false)
                setStartDate(date)
              }}
            />
            <CustomSvgIcon
              width={16}
              height={16}
              name="calender"
              className={`text-muted pointer-none`}
              style={{
                position: "absolute",
                right: 20,
                top: 12
              }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center mt-3">
        <button
          className="btn btn-outline-secondary w-100 d-block mr-3"
          onClick={() => setShowModalSheet(false)}>
          {t("Cancel")}
        </button>
        <button
          className="btn btn-primary w-100 d-block"
          onClick={() => {
            const updatedState = newOrderRowsState.map((row) => {
              return {
                ...row,
                startTime: `${datePickerRef.current.input.value}T00:00:00Z`
              }
            })

            setNewOrderRowsState(updatedState)
            setShowModalSheet(false)
          }}>
          {t("Save")}
        </button>
      </div>
    </div>
  )
}

export default ChangeDateForm
