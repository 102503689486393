import axios from "axios"

const CreateProject = async (formattedData) => {
  let data = []

  await axios
    .post("/api/hll/createProject", {
      body: formattedData
    })
    .then((response) => {
      data = response?.data ? response.data : []
    })

  return data
}

export default CreateProject
