import i18n from "../../i18n"

export const tableHeader = [
  {
    title: i18n.t("Name"),
    name: "firstName",
    alignment: "left",
    sortable: true
  },
  {
    title: i18n.t("Title"),
    name: "title",
    alignment: "left",
    sortable: true
  },
  {
    title: i18n.t("Email"),
    name: "email",
    alignment: "left",
    sortable: true
  },
  {
    title: i18n.t("Phone"),
    name: "phone1",
    alignment: "left",
    sortable: true
  },
  {
    title: i18n.t("Date of birth"),
    name: "dateOfBirth",
    alignment: "left",
    sortable: true
  },
  {
    title: i18n.t("User account"),
    name: "userAccount",
    alignment: "center",
    sortable: false
  },
  {
    title: "",
    name: "contactActions",
    alignment: "left",
    sortable: false
  }
]

export function hasUserAccount(users, email) {
  return users.find((user) => user.email === email)
}
