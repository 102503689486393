import React from "react"
import { singleUrl } from "../../../utils/helpers"
import { useTranslation } from "react-i18next"
import Icon from "../../Common/Icon"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

function ListItem({
  customerNumber,
  customerOrderId,
  customerName,
  customerType,
  siteName,
  siteNumber,
  customerOrderNumber,
  clickHandler,
  siteType,
  siteAddress
}) {
  const { t } = useTranslation()

  const ListItemIcon = () => {
    if (siteType === 5) {
      return <Icon name="stop" className="text-primary order-item-icon" />
    }

    if (customerType === 1) {
      return <Icon name="money" className="text-primary order-item-icon" />
    }

    return (
      <CustomSvgIcon
        width={16}
        height={16}
        name="chevron-right"
        className="order-item-icon"
      />
    )
  }

  return (
    <button
      onClick={() =>
        clickHandler(
          singleUrl(`${t("returns")}`, customerNumber, customerOrderId)
        )
      }
      className={`bg-white p-4 w-100 text-left d-flex justify-content-between align-items-center order-item${
        customerType === 1 ? " direct-payment" : ""
      }`}>
      <div className="pr-5">
        <span className="d-block mb-2 order-item-name font-weight-bold">
          {customerName}
        </span>
        <span className="mr-3 text-body">
          {customerOrderNumber && customerOrderNumber}
        </span>
        <span className="text-muted text-gray-700">
          {siteNumber && siteNumber}
          {siteName && ` ${siteName}`}
        </span>
        <div>
          <small>{siteAddress && siteAddress}</small>
        </div>
      </div>
      <div className="ml-auto pl-3 d-flex align-items-center">
        <ListItemIcon />
      </div>
    </button>
  )
}

export default ListItem
