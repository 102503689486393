import { useEffect, useState, useRef, useCallback } from "react"
import { useRecoilState, useResetRecoilState, useRecoilValue } from "recoil"
import { useOidcUser } from "@axa-fr/react-oidc"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { encode } from "base-64"
import axios from "axios"
import toast from "react-hot-toast"

import { machineLists, currentMachineList } from "./Store"
import CustomSvgIcon from "../../Common/CustomSvgIcon"
import Storage from "../../../utils/storage"

import PageHeader from "../../Common/PageHeader"
import Overlay from "../../Common/Overlay"
import Sheet from "./Sheet/Sheet"
import CreateListModal from "./Modals/CreateList"

import Item from "./Item"
import ConfirmationModal from "./Modals/Confirmation"

import { useOnClickOutside } from "../../../utils/hooks"
import { dateAndTimeNow } from "../../../utils/helpers"

const ARRAY_LIMIT = 1

export default function MachineLists() {
  const { t } = useTranslation()
  const sheetRef = useRef(null)
  const customer = Storage.get("customer", null, "local") ?? null
  const { oidcUser } = useOidcUser()

  // Recoil States
  const [machineListState, setMachineListState] = useRecoilState(machineLists)
  const currentMachineListState = useRecoilValue(currentMachineList)
  const resetCurrentMachineListState = useResetRecoilState(currentMachineList)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)

  //Local States
  const [showSheet, setShowSheet] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useOnClickOutside(sheetRef, () => {
    setShowSheet(false)
  })

  const escFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        setShowSheet(false)
        setShowModal(false)
        setShowConfirmDeleteModal(false)
        resetCurrentMachineListState()
      }
    },
    [resetCurrentMachineListState]
  )

  const handleClose = () => {
    setShowSheet(false)
    setShowModal(false)
    setShowConfirmDeleteModal(false)
    resetCurrentMachineListState()
  }

  // Handler delete current machine list
  const handleDelete = async () => {
    const listId = currentMachineListState?.id
    setLoading(true)
    await axios
      .delete(`/api/database/customer/deleteMachineList/${listId}`)
      .then(
        (_res) => {
          setShowConfirmDeleteModal(false)
          resetCurrentMachineListState()
          machineListRefetch()
          toast.success(t("Machine list deleted"))

          // Delete machine list event
          window.dataLayer.push({
            event: `HLL_delete_machine_list`,
            company: customer?.name,
            createdAt: dateAndTimeNow(),
            userType: "customer"
          })
        },
        (err) => {
          console.log(err, "DELETE /api/database/customer/deleteMachineList")
          toast.error(t("Something went wrong"))
        }
      )
      .finally(() => {
        setLoading(false)
        setShowSheet(false)
      })
  }

  // fetch customer machine lists
  const {
    isLoading,
    isRefetching,
    refetch: machineListRefetch
  } = useQuery({
    queryKey: ["customerMachineLists"],
    queryFn: async () => {
      const res = await axios.post("/api/database/customer/machineLists", {
        customerId: customer?.id,
        tid: encode(oidcUser?.tid),
        sub: encode(oidcUser?.sub)
      })
      const { data } = res
      let sortedList = data?.rows?.sort(
        (a, b) => new Date(b?.created) - new Date(a?.created) // sort by created date
      )

      setMachineListState({
        lists: sortedList,
        count: data?.rowCount ?? 0
      })
      return data
    },
    enabled: !!customer?.id && !!oidcUser?.tid && !!oidcUser?.sub,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true
  })

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)

    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [escFunction])

  return (
    <>
      {showSheet && <Overlay />}
      {showModal && <Overlay />}
      {showConfirmDeleteModal && <Overlay />}

      <ConfirmationModal
        showModal={showConfirmDeleteModal}
        setShowConfirmDeleteModal={setShowConfirmDeleteModal}
        handleDelete={handleDelete}
        loading={loading}
      />

      <div className="container-fluid container-fluid-max-width">
        <PageHeader
          preTitle={t("My pages")}
          title={t("Machine lists")}
          subTitle={t(
            "Create private or shared machine lists. Private list means that it's content will not be accessible to users other than you."
          )}
        />

        <div className="customer-machine-list-grid">
          <div className="d-flex align-items-center justify-content-center bg-muted border--dashed border-color-primary rounded text-primary">
            <button
              className="p-0 bg-transparent border-0 w-100"
              onClick={() => {
                setShowModal(!showModal)
              }}>
              <div className="customer-machine-list-grid__item customer-machine-list-grid__item--first no-shadow d-flex flex-column justify-content-center align-items-center">
                <div className="text-primary">
                  <div className="svg-icon-wrapper text-primary mb-3">
                    <CustomSvgIcon name="add" width={20} height={20} />
                  </div>
                  <p className="m-0">{t("Create new machine list")}</p>
                </div>
              </div>
            </button>
          </div>

          {!isLoading &&
            !isRefetching &&
            machineListState?.lists &&
            machineListState?.lists?.length === 0 &&
            Array?.from(Array(ARRAY_LIMIT).keys()).map((_item, index) => {
              return (
                <div
                  key={index}
                  className="customer-machine-list-grid__item no-shadow">
                  <div className="btn btn-block d-flex flex-column bg-transaprent border border--dashed rounded"></div>
                </div>
              )
            })}

          {isLoading || isRefetching
            ? Array?.from(Array(ARRAY_LIMIT).keys()).map((_item, index) => {
                return <Item key={index} isLoading={true} />
              })
            : machineListState?.lists &&
              machineListState?.lists?.length > 0 &&
              machineListState?.lists?.map((item, index) => {
                return (
                  <Item
                    key={index}
                    {...item}
                    listData={item}
                    showSheet={showSheet}
                    setShowSheet={setShowSheet}
                  />
                )
              })}
        </div>
      </div>
      <CreateListModal
        title={t("Create new machine list")}
        showModal={showModal}
        setMachineListState={setMachineListState}
        handleClose={() => {
          setShowModal(false)
        }}
      />
      {showSheet && !showConfirmDeleteModal && (
        <div ref={sheetRef}>
          <Sheet
            setShowSheet={setShowSheet}
            handleClose={handleClose}
            machineListRefetch={machineListRefetch}
            setShowConfirmDeleteModal={setShowConfirmDeleteModal}
          />
        </div>
      )}
    </>
  )
}
