import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import useOrderContext from "../../context/ReturnOrder/Store"
import Moment from "react-moment"
import moment from "moment"
import CustomSvgIcon from "../Common/CustomSvgIcon"

function LockedOrder({ customerOrderHead }) {
  const { t } = useTranslation()
  const { setOrderHeader } = useOrderContext()

  const date = customerOrderHead?.lock?.lockedAt
  const localDateAndTime = moment.utc(date).local().format()

  useEffect(() => {
    setOrderHeader({
      customerOrderNumber: customerOrderHead.customerOrderNumber,
      customerOrderId: customerOrderHead.customerOrderId,
      customerName: customerOrderHead.customerName,
      customerId: customerOrderHead.customerId,
      customerStatus: customerOrderHead.customerStatus
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container-fluid container-fluid-max-width">
      <div className="row justify-content-center">
        <div className="col-8 col-sm-7 col-md-6 col-lg-4 col-xl-3 py-5 text-center">
          <div className="mb-4">
            <CustomSvgIcon name="lock" width={50} height={50} />
          </div>
          <h1 className="h3 mb-2">
            {t("This order is locked or used by someone else.")}
          </h1>
          <p className="mb-4">
            <Moment
              dateTime={localDateAndTime}
              format="YYYY-MM-DD HH:mm"
              tz="Europe/Stockholm">
              {localDateAndTime}
            </Moment>
            {" | "} {customerOrderHead?.lock?.lockedBy}
          </p>
          <p>
            <Link
              to={`/${t("returns")}`}
              className="btn btn-primary btn-sm font-weight-bold px-4 py-2">
              {t("Back")}
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default LockedOrder
