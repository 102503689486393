import slugify from "react-slugify"
import i18n from "../i18n"
import Storage from "./storage"
import moment from "moment"
import Personnummer from "personnummer"

export function singleUrl(base, id, name = "") {
  return `/${base}/${id}/${slugify(name)}`
}

export const isIOS = /iPad|iPhone|iPod/.test(navigator?.userAgent)

export const orderSearchKeys = [
  {
    id: "fleetModelId",
    name: "Individnummer"
  },
  {
    id: "customerName",
    name: "Kundnamn"
  },
  {
    id: "siteId",
    name: "Platsnummer"
  },
  {
    id: "siteName",
    name: "Plats"
  },
  {
    id: "customerNumber",
    name: "Kundnummer"
  },
  {
    id: "articleId",
    name: "Artikelnummer"
  }
]

export function orderSearchBody(value = "", regionId) {
  return {
    fleetModelId: {
      logicalOperator: "Or",
      predicate: "StartsWith",
      value
    },
    articleId: {
      logicalOperator: "Or",
      predicate: "StartsWith",
      value
    },
    customerName: {
      logicalOperator: "Or",
      predicate: "StartsWith",
      value
    },
    customerNumber: {
      logicalOperator: "Or",
      predicate: "StartsWith",
      value
    },
    siteId: {
      logicalOperator: "Or",
      predicate: "StartsWith",
      value
    },
    siteName: {
      logicalOperator: "Or",
      predicate: "StartsWith",
      value
    },
    regionId: {
      logicalOperator: "And",
      predicate: "Equal",
      value: regionId
    }
  }
}

export function offerTypeMap(id, type = "name") {
  //RENT_BUSINESS_DAYS, RENT_ALL_DAYS, PURCHASE, CONSUMED_AMOUNT, INCLUDED, UNKNOWN, RENT_FOUR_DAYS
  const map = {
    0: {
      name: "Rent",
      niceName: i18n.t("Rent") // RENT_BUSINESS_DAYS
    },
    1: {
      name: "Rent",
      niceName: i18n.t("Rent") // RENT_ALL_DAYS
    },
    2: {
      name: "Purchase",
      niceName: i18n.t("Purchase")
    },
    3: {
      name: "PartialPurchase",
      niceName: i18n.t("Partial purchase")
    },
    4: {
      name: "Accessory",
      niceName: i18n.t("Accessories")
    },
    5: {
      name: "UNKNOWN",
      niceName: i18n.t("Unknown")
    },
    6: {
      name: "Rent",
      niceName: i18n.t("Rent") //RENT_FOUR_DAYS
    }
  }

  return map[id][type] ?? ""
}

export function orderRowTypeMap(id) {
  // UNKNOWN, Rent, Purchase, PartialPurchase, Accessory, MonthlyRent, DeliveryNoteText
  let mappedId = id

  if (id === 0 || id === 6) {
    mappedId = 1
  }

  if (id === 5) {
    mappedId = 0
  }

  const map = {
    0: "UNKNOWN", //<- UNKNOWN
    1: "Rent", //<- RENT_BUSINESS_DAYS, RENT_ALL_DAYS, RENT_FOUR_DAYS
    2: "Purchase", // <- PURCHASE
    3: "PartialPurchase", //<- CONSUMED_AMOUNT
    4: "Accessory", //<- INCLUDED
    5: "MonthlyRent", // finns ej
    6: "DeliveryNoteText" // finns ej
  }

  return map[mappedId] ?? "UNKNOWN"
}

export function orderRowTypeNameMap(name) {
  // UNKNOWN, Rent, Purchase, PartialPurchase, Accessory, MonthlyRent, DeliveryNoteText
  let mappedName = name

  if (name === "UNKNOWN" || name === "DeliveryNoteText") {
    mappedName = "Rent"
  }

  if (name === "MonthlyRent") {
    mappedName = "UNKNOWN"
  }

  const map = {
    UNKNOWN: 0, //<- UNKNOWN
    Rent: 1, //<- RENT_BUSINESS_DAYS, RENT_ALL_DAYS, RENT_FOUR_DAYS
    Purchase: 2, // <- PURCHASE
    PartialPurchase: 3, //<- CONSUMED_AMOUNT
    Accessory: 4, //<- INCLUDED
    MonthlyRent: 5, // finns ej
    DeliveryNoteText: 6 // finns ej
  }

  return map[mappedName] ?? 0
}

export function returnPriceOfferMeta(offers, divider = true) {
  let meta = ""

  if (!offers || offers.length === 0) {
    return meta
  }

  if (divider) {
    meta += ` | `
  }

  if (offers[0].type === 4 && !offers[0].price) {
    meta += `0 kr`
  } else {
    meta += `${offers[0].price} kr /${offers[0].priceUnit}`
  }

  return meta
}

export function calculateAgreedPrice(agreedPrice, price) {
  let formattedAmount = Number(agreedPrice.amount)

  if (!Number.isInteger(formattedAmount) || formattedAmount === 0) {
    return price
  }

  if (agreedPrice.type === "kr") {
    return agreedPrice.amount
  }

  return -Math.abs(formattedAmount)
}

export function itemStatus(statusName) {
  const map = {
    BorgholmHammaren: "-8", //<- Borgholm Hammaren  (handled by Borgholm Hammaren)
    MachineGroup: "-7", //<- Maskingrupp Akalla  (handled by Akalla Machinegroup)
    SeasonalStock: "-6", //<- Säsongslager Alingsås (seasonal stock Alingsås)
    Examined: "-5", // <- Undersöks i  Akalla verkstad (examined Akalla workshop)
    New: "-4", //<- Ny individ under handläggning, Akalla (New individ under processing)
    Workshop: "-3", //<- Verkstad (workshop)'
    Scrapped: "-2", //<- Skrotad (scrapped)
    Sold: "-1", //<- Såld (sold)
    Rented: 0, //<- Uthyrd (rented out)
    Branch: 1, //<- Depå (branch)
    Unknown: 2 //<- Depot (branch) vacant or Unknown status
  }

  return map[statusName] ?? null
}

export function formatUserGroupsForSelect() {
  const userGroups = Storage.get("userGroups") ?? null

  if (!userGroups) {
    return []
  }

  let formattedGroups = []

  userGroups.forEach(({ id, name }) => {
    formattedGroups.push({
      value: id,
      text: name
    })
  })

  return formattedGroups
}

export function findUserGroupById(id) {
  const userGroups = Storage.get("userGroups") ?? null

  if (!userGroups) {
    return []
  }

  return userGroups.filter((group) => group.id === id)
}

export function machineStatus(id) {
  const map = {
    "-8": "Borgholm Hammaren",
    "-7": "Maskingrupp Akalla",
    "-6": "Säsongslager Alingsås",
    "-5": "Undersöks i  Akalla verkstad",
    "-4": "Ny individ under handläggning, Akalla",
    "-3": "Saknad vid inventering",
    "-2": "Skrotad",
    "-1": "Såld",
    0: "Uthyrd"
  }

  return map[id] ?? null
}

export function inspectionType(id) {
  //https://hll.atlassian.net/browse/HLL-577

  if (!id) {
    return null
  }

  const map = {
    1: i18n.t("Inspection"),
    2: i18n.t("Yearly inspection"),
    4: i18n.t("Fire inspection"),
    5: i18n.t("Sprinkler inspection"),
    6: i18n.t("Exhaust test")
  }

  return map[id] ?? null
}

export function intervalType(id) {
  if (!id) {
    return null
  }

  const map = {
    1: i18n.t("Months"),
    2: i18n.t("Days")
  }

  return map[id] ?? null
}

export function availabilityName(availability) {
  const map = {
    "-1": i18n.t("On control"),
    0: i18n.t("Not Available"),
    1: i18n.t("Available")
  }

  return map[availability] ?? i18n.t("Not Available")
}

export function availabilityColorMap(availability) {
  const map = {
    "-1": "control",
    0: "not-available",
    1: "available"
  }

  return map[availability] ?? null
}

export function getInspectionDiff(inspection) {
  const today = moment()
  const intervalType = inspection?.intervalType
  const interval = inspection?.interval

  const momentInspectionDate = moment(inspection?.date).format("YYYY-MM-DD")

  const inspectionDueDate = moment(momentInspectionDate)
    .add(30, "days")
    .add(interval, intervalType === 1 ? "M" : "days")

  return {
    days: today.diff(inspectionDueDate, "days"),
    dueDate: inspectionDueDate
  }
}

export function getMachineStatus() {
  return ["1", "0", "-5", "-6", "-7", "-8", "-9", "-10"]
}

export function removeItemAtIndex(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

export function replaceItemAtIndex(arr, index, newValue) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)]
}

export function groupMachinesByBranch(
  machines,
  groupByObject = "newBranchName"
) {
  const temp = machines.reduce((newBranchName, machine) => {
    if (groupByObject === "newBranchName") {
      newBranchName[machine[groupByObject]] = [
        ...(newBranchName[machine[groupByObject]] || []),
        machine
      ]
    } else {
      newBranchName[machine.fromBranch.name] = [
        ...(newBranchName[machine.fromBranch.name] || []),
        machine
      ]
    }

    return newBranchName
  }, {})

  if (!temp) {
    return []
  }

  const machineList = []

  for (const key in temp) {
    machineList.push({
      newBranchName: key,
      items: temp[key]
    })
  }

  return machineList.sort((a, b) =>
    a.newBranchName > b.newBranchName ? 1 : -1
  )
}

export function billingFrequencyMapping(num) {
  const map = {
    1: i18n.t("Three times per month"),
    2: i18n.t("Two times per month"),
    3: i18n.t("One time per month"),
    4: i18n.t("One time middle of month")
  }

  return map[num] ?? ""
}

export function socialSecurityValidation(val) {
  return Personnummer.valid(val)
}

export function projectMarkingType(num) {
  const map = {
    1: "siteNumber",
    2: "siteName"
  }

  return map[num] ?? ""
}

export function googleMapsDataToAddress(addressData) {
  let formattedData = {
    adressLine1: "",
    adressLine2: "",
    adressLine3: "",
    postalCode: "",
    city: "",
    country: ""
  }

  if (!addressData) {
    return formattedData
  }

  let route = ""
  let street_number = ""
  let postal_town = ""
  let postal_code = ""
  let country = ""

  const components = addressData["address_components"]

  components.map((component) => {
    const name = component["long_name"]
    const type = component["types"][0]

    switch (type) {
      case "route":
        route = name
        break

      case "street_number":
        street_number = name
        break

      case "postal_town":
        postal_town = name
        break

      case "postal_code":
        postal_code = name
        break

      case "country":
        country = name
        break
      default:
    }

    if (route !== "" && street_number !== "") {
      formattedData.adressLine1 = `${route} ${street_number}`
    } else {
      formattedData.adressLine1 = addressData?.name
    }

    formattedData.postalCode = postal_code
    formattedData.city = postal_town
    formattedData.country = country

    return formattedData
  })

  return formattedData
}

export function multiDimensionalUnique(arr) {
  let uniques = []
  let itemsFound = {}

  for (let i = 0, l = arr.length; i < l; i++) {
    const stringified = JSON.stringify(arr[i])

    if (itemsFound[stringified]) {
      continue
    }

    uniques.push(arr[i])
    itemsFound[stringified] = true
  }

  return uniques
}

export function arrayUnique(arr) {
  let unique = [...new Set(arr)]
  return unique
}

export function billingMethodTypeMapping(num) {
  const map = {
    0: "Pappersfaktura",
    1: "PDF Faktura",
    2: "Peppol",
    3: "Svefaktura"
  }

  return map[num] ?? ""
}

export function billingMethodTypes() {
  const map = [
    {
      id: 0,
      name: "Pappersfaktura"
    },
    {
      id: 1,
      name: "PDF Faktura"
    },
    {
      id: 2,
      name: "Peppol"
    },
    {
      id: 3,
      name: "Svefaktura"
    }
  ]

  return map
}

export function billingMethodTypeDisabledLogic(defaultValue, id) {
  let disabled = false

  // If 1/Pappers-faktura
  if (defaultValue === 0 && id !== 1) {
    disabled = true
  }

  // If 1/PDF-faktura
  if (defaultValue === 1 && id !== 1) {
    disabled = true
  }

  // If 2/Peppol
  if (defaultValue === 2 && id !== 2) {
    disabled = true
  }

  // If 3/Svefaktura
  if (defaultValue === 3 && id !== 3) {
    disabled = true
  }

  return disabled
}

export function dateAndTimeNow(separator = false, zulu = false) {
  const date = new Date()

  return `${date.toLocaleDateString("sv-se")}${
    separator ? "T" : " "
  }${date.toLocaleTimeString("sv-se")}${zulu ? "Z" : ""}`
}

export function formatTimeAgo(date) {
  const formatter = new Intl.RelativeTimeFormat("sv", {
    numeric: "auto"
  })

  const DIVISIONS = [
    { amount: 60, name: "seconds" },
    { amount: 60, name: "minutes" },
    { amount: 24, name: "hours" },
    { amount: 7, name: "days" },
    { amount: 4.34524, name: "weeks" },
    { amount: 12, name: "months" },
    { amount: Number.POSITIVE_INFINITY, name: "years" }
  ]

  let duration = (date - new Date()) / 1000

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i]
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name)
    }
    duration /= division.amount
  }
  return duration
}

export function inventoryOccasionStatus(num) {
  const map = {
    0: i18n.t("Unknown"),
    1: i18n.t("Planned"),
    2: i18n.t("Ongoing"),
    3: i18n.t("Searching"),
    4: i18n.t("Completed")
  }

  return map[num] ?? ""
}

export function internalRegionsAndDepotsGrouping(regionArray, depotArray) {
  const sortedRegionArray = [...regionArray]
  sortedRegionArray?.sort((a, b) => (b?.regionName > a?.regionName ? -1 : 1))

  const sortedDepotArray = [...depotArray]
  sortedDepotArray?.sort((a, b) => (b?.name > a?.name ? -1 : 1))

  const array = []
  sortedRegionArray.forEach((region) => {
    array.push({
      ...region,
      depots: sortedDepotArray.filter(
        (depot) => depot?.regionId === region?.regionId
      )
    })
  })

  return sortedRegionArray ? array : null
}

export function arraysEqual(a, b) {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

export function addMinutesToDate(date, minutes) {
  date.setMinutes(date.getMinutes() + minutes)

  return date
}

export function getLastPartFromUrl(url) {
  const parts = url.split("/")
  return parts.at(-1)
}

export function disabledControlPhaseFilter(regionId, excludeIds = []) {
  if (regionId.includes(excludeIds)) {
    return true
  }

  return false
}

export const modalSheetBounceTransition = {
  y: {
    type: "tween",
    duration: 0.4
  }
}

export const searchOverlayBounceTransition = {
  y: {
    type: "tween",
    duration: 0.4
  }
}

export function priceWithCurrencyHelper(price) {
  if (typeof price !== "number") return price

  // Two decimals as default:
  let fractionDigits = 2

  // If value is a whole number, use no decimals:
  if (price % 1 === 0) {
    fractionDigits = 0
  }

  const formatedValue = Intl.NumberFormat("sv-SE", {
    style: "currency",
    currency: "SEK",
    maximumFractionDigits: fractionDigits
  }).format(price)

  return `${formatedValue}`
}

export function priceUnitHelper(priceUnit = null) {
  const prefix = "/"

  if (!priceUnit) {
    return `${prefix}st`
  }

  if (priceUnit === "-") {
    return `${prefix}st`
  }

  return `${prefix} ${priceUnit}`
}

export function priceDebitType(debitType = null) {
  const debitTypeDescription = debitType?.description ?? null

  if (!debitType) {
    return null
  }

  return debitTypeDescription
}
