import React from "react"
import { usePermissionCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"
import { RecoilRoot } from "recoil"
import List from "../../components/Machines/Incoming/List"
import Storage from "../../utils/storage"

const IncomingMachines = () => {
  const hasMachineTransferPermission = usePermissionCheck(
    "MinaSidor.MaskinflyttAdministrera"
  )
  const userState = Storage.get("userState") ? Storage.get("userState") : false

  if (
    !hasMachineTransferPermission ||
    !userState?.internal?.region?.settings?.usesTransport
  ) {
    return <PageNotFound noPermission={true} />
  }

  return (
    <RecoilRoot>
      <List />
    </RecoilRoot>
  )
}

export default IncomingMachines
