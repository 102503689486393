import React from "react"
import { useRecoilValue } from "recoil"
import { machineState, pageTypeState } from "../Store"
import { useTranslation } from "react-i18next"
import SummaryItem from "./SummaryItem"
import { groupMachinesByBranch } from "../../../utils/helpers"

const Confirmation = () => {
  const { t } = useTranslation()
  const pageType = useRecoilValue(pageTypeState)

  let machinesFromState = useRecoilValue(machineState)

  machinesFromState = machinesFromState.filter((machine) => {
    if (pageType === "incoming") {
      return machine.checkedAsIncoming
    }

    return machine
  })

  const machines = groupMachinesByBranch(machinesFromState)

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h3 className="text-center mb-5 d-flex flex-column">
            <span
              className="fe fe-info text-primary mb-3"
              style={{ fontSize: "50px" }}></span>
            <span>{t("Preview machines")}</span>
          </h3>
        </div>
        <div className="col-12">
          {machines &&
            machines.map((machine, index) => {
              return <SummaryItem key={index} machine={machine} />
            })}
        </div>
      </div>
    </div>
  )
}

export default Confirmation
