import React, { useState } from "react"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import Loader from "../Loader/Loader"
import toast from "react-hot-toast"
import Storage from "../../utils/storage"
import { dateAndTimeNow } from "../../utils/helpers"

const ModalConfirm = ({
  show,
  onHandleHide,
  data,
  modalTitle,
  heading,
  text,
  action
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const customer = Storage.get("customer", null, "local") ?? null

  const handleHide = (data = null, action = "") => {
    onHandleHide(data, action)
  }

  const handleClick = async () => {
    const endpoint = {
      delete: "customerRentalDelete",
      idRequirement: "updateCustomer",
      onlyCustomerCanCreate: "updateCustomer"
    }

    let params = {}

    if (action === "delete") {
      params = { id: data.id }
    } else {
      params = { body: data }
    }

    setLoading(true)

    await axios
      .post(`/api/hll/${endpoint[action]}`, {
        ...params
      })
      .then((response) => {
        if (response.status === 200) {
          handleHide(data, action)

          let eventData = {
            company: customer?.name,
            createdAt: dateAndTimeNow(),
            userType: "customer"
          }

          if (action === "idRequirement") {
            eventData.status = response?.data?.requiresIdForRentalCustomer
          } else if (action === "onlyCustomerCanCreate") {
            eventData.status =
              response?.data?.onlyCustomerCanCreateRentalContact
          }

          window.dataLayer.push({
            event: `HLL_contact_person_${action}`,
            eventData
          })
        }
      })
      .catch((error) => {
        console.error(error)
        toast.error(t("Something went wrong, try again."))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal show={show} onHide={handleHide} centered animation={false}>
      <Modal.Header className="justify-content-center">
        <h3 className="header-title mt-3 mb-0 text-center">{modalTitle}</h3>
      </Modal.Header>
      <Modal.Body className="text-center pt-0">
        <>
          {heading}
          <br />
          {text}
        </>
      </Modal.Body>
      <Modal.Footer>
        {loading && <Loader />}
        <button
          className="btn btn-white"
          onClick={handleHide}
          disabled={loading}>
          {t("Cancel")}
        </button>
        <button
          className="btn btn-primary"
          disabled={loading}
          type="button"
          onClick={handleClick}>
          {t("Confirm")}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirm
