import i18n from "../../../i18n"

export const formTemplate = {
  fields: [
    {
      name: "id",
      type: "hidden"
    },
    {
      name: "name",
      type: "text",
      title: i18n.t("Name of the project"),
      fieldClassName: "col-12 col-md-6 mb-3",
      required: {
        value: true,
        message: i18n.t("Required field")
      }
    },
    {
      name: "number",
      type: "text",
      title: i18n.t("Project number"),
      fieldClassName: "col-12 col-md-6",
      required: {
        value: true,
        message: i18n.t("Required field")
      }
    },
    {
      name: "adressLine1",
      type: "text",
      title: i18n.t("Address"),
      scope: "address",
      fieldClassName: "col-12 col-md-6 mb-3",
      required: {
        value: true,
        message: i18n.t("Required field")
      }
    },
    {
      name: "postalCode",
      type: "text",
      title: i18n.t("Zip code"),
      scope: "address",
      fieldClassName: "col-12 col-md-6",
      required: {
        value: true,
        message: i18n.t("Required field")
      }
    },
    {
      name: "city",
      type: "text",
      title: i18n.t("City"),
      scope: "address",
      fieldClassName: "col-12 col-md-6",
      required: {
        value: true,
        message: i18n.t("Required field")
      }
    },
    {
      name: "country",
      type: "text",
      title: i18n.t("Country"),
      scope: "address",
      fieldClassName: "col-12 col-md-6"
    }
  ]
}
