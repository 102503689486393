import React from "react"

import { motion, AnimatePresence } from "framer-motion"
export default function Overlay({ zIndex = 399 }) {
  return (
    <AnimatePresence>
      <motion.div
        key="overlay"
        className="modal-backdrop show"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.5 }}
        exit={{ opacity: 0 }}
        style={{ zIndex: zIndex }}
      />
    </AnimatePresence>
  )
}
