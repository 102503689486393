import React, { createContext, useContext, useReducer } from "react"

const SET_ORDER_DATA = "SET_ORDER_DATA"

const TransportOrderContext = createContext()

export const TransportOrderProvider = ({ children }) => {
  const defaultState = {
    orderData: []
  }

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case SET_ORDER_DATA: {
        const data = action.data

        return {
          ...state,
          orderData: data
        }
      }
      default:
        return state
    }
  }, defaultState)

  const setOrderData = (data) => dispatch({ type: SET_ORDER_DATA, data })

  return (
    <TransportOrderContext.Provider
      value={{
        orderData: state.orderData,
        setOrderData
      }}>
      {children}
    </TransportOrderContext.Provider>
  )
}

const useTransportOrderContext = () => useContext(TransportOrderContext)

export default useTransportOrderContext
