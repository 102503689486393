import React, { useState, useEffect } from "react"
import axios from "axios"
import SearchForm from "../../Common/SearchForm"
import SearchItem from "./SearchItem"
import Loader from "../../Loader/Loader"
import Alert from "../../Common/Alert"
import { useTranslation } from "react-i18next"
import { useRecoilState, useRecoilValue } from "recoil"
import { machineState, pageTypeState } from "../Store"
import { removeItemAtIndex } from "../../../utils/helpers"
import toast from "react-hot-toast"
import Storage from "../../../utils/storage"
import { Modal } from "react-bootstrap"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const Search = ({ handleModalHide, show, title, regionId }) => {
  const [machineTransfers, setMachineTransfers] = useRecoilState(machineState)
  const pageType = useRecoilValue(pageTypeState)
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState("")
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState("")
  const userState = Storage.get("userState") ?? null

  const handleSearch = (text) => {
    setSearchText(text)
  }

  const handleSearchBlur = (value) => {
    setSearchText(value)
  }

  const handleClick = async (item) => {
    const index = machineTransfers.findIndex(
      (machine) => machine.id === item.id
    )

    if (index < 0) {
      setMachineTransfers((oldMachineTransfers) => [
        ...oldMachineTransfers,
        item
      ])

      if (item?.internalType === "INDIVIDUAL") {
        await axios
          .post("/api/hll/fleetItemLock", {
            id: item.id
          })
          .then(() => {
            toast.success(`${t("Added")}: ${item.name}`)
          })
          .catch((err) => {
            toast.error(
              `Something went wrong. Correlation ID: ${err?.response?.data?.CorrelationId}`
            )
          })
      } else {
        toast.success(`${t("Added")}: ${item.name}`)
      }
    } else {
      const updatedMachineTransfers = removeItemAtIndex(machineTransfers, index)
      setMachineTransfers(updatedMachineTransfers)

      await axios
        .post("/api/hll/deleteFleetItemLock", {
          id: item.id
        })
        .then(() => {
          toast.success(`${t("Removed")}: ${item.name}`)
        })
        .catch((err) => {
          toast.error(
            `Something went wrong. Correlation ID: ${err?.response?.data?.CorrelationId}`
          )
        })
    }
  }

  useEffect(() => {
    let unmounted = false

    const request = async () => {
      const fleetItemQuery = axios.post("/api/hll/fleetItemQuery", {
        limit: 100,
        offset: 0,
        body: {
          fleetModelId: {
            logicalOperator: "Or",
            predicate: "StartsWith",
            value: searchText
          },
          number: {
            logicalOperator: "Or",
            predicate: "StartsWith",
            value: searchText
          },
          name: {
            logicalOperator: "Or",
            predicate: "Contains",
            value: searchText
          },
          branchId: {
            logicalOperator: "And",
            predicate: "Equal",
            value: userState?.internal?.depot?.id ?? 0
          },
          regionId: {
            logicalOperator: "And",
            predicate: "Equal",
            value: regionId
          },
          status: {
            logicalOperator: "And",
            predicate: "Equal",
            values: ["1"] // Only include available items 1
          },
          QueryContext: 1
        }
      })

      const articleQuery = axios.post("/api/hll/articleQuery", {
        body: {
          articleId: {
            logicalOperator: "Or",
            predicate: "StartsWith",
            value: searchText
          },
          articleNumber: {
            logicalOperator: "Or",
            predicate: "Contains",
            value: searchText
          },
          articleName: {
            logicalOperator: "Or",
            predicate: "Contains",
            value: searchText
          },
          regionId: {
            logicalOperator: "And",
            predicate: "Equal",
            value: regionId
          }
        }
      })

      await axios
        .all([fleetItemQuery, articleQuery])
        .then(
          axios.spread((...responses) => {
            if (!unmounted) {
              const fleetItems = responses[0].data.data ?? []
              const articles = responses[1].data.data ?? []

              if (fleetItems.length === 0 && articles.length === 0) {
                setError(t("No machines/articles found"))
              } else {
                const mapFleetItems = fleetItems.map((item) => {
                  return {
                    ...item,
                    internalType: "INDIVIDUAL"
                  }
                })

                const mapArticles = articles.map((item) => {
                  return {
                    ...item,
                    internalType: "ARTICLE",
                    branchId: userState?.internal?.depot?.id
                  }
                })

                const updatedResponse = mapFleetItems
                  .concat(mapArticles)
                  .map((machine) => {
                    if (pageType === "transfer") {
                      machine.newBranchId = ""
                      machine.newBranchName = ""
                      machine.quantity = 1
                    }

                    return machine
                  })

                setData(updatedResponse)
              }
            }
          })
        )
        .catch((err) => {
          setError(
            `Something went wrong. Correlation ID: ${err?.response?.data?.CorrelationId}`
          )
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (searchText !== "") {
      setData([])
      setError("")
      setLoading(true)
      request()
    }

    return () => {
      unmounted = true
    }
  }, [regionId, pageType, searchText, userState?.internal?.depot?.id, t])

  return (
    <Modal
      show={show}
      fullscreen="true"
      onHide={() => {
        setData([])
        setSearchText("")
        handleModalHide()
      }}
      backdrop="static"
      animation={false}
      className={`modal-scrollable modal-no-footer`}>
      <Modal.Header className="d-block p-3">
        <div className="position-relative">
          <h3 className="header-title mt-3 mb-0 text-center">
            {title && title}
          </h3>
          <button
            onClick={() => {
              setData([])
              setSearchText("")
              handleModalHide()
            }}
            className="bg-transparent position-absolute p-0 text-body"
            style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
            <CustomSvgIcon
              name="close"
              width={16}
              height={16}
              className="text-black"
            />
          </button>
        </div>
        <div className="mt-4">
          <SearchForm
            searchText={searchText}
            onSubmitHandler={handleSearch}
            onBlurHandler={handleSearchBlur}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="text-center p-0 bg-white is-scrollable mx-0">
        {loading && <Loader className="mt-4" />}

        {!loading && error && (
          <Alert className="mt-4 text-center" message={error} />
        )}

        {data.length > 0 &&
          data.map((item, index) => {
            return (
              <SearchItem
                key={index}
                index={index}
                item={item}
                handleClick={handleClick}
              />
            )
          })}
      </Modal.Body>
    </Modal>
  )
}

export default Search
