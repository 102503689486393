import React from "react"
import { usePermissionCheck, useAdminPermissionCheck } from "./hooks"
import { useTranslation } from "react-i18next"
import Storage from "./storage"
import { useCompanyCheck } from "./hooks"

// HLL INTERNAL ICONS
import { ReactComponent as InventoryIcon } from "../assets/icons/warehouse-storage.svg"
import { ReactComponent as ReturnIcon } from "../assets/icons/truck.svg"
import { ReactComponent as OrderIcon } from "../assets/icons/task-list-to-do.svg"
import { ReactComponent as MachineIcon } from "../assets/icons/flatter.svg"
import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg"
import { ReactComponent as MachineTransferIcon } from "../assets/icons/warehouse-delivery.svg"
import { ReactComponent as IncomingMachines } from "../assets/icons/shipment-in-transit.svg"

// HLL CUSTOMER ICONS
import { ReactComponent as ProjectsIcon } from "../assets/icons/work-places.svg"
import { ReactComponent as DepotsIcon } from "../assets/icons/depo.svg"
import { ReactComponent as PermissionToRentIcon } from "../assets/icons/unlock.svg"
import { ReactComponent as UserAdminIcon } from "../assets/icons/users-lock.svg"

//HLL ADMIN ICONS
import { ReactComponent as CogIcon } from "../assets/icons/cog.svg"

export function InternalNavigationSchema(
  type = "default",
  clickHandler = null
) {
  const { t } = useTranslation()
  const userState = Storage.get("userState", null, "local") ?? null
  const usesTransport = userState?.internal?.region?.settings?.usesTransport
  const hasHLLInternPermission = usePermissionCheck("MinaSidor.SkapaRetur")
  const hasMachineAdminPermission = usePermissionCheck(
    "MinaSidor.ProduktExemplarAdministrera"
  )
  const hasMachineTransferPermission = usePermissionCheck(
    "MinaSidor.MaskinflyttAdministrera"
  )
  const hasCreateOrderPermissons = usePermissionCheck("MinaSidor.SkapaOrder")

  const hasInventoryPermission = usePermissionCheck("MinaSidor.Inventering")

  let map = []

  if (hasHLLInternPermission) {
    map = [
      {
        icon: <DashboardIcon />,
        text: t("Dashboard"),
        link: `/${t("dashboard")}`,
        visible: type === "shortcuts" ? false : true,
        clickHandler: clickHandler
      },
      {
        icon: <MachineIcon />,
        text: t("Machines"),
        link: `/${t("machines")}`,
        visible: hasMachineAdminPermission,
        clickHandler: clickHandler
      },
      {
        icon: <MachineTransferIcon />,
        text: t("Machine transfer"),
        link: `/${t("machine-transfer")}`,
        visible: hasMachineTransferPermission,
        clickHandler: clickHandler
      },
      {
        icon: <IncomingMachines />,
        text: t("Incoming machines"),
        link: `/${t("incoming-machines")}`,
        visible: usesTransport && hasMachineTransferPermission,
        clickHandler: clickHandler
      },
      {
        icon: <ReturnIcon />,
        text: t("Returns"),
        link: `/${t("returns")}`,
        visible: hasHLLInternPermission,
        clickHandler: clickHandler
      },
      {
        icon: <OrderIcon />,
        text: t("Order"),
        link: `/${t("order")}`,
        visible: hasCreateOrderPermissons,
        clickHandler: clickHandler
      },
      {
        icon: <InventoryIcon />,
        text: t("Inventory"),
        link: `/${t("inventory")}`,
        visible: hasInventoryPermission,
        clickHandler: clickHandler
      }
    ]
  }

  return map
}

export function CustomerNavigationSchema(
  type = "default",
  clickHandler = null
) {
  const { t } = useTranslation()
  const hasHLLInternPermission = usePermissionCheck("MinaSidor.SkapaRetur")
  const hasCustomerSitePermission = usePermissionCheck(
    "MinaSidor.KundsPlatsLäs"
  )

  let map = []

  if (!hasHLLInternPermission) {
    map = [
      {
        icon: <DashboardIcon />,
        text: t("Dashboard"),
        link: `/${t("dashboard")}`,
        visible: type === "shortcuts" ? false : true,
        clickHandler: clickHandler
      },
      {
        icon: <ProjectsIcon />,
        text: t("Projects"),
        link: `/${t("projects")}`,
        visible:
          type === "shortcuts" || !hasCustomerSitePermission ? false : true,
        clickHandler: clickHandler
      },
      {
        icon: <MachineIcon />,
        text: t("Machines"),
        link: `/${t("customer")}/${t("machines")}`,
        visible: type === "shortcuts" ? false : true,
        clickHandler: clickHandler
      },
      {
        icon: <DepotsIcon />,
        text: t("Depots"),
        link: `/${t("depots")}`,
        visible: type === "shortcuts" ? false : true,
        clickHandler: clickHandler
      }
    ]
  }

  return map
}

export function CompanyPermissionNavigationSchema(
  type = "default",
  clickHandler = null
) {
  const { t } = useTranslation()
  const companyPermissions = useCompanyCheck()
  const hasHLLInternPermission = usePermissionCheck("MinaSidor.SkapaRetur")
  const hasUserPermission = usePermissionCheck("Säkerhet.AnvändareGruppAdmin")
  const hasRentalPermission = usePermissionCheck(
    "MinaSidor.BehorigatthyraAdmin"
  )

  let map = []

  if (!hasHLLInternPermission) {
    map = [
      {
        icon: <PermissionToRentIcon />,
        text: t("Rental Permissions"),
        link: `/${t("rental-permissions")}`,
        visible:
          type === "shortcuts" || !companyPermissions || !hasRentalPermission
            ? false
            : true,
        clickHandler: clickHandler
      },
      {
        icon: <UserAdminIcon />,
        text: t("Users"),
        link: `/${t("users")}`,
        visible:
          type === "shortcuts" || !companyPermissions || !hasUserPermission
            ? false
            : true,
        clickHandler: clickHandler
      }
    ]
  }

  return map
}

export function AdminNavigationSchema() {
  const { t } = useTranslation()
  const hasAdminPermission = useAdminPermissionCheck()
  let map = []

  if (!hasAdminPermission) {
    return []
  }

  map = [
    {
      icon: <CogIcon />,
      text: t("Admin"),
      link: `/${t("admin")}`,
      visible: true
    }
  ]

  return map
}
