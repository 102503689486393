import React from "react"
import { useTranslation } from "react-i18next"
import { useOidcUser } from "@axa-fr/react-oidc"
import Storage from "../../utils/storage"

import LatestProjectCard from "../../components/LatestProjectCard/LatestProjectCard"
import DepotCard from "../../components/DepotCard/DepotCard"
import ProjectCard from "../../components/ProjectCard/ProjectCard"
import RemindersCard from "../../components/RemindersCard/RemindersCard"
import Shortcuts from "../../components/Shortcuts/Shortcuts"
import MachineCategoryGrid from "../../components/__Customer/Machines/CategoryGrid/CategoryGrid"

import { usePermissionCheck } from "../../utils/hooks"
const Dashboard = () => {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()
  const customer = Storage.get("customer", null, "local") ?? null
  const userState = Storage.get("userState", null, "local") ?? null

  const hasHLLInternPermission = usePermissionCheck("MinaSidor.SkapaRetur")
  const hasCustomerSitePermission = usePermissionCheck(
    "MinaSidor.KundsPlatsLäs"
  )

  const hasDepotPermission = usePermissionCheck("MinaSidor.DepåLäs")

  // Favorite projects
  const projectsFavorites = userState?.projects?.favorites
    ? userState?.projects?.favorites.filter(
        (f) => f.customerId === customer?.id
      )
    : []

  // Reminders
  const reminders = userState?.reminders
    ? userState?.reminders.filter((f) => f.customerId === customer?.id)
    : []

  let columnClass = "col-lg-6"
  const widgets = ["LatestProjectCard", "DepotCard"]

  if (projectsFavorites && projectsFavorites.length > 0) {
    widgets.push("FavoriteProjects")
  }

  if (reminders && reminders.length > 0) {
    widgets.push("Reminders")
  }

  // if (widgets.length % 2 === 0) {
  //   columnClass = "col-lg-6"
  // }

  // If HLL Internal employee
  if (hasHLLInternPermission) {
    return (
      <div className="container-fluid container-fluid-max-width">
        <div className="row mb-4">
          <div className="col-12">
            <div className="header">
              <div className="header-body pt-0">
                <h6 className="header-pretitle">{t("My pages")}</h6>
                {oidcUser?.name && (
                  <h1 className="header-title">
                    {`${t("Hi")} ${oidcUser?.name}!`}
                  </h1>
                )}
              </div>
            </div>
          </div>
        </div>
        <Shortcuts />
      </div>
    )
  }

  return (
    <div className="container-fluid container-fluid-max-width">
      <div className="row my-4">
        <div className="col-12">
          <div className="header">
            <div className="header-body pt-0">
              <h6 className="header-pretitle">{t("My pages")}</h6>

              {oidcUser?.name && (
                <h1 className="header-title">
                  {`${t("Hi")} ${oidcUser?.name}!`}
                </h1>
              )}
            </div>
          </div>
        </div>
      </div>

      <MachineCategoryGrid />

      {customer && (
        <div className="row g-4">
          {hasCustomerSitePermission && (
            <div className={`col-12 ${columnClass}`}>
              <LatestProjectCard />
            </div>
          )}

          {hasDepotPermission && (
            <div className={`col-12 ${columnClass}`}>
              <DepotCard />
            </div>
          )}

          {projectsFavorites && projectsFavorites.length > 0 && (
            <div className={`col-12 ${columnClass}`}>
              <ProjectCard
                data={projectsFavorites}
                header={t("Favorite projects")}
                useLink={true}
                linkText={t("View my favorite projects")}
                favorite={true}
              />
            </div>
          )}

          {reminders && reminders.length > 0 && (
            <div className={`col-12 ${columnClass}`}>
              <RemindersCard
                header={t("My planned end dates")}
                data={reminders}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Dashboard
