import React, { useEffect, useCallback, useState } from "react"
import {
  inventoryView,
  inventoryAdministrationEdit
} from "../../../components/Inventory/Store"
import axios from "axios"
import { Alert } from "react-bootstrap"
import { useSetRecoilState } from "recoil"
import { useTranslation } from "react-i18next"
import EditForm from "../../../components/Inventory/Common/Administration/Edit/Form"
import { useLocation } from "react-router-dom"
import Loader from "../../../components/Loader/Loader"
import Toaster from "../../../components/Common/Toast"
import { getLastPartFromUrl } from "../../../utils/helpers"
import PageNotFound from "../../PageNotFound/PageNotFound"

import { usePermissionCheck } from "../../../utils/hooks"

export default function Edit() {
  const { t } = useTranslation()
  const hasInventoryAdminPermission = usePermissionCheck(
    "MinaSidor.inventeringAdministrera"
  )

  const location = useLocation()
  const { state, pathname } = location
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const setViewState = useSetRecoilState(inventoryView)
  const setInventoryAdministrationEditState = useSetRecoilState(
    inventoryAdministrationEdit
  )

  // Request current inventory save it to recoil state
  const requestInventory = useCallback(async () => {
    setError(false)
    setLoading(true)
    await axios
      .post("/api/hll/inventoryOccasionId", {
        inventoryOccasionId:
          state?.inventoryOccasionId ?? getLastPartFromUrl(pathname)
      })
      .then((response) => {
        setInventoryAdministrationEditState(
          (previousAdministrationEditState) => {
            return {
              ...previousAdministrationEditState,
              pageHeader: t("Inventory"),
              inventory: response?.data
            }
          }
        )
      })
      .catch((err) => {
        console.log("error", err)
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pathname,
    setInventoryAdministrationEditState,
    state?.inventoryOccasionId,
    t
  ])

  // Set view state to newInventory
  useEffect(() => {
    setViewState("inventoryAdministrationEdit")
    if (hasInventoryAdminPermission) {
      requestInventory()
    }
  }, [requestInventory, setViewState, hasInventoryAdminPermission])

  if (!hasInventoryAdminPermission) {
    return <PageNotFound />
  }

  return (
    <>
      <Toaster />
      {error && (
        <Alert variant="danger" className="text-white rounded-0">{`${t(
          "Something went wrong, please refresh current page."
        )}.`}</Alert>
      )}
      {loading && <Loader className="mt-4 mx-auto text-center" />}
      {!loading && !error && <EditForm />}
    </>
  )
}
