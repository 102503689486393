import React, { useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import Storage from "../../utils/storage"
import { useLocation } from "react-router-dom"

import TableSkeletonLoader from "../../components/Common/TableSkeletonLoader"
import Alert from "../../components/Common/Alert"
import axios from "axios"
import SkeletonLoader from "../../components/Common/SkeletonLoader"

import Item from "./Item"
import Pagination from "../Pagination/Pagination"

import { useMediaQuery } from "react-responsive"
import { setUserSettings } from "../../utils/hooks"

import { useOnClickOutside } from "../../utils/hooks"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const ProjectList = () => {
  const outsideClickHandlerRef = useRef()
  const { t } = useTranslation()

  const location = useLocation()

  const customer = Storage.get("customer", null, "local") ?? null
  const userState = Storage.get("userState") ?? null

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [InitialOrderState, setOrderState] = useState(false)
  const [preSortedData, setPreSortedData] = useState([])
  const [searchText, setSearchText] = useState("")
  const [posts, setPosts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const [showDropdown, setDropdownState] = useState(false)

  const indexOfLast = currentPage * perPage
  const indexOfFirst = indexOfLast - perPage
  const currentPosts = posts.slice(indexOfFirst, indexOfLast)
  const [activeType, setActiveType] = useState("ongoing")

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(outsideClickHandlerRef, () => setDropdownState(false))

  const [favoriteProjects, setFavoriteProjects] = useState(
    userState?.projects?.favorites ?? []
  )
  const [updatingUserState, setUpdatingUserState] = useState(false)

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 991px)"
  })

  const activeProjects = preSortedData
    ? preSortedData
        .filter(
          (project) =>
            project.activeStatus === "ActiveNotOngoing" &&
            project.number !== customer?.corporateIdentityNumber // Filtering default site from customer
        )
        .sort((a, b) => (a.startTime < b.startTime ? 1 : -1))
    : []

  const ongoingProjects = preSortedData
    ? preSortedData
        .filter(
          (project) =>
            project.activeStatus === "Ongoing" &&
            project.number !== customer?.corporateIdentityNumber
        )
        .sort((a, b) => (a.startTime < b.startTime ? 1 : -1))
    : []

  // Search on change
  const handleSearchChange = (e) => {
    e.preventDefault()
    const value = e.target.value
    setSearchText(value)
    setCurrentPage(1)
    filterData(value)
  }

  // Filter data based on search text
  const excludeColumns = ["id"] // exclude column list from filter
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim()
    if (lowercasedValue === "") {
      setPosts(preSortedData)
    } else {
      const filteredData = posts.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        )
      })
      setPosts(filteredData)
    }
  }

  // Pagination + Prev and next links
  const paginate = (currentPage, pageNumber) => setCurrentPage(pageNumber)
  const nextPage = () => setCurrentPage(currentPage + 1)
  const previousPage = () => setCurrentPage(currentPage - 1)

  // Toggle Posts Per Page menu
  const toggleperPageMenu = (e) => {
    setOrderState(true)
    e.preventDefault()
    setDropdownState((value) => !value)
  }

  // Update Posts Per Page
  const updateperPage = (e) => {
    e.preventDefault()
    setPerPage(e.target.value)
    setDropdownState(false)
  }

  // Sort data by key
  const sortByKey = (e) => {
    const key = e.target.dataset["sort"]
    let newData = posts.slice()

    if (InitialOrderState) {
      newData.sort((a, b) => (b[key] > a[key] ? 1 : -1))
    } else {
      newData.sort((a, b) => (a[key] > b[key] ? 1 : -1))
    }
    setPosts(newData)
  }

  // Toggle Sorting
  const toggleSorting = (e) => {
    e.preventDefault()
    sortByKey(e)
    setOrderState((InitialOrderState) => !InitialOrderState)
  }

  const navTypeHandler = (e) => {
    e.preventDefault()
    let name = e.target.dataset["name"]

    if (e.target.tagName === "SPAN") {
      name = e.target.parentNode.dataset["name"]
    }

    setActiveType(name)

    if (name === "active") {
      setPosts(
        preSortedData
          .filter(
            (project) =>
              project.activeStatus === "ActiveNotOngoing" &&
              project.number !== customer?.corporateIdentityNumber
          )
          .sort((a, b) => (a.startTime < b.startTime ? 1 : -1))
      )
    } else if (name === "ongoing") {
      setPosts(
        preSortedData
          .filter(
            (project) =>
              project.activeStatus === "Ongoing" &&
              project.number !== customer?.corporateIdentityNumber
          )
          .sort((a, b) => (a.startTime < b.startTime ? 1 : -1))
      )
    } else if (name === "favorites") {
      setPosts(
        favoriteProjects.sort((a, b) => (a.startTime < b.startTime ? 1 : -1))
      )
    } else {
      setPosts(
        preSortedData
          .filter(
            (project) =>
              project.activeStatus !== "Active" &&
              project.activeStatus !== "Ongoing" &&
              project.number !== customer?.corporateIdentityNumber
          )
          .sort((a, b) => (a.startTime < b.startTime ? 1 : -1))
      )
    }
  }

  useEffect(() => {
    let unmounted = false
    setLoading(true)

    const limit = 2000
    const offset = 0
    const body = {
      customerId: {
        logicalOperator: "And",
        predicate: "Equal",
        value: customer ? customer?.id : null
      },
      activeStatus: {
        logicalOperator: "And",
        predicate: "Equal",
        values: ["Ongoing", "ActiveNotOngoing", "AllActive"] //AllActive or AllActiveNotOngoing
      }
    }

    const requestSites = async () => {
      await axios
        .post("/api/hll/siteQuery", {
          body,
          limit,
          offset
        })
        .then((response) => {
          if (!unmounted) {
            setPreSortedData(
              response?.data?.data
                .filter(
                  (project) =>
                    project.number !== customer?.corporateIdentityNumber
                )
                .sort((a, b) => (a.startTime < b.startTime ? 1 : -1))
            )

            if (location?.state?.favorites) {
              setActiveType("favorites")
              setPosts(
                favoriteProjects.sort((a, b) =>
                  a.startTime < b.startTime ? 1 : -1
                )
              )
            } else {
              setPosts(
                response?.data?.data
                  .filter(
                    (project) =>
                      project.number !== customer?.corporateIdentityNumber &&
                      project.activeStatus === "Ongoing"
                  )
                  .sort((a, b) => (a.startTime < b.startTime ? 1 : -1))
              )
            }
          }
        })
        .catch((err) => {
          console.warn(err, "Error: /api/hll/siteQuery")
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    requestSites()

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (updatingUserState) {
      setUserSettings({
        ...userState,
        projects: {
          favorites: favoriteProjects
        }
      })
      Storage.set(
        "userState",
        {
          ...userState,
          projects: {
            favorites: favoriteProjects
          }
        },
        "local"
      )
      setUpdatingUserState(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteProjects, userState, updatingUserState])

  const totalPages = []

  for (let i = 1; i <= Math.ceil(posts.length / perPage); i++) {
    totalPages.push(i)
  }

  const allAmount =
    preSortedData.length - ongoingProjects.length - activeProjects.length

  return (
    <>
      <div className="header mb-3 mb-md-4">
        {loading && <SkeletonLoader />}

        {!loading && !error && (
          <div className="header-body pt-0">
            <div className="row align-items-center">
              <div className="col">
                <ul className="nav nav-tabs nav-overflow header-tabs">
                  <li className="nav-item">
                    <button
                      id="ongoing-tab"
                      className={`d-flex nav-link text-nowrap align-items-center${
                        activeType === "ongoing" ? " active" : ""
                      }${loading ? " is-disabled" : ""}`}
                      data-name="ongoing"
                      disabled={loading}
                      onClick={navTypeHandler}
                      style={{ backgroundColor: "transparent" }}>
                      {t("Ongoing")}
                      <span className="ml-2 badge bg-light disabled">
                        {ongoingProjects && ongoingProjects.length}
                      </span>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      id="active-tab"
                      className={`d-flex nav-link text-nowrap align-items-center${
                        activeType === "active" ? " active" : ""
                      }${loading ? " is-disabled" : ""}`}
                      data-name="active"
                      disabled={loading}
                      onClick={navTypeHandler}
                      style={{ backgroundColor: "transparent" }}>
                      {t("Active")}
                      <span className="ml-2 badge bg-light disabled">
                        {activeProjects && activeProjects.length}
                      </span>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      id="all-tab"
                      className={`d-flex nav-link text-nowrap align-items-center${
                        activeType === "all" ? " active" : ""
                      }${loading ? " is-disabled" : ""}`}
                      data-name="all"
                      disabled={loading}
                      onClick={navTypeHandler}
                      style={{ backgroundColor: "transparent" }}>
                      {t("All")}
                      <span className="ml-2 badge bg-light disabled">
                        {allAmount}
                      </span>
                    </button>
                  </li>
                  {favoriteProjects && favoriteProjects?.length > 0 && (
                    <li className="nav-item">
                      <button
                        id="favorite-tab"
                        className={`d-flex nav-link text-nowrap align-items-center${
                          activeType === "favorites" ? " active" : ""
                        }${loading ? " is-disabled" : ""}`}
                        data-name="favorites"
                        disabled={loading}
                        onClick={navTypeHandler}
                        style={{ backgroundColor: "transparent" }}>
                        {t("Favorites")}
                        <span className="ml-2 badge bg-light disabled">
                          {favoriteProjects?.length}
                        </span>
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      {error && (
        <Alert
          message={t("Something went wrong, please refresh current page.")}
        />
      )}

      <div className="data-list with-filters mt-0 mt-md-4 mb-3">
        <div className="card">
          <div className={`card-header${loading ? " is-disabled" : ""}`}>
            <div className="row align-items-center">
              <div className="col">
                <form>
                  <div className="input-group input-group-flush input-group-merge input-group-reverse">
                    <input
                      className={`form-control list-search`}
                      type="search"
                      placeholder={t("Search")}
                      value={searchText}
                      disabled={loading}
                      onChange={handleSearchChange}
                      required
                    />
                    <span className="input-group-text">
                      <CustomSvgIcon
                        name="search"
                        width={20}
                        height={20}
                        className={`text-muted`}
                      />
                    </span>
                  </div>
                </form>
              </div>

              <div className="col-auto mr-n3" ref={outsideClickHandlerRef}>
                {posts.length > 10 && (
                  <div className="dropdown">
                    <a
                      href="#!"
                      className="d-flex btn btn-sm dropdown-toggle"
                      role="button"
                      id="perPageMenu"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded={showDropdown}
                      onClick={toggleperPageMenu}
                      disabled>
                      {`${perPage} ${t("per page")}`}
                    </a>
                    <div
                      className={`dropdown-menu dropdown-menu-right${
                        showDropdown ? " show" : ""
                      }`}
                      aria-labelledby="perPageMenu">
                      <button
                        className="dropdown-item"
                        value={10}
                        onClick={updateperPage}>
                        10
                      </button>

                      {posts.length >= 20 && (
                        <button
                          className="dropdown-item"
                          value={20}
                          onClick={updateperPage}>
                          20
                        </button>
                      )}

                      {posts.length >= 30 && (
                        <button
                          className="dropdown-item"
                          value={30}
                          onClick={updateperPage}>
                          30
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-sm table-hover table-nowrap card-table">
              <thead>
                <tr>
                  {!isDesktopOrLaptop && <th className="col-1"></th>}
                  <th>
                    <a
                      className="list-sort-up text-muted text-gray-700"
                      data-sort="name"
                      href="#!"
                      onClick={toggleSorting}>
                      {t("Name")}
                    </a>
                  </th>
                  <th>
                    <a
                      className="list-sort text-muted text-gray-700"
                      data-sort="createdAt"
                      href="#!"
                      onClick={toggleSorting}>
                      {t("Created at")}
                    </a>
                  </th>
                  {isDesktopOrLaptop && <th className="col-1"></th>}
                </tr>
              </thead>

              {loading && (
                <tbody>
                  <TableSkeletonLoader rows={perPage} cells={3} />
                </tbody>
              )}

              {!loading && (
                <tbody className="list font-size-base">
                  <Item
                    data={currentPosts}
                    favoriteProjects={favoriteProjects}
                    setFavoriteProjects={setFavoriteProjects}
                    setUpdatingUserState={setUpdatingUserState}
                  />
                </tbody>
              )}
            </table>

            {!loading && currentPosts.length === 0 && (
              <div className="py-4 text-center">{t("Can't find anything")}</div>
            )}
          </div>

          {totalPages.length > 1 && (
            <div className="card-footer d-flex justify-content-between">
              <Pagination
                perPage={perPage}
                total={posts.length}
                paginate={paginate}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ProjectList
