const oidcConfigs = {
  authority: process.env.REACT_APP_AUTH_URL,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_PUBLIC_URL + "/authentication/callback",
  response_type: "code",
  //post_logout_redirect_uri: process.env.REACT_APP_PUBLIC_URL,
  scope:
    "openid profile roles perms offline_access mina_sidor_api idp_admin_api",
  silent_redirect_uri:
    process.env.REACT_APP_PUBLIC_URL + "/authentication/silent_callback",
  silent_login_uri:
    process.env.REACT_APP_PUBLIC_URL + "/authentication/silent_callback",

  automaticSilentRenew: true,
  loadUserInfo: true,
  extras: {
    issuer: process.env.REACT_APP_AUTH_URL,
    jwks_uri:
      process.env.REACT_APP_AUTH_URL + "/.well-known/openid-configuration/jwks",
    end_session_endpoint:
      process.env.REACT_APP_AUTH_URL + "/connect/endsession",
    authorization_endpoint:
      process.env.REACT_APP_AUTH_URL + "/connect/authorize",
    token_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/token",
    userinfo_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/userinfo"
    // check_session_iframe: process.env.REACT_APP_AUTH_URL + "/connect/checksession",
    // introspection_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/introspect",
  }
}

export default oidcConfigs
