import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import CustomSvgIcon from "../../../../components/Common/CustomSvgIcon"
import ImageComingSoon from "../../../../assets/images/image-coming-soon.png"

export default function MachineCategoryItem({
  id,
  name,
  slug,
  acf: { image_url: imageUrl },
  isVisible
}) {
  const { t } = useTranslation()

  return (
    <article
      data-id={`machine-category-${id}`}
      className={`machine-category-grid__item rounded${
        isVisible ? " d-block" : " d-none"
      }`}>
      <header className="position-absolute d-flex flex-column justify-content-between p-4">
        <h2 className="hyphenate text-white">
          <Link
            className="is-clickable text-white"
            to={{
              pathname: slug
            }}>
            {name}
          </Link>
        </h2>
        <div className="m-0 p-0 text-right text-white">
          <div className="d-flex align-items-center justify-content-end">
            <span className="mr-2">{t("View machines")}</span>
            <div className="svg-icon-wrapper text-white ml-3">
              <CustomSvgIcon name="arrow-right" width={20} height={20} />
            </div>
          </div>
        </div>
      </header>
      {imageUrl && (
        <figure className="m-0 p-0 h-100">
          <img
            src={imageUrl}
            alt={name}
            className="img-fluid rounded text-center mx-auto"
          />
        </figure>
      )}
      {!imageUrl && (
        <figure className="m-0 p-0 h-100">
          <img
            src={ImageComingSoon}
            alt={t("Image coming soon")}
            className="logo"
          />
        </figure>
      )}
    </article>
  )
}
