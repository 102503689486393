import i18n from "../../../i18n"

export const formTemplate = {
  fields: [
    {
      name: "name",
      type: "text",
      title: i18n.t("Name of the project"),
      fieldClassName: "col-12 col-md-6 mb-3",
      instructions: i18n.t("Max 40 characters"),
      maxLength: 40
    },
    {
      name: "number",
      type: "text",
      title: i18n.t("Project number"),
      fieldClassName: "col-12 col-md-6 mb-3 mb-lg-0",
      instructions: i18n.t("Max 20 characters"),
      maxLength: 20
    },
    {
      name: "adress",
      type: "text",
      title: `${i18n.t("Address")}`,
      scope: "googleMaps",
      fieldClassName: "col-12",
      required: {
        value: true,
        message: i18n.t("Required field")
      }
    }
  ]
}
