import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import CustomSvgIcon from "../../../Common/CustomSvgIcon"
import Overlay from "./Overlay"
import MenuItem from "./MenuItem"

import { useOnClickOutside } from "../../../../utils/hooks"

export default function MobileNavigation({
  showMobileNavigation,
  setShowMobileNavigation,
  categories,
  currentPath
}) {
  const { t } = useTranslation()
  const menuRef = useRef(null)
  const location = useLocation()
  const { pathname } = location

  useOnClickOutside(menuRef, () => setShowMobileNavigation(false))

  const handleShowNavigation = () => {
    setShowMobileNavigation(!showMobileNavigation)
  }

  useEffect(() => {
    setShowMobileNavigation(false)
  }, [pathname, setShowMobileNavigation])

  return (
    <>
      {showMobileNavigation && <Overlay />}
      <div className="d-block mb-3">
        <button
          className="btn btn-transparent text-left"
          onClick={handleShowNavigation}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="svg-icon-wrapper text-black mr-3">
              <CustomSvgIcon name="menu" width={20} height={20} />
            </div>
            <span className="ml-1">{t("Categories")}</span>
          </div>
        </button>
      </div>
      <div ref={menuRef}>
        <nav
          className={`nav-off-canvas machine-filter bg-white h-100 text-body position-fixed js-app-off-canvas${
            showMobileNavigation ? " is-open" : ""
          }`}>
          <div className="nav-off-canvas-inside position-relative">
            <div className="nav-off-canvas-header d-flex justify-content-center align-items-center">
              <div>
                <h3 className="mb-0">{t("Categories")}</h3>
              </div>
              <button
                onClick={handleShowNavigation}
                className="border-0 bg-white d-block close-nav-button text-body position-absolute"
                style={{ right: 0, marginRight: "7px" }}>
                <CustomSvgIcon
                  name="close"
                  width={16}
                  height={16}
                  className="text-black"
                />
              </button>
            </div>
            <div className="nav-off-canvas-body position-relative p-3">
              <div className="nav-off-canvas-nav-list">
                {categories && categories?.length > 0 && (
                  <div className="machine-category-menu">
                    {categories?.map((item) => {
                      const isVisible = item.parent === 0
                      if (!isVisible) return null
                      return (
                        <MenuItem
                          key={item.id}
                          {...item}
                          currentPath={currentPath}
                          categories={categories}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}
