import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import SkeletonLoader from "../../../Common/SkeletonLoader"
import ImageComingSoon from "../../../../assets/images/image-coming-soon.png"

export default function Card({
  name,
  slug,
  acf: { image_url: imageUrl },
  isLoading
}) {
  const { t } = useTranslation()

  return (
    <li
      className={`category-grid-card rounded${isLoading ? " is-loading" : ""}`}>
      {isLoading && <SkeletonLoader rounded={false} />}

      {!isLoading && (
        <>
          <h3 className="category-grid-card-title hyphenate text-white">
            <Link
              className="is-clickable text-white"
              to={{
                pathname: `/${t("customer")}/${t("machines")}/${slug}`
              }}>
              {name}
            </Link>
          </h3>
          <figure className="category-grid-card-media-frame">
            {imageUrl ? (
              <img src={imageUrl} alt={name ?? ""} />
            ) : (
              <img
                src={ImageComingSoon}
                alt={t("Image coming soon")}
                className="logo"
              />
            )}
          </figure>
        </>
      )}
    </li>
  )
}
