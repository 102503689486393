import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"

export default function MachineCategoryMenuItem({
  id,
  name,
  slug,
  isVisible,
  currentCategoryId
}) {
  const { t } = useTranslation()
  const location = useLocation()
  let { pathname } = location

  const isActiveParent = currentCategoryId === id || pathname.includes(slug)

  return (
    <>
      <div
        className={`machine-category-menu__item mb-3${
          isVisible ? " d-block" : " d-none"
        }${isActiveParent ? " parent pb-3 text-primary" : ""}`}>
        <Link
          to={{
            pathname: `/${t("customer")}/${t("machines")}/${slug}`
          }}>
          {name}
        </Link>
      </div>
    </>
  )
}
