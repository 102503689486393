import React from "react"
import { InternalNavigationSchema } from "../../utils/navigation"
import Item from "./Item"

export default function Shortcuts() {
  const schema = InternalNavigationSchema("shortcuts", null)

  if (!schema && schema.length === 0) {
    return null
  }

  return (
    <div className="row g-2 g-md-4 g-lg-4">
      {schema.map((item, index) => {
        if (!item.visible) {
          return null
        }

        return (
          <div className="col-4 col-md-3 col-lg-2" key={index}>
            <Item
              icon={item.icon}
              text={item.text}
              link={item.link}
              visible={item.visible}
            />
          </div>
        )
      })}
    </div>
  )
}
