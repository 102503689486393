import React from "react"
import { useRecoilValue } from "recoil"
import { inventoryView } from "./Store"

export default function InventoryHeaderTitle({ title, subTitle }) {
  const viewState = useRecoilValue(inventoryView)

  return (
    <div
      className="header-main-title text-center position-absolute"
      style={{
        maxWidth: "50%"
      }}>
      <strong className="d-block text-truncate">{title && title}</strong>
      {viewState !== "findInventory" && (
        <span className="d-block text-truncate">{subTitle && subTitle}</span>
      )}
    </div>
  )
}
