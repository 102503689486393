import React from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useOidcUser } from "@axa-fr/react-oidc"
import Logo from "../../assets/images/HLL_notext.svg"

const PageNotFound = ({
  noPermission = false,
  goBack = true,
  goBackPath = "/",
  goBackText = false
}) => {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()

  return (
    <div className="login mt-3">
      <div className="d-flex justify-content-center w-100">
        <div className="mx-auto w-100 p-4 m-0 text-center">
          <img src={Logo} alt={t("My pages")} className="logo mb-3" />

          {noPermission ? (
            <>
              <h1 className="text-center">{t("No access")}</h1>
              <p className="text-center">
                {t("You don't have the correct permissions for this page")}
              </p>
            </>
          ) : (
            <h1 className="text-center">{t("404 - Page not found")}</h1>
          )}
          {goBack && (
            <div className="form-text mt-3 text-center">
              <Link to={goBackPath}>
                {oidcUser
                  ? goBackText
                    ? goBackText
                    : t("Back to home")
                  : t("Back to login")}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
