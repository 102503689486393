import { useState } from "react"
import { useQuery } from "@tanstack/react-query"

import { getMachineCategories } from "../../../../utils/hooks"

export const useMachineCategories = () => {
  const INIT_CATEGORY_PAGE = 1
  const INIT_CATEGORY_PER_PAGE = 100
  const [page] = useState(INIT_CATEGORY_PAGE)
  const [categories, setCategories] = useState([])

  const params = {
    _fields: "id,name,slug,count,acf.image_url,parent",
    hide_empty: true,
    page: page,
    per_page: INIT_CATEGORY_PER_PAGE,
    hierarchical: true
  }

  // Fetch machine categories
  const { isLoading } = useQuery({
    queryKey: ["machineCategories"],
    queryFn: async () => {
      const response = await getMachineCategories(params)
      const categories = response?.categories ?? []

      setCategories(categories)

      if (response?.meta?.totalPages > page) {
        const nextPage = page + 1
        const nextParams = { ...params, page: nextPage }
        const nextResponse = await getMachineCategories(nextParams)
        setCategories([...categories, ...nextResponse?.categories])
        return nextResponse
      } else {
        return response
      }
    },
    refetchOnWindowFocus: false,
    cacheTime: 60000 * 60 * 12 // 12 hours
  })

  return {
    categories,
    isLoading
  }
}
