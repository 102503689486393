import React from "react"
import { useTranslation } from "react-i18next"
import { pageTypeState } from "../Store"
import { useRecoilValue } from "recoil"
import { availabilityColorMap } from "../../../utils/helpers"

const SummaryItem = ({ machine, transferNoteNr = "" }) => {
  const { t } = useTranslation()
  const pageType = useRecoilValue(pageTypeState)

  let groupHeading = pageType === "transfer" ? t("Transfer to") : t("From")
  groupHeading += ` ${machine.newBranchName}`

  return (
    <div className="machnine-transfer-item-wrap mb-2">
      <div className="d-flex justify-content-between align-items-center font-xs mb-2">
        <strong>{groupHeading}</strong>
        {transferNoteNr !== "" && pageType === "transfer" && (
          <span className="font-weight-normal mt-2 font-xs d-block">
            <strong>{` ${t("Transfer note nr")}: #${transferNoteNr}`}</strong>
          </span>
        )}
      </div>
      {machine.items &&
        machine.items.map((item) => {
          // HLL-1065 by Nicke
          const usesAvaliability = item?.availabilityIsUsed
          let availability = item?.availability
          if (availability === 0 && pageType === "incoming") {
            availability = 1 // Convert all items with "not-available/0" to "available/1" in this view
          }

          return (
            <div className="machine-transfer-item" key={item.id}>
              <strong className="d-flex align-items-center">
                {usesAvaliability && (
                  <span
                    className={`machine-item-availability d-block mr-2 flex-shrink-0 availability--${availabilityColorMap(
                      availability
                    )}`}></span>
                )}
                <span className="text-truncate text-body d-block">
                  {item.name}
                </span>
              </strong>
              <div className="d-flex align-items-center mt-2">
                <div className="mr-3">
                  <span className="badge bg-light">{`${
                    pageType === "transfer"
                      ? item.quantity
                      : item.amountToAcknowledge ?? item.recivedAmount
                  } st`}</span>
                </div>
                <span>
                  {pageType === "incoming"
                    ? item?.articleNumber
                      ? item.articleNumber
                      : item?.fleetItemNumber
                    : item.number}
                </span>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default SummaryItem
