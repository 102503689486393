import React from "react"
import { Link } from "react-router-dom"
export default function Item({ icon = "arrow-right", text, link, visible }) {
  if (!visible) {
    return null
  }

  const iconComponent = () => {
    if (typeof icon === "string" && icon.includes("fe")) {
      return (
        <span
          className={`fe ${icon} text-primary `}
          style={{ fontSize: 45 }}></span>
      )
    } else {
      return (
        <span
          className="d-block text-primary svg-icon-wrapper"
          style={{ width: 45, height: 45 }}>
          {icon}
        </span>
      )
    }
  }

  return (
    <Link className="shortcut text-center d-block text-gray-800 mb-4" to={link}>
      <div className="card mb-2">
        <div className="card-body mx-auto px-0 py-3 d-flex align-items-center justify-content-center">
          {iconComponent()}
        </div>
      </div>
      <span className="text font-weight-bold position-relative text-gray-800 font-size-sm">
        {text}
      </span>
    </Link>
  )
}
