import { useEffect, useState, useCallback } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useTranslation } from "react-i18next"
import axios from "axios"
import toast from "react-hot-toast"
import { useRecoilState } from "recoil"
import { useQuery } from "@tanstack/react-query"

import Storage from "../../../../utils/storage"
import { currentMachineList } from "../Store"
import { modalSheetBounceTransition } from "../../../../utils/helpers"
import CustomSvgIcon from "../../../Common/CustomSvgIcon"
import {
  useDebounce,
  getMachineListPriceCalculation
} from "../../../../utils/hooks"

import Loader from "../../../Loader/Loader"
import {
  calculateInsuranceCost,
  calculateRentalPrice,
  calculatePurchaseCost,
  getMachineAccessoriesAndServices
} from "../helpers"

import SheetData from "./Data"

const DEFAULT_DEBOUNCE_TIME = 1000

export default function Sheet({
  setShowSheet,
  handleClose,
  machineListRefetch,
  setShowConfirmDeleteModal
}) {
  const { t } = useTranslation()
  const customer = Storage.get("customer", null, "local") ?? null

  // let modalSheetClassName =
  //   "text-center modal-sheet no-shadow full-height machine-list-modal-sheet"

  let modalSheetClassName = "modal-split-sheet"

  // Recoil States
  const [currentMachineListState, setCurrentMachineListState] =
    useRecoilState(currentMachineList)

  // Local States
  const [isUpdating, setIsUpdating] = useState(false)
  const [loading, setLoading] = useState(false)
  const [listName, setListName] = useState(currentMachineListState?.data?.name)
  const [listPrivate, setListPrivate] = useState(
    currentMachineListState?.data?.private
  )

  const [showTotalRentalCostDetails, setShowTotalRentalCostDetails] =
    useState(false)
  const [showTotalPurchaseCostDetails, setShowTotalPurchaseCostDetails] =
    useState(false)

  const debouncedUpdate = useDebounce(isUpdating, DEFAULT_DEBOUNCE_TIME)

  // Handle update current machine list name
  const onChangeName = (e) => {
    setListName(e.target.value)

    setCurrentMachineListState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        name: e.target.value
      }
    }))
  }

  // Handle current machine list privacy
  const onChangePrivate = (e) => {
    setListPrivate(e.target.checked)
    setCurrentMachineListState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        private: e.target.checked
      }
    }))
  }

  const { isFetching: isPriceCalculationFetching } = useQuery({
    queryKey: [`machineListPrices`],
    queryFn: async () => {
      setLoading(true)
      setShowTotalRentalCostDetails(false)
      setShowTotalPurchaseCostDetails(false)

      const result = await getMachineListPriceCalculation(
        customer?.id,
        currentMachineListState?.data?.machines
      )

      if (result?.data) {
        setCurrentMachineListState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            machines: result?.data
          }
        }))
      }
      setLoading(false)
      return result
    },
    refetchOnWindowFocus: false,
    enabled: !!currentMachineListState?.data?.machines?.length > 0,
    cacheTime: 60000 * 60 // 1 hour,
  })

  const delay = (time) => {
    return new Promise((res) => {
      setTimeout(res, time)
    })
  }

  // Handle save and update current machine list
  const handleSaveAndUpdate = async () => {
    setLoading(true)
    const listId = currentMachineListState?.id
    const body = currentMachineListState?.data

    await axios
      .put(`/api/database/customer/updateMachineList/${listId}`, {
        data: body
      })
      .then(
        (res) => {
          setShowSheet(false)
          setCurrentMachineListState(res?.data?.rows[0])
        },
        (err) => {
          console.log(err, "PUT /api/database/customer/updateMachineList")
          toast.error(t("Something went wrong"))
        }
      )
      .finally(() => {
        setLoading(false)
        machineListRefetch()
      })
  }

  const onSubmit = (e) => {
    e.preventDefault()
  }

  // Handle update current machine list on delay
  const handleListUpdateOnDelay = useCallback(async () => {
    setLoading(true)
    setShowTotalRentalCostDetails(false)
    setShowTotalPurchaseCostDetails(false)

    const body = currentMachineListState?.data

    setCurrentMachineListState((prevState) => ({
      ...prevState,
      data: body
    }))
    await delay(500)

    setLoading(false)
    setIsUpdating(false)
  }, [currentMachineListState?.data, setCurrentMachineListState])

  const machines =
    currentMachineListState?.data?.machines &&
    currentMachineListState?.data?.machines.length > 0
      ? currentMachineListState?.data?.machines
      : null

  let totalInsuranceCost = calculateInsuranceCost()

  // Prices
  let totalRentalCost = calculateRentalPrice(
    machines,
    totalInsuranceCost,
    isPriceCalculationFetching
  )
  let totalPurchaseCost = calculatePurchaseCost(
    machines,
    isPriceCalculationFetching
  )

  // get accessories and services
  let { accessories, services } = getMachineAccessoriesAndServices(
    machines,
    isPriceCalculationFetching
  )

  useEffect(() => {
    if (debouncedUpdate) {
      handleListUpdateOnDelay()
    }
  }, [debouncedUpdate, handleListUpdateOnDelay])

  // console.log("currentMachineListState", currentMachineListState)

  return (
    <AnimatePresence>
      <motion.div
        key="modalSheet"
        className={modalSheetClassName}
        initial={{ opacity: 0, y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "100%" }}
        transition={modalSheetBounceTransition}>
        <div className="modal-split-sheet__header">
          <div className="d-flex justify-content-between align-items-center p-4">
            <div className="d-flex align-items-center">
              <h3 className="mb-0">{t("Machine list")}</h3>
              {loading && <Loader className="ml-3" />}
            </div>
            <button
              disabled={loading}
              onClick={() => {
                handleClose()
              }}
              className={`modal-split-sheet__close-btn${
                loading ? " is-disabled" : ""
              }`}>
              <CustomSvgIcon
                name="close"
                width={16}
                height={16}
                className="text-black"
              />
            </button>
          </div>
        </div>
        <div className="modal-split-sheet__wrapper">
          <SheetData
            currentMachineListState={currentMachineListState}
            setShowConfirmDeleteModal={setShowConfirmDeleteModal}
            machines={machines}
            loading={loading}
            isPriceCalculationFetching={isPriceCalculationFetching}
            setIsUpdating={setIsUpdating}
            listName={listName}
            listPrivate={listPrivate}
            onChangeName={onChangeName}
            onChangePrivate={onChangePrivate}
            showTotalRentalCostDetails={showTotalRentalCostDetails}
            showTotalPurchaseCostDetails={showTotalPurchaseCostDetails}
            setShowTotalRentalCostDetails={setShowTotalRentalCostDetails}
            setShowTotalPurchaseCostDetails={setShowTotalPurchaseCostDetails}
            totalPurchaseCost={totalPurchaseCost}
            totalRentalCost={totalRentalCost}
            totalInsuranceCost={totalInsuranceCost}
            accessories={accessories}
            services={services}
            handleSaveAndUpdate={handleSaveAndUpdate}
            onSubmit={onSubmit}
          />
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
