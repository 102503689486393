import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { Dropdown } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import CustomDropDownToggle from "../../../Common/CustomDropDownToggle"
import CustomDropdownMenu from "../../../Common/CustomDropdownMenu"
import { usePermissionCheck } from "../../../../utils/hooks"

import CustomSvgIcon from "../../../Common/CustomSvgIcon"

export default function DocumentRecipient({
  email,
  documentTypes,
  handleShowEditModal,
  handleShowConfirmModal,
  isLocked = false
}) {
  const { t } = useTranslation()
  const tableRowRef = useRef()
  const recipientData = {
    email,
    documentTypes
  }

  const hasDocumentRecipientsAdminPermission = usePermissionCheck(
    "MinaSidor.DokumentmottagareAdministrera"
  )

  return (
    <tr ref={tableRowRef}>
      <td className={`${isLocked ? " text-muted" : ""}`}>
        {email}
        {isLocked && (
          <small className="d-block">{t("Marked as client recipient")}</small>
        )}
      </td>
      <td>
        <div
          className={`d-flex align-items-center ${
            isLocked ? " text-muted" : ""
          }`}>
          <div className="mr-3 mr-sm-4">
            <div className="d-flex align-items-center">
              <span
                className={`fe fe-${
                  documentTypes.includes(1)
                    ? "check-circle text-primary"
                    : "circle"
                } ${isLocked ? "text-muted" : ""}`}
              />
              <p className="ml-2 mb-0">{t("Delivery note")}</p>
            </div>
          </div>
          <div className="mr-3 mr-sm-4">
            <div className="d-flex align-items-center">
              <span
                className={`fe fe-${
                  documentTypes.includes(2)
                    ? "check-circle text-primary"
                    : "circle"
                } ${isLocked ? "text-muted" : ""}`}
              />
              <p className="ml-2 mb-0">{t("Return slip")}</p>
            </div>
          </div>
          <div className="mr-3 mr-sm-4">
            <div className="d-flex align-items-center">
              <span
                className={`fe fe-${
                  documentTypes.includes(3)
                    ? "check-circle text-primary"
                    : "circle"
                } ${isLocked ? "text-muted" : ""}`}
              />
              <p className="ml-2 mb-0">{t("Outstanding rents")}</p>
            </div>
          </div>
          {isLocked ? (
            <CustomSvgIcon name="lock" width={22} height={22} />
          ) : (
            hasDocumentRecipientsAdminPermission && (
              <Dropdown className="d-flex align-items-center">
                <Dropdown.Toggle as={CustomDropDownToggle}>
                  <span className="d-block svg-icon-wrapper">
                    <CustomSvgIcon
                      name="dot-navigation"
                      width={22}
                      height={24}
                      className={`text-muted`}
                    />
                  </span>
                </Dropdown.Toggle>
                <DropdownMenu
                  as={CustomDropdownMenu}
                  className="position-fixed"
                  style={{ zIndex: "999" }}>
                  <DropdownItem
                    onClick={() => handleShowEditModal(recipientData)}>
                    {t("Edit")}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleShowConfirmModal(recipientData)}>
                    {t("Delete recipient")}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )
          )}
        </div>
      </td>
    </tr>
  )
}
