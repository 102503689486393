import React from "react"

const InspectionNotes = ({ notes }) => {
  return (
    <div className="col-12">
      {notes.map((note, index) => {
        const date = new Date(note?.createdAt)
        return (
          <div key={index} className={`inspection-note divider--bottom py-3`}>
            <small>{`${date.toLocaleString("sv-SE")} | ${
              note?.createdBy
            }`}</small>
            <p className="mb-0 text-truncate">{note?.inspectionInfo}</p>
          </div>
        )
      })}
    </div>
  )
}

export default InspectionNotes
