import React from "react"
import { useTranslation } from "react-i18next"
import Alert from "../../components/Common/Alert"
import Profile from "../../components/CompanyProfile/Profile"
import Billing from "../../components/CompanyProfile/Billing"
import DocumentRecipients from "../../components/CompanyProfile/DocumentRecipients"
import { useLocation } from "react-router-dom"
import PageNotFound from "../PageNotFound/PageNotFound"
import Storage from "../../utils/storage"
import { usePermissionCheck } from "../../utils/hooks"

const CompanyProfile = () => {
  const { t } = useTranslation()
  const customer = Storage.get("customer", null, "local")
  let { pathname } = useLocation()

  //Permissons
  const hasBillingPermission = usePermissionCheck("MinaSidor.FakturametodLäs")
  const hasDocumentRecipientsReadPermission = usePermissionCheck(
    "MinaSidor.DokumentmottagareLäs"
  )

  // if not billing permission and billing in url
  if (!hasBillingPermission && pathname.includes(t("billing"))) {
    return <PageNotFound noPermission={true} />
  }
  // if not document recipients permission and document recipients in url
  if (
    !hasDocumentRecipientsReadPermission &&
    pathname.includes(t("document-recipients"))
  ) {
    return <PageNotFound noPermission={true} />
  }

  //Billing
  if (hasBillingPermission && pathname.includes(t("billing"))) {
    return (
      <div className="container-fluid container-fluid-max-width">
        <Billing customer={customer} />
      </div>
    )
  }

  //DocumentRecipients
  if (
    hasDocumentRecipientsReadPermission &&
    pathname.includes(t("document-recipients"))
  ) {
    return (
      <div className="container-fluid container-fluid-max-width">
        <DocumentRecipients customer={customer} />
      </div>
    )
  }

  function DetailsComponent() {
    if (typeof pathname.includes(t("company-profile"))) {
      return <Profile data={customer} />
    }
    return <PageNotFound noPermission={true} />
  }

  if (!customer) {
    return (
      <Alert
        className="text-center p-5"
        message={`${t("Something went wrong, please refresh current page.")}`}
      />
    )
  }

  return (
    <div className="container-fluid container-fluid-max-width">
      <DetailsComponent />
    </div>
  )
}

export default CompanyProfile
