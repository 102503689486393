import React from "react"
import { useTranslation } from "react-i18next"

const MachineActions = ({ proceed, handleGoBack, handleOpenModal }) => {
  const { t } = useTranslation()

  return (
    <div className="machine-footer position-fixed w-100 bg-white js-app-action-footer">
      <div className="container-fluid container-fluid-max-width">
        <div className="py-3">
          <div className="ml-auto">
            <div className="d-flex align-items-center justify-content-center">
              <button
                onClick={handleGoBack}
                className="btn btn-outline-secondary d-block w-100 mr-3">
                {t("Cancel")}
              </button>
              <button
                onClick={handleOpenModal}
                disabled={!proceed}
                className={`btn btn-primary d-block w-100${
                  !proceed ? " disabled" : ""
                }`}>
                {t("Continue")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MachineActions
