import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { usePermissionCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"
import ProjectDocumentRecipients from "../../components/ProjectDocumentRecipients/ProjectDocumentRecipients"
import axios from "axios"
import Storage from "../../utils/storage"
import PageLoader from "../../components/PageLoader/PageLoader"

export default function DocumentRecipients(props) {
  const { id } = useParams()
  const customer = Storage.get("customer", null, "local")
  const [customerDocumentRecipients, setCustomerDocumentRecipients] =
    useState(null)
  const [loading, setLoading] = useState(false)

  //Permissions
  const hasCompanyPermission = usePermissionCheck("MinaSidor.DotterbolagLäs")

  useEffect(() => {
    let unmounted = false

    const request = async () => {
      setLoading(true)

      await axios
        .post("/api/hll/customerDocumentRecipients", {
          customerId: customer?.id
        })
        .then((response) => {
          if (!unmounted) {
            setCustomerDocumentRecipients(response.data)
          }
        })
        .catch((error) => {
          console.error(`Error code: ${error.response.data.CorrelationId}`)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    request()

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer.id])

  if (!hasCompanyPermission) {
    return <PageNotFound noPermission={true} />
  }

  if (loading) {
    return <PageLoader />
  }

  return (
    <>
      <div className="container-fluid container-fluid-max-width">
        <ProjectDocumentRecipients
          id={id}
          customerDocumentRecipients={customerDocumentRecipients}
          {...props}
        />
      </div>
    </>
  )
}
