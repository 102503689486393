import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { usePermissionCheck } from "../../utils/hooks"
import OustandingRentSettingsForm from "./Forms/OustandingRentSettingsForm/OustandingRentSettingsForm"
import DocumentRecipientsList from "./Forms/DocumentRecipients/DocumentRecipientsList"
import Toaster from "../Common/Toast"
import axios from "axios"
import Loader from "../Loader/Loader"

export default function DocumentRecipients({ customer }) {
  const { t } = useTranslation()

  const hasDocumentRecipientsAdminPermission = usePermissionCheck(
    "MinaSidor.DokumentmottagareAdministrera"
  )

  // Local states
  const [customerData, setCustomerData] = useState(customer)
  const [documentRecipients, setDocumentRecipients] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)

  useEffect(() => {
    let unmounted = false

    const request = async () => {
      setLoading(true)

      await axios
        .post("/api/hll/customerDocumentRecipients", {
          customerId: customer?.id
        })
        .then((response) => {
          if (!unmounted) {
            setDocumentRecipients(response.data)
          }
        })
        .catch((error) => {
          console.error(`Error code: ${error.response.data.CorrelationId}`)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    request()

    return () => {
      unmounted = true
    }
  }, [customer])

  return (
    <>
      <Toaster />
      <div className="row mb-5">
        <div className="col-12">
          <div className="header">
            <div className="header-body">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="header-pretitle">
                    {t("My pages")} - {t("Company Profile")}
                  </h6>
                  <h1 className="header-title text-truncate">
                    {t("Document recipients")}
                  </h1>
                </div>
                <div className="col-auto mt-2 mt-md-0">
                  {hasDocumentRecipientsAdminPermission && !loading && (
                    <button
                      className={`btn btn-primary ml-auto${
                        !hasDocumentRecipientsAdminPermission ? " disabled" : ""
                      }`}
                      disabled={!hasDocumentRecipientsAdminPermission}
                      onClick={() => setShowModalUpdate(true)}>
                      {t("Add a recipient")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}

      {!loading && documentRecipients && (
        <>
          {hasDocumentRecipientsAdminPermission && (
            <OustandingRentSettingsForm
              customerData={customerData}
              setCustomerData={setCustomerData}
            />
          )}

          <DocumentRecipientsList
            documentRecipients={documentRecipients}
            customerData={customerData}
            showModalUpdate={showModalUpdate}
            setShowModalUpdate={setShowModalUpdate}
          />
        </>
      )}
    </>
  )
}
