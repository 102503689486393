import i18n from "../../../../i18n"

export const billingMethodViewTemplate = {
  fields: [
    {
      name: "type",
      type: "textField",
      title: i18n.t("Type of invoicing"),
      fieldClassName: "col-12 col-md-6 mb-3",
      instructions: ""
    },
    {
      name: "pdfInvoiceReceiverAddress",
      type: "textField",
      title: i18n.t("Email address"),
      fieldClassName: "col-12 col-md-6 mb-3",
      instructions: ""
    },
    {
      name: "billingFrequency",
      type: "textField",
      title: i18n.t("Billing frequency"),
      fieldClassName: "col-12 col-md-6 mb-3",
      instructions: ""
    }
  ]
}

export const billingMethodFormTemplate = {
  fields: [
    {
      name: "type",
      type: "select",
      title: i18n.t("Type of invoicing"),
      fieldClassName: "col-12 mb-4 text-left",
      disabled: false,
      readOnly: false,
      required: {
        value: false,
        message: i18n.t("Required field")
      }
    },
    {
      name: "pdfInvoiceReceiverAddress",
      type: "email",
      title: i18n.t("Email address"),
      fieldClassName: "col-12 col-md-6 mb-4 text-left",
      disabled: false,
      readOnly: false,
      required: {
        value: true,
        message: i18n.t("Required field")
      }
    },
    {
      name: "pdfInvoiceReceiverAddressConfirmation",
      type: "email",
      title: i18n.t("Confirm email address"),
      fieldClassName: "col-12 col-md-6 mb-4 text-left",
      disabled: false,
      readOnly: false,
      required: {
        value: true,
        message: i18n.t("Required field")
      }
    }
  ]
}
