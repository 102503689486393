import { useTranslation } from "react-i18next"

import { priceWithCurrencyHelper } from "../../../../utils/helpers"

export default function RentalCostDetails({
  totalRentalCost,
  totalInsuranceCost
}) {
  const { t } = useTranslation()
  return (
    <div className="mb-3 text-muted mr-4">
      <div className="d-flex justify-content-between align-items-center mt-1">
        <div>{t("Machines and accessories")}</div>
        <div>
          {priceWithCurrencyHelper(totalRentalCost - totalInsuranceCost)}
        </div>
      </div>

      {totalInsuranceCost > 0 && (
        <div className="d-flex justify-content-between align-items-center mt-1">
          <div>{t("Insurance")}</div>
          <div>{priceWithCurrencyHelper(totalInsuranceCost)}</div>
        </div>
      )}
    </div>
  )
}
