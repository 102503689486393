import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const Pagination = ({
  perPage,
  total,
  paginate,
  currentPage,
  nextPage,
  previousPage,
  prevBtnClassName = "d-flex page-link pl-0 pr-4 border-right align-items-center",
  nextBtnClassName = "d-flex page-link pl-4 pr-0 border-left align-items-center"
}) => {
  const { t } = useTranslation()

  const totalPages = []

  for (let i = 1; i <= Math.ceil(total / perPage); i++) {
    totalPages.push(i)
  }

  return (
    <Fragment>
      {total !== 0 && (
        <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
          <li className="page-item">
            <button
              className={prevBtnClassName}
              onClick={() => previousPage()}
              disabled={parseInt(currentPage) === 1 ? true : false}>
              <CustomSvgIcon
                width={16}
                height={16}
                name="arrow-left"
                className={`text-black mr-2`}
              />
              {t("Previous")}
            </button>
          </li>
        </ul>
      )}

      <ul className="list-pagination pagination pagination-tabs card-pagination d-none d-xl-flex">
        {totalPages.map((number) => (
          <li
            key={number}
            className={
              parseInt(currentPage) === parseInt(number) ? "active" : ""
            }>
            <button
              className="page"
              data-number={number}
              onClick={() => paginate(currentPage, number)}>
              {number}
            </button>
          </li>
        ))}
      </ul>

      {total !== 0 && (
        <ul className="list-pagination-next pagination pagination-tabs card-pagination">
          <li className="page-item">
            <button
              className={nextBtnClassName}
              href="#!"
              onClick={() => nextPage()}
              disabled={
                parseInt(currentPage) === parseInt(totalPages.length)
                  ? true
                  : false
              }>
              {t("Next")}

              <CustomSvgIcon
                width={16}
                height={16}
                name="arrow-right"
                className={`text-black ml-2`}
              />
            </button>
          </li>
        </ul>
      )}
    </Fragment>
  )
}

export default Pagination
