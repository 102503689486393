import React from "react"
import Icon from "../../Common/Icon"

const IncrementDecrement = ({
  label,
  amount,
  handleQuantityClick,
  handleQuantityChange,
  decrementLimit,
  incrementLimit,
  itemType
}) => {
  return (
    <>
      <label
        htmlFor="date"
        className="field__label d-block form-label mb-1 text-uppercase">
        {label}
      </label>
      <div className="bg-white d-flex justify-content-between align-items-center increment-decrement-box">
        <button
          className="bg-transparent p-0 text-body"
          disabled={
            itemType === "INDIVIDUAL" ? true : amount === decrementLimit
          }
          onClick={() => handleQuantityClick("decrement")}>
          <Icon name="minusCircle" />
        </button>
        <div>
          <input
            inputMode="decimal"
            type="number"
            value={amount}
            disabled={itemType === "INDIVIDUAL" ? true : false}
            className="h-100 form-control p-0 text-center border-0 appearence-none"
            onChange={handleQuantityChange}
          />
        </div>
        <button
          className="bg-transparent p-0 text-body"
          disabled={itemType === "INDIVIDUAL" ? true : amount >= incrementLimit}
          onClick={() => handleQuantityClick("increment")}>
          <Icon name="plusCircle" />
        </button>
      </div>
    </>
  )
}

export default IncrementDecrement
