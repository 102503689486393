import i18n from "../../i18n"

export const inviteFormTemplate = {
  fields: [
    {
      name: "action",
      type: "action"
    },
    {
      name: "customerId",
      type: "hidden"
    },
    {
      name: "id",
      type: "hidden"
    },
    {
      name: "firstName",
      type: "text",
      title: i18n.t("First name"),
      required: {
        value: true,
        message: i18n.t("First name is required")
      }
    },
    {
      name: "lastName",
      type: "text",
      title: i18n.t("Last name"),
      required: {
        value: true,
        message: i18n.t("Last name is required")
      }
    },
    {
      name: "email",
      type: "email",
      title: i18n.t("Email"),
      required: {
        value: true,
        message: i18n.t("Email is required")
      }
    },
    {
      name: "validTo",
      type: "date",
      title: i18n.t("Expiry date"),
      instructions: i18n.t("Last date the user account is active")
    },
    {
      name: "userAccountGroups",
      type: "userAccountGroups",
      title: i18n.t("Permissions")
    }
  ]
}

export const updateFormTemplate = {
  fields: [
    {
      name: "action",
      type: "action"
    },
    {
      name: "customerId",
      type: "hidden"
    },
    {
      name: "id",
      type: "hidden"
    },
    {
      name: "firstName",
      type: "text",
      title: i18n.t("First name"),
      required: {
        value: true,
        message: i18n.t("First name is required")
      }
    },
    {
      name: "lastName",
      type: "text",
      title: i18n.t("Last name"),
      required: {
        value: true,
        message: i18n.t("Last name is required")
      }
    },
    {
      name: "email",
      type: "email",
      title: i18n.t("Email")
    },
    {
      name: "validTo",
      type: "date",
      title: i18n.t("Expiry date"),
      instructions: i18n.t("Last date the user account is active")
    },
    {
      name: "userAccountGroups",
      type: "userAccountGroups",
      title: i18n.t("Permissions")
    }
  ]
}
