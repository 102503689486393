import React from "react"
import List from "./List"

export default function Registration({ data }) {
  if (data?.length === 0) return null

  return (
    <div className="row">
      {data.map((item, index) => {
        return (
          <div className="col-12 px-0 px-sm-3" key={index}>
            <List {...item} />
          </div>
        )
      })}
    </div>
  )
}
