import React from "react"

function TableSkeletonLoader({ rows = 5, cells = 7 }) {
  const tableSize = [rows, cells]

  const TableRows = () => {
    return [...Array(tableSize[0])].map((tr, rowIndex) => (
      <tr key={rowIndex}>
        {[...Array(tableSize[1])].map((td, cellIndex) => (
          <td key={rowIndex + cellIndex} className="loading-cell py-4">
            <div className="loading-bar"></div>
          </td>
        ))}
      </tr>
    ))
  }

  return <TableRows />
}

export default TableSkeletonLoader
