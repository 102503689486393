import SkeletonLoader from "../../Common/SkeletonLoader"
export default function Loader({ limit = 6 }) {
  const LIMIT = limit

  return (
    <div className="row mt-4">
      <div className="col-12 col-lg-6">
        {Array.from(Array(LIMIT).keys()).map((_item, index) => {
          return <SkeletonLoader key={index} className="mb-3" rounded={false} />
        })}
      </div>
      <div className="col-12 col-lg-6">
        {Array.from(Array(LIMIT).keys()).map((_item, index) => {
          return <SkeletonLoader key={index} className="mb-3" rounded={false} />
        })}
      </div>
    </div>
  )
}
