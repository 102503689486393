import React from "react"
import List from "../../components/Machines/Transfer/List"
import { usePermissionCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"
import { RecoilRoot } from "recoil"

const MachineTransfer = () => {
  const hasMachineTransferPermission = usePermissionCheck(
    "MinaSidor.MaskinflyttAdministrera"
  )

  if (!hasMachineTransferPermission) {
    return <PageNotFound noPermission={true} />
  }

  return (
    <RecoilRoot>
      <List />
    </RecoilRoot>
  )
}

export default MachineTransfer
