import React from "react"
import { motion } from "framer-motion"

export default function List({
  handleClick,
  item,
  currentMachine,
  recordStreaming
}) {
  const isActive = currentMachine?.id === item?.id
  return (
    <motion.div
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 50, opacity: 0 }}>
      <button
        className={`d-block w-100 bg-white overflow-hidden p-0 machine-item${
          isActive || recordStreaming ? " is-disabled" : ""
        }`}
        disabled={isActive || recordStreaming}
        onClick={() => handleClick(item)}>
        <div className="row d-flex align-items-center p-4">
          <div className="col">
            <div className="d-block font-weight-bold text-left">
              {item?.number && item?.number}
            </div>
            <div className="d-block text-muted text-gray-700 text-left">
              {item?.fleetModelName && item?.fleetModelName}
            </div>
          </div>
          <div className="col-auto">
            <span
              className={`d-block p-0 rounded-circle${
                isActive ? " bg-primary" : " bg-white border border-primary"
              }`}
              style={{
                fontSize: 16,
                width: 22,
                height: 22,
                lineHeight: 1.3
              }}>
              {isActive && <i className="fe fe-check text-white"></i>}
            </span>
          </div>
        </div>
      </button>
    </motion.div>
  )
}
