import i18n from "../../../../i18n"

export const billingAddressViewTemplate = {
  fields: [
    {
      name: "adressLine1",
      type: "textField",
      title: i18n.t("Name/Company"),
      fieldClassName: "col-12 mb-3 text-left",
      instructions: ""
    },
    {
      name: "adressLine2",
      type: "textField",
      title: i18n.t("C/o"),
      fieldClassName: "col-12 col-md-6 mb-3 text-left",
      instructions: ""
    },
    {
      name: "adressLine3",
      type: "textField",
      title: i18n.t("Address"),
      fieldClassName: "col-12 col-md-6 mb-3 text-left",
      instructions: ""
    },
    {
      name: "offset",
      type: "offset",
      fieldClassName: "col-12 col-md-6 d-flex align-items-center mb-3 mb-md-0",
      title: "",
      instructions: ""
    },
    {
      name: "postalCode",
      type: "textField",
      title: i18n.t("Zip code"),
      fieldClassName: "col-12 col-md-6 mb-3 text-left",
      instructions: ""
    },
    {
      name: "city",
      type: "textField",
      title: i18n.t("City"),
      fieldClassName: "col-12 col-md-6 mb-3 text-left",
      instructions: ""
    }
  ]
}

export const billingAddressFormTemplate = {
  fields: [
    {
      name: "type",
      type: "hidden",
      title: "",
      fieldClassName: "",
      instructions: ""
    },
    {
      name: "pdfInvoiceReceiverAddress",
      type: "hidden",
      title: "",
      fieldClassName: "",
      instructions: ""
    },
    {
      name: "adressLine1",
      type: "hidden",
      title: i18n.t("Name/Company"),
      fieldClassName: "col-12 mb-3 text-left",
      instructions: ""
    },
    {
      name: "adressLine2",
      type: "text",
      title: i18n.t("C/o"),
      fieldClassName: "col-12 col-md-6 mb-3 text-left",
      disabled: false,
      readOnly: false
    },
    {
      name: "adressLine3",
      type: "text",
      title: i18n.t("Address"),
      fieldClassName: "col-12 col-md-6 mb-3 text-left",
      disabled: false,
      readOnly: false,
      required: {
        value: true,
        message: i18n.t("Required field")
      }
    },
    {
      name: "",
      type: "offset",
      fieldClassName: "col-12 col-md-6 text-left",
      disabled: false,
      readOnly: false,
      title: ""
    },
    {
      name: "postalCode",
      type: "text",
      title: i18n.t("Zip code"),
      fieldClassName: "col-12 col-md-6 mb-3 text-left",
      disabled: false,
      readOnly: false,
      required: {
        value: true,
        message: i18n.t("Required field")
      }
    },
    {
      name: "city",
      type: "text",
      title: i18n.t("City"),
      fieldClassName: "col-12 col-md-6 mb-3 text-left",
      disabled: false,
      readOnly: false,
      required: {
        value: true,
        message: i18n.t("Required field")
      }
    }
    // {
    //   name: "country",
    //   type: "hidden",
    //   title: i18n.t("Country"),
    //   fieldClassName: "col-12 col-md-6 mb-3 text-left",
    //   disabled: false,
    //   readOnly: false
    // }
  ]
}
