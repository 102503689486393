import React from "react"

const ActionFooter = ({
  children,
  padding = "py-3",
  className = "d-flex align-items-center"
}) => {
  return (
    <div className="footer-actions position-fixed w-100 bg-white js-app-action-footer">
      <div className="container-fluid container-fluid-max-width">
        <div className={padding}>
          <div className="ml-auto">
            <div className={className}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActionFooter
