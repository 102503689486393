import React, { useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import { newOrderInformation } from "../../Store"

import Address from "./Address"

import {
  googleMapsDataToAddress,
  projectMarkingType
} from "../../../../utils/helpers"

import Loader from "../../../Loader/Loader"
import InputMask from "react-input-mask"

export default function CreateProject({
  setDisabledSubmit,
  setFormatedFormData,
  handleSubmit,
  loading,
  setError
}) {
  const nameRef = useRef()
  const numberRef = useRef()
  const { t } = useTranslation()
  const newOrderInformationState = useRecoilValue(newOrderInformation)
  const [siteNameRequired, setSiteNameRequired] = useState(true)
  const [siteNumberRequired, setSiteNumberRequired] = useState(true)
  const [siteName, setSiteName] = useState("")
  const [siteNameFilter, setSiteNameFilter] = useState("")
  const [siteNumber, setSiteNumber] = useState("")
  const [siteNumberFilter, setSiteNumberFilter] = useState("")
  const [addressData, setAddressData] = useState(null)
  const [requiredBoth, setRequiredBoth] = useState(false)

  const { customer } = newOrderInformationState
  const { markingFormats } = customer

  const formatChars = {
    9: "[0-9]",
    a: "[A-Za-z]",
    "#": "[0-9]",
    _: "[A-Za-z]",
    "*": "[A-Za-z0-9]"
  }

  const handleSiteNameChange = ({ target: { value } }) => {
    const rawValue = value.replace(/_/g, "")
    setError(false)
    setSiteName(value)

    setSiteNumberRequired(
      rawValue === "" ||
        (siteNameFilter.length > 0 && rawValue.length !== siteNameFilter.length)
    )
  }

  const handleSiteNumberChange = ({ target: { value } }) => {
    const rawValue = value.replace(/_/g, "")
    setError(false)
    setSiteNumber(value)

    setSiteNameRequired(
      rawValue === "" ||
        (siteNumberFilter.length > 0 &&
          rawValue.length !== siteNumberFilter.length)
    )
  }

  useEffect(() => {
    setFormatedFormData({
      name: siteName,
      number: siteNumber,
      customerId: customer?.id,
      address: googleMapsDataToAddress(addressData)
    })

    const siteNameRawValue = siteName ? siteName.replace(/_/g, "") : ""
    const siteNumberRawValue = siteNumber ? siteNumber.replace(/_/g, "") : ""

    let isValidSiteName = false
    let isValidSiteNumber = false

    if (
      siteNameFilter.length > 0 &&
      siteNameRawValue.length !== siteNameFilter.length
    ) {
      isValidSiteName = false
    } else {
      isValidSiteName = siteNameRawValue !== ""
    }

    if (
      siteNumberFilter.length > 0 &&
      siteNumberRawValue.length !== siteNumberFilter.length
    ) {
      isValidSiteNumber = false
    } else {
      isValidSiteNumber = siteNumberRawValue !== ""
    }

    let disabledSubmit = true

    if (requiredBoth) {
      if (addressData && isValidSiteName && isValidSiteNumber) {
        disabledSubmit = false
      }
    } else {
      if (addressData && (isValidSiteName || isValidSiteNumber)) {
        disabledSubmit = false
      }
    }

    setDisabledSubmit(disabledSubmit)
  }, [
    addressData,
    customer.id,
    siteName,
    siteNumber,
    setDisabledSubmit,
    setFormatedFormData,
    siteNameFilter,
    siteNumberFilter,
    requiredBoth
  ])

  const defaultSiteNameFilter = markingFormats.filter(
    (format) => projectMarkingType(format?.type) === "siteName"
  )

  const defaultSiteNumberFilter = markingFormats.filter(
    (format) => projectMarkingType(format?.type) === "siteNumber"
  )

  useEffect(() => {
    if (defaultSiteNameFilter && defaultSiteNameFilter.length > 0) {
      setSiteNameFilter(defaultSiteNameFilter[0]?.filter)
    }
    if (defaultSiteNumberFilter && defaultSiteNumberFilter.length > 0) {
      setSiteNumberFilter(defaultSiteNumberFilter[0]?.filter)
    }

    if (
      defaultSiteNameFilter.length > 0 &&
      defaultSiteNumberFilter.length > 0
    ) {
      setRequiredBoth(true) // If we find masking filter on both name and number, both needs to be required.
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container-fluid container-fluid-max-width mt-4">
      {loading && <Loader />}
      {!loading && (
        <form autoComplete="off" onSubmit={() => handleSubmit()}>
          <div className="row">
            <div className="col-12 mb-3 text-left">
              <label
                htmlFor="siteName"
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {t("Name of the project")}
                {(siteNameRequired || requiredBoth) && "*"}
              </label>
              <div className="row gx-2">
                <div className="col">
                  <InputMask
                    type="text"
                    ref={nameRef}
                    name="siteName"
                    id="siteName"
                    value={siteName}
                    onChange={(e) => handleSiteNameChange(e)}
                    placeholder={siteNameFilter}
                    mask={siteNameFilter}
                    formatChars={formatChars}
                    className="form-control"
                  />
                </div>
                {defaultSiteNameFilter && defaultSiteNameFilter.length > 1 && (
                  <div className="col-auto">
                    <select
                      className="form-select"
                      style={{ height: 50, marginRight: "-2px" }}
                      onChange={({ target: { value } }) => {
                        setSiteName("")
                        setSiteNameFilter(value)
                      }}>
                      {defaultSiteNameFilter.map((format, index) => {
                        return (
                          <option key={index} value={format?.filter}>
                            {format?.filter}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 mb-3 text-left">
              <label
                htmlFor="siteNumber"
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {t("Project number")}
                {(siteNumberRequired || requiredBoth) && "*"}
              </label>
              <div className="row gx-2">
                <div className="col">
                  <InputMask
                    type="text"
                    ref={numberRef}
                    name="siteNumber"
                    id="siteNumber"
                    value={siteNumber}
                    onChange={(e) => handleSiteNumberChange(e)}
                    placeholder={siteNumberFilter}
                    mask={siteNumberFilter}
                    formatChars={formatChars}
                    className="form-control"
                  />
                </div>
                {defaultSiteNumberFilter && defaultSiteNumberFilter.length > 1 && (
                  <div className="col-auto">
                    <select
                      className="form-select"
                      style={{ height: 50, marginRight: "-2px" }}
                      onChange={({ target: { value } }) => {
                        setSiteNumber("")
                        setSiteNumberFilter(value)
                      }}>
                      {defaultSiteNumberFilter.map((format, index) => {
                        return (
                          <option key={index} value={format?.filter}>
                            {format?.filter}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 mb-3 text-left">
              <label
                htmlFor="name"
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {t("Address")}*
              </label>
              <Address setAddressData={setAddressData} />
            </div>
          </div>
        </form>
      )}
    </div>
  )
}
