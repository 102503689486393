import React from "react"
import { useTranslation } from "react-i18next"

import DepotList from "../../components/DepotList/DepotList"
import Storage from "../../utils/storage"

import { usePermissionCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"

const Depots = () => {
  const { t } = useTranslation()
  const hasHLLInternPermission = usePermissionCheck("MinaSidor.SkapaRetur")
  const hasDepotPermission = usePermissionCheck("MinaSidor.DepåLäs")
  const depotStorage = Storage.get("depots", null, "local")

  const depots = depotStorage ? depotStorage : null

  if (!hasDepotPermission || hasHLLInternPermission) {
    return <PageNotFound noPermission={true} />
  }

  return (
    <div className="container-fluid container-fluid-max-width">
      <div className="row mb-5">
        <div className="col-12">
          <div className="header">
            <div className="header-body">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="header-pretitle">{t("My pages")}</h6>

                  <h1 className="header-title text-truncate">{t("Depots")}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {depots && depots.length > 0 && (
        <div className="row mb-4">
          <DepotList data={depots?.filter((depot) => depot?.address)} />
        </div>
      )}
    </div>
  )
}

export default Depots
