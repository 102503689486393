import React, { useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { newOrderRows, modalView, view, newOrderInformation } from "./Store"
import { useSetRecoilState, useRecoilState } from "recoil"
import ModalMachineSearch from "./Modal/ModalMachineSearch"
import { Accordion } from "react-bootstrap"
import Item from "./Common/Item"
import ActionFooter from "../Common/ActionFooter"
import ChangeDateForm from "./Form/ChangeDateForm"
import ModalSheet from "../Common/ModalSheet"
import { Tabs, Tab } from "react-bootstrap"
import {
  calculateOrderTotalPerRow,
  formatBodyForPost,
  previewItemMeta,
  checkMissingPrice
} from "./helpers"
import axios from "axios"
import ValidationInfo from "./ValidationInfo"
import NewOrderInformation from "./NewOrderInformation"
import NewOrderSummary from "./NewOrderSummary"
import ModalOrderCopies from "./Modal/ModalOrderCopies"
import Storage from "../../utils/storage"
import Toast from "../Common/Toast"
import { useNavigate } from "react-router-dom"
import CustomSvgIcon from "../Common/CustomSvgIcon"

export default function CreateOrder() {
  const userState = Storage.get("userState") ?? null
  const date = new Date()
  const { t } = useTranslation()
  const onUnMount = useRef()
  const [viewState, setViewState] = useRecoilState(view)
  const setModalViewState = useSetRecoilState(modalView)
  const itemIsPreview =
    viewState === "previewExistingOrder" || viewState === "previewNewOrder"
  const [activeTab, setActiveTab] = useState("newOrderRows")
  const [newOrderRowsState, setNewOrderRowsState] = useRecoilState(newOrderRows)
  const [newOrderInformationState, setNewOrderInformationState] =
    useRecoilState(newOrderInformation)
  const { creditLimit } = newOrderInformationState?.customer
  const [showModal, setShowModal] = useState(false)
  const [showModalSheet, setShowModalSheet] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [showCreditLimitInfo, setShowCreditLimitInfo] = useState(false)
  const [showOrderCopiesModal, setShowOrderCopiesModal] = useState(false)
  const [isMissingPrice, setIsMissingPrice] = useState(false)
  const navigate = useNavigate()

  const handleHideModal = () => {
    setShowModal(false)
  }

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleSave = async () => {
    setLoading(true)

    const body = {
      customerOrder: {
        customerOrderHead: {
          customerId: newOrderInformationState?.customer?.id,
          date: `${date.toLocaleDateString("sv-se")}T${date.toLocaleTimeString(
            "sv-se"
          )}Z`,
          siteId: newOrderInformationState?.project?.id,
          rentalContact: {
            id: newOrderInformationState?.reference?.id
          },
          pickupBy: newOrderInformationState?.pickupBy,
          branchId: userState.internal?.depot.id,
          insuranceChoice: newOrderInformationState.project?.insuranceOption
        },
        orderRows: formatBodyForPost(newOrderRowsState)
      },
      metaData: {
        useCustomerOrderTemplate: false,
        customerOrderTemplateId: ""
      }
    }

    await axios
      .post("/api/hll/customerOrderCreateRequest", {
        bypassSocialSecurityNumber:
          newOrderInformationState?.bypassSocialSecurityNumber,
        body
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          setData(response.data)

          if (response.data.requestResult === 1) {
            setShowModalSheet(true)
          } else {
            setShowModalSheet(false)
            setViewState("summary")
          }
        }
      })
      .catch((error) => {
        console.error(error)
        setShowModalSheet(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  onUnMount.current = () => {
    if (newOrderRowsState.length > 0) {
      const deleteFleetItemLock = async (id) => {
        await axios.post("/api/hll/deleteFleetItemLock", {
          id
        })
      }

      newOrderRowsState.forEach((row) => {
        if (row.internalType !== "INDIVIDUAL") {
          return
        }

        deleteFleetItemLock(row.id)
      })

      setNewOrderRowsState([])
    }

    setNewOrderInformationState({
      customer: null,
      project: null,
      reference: null,
      pickupBy: null,
      bypassSocialSecurityNumber: false
    })
  }

  useEffect(() => {
    return () => {
      onUnMount.current()
    }
  }, [])

  useEffect(() => {
    let newOrderTotal = 0

    newOrderRowsState.forEach((row) => {
      if (row.quantity === 0) {
        return
      }

      newOrderTotal += calculateOrderTotalPerRow(row)
    })

    setShowCreditLimitInfo(newOrderTotal > creditLimit?.value)
  }, [creditLimit, newOrderRowsState])

  useEffect(() => {
    if (creditLimit?.value > 0) {
      const timeoutID = setTimeout(() => {
        setShowModal(true)
      }, 750)
      return () => clearTimeout(timeoutID)
    }
  }, [creditLimit])

  useEffect(() => {
    const withoutPrice = newOrderRowsState.filter((row) => {
      return checkMissingPrice(row)
    })

    setIsMissingPrice(withoutPrice.length > 0)
  }, [newOrderRowsState])

  return (
    <div className="container-fluid container-fluid-max-width px-0 px-lg-2 has-action-footer">
      <Toast />
      {viewState !== "summary" && (
        <>
          <ModalMachineSearch
            show={showModal}
            handleHideModal={handleHideModal}
          />
          {creditLimit?.hasCreditLimit && creditLimit?.value <= 0 && (
            <div className="text-center p-4">
              <i
                className="fe fe-info d-block mb-2 text-primary"
                style={{ fontSize: 50 }}></i>
              <h3>{t("The customer lacks sufficient credit limit")}</h3>
            </div>
          )}
          {newOrderRowsState.length > 0 && (
            <Tabs
              justify
              fill
              id="uncontrolled-tab"
              activeKey={activeTab}
              onSelect={(key) => setActiveTab(key)}
              className="bg-white">
              <Tab
                eventKey="newOrderRows"
                tabClassName="w-100 bg-transparent"
                title={
                  <div className="d-flex align-items-center justify-content-center">
                    {t("New")}
                    {newOrderRowsState.length > 0 && (
                      <span className="ml-2 badge bg-light">
                        {newOrderRowsState.length}
                      </span>
                    )}
                  </div>
                }>
                <Accordion defaultActiveKey="0">
                  {newOrderInformationState.customer.creditLimit
                    ?.hasCreditLimit &&
                    newOrderInformationState.customer.creditLimit?.value <=
                      0 && (
                      <div className="text-center p-4">
                        <i
                          className="fe fe-info d-block mb-2 text-primary"
                          style={{ fontSize: 50 }}></i>
                        <h3>
                          {t("The customer lacks sufficient credit limit")}
                        </h3>
                      </div>
                    )}
                  {newOrderRowsState.length > 0 &&
                    newOrderRowsState.map((item, index) => {
                      let meta = [
                        item.reference.number,
                        `${item.internalPrice} kr`
                      ]

                      if (item.priceUnit) {
                        meta[1] += `/${item.priceUnit}`
                      }

                      if (itemIsPreview) {
                        meta = previewItemMeta(item)
                      }

                      return (
                        <Item
                          key={item.orderRowId}
                          index={index}
                          title={item.reference.itemName}
                          meta={meta}
                          context={itemIsPreview ? "preview" : "accordion"}
                          item={item}
                        />
                      )
                    })}
                </Accordion>
              </Tab>

              <Tab
                eventKey="orderInfo"
                tabClassName="w-100 bg-transparent"
                title={
                  <div className="d-flex align-items-center justify-content-center">
                    {t("Order information")}
                  </div>
                }>
                <div className="p-4">
                  <NewOrderInformation />
                </div>
              </Tab>
            </Tabs>
          )}
        </>
      )}

      {viewState === "summary" && (
        <>
          <ModalOrderCopies
            data={data}
            show={showOrderCopiesModal}
            setShowOrderCopiesModal={setShowOrderCopiesModal}
          />
          <NewOrderSummary data={data} />
        </>
      )}

      <ActionFooter>
        {showCreditLimitInfo && (
          <div className="badge bg-soft-danger d-block">
            {t("The customer lacks sufficient credit limit")}
          </div>
        )}

        {viewState === "createOrder" && !showCreditLimitInfo && (
          <>
            <button
              className={`border rounded text-primary bg-white p-0${
                creditLimit?.hasCreditLimit && creditLimit?.value <= 0
                  ? " btn-disabled"
                  : ""
              }`}
              style={{ fontSize: 20, width: 40, height: 40 }}
              onClick={() => {
                handleShowModal()
                setModalViewState("machineSearch")
              }}
              disabled={creditLimit?.hasCreditLimit && creditLimit?.value <= 0}>
              <CustomSvgIcon
                width={16}
                height={16}
                name="add"
                className={`mr-2`}
              />
            </button>
            <button
              disabled={
                newOrderRowsState.length === 0 ||
                (creditLimit?.hasCreditLimit && creditLimit?.value <= 0)
              }
              className={`border rounded text-primary bg-white p-0 ml-3${
                newOrderRowsState.length === 0 ||
                (creditLimit?.hasCreditLimit && creditLimit?.value <= 0)
                  ? " btn-disabled"
                  : ""
              }`}
              style={{ fontSize: 20, width: 40, height: 40 }}
              onClick={() => setShowModalSheet(true)}>
              <CustomSvgIcon width={16} height={16} name="calender" />
            </button>
          </>
        )}
        {viewState !== "createOrder" && (
          <button
            className="btn btn-outline-secondary mr-auto"
            disabled={loading}
            onClick={() => {
              if (viewState === "previewNewOrder") {
                setViewState("createOrder")
                setActiveTab("newOrderRows")
              } else {
                setShowOrderCopiesModal(true)
              }
            }}>
            {t(
              viewState === "previewNewOrder" ? "Back" : "Send extra order copy"
            )}
          </button>
        )}

        <button
          className="btn btn-primary ml-auto"
          disabled={
            newOrderRowsState.length === 0 ||
            loading ||
            showCreditLimitInfo ||
            isMissingPrice
          }
          onClick={() => {
            if (viewState === "createOrder") {
              setViewState("previewNewOrder")
            } else if (viewState === "previewNewOrder") {
              handleSave()
            } else {
              setViewState("createOrder")
              navigate("/order")
            }
          }}>
          {loading ? (
            <div
              className="spinner-grow spinner-grow-sm text-white position-relative"
              role="status"
              style={{ top: "-2px" }}>
              <span className="sr-only">Loading...</span>
            </div>
          ) : viewState === "createOrder" ? (
            t("Preview")
          ) : viewState === "summary" ? (
            t("Close")
          ) : (
            t("Save")
          )}
        </button>
      </ActionFooter>
      <ModalSheet show={showModalSheet}>
        {viewState === "createOrder" && (
          <ChangeDateForm setShowModalSheet={setShowModalSheet} />
        )}
        {viewState === "previewNewOrder" && (
          <ValidationInfo data={data} setShowModalSheet={setShowModalSheet} />
        )}
      </ModalSheet>
    </div>
  )
}
