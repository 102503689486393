import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
export default function MachineCategorySubMenuItem({
  id,
  name,
  slug,
  parentSlug,
  categories
}) {
  const { t } = useTranslation()
  const location = useLocation()
  let { pathname } = location
  const lastPathnameIndex = pathname.split("/").pop()

  let isActiveParent = lastPathnameIndex === slug

  if (pathname.includes(slug)) {
    isActiveParent = true
  }

  return (
    <>
      <div
        className={`machine-category-menu__item mb-1 pl-4 child${
          lastPathnameIndex === slug ? " text-primary" : ""
        }`}>
        <Link
          to={{
            pathname: `/${t("customer")}/${t("machines")}/${parentSlug}/${slug}`
          }}
          state={{ id: id, slug: slug, name: name }}
          className="d-block">
          {name}
        </Link>
      </div>

      <div className="machine-category-menu pl-4">
        {categories?.map((category) => {
          let visible = false
          let isActive = false
          let currentParentSlug = slug

          if (isActiveParent && category?.parent === id) {
            visible = true
          }

          if (pathname.includes(category?.slug) && category?.parent === id) {
            isActive = true
          }

          if (!visible) return null

          return (
            <div
              key={category?.id}
              className={`machine-category-menu__item mb-1 pl-4 child border-0${
                isActive ? " text-primary" : ""
              }`}>
              <Link
                to={{
                  pathname: `/${t("customer")}/${t(
                    "machines"
                  )}/${parentSlug}/${currentParentSlug}/${category?.slug}`
                }}
                state={{
                  id: category?.id,
                  slug: category?.slug,
                  name: category?.name
                }}
                className="d-block">
                {category?.name}
              </Link>
            </div>
          )
        })}
      </div>
    </>
  )
}
