import React from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { Accordion } from "react-bootstrap"
import Item from "./Item"
import { machineState, pageTypeState } from "../Store"
import { useBarcodeScanner } from "../../../utils/hooks"
import { replaceItemAtIndex } from "../../../utils/helpers"
import { useTranslation } from "react-i18next"
import toast from "react-hot-toast"

const Items = ({ emptyText, isEmpty }) => {
  const { t } = useTranslation()
  const [machines, setMachines] = useRecoilState(machineState)
  const pageType = useRecoilValue(pageTypeState)

  const handleScan = (data) => {
    let fleetItemNumber = ""

    if (data.startsWith("http")) {
      fleetItemNumber = new URLSearchParams(new URL(data).search).get("id")
    }

    updateMachines(fleetItemNumber)
  }

  useBarcodeScanner({ handleScan })

  const updateMachines = (fleetItemNumber) => {
    const findMachine = machines.find(
      (machine) => machine.fleetItemNumber === fleetItemNumber
    )

    if (!findMachine) {
      toast.error(t("Unable to find any machine"))
      return
    }

    const findIndex = machines.findIndex(
      (machine) => machine.fleetItemNumber === fleetItemNumber
    )

    const updatedMachines = replaceItemAtIndex(machines, findIndex, {
      ...findMachine,
      recivedAmount: 1,
      amountToAcknowledge: 1,
      checkedAsIncoming: true
    })

    setMachines(updatedMachines)
    toast.success(
      `${t("Added machine with fleetitem number")}: ${fleetItemNumber}`
    )
  }

  if (machines.length === 0 || (isEmpty && pageType === "incoming")) {
    return (
      <div className="text-center p-4">
        <i
          className="fe fe-info d-block mb-2 text-primary"
          style={{ fontSize: 50 }}></i>
        <h3>{emptyText}</h3>
      </div>
    )
  }

  return (
    <Accordion defaultActiveKey="0">
      {machines &&
        machines.map((machine) => {
          return <Item key={machine.id} item={machine} />
        })}
    </Accordion>
  )
}

export default Items
