import SkeletonLoader from "../../../Common/SkeletonLoader"

import { useMachineCategories } from "../Common/hooks"
import BreadCrumb from "./BreadCrumb"
import CategoryItem from "./CategoryItem"
import MenuItem from "./MenuItem"

export default function MachineCategories() {
  const { categories, isLoading } = useMachineCategories()

  return (
    <>
      <BreadCrumb />

      {isLoading && (
        <div className="container-fluid container-fluid-max-width mt-4">
          <div className="row">
            <aside className="col-12 col-md-3">
              <SkeletonLoader className="py-0 mb-2 mb-lg-0" />
            </aside>
            <main className="col-12 col-md-9">
              <SkeletonLoader className="py-0 mb-2 mb-lg-0" />
            </main>
          </div>
        </div>
      )}

      {!isLoading && (
        <div className="container-fluid container-fluid-max-width mt-0 mt-lg-4">
          <div className="row">
            <aside className="col-12 col-md-3 d-none d-md-block">
              {categories && categories?.length > 0 && (
                <div className="machine-category-menu">
                  {categories?.map((item) => {
                    const isVisible = item.parent === 0
                    return (
                      <MenuItem key={item.id} isVisible={isVisible} {...item} />
                    )
                  })}
                </div>
              )}
            </aside>
            <main className="col-12 col-md-9 mt-4 mt-md-0">
              {categories && categories?.length > 0 && (
                <div className="machine-category-grid">
                  {categories?.map((item) => {
                    const isVisible = item.parent === 0
                    return (
                      <CategoryItem
                        key={item.id}
                        isVisible={isVisible}
                        {...item}
                      />
                    )
                  })}
                </div>
              )}
            </main>
          </div>
        </div>
      )}
    </>
  )
}
