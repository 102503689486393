import React, { useEffect, useState } from "react"
import axios from "axios"
import Actions from "../Common/Actions"
import Loader from "../../Loader/Loader"
import Items from "../Common/Items"
import Storage from "../../../utils/storage"
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil"
import {
  machineState,
  incomingMachineState,
  pageTypeState,
  viewState
} from "../Store"
import Summary from "../Common/Summary"
import Toast from "../../Common/Toast"
import { useTranslation } from "react-i18next"
import { groupMachinesByBranch } from "../../../utils/helpers"
import uuid from "react-uuid"
import Search from "./Search"
import Confirmation from "../Common/Confirmation"

const List = () => {
  const [machines, setMachines] = useRecoilState(machineState)
  const setIncomingMachines = useSetRecoilState(incomingMachineState)
  const setPageType = useSetRecoilState(pageTypeState)
  const view = useRecoilValue(viewState)
  const [searchText, setSearchText] = useState("")
  const [isEmpty, setIsEmpty] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const userState = Storage.get("userState") ?? null
  const depotId = userState?.internal?.depot?.id ?? 0
  const { t } = useTranslation()

  const filterData = (value) => {
    const filteredData = machines.map((item) => {
      const isVisible = Object.keys(item).some((key) => {
        return [
          "newBranchName",
          "name",
          "fleetItemNumber",
          "articleNumber"
        ].includes(key)
          ? item[key]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          : false
      })

      return {
        ...item,
        isVisible: isVisible
      }
    })

    setIsEmpty(filteredData.filter((row) => row.isVisible).length === 0)

    return filteredData
  }

  const handleSearchChange = (value) => {
    setSearchText(value)
    setMachines(filterData(value))
  }

  useEffect(() => {
    let unmounted = false
    setLoading(true)
    setPageType("incoming")

    const request = async () => {
      await axios
        .post("/api/hll/equipmentTransferQuery", {
          limit: 1000,
          offset: 0,
          body: {
            toBranchId: {
              predicate: "Equal",
              value: depotId
            },
            active: {
              value: true
            }
          }
        })
        .then((response) => {
          if (!unmounted) {
            if (response.data.data && response.data.data.length > 0) {
              const data = response.data.data
              const groupedData = groupMachinesByBranch(data, "fromBranch.name")

              let filteredData = []
              let fleetItems = []
              let articles = []

              groupedData.forEach((branch) => {
                const items = branch.items

                items.forEach((item) => {
                  fleetItems.push(
                    item.fleetItemsToTransfer
                      .filter(
                        (machine) =>
                          !machine.InTransfer && machine.recivedAmount === 0
                      )
                      .map((machine) => {
                        machine.id = uuid()
                        machine.branchId = item.fromBranch.id
                        machine.branchName = item.fromBranch.name
                        machine.newBranchName = item.fromBranch.name
                        machine.quantity = 0
                        machine.defaultQuantity = 1
                        machine.checkedAsIncoming = false
                        machine.isVisible = true

                        return machine
                      })
                  )

                  articles.push(
                    item.articlesToTransfer
                      .filter(
                        (article) => article.recivedAmount < article.amount
                      )
                      .map((article) => {
                        article.id = uuid()
                        article.branchId = item.fromBranch.id
                        article.branchName = item.fromBranch.name
                        article.newBranchName = item.fromBranch.name
                        article.quantity = 0
                        article.defaultQuantity =
                          article.amount - article.recivedAmount
                        article.checkedAsIncoming = false
                        article.isVisible = true

                        return article
                      })
                  )
                })
              })

              filteredData = [].concat(...fleetItems, ...articles)

              setMachines(filteredData)
              setIncomingMachines(data)
            } else {
              setError(t("No incoming machines found."))
            }
          }
        })
        .catch((err) => {
          const correlationId = err?.response?.data?.CorrelationId
          console.error(correlationId)
          setError(`Something went wrong. Correlation ID: ${correlationId}`)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    request()

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depotId, setMachines, setIncomingMachines, setPageType, t])

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center my-3 flex-grow-1">
        <Loader />
      </div>
    )
  }

  if (error) {
    return (
      <div className="d-flex align-items-center justify-content-center my-3 flex-grow-1">
        {error}
      </div>
    )
  }

  return (
    <>
      <Toast />
      <div className="container-fluid container-fluid-max-width has-action-footer px-0 py-0 py-lg-2 px-lg-2">
        {view === "list" && (
          <>
            <Search
              onChangeHandler={handleSearchChange}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Items
              emptyText={t("No incoming machines found.")}
              isEmpty={isEmpty}
            />
          </>
        )}
        {view === "confirmation" && <Confirmation />}
        {view === "summary" && <Summary />}
        <Actions handleModalShow={false} />
      </div>
    </>
  )
}

export default List
