import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilState } from "recoil"
import { inventoryAdministrationCreate } from "../../../Store"
import ActionFooter from "../../../../Common/ActionFooter"
import Loader from "../../../../Loader/Loader"
import {
  internalRegionsAndDepotsGrouping,
  arraysEqual
} from "../../../../../utils/helpers"
import CustomSvgIcon from "../../../../Common/CustomSvgIcon"

export default function Filters({
  show,
  setShow,
  selectedFilters,
  setSelectedFilters,
  setBranchFilters
}) {
  const { t } = useTranslation()

  const [filterlist, setFilterList] = useState([])
  const [toggleIndex, setToggleIndex] = useState([])
  const [markedAll, setMarkedAll] = useState(false)

  const [
    inventoryAdministrationCreateState,
    setInventoryAdministrationCreateState
  ] = useRecoilState(inventoryAdministrationCreate)

  // Handle toggle
  const handleToggle = (id) => {
    if (toggleIndex.includes(id)) {
      setToggleIndex(toggleIndex.filter((index) => index !== id))
    } else {
      setToggleIndex((previousToggleIndex) => [...previousToggleIndex, id])
    }
  }

  // Handle change
  const handleChange = (e) => {
    const { id, name, value } = e.target

    setToggleIndex((previousToggleIndex) => [...previousToggleIndex, id])

    if (name === "internalRegion") {
      // -> This must be a internal company
      const exists = selectedFilters?.find(
        (filter) => filter?.regionId === value
      )

      if (exists) {
        setSelectedFilters(
          selectedFilters?.filter((filter) => filter?.regionId !== value)
        )
      } else {
        setSelectedFilters((previousSelectedFilters) => [
          ...previousSelectedFilters,
          ...inventoryAdministrationCreateState?.depots.filter(
            (depot) => depot?.regionId === value
          )
        ])
      }
    } else {
      // -> This must be a depot
      const exists = selectedFilters?.find((filter) => filter?.id === value)

      if (typeof exists === "undefined") {
        setSelectedFilters((previousSelectedFilters) => [
          ...previousSelectedFilters,
          ...inventoryAdministrationCreateState?.depots.filter(
            (depot) => depot?.id === value
          )
        ])
      } else {
        setSelectedFilters((previousSelectedFilters) => [
          ...previousSelectedFilters.filter((depot) => depot?.id !== value)
        ])
      }
    }
  }

  // Handle mark all
  const handleMarkAll = () => {
    setMarkedAll(!markedAll)
    setSelectedFilters(
      markedAll ? [] : inventoryAdministrationCreateState?.depots
    )
  }

  // Handle show/hide filters
  const handleHide = () => {
    setShow(!show)
    setFilterList([])
  }

  // Handle add button
  const handleAdd = () => {
    if (selectedFilters) {
      let branchFilters = []

      selectedFilters.map((filter) =>
        branchFilters.push({
          inventoryOccasionId: null,
          branchId: filter.id,
          branchName: filter.name,
          regionId:
            inventoryAdministrationCreateState?.depots.find(
              (depot) => depot?.id === filter?.id
            )?.regionId || null
        })
      )
      setInventoryAdministrationCreateState(
        (previousAdministrationCreateState) => {
          return {
            ...previousAdministrationCreateState,
            inventory: {
              ...previousAdministrationCreateState?.inventory,
              branchFilters: branchFilters
            }
          }
        }
      )

      setBranchFilters(branchFilters)
    }

    setShow(!show)
  }

  // Group internal companies and depots on mount
  useEffect(() => {
    const branchFilterList = internalRegionsAndDepotsGrouping(
      inventoryAdministrationCreateState?.internalRegions,
      inventoryAdministrationCreateState?.depots
    )

    // Set branchFilterList to recoil state
    setInventoryAdministrationCreateState((previousAdministrationEditState) => {
      return {
        ...previousAdministrationEditState,
        branchFilterList
      }
    })

    // Set branchFilterList to local state
    setFilterList(branchFilterList)
  }, [
    inventoryAdministrationCreateState?.internalRegions,
    inventoryAdministrationCreateState?.depots,
    setInventoryAdministrationCreateState
  ])

  // Set selected filters on mount
  useEffect(() => {
    if (
      inventoryAdministrationCreateState?.inventory?.branchFilters &&
      inventoryAdministrationCreateState?.inventory?.branchFilters.length > 0
    ) {
      // Clear selected filters
      setSelectedFilters([])

      // Set selected filters
      inventoryAdministrationCreateState?.inventory?.branchFilters.map(
        (filter) => {
          return setSelectedFilters((previousSelectedFilters) => [
            ...previousSelectedFilters,
            ...inventoryAdministrationCreateState?.depots.filter(
              (depot) => depot?.id === filter?.branchId
            )
          ])
        }
      )

      const currentCompanies =
        inventoryAdministrationCreateState?.inventory?.branchFilters.map(
          (filter) => {
            return filter?.regionId
          }
        )
      let uniqueIdArr = [...new Set(currentCompanies)]

      setToggleIndex(uniqueIdArr)
    }
  }, [
    inventoryAdministrationCreateState?.depots,
    inventoryAdministrationCreateState?.inventory?.branchFilters,
    setSelectedFilters
  ])

  useEffect(() => {
    setMarkedAll(
      arraysEqual(selectedFilters, inventoryAdministrationCreateState?.depots)
    )
  }, [inventoryAdministrationCreateState?.depots, selectedFilters])

  return (
    <>
      <nav
        className={`nav-off-canvas bg-white w-100 h-100 text-body position-fixed js-app-off-canvas${
          show ? " is-open" : ""
        }`}>
        <div className="nav-off-canvas-inside position-relative">
          <div className="nav-off-canvas-header d-flex justify-content-center align-items-center">
            <div>
              <h3 className="mb-0">
                {t("Choose")} {t("Regions and depots")}
              </h3>
            </div>
            <button
              onClick={() => {
                setShow(!show)
                setFilterList([])
              }}
              className="border-0 bg-white d-block close-nav-button text-body position-absolute"
              style={{ right: 0, marginRight: "7px" }}>
              <CustomSvgIcon
                name="close"
                width={16}
                height={16}
                className="text-black"
              />
            </button>
          </div>
        </div>

        {filterlist && filterlist.length === 0 && (
          <div className="nav-off-canvas-body position-relative">
            <Loader className="mt-4" />
          </div>
        )}

        {filterlist && filterlist.length > 0 && (
          <>
            <div className="nav-off-canvas-body position-relative">
              <div className="nav-off-canvas-nav-list m-0 my-4">
                {filterlist?.map((item) => {
                  const depots = item?.depots
                  let companyIsChecked = false
                  let isActiveCompany = false
                  const selectedCompany = selectedFilters.find(
                    (selectedFilter) =>
                      selectedFilter?.regionId === item?.regionId
                  )

                  if (typeof selectedCompany === "undefined") {
                    companyIsChecked = false
                    isActiveCompany = false
                  } else {
                    companyIsChecked = true
                    isActiveCompany = true
                  }

                  return (
                    <div key={item?.regionId}>
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="d-flex align-items-center">
                          <input
                            id={item?.regionId}
                            name="internalRegion"
                            type="checkbox"
                            value={item?.regionId}
                            checked={companyIsChecked}
                            className="form-check-input list-checkbox mt-0 flex-shrink-0"
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                          <span className="ml-2">{item?.regionName}</span>
                        </label>
                        <button
                          className="bg-transparent"
                          onClick={() => handleToggle(item?.regionId)}>
                          {isActiveCompany ? (
                            <CustomSvgIcon
                              name="chevron-up"
                              width={16}
                              height={16}
                            />
                          ) : (
                            <CustomSvgIcon
                              name="chevron-down"
                              width={16}
                              height={16}
                            />
                          )}
                        </button>
                      </div>
                      {depots &&
                        depots?.length > 0 &&
                        depots?.map((depot) => {
                          let depotIsChecked = false
                          const selectedDepot = selectedFilters.find(
                            (selectedFilter) => selectedFilter?.id === depot?.id
                          )

                          if (typeof selectedDepot !== "undefined") {
                            depotIsChecked = true
                          } else {
                            depotIsChecked = false
                          }

                          if (!toggleIndex.includes(item?.regionId)) return null

                          return (
                            <div key={depot?.id} className="ml-3">
                              <label className="d-flex align-items-center my-1">
                                <input
                                  id={item?.regionId}
                                  name="depot"
                                  type="checkbox"
                                  value={depot?.id}
                                  checked={depotIsChecked}
                                  className="form-check-input list-checkbox mt-0 flex-shrink-0"
                                  onChange={(e) => {
                                    handleChange(e)
                                  }}
                                />
                                <span className="ml-2">{depot?.name}</span>
                              </label>
                            </div>
                          )
                        })}
                    </div>
                  )
                })}
              </div>
            </div>
            <ActionFooter className="d-flex align-items-center justify-content-center">
              <button
                className="btn btn-outline-secondary d-block mr-3"
                onClick={() => handleHide()}>
                {t("Cancel")}
              </button>

              <button
                className="btn btn-outline-primary d-block mr-3"
                onClick={() => handleMarkAll()}>
                {markedAll ? t("Uncheck all") : t("Mark all")}
              </button>
              <button
                className="btn btn-primary d-block"
                onClick={() => handleAdd()}
                disabled={selectedFilters?.length === 0}>
                {t("Add")}
              </button>
            </ActionFooter>
          </>
        )}
      </nav>
    </>
  )
}
