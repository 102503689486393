import React, { useEffect } from "react"
import MachineItemDataRow from "./MachineItemDataRow"
import { useTranslation } from "react-i18next"
import {
  availabilityColorMap,
  availabilityName,
  dateAndTimeNow
} from "../../../utils/helpers"
import Storage from "../../../utils/storage"
import useMachineContext from "../../../context/Machine/Store"
import { useNavigate } from "react-router-dom"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const MachineSummary = ({
  currentAvailability,
  currentBranchName,
  setSummary,
  summary: { name, number, availability, branchName, hasError, errorMessage }
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userState = Storage.get("userState") ?? null
  const depot = userState?.internal?.depot?.name ?? ""
  const { setSearchData } = useMachineContext()

  const handleGoBackToSearch = () => {
    // Reset search data
    setSearchData({
      searchText: "",
      offset: 0,
      currentPage: 0,
      data: [],
      pageInfo: { total: 0 }
    })

    // Navigate to search page
    navigate(`/${t("machines")}`)
  }

  useEffect(() => {
    if (!hasError) {
      window.dataLayer.push({
        event: "HLL_machine_status_change",
        itemName: name,
        itemNumber: number,
        availability,
        depot,
        createdAt: dateAndTimeNow(),
        userType: "internal"
      })
    }
  }, [availability, depot, hasError, name, number])

  return (
    <div className="container-fluid container-fluid-max-width">
      <div className="row">
        <div className="col-12">
          <h1 className="text-center mb-5 d-flex flex-column">
            <span
              className={`fe fe-${
                hasError ? "info" : "check-circle"
              } text-primary mb-3`}
              style={{ fontSize: "50px" }}></span>
            <span>
              {hasError ? t("Something went wrong") : t("Change implemented")}
            </span>
          </h1>

          {hasError ? (
            <div className="border rounded p-4 bg-white">
              <p className="text-uppercase font-xs pb-0 mb-1">{t("Message")}</p>
              <p className="text-muted mb-0 pb-0">{errorMessage}</p>
            </div>
          ) : (
            <div className="border rounded">
              <MachineItemDataRow
                wrapperClass="bg-white p-4 border-bottom rounded-top"
                label={t("Individual number")}
                value={number}
                valueClass="text-muted mb-0"
              />
              <MachineItemDataRow
                wrapperClass="bg-white p-4 border-bottom"
                label={t("Name")}
                value={name}
                valueClass="text-muted mb-0"
              />
              {currentAvailability !== availability && (
                <MachineItemDataRow
                  wrapperClass={`bg-white p-4 ${
                    currentBranchName !== branchName
                      ? "border-bottom"
                      : "rounded-bottom"
                  }`}
                  label={t("Availability")}>
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <span
                        className={`machine-item-availability mr-2 availability--${availabilityColorMap(
                          currentAvailability
                        )}`}></span>
                      <span className="text-muted">
                        {availabilityName(currentAvailability)}
                      </span>
                    </div>

                    <CustomSvgIcon
                      width={16}
                      height={16}
                      name="arrow-right"
                      className="mx-2"
                    />
                    <div className="d-flex align-items-center">
                      <span
                        className={`machine-item-availability mr-2 availability--${availabilityColorMap(
                          availability
                        )}`}></span>
                      <span className="text-muted">
                        {availabilityName(availability)}
                      </span>
                    </div>
                  </div>
                </MachineItemDataRow>
              )}
              {currentBranchName !== branchName && (
                <MachineItemDataRow
                  wrapperClass="bg-white p-4 rounded-bottom"
                  label={t("Depot change")}>
                  <div className="d-flex">
                    <span className="text-muted">{currentBranchName}</span>
                    <CustomSvgIcon
                      width={16}
                      height={16}
                      name="arrow-right"
                      className="mx-2"
                    />
                    <span className="text-muted">{branchName}</span>
                  </div>
                </MachineItemDataRow>
              )}
            </div>
          )}

          <div className="mt-4 text-center">
            {hasError ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  setSummary(null)
                }}>
                {t("Back to the machine")}
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={handleGoBackToSearch}>
                {t("Search new machine")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MachineSummary
