import React, { Fragment, useState } from "react"
import axios from "axios"
import { useTranslation } from "react-i18next"
import Alert from "../../components/Common/Alert"
import Modal from "./Modal"
import Storage from "../../utils/storage"

import CustomSvgIcon from "../../components/Common/CustomSvgIcon"

import { setUserSettings } from "../../utils/hooks"
import { Link } from "react-router-dom"

const DepotCard = () => {
  const { t } = useTranslation()

  const userState = Storage.get("userState") ?? null
  const depotStorage = Storage.get("depots", null, "local")

  const [depot, setDepot] = useState(userState?.depot ?? false)
  const [openingHours, setOpeningHours] = useState(
    userState?.openingHours ?? false
  )

  const depots = depotStorage ? depotStorage : null

  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [openModal, setOpenModal] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
    setOpenModal(true)
  }

  const handleChangeDepot = async (e) => {
    const depot = e.target.value
    const parsedDepot = JSON.parse(depot)

    setDepot(parsedDepot)
    setLoading(true)

    await axios
      .post("/api/hll/openingHours", {
        id: parsedDepot?.id
      })
      .then((response) => {
        setOpeningHours(response.data)

        // Set to userSettings
        setUserSettings({
          ...userState,
          depot: parsedDepot,
          openingHours: response?.data
        })

        Storage.set(
          "userState",
          {
            ...userState,
            depot: parsedDepot,
            openingHours: response?.data
          },
          "local"
        )
      })
      .catch((err) => {
        console.log(err)
        setOpeningHours(false)
        setErrorMessage(
          `${t("Can't find any opening hours for selected depot.")}`
        )
        setError(true)
      })
      .finally(() => {
        setShow(false)
        setLoading(false)
      })
  }

  const removeDepot = (e) => {
    e.preventDefault()
    setUserSettings({
      ...userState,
      depot: null,
      openingHours: null
    })

    Storage.set(
      "userState",
      {
        ...userState,
        depot: null,
        openingHours: null
      },
      "local"
    )

    setDepot(null)
    setOpeningHours(null)
    setError(false)
    setErrorMessage("")
  }

  const coordinatesToUrl = (coordinates) => {
    return `${coordinates.lat},${coordinates.long}`
  }

  return (
    <Fragment>
      <div className="card h-100 depot-card">
        <div className="card-header">
          <h2 className="card-header-title">
            {depot ? `${t("My depot")}: ${depot.name}` : t("Depot")}
          </h2>

          {depot && (
            <a href="#!" className="small" onClick={removeDepot}>
              {t("Remove depot")}
            </a>
          )}
        </div>

        {!depot && !loading && !error && (
          <div className="d-flex align-items-center justify-content-center my-3 flex-grow-1">
            <button className="btn btn-secondary" onClick={handleShow}>
              {t("Choose your depot")}
            </button>
          </div>
        )}

        {depot && !loading && (
          <div className="card-body pb-0">
            <div className="row">
              <div className="col-12 col-lg-auto">
                {depot?.address && (
                  <>
                    <h2 className="card-header-title">{depot?.name}</h2>
                    <span className="d-block">
                      {depot?.address?.adressLine1}
                    </span>
                    <span className="d-block">
                      {`${depot?.address?.postalCode} ${depot?.address?.city}`}
                    </span>
                    {depot?.phone && (
                      <span className="d-block">
                        <a href={`tel:${depot.phone}`}>{depot.phone}</a>
                      </span>
                    )}
                    {depot?.email && (
                      <span className="d-block">
                        <a href={`mailto:${depot.email}`}>{depot.email}</a>
                      </span>
                    )}
                  </>
                )}
                {openingHours && (
                  <h2 className="card-header-title mt-4">
                    {t("Opening hours")}
                  </h2>
                )}
                {openingHours &&
                  openingHours.map((item, index) => (
                    <span
                      key={index}
                      className="d-block">{`${item.days}: ${item.hours}`}</span>
                  ))}

                {depot?.address?.coordinates && (
                  <Link
                    to={`https://www.google.com/maps/place/${coordinatesToUrl(
                      depot?.address?.coordinates
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block mt-3 text-primary">
                    <div className="d-flex align-items-center">
                      {t("Go to map")}
                      <CustomSvgIcon
                        className="ml-2"
                        name="arrow-right"
                        width={16}
                        height={16}
                      />
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}

        {error && (
          <Alert
            className="text-center my-4"
            message={
              errorMessage
                ? `${errorMessage}`
                : t("Something went wrong, please refresh current page.")
            }
          />
        )}
      </div>

      {openModal && (
        <Modal
          depots={depots}
          handleChangeDepot={handleChangeDepot}
          handleClose={handleClose}
          show={show}
          loading={loading}
          error={error}
          errorMessage={errorMessage}
        />
      )}
    </Fragment>
  )
}

export default DepotCard
