import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const useReturnRoute = () => {
  const { pathname } = useLocation()

  const isReturnRoute =
    pathname === "/return" ||
    pathname === "/returer" ||
    pathname.includes("/returer/") ||
    pathname.includes("/returer/")

  return isReturnRoute
}

export const useMachineRoute = () => {
  const { pathname } = useLocation()

  if (pathname.includes("kund") || pathname.includes("customer")) {
    return false
  }

  const isMachineRoute =
    pathname === "/maskiner" ||
    pathname === "/machines" ||
    pathname.includes("/maskiner/") ||
    pathname.includes("/machines/")

  return isMachineRoute
}

export const useCustomerMachineRoute = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  if (!pathname.includes("kund") || !pathname.includes("customer")) {
    return false
  }

  const isCustomerMachineRoute =
    pathname.includes(`${t("customer")}/${t("machines")}`) ||
    pathname.includes(`${t("customer")}/${t("machine")}`)

  return isCustomerMachineRoute
}

export const useOrderRoute = () => {
  const { pathname } = useLocation()

  const isOrderRoute = pathname === "/order" || pathname.includes("/order")
  return isOrderRoute
}

export const useInventoryRoute = () => {
  const { pathname } = useLocation()

  const isInventoryRoute =
    pathname === "/inventory" ||
    pathname === "/inventering" ||
    pathname.includes("/inventory/") ||
    pathname.includes("/inventering/")

  return isInventoryRoute
}

export const useMaintenanceRoute = () => {
  const { pathname } = useLocation()

  const isMaintenanceRoute = pathname.includes("maintenance")

  return isMaintenanceRoute
}
