import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { encode } from "base-64"
import { useOidcUser } from "@axa-fr/react-oidc"
import axios from "axios"
import toast from "react-hot-toast"
import { useSetRecoilState } from "recoil"

import { machineLists } from "../../MachineLists/Store"
import Storage from "../../../../utils/storage"
import { useMachineCategories } from "../Common/hooks"
import { getMachineCategories, getMachines } from "../../../../utils/hooks"
import SkeletonLoader from "../../../Common/SkeletonLoader"

import BreadCrumb from "./BreadCrumb"

import MenuItem from "./MenuItem"
import CategoryItem from "./CategoryItem"
import ProductItem from "./ProductItem"
import MobileNavigation from "./MobileNavigation"

import { dateAndTimeNow } from "../../../../utils/helpers"

import AddToMachineListModal from "./Modal/AddToMachineList"

export default function Category() {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()
  const location = useLocation()
  let { pathname } = location

  const currentPath = pathname.split("/").pop()
  const { categories: machineCategories, isLoading } = useMachineCategories()
  const customer = Storage.get("customer", null, "local") ?? null

  //Recoil states
  const setCustomerMachineListState = useSetRecoilState(machineLists)

  // States
  const [customerListState, setCustomerListState] = useState(null)
  const [isAddingToList, setIsAddingToList] = useState(false)
  const [showMobileNavigation, setShowMobileNavigation] = useState(false)
  const [loadMachines, setLoadMachines] = useState(false)
  const [machineState, setMachineState] = useState({
    data: null,
    categories: null,
    products: null
  })

  const [showMachineListModal, setShowMachineListModal] = useState(false)
  const [createNewMachinelist, setCreateNewMachinelist] = useState(false)
  const [selectedMachineList, setSelectedMachineList] = useState(null)
  const [machineGroupDataState, setMachineGroupDataState] = useState(null)

  const handleSaveToMachineList = async (listId, machineGroupData) => {
    let body = []
    // Get current machine group data
    const tempAccessories = machineGroupData?.acf?.accessories ?? []
    const tempServices = machineGroupData?.acf?.services ?? []

    // Set quantity to selected accessories and services
    tempAccessories.map((item) => {
      if (item?.selected) {
        item.quantity = item?.amount
      } else {
        item.quantity = 0
      }
      return item
    })

    tempServices.map((item) => {
      if (item?.selected) {
        item.quantity = item?.amount
      } else {
        item.quantity = 0
      }
      return item
    })

    const currentMachineGroup = machineGroupData
    currentMachineGroup.accessories = tempAccessories
    currentMachineGroup.services = tempServices

    const currentList = customerListState.find((item) => item?.id === listId)
    const existingMachineGroup = currentList?.data?.machines.find(
      (item) => item?.id === currentMachineGroup?.id
    )

    if (existingMachineGroup !== undefined) {
      //Updating quantity to existing model
      currentMachineGroup.quantity = existingMachineGroup.quantity + 1
      body = {
        ...currentList?.data,
        machines: currentList?.data?.machines.map((item) =>
          item?.id === currentMachineGroup?.id ? currentMachineGroup : item
        )
      }
    } else {
      //Add quantity to current model
      currentMachineGroup.quantity = 1
      body = {
        ...currentList?.data,
        machines: [...currentList?.data?.machines, currentMachineGroup]
      }
    }

    setIsAddingToList(true)

    await axios
      .put(`/api/database/customer/updateMachineList/${listId}`, {
        data: body
      })
      .then(
        (res) => {
          toast.success(`${t("Machine added to machine list")} ${body?.name}`)
          setCustomerMachineListState((prevState) => ({
            ...prevState,
            lists: prevState.lists.map((list) =>
              list.id === listId ? res.data?.rows : list
            )
          }))

          // Adding machine to machine list analytics event
          window.dataLayer.push({
            event: `HLL_adding_machine_to_machine_list`,
            company: customer?.name,
            createdAt: dateAndTimeNow(),
            userType: "customer"
          })
        },
        (err) => {
          console.log(err, "PUT /api/database/customer/updateMachineList/:id")
          toast.error(t("Something went wrong"))
        }
      )
      .finally(() => {
        setIsAddingToList(false)
        setShowMachineListModal(false)
        setCreateNewMachinelist(false)
        setSelectedMachineList(null)
        setMachineGroupDataState(null)
      })
  }

  // Fetch machine category
  const { refetch } = useQuery({
    queryKey: ["machineCategory"],
    queryFn: async () => {
      let INIT_PAGE = 1
      const INIT_PER_PAGE = 100
      setLoadMachines(false)

      const categoryParams = {
        _fields: "id,name,slug,count,acf.image_url,parent",
        hide_empty: true,
        slug: currentPath
      }
      const machineCategory = await getMachineCategories(categoryParams)
      const machineCategoryId = machineCategory?.categories[0]?.id

      const machineSubcategories = await getMachineCategories({
        _fields: "id,name,slug,count,acf.image_url,parent,description",
        per_page: INIT_PER_PAGE,
        page: INIT_PAGE,
        hide_empty: true,
        parent: machineCategoryId
      })

      if (machineSubcategories?.categories?.length === 0) {
        setLoadMachines(true)
      }

      setMachineState((prevState) => ({
        ...prevState,
        data: machineCategory?.categories[0] ?? null,
        categories: machineSubcategories?.categories ?? null
      }))

      if (machineSubcategories?.meta.totalPages > INIT_PAGE) {
        const nextPage = INIT_PAGE + 1
        const nextParams = { ...categoryParams, page: nextPage }
        const nextMachines = await getMachineCategories(nextParams)
        setMachineState((prevState) => ({
          ...prevState,
          categories: [...machineSubcategories?.categories, ...nextMachines]
        }))
        return nextMachines
      } else {
        return machineSubcategories
      }
    },
    enabled: machineCategories?.length > 0,
    refetchOnWindowFocus: false,
    cacheTime: 60000 * 60 // 1 hour,
  })

  // Fetch machines when loadMachines is true
  useQuery({
    queryKey: ["machines"],
    queryFn: async () => {
      let INIT_PAGE = 1
      const INIT_PER_PAGE = 100

      const machineParams = {
        _fields: "id,title,slug,acf",
        per_page: INIT_PER_PAGE,
        page: INIT_PAGE,
        product_cat: machineState?.data?.id
      }

      const machines = await getMachines(machineParams)

      setMachineState((prevState) => ({
        ...prevState,
        products: machines ?? null
      }))

      if (machines?.meta?.totalPages > INIT_PAGE) {
        const nextPage = INIT_PAGE + 1
        const nextParams = { ...machineParams, page: nextPage }
        const nextMachines = await getMachines(nextParams)
        setMachineState((prevState) => ({
          ...prevState,
          products: [...machines, ...nextMachines]
        }))
        return nextMachines
      } else {
        return machines
      }
    },

    enabled: loadMachines,
    refetchOnWindowFocus: false,
    cacheTime: 60000 * 60 // 1 hour,
  })

  // refetch when pathname changes
  useEffect(() => {
    setMachineState({
      data: null,
      categories: null,
      products: null
    })
    refetch()
  }, [pathname, refetch])

  // fetch customer machine lists
  const { refetch: customerMachineListRefresh } = useQuery({
    queryKey: ["customerMachineLists"],
    queryFn: async () => {
      const res = await axios.post("/api/database/customer/machineLists", {
        customerId: customer?.id,
        tid: encode(oidcUser?.tid),
        sub: encode(oidcUser?.sub)
      })
      const { data } = res

      setCustomerListState(data?.rows)
      return data
    },
    enabled:
      !!machineState?.products?.products && !!oidcUser?.tid && !!oidcUser?.sub,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true
  })

  const { id } = machineState?.data ?? {}
  const categories = machineState?.categories ?? []
  const products = machineState?.products?.products ?? []

  return (
    <>
      <AddToMachineListModal
        loading={isAddingToList}
        customerMachineListRefresh={customerMachineListRefresh}
        createNewMachinelist={createNewMachinelist}
        setCreateNewMachinelist={setCreateNewMachinelist}
        selectedMachineList={selectedMachineList}
        setSelectedMachineList={setSelectedMachineList}
        customerMachineLists={customerListState}
        show={showMachineListModal}
        setShow={setShowMachineListModal}
        handleSaveToMachineList={handleSaveToMachineList}
        setMachineGroupDataState={setMachineGroupDataState}
        machineGroupDataState={machineGroupDataState}
      />
      <BreadCrumb machineGroupData={machineState?.data} />

      {isLoading && (
        <div className="container-fluid container-fluid-max-width mt-4">
          <div className="row">
            <aside className="col-12 col-md-3">
              <SkeletonLoader className="py-0 mb-2 mb-lg-0" />
            </aside>
            <main className="col-12 col-md-9">
              <SkeletonLoader className="py-0 mb-2 mb-lg-0" />
            </main>
          </div>
        </div>
      )}

      {!isLoading && (
        <div className="container-fluid container-fluid-max-width mt-4">
          <div className="row">
            <div className="col-12 d-block d-lg-none">
              <MobileNavigation
                showMobileNavigation={showMobileNavigation}
                setShowMobileNavigation={setShowMobileNavigation}
                categories={machineCategories}
                currentPath={currentPath}
              />
            </div>
            <aside className="col-12 col-md-3 d-none d-lg-block">
              {machineCategories && machineCategories?.length > 0 && (
                <div className="machine-category-menu">
                  {machineCategories?.map((item) => {
                    const isVisible = item.parent === 0
                    if (!isVisible) return null
                    return (
                      <MenuItem
                        key={item.id}
                        {...item}
                        currentPath={currentPath}
                        categories={machineCategories}
                      />
                    )
                  })}
                </div>
              )}
            </aside>
            <main className="col-12 col-lg-9">
              {products?.length === 0 &&
                categories &&
                categories?.length > 0 && (
                  <div className="machine-category-grid is-sub-categories">
                    {categories?.map((item) => {
                      let isVisible = item.parent === id
                      if (!isVisible) return null
                      return (
                        <CategoryItem
                          key={item.id}
                          {...item}
                          categories={categories}
                        />
                      )
                    })}
                  </div>
                )}
              {products && products?.length > 0 && (
                <div className="machine-category-grid">
                  {products?.map((item) => {
                    return (
                      <ProductItem
                        key={item.id}
                        itemData={item}
                        {...item}
                        isAddingToList={isAddingToList}
                        customerListState={customerListState}
                        customerMachineListRefresh={customerMachineListRefresh}
                        handleSaveToMachineList={handleSaveToMachineList}
                        setShowMachineListModal={setShowMachineListModal}
                        showMachineListModal={showMachineListModal}
                        createNewMachinelist={createNewMachinelist}
                        setCreateNewMachinelist={setCreateNewMachinelist}
                        selectedMachineList={selectedMachineList}
                        setSelectedMachineList={setSelectedMachineList}
                        setMachineGroupDataState={setMachineGroupDataState}
                      />
                    )
                  })}
                </div>
              )}
            </main>
          </div>
        </div>
      )}
    </>
  )
}
