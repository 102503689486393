import { useState, useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import axios from "axios"
import { useOidcUser } from "@axa-fr/react-oidc"
import { encode } from "base-64"

import Storage from "../../../../../utils/storage"
import CustomSvgIcon from "../../../../Common/CustomSvgIcon"
import toast from "react-hot-toast"
import { dateAndTimeNow } from "../../../../../utils/helpers"

export default function CreateList({
  title,
  show,
  setMachineListState,
  handleCloseCreateNew,
  setShowModal,
  customerMachineListRefresh,
  machineGroupDataState
}) {
  const { t } = useTranslation()
  const nameRef = useRef(null)
  const privateRef = useRef(null)
  const customer = Storage.get("customer", null, "local") ?? null
  const { oidcUser } = useOidcUser()
  const identifier = `${encode(oidcUser?.tid)}${encode(oidcUser?.sub)}`

  const [loading, setLoading] = useState(false)
  const [listName, setListName] = useState("")
  const [listPrivate, setListPrivate] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const onChangeName = (e) => {
    setListName(e.target.value)

    if (e.target.value !== "" && e.target.value.length >= 3) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }

  const onChangePrivate = (e) => {
    setListPrivate(e.target.checked)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const nameValue = nameRef.current.value
    const privateValue = privateRef.current.checked

    // Get current machine group data
    const tempAccessories = machineGroupDataState?.acf?.accessories ?? []
    const tempServices = machineGroupDataState?.acf?.services ?? []

    // Set quantity to selected accessories and services
    tempAccessories.map((item) => {
      if (item?.selected) {
        item.quantity = item?.amount
      } else {
        item.quantity = 0
      }
      return item
    })

    tempServices.map((item) => {
      if (item?.selected) {
        item.quantity = item?.amount
      } else {
        item.quantity = 0
      }
      return item
    })

    const currentMachineGroup = machineGroupDataState ?? []
    currentMachineGroup.quantity = 1

    currentMachineGroup.accessories = tempAccessories
    currentMachineGroup.services = tempServices

    await axios
      .post("/api/database/customer/createMachineList", {
        customerId: customer?.id,
        body: {
          name: nameValue,
          customerId: customer?.id,
          userid: identifier,
          private: privateValue,
          machines: [currentMachineGroup]
        }
      })
      .then((res) => {
        const tempData = res.data?.rows[0]

        setMachineListState((prevState) => ({
          ...prevState,
          lists: [tempData, ...prevState.lists]
        }))
        toast.success(t("List created successfully"))

        // Create machine list event
        window.dataLayer.push({
          event: `HLL_create_machine_list`,
          company: customer?.name,
          createdAt: dateAndTimeNow(),
          userType: "customer"
        })

        handleCloseCreateNew()
        setShowModal(false)

        // Adding machine to machine list analytics event
        window.dataLayer.push({
          event: `HLL_adding_machine_to_machine_list`,
          company: customer?.name,
          createdAt: dateAndTimeNow(),
          userType: "customer"
        })
        toast.success(`${t("Machine added to machine list")} ${nameValue}`)
      })
      .catch((err) => {
        console.log(err, "err")
        toast.error(t("Something went wrong, try again."))
      })
      .finally(() => {
        setLoading(false)
        customerMachineListRefresh()
      })
  }

  // autofocus inpout field
  useEffect(() => {
    if (show && nameRef.current) {
      nameRef.current.focus()
    }
  }, [show])

  return (
    <Modal show={show} centered animation={false} onHide={handleCloseCreateNew}>
      <Modal.Header className="d-block border-bottom">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="header-title mb-0 text-left">{title}</h3>
          <button
            disabled={loading}
            onClick={handleCloseCreateNew}
            className="position-relative bg-transparent p-0 text-body"
            style={{
              top: "3px"
            }}>
            <CustomSvgIcon
              name="close"
              width={16}
              height={16}
              className="text-black"
            />
          </button>
        </div>
      </Modal.Header>
      <form onSubmit={onSubmit}>
        <Modal.Body className="text-center py-3 px-4 bg-white is-scrollable mx-0">
          <div className="row">
            <div className="col-12">
              <div className="form-group text-left m-0">
                <label
                  htmlFor="listName"
                  className="field__label form-label mt-1 mb-1 text-uppercase">
                  {t("Name")}
                  {" *"}
                </label>
                <input
                  ref={nameRef}
                  disabled={loading}
                  type="text"
                  id="name"
                  name="name"
                  value={listName}
                  onChange={onChangeName}
                  className={`form-control form-control-modal rounded-0`}
                />
                <span className="text-muted font-xs mt-2">
                  {t("Min 3 characters")}
                </span>
              </div>
            </div>
            <div className="col-12 my-3">
              <div className="d-flex align-items-center justify-content-start">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="private"
                    id="private"
                    checked={listPrivate}
                    ref={privateRef}
                    disabled={!isValid || loading}
                    onChange={(e) => onChangePrivate(e)}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-start m-0">
                  <CustomSvgIcon
                    name="lock"
                    width={12}
                    height={12}
                    className="text-primary"
                  />
                  <span className="d-block text-muted font-xs ml-2">
                    {t("Make the list private")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-block border-top py-3 px-4">
          <div className="d-flex align-items-center justify-content-center">
            <button
              disabled={loading}
              onClick={handleCloseCreateNew}
              className={`btn btn-outline-secondary d-block mr-3 w-100 p-3`}>
              {t("Close")}
            </button>
            <button
              type="submit"
              className={`btn btn-primary d-block w-100 p-3`}
              disabled={!isValid || loading}>
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm text-white"
                  role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                t("Save")
              )}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
