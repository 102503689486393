import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import sv from "date-fns/locale/sv"
import moment from "moment"
import "moment-timezone"
import CustomSvgIcon from "../Common/CustomSvgIcon"

registerLocale("sv", sv)

const RentalDateModal = ({ open, onHideHandler, onSaveHandler }) => {
  const { t } = useTranslation()
  const days = (type, nr = 7) => moment()[type](nr, "d").toDate()
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [startDate, setStartDate] = useState(moment().toDate())

  return (
    <Modal
      centered
      animation={false}
      show={open}
      backdrop="static"
      fullscreen="true"
      keyboard={false}
      className="modal-scrollable">
      <Modal.Header className="d-block p-3">
        <div className="position-relative">
          <h3 className="header-title mt-3 mb-0 text-center">
            {t("Change return date for all orders")}
          </h3>

          <button
            onClick={onHideHandler}
            className="bg-transparent position-absolute p-0 text-body"
            style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
            <CustomSvgIcon
              name="close"
              width={16}
              height={16}
              className="text-black"
            />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="p-3 bg-white is-scrollable mx-0">
        <label
          htmlFor="date"
          className="field__label d-block form-label mb-1 text-uppercase">
          {t("Date")}
        </label>
        <div className="position-relative">
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date)
              setDatePickerOpen(false)
            }}
            dateFormat="yyyy-MM-dd"
            minDate={days("subtract")}
            maxDate={days("add")}
            locale="sv"
            onClickOutside={() => setDatePickerOpen(false)}
            onFocus={() => setDatePickerOpen(true)}
            open={datePickerOpen}
            readOnly={true}
          />
          <CustomSvgIcon
            width={16}
            height={16}
            name="calender"
            className={`text-muted pointer-none`}
            style={{
              position: "absolute",
              right: 20,
              top: 12
            }}
          />
        </div>
        <p className="text-muted mt-3">
          {t("Please note that any changed dates will be overwritten")}
        </p>
      </Modal.Body>
      <Modal.Footer className="mt-auto p-3">
        <button
          className="btn btn-primary w-100"
          onClick={() => {
            onSaveHandler(startDate)
          }}>
          {t("Save")}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default RentalDateModal
