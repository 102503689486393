import React, { useEffect, useState } from "react"
import { useOidcUser, useOidc } from "@axa-fr/react-oidc"
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Logo from "../../assets/images/HLL_notext.svg"
import Storage from "../../utils/storage"
import InternalVerificationModalWrapper from "../../components/InternalVerificationModal/ModalWrapper"
import NavAccount from "./NavAccount"
import Search from "./Search"
import MachinesHeader from "../Machines/Header"
import ReturnOrderHeader from "../ReturnOrder/Header"
import { usePermissionCheck, useEnviromentCheck } from "../../utils/hooks"

import PushNotifications from "../PushNotifications/PushNotifications"
import HeaderTitle from "./HeaderTitle"
import OrderHeader from "../Order/OrderHeader"
import InventoryHeader from "../Inventory/InventoryHeader"

import { useOidcIdToken } from "@axa-fr/react-oidc"

import {
  useReturnRoute,
  useMachineRoute,
  useOrderRoute,
  useInventoryRoute,
  useMaintenanceRoute
} from "../../Router/hooks"

const Header = ({
  customer,
  setCustomer,
  showClientCompanyModal,
  setShowClientCompanyModal,
  setSwitchClientAccounts,
  userSettingsReady
}) => {
  const { t } = useTranslation()
  const { idToken } = useOidcIdToken()
  const { oidcUser } = useOidcUser()
  const { logout } = useOidc()
  const userState = Storage.get("userState") ?? null
  const { pathname } = useLocation()
  const [showInternalRegionModal, setShowInternalRegionModal] = useState(false)
  const hasHllInternalPermission = usePermissionCheck("MinaSidor.SkapaRetur")
  const isStagingEnviroment = useEnviromentCheck()

  const showVerificationModal = () => {
    setShowInternalRegionModal(true)
    Storage.remove("userState", "local")
  }

  const logoutCallback = React.useCallback(async () => {
    const logoutUrl = `${process.env.REACT_APP_AUTH_URL}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.REACT_APP_PUBLIC_URL}`
    await logout()
    document.location.href = logoutUrl
  }, [idToken, logout])

  const handleLogOut = () => {
    Storage.remove("userGroups")
    Storage.remove("customer", "local")
    Storage.remove("subsidiaries", "local")
    Storage.remove("userState", "local")
    Storage.remove("internalCompanies", "local")
    Storage.remove("internalRegions", "local")
    Storage.remove("depots", "local")
    logoutCallback()
  }

  const isReturnRoute = useReturnRoute()
  const isMachineRoute = useMachineRoute()
  const isOrderRoute = useOrderRoute()
  const isInventoryRoute = useInventoryRoute()
  const isMaintenanceRoute = useMaintenanceRoute()

  useEffect(() => {
    if (userSettingsReady && !userState?.internal) {
      setShowInternalRegionModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettingsReady, setShowClientCompanyModal])

  if (!oidcUser) {
    return null
  }

  if (isMaintenanceRoute) {
    return null
  }

  return (
    <>
      <PushNotifications />
      {showInternalRegionModal && (
        <InternalVerificationModalWrapper
          showInternalRegionModal={showInternalRegionModal}
          setShowInternalRegionModal={setShowInternalRegionModal}
        />
      )}

      {hasHllInternalPermission && isStagingEnviroment ? (
        <span
          className="badge bg-soft-danger w-100 enviroment-badge rounded-0 text-center"
          style={{ border: 0, lineHeight: "normal", fontSize: "0.9rem" }}>
          {t("This is a dev enviroment")}
        </span>
      ) : (
        <></>
      )}

      {(() => {
        if (isReturnRoute) {
          return <ReturnOrderHeader handleModal={showVerificationModal} />
        } else if (hasHllInternalPermission && isMachineRoute) {
          return <MachinesHeader handleModal={showVerificationModal} />
        } else if (isOrderRoute) {
          return <OrderHeader handleModal={showVerificationModal} />
        } else if (isInventoryRoute) {
          return <InventoryHeader handleModal={showVerificationModal} />
        } else {
          return (
            <>
              <div className="app-header header bg-dark-theme shadow position-relative js-app-header">
                <div className="container-fluid container-fluid-max-width d-flex align-items-center justify-content-center h-100">
                  <figure className="d-block m-0">
                    <Link
                      to="/"
                      className="d-block ml-0"
                      aria-label={t("My pages")}>
                      <img
                        src={Logo}
                        alt="Hyreslandslagets logotyp"
                        className="logo"
                      />
                    </Link>
                  </figure>

                  <HeaderTitle
                    depot={userState?.internal?.depot?.name}
                    pathname={pathname}
                  />

                  <NavAccount
                    profile={oidcUser}
                    handleLogOut={handleLogOut}
                    handleModal={showVerificationModal}
                    customer={customer}
                    setCustomer={setCustomer}
                    showClientCompanyModal={showClientCompanyModal}
                    setShowClientCompanyModal={setShowClientCompanyModal}
                    setSwitchClientAccounts={setSwitchClientAccounts}
                  />
                </div>
              </div>

              <Search />
            </>
          )
        }
      })()}
    </>
  )
}

export default Header
