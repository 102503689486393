import React from "react"
import { useAccordionButton } from "react-bootstrap"

function CustomToggle({ eventKey, children, className }) {
  const handleOnClick = useAccordionButton(eventKey)

  return (
    <button
      type="button"
      className={`bg-transparent text-left p-0 ${className}`}
      onClick={handleOnClick}>
      {children}
    </button>
  )
}

export default CustomToggle
