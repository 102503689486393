import React, { useEffect, useState } from "react"
import axios from "axios"
import { useForm, Controller } from "react-hook-form"
import uuid from "react-uuid"
import { useTranslation } from "react-i18next"
import { useRecoilState, useResetRecoilState } from "recoil"
import { inventoryAdministrationCreate } from "../../../Store"
import {
  internalRegionsAndDepotsGrouping,
  addMinutesToDate,
  dateAndTimeNow
} from "../../../../../utils/helpers"

import Filters from "./Filters"
import ActionFooter from "../../../../Common/ActionFooter"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import "moment-timezone"

import toast from "react-hot-toast"
import ModalSheet from "../../../../Common/ModalSheet"
import Loader from "../../../../Loader/Loader"
import Overlay from "../../Overlay"

import Storage from "../../../../../utils/storage"

import DatePicker, { registerLocale } from "react-datepicker"
import sv from "date-fns/locale/sv"
import "react-datepicker/dist/react-datepicker.css"
import CustomSvgIcon from "../../../../Common/CustomSvgIcon"
registerLocale("sv", sv)

export default function Form() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const userState = Storage.get("userState") ?? null

  const [
    inventoryAdministrationCreateState,
    setInventoryAdministrationCreateState
  ] = useRecoilState(inventoryAdministrationCreate)

  const { inventory, internalRegions, depots } =
    inventoryAdministrationCreateState

  // Reset inventoryAdministrationCreate state
  const resetInventoryAdministrationCreateState = useResetRecoilState(
    inventoryAdministrationCreate
  )

  const {
    register,
    setValue,
    control,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm({
    mode: "all"
  })

  const [name, setName] = useState(
    inventoryAdministrationCreateState?.inventory?.name || ""
  )
  const [start, setStart] = useState(
    inventoryAdministrationCreateState?.inventory?.start
      ? new Date(inventoryAdministrationCreateState?.inventory?.start)
      : null
  )
  const [branchFilters, setBranchFilters] = useState(
    inventoryAdministrationCreateState?.inventory?.branchFilters || []
  )
  const [showBranchFilter, setShowBranchFilter] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [inventoryDataIsValidated, setInventoryDataIsValidated] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [showModalSheet, setShowModalSheet] = useState(false)
  const [saveAndUpdateVerification, setSaveAndUpdateVerification] =
    useState(false)
  const [inventoryVerification, setInventoryVerification] = useState(false)

  const filterPassedTime = (time) => {
    const currentDate = addMinutesToDate(new Date(), 15) // Add 15 minutes to current date
    const selectedDate = new Date(time)
    return currentDate.getTime() < selectedDate.getTime()
  }

  const handleUpdateInventoryName = (e) => {
    const value = e?.target?.value

    setValue("name", value)
    clearErrors("name")
    setName(value)

    if (!value.length) {
      setError("name")
    }

    setInventoryAdministrationCreateState((previousState) => {
      return {
        ...previousState,
        inventory: {
          ...previousState.inventory,
          name: value
        }
      }
    })
  }

  const handleUpdateInventoryStart = (date) => {
    const dateValue = date ? date : new Date()

    setValue("start", new Date(dateValue))
    clearErrors("start")
    setStart(new Date(dateValue))

    setInventoryAdministrationCreateState((previousState) => {
      return {
        ...previousState,
        inventory: {
          ...previousState.inventory,
          start: moment(dateValue).toISOString()
        }
      }
    })
  }

  const handleInventoryVerifiction = () => {
    setInventoryVerification(!inventoryVerification)
  }

  const handleCancel = () => {
    navigate(`/${t("inventory")}/${t("administration")}`)
  }

  const handleGoBack = () => {
    navigate(`/${t("inventory")}/${t("administration")}`)
  }

  const handleSaveAndUpdateContinue = () => {
    setShowModalSheet(true)
  }

  const onSubmitAndSave = async () => {
    const body = {
      name: inventoryAdministrationCreateState?.inventory?.name,
      status: 1, // Going to be "planned" check helper function
      start: inventoryAdministrationCreateState?.inventory?.start,
      branchFilters: branchFilters,
      fleetModelFilters: []
    }

    const totalInventory =
      parseInt(inventoryAdministrationCreateState?.depots?.length) ===
      parseInt(branchFilters.length)

    if (totalInventory) {
      body.branchFilters = []
    }

    setLoading(true)

    await axios
      .post("/api/hll/inventoryOccasionCreate", {
        body
      })
      .then((response) => {
        if (response?.status === 200) {
          toast.success(t("Inventory created successfully"), {
            duration: 2000
          })

          window?.dataLayer?.push({
            event: "create_inventory",
            company: userState?.internal?.region?.regionName ?? "",
            depot: userState?.internal?.depot?.name ?? "",
            createdAt: dateAndTimeNow(),
            userType: "internal"
          })

          resetInventoryAdministrationCreateState() // <--- Reset inventoryAdministrationCreate state

          handleGoBack()
        }
      })
      .catch((err) => {
        console.log("error", err)
        toast.error(t("Inventory could not be created. Try again."), {
          duration: 2000
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (name === "") {
      setInventoryDataIsValidated(false)
    } else if (!start) {
      setInventoryDataIsValidated(false)
    } else if (branchFilters.length === 0) {
      setInventoryDataIsValidated(false)
    } else {
      setInventoryDataIsValidated(true)
    }
  }, [branchFilters.length, name, start])

  // Set branch filter list to inventoryAdministrationCreateState
  useEffect(() => {
    const branchFilterList = internalRegionsAndDepotsGrouping(
      internalRegions,
      depots
    )
    setInventoryAdministrationCreateState((previousAdministrationEditState) => {
      return {
        ...previousAdministrationEditState,
        branchFilterList
      }
    })
  }, [internalRegions, depots, setInventoryAdministrationCreateState])

  return (
    <>
      {showModalSheet && <Overlay />}
      <div className="container-fluid container-fluid-max-width py-4">
        {showBranchFilter && (
          <Filters
            show={showBranchFilter}
            setShow={setShowBranchFilter}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            setBranchFilters={setBranchFilters}
          />
        )}
        <form>
          <div className="row mb-3">
            <div className="col-12">
              <input
                type="hidden"
                id="branchFilters"
                name="branchFilters"
                className="form-control"
                value={JSON.stringify(inventory?.branchFilters)}
                {...register("branchFilters", {
                  required: true
                })}
              />
            </div>
            <div className="col-12">
              <label
                htmlFor="name"
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {t("Name")}
                {"*"}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder={t("Enter a name")}
                className="form-control"
                value={name}
                {...register("name", {
                  required: true,
                  onChange: (e) => {
                    handleUpdateInventoryName(e)
                  }
                })}
              />
              {errors["name"] && (
                <span className="text-danger d-block font-xs mt-2">
                  {t("You must enter a name")}
                </span>
              )}
            </div>
          </div>
          <div className="row align-items-center mb-3">
            <div className="col">
              <label
                htmlFor="start"
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {t("Start time")}
                {"*"}
              </label>

              <Controller
                id="start"
                name="start"
                control={control}
                render={() => (
                  <DatePicker
                    locale="sv"
                    onChange={(date) => handleUpdateInventoryStart(date)}
                    selected={start}
                    dateFormat="yyyy-MM-dd HH:mm"
                    showTimeSelect
                    className="form-control"
                    minDate={new Date()}
                    filterTime={filterPassedTime}
                    timeIntervals={15}
                    placeholderText={t("Select date")}
                  />
                )}
              />

              {errors["start"] && (
                <span className="text-danger d-block font-xs mt-2">
                  {t("You must enter a date")}
                </span>
              )}
            </div>
          </div>
        </form>

        <div className="row align-items-center mb-3">
          <div className="col-12">
            <label
              htmlFor="branchFilters"
              className="field__label form-label mt-1 mb-1 text-uppercase">
              {t("Regions and depots")}
            </label>
            {inventoryAdministrationCreateState?.inventory?.branchFilters &&
              inventoryAdministrationCreateState?.inventory?.branchFilters
                .length > 0 && (
                <div
                  className="text-read-only-box"
                  style={{ maxHeight: "200px", overflowY: "auto" }}>
                  {inventoryAdministrationCreateState?.inventory?.branchFilters?.map(
                    (filter) => {
                      const branchName =
                        inventoryAdministrationCreateState.depots.find(
                          (depot) => depot.id === filter.branchId
                        )?.name

                      return (
                        <div key={uuid()}>
                          <label className="d-flex align-items-center my-1">
                            <input
                              name="depot"
                              type="checkbox"
                              checked
                              readOnly
                              disabled
                              className="form-check-input list-checkbox mt-0 flex-shrink-0"
                            />
                            <span className="ml-2">
                              {branchName && branchName}
                            </span>
                          </label>
                        </div>
                      )
                    }
                  )}
                </div>
              )}
          </div>
          {parseInt(inventoryAdministrationCreateState?.depots?.length) ===
            parseInt(
              inventoryAdministrationCreateState?.inventory?.branchFilters
                .length
            ) && (
            <div className="col-12 my-3">
              <span className="d-flex align-items-center">
                <span className="fe fe-alert-triangle text-danger mr-2"></span>
                <small className="d-block">{`${t("Total inventory")} ${t(
                  "on"
                )} ${t("all depots")}`}</small>
              </span>
            </div>
          )}
        </div>

        <button
          className="btn btn-white d-block w-100"
          onClick={() => setShowBranchFilter(true)}>
          <div className="d-flex align-items-center justify-content-center">
            <CustomSvgIcon
              width={16}
              height={16}
              name="add"
              className={`mr-2`}
            />
            <span>
              {t("Add")}/{t("Edit")}
            </span>
          </div>
        </button>

        <ActionFooter className="d-flex align-items-center justify-content-between">
          <button
            className="btn btn-outline-secondary d-block mr-3"
            onClick={() => handleCancel()}>
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary d-block w-100"
            disabled={!inventoryDataIsValidated}
            onClick={() => handleSaveAndUpdateContinue()}>
            {loading ? (
              <div
                className="spinner-border spinner-border-sm text-white"
                role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              t("Save")
            )}
          </button>
        </ActionFooter>

        <ModalSheet
          show={showModalSheet}
          roundedBig={showModalSheet}
          maxWidth={false}
          shadow={false}>
          <div className="position-relative mt-3">
            <h2 className="header-title mb-0 text-center px-3">
              {t("Create a new inventory")}
            </h2>
          </div>

          <div className="field my-4">
            {loading && <Loader />}

            {saveAndUpdateVerification ? (
              !loading && (
                <div className="row mb-3">
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <input
                      type="checkbox"
                      id="inventoryVerification"
                      name="inventoryVerification"
                      className="form-check-input list-checkbox mt-0 flex-shrink-0 mr-2"
                      checked={inventoryVerification}
                      onChange={() => handleInventoryVerifiction()}
                    />
                    <label
                      htmlFor="inventoryVerification"
                      className="field__label form-label mt-1 mb-1 text-uppercase">
                      {t(
                        "I confirm the start of the inventory according to the chosen time and selection."
                      )}
                    </label>
                  </div>
                </div>
              )
            ) : (
              <div className="row mb-3">
                <div className="col-12">
                  <p className="text-muted mb-2">
                    {t(
                      "You create an inventory with the below information and selection"
                    )}
                    {":"}
                  </p>
                  {name && (
                    <p className="mb-0 font-weight-bold">
                      <span>{name}</span>
                    </p>
                  )}
                  {start && (
                    <p className="mb-0">
                      <span>{moment(start).format("YYYY-MM-DD HH:mm")}</span>
                    </p>
                  )}

                  {branchFilters &&
                  branchFilters.length > 0 &&
                  branchFilters.length !==
                    inventoryAdministrationCreateState?.depots?.length ? (
                    <p className="mb-0">
                      <span className="d-block">
                        {branchFilters?.map((filter, index) => {
                          const separator =
                            index + 1 === branchFilters.length ? "" : ", "

                          return (
                            <span key={uuid()}>
                              {filter?.branchName}
                              {separator}
                            </span>
                          )
                        })}
                      </span>
                    </p>
                  ) : (
                    <span className="d-block">
                      <span className="fe fe-alert-triangle mr-2 text-danger"></span>
                      <span>{`${t("Total inventory")} ${t("on")} ${t(
                        "all depots"
                      )}`}</span>
                    </span>
                  )}
                </div>
              </div>
            )}

            <div className="text-center mt-4 border-top pt-4">
              <div className="d-flex align-items-center justify-content-between">
                <button
                  disabled={loading}
                  className="btn btn-outline-secondary w-100 mr-3"
                  onClick={() => {
                    setShowModalSheet(false)
                    setSaveAndUpdateVerification(false)
                    setInventoryVerification(false)
                  }}>
                  {t("Cancel")}
                </button>
                {!saveAndUpdateVerification && (
                  <button
                    disabled={loading}
                    className="btn btn-primary w-100"
                    onClick={() => setSaveAndUpdateVerification(true)}>
                    {t("Continue")}
                  </button>
                )}
                {saveAndUpdateVerification && (
                  <button
                    disabled={loading || !inventoryVerification}
                    className="btn btn-primary w-100"
                    onClick={() => onSubmitAndSave()}>
                    {t("Save")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </ModalSheet>
      </div>
    </>
  )
}
