import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Tabs, Tab } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"

import Storage from "../../../../utils/storage"
import { priceWithCurrencyHelper } from "../../../../utils/helpers"
import CustomSvgIcon from "../../../Common/CustomSvgIcon"

import MachineListItem from "../Machines/ListItem/Item"
import RentalCostDetails from "../Machines/RentalCostDetails"
import PurchaseCostDetails from "../Machines/PurchaseCostDetails"

export default function SheetData(props) {
  if (useMediaQuery({ query: "(max-width: 1200px)" })) {
    return <Mobile {...props} />
  }
  return <Desktop {...props} />
}

function Desktop({
  currentMachineListState,
  machines,
  loading,
  setIsUpdating,
  isPriceCalculationFetching,
  listName,
  listPrivate,
  onChangeName,
  onChangePrivate,
  setShowConfirmDeleteModal,
  showTotalRentalCostDetails,
  showTotalPurchaseCostDetails,
  setShowTotalRentalCostDetails,
  setShowTotalPurchaseCostDetails,
  totalPurchaseCost,
  totalRentalCost,
  totalInsuranceCost,
  accessories,
  services,
  handleSaveAndUpdate,
  onSubmit
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const nameRef = useRef(null)
  const privateRef = useRef(null)

  const customer = Storage.get("customer", null, "local") ?? null

  const handleOnClickNavigate = () => {
    document.documentElement.classList.remove("overflow-hidden")
    navigate(`/${t("customer")}/${t("machines")}`)
  }

  return (
    <>
      {machines ? (
        <div className="modal-split-sheet__column bg-background">
          <div className="modal-split-sheet__list p-0 p-lg-4">
            {machines?.map((item, index) => {
              return (
                <MachineListItem
                  key={index}
                  loading={loading}
                  isPriceCalculationFetching={isPriceCalculationFetching}
                  setIsUpdating={setIsUpdating}
                  {...item}
                />
              )
            })}
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center bg-background p-4 pt-lg-0">
            <span className="d-block mb-3">
              {t("Add more machines by searching or browsing our collection")}
            </span>
            <button
              disabled={loading}
              className="btn btn-outline-secondary btn-white"
              onClick={handleOnClickNavigate}>
              {t("Add machines")}
            </button>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center modal-split-sheet__column">
          <div className="p-4">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="d-block mb-3">
                {t("Add machines by searching or browsing our collection")}
              </span>
              <button
                disabled={loading}
                className="btn btn-outline-secondary btn-white"
                onClick={handleOnClickNavigate}>
                {t("Add machines")}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="modal-split-sheet__column">
        <div className="d-flex flex-column">
          <form onSubmit={onSubmit}>
            <div className="p-4">
              <div className="col-12">
                <div className="form-group text-left m-0">
                  <label
                    htmlFor="listName"
                    className="field__label form-label mt-1 mb-1 text-uppercase">
                    {t("Name")}
                    {" *"}
                  </label>
                  <input
                    ref={nameRef}
                    disabled={loading}
                    type="text"
                    id="name"
                    name="name"
                    value={listName}
                    onChange={(e) => onChangeName(e)}
                    className={`form-control form-control-modal${
                      loading ? " is-disabled" : ""
                    }`}
                  />
                  <span className="text-muted font-xs mt-2">
                    {t("Min 3 characters")}
                  </span>
                </div>
              </div>
              <div className="col-12 my-3">
                <div className="d-flex align-items-center justify-content-start">
                  <div className="form-check form-switch">
                    <input
                      ref={privateRef}
                      disabled={loading}
                      type="checkbox"
                      name="private"
                      id="private"
                      checked={listPrivate}
                      onChange={(e) => onChangePrivate(e)}
                      className={`form-check-input${
                        loading ? " is-disabled" : ""
                      }`}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-start m-0">
                    <CustomSvgIcon
                      name="lock"
                      width={12}
                      height={12}
                      className="text-primary"
                    />
                    <span className="d-block text-muted font-xs ml-2">
                      {t("Make the list private")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group text-left m-0">
                  <label
                    htmlFor="costOverview"
                    className="field__label form-label mt-1 mb-1 text-uppercase">
                    {t("Cost overview")}
                  </label>
                  <div className="border rounded p-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="m-0">{t("Rentals")}</p>

                      <p className="font-weight-bold m-0">
                        {isPriceCalculationFetching ? (
                          <small className="d-block ml-2">{`${t(
                            "Loading price"
                          )}...`}</small>
                        ) : (
                          <button
                            className="bg-transparent p-0 m-0"
                            onClick={() => {
                              setShowTotalRentalCostDetails(
                                !showTotalRentalCostDetails
                              )
                              setShowTotalPurchaseCostDetails(false)
                            }}>
                            <div className="d-flex align-items-center">
                              <span>
                                <strong>
                                  {priceWithCurrencyHelper(
                                    totalRentalCost ?? 0
                                  )}
                                </strong>
                              </span>
                              <CustomSvgIcon
                                name={
                                  showTotalRentalCostDetails
                                    ? "chevron-up"
                                    : "chevron-down"
                                }
                                width={16}
                                height={16}
                                className="text-primary ml-2"
                              />
                            </div>
                          </button>
                        )}
                      </p>
                    </div>

                    {showTotalRentalCostDetails && (
                      <RentalCostDetails
                        totalRentalCost={totalRentalCost}
                        totalInsuranceCost={totalInsuranceCost}
                      />
                    )}

                    <div className="d-flex justify-content-between align-items-center mt-1">
                      <p className="m-0">{t("Purchase")}</p>
                      <p className="font-weight-bold m-0">
                        {isPriceCalculationFetching ? (
                          <small className="d-block ml-2">{`${t(
                            "Loading price"
                          )}...`}</small>
                        ) : (
                          <button
                            className="bg-transparent p-0 m-0"
                            onClick={() => {
                              setShowTotalPurchaseCostDetails(
                                !showTotalPurchaseCostDetails
                              )
                              setShowTotalRentalCostDetails(false)
                            }}>
                            <div className="d-flex align-items-center">
                              <span>
                                <strong>
                                  {priceWithCurrencyHelper(totalPurchaseCost)}
                                </strong>
                              </span>
                              <CustomSvgIcon
                                name={
                                  showTotalPurchaseCostDetails
                                    ? "chevron-up"
                                    : "chevron-down"
                                }
                                width={16}
                                height={16}
                                className="text-primary ml-2"
                              />
                            </div>
                          </button>
                        )}
                      </p>
                    </div>

                    {showTotalPurchaseCostDetails && (
                      <PurchaseCostDetails
                        accessories={accessories}
                        services={services}
                      />
                    )}

                    <div className="d-flex align-items-start justify-content-start mt-4">
                      <span className="lh-1 mr-2">
                        <i className="fe fe-info text-primary" />
                      </span>
                      <small>
                        {t(
                          "All prices are your contract prices, excluding VAT, excluding insurance and may differ from the final invoice."
                        )}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="modal-split-sheet__actions p-4 border-top">
            <div className="d-flex flex-row align-items-center justify-content-center mt-auto gap-3">
              <button
                disabled={loading}
                onClick={handleSaveAndUpdate}
                className={`btn btn-primary d-block flex-grow col`}>
                {t("Save and close")}
              </button>

              <button
                disabled={
                  loading || customer?.id !== currentMachineListState?.customer
                }
                onClick={() => setShowConfirmDeleteModal(true)}
                title={t("Delete machine list")}
                className={`btn bg-transparent d-flex align-items-center col-auto mx-auto`}>
                <CustomSvgIcon
                  name="trash"
                  width={20}
                  height={20}
                  className="text-danger"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function Mobile({
  currentMachineListState,
  machines,
  loading,
  setIsUpdating,
  isPriceCalculationFetching,
  listName,
  listPrivate,
  onChangeName,
  onChangePrivate,
  setShowConfirmDeleteModal,
  showTotalRentalCostDetails,
  showTotalPurchaseCostDetails,
  setShowTotalRentalCostDetails,
  setShowTotalPurchaseCostDetails,
  totalPurchaseCost,
  totalRentalCost,
  totalInsuranceCost,
  accessories,
  services,
  handleSaveAndUpdate,
  onSubmit
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const nameRef = useRef(null)
  const privateRef = useRef(null)

  const customer = Storage.get("customer", null, "local") ?? null
  const [activeTab, setActiveTab] = useState("machines")

  const handleOnClickNavigate = () => {
    document.documentElement.classList.remove("overflow-hidden")
    navigate(`/${t("customer")}/${t("machines")}`)
  }

  return (
    <Tabs
      justify
      fill
      id="uncontrolled-tab"
      activeKey={activeTab}
      onSelect={(key) => setActiveTab(key)}
      className="bg-white">
      <Tab
        eventKey="machines"
        tabClassName="w-100 bg-transparent"
        title={
          <div className="d-flex align-items-center justify-content-center">
            {t("Machines")}
          </div>
        }>
        {machines ? (
          <div className="modal-split-sheet__column">
            <div className="modal-split-sheet__list p-0 p-lg-4">
              {machines?.map((item, index) => {
                return (
                  <MachineListItem
                    key={index}
                    loading={loading}
                    isPriceCalculationFetching={isPriceCalculationFetching}
                    setIsUpdating={setIsUpdating}
                    {...item}
                  />
                )
              })}
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center bg-background p-4 pt-lg-0">
              <span className="d-block mb-3 text-center">
                {t("Add more machines by searching or browsing our collection")}
              </span>
              <button
                disabled={loading}
                className="btn btn-outline-secondary btn-white"
                onClick={() => handleOnClickNavigate()}>
                {t("Add machines")}
              </button>
            </div>
            <div className="modal-split-sheet__actions p-4 border-top">
              <div className="d-flex flex-row align-items-center justify-content-center mt-auto gap-3">
                <button
                  disabled={loading}
                  onClick={handleSaveAndUpdate}
                  className={`btn btn-primary d-block col flex-grow`}>
                  {t("Save and close")}
                </button>
                <button
                  disabled={
                    loading ||
                    customer?.id !== currentMachineListState?.customer
                  }
                  onClick={() => setShowConfirmDeleteModal(true)}
                  title={t("Delete machine list")}
                  className={`btn bg-transparent d-flex align-items-center col-auto mx-auto`}>
                  <CustomSvgIcon
                    name="trash"
                    width={20}
                    height={20}
                    className="text-danger"
                  />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center bg-background p-4 auto">
              <div className="p-4">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <span className="d-block mb-3 text-center">
                    {t("Add machines by searching or browsing our collection")}
                  </span>
                  <button
                    disabled={loading}
                    className="btn btn-outline-secondary btn-white"
                    onClick={() => handleOnClickNavigate()}>
                    {t("Add machines")}
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center bg-white p-4 border-top auto">
              <button
                disabled={loading}
                onClick={handleSaveAndUpdate}
                className={`btn btn-primary d-block col flex-grow`}>
                {t("Save and close")}
              </button>
              <button
                disabled={
                  loading || customer?.id !== currentMachineListState?.customer
                }
                onClick={() => setShowConfirmDeleteModal(true)}
                title={t("Delete machine list")}
                className={`btn bg-transparent d-flex align-items-center col-auto mx-auto`}>
                <CustomSvgIcon
                  name="trash"
                  width={20}
                  height={20}
                  className="text-danger"
                />
              </button>
            </div>
          </>
        )}
      </Tab>

      <Tab
        eventKey="overview"
        tabClassName="w-100 bg-transparent"
        title={
          <div className="d-flex align-items-center justify-content-center">
            {t("Overview")}
          </div>
        }>
        <div className="modal-split-sheet__column bg-white">
          <div className="d-flex flex-column">
            <form onSubmit={onSubmit}>
              <div className="p-4">
                <div className="col-12">
                  <div className="form-group text-left m-0">
                    <label
                      htmlFor="listName"
                      className="field__label form-label mt-1 mb-1 text-uppercase">
                      {t("Name")}
                      {" *"}
                    </label>
                    <input
                      ref={nameRef}
                      disabled={loading}
                      type="text"
                      id="name"
                      name="name"
                      value={listName}
                      onChange={(e) => onChangeName(e)}
                      className={`form-control form-control-modal${
                        loading ? " is-disabled" : ""
                      }`}
                    />
                    <span className="text-muted font-xs mt-2">
                      {t("Min 3 characters")}
                    </span>
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="d-flex align-items-center justify-content-start">
                    <div className="form-check form-switch">
                      <input
                        ref={privateRef}
                        disabled={loading}
                        type="checkbox"
                        name="private"
                        id="private"
                        checked={listPrivate}
                        onChange={(e) => onChangePrivate(e)}
                        className={`form-check-input${
                          loading ? " is-disabled" : ""
                        }`}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-start m-0">
                      <CustomSvgIcon
                        name="lock"
                        width={12}
                        height={12}
                        className="text-primary"
                      />
                      <span className="d-block text-muted font-xs ml-2">
                        {t("Make the list private")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group text-left m-0">
                    <label
                      htmlFor="costOverview"
                      className="field__label form-label mt-1 mb-1 text-uppercase">
                      {t("Cost overview")}
                    </label>
                    <div className="border rounded p-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="m-0">{t("Rentals")}</p>

                        <p className="font-weight-bold m-0">
                          {isPriceCalculationFetching ? (
                            <small className="d-block ml-2">{`${t(
                              "Loading price"
                            )}...`}</small>
                          ) : (
                            <button
                              className="bg-transparent p-0 m-0"
                              onClick={() => {
                                setShowTotalRentalCostDetails(
                                  !showTotalRentalCostDetails
                                )
                                setShowTotalPurchaseCostDetails(false)
                              }}>
                              <div className="d-flex align-items-center">
                                <span>
                                  <strong>
                                    {priceWithCurrencyHelper(
                                      totalRentalCost ?? 0
                                    )}
                                  </strong>
                                </span>
                                <CustomSvgIcon
                                  name={
                                    showTotalRentalCostDetails
                                      ? "chevron-up"
                                      : "chevron-down"
                                  }
                                  width={16}
                                  height={16}
                                  className="text-primary ml-2"
                                />
                              </div>
                            </button>
                          )}
                        </p>
                      </div>

                      {showTotalRentalCostDetails && (
                        <RentalCostDetails
                          totalRentalCost={totalRentalCost}
                          totalInsuranceCost={totalInsuranceCost}
                        />
                      )}

                      <div className="d-flex justify-content-between align-items-center mt-1">
                        <p className="m-0">{t("Purchase")}</p>
                        <p className="font-weight-bold m-0">
                          {isPriceCalculationFetching ? (
                            <small className="d-block ml-2">{`${t(
                              "Loading price"
                            )}...`}</small>
                          ) : (
                            <button
                              className="bg-transparent p-0 m-0"
                              onClick={() => {
                                setShowTotalPurchaseCostDetails(
                                  !showTotalPurchaseCostDetails
                                )
                                setShowTotalRentalCostDetails(false)
                              }}>
                              <div className="d-flex align-items-center">
                                <span>
                                  <strong>
                                    {priceWithCurrencyHelper(
                                      totalPurchaseCost ?? 0
                                    )}
                                  </strong>
                                </span>
                                <CustomSvgIcon
                                  name={
                                    showTotalPurchaseCostDetails
                                      ? "chevron-up"
                                      : "chevron-down"
                                  }
                                  width={16}
                                  height={16}
                                  className="text-primary ml-2"
                                />
                              </div>
                            </button>
                          )}
                        </p>
                      </div>

                      {showTotalPurchaseCostDetails && (
                        <PurchaseCostDetails
                          accessories={accessories}
                          services={services}
                        />
                      )}

                      <div className="d-flex align-items-start justify-content-start mt-4">
                        <span className="lh-1 mr-2">
                          <i className="fe fe-info text-primary" />
                        </span>
                        <small>
                          {t(
                            "All prices are your contract prices, excluding VAT, excluding insurance and may differ from the final invoice."
                          )}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="modal-split-sheet__actions p-4 border-top">
              <div className="d-flex flex-row align-items-center justify-content-center mt-auto gap-3">
                <button
                  disabled={loading}
                  onClick={handleSaveAndUpdate}
                  className={`btn btn-primary d-block col flex-grow`}>
                  {t("Save and close")}
                </button>

                <button
                  disabled={
                    loading ||
                    customer?.id !== currentMachineListState?.customer
                  }
                  onClick={() => setShowConfirmDeleteModal(true)}
                  title={t("Delete machine list")}
                  className={`btn bg-transparent d-flex align-items-center col-auto mx-auto`}>
                  <CustomSvgIcon
                    name="trash"
                    width={20}
                    height={20}
                    className="text-danger"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Tab>
    </Tabs>
  )
}
