import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import { useOidcUser } from "@axa-fr/react-oidc"
import { encode } from "base-64"
import { useSetRecoilState } from "recoil"
import { machineLists } from "../../../MachineLists/Store"

import CustomSvgIcon from "../../../../Common/CustomSvgIcon"
import CreateList from "./CreateList"

export default function AddToMachineListModal({
  loading,
  customerMachineListRefresh,
  createNewMachinelist,
  setCreateNewMachinelist,
  selectedMachineList,
  setSelectedMachineList,
  customerMachineLists,
  show,
  setShow,
  handleSaveToMachineList,
  setMachineGroupDataState,
  machineGroupDataState
}) {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()

  const setMachineListState = useSetRecoilState(machineLists)

  const handleCloseCreateNew = () => {
    setCreateNewMachinelist(false)
  }

  if (createNewMachinelist) {
    return (
      <CreateList
        customerMachineListRefresh={customerMachineListRefresh}
        show={createNewMachinelist}
        title={t("Create new machine list")}
        setMachineListState={setMachineListState}
        handleCloseCreateNew={handleCloseCreateNew}
        setShowModal={setShow}
        machineGroupDataState={machineGroupDataState}
      />
    )
  }

  return (
    <Modal show={show} centered animation={false}>
      <Modal.Header className="d-block border-bottom">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="header-title mb-0 text-left">
            {t("Save to machine list")}
          </h3>
          <button
            onClick={() => {
              setShow(false)
              setCreateNewMachinelist(false)
              setMachineGroupDataState(null)
            }}
            className="position-relative bg-transparent p-0 text-body"
            style={{
              top: "3px"
            }}>
            <CustomSvgIcon
              name="close"
              width={16}
              height={16}
              className="text-black"
            />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="text-center py-4 px-4 bg-white is-scrollable mx-0">
        <div className="d-flex align-items-center justify-content-center">
          <div className="mr-auto w-100">
            <select
              disabled={customerMachineLists?.length === 0}
              className={`form-control px-4 w-100${
                customerMachineLists?.length === 0 ? " is-disabled" : ""
              }`}
              name="machineList"
              id="machineList"
              defaultValue={selectedMachineList?.id}
              onChange={(e) => {
                if (!e.target.value) {
                  setSelectedMachineList(null)
                  return
                }

                const machineList = customerMachineLists.find(
                  (list) => list.id === e.target.value
                )
                setSelectedMachineList(machineList)
              }}>
              <option value={null}>{t("Select machine list")}</option>
              {customerMachineLists && customerMachineLists.length > 0
                ? customerMachineLists.map((list, i) => {
                    if (
                      list?.data?.private &&
                      list?.data?.userid !==
                        `${encode(oidcUser?.tid)}${encode(oidcUser?.sub)}`
                    ) {
                      return null
                    }

                    return (
                      <option key={i} value={list?.id}>
                        {list?.data?.name}
                      </option>
                    )
                  })
                : null}
            </select>
          </div>
          <div className="ml-3">
            <button
              onClick={() => {
                setCreateNewMachinelist(true)
              }}
              className="btn btn-outline-secondary d-flex align-items-center"
              style={{
                height: "42px"
              }}>
              <CustomSvgIcon name="add" width={20} height={20} />
            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-block border-top py-3 px-4">
        <div className="d-flex align-items-center justify-content-center">
          <button
            onClick={() => {
              setShow(false)
              setCreateNewMachinelist(false)
              setMachineGroupDataState(null)
            }}
            className={`btn btn-outline-secondary d-block mr-3 w-100 p-3`}>
            {t("Close")}
          </button>
          <button
            disabled={loading || !selectedMachineList}
            type="submit"
            className={`btn btn-primary d-block w-100 p-3`}
            onClick={() => {
              if (!selectedMachineList) return

              handleSaveToMachineList(
                selectedMachineList?.id,
                machineGroupDataState
              )
            }}>
            {loading ? (
              <div
                className="spinner-border spinner-border-sm text-white"
                role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              t("Save")
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
