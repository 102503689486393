import React, { useEffect, useCallback, useState } from "react"
import { Tabs, Tab } from "react-bootstrap"
import axios from "axios"
import { useTranslation } from "react-i18next"
import {
  inventoryView,
  inventoryAdministration,
  inventoryAdministrationCreate,
  inventoryAdministrationEdit
} from "../../../components/Inventory/Store"
import { useSetRecoilState, useResetRecoilState } from "recoil"
import ActionFooter from "../../../components/Common/ActionFooter"
import { useNavigate } from "react-router-dom"
import Loader from "../../../components/Loader/Loader"
import List from "../../../components/Inventory/Common/Administration/List"

import { usePermissionCheck } from "../../../utils/hooks"
import PageNotFound from "../../PageNotFound/PageNotFound"
import CustomSvgIcon from "../../../components/Common/CustomSvgIcon"

export default function Administration() {
  const { t } = useTranslation()
  const hasInventoryAdminPermission = usePermissionCheck(
    "MinaSidor.inventeringAdministrera"
  )

  const navigate = useNavigate()
  const setViewState = useSetRecoilState(inventoryView)
  const setInventoryAdministrationState = useSetRecoilState(
    inventoryAdministration
  )

  // Reset inventoryAdministrationCreate state
  const resetInventoryAdministrationCreateState = useResetRecoilState(
    inventoryAdministrationCreate
  )

  // Reset inventoryAdministrationEdit state
  const resetInventoryAdministrationEditState = useResetRecoilState(
    inventoryAdministrationEdit
  )

  const [activeTab, setActiveTab] = useState("ongoing")
  const [inventoryData, setInventoryData] = useState({
    planned: [],
    ongoing: [],
    completed: []
  })
  const [loading, setLoading] = useState(false)

  const requestInventories = useCallback(async () => {
    setLoading(true)

    await axios
      .get("/api/hll/inventoryOccasion")
      .then((response) => {
        const sortedData = response?.data?.sort((a, b) =>
          a.start > b.start ? 1 : -1
        )
        setInventoryAdministrationState((previousAdministrationState) => {
          return {
            ...previousAdministrationState,
            pageHeader: t("Administration"),
            inventories: sortedData
          }
        })

        setInventoryData((previousInventoryData) => {
          return {
            ...previousInventoryData,
            // unknown: sortedData?.filter((item) => item?.status === 0),
            planned: sortedData?.filter((item) => item?.status === 1),
            ongoing: sortedData?.filter(
              (item) => item?.status === 2 || item?.status === 3
            ),
            completed: sortedData?.filter((item) => item?.status === 4)
          }
        })
      })
      .catch((err) => {
        console.log("error", err)
        //setErrorCode(err?.response?.data?.CorrelationId)
        //setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [setInventoryAdministrationState, t])

  // Fetch and set inventory state
  useEffect(() => {
    setViewState("inventoryAdministration")
    resetInventoryAdministrationCreateState() // <-- Reset inventoryAdministrationCreate state
    resetInventoryAdministrationEditState() // <-- Reset inventoryAdministrationEdit state

    if (hasInventoryAdminPermission) {
      requestInventories()
    }
  }, [
    requestInventories,
    setViewState,
    hasInventoryAdminPermission,
    resetInventoryAdministrationCreateState,
    resetInventoryAdministrationEditState
  ])

  if (!hasInventoryAdminPermission) {
    return <PageNotFound />
  }

  return (
    <>
      <div className="container-fluid container-fluid-max-width">
        <Tabs
          justify
          fill
          id="uncontrolled-tab"
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
          className="bg-white">
          <Tab
            eventKey="ongoing"
            tabClassName="w-100 bg-transparent"
            disabled={loading}
            title={
              <div className="d-flex align-items-center justify-content-center">
                {t("Ongoing")}
              </div>
            }></Tab>
          <Tab
            eventKey="planned"
            tabClassName="w-100 bg-transparent"
            disabled={loading}
            title={
              <div className="d-flex align-items-center justify-content-center">
                {t("Planned")}
              </div>
            }></Tab>
          <Tab
            eventKey="completed"
            tabClassName="w-100 bg-transparent"
            disabled={loading}
            title={
              <div className="d-flex align-items-center justify-content-center">
                {t("Completed")}
              </div>
            }></Tab>
        </Tabs>
      </div>

      <div className="container-fluid container-fluid-max-width">
        {loading && <Loader className="pt-4 mx-auto text-center" />}

        {!loading &&
          activeTab === "ongoing" &&
          inventoryData?.ongoing.length > 0 && (
            <div className="row mt-4">
              {inventoryData?.ongoing.map((inventory) => (
                <List key={inventory?.inventoryOccasionId} {...inventory} />
              ))}
            </div>
          )}

        {!loading &&
          activeTab === "planned" &&
          inventoryData?.planned.length > 0 && (
            <div className="row mt-4">
              {inventoryData?.planned.map((inventory) => (
                <List key={inventory?.inventoryOccasionId} {...inventory} />
              ))}
            </div>
          )}

        {!loading &&
          activeTab === "completed" &&
          inventoryData?.completed.length > 0 && (
            <div className="row mt-4">
              {inventoryData?.completed.map((inventory) => (
                <List key={inventory?.inventoryOccasionId} {...inventory} />
              ))}
            </div>
          )}
      </div>

      {hasInventoryAdminPermission && (
        <ActionFooter>
          <button
            disabled={loading}
            className="btn btn-outline-secondary d-flex align-items-center text-primary"
            onClick={() =>
              navigate(`/${t("inventory")}/administration/${t("create")}`)
            }>
            <CustomSvgIcon width={20} height={20} name="add" />
          </button>
        </ActionFooter>
      )}
    </>
  )
}
