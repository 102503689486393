export function calculateInsuranceCost() {
  let totalInsuranceCost = 0

  return totalInsuranceCost
}

export function calculateRentalPrice(arr, totalInsuranceCost = 0, loading) {
  // vars
  let totalRentalCost = 0

  const data = arr && arr.length > 0 ? arr : null

  if (!data) {
    return totalRentalCost
  }

  if (loading) {
    return totalRentalCost
  }

  data?.map((machine) => {
    totalRentalCost += machine?.cost * machine?.quantity ?? 0
    const accessories = machine?.acf?.accessories ?? []

    // Calculate accessories cost
    if (accessories && accessories.length > 0) {
      accessories.map((accessory) => {
        if (accessory?.orderRowType === 1) {
          totalRentalCost += accessory?.cost * accessory?.quantity ?? 0
        }

        return accessory
      })
    }

    return machine
  })

  totalRentalCost = totalRentalCost + totalInsuranceCost

  return totalRentalCost
}

export function calculatePurchaseCost(arr, loading) {
  // vars
  let totalPurchaseCost = 0

  const data = arr && arr.length > 0 ? arr : null

  if (!data) {
    return totalPurchaseCost
  }

  if (loading) {
    return totalPurchaseCost
  }

  data?.map((machine) => {
    const accessories = machine?.acf?.accessories ?? []
    const services = machine?.acf?.services ?? []

    // Calculate accessories cost
    if (accessories && accessories.length > 0) {
      accessories.map((accessory) => {
        if (accessory?.orderRowType === 2 || accessory?.orderRowType === 3) {
          totalPurchaseCost += accessory?.cost * accessory?.quantity ?? 0
        }

        return accessory
      })
    }

    // Calculate services cost
    if (services && services.length > 0) {
      services.map((service) => {
        if (service?.orderRowType === 2 || service?.orderRowType === 3) {
          totalPurchaseCost += service?.cost * service?.quantity ?? 0
        }

        return service
      })
    }

    return machine
  })
  return totalPurchaseCost
}

export function getMachineAccessoriesAndServices(array, loading) {
  let accessories = []
  let services = []

  !loading &&
    array?.map((item) => {
      let machineAccessories = item?.acf?.accessories ?? []
      let machineServices = item?.acf?.services ?? []

      accessories = [...accessories, ...machineAccessories]
      services = [...services, ...machineServices]

      return item
    })

  return {
    accessories,
    services
  }
}
