import React, { useState, useRef } from "react"
import axios from "axios"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import useMachineContext from "../../../context/Machine/Store"
import Storage from "../../../utils/storage"
import ActionFooter from "../../Common/ActionFooter"
import toast from "react-hot-toast"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const ControlNoteModal = ({
  fleetItemId,
  open,
  setModalOpen,
  newInspectionNotes,
  setNewInspectionNotes
}) => {
  const { t } = useTranslation()
  const noteInputEl = useRef(null)
  const [loading, setLoading] = useState(false)
  const [process, setProcess] = useState(false)
  const { machineItem } = useMachineContext()
  const userState = Storage.get("userState") ?? null

  const controlNoteChange = (event) => {
    event.preventDefault()
    const value = event.target.value

    if (value.length > 0) {
      setProcess(true)
    } else {
      setProcess(false)
    }
  }

  const hideModal = () => {
    setModalOpen(false)
  }

  const onSubmit = async (event) => {
    const noteValue = noteInputEl.current.value
    event.preventDefault()

    const body = {
      inspectionInfo: noteValue
    }
    setLoading(true)
    await axios
      .post("/api/hll/inspectionNote", {
        id: fleetItemId,
        body
      })
      .then((response) => {
        if (response.data && response.status !== 400) {
          setNewInspectionNotes([].concat([response.data], newInspectionNotes))

          window.dataLayer.push({
            event: "HLL_machine_control_note_created",
            itemName: machineItem?.name,
            itemNumber: machineItem?.number,
            depot: userState?.internal?.depot?.name,
            createdAt: response?.data?.createdAt,
            userType: "internal"
          })

          setModalOpen(false)
        }
      })
      .catch((error) => {
        console.error(error)
        toast.error(t("Something went wrong, try again."))
      })
      .finally(() => {
        setLoading(false)
        toast.success(t("The note has been saved!"))
      })
  }

  return (
    <>
      <Modal
        show={open}
        centered
        animation={false}
        backdrop="static"
        keyboard={false}
        fullscreen="true"
        onHide={() => {
          setModalOpen(false)
        }}
        className={"modal-scrollable"}>
        <Modal.Header className="d-block p-3">
          <div className="position-relative">
            <h3 className="header-title mt-3 mb-0 text-center">
              {t("Create new control note")}
            </h3>
            <button
              onClick={hideModal}
              className="bg-transparent position-absolute p-0 text-body"
              style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
              <CustomSvgIcon
                name="close"
                width={16}
                height={16}
                className="text-black"
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12" key="note">
              <label
                htmlFor="note"
                className="field__label form-label text-uppercase font-xs pb-0 mb-2">
                {t("Control note")}
              </label>
              <textarea
                id="note"
                className="form-control"
                rows="3"
                ref={noteInputEl}
                onChange={controlNoteChange}
              />
            </div>
          </div>
        </Modal.Body>
        <ActionFooter>
          <button
            className={`btn btn-outline-secondary w-100 d-block mr-3`}
            onClick={hideModal}>
            {t("Close")}
          </button>

          <button
            className={`btn btn-primary w-100 d-block${
              loading ? " btn-loading" : ""
            }`}
            disabled={loading || !process}
            onClick={onSubmit}>
            {loading ? (
              <div
                className="spinner-grow spinner-grow-sm text-white"
                role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              t("Save note")
            )}
          </button>
        </ActionFooter>
      </Modal>
    </>
  )
}

export default ControlNoteModal
