import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { PatternFormat } from "react-number-format"
import DatePicker, { registerLocale } from "react-datepicker"
import sv from "date-fns/locale/sv"
import "react-datepicker/dist/react-datepicker.css"
registerLocale("sv", sv)

function PermissionForm({
  formTemplate,
  handleForm,
  requiresIdForRentalCustomer,
  userGroups = null
}) {
  const {
    register,
    errors,
    formData,
    action,
    control,
    setValue,
    setError,
    clearErrors
  } = handleForm
  const { fields } = formTemplate
  const { t } = useTranslation()

  const [endDateState, setEndDateState] = useState(
    formData?.endDate ? new Date(formData?.endDate) : null
  )
  const [userGroupDataState, setUserGroupDataStateState] = useState(
    formData?.userAccountGroups ? formData?.userAccountGroups : []
  )

  const handleChangeEndDate = (date) => {
    setEndDateState(date)
  }

  useEffect(() => {
    if (userGroupDataState) {
      setValue("userAccountGroups", userGroupDataState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroupDataState])

  useEffect(() => {
    setValue("endDate", endDateState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDateState])

  useEffect(() => {
    const dateOfBirthValue =
      typeof formData["dateOfBirth"] !== "undefined"
        ? formData["dateOfBirth"]
        : ""

    if (requiresIdForRentalCustomer && !dateOfBirthValue) {
      setError("dateOfBirth", {
        type: "custom",
        message: t("You must fill in your complete social security number")
      })
    }

    if (formData["dateOfBirth"]) {
      setValue("dateOfBirth", formData["dateOfBirth"])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiresIdForRentalCustomer, setError, t, formData])

  const renderFields = (fields) => {
    const handleDateOfBirthValidation = (data) => {
      const { formattedValue } = data

      if (formattedValue) {
        const maskedValue = formattedValue.replace(/_/g, "")
        if (maskedValue.length === 10) {
          clearErrors("dateOfBirth")
        } else {
          setError("dateOfBirth", {
            type: "custom",
            message: t("You must fill in your complete social security number")
          })
        }
        setValue("dateOfBirth", formattedValue)
      }
    }

    return fields.map((field) => {
      const {
        type,
        title,
        name,
        required,
        instructions,
        placeholder,
        regExp,
        format
      } = field

      switch (type) {
        case "action":
          return (
            <input
              key={name}
              type="hidden"
              name={name}
              defaultValue={action}
              {...register(name)}
            />
          )

        case "hidden":
          return (
            <input
              key={name}
              type="hidden"
              name={name}
              defaultValue={
                typeof formData[name] !== "undefined" ? formData[name] : ""
              }
              {...register(name)}
            />
          )

        case "text":
          return (
            <div className="col-12 col-sm-6 mb-3" key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {required && "*"}
              </label>
              <input
                type="text"
                id={name}
                name={name}
                defaultValue={
                  typeof formData[name] !== "undefined" ? formData[name] : ""
                }
                className="form-control form-control-modal"
                {...register(name, { required })}
              />
              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}
              {instructions && (
                <span className="text-muted font-xs mt-2">{instructions}</span>
              )}
            </div>
          )

        case "number":
          return (
            <div className="col-12 col-sm-6 mb-3" key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}

                {name === "dateOfBirth" && requiresIdForRentalCustomer && "*"}
              </label>

              {regExp ? (
                <Controller
                  id={name}
                  name={name}
                  control={control}
                  render={({ field }) => {
                    return (
                      <PatternFormat
                        required={requiresIdForRentalCustomer}
                        format={format}
                        mask="_"
                        displayType="input"
                        placeholder={placeholder}
                        className="form-control form-control-modal"
                        defaultValue={
                          typeof formData[name] !== "undefined"
                            ? formData[name]
                            : ""
                        }
                        onValueChange={(data) => {
                          handleDateOfBirthValidation(data)
                        }}
                        {...field}
                        ref={null}
                      />
                    )
                  }}
                />
              ) : (
                <input
                  type="number"
                  id={name}
                  name={name}
                  defaultValue={
                    typeof formData[name] !== "undefined" ? formData[name] : ""
                  }
                  className="form-control form-control-modal"
                  {...register(name, { required })}
                />
              )}

              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}

              {instructions && (
                <span className="text-muted font-xs mt-2">{instructions}</span>
              )}
            </div>
          )

        case "tel":
          return (
            <div className="col-12 col-sm-6 mb-3" key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {required && "*"}
              </label>

              {regExp ? (
                <Controller
                  id={name}
                  name={name}
                  control={control}
                  rules={{
                    required: required
                  }}
                  defaultValue={
                    typeof formData[name] !== "undefined" ? formData[name] : ""
                  }
                  render={() => {
                    return (
                      <PatternFormat
                        format={format}
                        mask="_"
                        placeholder={placeholder}
                        className="form-control form-control-modal"
                        defaultValue={
                          typeof formData[name] !== "undefined"
                            ? formData[name]
                            : ""
                        }
                        ref={null}
                      />
                    )
                  }}
                />
              ) : (
                <input
                  type="tel"
                  id={name}
                  name={name}
                  defaultValue={
                    typeof formData[name] !== "undefined" ? formData[name] : ""
                  }
                  className="form-control form-control-modal"
                  {...register(name, { required })}
                />
              )}

              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}
              {instructions && (
                <span className="text-muted font-xs mt-2">{instructions}</span>
              )}
            </div>
          )

        case "email":
          return (
            <div className="col-12 col-sm-6 mb-3" key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {required && "*"}
              </label>
              <input
                type="email"
                id={name}
                name={name}
                defaultValue={
                  typeof formData[name] !== "undefined" ? formData[name] : ""
                }
                className="form-control form-control-modal"
                {...register(name, { required })}
              />
              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}
              {instructions && (
                <span className="text-muted font-xs mt-2">{instructions}</span>
              )}
            </div>
          )

        case "date":
          return (
            <div className="col-12 col-sm-6 mb-3" key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {required && "*"}
              </label>
              <Controller
                id={name}
                name={name}
                control={control}
                render={() => (
                  <DatePicker
                    onChange={(date) => handleChangeEndDate(date)}
                    selected={endDateState}
                    dateFormat="yyyy-MM-dd"
                    className="form-control form-control-modal"
                  />
                )}
              />
              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}
              {instructions && (
                <span className="text-muted font-xs mt-2">{instructions}</span>
              )}
            </div>
          )

        case "userAccountGroups":
          return (
            <div className="col-12 mt-3 mb-3" key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
              </label>

              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}

              {userGroups &&
                userGroups?.map((userGroup, index) => {
                  const fieldName = `userAccountGroupsData[${index}]`
                  const findGroupIndex =
                    userGroupDataState &&
                    userGroupDataState.find(
                      (group) => group?.id === userGroup?.id
                    )

                  return (
                    <div className="checkbox-rows py-3" key={userGroup?.id}>
                      <div className="d-flex align-items-start">
                        <input
                          type="checkbox"
                          name={fieldName}
                          className="form-check-input list-checkbox flex-shrink-0"
                          checked={findGroupIndex ? true : false}
                          value={
                            userGroupDataState && userGroupDataState.length > 0
                              ? JSON.stringify(userGroupDataState[index])
                              : JSON.stringify(userGroup)
                          }
                          onChange={(e) => {
                            const checked = e.target.checked

                            clearErrors("userAccountGroups") // clearing error
                            if (checked) {
                              setUserGroupDataStateState((groups) =>
                                groups.concat(userGroup)
                              )
                            } else {
                              setUserGroupDataStateState((current) =>
                                current.filter(
                                  (group) =>
                                    JSON.stringify(group) !==
                                    JSON.stringify(userGroup)
                                )
                              )
                            }
                          }}
                        />
                        <p className="ml-3 mb-0">
                          <span className="d-block">{userGroup?.name}</span>
                          <span className="d-block text-muted font-xs mt-2">
                            {userGroup?.name === "Administratör" &&
                              `Behörighet att se och redigera alla funktioner under Mitt HLL, tex projekt, behöriga att hyra, faktureringsuppgifter, dokumentmottagare och även administrera användare.`}

                            {userGroup?.name === "Ekonomi" &&
                              `Behörighet att se och ändra faktureringsuppgifter, som tex faktureringsmetod och adress.`}

                            {userGroup?.name === "Standardanvändare" &&
                              `Behörighet att se projekt och dess innehåll, boka returtransport, se depåinformation och se fakturauppgifter.`}
                          </span>
                        </p>
                      </div>
                    </div>
                  )
                })}
            </div>
          )

        default:
          return <div className="col-12">Invalid field</div>
      }
    })
  }

  return renderFields(fields)
}

export default PermissionForm
