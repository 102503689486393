import React, { useEffect, useState } from "react"
import axios from "axios"
import { useParams, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useOidcUser } from "@axa-fr/react-oidc"
import Loader from "../../components/Loader/Loader"
import Alert from "../../components/Common/Alert"
import DirectPayment from "../../components/Returns/DirectPayment"
import InsuranceCase from "../../components/Returns/InsuranceCase"
import Order from "../../components/ReturnOrder/Order"
import { usePermissionCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"
import LockedOrder from "../../components/Returns/LockedOrder"
import useOrderContext from "../../context/ReturnOrder/Store"

function Return() {
  const hasPermission = usePermissionCheck("MinaSidor.SkapaRetur")
  const { t } = useTranslation()
  const { customerOrderId } = useParams()
  const location = useLocation()
  const { oidcUser } = useOidcUser()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorCode, setErrorCode] = useState("")
  const [data, setData] = useState(null)
  const { setDebitTypes } = useOrderContext()

  useEffect(() => {
    let unmounted = false

    const requests = async () => {
      setLoading(true)

      const customerOrderRequest = axios.post("/api/hll/customerOrder", {
        customerOrderId
      })

      const debitTypesRequest = axios.post("/api/hll/debitTypes")

      await axios
        .all([customerOrderRequest, debitTypesRequest])
        .then(
          axios.spread((...responses) => {
            if (!unmounted) {
              const customerOrder = responses[0].data ?? null
              const debitTypes = responses[1].data ?? null
              setData(customerOrder)
              setDebitTypes(debitTypes)
            }
          })
        )
        .catch((err) => {
          setErrorCode(` Error code: ${err?.response?.data?.CorrelationId}`)
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    requests()

    return () => {
      const { pathname } = location

      // Only do this if locked in user is same person as locked the order.
      if (!pathname.includes("summering")) {
        const request = async () => {
          await axios
            .post("/api/hll/deleteOrderLock", {
              customerOrderId
            })
            .then(() => {
              console.info(`Order ${customerOrderId} unlocked`)
            })
            .catch((error) => {
              console.error(`Error: ${error.response.data.CorrelationId}`)
            })
        }
        request()
      }

      unmounted = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerOrderId])

  if (!hasPermission) {
    return <PageNotFound noPermission={true} />
  }

  if (loading) {
    return (
      <div className="container-fluid container-fluid-max-width mx-auto text-center">
        <Loader className="mt-5 text-center mx-auto" />
      </div>
    )
  }

  if (!data) {
    if (error) {
      return (
        <Alert
          className="text-center p-5"
          message={`${t(
            "Something went wrong, please refresh current page."
          )}${errorCode}`}
        />
      )
    } else {
      return null
    }
  }

  if (data.customerOrderHead.customerType === 1) {
    return <DirectPayment {...data} />
  }

  if (data.customerOrderHead.siteType === 5) {
    return <InsuranceCase {...data} />
  }

  const { isLocked, lockedBy, lockedFromClientApp } =
    data?.customerOrderHead?.lock
  const preferredUsername = oidcUser?.preferred_username

  // Låst av någon annan användare!
  if (isLocked && lockedBy !== preferredUsername.toLowerCase()) {
    //console.log("LLåst av någon annan användare")
    return <LockedOrder {...data} />
  }

  // Låst av samma användare i HYRse
  if (
    isLocked &&
    lockedBy === preferredUsername.toLowerCase() &&
    lockedFromClientApp !== "HLL.MinaSidor"
  ) {
    //console.log("Låst av samma användare i HYRse")
    return <LockedOrder {...data} />
  }

  return (
    <>
      <Order orderId={customerOrderId} {...data} />
    </>
  )
}

export default Return
