import React, { useContext, useRef, useState, useEffect } from "react"
import { Accordion, AccordionContext } from "react-bootstrap"
import CustomAccordionToggle from "../../Common/CustomAccordionToggle"
import { useTranslation } from "react-i18next"
import { useRecoilValue, useRecoilState } from "recoil"
import { depotState, machineState, pageTypeState } from "../Store"
import {
  replaceItemAtIndex,
  removeItemAtIndex,
  availabilityColorMap
} from "../../../utils/helpers"
import IncrementDecrement from "./IncrementDecrement"
import DepotSelect from "../Transfer/DepotSelect"
import axios from "axios"
import toast from "react-hot-toast"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const Item = ({ item }) => {
  const currentEventKey = useContext(AccordionContext)
  const isAccordionActive = currentEventKey === item.id
  const { t } = useTranslation()
  const [machines, setMachines] = useRecoilState(machineState)
  const depots = useRecoilValue(depotState)
  const pageType = useRecoilValue(pageTypeState)
  const [amount, setAmount] = useState(item.quantity)
  const index = machines.findIndex((machine) => machine.id === item.id)
  const usesAvaliability = item?.availabilityIsUsed

  // Need this to set amount correctly if recieved amount exists
  const defaultQuantity = item.defaultQuantity

  const decrementLimit = pageType === "incoming" ? 0 : 1
  const incrementLimit = pageType === "incoming" ? defaultQuantity : 1000
  const cbRef = useRef()

  const handleQuantityClick = (type) => {
    const updatedAmount =
      type === "increment" ? Number(amount) + 1 : Number(amount) - 1

    handleQuantityChange({
      target: {
        value: updatedAmount
      }
    })
  }

  const handleQuantityChange = ({ target: { value } }) => {
    let updatedAmount = value

    if (updatedAmount < 0) {
      updatedAmount = Math.abs(value)
    }

    if (updatedAmount < 1 && pageType === "transfer") {
      updatedAmount = 1
    }

    if (pageType === "incoming") {
      cbRef.current.checked = updatedAmount > 0
    }

    let updatedMachines = []

    if (pageType === "transfer") {
      updatedMachines = replaceItemAtIndex(machines, index, {
        ...item,
        quantity: Number(updatedAmount)
      })
    } else {
      updatedMachines = replaceItemAtIndex(machines, index, {
        ...item,
        amountToAcknowledge: Number(updatedAmount),
        checkedAsIncoming: updatedAmount > 0
      })
    }

    setAmount(updatedAmount)
    setMachines(updatedMachines)
  }

  const handleDepotChange = ({ target: { value } }) => {
    let findDepotById = null

    if (value > 0) {
      findDepotById = depots.find((depot) => depot.id === value)
    }

    const updatedMachineTransfers = replaceItemAtIndex(machines, index, {
      ...item,
      newBranchId: value > 0 ? value : "",
      newBranchName: value > 0 ? findDepotById.name : ""
    })

    setMachines(updatedMachineTransfers)
  }

  const handleRemove = async (id) => {
    const updatedMachineTransfers = removeItemAtIndex(machines, index)
    setMachines(updatedMachineTransfers)

    await axios
      .post("/api/hll/deleteFleetItemLock", {
        id
      })
      .then(() => {
        toast.success(`${t("Removed")}: ${item.name}`)
      })
      .catch((err) => {
        toast.error(
          `Something went wrong. Correlation ID: ${err?.response?.data?.CorrelationId}`
        )
      })
  }

  const handleCheckboxChange = ({ target: { checked } }) => {
    let quantity = checked ? Number(defaultQuantity) : 0

    let updatedMachines = []

    if (pageType === "transfer") {
      updatedMachines = replaceItemAtIndex(machines, index, {
        ...item,
        quantity
      })
    } else {
      updatedMachines = replaceItemAtIndex(machines, index, {
        ...item,
        amountToAcknowledge: quantity,
        checkedAsIncoming: checked
      })
    }

    setAmount(quantity)
    setMachines(updatedMachines)
  }

  useEffect(() => {
    if (item?.amountToAcknowledge && item?.amountToAcknowledge > 0) {
      setAmount(item?.amountToAcknowledge)
      cbRef.current.checked = true
    }
  }, [item])

  // HLL-1065 by Nicke
  let availability = item?.availability
  if (availability === 0 && pageType === "incoming") {
    availability = 1 // Convert all items with "not-available/0" to "available/1" in this view
  }

  return (
    <>
      <div
        className={`accordion--row accordion-item${
          pageType === "incoming" && !item.isVisible ? " d-none" : ""
        }`}>
        <div
          className={`d-block p-0 w-100 border-0 text-left text-reset${
            isAccordionActive ? " state--active" : ""
          }`}>
          <div className={`p-3 pb-0 ${!isAccordionActive ? " bg-white" : ""}`}>
            <div className="d-flex align-items-center justify-content-between">
              {pageType === "incoming" && (
                <div className="pr-3">
                  <input
                    className="form-check-input list-checkbox list-checkbox-theme"
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    defaultChecked={machines.find(
                      (machine) =>
                        machine.id === item.id && machine.checkedAsIncoming
                    )}
                    ref={cbRef}
                  />
                </div>
              )}
              <CustomAccordionToggle
                eventKey={item.id}
                className="w-100 d-flex align-items-center text-body">
                <div className="mr-auto">
                  <span className="font-weight-bold">{item.name}</span>
                </div>
                <div className="ml-auto">
                  <div className="d-flex align-items-center">
                    {usesAvaliability && (
                      <span
                        className={`machine-item-availability d-block mr-2 flex-shrink-0 availability--${availabilityColorMap(
                          availability
                        )}`}></span>
                    )}
                    {isAccordionActive ? (
                      <CustomSvgIcon name="chevron-up" width={16} height={16} />
                    ) : (
                      <CustomSvgIcon
                        name="chevron-down"
                        width={16}
                        height={16}
                      />
                    )}
                  </div>
                </div>
              </CustomAccordionToggle>
            </div>
          </div>
          <div className={`p-3${!isAccordionActive ? " bg-white" : ""}`}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="pr-3">
                <span className="badge bg-light">
                  {pageType === "incoming"
                    ? `${amount} ${t("by")} ${defaultQuantity}`
                    : `${amount} ${item.unit}`}
                </span>
              </div>
              <div className="mr-auto text-muted">
                {pageType === "incoming"
                  ? item?.articleNumber
                    ? item.articleNumber
                    : item?.fleetItemNumber
                  : item.number}
              </div>
              <div className="ml-auto">
                <span className="ml-2 badge bg-light">
                  {item.newBranchName !== ""
                    ? item.newBranchName
                    : t("No depot selected")}
                </span>
              </div>
            </div>
          </div>
          <Accordion.Collapse eventKey={item.id}>
            <div className="p-3 pt-2">
              <div className="d-flex">
                {pageType === "transfer" && (
                  <div className="col pr-2">
                    <DepotSelect
                      item={item}
                      label={t("Moves to")}
                      selectedLabel={t("Select depot")}
                      handleDepotChange={handleDepotChange}
                      depots={depots}
                    />
                  </div>
                )}
                <div className="col">
                  <IncrementDecrement
                    label={t("Amount")}
                    amount={amount}
                    handleQuantityClick={handleQuantityClick}
                    handleQuantityChange={handleQuantityChange}
                    pageType={pageType}
                    decrementLimit={decrementLimit}
                    incrementLimit={incrementLimit}
                    itemType={item?.internalType}
                  />
                </div>
              </div>
              {pageType === "transfer" && (
                <button
                  className="btn mt-3 btn-primary w-100"
                  onClick={() => {
                    handleRemove(item.id)
                  }}>
                  {t("Remove")}
                </button>
              )}
            </div>
          </Accordion.Collapse>
        </div>
      </div>
    </>
  )
}

export default Item
