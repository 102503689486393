import React, {
  createContext,
  useCallback,
  useContext,
  useReducer
} from "react"

const SET_ORDER_SEARCH = "SET_ORDER_SEARCH"
const SET_ORDER_HEADER = "SET_ORDER_HEADER"

const SET_RENTAL = "SET_RENTAL"
const DELETE_RENTAL = "DELETE_RENTAL"
const RESET_RENTAL = "RESET_RENTAL"

const SET_NEW_RENTAL = "SET_NEW_RENTAL"
const DELETE_NEW_RENTAL = "DELETE_NEW_RENTAL"
const RESET_NEW_RENTAL = "RESET_NEW_RENTAL"

const SET_DEBIT_TYPES = "SET_DEBIT_TYPES"

const OrderContext = createContext()

const defaultState = {
  searchData: {
    searchText: "",
    offset: 0,
    currentPage: 0,
    data: [],
    pageInfo: { total: 0 }
  },
  orderHeader: {
    customerOrderNumber: "",
    customerOrderId: "",
    customerName: ""
  },
  rentals: [],
  newRentals: [],
  debitTypes: []
}

export const OrderProvider = ({ children }) => {
  const memoizedReducer = useCallback((state, action) => {
    switch (action.type) {
      case SET_ORDER_SEARCH: {
        const currentState = state

        currentState.searchData = Object.assign(state.searchData, action.data)

        return Object.assign({}, currentState)
      }

      case SET_RENTAL: {
        const currentOrderState = state.rentals

        const findIndex = currentOrderState.findIndex(
          (item) =>
            item.orderId === action.data.orderId && item.id === action.data.id
        )

        if (findIndex > -1) {
          const previousData = currentOrderState.find(
            (item) =>
              item.orderId === action.data.orderId && item.id === action.data.id
          )

          currentOrderState[findIndex] = { ...previousData, ...action.data }
        } else {
          currentOrderState.push(action.data)
        }

        return {
          ...state,
          rentals: [...currentOrderState]
        }
      }

      case DELETE_RENTAL: {
        const { id, orderId } = action.data
        const currentState = state

        const filteredOrderData = currentState.rentals.filter(
          (item) => item.id !== id || item.orderId !== orderId
        )

        return {
          ...state,
          rentals: [...filteredOrderData]
        }
      }

      case RESET_RENTAL: {
        return {
          ...state,
          rentals: []
        }
      }

      case SET_NEW_RENTAL: {
        const currentNewRentalsState = state.newRentals

        action.data.forEach((actionData) => {
          const findIndex = currentNewRentalsState.findIndex(
            ({ internalId }) => {
              return internalId === actionData.internalId
            }
          )

          if (findIndex > -1) {
            const previousData = currentNewRentalsState.find(
              ({ internalId }) => {
                return internalId === actionData.internalId
              }
            )

            currentNewRentalsState[findIndex] = {
              ...previousData,
              ...actionData
            }
          } else {
            currentNewRentalsState.push({
              ...actionData,
              sort: currentNewRentalsState.length + 1
            })
          }
        })

        return {
          ...state,
          newRentals: currentNewRentalsState
        }
      }

      case DELETE_NEW_RENTAL: {
        const currentNewRentalState = state.newRentals

        const filteredRentals = currentNewRentalState.filter((item) => {
          return item.internalId !== action.data.internalId
        })

        return {
          ...state,
          newRentals: [...filteredRentals]
        }
      }

      case RESET_NEW_RENTAL: {
        return {
          ...state,
          newRentals: []
        }
      }

      case SET_ORDER_HEADER: {
        const currentState = state

        currentState.orderHeader = Object.assign(state.orderHeader, action.data)

        return Object.assign({}, currentState)
      }

      case SET_DEBIT_TYPES: {
        const currentState = state

        currentState.debitTypes = Object.assign(state.debitTypes, action.data)

        return Object.assign({}, currentState)
      }

      default:
        return state
    }
  }, [])

  const [state, dispatch] = useReducer(memoizedReducer, defaultState)

  const setSearchData = (data) => dispatch({ type: SET_ORDER_SEARCH, data })
  const setOrderHeader = (data) => dispatch({ type: SET_ORDER_HEADER, data })

  const setRental = (data) => dispatch({ type: SET_RENTAL, data })
  const deleteRental = (data) => dispatch({ type: DELETE_RENTAL, data })
  const resetRental = () => dispatch({ type: RESET_RENTAL })

  const deleteNewRental = (data) => dispatch({ type: DELETE_NEW_RENTAL, data })
  const setNewRental = (data) => dispatch({ type: SET_NEW_RENTAL, data })
  const resetNewRental = () => dispatch({ type: RESET_NEW_RENTAL })

  const setDebitTypes = (data) => dispatch({ type: SET_DEBIT_TYPES, data })

  return (
    <OrderContext.Provider
      value={{
        searchData: state.searchData,
        rentalData: state.rentals,
        newRentalsData: state.newRentals,
        orderHeader: state.orderHeader,
        debitTypes: state.debitTypes,
        setSearchData,
        setOrderHeader,
        setRental,
        resetRental,
        deleteRental,
        deleteNewRental,
        setNewRental,
        resetNewRental,
        setDebitTypes
      }}>
      {children}
    </OrderContext.Provider>
  )
}

const useOrderContext = () => useContext(OrderContext)

export function useSearchData() {
  const { searchData, setSearchData } = useOrderContext()
  return [searchData, setSearchData]
}

export function useOrderHeader() {
  const { orderHeader, setOrderHeader } = useOrderContext()
  return [orderHeader, setOrderHeader]
}

export default useOrderContext
