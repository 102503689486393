import i18n from "../../../../i18n"

export const companyProfileFormTemplate = {
  fields: [
    {
      name: "name",
      type: "text",
      title: i18n.t("Company name"),
      fieldClassName: "col-12 col-md-6 mb-3",
      // required: {
      //   value: true,
      //   message: i18n.t("Required field")
      // },
      readOnly: true
    },
    {
      name: "number",
      type: "text",
      title: i18n.t("Client number"),
      // required: {
      //   value: true,
      //   message: i18n.t("Required field")
      // },
      readOnly: true
    },
    {
      name: "corporateIdentityNumber",
      type: "text",
      title: i18n.t("Corporate identity number"),
      // required: {
      //   value: true,
      //   message: i18n.t("Required field")
      // },
      readOnly: true
    },
    {
      name: "accountName",
      type: "text",
      title: i18n.t("Customer account"),
      fieldClassName: "col-12 col-md-6 mb-3",
      required: {
        value: true,
        message: i18n.t("Required field")
      },
      disabled: false,
      readOnly: false
    },
    {
      name: "email",
      type: "email",
      title: i18n.t("Email"),
      fieldClassName: "col-12 col-md-6 mb-3",
      readOnly: true
    },
    {
      name: "telephone",
      type: "text",
      title: i18n.t("Phone"),
      fieldClassName: "col-12 col-md-6 mb-3",
      readOnly: true
    },
    {
      name: "mobileNumber",
      type: "text",
      title: i18n.t("Mobile phone number"),
      fieldClassName: "col-12 col-md-6 mb-3",
      readOnly: true
    },
    {
      name: "offset",
      type: "offset",
      fieldClassName: "col-12 col-md-6",
      title: ""
    },
    {
      name: "header",
      type: "header",
      fieldClassName: "col-12",
      title: i18n.t("Postal address")
    },
    {
      name: "adressLine1",
      type: "text",
      title: i18n.t("Name/Company"),
      fieldClassName: "col-12 col-md-6 mb-3",
      scope: "postalAdress",
      readOnly: true
    },
    {
      name: "adressLine2",
      type: "text",
      title: i18n.t("C/o"),
      fieldClassName: "col-12 col-md-6 mb-3",
      scope: "postalAdress",
      readOnly: true
    },
    {
      name: "adressLine3",
      type: "text",
      fieldClassName: "col-12 col-md-6 mb-3",
      title: i18n.t("Address"),
      scope: "postalAdress",
      readOnly: true
    },
    {
      name: "postalCode",
      type: "text",
      fieldClassName: "col-12 col-md-6 mb-3",
      title: i18n.t("Zip code"),
      scope: "postalAdress",
      readOnly: true
    },
    {
      name: "city",
      type: "text",
      fieldClassName: "col-12 col-md-6",
      title: i18n.t("City"),
      scope: "postalAdress",
      readOnly: true
    }
  ]
}
