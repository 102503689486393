import axios from "axios"

const SearchOrder = async (t, inputValue, regionId, setLoading, setError) => {
  let data = []
  setLoading(true)
  const limit = 1000
  const offset = 0
  const body = {
    customerOrderNumber: {
      logicalOperator: "Or",
      predicate: "Contains",
      value: inputValue
    },
    customerNumber: {
      logicalOperator: "Or",
      predicate: "Contains",
      value: inputValue
    },
    fleetItemNumber: {
      logicalOperator: "Or",
      predicate: "Contains",
      value: inputValue
    },
    customerName: {
      logicalOperator: "Or",
      predicate: "Contains",
      value: inputValue
    },
    siteId: {
      logicalOperator: "Or",
      predicate: "Contains",
      value: inputValue
    },
    siteName: {
      logicalOperator: "Or",
      predicate: "Contains",
      value: inputValue
    },
    siteNumber: {
      logicalOperator: "Or",
      predicate: "Contains",
      value: inputValue
    },
    regionId: {
      logicalOperator: "And",
      predicate: "Equal",
      value: regionId
    }
  }

  await axios
    .post("/api/hll/customerOrderQuery", {
      body,
      limit,
      offset
    })
    .then((response) => {
      data = response?.data?.data ? response.data.data : []
    })
    .catch((err) => {
      setError(
        `${t("Something went wrong")}: ${err?.response?.data?.CorrelationId}`
      )
    })
    .finally(() => {
      setLoading(false)
    })

  return data
}

export default SearchOrder
