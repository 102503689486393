import React from "react"
import { useSortableData } from "../../utils/hooks"
import TableSkeletonLoader from "../Common/TableSkeletonLoader"
import Contact from "./Contact"
import { useMediaQuery } from "react-responsive"
import { tableHeader, hasUserAccount } from "./helpers"

const ContactPersonsTable = ({
  contacts,
  loading = false,
  users,
  updateAction,
  updatedContact,
  handleInvite,
  handleEdit,
  handleDelete,
  setShowModalSheet,
  setModalSheetData
}) => {
  const { items, handleSort } = useSortableData(contacts)
  return (
    <>
      <table className="table table-sm table-hover table-nowrap card-table">
        <thead>
          <tr>
            {useMediaQuery({ query: "(max-width: 990px)" }) && <th></th>}
            {tableHeader.map((header) => {
              return (
                <th
                  key={header.name}
                  className={`text-${header.alignment} text-gray-700`}>
                  {header.title !== "" && header.sortable ? (
                    <button
                      data-sort
                      type="button"
                      onClick={() => handleSort(header.name)}
                      className="btn-table-header-sort">
                      {header.title}
                    </button>
                  ) : (
                    ""
                  )}
                </th>
              )
            })}
          </tr>
        </thead>

        {loading ? (
          <tbody>
            <TableSkeletonLoader row={10} cells={7} />
          </tbody>
        ) : (
          <>
            <tbody className="list font-size-base">
              {items.map((contact) => {
                return (
                  <Contact
                    key={contact.id}
                    data={contact}
                    hasUserAccount={hasUserAccount(users, contact.email)}
                    updateAction={updateAction}
                    updatedContact={updatedContact}
                    onHandleInvite={handleInvite}
                    onHandleEdit={handleEdit}
                    onHandleDelete={handleDelete}
                    setShowModalSheet={setShowModalSheet}
                    setModalSheetData={setModalSheetData}
                  />
                )
              })}
            </tbody>
          </>
        )}
      </table>
    </>
  )
}

export default ContactPersonsTable
