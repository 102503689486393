import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Storage from "../../utils/storage"
import Moment from "react-moment"
import "moment-timezone"
import { singleUrl } from "../../utils/helpers"
import Alert from "../../components/Common/Alert"
import { useQuery } from "@tanstack/react-query"

import TableSkeletonLoader from "../Common/TableSkeletonLoader"
import CustomSvgIcon from "../Common/CustomSvgIcon"

import { getSiteQuery } from "../../utils/hooks"

const LatestProjectCard = () => {
  const { t } = useTranslation()
  const customer = Storage.get("customer", null, "local") ?? null

  const { data, isLoading, isError } = useQuery({
    queryKey: [`siteQuery-${customer?.id}`],
    queryFn: async () => {
      const limit = 1000
      const offset = 0
      const body = {
        customerId: {
          logicalOperator: "And",
          predicate: "Equal",
          value: customer?.id
        },
        activeStatus: {
          logicalOperator: "And",
          predicate: "Equal",
          values: ["AllActive"]
        }
      }
      const result = await getSiteQuery(body, limit, offset)
      return result
    },
    enabled: !!customer?.id,
    cacheTime: 60000 * 60 * 12 // 12 hours
  })

  const projects =
    data && data.length > 0
      ? data
          .sort((a, b) => (b.startTime > a.startTime ? 1 : -1))
          .filter(
            (project) => project.number !== customer?.corporateIdentityNumber
          )
          .splice(0, 4)
      : []

  return (
    <div className="card h-100">
      <div className="card-header">
        <h4 className="card-header-title">{t("Latest project")}</h4>
        <Link to={`/${t("projects")}`} className="small">
          {t("View all projects")}
        </Link>
      </div>

      {isLoading && (
        <div className="table-responsive">
          <table className="table table-sm table-hover table-nowrap card-table">
            <tbody>
              <TableSkeletonLoader rows={5} cells={1} />
            </tbody>
          </table>
        </div>
      )}

      {isError && (
        <Alert
          className="p-4 text-center"
          message={t("Something went wrong, please refresh current page.")}
        />
      )}

      {!isLoading && projects && projects.length === 0 && (
        <div className="p-4 text-center">{t("Can't find any projects")}</div>
      )}

      {!isLoading && projects.length !== 0 && (
        <div className="card-body">
          <ul className="list-group list-group-flush my-n3">
            {projects.map((project, index) => (
              <li
                key={project.id}
                className={`list-group-item border-top-0 border-left-0 border-right-0${
                  index === projects.length - 1 ? " border-bottom-0" : ""
                }`}>
                <Link
                  to={{
                    pathname: singleUrl(
                      `${t("projects")}`,
                      project.customerId,
                      project.id
                    ),
                    customerId: project.customerId,
                    id: project.id,
                    name: project.name,
                    number: project.number
                  }}
                  className="d-block">
                  <div className="row align-items-center">
                    <div className="col-auto align-self-start">
                      <CustomSvgIcon
                        width={16}
                        height={16}
                        name="file"
                        className="text-muted"
                      />
                    </div>
                    <div className="col">
                      <p className="body-text font-weight-normal my-0">
                        {project?.number && project.number}
                        {project?.name && ` ${project.name}`}
                      </p>

                      <p className="text-gray-700 my-0">
                        <small>
                          {t("Created at")}:{" "}
                          <Moment
                            locale="sv"
                            dateTime={project.startTime}
                            format="YYYY-MM-DD">
                            {project.startTime}
                          </Moment>
                        </small>
                      </p>
                    </div>

                    <div className="col-auto">
                      <CustomSvgIcon
                        width={16}
                        height={16}
                        name="chevron-right"
                        className="text-muted"
                      />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default LatestProjectCard
