import React, { useEffect } from "react"
import { useOidcAccessToken } from "@axa-fr/react-oidc"
import { useTranslation } from "react-i18next"
import Logo from "../../assets/images/HLL_notext.svg"

import Storage from "../../utils/storage"
import { useCookies } from "react-cookie"

const ErrorOnAuthenticate = () => {
  const { t } = useTranslation()

  const { accessToken } = useOidcAccessToken()

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies([])
  useEffect(() => {
    const timer = window.setInterval(() => {
      Storage.remove("userGroups")
      Storage.remove("customer", "local")
      Storage.remove("userState", "local")
    }, 3000)
    return () => {
      // Return callback to run on unmount.
      window.clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    setCookie("HLL.JWT.AuthToken", accessToken, { path: "/" })
  }, [accessToken, setCookie])

  return (
    <>
      <div className="container my-5 text-center">
        <div className="row mb-5">
          <div className="col-12">
            <figure className="d-block m-0 pb-5">
              <img
                src={Logo}
                alt="Hyreslandslagets logotyp"
                style={{ width: "50px" }}
                className="logo"
              />
            </figure>
            <h1>{t("An error occurred! Click to reload")}</h1>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => (window.location.href = "/")}>
              {t("Click to reload!")}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ErrorOnAuthenticate
