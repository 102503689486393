import React, { useState } from "react"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import PermissionForm from "./Form"
import Loader from "../Loader/Loader"
import toast from "react-hot-toast"

import Storage from "../../utils/storage"
import { dateAndTimeNow } from "../../utils/helpers"
import moment from "moment"
import "moment-timezone"
import { hasUserAccount } from "./helpers"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const ModalForm = ({
  show,
  onHandleHide,
  title,
  subTitle,
  submitText,
  formTemplate,
  formData,
  action,
  requiresIdForRentalCustomer,
  userGroups,
  users
}) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    shouldUnregister: true,
    mode: "all"
  })

  const handleForm = {
    register,
    errors,
    formData,
    action,
    control,
    setError,
    clearErrors,
    setValue
  }

  const [loading, setLoading] = useState(false)
  const [responseData, setResponseData] = useState(null)
  const [findingUserAccount, setFindingUserAccount] = useState(false)
  const [userAccountData, setUserAccountData] = useState(null)

  const customer = Storage.get("customer", null, "local") ?? null

  const handleHide = () => {
    setFindingUserAccount(false)
    onHandleHide(responseData)
  }

  // Update validTo date on userAcccount if edit end date
  const handleUpdateUserAccount = async () => {
    const data = responseData ?? null
    const user = userAccountData
    if (!data) {
      return
    }

    user.validTo = responseData?.endDate ?? null

    setLoading(true)

    await axios
      .post("/api/hll/customerUserEdit", {
        body: user
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          toast.success(t("The contact and the user has been saved!"))
          window.dataLayer.push({
            event: `HLL_user_edit`,
            company: customer?.name,
            createdAt: dateAndTimeNow(),
            userType: "customer"
          })

          handleHide()
        }
      })
      .catch((error) => {
        console.error(error)
        toast.error(t("Something went wrong, try again."))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSubmit = async (data) => {
    const { action } = data
    const endpoint = {
      invite: "customerUserInvite",
      add: "customerRentalContactAdd",
      edit: "customerRentalContactEdit"
    }

    if (!action) {
      return null
    }

    if (!endpoint) {
      return null
    }

    if (action === "invite" && !data?.userAccountGroups.length) {
      setError("userAccountGroups", {
        type: "custom",
        message: t("You must specify at least one permission.")
      })
      return null // do not continue if error or empty userAccountGroups
    }

    // We can't send the action property to the endpoint.
    delete data.action

    // Check if have a dateOfBirth
    if (!data?.dateOfBirth) {
      data.dateOfBirth = "" // set key to empty string if is not exists
    }

    // Check if have a endDate
    if (!data?.endDate) {
      data.endDate = null // set key to null if is not exists
    } else {
      // Formating date for API
      data.endDate = moment(data?.endDate).format("YYYY-MM-DD[T]00:00:00[Z]")
    }

    let formattedData = data
    //console.log(data, "data")

    // Format data for user invititation
    if (action === "invite") {
      formattedData = {
        forRegistrationNumber: customer
          ? customer?.corporateIdentityNumber
          : "",
        userAccount: {
          ...data,
          id: "",
          userAccountGroups: data.userAccountGroups
        }
      }
    }

    // Format data for user edit
    if (action === "edit") {
      delete data?.userAccountGroups
      formattedData = data
    }

    setFindingUserAccount(false)
    setLoading(true)
    setResponseData(null)

    await axios
      .post(`/api/hll/${endpoint[action]}`, {
        body: formattedData
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          window.dataLayer.push({
            event: `HLL_contact_person_${action}`,
            company: customer?.name,
            createdAt: dateAndTimeNow(),
            userType: "customer",
            endDate: formattedData?.endDate ? true : false
          })

          setResponseData(response.data)

          if (
            action === "edit" &&
            formattedData?.endDate &&
            hasUserAccount(users, formattedData?.email)
          ) {
            setFindingUserAccount(true)
            setUserAccountData(hasUserAccount(users, formattedData?.email))
            return null
          } else {
            if (action === "invite") {
              toast.success(t("User invited"))
            } else {
              toast.success(t("The contact has been saved!"))
            }
            handleHide()
          }
        }
      })
      .catch((error) => {
        console.error(error)
        if (action === "invite") {
          toast.error(t("Something went wrong, the user may already exist!?"))
        } else {
          toast.error(t("Something went wrong, try again."))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal show={show} onHide={handleHide} centered animation={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header className="justify-content-center">
          <h3 className="h1 header-title mt-3 mb-4 text-center">
            {findingUserAccount ? t("User account") : title && title}
          </h3>
        </Modal.Header>
        {subTitle && <p className="header-subtitle text-center">{subTitle}</p>}
        <Modal.Body>
          <div className="row">
            {findingUserAccount ? (
              <div className="text-center mx-auto">
                <p
                  className="d-flex align-items-center
     justify-content-center mb-0">
                  <CustomSvgIcon
                    width={16}
                    height={16}
                    name="check-circle-selected-filled"
                    className={`text-success with-multiple-paths mr-2`}
                  />
                  <strong>{responseData?.email}</strong>
                </p>
                <p>{`${t(
                  "We have found a user account linked to this email address, do you also want to set an expiration date on the user"
                )}?`}</p>
              </div>
            ) : (
              <PermissionForm
                formTemplate={formTemplate}
                handleForm={handleForm}
                requiresIdForRentalCustomer={requiresIdForRentalCustomer}
                userGroups={userGroups}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading && <Loader />}
          {findingUserAccount ? (
            <>
              <button
                className="btn btn-white"
                type="button"
                onClick={() => {
                  toast.success(t("The contact has been saved!"))
                  handleHide()
                }}
                disabled={loading}>
                {t("No")}
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={loading}
                onClick={handleUpdateUserAccount}>
                {t("Yes")}
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-white"
                type="button"
                onClick={handleHide}
                disabled={loading}>
                {t("Close")}
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={loading}>
                {submitText}
              </button>
            </>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ModalForm
