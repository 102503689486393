import React from "react"
import { useTranslation } from "react-i18next"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const SummaryHeader = ({ data }) => {
  const { t } = useTranslation()

  return (
    <div className="py-3">
      <div className="d-flex align-items-center justify-content-center">
        <CustomSvgIcon
          name="check-circle-selected"
          width={50}
          height={50}
          className={`text-center with-multiple-paths mb-3`}
        />
      </div>
      <h3>{t("Order information")}</h3>
      <div className="bg-white p-4 border rounded">
        <div>
          <span className="d-block text-uppercase font-xs mb-1">
            {t("Client")}
          </span>
          {data?.customerName}
        </div>
        <div className="mt-3">
          <span className="d-block text-uppercase font-xs mb-1">
            {t("Client number")}
          </span>
          {data?.customerNumber}
        </div>
        {data?.siteName && (
          <div className="mt-3">
            <span className="d-block text-uppercase font-xs mb-1">
              {t("Project")}
            </span>
            {data?.siteName}
          </div>
        )}
        <div className="mt-3">
          <span className="d-block text-uppercase font-xs mb-1">
            {t("Reference")}
          </span>
          {data?.rentalContact?.name}
        </div>
        {data?.pickupBy?.name && data?.pickupBy?.name !== "" && (
          <div className="mt-3">
            <span className="d-block text-uppercase font-xs mb-1">
              {t("Pickup by")}
            </span>
            {data?.pickupBy?.name}
          </div>
        )}
        <div className="mt-3">
          <span className="d-block text-uppercase font-xs mb-1">
            {t("Order Number")}
          </span>
          <span className="text-primary">{data?.customerOrderNumber}</span>
        </div>
      </div>
    </div>
  )
}

export default SummaryHeader
