import React from "react"
import { NavLink } from "react-router-dom"

const NavItem = ({ to, text, icon, clickHandler }) => {
  return (
    <NavLink
      activeclassname="text-primary"
      end
      to={to}
      className={({ isActive }) =>
        isActive
          ? "text-primary d-flex align-items-center link-site-nav"
          : "d-flex align-items-center link-site-nav"
      }
      onClick={clickHandler}>
      {icon && (
        <span
          className={`fe ${icon} text-primary mr-3`}
          style={{ fontSize: 25 }}></span>
      )}
      <span className="font-weight-normal">{text}</span>
    </NavLink>
  )
}

export default NavItem
