import React from "react"

function PageHeader({
  preTitle = "",
  title = "",
  subTitle = "",
  button = {},
  onClickHandler = () => {}
}) {
  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="header">
          <div className="header-body d-sm-flex align-items-center">
            <div className="mb-3 mb-sm-0">
              {preTitle && <h6 className="header-pretitle">{preTitle}</h6>}
              {title && <h1 className="header-title">{title}</h1>}
              {subTitle && <p className="header-subtitle">{subTitle}</p>}
            </div>
            {Object.keys(button).length !== 0 && (
              <button
                type="button"
                className="ml-auto btn btn-primary"
                title={button.text}
                onClick={onClickHandler}>
                {button.text}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageHeader
