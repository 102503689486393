import React from "react"
import { useOidcUser } from "@axa-fr/react-oidc"
import InternalVerificationModal from "./InternalVerificationModal"

export default function InternalRegionModalWrapper({
  showInternalRegionModal,
  setShowInternalRegionModal
}) {
  const { oidcUser } = useOidcUser()

  if (!oidcUser) {
    return null
  }

  const perm = oidcUser?.perm

  if (!perm) {
    return null
  }

  if (!perm.includes("MinaSidor.SkapaRetur")) {
    return null
  }

  return (
    <InternalVerificationModal
      showInternalRegionModal={showInternalRegionModal}
      setShowInternalRegionModal={setShowInternalRegionModal}
    />
  )
  //return null
}
