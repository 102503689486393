import React, { useState, useEffect } from "react"
import { Controller } from "react-hook-form"
import DatePicker, { registerLocale } from "react-datepicker"
import sv from "date-fns/locale/sv"
import "react-datepicker/dist/react-datepicker.css"
registerLocale("sv", sv)

function PermissionForm({ formTemplate, handleForm, userGroups }) {
  const { register, errors, formData, action, setValue, clearErrors, control } =
    handleForm
  const { fields } = formTemplate
  const [userGroupDataState, setUserGroupDataStateState] = useState(
    formData?.userAccountGroups ? formData?.userAccountGroups : []
  )

  const [validToState, setValidToState] = useState(
    formData?.validTo ? new Date(formData?.validTo) : null
  )

  const handleChangeValidTo = (date) => {
    setValidToState(date)
  }

  useEffect(() => {
    setValue("userAccountGroups", userGroupDataState)
  }, [setValue, userGroupDataState])

  useEffect(() => {
    setValue("validTo", validToState)
  }, [setValue, validToState])

  const renderFields = (fields) => {
    return fields.map((field) => {
      const { type, title, name, required, instructions } = field

      switch (type) {
        case "action":
          return (
            <input
              key={name}
              type="hidden"
              name={name}
              defaultValue={action}
              {...register(name)}
            />
          )

        case "hidden":
          return (
            <input
              key={name}
              type="hidden"
              name={name}
              defaultValue={
                typeof formData[name] !== "undefined" ? formData[name] : ""
              }
              {...register(name)}
            />
          )

        case "text":
          return (
            <div className="col-12 col-sm-6 mb-3" key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {required && "*"}
              </label>
              <input
                type="text"
                id={name}
                name={name}
                defaultValue={
                  typeof formData[name] !== "undefined" ? formData[name] : ""
                }
                className="form-control form-control-modal"
                {...register(name, { required })}
              />
              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}
              {instructions && (
                <span className="text-muted font-xs mt-2">{instructions}</span>
              )}
            </div>
          )

        case "email":
          return (
            <div className="col-12 col-sm-6 mb-3" key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {required && "*"}
              </label>
              <input
                type={type}
                id={name}
                name={name}
                readOnly={action === "edit"}
                defaultValue={
                  typeof formData[name] !== "undefined" ? formData[name] : ""
                }
                className={`form-control form-control-modal${
                  action === "edit" ? " disabled" : ""
                }`}
                {...register(name, { required })}
              />
              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}
              {instructions && (
                <span className="text-muted font-xs mt-2">{instructions}</span>
              )}
            </div>
          )

        case "date":
          return (
            <div className="col-12 col-sm-6 mb-3" key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {required && "*"}
              </label>
              <Controller
                control={control}
                name={name}
                render={() => (
                  <DatePicker
                    onChange={(date) => handleChangeValidTo(date)}
                    selected={validToState}
                    dateFormat="yyyy-MM-dd"
                    className="form-control form-control-modal"
                  />
                )}
              />
              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}
              {instructions && (
                <span className="text-muted font-xs mt-2">{instructions}</span>
              )}
            </div>
          )

        case "userAccountGroups":
          return (
            <div className="col-12 mt-3 mb-3" key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
              </label>

              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}

              {userGroups &&
                userGroups?.map((userGroup, index) => {
                  const fieldName = `userAccountGroupsData[${index}]`
                  const findGroupIndex = userGroupDataState.find(
                    (group) => group?.id === userGroup?.id
                  )

                  return (
                    <div className="checkbox-rows py-3" key={userGroup?.id}>
                      <div className="d-flex align-items-start">
                        <input
                          type="checkbox"
                          name={fieldName}
                          className="form-check-input list-checkbox flex-shrink-0"
                          checked={findGroupIndex ? true : false}
                          value={
                            userGroupDataState.length > 0
                              ? JSON.stringify(userGroupDataState[index])
                              : JSON.stringify(userGroup)
                          }
                          onChange={(e) => {
                            const checked = e.target.checked

                            clearErrors("userAccountGroups") // clearing error
                            if (checked) {
                              setUserGroupDataStateState((groups) =>
                                groups.concat(userGroup)
                              )
                            } else {
                              setUserGroupDataStateState((current) =>
                                current.filter(
                                  (group) =>
                                    JSON.stringify(group) !==
                                    JSON.stringify(userGroup)
                                )
                              )
                            }
                          }}
                        />
                        <p className="ml-3 mb-0">
                          <span className="d-block">{userGroup?.name}</span>
                          <span className="d-block text-muted font-xs mt-2">
                            {userGroup?.name === "Administratör" &&
                              `Behörighet att se och redigera alla funktioner under Mitt HLL, tex projekt, behöriga att hyra, faktureringsuppgifter, dokumentmottagare och även administrera användare.`}

                            {userGroup?.name === "Ekonomi" &&
                              `Behörighet att se och ändra faktureringsuppgifter, som tex faktureringsmetod och adress.`}

                            {userGroup?.name === "Standardanvändare" &&
                              `Behörighet att se projekt och dess innehåll, boka returtransport, se depåinformation och se fakturauppgifter.`}
                          </span>
                        </p>
                      </div>
                    </div>
                  )
                })}
            </div>
          )

        default:
          return <div className="col-12">Invalid field</div>
      }
    })
  }

  return renderFields(fields)
}

export default PermissionForm
