import React, { useEffect, useState } from "react"
import { availabilityColorMap } from "../../../utils/helpers"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import { machineState, pageTypeState } from "../Store"
import Storage from "../../../utils/storage"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const SearchItem = ({ item, index, handleClick }) => {
  const userState = Storage.get("userState") ?? null

  const usesAvaliability =
    userState?.internal?.region?.settings?.usesAvaliability

  const machineTransfers = useRecoilValue(machineState)
  const pageType = useRecoilValue(pageTypeState)
  const { t } = useTranslation()
  const findMachine = machineTransfers.findIndex(
    (machine) => machine.id === item.id
  )

  const [inTransport, setInTransport] = useState(item?.inTransport ?? false)
  const [isDisabled, setIsDisabled] = useState(false)

  // This is the "inTransport" state that is used in the "SearchItem" component.
  useEffect(() => {
    if (typeof item?.inTransport === "undefined") {
      setInTransport(false)
    } else if (item?.inTransport) {
      setInTransport(true)
    } else {
      setInTransport(false)
    }
  }, [item?.inTransport])

  // This is the "isDisabled" state that is used in the "SearchItem" component.
  useEffect(() => {
    if (!usesAvaliability) {
      setIsDisabled(false)
    } else {
      if (typeof item.availability === "undefined") {
        // This must be an article...because an article do not have "availability".
        setIsDisabled(false)
      } else if (item.availability === 0) {
        setIsDisabled(true)
      } else if (item.isLocked) {
        setIsDisabled(true)
      }
    }
  }, [item?.availability, item?.isLocked, usesAvaliability])

  return (
    <button
      className={`d-block w-100 bg-white border-bottom p-0 text-body${
        isDisabled || inTransport ? " is-disabled" : ""
      }${findMachine > -1 ? " is-selected" : ""}${
        index === 0 ? " border-top" : ""
      }`}
      onClick={() => {
        handleClick(item)
      }}
      disabled={isDisabled || inTransport}>
      <div className="p-3">
        <div className="row d-flex align-items-center justify-content-between">
          <div className="col">
            <div className="row">
              <div className="col">
                <span className="d-block font-weight-bold text-left">
                  {item?.name && item.name}
                </span>
                {isDisabled ||
                  (inTransport && (
                    <span className="text-muted d-block mt-1 font-xs text-left">
                      {pageType === "transfer"
                        ? t(
                            "NB! The machine/article is not available for transfer right now"
                          )
                        : t("NB! Unable to add machine/article")}
                    </span>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="d-flex align-items-center">
              {usesAvaliability && item.internalType === "INDIVIDUAL" && (
                <span
                  className={`machine-item-availability d-block mr-2 flex-shrink-0 availability--${availabilityColorMap(
                    item.availability
                  )}`}></span>
              )}
              {item?.isLocked ? (
                <CustomSvgIcon
                  name="lock"
                  width={22}
                  height={22}
                  className="machine-transfer-item-icon"
                />
              ) : findMachine > -1 ? (
                <CustomSvgIcon
                  name="remove"
                  width={22}
                  height={22}
                  className="machine-transfer-item-icon"
                />
              ) : (
                <CustomSvgIcon
                  name="add"
                  width={22}
                  height={22}
                  className="machine-transfer-item-icon"
                />
              )}
            </div>
          </div>
        </div>
        <div className="bg-light rounded border border-light border-1 mt-3">
          <div className="row d-flex align-items-center justify-content-between g-0">
            <div className="col">
              <span className="d-block text-muted text-gray-700 py-2 px-3 text-left">
                {item?.number && item.number}
              </span>
            </div>
          </div>
        </div>
      </div>
    </button>
  )
}

export default SearchItem
