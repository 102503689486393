import React from "react"
import { useTranslation } from "react-i18next"
import { useRecoilState } from "recoil"
import { newInventoryFind } from "../../Store"
import Storage from "../../../../utils/storage"
import ActionFooter from "../../../Common/ActionFooter"
import CustomSvgIcon from "../../../Common/CustomSvgIcon"
import uuid from "react-uuid"
import { disabledControlPhaseFilter } from "../../../../utils/helpers"

export default function Filters({
  showFilters,
  setShowFilters,
  setIsFiltering,
  loading,
  data,
  setData,
  setSorting,
  sorting
}) {
  const { t } = useTranslation()
  const [newInventoryFindState, setNewInventoryFindState] =
    useRecoilState(newInventoryFind)

  const userState = Storage.get("userState") ?? null

  const handleSorting = (event) => {
    const { name, id } = event.target

    setSorting({
      key: "sort",
      [name]: id
    })

    let filteredData = [...data]

    if (id === "numberASC") {
      filteredData?.sort((a, b) =>
        a?.number.toLowerCase() > b?.number.toLowerCase() ? 1 : -1
      )
    }

    if (id === "numberDESC") {
      filteredData?.sort((a, b) =>
        a?.number.toLowerCase() < b?.number.toLowerCase() ? 1 : -1
      )
    }

    if (id === "nameASC") {
      filteredData?.sort((a, b) =>
        a?.fleetModelName.toLowerCase() > b?.fleetModelName.toLowerCase()
          ? 1
          : -1
      )
    }

    if (id === "nameDESC") {
      filteredData?.sort((a, b) =>
        a?.fleetModelName.toLowerCase() < b?.fleetModelName.toLowerCase()
          ? 1
          : -1
      )
    }

    setData(filteredData)
  }

  const handleInternalRegionQuery = (regionId) => {
    setIsFiltering(true)

    const exists = newInventoryFindState.filters.selectedCompanies.find(
      (id) => id === regionId
    )

    if (exists) {
      setNewInventoryFindState((previousNewInventoryFindState) => {
        return {
          ...previousNewInventoryFindState,
          filters: {
            ...previousNewInventoryFindState.filters,
            selectedCompanies:
              previousNewInventoryFindState.filters.selectedCompanies.filter(
                (id) => id !== regionId
              )
          }
        }
      })
    } else {
      setNewInventoryFindState((previousNewInventoryFindState) => {
        return {
          ...previousNewInventoryFindState,
          filters: {
            ...previousNewInventoryFindState.filters,
            selectedCompanies: [
              ...previousNewInventoryFindState.filters.selectedCompanies,
              regionId
            ]
          }
        }
      })
    }
  }

  const internalRegions = newInventoryFindState?.filters?.internalRegions ?? []

  const disabledFiltering = disabledControlPhaseFilter(
    userState?.internal?.region?.regionId,
    [3] // -> region stockholm
  )

  return (
    <>
      <nav
        className={`nav-off-canvas bg-white h-100 text-body position-fixed js-app-off-canvas${
          showFilters ? " is-open" : ""
        }`}>
        <div className="nav-off-canvas-inside position-relative">
          <div className="nav-off-canvas-header d-flex justify-content-center align-items-center">
            <div>
              <h3 className="mb-0">{t("Filter")}</h3>
            </div>
            <button
              disabled={loading}
              onClick={() => {
                setShowFilters(!showFilters)
              }}
              className="border-0 bg-white d-block close-nav-button text-body position-absolute"
              style={{ right: 0, marginRight: "7px" }}>
              <CustomSvgIcon
                name="close"
                width={16}
                height={16}
                className="text-black"
              />
            </button>
          </div>
        </div>
        <div className="nav-off-canvas-body position-relative">
          <div className="nav-off-canvas-nav-list m-0 my-4">
            <div className="filter-group border-bottom mt-4">
              <div className="row mb-4">
                <div className="col-12">
                  <h3>{t("Region")}</h3>
                </div>
                <div className="col-12">
                  {internalRegions &&
                    internalRegions.length > 0 &&
                    internalRegions?.map((region, i) => {
                      let defaultChecked = true

                      return (
                        <label
                          key={i}
                          className="mt-1 mb-1 d-flex align-items-center">
                          <input
                            type="checkbox"
                            id="internalRegions"
                            name="internalRegions"
                            disabled={loading || disabledFiltering}
                            defaultChecked={defaultChecked}
                            onChange={() =>
                              handleInternalRegionQuery(region?.regionId)
                            }
                            className={`form-check-input list-checkbox mt-0 flex-shrink-0${
                              loading || disabledFiltering ? " is-disabled" : ""
                            }`}></input>
                          <span
                            className={`ml-2${
                              loading || disabledFiltering
                                ? " text-is-disabled"
                                : ""
                            }`}>
                            {region?.regionName}
                          </span>
                        </label>
                      )
                    })}
                </div>
              </div>
            </div>
            <div className="filter-group border-bottom mt-4">
              <div className="row mb-4">
                <div className="col-12">
                  <h3>{t("Sorting")}</h3>
                </div>
                <div className="col-12">
                  {["numberASC", "numberDESC"].map((val) => (
                    <label
                      className="mt-1 mb-1 d-flex align-items-center"
                      key={uuid()}>
                      <input
                        type="radio"
                        id={val}
                        name="sort"
                        disabled={loading}
                        onChange={(e) => handleSorting(e)}
                        defaultChecked={sorting?.sort === val}
                        className="form-check-input list-checkbox mt-0 flex-shrink-0"></input>
                      <span className="ml-2">
                        {val === "numberASC"
                          ? t("Number (Ascending)")
                          : t("Number (Descending)")}
                      </span>
                    </label>
                  ))}
                  {["nameASC", "nameDESC"].map((val) => (
                    <label
                      className="mt-1 mb-1 d-flex align-items-center"
                      key={uuid()}>
                      <input
                        type="radio"
                        id={val}
                        name="sort"
                        disabled={loading}
                        onChange={(e) => handleSorting(e)}
                        defaultChecked={sorting?.sort === val}
                        className="form-check-input list-checkbox mt-0 flex-shrink-0"></input>
                      <span className="ml-2">
                        {val === "nameASC" ? t("Name A-Ö") : t("Name Ö-A")}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ActionFooter className="d-flex align-items-center justify-content-center">
          <button
            disabled={loading}
            className={`btn btn-primary d-block w-100`}
            onClick={() => {
              setShowFilters(!showFilters)
            }}>
            {loading ? (
              <div
                className="spinner-border spinner-border-sm text-white"
                role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              `${t("Show machines")} (${data?.length ?? 0})`
            )}
          </button>
        </ActionFooter>
      </nav>
    </>
  )
}
