import React from "react"
import { useTranslation } from "react-i18next"
import { inventoryOccasionStatus } from "../../../../utils/helpers"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import "moment-timezone"
import CustomSvgIcon from "../../../Common/CustomSvgIcon"

export default function List({
  inventoryOccasionId,
  name,
  status,
  start,
  end
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <button
            className="bg-transparent d-block w-100 m-0 p-0 text-left"
            onClick={() =>
              navigate(
                `/${t("inventory")}/administration/${t(
                  "edit"
                )}/${encodeURIComponent(inventoryOccasionId)}`,
                { state: { inventoryOccasionId: inventoryOccasionId } }
              )
            }>
            <div className="row align-item-center">
              <div className="col position-relative">
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="card-header-title text-truncate mr-4">
                    {name ?? name}
                  </h2>
                  <CustomSvgIcon
                    width={16}
                    height={16}
                    name="chevron-right"
                    className="text-primary"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <p className="field__label form-label mt-1 mb-1 text-uppercase text-muted">
                  {t("Status")}
                </p>
                <p className="m-0">
                  {inventoryOccasionStatus(status ?? status)}
                </p>
              </div>

              {status > 0 && (
                <div className="col">
                  <p className="field__label form-label mt-1 mb-1 text-uppercase text-muted">
                    {status === 4 ? ` ${t("End date")}` : ` ${t("Start date")}`}
                  </p>
                  {status === 4 && end ? (
                    <p className="m-0">
                      {moment(end).format("YYYY-MM-DD HH:mm")}
                    </p>
                  ) : (
                    <p className="m-0">
                      {moment(start).format("YYYY-MM-DD HH:mm")}
                    </p>
                  )}
                </div>
              )}
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}
