import React, { useState, useEffect } from "react"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { useTranslation } from "react-i18next"
import Loader from "../../../components/Loader/Loader"
import SearchForm from "./SearchForm"
import ListItem from "../Orders/ListItem"
import Alert from "../../../components/Common/Alert"
import useOrderContext from "../../../context/ReturnOrder/Store"
import Storage from "../../../utils/storage"
import { useNavigate } from "react-router-dom"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const Search = () => {
  const { t } = useTranslation()
  const {
    searchData,
    setSearchData,
    setOrderHeader,
    resetRental,
    resetNewRental,
    newRentalsData,
    rentalData
  } = useOrderContext()
  const navigate = useNavigate()

  const userState = Storage.get("userState") ?? null
  const regionId = userState?.internal?.region?.regionId ?? 3

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    data: searchData.data,
    pageInfo: searchData.pageInfo
  })
  const [error, setError] = useState(false)
  const [errorCode, setErrorCode] = useState("")
  const [offset, setOffset] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [isSearching, setIsSearching] = useState(false)

  const perPage = 10

  const handlePageClick = ({ selected }) => {
    const newOffset = Math.ceil(selected * perPage)
    setOffset(newOffset)
    setSearchData({
      offset: newOffset,
      currentPage: selected
    })

    setSearchText(searchData.searchText)
  }

  const handleSearchSubmit = (event) => {
    const searchInput = event?.target?.elements.search

    setIsSearching(true)
    setSearchText(searchInput?.value)
    setOffset(0)
    setSearchData({ offset: 0, searchText: searchInput?.value, currentPage: 0 })
    searchInput.blur()
  }

  const handleSearchBlur = (value) => {
    setIsSearching(true)
    setSearchText(value)
    setOffset(0)
    setSearchData({ offset: 0, searchText: value, currentPage: 0 })
  }

  const handleItemClick = (url) => {
    navigate(url)
    setSearchData(data)
    setOrderHeader({
      customerOrderNumber: "",
      customerOrderId: "",
      customerName: ""
    })
  }

  const SearchMessage = () => {
    if (error || isSearching) {
      return null
    }

    if (searchText === "" && searchData.searchText === "") {
      return (
        <div className="py-4 text-center">
          <CustomSvgIcon
            name="search"
            width={50}
            height={50}
            className={"mb-2"}
          />
          <h3>{t("No search done")}</h3>
        </div>
      )
    }

    return !loading && data.data.length === 0 ? (
      <div className="py-4 text-center">
        <i
          className="fe fe-info d-block mb-2 text-primary"
          style={{ fontSize: 50 }}></i>
        <h3>{t("Can't find any orders")}</h3>
      </div>
    ) : null
  }

  useEffect(() => {
    let unmounted = false

    if (searchText !== "") {
      const body = {
        fleetItemNumber: {
          logicalOperator: "Or",
          predicate: "StartsWith",
          value: searchText
        },
        customerOrderNumber: {
          logicalOperator: "Or",
          predicate: "StartsWith",
          value: searchText
        },
        customerName: {
          logicalOperator: "Or",
          predicate: "Contains",
          value: searchText
        },
        regionId: {
          logicalOperator: "And",
          predicate: "Equal",
          value: regionId ?? 3
        }
      }

      setLoading(true)
      setData({ data: [], pageInfo: { total: 0 } })

      const requestOrders = async () => {
        await axios
          .post("/api/hll/customerOrderQuery", {
            body,
            limit: perPage,
            offset
          })
          .then((response) => {
            if (!unmounted) {
              setData({
                data: response?.data?.data ? response.data.data : [],
                pageInfo: {
                  total: response?.data?.pageInfo?.total
                    ? response.data.pageInfo.total
                    : 0
                }
              })
            }
          })
          .catch((err) => {
            //console.warn(err, "Error: api/hll/customerOrders")
            setErrorCode(err?.response?.data?.CorrelationId)
            setError(true)
          })
          .finally(() => {
            setIsSearching(false)
            setLoading(false)
          })
      }

      requestOrders()
    }

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, offset, regionId])

  useEffect(() => {
    setPageCount(Math.ceil(data.pageInfo.total / perPage))
  }, [data.pageInfo.total])

  useEffect(() => {
    if (rentalData.length > 0) {
      resetRental()
    }

    if (newRentalsData.length > 0) {
      resetNewRental()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentalData, newRentalsData])

  return (
    <div className="mt-4">
      <SearchForm
        onSubmitHandler={handleSearchSubmit}
        onBlurHandler={handleSearchBlur}
      />

      <SearchMessage />

      {loading && <Loader className="mt-5 mx-auto text-center" />}

      {error && (
        <Alert
          className="text-center my-4"
          message={`${t(
            "Something went wrong, please refresh current page."
          )} ${t("Error code:")} ${errorCode ? errorCode : ""}`}
        />
      )}

      {data.data && data.data.length > 0 && (
        <div className="row my-4">
          <div className="col-12 col-12 px-0 px-sm-3">
            {data.data.map((order) => {
              return (
                <ListItem
                  clickHandler={handleItemClick}
                  key={order.customerOrderNumber}
                  {...order}
                />
              )
            })}
          </div>
        </div>
      )}

      <div
        className={`card-footer justify-content-between w-100 ${
          data.pageInfo.total > perPage && data.data.length > 0
            ? " d-block"
            : " d-none"
        }`}>
        <div className="text-muted text-center d-md-none mb-3">{`${t("Page")} ${
          searchData.currentPage + 1
        } ${t("of")} ${pageCount}`}</div>

        <div className="d-flex">
          <ReactPaginate
            previousLabel={t("Previous")}
            nextLabel={t("Next")}
            breakLabel={"..."}
            pageCount={pageCount > 0 ? pageCount : 1}
            initialPage={searchData.currentPage}
            //forcePage={searchData.currentPage}
            disableInitialCallback={true}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination pagination-lg w-100 justify-content-center"
            previousClassName="page-item"
            previousLinkClassName="d-flex page-link"
            nextClassName="page-item"
            nextLinkClassName="d-flex page-link"
            pageClassName="d-none d-md-flex page-item"
            pageLinkClassName="page"
            activeClassName="active"
            breakClassName="page d-none d-md-flex"
            disabledClassName="disabled"
          />
        </div>
      </div>
    </div>
  )
}

export default Search
