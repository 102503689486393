import { atom } from "recoil"

export const view = atom({
  key: "orderViewState",
  default: "createOrder" // createOrder, viewOrder, previewNewOrder, previewExistingOrder, summary, existingOrders, orderInformation
})

export const modalView = atom({
  key: "modalViewState",
  default: "machineSearch" // machineSearch, machineAccessories, orderSearch
})

export const orderInformation = atom({
  key: "orderInformationState",
  default: null
})

export const existingOrderRows = atom({
  key: "existingOrderRowsState",
  default: []
})

export const newOrderRows = atom({
  key: "newOrderRowsState",
  default: []
})

export const newOrderInformation = atom({
  key: "newOrderInformationState",
  default: {
    customer: null,
    project: null,
    reference: null,
    pickupBy: {
      name: "",
      socialSecurityNumber: ""
    },
    bypassSocialSecurityNumber: false
  }
  //Debugging
  // default: {
  //   customer: {
  //     id: "7F7D16C1-E435-47B2-867E-35FB52B95107",
  //     number: "HLL3000450",
  //     name: "JVAB Järfälla VA Byggentreprenad AB",
  //     name2: "",
  //     email: "info@jvab.se",
  //     telephone: "08-58490900",
  //     mobileNumber: "08-58490912",
  //     customerType: 0,
  //     accountName: "",
  //     accessCode: null,
  //     corporateIdentityNumber: "5564285822",
  //     regionId: "3",
  //     creditSpace: 2000000,
  //     creditLimit: {
  //       hasCreditLimit: true,
  //       value: 0
  //     },
  //     salesRepresentative: "Martin Danell",
  //     onlyCustomerCanCreateRentalContact: false,
  //     requiresIdForRentalCustomer: true,
  //     companyType: 3,
  //     postalAdress: {
  //       adressLine1: "Järfälla VA Byggentreprenad AB(JVAB)",
  //       adressLine2: "",
  //       adressLine3: "Skarprättarvägen 14",
  //       postalCode: "176   77",
  //       city: "Järfälla",
  //       country: null
  //     },
  //     billingMethod: {
  //       type: 1,
  //       pdfInvoiceReceiverAddress: "jvab@pdf.scancloud.se",
  //       billingAddress: {
  //         adressLine1: "JVAB Järfälla VA Byggentreprenad AB",
  //         adressLine2: "",
  //         adressLine3: "FE 1236 Scancloud",
  //         postalCode: "831 90",
  //         city: "Östersund",
  //         country: null
  //       },
  //       svefakuturaId1: null,
  //       svefakuturaId2: null,
  //       peppolId: null
  //     },
  //     billingFrequency: 3,
  //     markingFormats: [
  //       {
  //         type: 2,
  //         filter: "_____"
  //       },
  //       {
  //         type: 1,
  //         filter: "AO:#####"
  //       },
  //       {
  //         type: 1,
  //         filter: "ÄTA:#####"
  //       }
  //     ],
  //     customerSettings: {
  //       oustandingRentNotificationFrequency: {
  //         day: 1,
  //         frequency: 1
  //       },
  //       oustandingRentNotificationForAllSites: false
  //     },
  //     documentRecipients: [],
  //     searchText: "JVAB"
  //   },
  //   project: {
  //     id: "5481",
  //     name: "",
  //     number: "5564285822",
  //     customerId: "7F7D16C1-E435-47B2-867E-35FB52B95107",
  //     startTime: "0001-01-01T00:00:00Z",
  //     siteType: 1,
  //     address: {
  //       adressLine1: "",
  //       adressLine2: null,
  //       adressLine3: null,
  //       postalCode: "",
  //       city: "",
  //       country: null
  //     },
  //     activeStatus: null,
  //     isEditable: false,
  //     isBlockedForOrder: false,
  //     siteSettings: {
  //       oustandingRentNotificationFrequency: {
  //         day: 0,
  //         frequency: 0
  //       }
  //     },
  //     documentRecipients: [],
  //     searchText: ""
  //   },
  //   reference: {
  //     id: "16728913",
  //     customerId: "7F7D16C1-E435-47B2-867E-35FB52B95107",
  //     firstName: "Adam ",
  //     lastName: "Wagner",
  //     title: "",
  //     phone1: "070-6082144",
  //     phone2: "",
  //     email: "",
  //     dateOfBirth: "1998-03-01",
  //     searchText: ""
  //   },
  //   pickupBy: {
  //     name: "",
  //     socialSecurityNumber: ""
  //   }
  // }
})

export const debitTypes = atom({
  key: "debitTypesState",
  default: []
})

export const creditLimit = atom({
  key: "orderCreditLimitState",
  default: null
})
