import React, { useState } from "react"
import { replaceItemAtIndex } from "../../../utils/helpers"
import { newOrderRows } from "../Store"
import { useRecoilState } from "recoil"
import Icon from "../../Common/Icon"

const ItemMeta = ({ context, meta, item }) => {
  const [newOrderRowsState, setNewOrderRowsState] = useRecoilState(newOrderRows)
  const index = newOrderRowsState.findIndex(
    (row) => row.orderRowId === item.orderRowId
  )

  const [amount, setAmount] = useState(item?.quantity ?? 1)

  const handleAmountClick = (type) => {
    const updatedAmount =
      type === "increment" ? Number(amount) + 1 : Number(amount) - 1

    setAmount(updatedAmount)

    handleInputChange({
      target: {
        value: updatedAmount
      }
    })
  }

  const handleInputChange = ({ target: { value } }) => {
    let updatedAmount = value

    if (updatedAmount < 0) {
      updatedAmount = 1
    }

    setAmount(updatedAmount)

    const orderRows = replaceItemAtIndex(newOrderRowsState, index, {
      ...item,
      quantity: Number(updatedAmount)
    })

    setNewOrderRowsState(orderRows)
  }

  if (
    item.internalType &&
    (item.internalType === "ARTICLE" || item.internalType === "ACCESSORY") &&
    context === "accordion"
  ) {
    return (
      <div className="d-flex align-items-center justify-content-center mt-3">
        <div className="bg-light px-3 py-2 rounded border border-light border-1 w-100">
          <div className="row d-flex align-items-center justify-content-between g-0">
            {meta.map((metaData, index) => {
              if (metaData === "") {
                return null
              }
              return (
                <div
                  className={index > 0 ? "col-auto pl-2" : "col"}
                  key={index}>
                  <span
                    className={`d-block text-muted text-gray-700 text-left py-1 ${
                      index > 0
                        ? " border-left border-1 border-white pl-2 text-right"
                        : " text-left"
                    }`}>
                    {metaData}
                  </span>
                </div>
              )
            })}
          </div>
        </div>
        <div className="bg-white d-flex justify-content-between align-items-center rounded border border-light border-1 w-100 ml-2">
          <button
            className="bg-transparent px-3"
            disabled={amount <= 1}
            onClick={() => handleAmountClick("decrement")}>
            <Icon name="minusCircle" />
          </button>
          <div className="w-100">
            <input
              inputMode="decimal"
              type="number"
              value={amount}
              onChange={handleInputChange}
              className="h-100 form-control p-0 text-center appearence-none border-start border-end border-light border-1 border-top-0 border-bottom-0 rounded-0"
            />
          </div>
          <button
            className="bg-transparent px-3"
            onClick={() => handleAmountClick("increment")}>
            <Icon name="plusCircle" />
          </button>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`bg-light rounded border border-light border-1 mt-3`}>
        <div className="row d-flex align-items-center justify-content-between g-0">
          {meta.map((metaData, index) => {
            if (metaData === "") {
              return null
            }

            if (typeof metaData === "undefined") {
              return null
            }

            return (
              <div className={index > 0 ? "col-auto pl-2" : "col"} key={index}>
                <span
                  className={`d-block text-muted text-gray-700 py-2 px-3 ${
                    index > 0
                      ? " border-left border-1 border-white py-2 px-3 text-right"
                      : " text-left"
                  }`}>
                  {metaData}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default ItemMeta
