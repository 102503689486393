import React, { useState, useEffect, useRef } from "react"
import useTransportOrderContext from "../../context/TransportOrder/Store"
import { useTranslation } from "react-i18next"
import Item from "./Item"
import Pagination from "../Pagination/Pagination"

import TableSkeletonLoader from "../Common/TableSkeletonLoader"
import { useMediaQuery } from "react-responsive"
import Storage from "../../utils/storage"
import { setUserSettings } from "../../utils/hooks"
import ReminderModal from "./Modal/ReminderModal"
import { useOnClickOutside } from "../../utils/hooks"

import OffCanvas from "../Common/OffCanvas"
import Overlay from "../Common/Overlay"
import MachineInformation from "./MachineInformation"

import CustomSvgIcon from "../Common/CustomSvgIcon"

const DEFAULT_PER_PAGE = 20

const ProjectRentalList = ({
  project = null,
  data,
  loading,
  activeType,
  allChecked,
  setAllChecked,
  checkedItems,
  setCheckedItems
}) => {
  const outsideClickHandlerRef = useRef()
  const offCanvasRef = useRef(null)
  const { t } = useTranslation()

  const userState = Storage.get("userState") ?? null

  const [InitialOrderState, setOrderState] = useState(false)

  const [searchText, setSearchText] = useState("")
  const [posts, setPosts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)

  const [showDropdown, setDropdownState] = useState(false)

  const indexOfLast = currentPage * perPage
  const indexOfFirst = indexOfLast - perPage
  const currentPosts = posts.slice(indexOfFirst, indexOfLast)

  const [showReminderModal, setShowReminderModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [machineInformationData, setMachineInformationData] = useState(null)

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(outsideClickHandlerRef, () => setDropdownState(false))
  useOnClickOutside(offCanvasRef, () => {
    setShowInfoModal(false)
  })

  const [updatingUserState, setUpdatingUserState] = useState(false)
  const [projectReminders, setProjectReminders] = useState(
    userState?.reminders ?? []
  )

  const { setOrderData } = useTransportOrderContext()

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 991px)"
  })

  const handleCloseModal = () => {
    setShowReminderModal(false)
    setShowInfoModal(false)
    setMachineInformationData(null)
  }

  // Search on change
  const handleSearchChange = (e) => {
    e.preventDefault()
    const value = e.target.value
    setSearchText(value)
    setCurrentPage(1)
    filterData(value)
  }

  // Filter data based on search text
  const excludeColumns = [] // exclude column list from filter
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim()
    if (lowercasedValue === "") {
      setPosts(data)
    } else {
      const filteredData = posts.filter((item) => {
        return Object.keys(item.reference).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item.reference[key]
                .toString()
                .toLowerCase()
                .includes(lowercasedValue)
        )
      })

      setPosts(filteredData)
    }
  }

  // Pagination + Prev and next links
  const paginate = (currentPage, pageNumber) => setCurrentPage(pageNumber)
  const nextPage = () => setCurrentPage(currentPage + 1)
  const previousPage = () => setCurrentPage(currentPage - 1)

  // Toggle Posts Per Page menu
  const toggleperPageMenu = (e) => {
    setOrderState(true)
    e.preventDefault()
    setDropdownState((value) => !value)
  }

  // Update Posts Per Page
  const updateperPage = (e) => {
    e.preventDefault()
    setPerPage(e.target.value)
    setDropdownState(false)
  }

  // Sort data by key
  const sortByKey = (e) => {
    const key = e.target.dataset["sort"]
    let newData = posts.slice()

    if (InitialOrderState) {
      newData.sort((a, b) => (b[key] > a[key] ? 1 : -1))
    } else {
      newData.sort((a, b) => {
        if (key === "itemName" || key === "type") {
          return a["reference"][key] > b["reference"][key] ? 1 : -1
        }

        return a[key] > b[key] ? 1 : -1
      })
    }

    setPosts(newData)
  }

  // Toggle Sorting
  const toggleSorting = (e) => {
    e.preventDefault()
    sortByKey(e)
    setOrderState((InitialOrderState) => !InitialOrderState)
  }

  // Check all
  const handleCheckboxAll = (e) => {
    let items = posts
    const checked = e.target.checked
    setCheckedItems([])
    setAllChecked(checked)
    setPerPage(posts.length)

    items.map((item) => {
      item.isChecked = e.target.checked

      if (item.isChecked) {
        if (checkedItems.length > 0) {
          setCheckedItems((items) => items)
        } else {
          setCheckedItems(...checkedItems, items)
        }
      } else {
        setPerPage(DEFAULT_PER_PAGE)
        setCheckedItems([])
      }

      setPosts(items)
      return items
    })
  }

  // Post Checkbox Change
  const postCheckboxChange = (e) => {
    let items = posts
    const value = e.target.value
    const checked = e.target.checked

    items.map((item) => {
      if (checked) {
        if (item?.id === value) {
          item.isChecked = checked
          setCheckedItems([...checkedItems, item])
        }
      } else {
        if (item?.id === value) {
          item.isChecked = false
          const newList = checkedItems.filter((i) => i.id !== value)
          setCheckedItems(newList)
        }
      }
      setPosts(items)
      return items
    })
  }

  // setOrderData
  useEffect(() => {
    setOrderData(checkedItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedItems])

  useEffect(() => {
    setPosts(data)
  }, [data])

  useEffect(() => {
    if (updatingUserState) {
      setUserSettings({
        ...userState,
        reminders: projectReminders
      })
      Storage.set(
        "userState",
        {
          ...userState,
          reminders: projectReminders
        },
        "local"
      )

      setUpdatingUserState(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectReminders, updatingUserState, userState])

  const totalPages = []

  for (let i = 1; i <= Math.ceil(posts.length / perPage); i++) {
    totalPages.push(i)
  }

  let loadingCells = 6

  if (activeType === "purchase") {
    loadingCells = 4
  } else if (activeType === "all") {
    loadingCells = 5
  }

  return (
    <>
      <ReminderModal
        show={showReminderModal}
        close={handleCloseModal}
        machineInformationData={machineInformationData}
        projectReminders={projectReminders}
        setProjectReminders={setProjectReminders}
        setUpdatingUserState={setUpdatingUserState}
      />
      <div ref={offCanvasRef}>
        <OffCanvas
          show={showInfoModal}
          setShow={setShowInfoModal}
          header={t("Fleetitem information")}>
          <div className="position-relative">
            {/* <div className="nav-off-canvas-image-gallery">[IMAGE GALLERY]</div> */}
            <div className="nav-off-canvas-content">
              {machineInformationData && (
                <MachineInformation
                  showInfoModal={showInfoModal}
                  data={machineInformationData}
                  projectReminders={projectReminders}
                />
              )}
            </div>
          </div>
        </OffCanvas>
      </div>

      {showInfoModal && <Overlay />}

      <div className="data-list with-filters mt-0 mt-md-4">
        <div className="card">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col">
                <form>
                  <div className="input-group input-group-flush input-group-merge input-group-reverse">
                    <input
                      className="form-control list-search"
                      type="search"
                      placeholder={t("Search")}
                      value={searchText}
                      onChange={handleSearchChange}
                      required
                    />
                    <span className="input-group-text">
                      <CustomSvgIcon
                        name="search"
                        width={20}
                        height={20}
                        className={`text-muted`}
                      />
                    </span>
                  </div>
                </form>
              </div>

              <div className="col-auto mr-n3" ref={outsideClickHandlerRef}>
                {posts.length > perPage && (
                  <div className="dropdown">
                    <a
                      href="#!"
                      className="d-flex btn btn-sm dropdown-toggle"
                      role="button"
                      id="perPageMenu"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded={showDropdown}
                      onClick={toggleperPageMenu}
                      disabled>
                      {`${perPage} ${t("per page")}`}
                    </a>
                    <div
                      className={`dropdown-menu dropdown-menu-right${
                        showDropdown ? " show" : ""
                      }`}
                      aria-labelledby="perPageMenu">
                      {posts.length >= 20 && (
                        <button
                          className="dropdown-item"
                          value={20}
                          onClick={updateperPage}>
                          20
                        </button>
                      )}

                      {posts.length >= 30 && (
                        <button
                          className="dropdown-item"
                          value={30}
                          onClick={updateperPage}>
                          30
                        </button>
                      )}

                      {posts.length >= 50 && (
                        <button
                          className="dropdown-item"
                          value={50}
                          onClick={updateperPage}>
                          50
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-sm table-hover table-nowrap card-table">
              <thead>
                <tr>
                  {activeType === "rented" && !isDesktopOrLaptop && (
                    <th className="col-1"></th>
                  )}
                  {activeType === "rented" && (
                    <th className="col-1">
                      <div className="form-check mb-n2">
                        <input
                          className="form-check-input list-checkbox-all"
                          name="all"
                          type="checkbox"
                          checked={allChecked}
                          onChange={(e) => handleCheckboxAll(e)}
                        />
                      </div>
                    </th>
                  )}

                  {activeType === "returned" && (
                    <th className="col-1">
                      <div className="form-check mb-n2">
                        <input
                          className="form-check-input list-checkbox-all"
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        />
                      </div>
                    </th>
                  )}

                  <th>
                    <a
                      className="list-sort-up text-muted text-gray-700"
                      href="#!">
                      {t("Amount")}
                    </a>
                  </th>
                  <th>
                    <a
                      className="list-sort-up text-muted text-gray-700"
                      data-sort="itemName"
                      href="#!"
                      onClick={toggleSorting}>
                      {t("Name")}
                    </a>
                  </th>
                  <th>
                    <a
                      className="list-sort text-muted text-gray-700"
                      data-sort="type"
                      href="#!"
                      onClick={toggleSorting}>
                      {t("Type")}
                    </a>
                  </th>

                  {activeType === "rented" && (
                    <th>
                      <a
                        className="list-sort text-muted text-gray-700"
                        data-sort="startTime"
                        href="#!"
                        onClick={toggleSorting}>
                        {t("Rented at")}
                      </a>
                    </th>
                  )}

                  {activeType === "returned" && (
                    <th>
                      <a
                        className="list-sort text-muted text-gray-700"
                        data-sort="startTime"
                        href="#!"
                        onClick={toggleSorting}>
                        {t("Rented at")}
                      </a>
                    </th>
                  )}

                  {activeType === "returned" && (
                    <th>
                      <a
                        className="list-sort text-muted text-gray-700"
                        data-sort="createdAt"
                        href="#!"
                        onClick={toggleSorting}>
                        {t("Returned")}
                      </a>
                    </th>
                  )}

                  {activeType === "all" && (
                    <th>
                      <a
                        className="list-sort text-muted text-gray-700"
                        href="#!">
                        {t("Status")}
                      </a>
                    </th>
                  )}
                  {activeType === "rented" && isDesktopOrLaptop && (
                    <th className="col-1"></th>
                  )}
                </tr>
              </thead>

              {loading && (
                <tbody className="list font-size-base">
                  <TableSkeletonLoader
                    rows={DEFAULT_PER_PAGE}
                    cells={loadingCells}
                  />
                </tbody>
              )}

              {!loading && (
                <Item
                  project={project}
                  data={currentPosts}
                  activeType={activeType}
                  checkedItems={checkedItems}
                  postCheckboxChange={postCheckboxChange}
                  projectReminders={projectReminders}
                  setProjectReminders={setProjectReminders}
                  setShowReminderModal={setShowReminderModal}
                  setMachineInformationData={setMachineInformationData}
                  setShowInfoModal={setShowInfoModal}
                  showInfoModal={showInfoModal}
                />
              )}
            </table>

            {!loading && currentPosts.length === 0 && (
              <div className="py-4 text-center">{t("Can't find anything")}</div>
            )}
          </div>

          {totalPages.length > 1 && (
            <div className="card-footer d-flex justify-content-between">
              <Pagination
                perPage={perPage}
                total={posts.length}
                paginate={paginate}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ProjectRentalList
