import React from "react"
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "react-responsive"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import { singleUrl } from "../../utils/helpers"
import Moment from "react-moment"
import "moment-timezone"
import Storage from "../../utils/storage"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const Item = ({
  project,
  data,
  activeType,
  checkedItems,
  postCheckboxChange,
  projectReminders,
  setShowReminderModal,
  setMachineInformationData,
  setShowInfoModal,
  showInfoModal
}) => {
  const { t } = useTranslation()
  const customer = Storage.get("customer", null, "local") ?? null

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 991px)"
  })

  const orderRowReminderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Set a planned end date")}
    </Tooltip>
  )

  const orderRowInfoTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Show more information about the Article/Fleet Model")}
    </Tooltip>
  )

  const typeMapping = {
    ARTICLE: t("Article(s)"),
    INDIVIDUAL: t("Individual"),
    ACCESSORIES: t("Accessories")
  }

  const handleOpenProjectRemidersModal = (item) => {
    const data = {
      customerId: customer?.id,
      type: "project",
      createdAt: new Date(),
      orderRowId: item?.id, // OrderRow ID,
      projectId: project?.id, // Project ID,
      pathname: `${singleUrl(
        `${t("projects")}`,
        project?.customerId,
        project?.id
      )}`, // path to project
      data: {
        project,
        orderRow: item
      }
    }

    setMachineInformationData(data)
    setShowReminderModal(true)
  }

  const handleOpenInfoModal = (item) => {
    setMachineInformationData(item)
    setShowInfoModal(true)
  }

  return (
    <>
      <tbody className="list font-size-base">
        {data.map((item) => {
          const isActive = projectReminders.find(
            (r) => r?.orderRowId === item.id
          )

          return (
            <tr key={item.id}>
              {activeType === "rented" && !isDesktopOrLaptop && (
                <td className="col-1">
                  <div className="d-flex align-items-center justify-content-center">
                    <div>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 100, hide: 100 }}
                        overlay={orderRowReminderTooltip}>
                        <button
                          className={`bg-transparent`}
                          onClick={() => handleOpenProjectRemidersModal(item)}>
                          <CustomSvgIcon
                            name="calender"
                            width={16}
                            height={16}
                            className={`${
                              isActive ? "text-primary" : "text-muted"
                            }`}
                          />
                        </button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 100, hide: 0 }}
                        overlay={orderRowInfoTooltip}>
                        <button
                          className={`fe fe-info text-gray-500 bg-transparent`}
                          onClick={() => handleOpenInfoModal(item)}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                </td>
              )}
              {activeType === "rented" && (
                <td>
                  <div className="form-check">
                    <input
                      className="form-check-input list-checkbox"
                      type="checkbox"
                      value={item?.id}
                      data-name={item?.reference?.itemName}
                      checked={checkedItems.some((e) => e.id === item?.id)}
                      disabled={activeType !== "rented"}
                      onChange={(e) => postCheckboxChange(e)}
                    />
                  </div>
                </td>
              )}

              {activeType === "returned" && (
                <td>
                  <div className="form-check">
                    <input
                      className="form-check-input list-checkbox"
                      type="checkbox"
                      checked={true}
                      disabled={true}
                    />
                  </div>
                </td>
              )}

              <td>
                <div className="d-flex align-items-center">
                  <span className="ml-2 badge bg-light disabled">
                    {item?.quantity}
                  </span>
                </div>
              </td>
              <td
                onClick={() => {
                  setShowInfoModal(!showInfoModal)
                  handleOpenInfoModal(item)
                }}
                style={{
                  cursor: "pointer"
                }}>
                <div className={`d-flex align-items-center text-primary`}>
                  {item?.reference?.itemName}
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  {item?.orderRowType === 4
                    ? typeMapping["ACCESSORIES"]
                    : typeMapping[item?.reference?.type]}
                </div>
              </td>

              {activeType === "rented" && (
                <td>
                  <span className="item-created-at text-reset">
                    <Moment
                      locale="sv"
                      dateTime={item?.startTime}
                      format="YYYY-MM-DD">
                      {item?.startTime}
                    </Moment>
                  </span>
                </td>
              )}

              {activeType === "returned" && (
                <td>
                  <span className="item-created-at text-reset">
                    <Moment
                      locale="sv"
                      dateTime={item?.startTime}
                      format="YYYY-MM-DD">
                      {item?.startTime}
                    </Moment>
                  </span>
                </td>
              )}

              {activeType === "returned" && (
                <td>
                  <span className="item-created-at text-reset">
                    {item?.endTime ? (
                      <Moment
                        locale="sv"
                        dateTime={item?.endTime}
                        format="YYYY-MM-DD">
                        {item?.endTime}
                      </Moment>
                    ) : (
                      <span></span>
                    )}
                  </span>
                </td>
              )}
              {activeType === "all" && (
                <td>
                  <span className="item-created-at text-reset">
                    {item?.endTime &&
                      item?.orderRowType !== 2 &&
                      item?.orderRowType !== 3 && (
                        <span className="badge bg-light">{t("Returned")}</span>
                      )}
                    {!item?.endTime &&
                      item?.orderRowType !== 2 &&
                      item?.orderRowType !== 3 && (
                        <span className="badge bg-light">{t("Leased")}</span>
                      )}
                    {item.orderRowType === 2 && (
                      <span className="badge bg-light">{t("Purchase")}</span>
                    )}
                    {!item.endTime && item.orderRowType === 3 && (
                      <span className="badge bg-light">{t("Purchase")}</span>
                    )}
                  </span>
                </td>
              )}
              {activeType === "rented" && isDesktopOrLaptop && (
                <td className="col-1">
                  <div className="d-flex align-items-center justify-content-center">
                    <div>
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 100, hide: 0 }}
                        overlay={orderRowReminderTooltip}>
                        <button
                          className={` bg-transparent`}
                          onClick={() => handleOpenProjectRemidersModal(item)}>
                          <CustomSvgIcon
                            name="calender"
                            width={16}
                            height={16}
                            className={`${
                              isActive ? "text-primary" : "text-muted"
                            }`}
                          />
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </td>
              )}
            </tr>
          )
        })}
      </tbody>
    </>
  )
}

export default Item
