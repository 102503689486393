import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import DocumentRecipient from "./DocumentRecipient"
import TableSkeletonLoader from "../../../Common/TableSkeletonLoader"
import ModalUpdate from "./ModalUpdate"
import ModalConfirm from "./ModalConfirm"
import axios from "axios"
import toast from "react-hot-toast"
import { recipientsEmailMapping, findDocumentTypesInArray } from "../../helpers"
import CustomSvgIcon from "../../../Common/CustomSvgIcon"

export default function DocumentRecipientsList({
  documentRecipients,
  customerData,
  showModalUpdate,
  setShowModalUpdate
}) {
  const { t } = useTranslation()
  const [modalEditData, setModalEditData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [existingEmail, setExistingEmail] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [recipients, setRecipients] = useState(documentRecipients)
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  // Adding email array to state from the documentRecipients list
  const [recipientsEmails, setRecipientsEmails] = useState(
    recipientsEmailMapping(recipients)
  )

  // Search on change
  const handleSearchChange = (e) => {
    e.preventDefault()
    const value = e.target.value

    setSearchText(value)
    filterData(value)
  }

  // Filter data based on search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim()
    if (lowercasedValue === "") {
      setRecipientsEmails(recipientsEmailMapping(recipients))
    } else {
      const filteredData = search(lowercasedValue)
      setRecipientsEmails(filteredData)
    }
  }

  // Do a search absed on searchText
  const search = (searchText) => {
    const emails = recipientsEmailMapping(recipients)
    let arrayOfMatchedEmails = emails.filter((email) => {
      return JSON.stringify(email).toString().toLowerCase().includes(searchText)
    })
    return arrayOfMatchedEmails
  }

  const filterEmailFromDocumentTypes = (emailToRemove) => {
    return recipients.map((recipient) => {
      const emails = recipient.emails.filter((email) => email !== emailToRemove)

      return {
        ...recipient,
        emails
      }
    })
  }

  const handleHideEditModal = () => {
    setShowModalUpdate(false)
    setModalEditData(null)
    setExistingEmail(false)
  }

  const handleShowEditModal = (recipientData) => {
    setShowModalUpdate(true)
    setModalEditData(recipientData)
    setExistingEmail(false)
  }

  const handleShowConfirmModal = (recipientData) => {
    setShowModalConfirm(true)
    setModalEditData(recipientData)
  }

  const handleHideConfirmModal = () => {
    setShowModalConfirm(false)
    setModalEditData(null)
  }

  const handleUpdateDocumentRecipient = async (updatedData) => {
    let postData = []

    const { documentTypes, email, oldEmail } = updatedData
    setExistingEmail(false)

    const skipExistingEmailValidation = oldEmail === email && modalEditData

    const findExistingEmails = recipients.filter((recipient) =>
      recipient.emails.includes(email)
    )

    if (!skipExistingEmailValidation && findExistingEmails.length > 0) {
      setExistingEmail(true)
      return
    }

    if (modalEditData) {
      // Edit
      const updatedEmail = oldEmail !== email ? email : oldEmail
      const updatedRecipients = filterEmailFromDocumentTypes(oldEmail)

      documentTypes.forEach((document) => {
        const index = updatedRecipients.findIndex(
          (type) => Number(type.documentType) === Number(document)
        )

        if (index > -1) {
          updatedRecipients[index].emails.push(updatedEmail)
        } else {
          updatedRecipients.push({
            documentType: Number(document),
            emails: [updatedEmail]
          })
        }
      })

      postData = updatedRecipients
    } else {
      // Add new
      if (recipients.length === 0) {
        documentTypes.map((type) => {
          postData.push({
            documentType: Number(type),
            emails: [email]
          })
          return type
        })
      } else {
        for (let index = 0; index < documentTypes.length; index++) {
          if (
            Number(recipients[index]?.documentType) ===
            Number(documentTypes[index])
          ) {
            recipients[index].emails.push(email)
          } else {
            recipients.push({
              documentType: Number(documentTypes[index]),
              emails: [email]
            })
          }
        }

        postData = recipients
      }
    }

    // console.log("postData", postData)
    // return

    handleRequest(postData)
  }

  const handleDelete = (data) => {
    const updatedRecipients = filterEmailFromDocumentTypes(data.email)

    let deleteAll = true

    updatedRecipients.forEach((recipient) => {
      if (recipient.emails.length > 0) {
        deleteAll = false
      }
    })

    setShowModalConfirm(false)
    handleRequest(deleteAll ? [] : updatedRecipients)
  }

  const handleRequest = async (body) => {
    setLoading(true)
    await axios
      .post("/api/hll/updateCustomerDocumentRecipients", {
        customerId: customerData?.id,
        body
      })
      .then((response) => {
        if (response.status === 200) {
          setRecipients(response?.data?.documentRecipients)
          setRecipientsEmails(
            recipientsEmailMapping(response?.data?.documentRecipients)
          )
          toast.success(t("Your company profile is updated"))
        }
      })
      .catch((error) => {
        //console.error(error)
        toast.error(t("Something went wrong, try again."))
      })
      .finally(() => {
        setLoading(false)
        setShowModalUpdate(false)
        setModalEditData(null)
      })
  }

  return (
    <>
      <ModalConfirm
        show={showModalConfirm}
        data={modalEditData}
        handleHideConfirmModal={handleHideConfirmModal}
        handleDelete={handleDelete}
      />
      <ModalUpdate
        show={showModalUpdate}
        data={modalEditData}
        handleHideEditModal={handleHideEditModal}
        handleUpdateDocumentRecipient={handleUpdateDocumentRecipient}
        loading={loading}
        existingEmail={existingEmail}
      />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h2 className="card-header-title text-truncate">
                {t("Receiver")}
              </h2>
            </div>
            {recipientsEmails && (
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    <div
                      className={`input-group input-group-flush input-group-merge input-group-reverse${
                        !recipientsEmails || loading ? " is-disabled" : ""
                      }`}>
                      <input
                        className={`form-control list-search${
                          !recipientsEmails || loading ? " is-disabled" : ""
                        }`}
                        type="search"
                        disabled={!recipientsEmails || loading}
                        placeholder={t("Search")}
                        value={searchText}
                        onChange={handleSearchChange}
                      />
                      <span className="input-group-text">
                        <CustomSvgIcon name="search" width={20} height={20} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!recipientsEmails && (
              <div className="card-body text-center">
                {t("Can't find anything")}
              </div>
            )}

            {recipientsEmails && (
              <div className="table-responsive">
                <table className="table table-sm table-hover table-nowrap card-table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="col-12 col mr-auto text-left text-gray-700">
                        {t("Email")}
                      </th>
                      <th
                        scope="col"
                        className="col-12 col text-left text-gray-700">
                        {t("Document type")}
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <tbody>
                      <TableSkeletonLoader rows={5} cells={3} />
                    </tbody>
                  ) : (
                    <tbody className="list font-size-base">
                      {recipientsEmails &&
                        recipientsEmails.map((email, index) => {
                          const documentTypes = findDocumentTypesInArray(
                            recipients,
                            email
                          )
                          return (
                            <DocumentRecipient
                              key={index}
                              email={email}
                              documentTypes={documentTypes}
                              handleShowEditModal={handleShowEditModal}
                              handleShowConfirmModal={handleShowConfirmModal}
                              handleHideConfirmModal={handleHideConfirmModal}
                            />
                          )
                        })}
                    </tbody>
                  )}
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
