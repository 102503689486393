import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import { newOrderRows, existingOrderRows, orderInformation } from "./Store"
import SummaryHeader from "./SummaryHeader"
import { Tabs, Tab } from "react-bootstrap"
import Item from "./Common/Item"
import { previewItemMeta } from "./helpers"
import Storage from "../../utils/storage"
import { dateAndTimeNow } from "../../utils/helpers"

const OrderSummary = ({ data }) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState("newOrderRows")
  const newOrderRowsState = useRecoilValue(newOrderRows)
  const existingOrderRowsState = useRecoilValue(existingOrderRows)
  const orderInformationState = useRecoilValue(orderInformation)
  const userState = Storage.get("userState") ?? null
  const depot = userState?.internal?.depot?.name ?? ""

  useEffect(() => {
    if (newOrderRowsState && newOrderRowsState.length > 0) {
      window.dataLayer.push({
        event: "HLL_update_order",
        depot,
        createdAt: dateAndTimeNow(),
        orderId: orderInformationState?.customerOrderId,
        userType: "internal",
        items: newOrderRowsState.map((orderRow) => {
          return {
            itemId: orderRow?.id,
            itemName: orderRow?.reference?.itemName,
            quantity: orderRow?.quantity
          }
        })
      })
    }
  }, [
    newOrderRowsState,
    depot,
    orderInformationState?.customerOrderId,
    orderInformationState
  ])

  return (
    <>
      <SummaryHeader data={data?.customerOrder?.customerOrderHead} />

      <Tabs
        justify
        fill
        id="uncontrolled-tab"
        activeKey={activeTab}
        onSelect={(key) => setActiveTab(key)}
        className="bg-white">
        <Tab
          eventKey="newOrderRows"
          tabClassName="w-100 bg-transparent"
          title={
            <div className="d-flex align-items-center justify-content-center">
              {t("New order rows")}
            </div>
          }>
          {newOrderRowsState.map((item, index) => (
            <Item
              key={index}
              index={index}
              title={item?.reference?.itemName}
              meta={previewItemMeta(item)}
              item={item}
              context="preview"
            />
          ))}
        </Tab>
        <Tab
          eventKey="existingOrderRows"
          tabClassName="w-100 bg-transparent"
          title={
            <div className="d-flex align-items-center justify-content-center">
              {t("Existing order rows")}
            </div>
          }>
          {existingOrderRowsState && existingOrderRowsState.length > 0 ? (
            existingOrderRowsState.map((item, index) => (
              <Item
                key={index}
                index={index}
                title={item?.reference?.itemName}
                meta={[item?.id, `${item?.quantity} st`]}
                item={item}
                context="preview"
              />
            ))
          ) : (
            <div className="p-4 text-center">
              {t("No existing orders found")}
            </div>
          )}
        </Tab>
      </Tabs>
    </>
  )
}

export default OrderSummary
