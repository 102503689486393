import React, { useEffect, useState, useRef } from "react"
import axios from "axios"
import { useForm, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useRecoilState } from "recoil"
import { inventoryAdministrationEdit } from "../../../Store"
import {
  inventoryOccasionStatus,
  internalRegionsAndDepotsGrouping,
  addMinutesToDate,
  dateAndTimeNow
} from "../../../../../utils/helpers"

import Filters from "./Filters"
import ActionFooter from "../../../../Common/ActionFooter"
import Storage from "../../../../../utils/storage"

import moment from "moment"
import "moment-timezone"
import uuid from "react-uuid"
import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast"
import ModalSheet from "../../../../Common/ModalSheet"
import Overlay from "../../Overlay"
import Loader from "../../../../Loader/Loader"

import DatePicker, { registerLocale } from "react-datepicker"
import sv from "date-fns/locale/sv"
import "react-datepicker/dist/react-datepicker.css"
import CustomSvgIcon from "../../../../Common/CustomSvgIcon"
registerLocale("sv", sv)

export default function Form() {
  const { t } = useTranslation()
  const formRef = useRef()
  const navigate = useNavigate()

  const userState = Storage.get("userState") ?? null

  const [
    inventoryAdministrationEditState,
    setInventoryAdministrationEditState
  ] = useRecoilState(inventoryAdministrationEdit)

  const { inventory, internalRegions, depots } =
    inventoryAdministrationEditState

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: "all"
  })

  const [name, setName] = useState(inventory?.name ?? "")
  const [start, setStart] = useState(new Date(inventory?.start))
  const [branchFilters, setBranchFilters] = useState(inventory?.branchFilters)
  const [status, setStatus] = useState(inventory?.status)
  const [disabled, setDisabled] = useState(inventory?.status > 1)
  const [showBranchFilter, setShowBranchFilter] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(
    inventory?.branchFilters ?? []
  )
  const [dataIsUpdated, setDataIsUpdated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showModalSheet, setShowModalSheet] = useState(false)
  const [modalViewState, setModalViewState] = useState(null)
  const [saveAndUpdateVerification, setSaveAndUpdateVerification] =
    useState(false)
  const [inventoryVerification, setInventoryVerification] = useState(false)

  const filterPassedTime = (time) => {
    const currentDate = addMinutesToDate(new Date(), 15) // Add 15 minutes to current date
    const selectedDate = new Date(time)
    return currentDate.getTime() < selectedDate.getTime()
  }

  const handleUpdateInventoryName = (e) => {
    const value = e?.target?.value

    if (
      value?.length !==
      inventoryAdministrationEditState?.inventory?.name?.length
    ) {
      setDataIsUpdated(true)

      // If name is changed and you have a total inventory
      if (
        parseInt(inventoryAdministrationEditState?.depots?.length) ===
        parseInt(
          inventoryAdministrationEditState?.inventory?.branchFilters.length
        )
      ) {
        setDataIsUpdated(true)
      }
    }

    setValue("name", value)
    clearErrors("name")
    setName(value)

    setInventoryAdministrationEditState((previousState) => {
      return {
        ...previousState,
        inventory: {
          ...previousState.inventory,
          name: value
        }
      }
    })
  }

  const handleUpdateInventoryStart = (date) => {
    const dateValue = date ? date : new Date()

    const compareDate = moment(dateValue).toISOString()

    if (compareDate !== inventoryAdministrationEditState?.inventory?.start) {
      setDataIsUpdated(true)

      // If start is changed and you have a total inventory
      if (
        parseInt(inventoryAdministrationEditState?.depots?.length) ===
        parseInt(
          inventoryAdministrationEditState?.inventory?.branchFilters.length
        )
      ) {
        setDataIsUpdated(true)
      }
    }

    setValue("start", new Date(dateValue))
    clearErrors("start")
    setStart(new Date(dateValue))

    setInventoryAdministrationEditState((previousState) => {
      return {
        ...previousState,
        inventory: {
          ...previousState.inventory,
          start: moment(dateValue).toISOString()
        }
      }
    })
  }

  const handleInventoryVerifiction = () => {
    setInventoryVerification(!inventoryVerification)
  }

  const handleCancel = () => {
    navigate(`/${t("inventory")}/${t("administration")}`)
  }

  const handleDelete = async () => {
    setLoading(true)
    await axios
      .post("/api/hll/inventoryOccasionDelete", {
        inventoryOccasionId:
          inventoryAdministrationEditState?.inventory.inventoryOccasionId
      })
      .then((response) => {
        if (response?.data?.success) {
          setInventoryAdministrationEditState(
            (previousAdministrationEditState) => {
              return {
                ...previousAdministrationEditState,
                inventories:
                  previousAdministrationEditState?.inventories?.filter(
                    (item) =>
                      item?.inventoryOccasionId !==
                      inventoryAdministrationEditState?.inventory
                        .inventoryOccasionId
                  )
              }
            }
          )
        }
        toast.success(t("Inventory deleted successfully"), {
          duration: 2000
        })

        window?.dataLayer?.push({
          event: "delete_inventory",
          company: userState?.internal?.region?.regionName ?? "",
          depot: userState?.internal?.depot?.name ?? "",
          createdAt: dateAndTimeNow(),
          userType: "internal"
        })

        navigate(`/${t("inventory")}/${t("administration")}`)
      })
      .catch((err) => {
        console.log("error", err)
        toast.error(t("Inventory could not be deleted. Try again."), {
          duration: 2000
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleGoBack = () => {
    navigate(`/${t("inventory")}/${t("administration")}`)
  }

  const handleStartControlPhaseVerification = () => {
    setShowModalSheet(true)
    setModalViewState("startControlPhaseVerification")
  }

  const handleStartControlPhase = async () => {
    //console.log("Sending status to api and start the control phase...")

    const body = {
      ...inventoryAdministrationEditState?.inventory,
      status: parseInt(inventoryAdministrationEditState?.inventory?.status) + 1
    }

    setLoading(true)
    await axios
      .post("/api/hll/inventoryOccasionUpdate", {
        inventoryOccasionId:
          inventoryAdministrationEditState?.inventory?.inventoryOccasionId,
        body
      })
      .then((response) => {
        //console.log(response)
        setInventoryAdministrationEditState(
          (previousAdministrationEditState) => {
            return {
              ...previousAdministrationEditState,
              inventory: {
                ...previousAdministrationEditState?.inventory,
                name: response?.data?.name,
                start: new Date(response?.data?.start),
                status: response?.data?.status
              }
            }
          }
        )

        toast.success(
          t("Inventory updated successfully, and starting the control phase."),
          {
            duration: 2000
          }
        )
      })
      .catch((err) => {
        console.log("error", err)
        toast.error(t("Inventory could not be updated. Try again."), {
          duration: 2000
        })
      })
      .finally(() => {
        setLoading(false)
        setModalViewState(null)
        setShowModalSheet(false)
      })
  }

  const handleCompleteControlPhaseVerification = () => {
    setShowModalSheet(true)
    setModalViewState("completeControlPhaseVerification")
  }

  const handleCompleteControlPhase = async () => {
    //console.log("Sending status to api and complete the control phase...")

    const body = {
      ...inventoryAdministrationEditState?.inventory,
      status: parseInt(inventoryAdministrationEditState?.inventory?.status) + 1
    }

    setLoading(true)
    await axios
      .post("/api/hll/inventoryOccasionUpdate", {
        inventoryOccasionId:
          inventoryAdministrationEditState?.inventory?.inventoryOccasionId,
        body
      })
      .then((response) => {
        //console.log(response)
        setInventoryAdministrationEditState(
          (previousAdministrationEditState) => {
            return {
              ...previousAdministrationEditState,
              inventory: {
                ...previousAdministrationEditState?.inventory,
                name: response?.data?.name,
                start: new Date(response?.data?.start),
                status: response?.data?.status
              }
            }
          }
        )

        toast.success(
          t("Inventory updated successfully, and complete the control phase."),
          {
            duration: 2000
          }
        )
        navigate(`/${t("inventory")}/${t("administration")}`)
      })
      .catch((err) => {
        console.log("error", err)
        toast.error(t("Inventory could not be updated. Try again."), {
          duration: 2000
        })
      })
      .finally(() => {
        setLoading(false)
        setModalViewState(null)
        setShowModalSheet(false)
      })
  }

  // Handle update branch filters and send to API
  const handleUpdateBranchFilters = async () => {
    let branchFilters =
      inventoryAdministrationEditState?.inventory?.branchFilters

    //delete body?.branchName // Remove branchName from body

    const totalInventory =
      parseInt(inventoryAdministrationEditState?.depots?.length) ===
      parseInt(branchFilters.length)

    if (totalInventory) {
      branchFilters = []
    }

    await axios
      .post("/api/hll/inventoryOccasionBranchFilter", {
        inventoryOccasionId:
          inventoryAdministrationEditState?.inventory?.inventoryOccasionId,
        body: branchFilters
      })
      .then(() => {
        //console.log(response)
        toast.success(t("Depots updated successfully"), {
          duration: 2000
        })
      })
      .catch((err) => {
        console.log("error", err)
        toast.error(t("Depots could not be updated. Try again."), {
          duration: 2000
        })
      })
      .finally(() => {
        setLoading(false)
        setDataIsUpdated(false)
        handleGoBack()
      })
  }

  const onSubmitAndSave = async () => {
    const body = {
      name: inventoryAdministrationEditState?.inventory?.name,
      start: moment(
        inventoryAdministrationEditState?.inventory?.start
      ).toISOString(),
      status: parseInt(inventoryAdministrationEditState?.inventory?.status)
    }

    setLoading(true)

    await axios
      .post("/api/hll/inventoryOccasionUpdate", {
        inventoryOccasionId:
          inventoryAdministrationEditState?.inventory?.inventoryOccasionId,
        body
      })
      .then((response) => {
        if (response.status === 200) {
          setInventoryAdministrationEditState(
            (previousAdministrationEditState) => {
              return {
                ...previousAdministrationEditState,
                inventory: {
                  ...previousAdministrationEditState?.inventory,
                  name: response?.data?.name,
                  start: new Date(response?.data?.start)
                }
              }
            }
          )

          toast.success(t("Inventory updated successfully"), {
            duration: 2000
          })

          window?.dataLayer?.push({
            event: "edit_inventory",
            company: userState?.internal?.region?.regionName ?? "",
            depot: userState?.internal?.depot?.name ?? "",
            createdAt: dateAndTimeNow(),
            userType: "internal"
          })

          handleGoBack()
        }
      })
      .catch((err) => {
        console.log("error", err)
        toast.error(t("Inventory could not be updated. Try again."), {
          duration: 2000
        })
      })
      .finally(() => {
        setLoading(false)
        setDataIsUpdated(false)
        handleUpdateBranchFilters()
      })
  }

  useEffect(() => {
    setDisabled(inventoryAdministrationEditState?.inventory?.status > 1)
    setStatus(inventoryAdministrationEditState?.inventory?.status)

    // If branchFilters is empty array, you have a total inventory
    if (
      inventoryAdministrationEditState?.inventory?.branchFilters?.length === 0
    ) {
      setInventoryAdministrationEditState((previousAdministrationEditState) => {
        return {
          ...previousAdministrationEditState,
          inventory: {
            ...previousAdministrationEditState?.inventory,
            branchFilters: inventoryAdministrationEditState?.depots?.map(
              (depot) => {
                return {
                  branchId: depot?.id,
                  branchName: depot?.name,
                  inventoryOccasionId:
                    inventoryAdministrationEditState?.inventory
                      ?.inventoryOccasionId
                }
              }
            )
          }
        }
      })
    }
  }, [inventoryAdministrationEditState, setInventoryAdministrationEditState])

  // Set branch filter list to inventoryAdministrationEditState
  useEffect(() => {
    const branchFilterList = internalRegionsAndDepotsGrouping(
      internalRegions,
      depots
    )
    setInventoryAdministrationEditState((previousAdministrationEditState) => {
      return {
        ...previousAdministrationEditState,
        branchFilterList
      }
    })
  }, [internalRegions, depots, setInventoryAdministrationEditState])

  useEffect(() => {
    if (
      branchFilters?.length > 0 &&
      selectedFilters?.length !== branchFilters.length
    ) {
      setDataIsUpdated(true)
    }
  }, [branchFilters, selectedFilters.length])

  return (
    <>
      {showModalSheet && <Overlay />}
      <div className="container-fluid container-fluid-max-width py-4">
        {showBranchFilter && (
          <Filters
            show={showBranchFilter}
            setShow={setShowBranchFilter}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            setBranchFilters={setBranchFilters}
          />
        )}
        <form
          id="edit-inventory-form"
          ref={formRef}
          onSubmit={handleSubmit(onSubmitAndSave)}>
          <div className="row mb-3">
            <div className="col-12">
              <label
                htmlFor="name"
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {t("Name")}
                {!disabled && "*"}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder={name}
                className={`form-control${
                  disabled || loading ? " disabled" : ""
                }`}
                value={name}
                disabled={disabled || loading}
                {...register("name", {
                  required: true,
                  onChange: (e) => {
                    handleUpdateInventoryName(e)
                  }
                })}
              />
              {errors["name"] && (
                <span className="text-danger d-block font-xs mt-2">
                  {t("You must enter a name")}
                </span>
              )}
            </div>
          </div>
          <div className="row align-items-center mb-3">
            <div className="col">
              <label
                htmlFor="start"
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {t("Start time")}
                {!disabled && "*"}
              </label>

              <Controller
                id="start"
                name="start"
                control={control}
                render={() => (
                  <DatePicker
                    locale="sv"
                    disabled={disabled || loading}
                    onChange={(date) => handleUpdateInventoryStart(date)}
                    selected={start}
                    dateFormat="yyyy-MM-dd HH:mm"
                    showTimeSelect
                    className={`form-control${
                      disabled || loading ? " disabled" : ""
                    }`}
                    minDate={new Date()}
                    filterTime={filterPassedTime}
                    timeIntervals={15}
                  />
                )}
              />

              {errors["start"] && (
                <span className="text-danger d-block font-xs mt-2">
                  {t("You must enter a date")}
                </span>
              )}
            </div>
            <div className="col">
              <label
                htmlFor="status"
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {t("Status")}
              </label>

              <input
                type="text"
                id="status"
                name="status"
                placeholder={inventory?.status}
                className="form-control disabled"
                value={inventoryOccasionStatus(inventory?.status)}
                readOnly
                disabled
              />
            </div>
          </div>
        </form>

        {status < 2 && ( // Inventory is not ongoing yet
          <ActionFooter className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-outline-secondary d-block mr-3"
              onClick={() => handleCancel()}>
              {t("Cancel")}
            </button>
            <button
              className="btn btn-primary d-block w-100"
              disabled={!dataIsUpdated || loading}
              onClick={() => {
                setModalViewState("confirmation")
                setShowModalSheet(true)
              }}>
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm text-white"
                  role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                t("Save")
              )}
            </button>
          </ActionFooter>
        )}

        {status === 2 && ( // Inventory is ongoing and can be starting the control phase
          <ActionFooter className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary d-block w-100"
              disabled={loading}
              onClick={() => handleStartControlPhaseVerification()}>
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm text-white"
                  role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                t("Start the control phase")
              )}
            </button>
          </ActionFooter>
        )}

        {status === 3 && ( // Inventory is searching....
          <ActionFooter className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary d-block w-100"
              disabled={loading}
              onClick={() => handleCompleteControlPhaseVerification()}>
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm text-white"
                  role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                t("Complete the control phase")
              )}
            </button>
          </ActionFooter>
        )}

        {inventoryAdministrationEditState?.inventory?.branchFilters &&
          inventoryAdministrationEditState?.inventory?.branchFilters.length >
            0 && (
            <div className="row align-items-center mb-3">
              <div className="col-12">
                <label className="field__label form-label mt-1 mb-1 text-uppercase">
                  {t("Depots")}
                </label>

                <div
                  className="text-read-only-box"
                  style={{ maxHeight: "200px", overflowY: "auto" }}>
                  {inventoryAdministrationEditState?.inventory?.branchFilters?.map(
                    (filter) => {
                      return (
                        <div key={uuid()}>
                          <label className="d-flex align-items-center my-1">
                            <input
                              name="depot"
                              type="checkbox"
                              checked
                              readOnly
                              disabled
                              className="form-check-input list-checkbox mt-0 flex-shrink-0"
                            />
                            <span className="ml-2">{filter?.branchName}</span>
                          </label>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            </div>
          )}

        {status < 2 &&
          parseInt(inventoryAdministrationEditState?.depots?.length) ===
            parseInt(
              inventoryAdministrationEditState?.inventory?.branchFilters.length
            ) && (
            <div className="col-12 my-3">
              <span className="d-flex align-items-center">
                <span className="fe fe-alert-triangle text-danger mr-2"></span>
                <small className="d-block">{`${t("Total inventory")} ${t(
                  "on"
                )} ${t("all depots")}`}</small>
              </span>
            </div>
          )}

        {status < 2 && (
          <button
            className="btn btn-white d-block w-100"
            disabled={loading}
            onClick={() => setShowBranchFilter(true)}>
            <div className="d-flex align-items-center justify-content-center">
              <CustomSvgIcon
                width={20}
                height={20}
                name="add"
                className={`mr-2`}
              />
              <span>
                {t("Add")}/{t("Edit")}
              </span>
            </div>
          </button>
        )}

        {status === 1 && (
          <div className="row align-items-center mt-5 mb-3">
            <div className="col-12">
              <label className="field__label form-label mt-1 mb-1 text-uppercase">
                {t("Delete a scheduled inventory")}
              </label>
              <p>
                {t(
                  "You can change or delete a scheduled inventory up to 30 minutes before the start time."
                )}
              </p>
              <button
                className="btn btn-danger text-white d-block w-100"
                disabled={loading}
                onClick={() => {
                  setShowModalSheet(true)
                  setModalViewState("delete")
                }}>
                <div className="d-flex align-items-center justify-content-center">
                  <span className="fe fe-trash-2 mr-2"></span>
                  <span>
                    {t("Delete")} {t("inventory")}
                  </span>
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
      <ModalSheet
        show={showModalSheet}
        roundedBig={showModalSheet}
        maxWidth={false}
        shadow={false}>
        {modalViewState === "delete" && (
          <>
            <div className="position-relative mt-3">
              <h2 className="header-title mb-0 text-center px-3">
                {t("Delete inventory")}
              </h2>
            </div>

            <div className="field my-4">
              {loading && <Loader />}

              {!loading && (
                <div className="row mb-3">
                  <div className="col-12">
                    <p className="text-danger mb-0">
                      {t(
                        "Warning: This action is not reversible. Please be certain."
                      )}
                    </p>
                  </div>
                </div>
              )}

              <div className="text-center mt-4 border-top pt-4">
                <div className="d-flex align-items-center justify-content-between">
                  <button
                    disabled={loading}
                    className="btn btn-outline-secondary w-100 mr-3"
                    onClick={() => {
                      setShowModalSheet(false)
                      setModalViewState(null)
                    }}>
                    {t("Cancel")}
                  </button>
                  <button
                    disabled={loading}
                    className="btn btn-danger w-100"
                    onClick={() => handleDelete()}>
                    {t("Delete")}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {modalViewState === "confirmation" && (
          <>
            <div className="position-relative mt-3">
              <h2 className="header-title mb-0 text-center px-3">
                {t("Edit inventory")}
              </h2>
            </div>
            <div className="field my-4">
              {loading && <Loader />}

              {saveAndUpdateVerification ? (
                !loading && (
                  <div className="row mb-3">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                      <input
                        type="checkbox"
                        id="inventoryVerification"
                        name="inventoryVerification"
                        className="form-check-input list-checkbox mt-0 flex-shrink-0 mr-2"
                        checked={inventoryVerification}
                        onChange={() => handleInventoryVerifiction()}
                      />
                      <label
                        htmlFor="inventoryVerification"
                        className="field__label form-label mt-1 mb-1 text-uppercase">
                        {t(
                          "I confirm the start of the inventory according to the chosen time and selection."
                        )}
                      </label>
                    </div>
                  </div>
                )
              ) : (
                <div className="row mb-3">
                  <div className="col-12">
                    <p className="text-muted mb-2">
                      {t(
                        "You edit an inventory with the below information and selection"
                      )}
                      {":"}
                    </p>
                    {name && (
                      <p className="mb-0 font-weight-bold">
                        <span>{name}</span>
                      </p>
                    )}
                    {start && (
                      <p className="mb-0">
                        <span>{moment(start).format("YYYY-MM-DD HH:mm")}</span>
                      </p>
                    )}

                    {branchFilters &&
                    branchFilters.length > 0 &&
                    branchFilters.length !==
                      inventoryAdministrationEditState?.depots?.length ? (
                      <p className="mb-0">
                        <span className="d-block">
                          {branchFilters?.map((filter, index) => {
                            const separator =
                              index + 1 === branchFilters.length ? "" : ", "

                            return (
                              <span key={uuid()}>
                                {filter?.branchName}
                                {separator}
                              </span>
                            )
                          })}
                        </span>
                      </p>
                    ) : (
                      <span className="d-block">
                        <span className="fe fe-alert-triangle mr-2 text-danger"></span>
                        <span>{`${t("Total inventory")} ${t("on")} ${t(
                          "all depots"
                        )}`}</span>
                      </span>
                    )}
                  </div>
                </div>
              )}

              <div className="text-center mt-4 border-top pt-4">
                <div className="d-flex align-items-center justify-content-between">
                  <button
                    disabled={loading}
                    className="btn btn-outline-secondary w-100 mr-3"
                    onClick={() => {
                      setShowModalSheet(false)
                      setSaveAndUpdateVerification(false)
                      setInventoryVerification(false)
                    }}>
                    {t("Cancel")}
                  </button>
                  {!saveAndUpdateVerification && (
                    <button
                      disabled={loading}
                      className="btn btn-primary w-100"
                      onClick={() => setSaveAndUpdateVerification(true)}>
                      {t("Continue")}
                    </button>
                  )}
                  {saveAndUpdateVerification && (
                    <button
                      type="submit"
                      form="edit-inventory-form"
                      disabled={loading || !inventoryVerification}
                      className="btn btn-primary w-100">
                      {t("Save")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {modalViewState === "startControlPhaseVerification" && (
          <>
            <div className="position-relative mt-3">
              <h2 className="header-title mb-0 text-center px-3">
                {t("Start the control phase")}
              </h2>
              <p>
                {t(
                  "Warning: This action is not reversible. Please be certain."
                )}
              </p>
            </div>
            <div className="text-center mt-4 border-top pt-4">
              <div className="d-flex align-items-center justify-content-between">
                <button
                  className="btn btn-outline-secondary w-100 mr-3"
                  onClick={() => {
                    setShowModalSheet(false)
                    setModalViewState(null)
                  }}>
                  {t("Cancel")}
                </button>
                <button
                  disabled={loading}
                  className="btn btn-primary w-100"
                  onClick={() => handleStartControlPhase()}>
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm text-white"
                      role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    t("Continue")
                  )}
                </button>
              </div>
            </div>
          </>
        )}

        {modalViewState === "completeControlPhaseVerification" && (
          <>
            <div className="position-relative mt-3">
              <h2 className="header-title mb-0 text-center px-3">
                {t("Complete the control phase")}
              </h2>
              <p>
                {t(
                  "Warning: This action is not reversible. Please be certain."
                )}
              </p>
            </div>
            <div className="text-center mt-4 border-top pt-4">
              <div className="d-flex align-items-center justify-content-between">
                <button
                  className="btn btn-outline-secondary w-100 mr-3"
                  onClick={() => {
                    setShowModalSheet(false)
                    setModalViewState(null)
                  }}>
                  {t("Cancel")}
                </button>
                <button
                  disabled={loading}
                  className="btn btn-primary w-100"
                  onClick={() => handleCompleteControlPhase()}>
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm text-white"
                      role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    t("Continue")
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </ModalSheet>
    </>
  )
}
