import React, { useEffect } from "react"
import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc"
import { Routes, Route, Navigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useCookies } from "react-cookie"

import PageWrapper from "../PageWrapper"

import Dashboard from "../pages/Dashboard/Dashboard"
import Maintenance from "../pages/Maintenance/Maintenance"
import Projects from "../pages/Projects/Projects"
import Returns from "../pages/Returns/Returns"
import Return from "../pages/Return/Return"
import Summary from "../pages/Return/Summary"
import Depots from "../pages/Depots/Depots"
import Project from "../pages/Project/Project"
import RentalPermissions from "../pages/RentalPermissions/RentalPermissions"
import Users from "../pages/Users/Users"
import Machines from "../pages/Machines/Machines"
import Machine from "../pages/Machine/Machine"
import PageNotFound from "../pages/PageNotFound/PageNotFound"
import MachineTransfer from "../pages/MachineTransfer/MachineTransfer"
import IncomingMachines from "../pages/IncomingMachines/IncomingMachines"
import CompanyProfile from "../pages/CompanyProfile/CompanyProfile"

import CreateProject from "../pages/Project/Create"
import EditProject from "../pages/Project/Edit"
import DocumentRecipients from "../pages/Project/DocumentRecipients"

import NoPermission from "../pages/NoPermission/NoPermission"
import Order from "../pages/Order/Order"
import EditOrder from "../pages/Order/EditOrder"
import NewOrder from "../pages/Order/NewOrder"
import Inventory from "../pages/Inventory/Inventory"
import InventoryFind from "../pages/Inventory/Find"
import InventoryRegistration from "../pages/Inventory/InventoryRegistration"
import InventoryAdministration from "../pages/Inventory/Administration/Administration"
import InventoryAdministrationCreate from "../pages/Inventory/Administration/Create"
import InventoryAdministrationEdit from "../pages/Inventory/Administration/Edit"
import AdminPage from "../pages/Admin/Admin"

import CustomerMachines from "../pages/__Customer/Machines/Machines"
import CustomerMachineCategory from "../pages/__Customer/Machines/Category/Category"
import CustomerMachine from "../pages/__Customer/Machine/Machine"
import CustomerSearch from "../pages/__Customer/Search/Search"

import CustomerMachineLists from "../pages/__Customer/MachineLists/MachineLists"

import PageLoader from "../components/PageLoader/PageLoader"
import { usePermissionCheck } from "../utils/hooks"

const RoutesWrapper = () => {
  const { oidcUserLoadingState, oidcUser } = useOidcUser()
  const { accessToken } = useOidcAccessToken()
  const { t } = useTranslation()
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies([])
  const hasHLLInternalPermission = usePermissionCheck("MinaSidor.SkapaRetur")

  useEffect(() => {
    console.log("accessToken renew...")
    setCookie("HLL.JWT.AuthToken", accessToken, { path: "/" })
  }, [accessToken, setCookie])

  if (oidcUserLoadingState.Loading) {
    return <PageLoader />
  }

  if (oidcUserLoadingState.Unauthenticated) {
    return <NoPermission />
  }

  if (oidcUserLoadingState.LoadingError) {
    return <NoPermission />
  }

  if (!oidcUser) {
    return <PageLoader />
  }

  return (
    <>
      <PageWrapper>
        <Routes>
          <Route
            exact
            path="/"
            element={
              hasHLLInternalPermission ? (
                <Navigate to={`/${t("staff")}/${t("dashboard")}`} />
              ) : (
                <Navigate to={`/${t("customer")}/${t("dashboard")}`} />
              )
            }
          />
          <Route exact path="/kund" element={<Dashboard />} />
          <Route exact path="/customer" element={<Dashboard />} />

          <Route exact path="/kund/oversikt" element={<Dashboard />} />
          <Route exact path="/customer/dashboard" element={<Dashboard />} />
          <Route exact path="/personal/oversikt" element={<Dashboard />} />
          <Route exact path="/staff/dashboard" element={<Dashboard />} />

          <Route
            exact
            path="/kund"
            element={<Navigate to={`/${t("customer")}/${t("dashboard")}`} />}
          />

          <Route
            exact
            path="/customer"
            element={<Navigate to={`/${t("customer")}/${t("dashboard")}`} />}
          />

          <Route
            exact
            path="/personal"
            element={<Navigate to={`/${t("staff")}/${t("dashboard")}`} />}
          />

          <Route
            exact
            path="/staff"
            element={<Navigate to={`/${t("staff")}/${t("dashboard")}`} />}
          />

          <Route
            exact
            path="/kund/maskinlistor"
            element={
              hasHLLInternalPermission ? (
                <NoPermission />
              ) : (
                <CustomerMachineLists />
              )
            }
          />

          <Route
            exact
            path="/customer/machinelists"
            element={
              hasHLLInternalPermission ? (
                <NoPermission />
              ) : (
                <CustomerMachineLists />
              )
            }
          />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/oversikt" element={<Dashboard />} />
          <Route exact path="/projects" element={<Projects />} />
          <Route exact path="/projekt" element={<Projects />} />
          <Route exact path="/projekt/skapa" element={<CreateProject />} />
          <Route exact path="/project/create" element={<CreateProject />} />
          <Route exact path="/project/:customerId/:id" element={<Project />} />
          <Route exact path="/projekt/:customerId/:id" element={<Project />} />
          <Route
            exact
            path="/project/:customerId/:id/edit"
            element={<EditProject />}
          />
          <Route
            exact
            path="/projekt/:customerId/:id/redigera"
            element={<EditProject />}
          />
          <Route
            exact
            path="/projekt/:customerId/:id/document-recipients"
            element={<DocumentRecipients />}
          />
          <Route
            exact
            path="/projekt/:customerId/:id/dokument-mottagare"
            element={<DocumentRecipients />}
          />

          <Route exact path="/kund/maskiner" element={<CustomerMachines />} />

          <Route
            exact
            path="/customer/machines"
            element={<CustomerMachines />}
          />

          <Route
            exact
            path="/kund/maskiner/:category"
            element={<CustomerMachineCategory />}
          />

          <Route
            path="/kund/maskiner/:category/*"
            element={<CustomerMachineCategory />}
          />

          <Route
            exact
            path="/customer/machines/:category"
            element={<CustomerMachineCategory />}
          />

          <Route
            exact
            path="/customer/machines/:category/*"
            element={<CustomerMachineCategory />}
          />

          <Route
            exact
            path="/kund/maskin/:category"
            element={<CustomerMachine />}
          />

          <Route
            exact
            path="/customer/machine/:category"
            element={<CustomerMachine />}
          />

          <Route
            exact
            path="/kund/maskin/:category/*"
            element={<CustomerMachine />}
          />

          <Route
            exact
            path="/customer/machine/:category/*"
            element={<CustomerMachine />}
          />

          <Route exact path="/kund/sok" element={<CustomerSearch />} />
          <Route exact path="/customer/search" element={<CustomerSearch />} />

          <Route exact path="/returns" element={<Returns />} />
          <Route exact path="/returer" element={<Returns />} />
          <Route
            exact
            path="/returns/:customerNumber/:customerOrderId"
            element={<Return />}
          />
          <Route
            exact
            path="/returer/:customerNumber/:customerOrderId"
            element={<Return />}
          />
          <Route
            exact
            path="/returns/:customerNumber/:customerOrderId/summary"
            element={<Summary />}
          />
          <Route
            exact
            path="/returer/:customerNumber/:customerOrderId/summering"
            element={<Summary />}
          />
          <Route exact path="/depots" element={<Depots />} />
          <Route exact path="/depaer" element={<Depots />} />
          <Route
            exact
            path="/rental-permissions"
            element={<RentalPermissions />}
          />
          <Route
            exact
            path="/behoriga-att-hyra"
            element={<RentalPermissions />}
          />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/anvandare" element={<Users />} />
          <Route exact path="/maskiner" element={<Machines />} />
          <Route exact path="/machines" element={<Machines />} />
          <Route exact path="/machines/:id/:name" element={<Machine />} />
          <Route exact path="/maskiner/:id/:name" element={<Machine />} />
          <Route exact path="/maskinflytt" element={<MachineTransfer />} />
          <Route exact path="/machine-transfer" element={<MachineTransfer />} />
          <Route
            exact
            path="/inkommande-maskiner"
            element={<IncomingMachines />}
          />
          <Route
            exact
            path="/incoming-machines"
            element={<IncomingMachines />}
          />
          <Route exact path="/maintenance" element={<Maintenance />} />
          <Route exact path="/foretagsprofil" element={<CompanyProfile />} />
          <Route
            exact
            path="/foretagsprofil/:slug"
            element={<CompanyProfile />}
          />
          <Route exact path="/company-profile" element={<CompanyProfile />} />
          <Route
            exact
            path="/company-profile/:slug"
            element={<CompanyProfile />}
          />
          <Route exact path="/order" element={<Order />} />
          <Route exact path="/order/edit/:id" element={<EditOrder />} />
          <Route exact path="/order/redigera/:id" element={<EditOrder />} />
          <Route exact path="/order/create" element={<NewOrder />} />
          <Route exact path="/order/skapa" element={<NewOrder />} />

          <Route exact path="/inventory" element={<Inventory />} />
          <Route exact path="/inventering" element={<Inventory />} />

          <Route exact path="/inventory/find" element={<InventoryFind />} />
          <Route exact path="/inventering/hitta" element={<InventoryFind />} />

          <Route
            exact
            path="/inventory/registration/:id"
            element={<InventoryRegistration />}
          />
          <Route
            exact
            path="/inventering/registrering/:id"
            element={<InventoryRegistration />}
          />

          <Route
            exact
            path="/inventory/administration"
            element={<InventoryAdministration />}
          />
          <Route
            exact
            path="/inventering/administration"
            element={<InventoryAdministration />}
          />

          <Route
            exact
            path="/inventory/administration/edit/:id"
            element={<InventoryAdministrationEdit />}
          />
          <Route
            exact
            path="/inventory/administration/edit"
            element={<PageNotFound />}
          />

          <Route
            exact
            path="/inventering/administration/redigera/:id"
            element={<InventoryAdministrationEdit />}
          />

          <Route
            exact
            path="/inventering/administration/redigera"
            element={<PageNotFound />}
          />

          <Route
            exact
            path="/inventory/administration/create"
            element={<InventoryAdministrationCreate />}
          />
          <Route
            exact
            path="/inventering/administration/skapa"
            element={<InventoryAdministrationCreate />}
          />
          <Route exact path="/admin/" element={<AdminPage />} />
          {/* <Route default element={PageNotFound} /> */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </PageWrapper>
    </>
  )
}

export default RoutesWrapper
