import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import DatePicker, { registerLocale } from "react-datepicker"
import sv from "date-fns/locale/sv"
import "react-datepicker/dist/react-datepicker.css"

import Loader from "../../Loader/Loader"
import toast from "react-hot-toast"

import Storage from "../../../utils/storage"
registerLocale("sv", sv)

export default function ReminderModal({
  show,
  close,
  machineInformationData,
  projectReminders,
  setProjectReminders,
  setUpdatingUserState
}) {
  const { t } = useTranslation()
  const customer = Storage.get("customer", null, "local") ?? null
  const existingReminder =
    projectReminders &&
    projectReminders.find(
      (r) => r.orderRowId === machineInformationData?.orderRowId
    )

  const [loading, setLoading] = useState(true)
  const [reminderData, setReminderData] = useState({
    date: new Date()
  })

  const handleDateChange = (date) => {
    setReminderData({
      ...reminderData,
      date: new Date(date)
    })
  }

  const handleRemoveProjectReminder = (id) => {
    setProjectReminders((current) =>
      current.filter((item) => item?.orderRowId !== id)
    )
    setUpdatingUserState(true)
    toast.success(t("Reminder deleted"))
    close()
  }

  const handleSaveAndUpdateProjectRemiders = () => {
    const tempData = {
      ...machineInformationData,
      reminderData
    }

    if (existingReminder) {
      const updatedData = projectReminders.map((x) =>
        x.orderRowId === machineInformationData?.orderRowId
          ? { ...x, reminderData }
          : x
      )
      setProjectReminders(updatedData)
      toast.success(t("Reminder is updated"))
    } else {
      setProjectReminders((currentArr) => [...currentArr, tempData])
      toast.success(t("Reminder is added"))

      window.dataLayer.push({
        event: "HLL_planned_return",
        company: customer?.name,
        name: machineInformationData?.data?.orderRow?.reference?.itemName,
        userType: "customer"
      })
    }
    setUpdatingUserState(true)
    close()
  }

  useEffect(() => {
    if (machineInformationData) {
      setLoading(false)
      setReminderData({
        date: new Date()
      })
    }
  }, [machineInformationData])

  useEffect(() => {
    if (existingReminder) {
      setReminderData(existingReminder?.reminderData)
    }
  }, [existingReminder])

  return (
    <Modal
      show={show}
      onHide={() => close()}
      centered
      animation={false}
      backdrop="static">
      <Modal.Header className="d-block">
        <div className="position-relative">
          <h2 className="header-title mt-3 mb-0 text-center">
            {t("Set a planned end date")}
          </h2>
        </div>
      </Modal.Header>
      <Modal.Header className="d-block pt-0">
        {machineInformationData?.orderRow?.reference?.itemName && (
          <p className="mt-1 mb-0 text-center">
            {machineInformationData?.orderRow?.reference?.itemName}
          </p>
        )}
        <div className="row">
          <div className="field col-12 mb-2 mb-sm-0">
            <p className="m-0 text-center d-inline d-sm-block">
              <small>
                {t(
                  "Plan machines you are going to book a return on, machines with planned end dates are visible on the start page."
                )}
              </small>
            </p>
            <p className="m-0 text-center d-inline d-sm-block">
              <small>
                {" "}
                {t(
                  "Book the return of the machine directly here in Mitt HLL or contact your nearest depot."
                )}
              </small>
            </p>
          </div>
          <div className="field col-12">
            <label
              htmlFor="date"
              className="field__label form-label mb-1 text-uppercase">
              {t("Date")}
            </label>
            <DatePicker
              name="date"
              selected={new Date(reminderData?.date)}
              onChange={(date) => handleDateChange(date)}
              required
              disabled={loading}
              className="form-control"
              dateFormat="yyyy-MM-dd"
              minDate={new Date()}
              //showTimeSelect
            />
          </div>
          {existingReminder && (
            <div className="field col-12">
              <button
                className="btn btn-danger w-100 d-block mt-2"
                onClick={() =>
                  handleRemoveProjectReminder(
                    machineInformationData?.orderRowId
                  )
                }>
                {t("Remove")}
              </button>
            </div>
          )}
        </div>
      </Modal.Header>
      <Modal.Body>{loading && <Loader />}</Modal.Body>
      {!loading && (
        <Modal.Footer>
          <div className="d-flex align-items-center">
            <button
              className="btn btn-outline-secondary w-100 d-block mr-3"
              onClick={() => close()}>
              {t("Close")}
            </button>
            <button
              className={`btn btn-primary w-100 d-block${
                !reminderData ? " is-disabled" : ""
              }`}
              disabled={!reminderData}
              onClick={() => handleSaveAndUpdateProjectRemiders()}>
              {t("Continue")}
            </button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  )
}
