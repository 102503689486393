import i18n from "../../i18n"

export const inviteFormTemplate = {
  fields: [
    {
      name: "action",
      type: "action"
    },
    {
      name: "customerId",
      type: "hidden"
    },
    {
      name: "id",
      type: "hidden"
    },
    {
      name: "firstName",
      type: "text",
      title: i18n.t("First name"),
      required: {
        value: true,
        message: i18n.t("First name is required")
      }
    },
    {
      name: "lastName",
      type: "text",
      title: i18n.t("Last name"),
      required: {
        value: true,
        message: i18n.t("Last name is required")
      }
    },
    {
      name: "email",
      type: "email",
      title: i18n.t("Email"),
      required: {
        value: true,
        message: i18n.t("Email is required")
      }
    },
    {
      name: "userAccountGroups",
      type: "userAccountGroups",
      title: i18n.t("Permissions")
    }
  ]
}

export const updateFormTemplate = {
  fields: [
    {
      name: "action",
      type: "action"
    },
    {
      name: "customerId",
      type: "hidden"
    },
    {
      name: "id",
      type: "hidden"
    },
    {
      name: "firstName",
      type: "text",
      title: i18n.t("First name"),
      required: {
        value: true,
        message: i18n.t("First name is required")
      }
    },
    {
      name: "lastName",
      type: "text",
      title: i18n.t("Last name"),
      required: {
        value: true,
        message: i18n.t("Last name is required")
      }
    },
    {
      name: "title",
      type: "text",
      title: i18n.t("Title")
    },
    {
      name: "dateOfBirth",
      type: "number",
      title: i18n.t("Date of Birth"),
      required: {
        value: true,
        message: i18n.t("Date of Birth is required")
      },
      placeholder: i18n.t("YYYY-MM-DD"),
      regExp: true,
      format: "####-##-##"
    },
    {
      name: "email",
      type: "email",
      title: i18n.t("Email")
    },
    {
      name: "endDate",
      type: "date",
      title: i18n.t("Expiry date"),
      instructions: i18n.t("Last date the permission is active")
    },
    {
      name: "phone1",
      type: "tel",
      title: i18n.t("Phone 1"),
      required: {
        value: true,
        message: i18n.t("Phone 1 is required")
      },
      placeholder: i18n.t("070-XXXXXXX")
    },
    {
      name: "phone2",
      type: "tel",
      title: i18n.t("Phone 2"),
      placeholder: i18n.t("070-XXXXXXX")
    }
  ]
}
