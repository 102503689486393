import React, { useEffect } from "react"
import {
  inventoryView,
  inventoryAdministrationCreate
} from "../../../components/Inventory/Store"
import { useSetRecoilState } from "recoil"
import { useTranslation } from "react-i18next"
import CreateForm from "../../../components/Inventory/Common/Administration/Create/Form"
import Toaster from "../../../components/Common/Toast"
import { usePermissionCheck } from "../../../utils/hooks"
import PageNotFound from "../../PageNotFound/PageNotFound"

export default function Create() {
  const { t } = useTranslation()

  const hasInventoryAdminPermission = usePermissionCheck(
    "MinaSidor.inventeringAdministrera"
  )

  const setViewState = useSetRecoilState(inventoryView)
  const setInventoryAdministrationCreateState = useSetRecoilState(
    inventoryAdministrationCreate
  )
  // Set view state to newInventory
  useEffect(() => {
    setViewState("inventoryAdministrationCreate")

    setInventoryAdministrationCreateState((previousAdministrationState) => {
      return {
        ...previousAdministrationState,
        pageHeader: t("Create new inventory")
      }
    })
  }, [setInventoryAdministrationCreateState, setViewState, t])

  useEffect(() => {
    window.onbeforeunload = function () {
      return true
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [])

  if (!hasInventoryAdminPermission) {
    return <PageNotFound />
  }

  return (
    <>
      <Toaster />
      <CreateForm />
    </>
  )
}
