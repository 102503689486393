import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { Form, FormControl } from "react-bootstrap"
import { isMobile } from "react-device-detect"
import CustomSvgIcon from "../../../Common/CustomSvgIcon"
const SearchForm = ({
  onSubmitHandler,
  onChangeHandler,
  onBlurHandler,
  disabled,
  status,
  setStatus,
  error,
  setError,
  loading,
  searchText
}) => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const inputStyle = isMobile
    ? { paddingLeft: "50px", paddingRight: "50px" }
    : { paddingLeft: "50px", paddingRight: "50px" }

  return (
    <Form
      autoComplete="off"
      className="position-relative"
      onSubmit={(event) => {
        const searchInput = event?.target?.elements.search
        event.preventDefault()
        onSubmitHandler(event?.target?.value ? event.target.value : "")
        searchInput.blur()
      }}>
      <div className="row">
        <div className="col-12">
          <div className="position-relative">
            <FormControl
              className={`form-control form-control-lg${
                disabled ? " disabled" : ""
              }`}
              name="search"
              type="search"
              placeholder={t("Search the list")}
              ref={inputRef}
              value={searchText}
              required
              enterKeyHint="Search"
              autoCapitalize="none"
              autoComplete="off"
              spellCheck={false}
              style={inputStyle}
              autoCorrect="off"
              disabled={disabled}
              onChange={(event) =>
                onChangeHandler(event?.target?.value ? event.target.value : "")
              }
            />
            <button
              className={`bg-transparent position-absolute${
                disabled ? " disabled" : ""
              }`}
              style={{ top: "50%", left: 10, transform: "translateY(-50%)" }}
              type="submit"
              disabled={disabled}>
              <CustomSvgIcon
                name="search"
                width={20}
                height={20}
                className="text-muted"
              />
            </button>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default SearchForm
