import React, { useState } from "react"
import moment from "moment"
import "moment-timezone"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import {
  getFleetModel,
  getFleetModelCustomerPriceWithoutDetails
} from "../../utils/hooks"
import Loader from "../Loader/Loader"

import {
  priceWithCurrencyHelper,
  priceUnitHelper,
  orderRowTypeMap,
  priceDebitType
} from "../../utils/helpers"

import Storage from "../../utils/storage"

export default function MachineInformation({
  showInfoModal,
  data,
  projectReminders
}) {
  const { t } = useTranslation()
  const customer = Storage.get("customer", null, "local") ?? null
  const orderRowType = orderRowTypeMap(data?.orderRowType)

  const typeMapping = {
    ARTICLE: t("Article(s)"),
    INDIVIDUAL: t("Individual"),
    ACCESSORIES: t("Accessories")
  }

  const existingReminder =
    projectReminders && projectReminders.find((r) => r.orderRowId === data?.id)

  const [fleetModelData, setFleetModelData] = useState(null)
  const [fleetModelPriceData, setFleetModelPriceData] = useState(null)

  const {
    isLoading: fleetModelIsLoading,
    isRefetching: fleetModelIsRefetching
  } = useQuery({
    queryKey: ["fleetModel"],
    queryFn: async () => {
      const fleetModel = await getFleetModel(data?.reference?.fleetModelId)
      setFleetModelData(fleetModel)
      return fleetModel ?? null
    },
    refetchOnWindowFocus: false,
    enabled:
      showInfoModal &&
      data?.reference?.fleetModelId?.length > 0 &&
      orderRowType !== "Accessory",
    cacheTime: 60000 * 60 // 1 hour
  })

  const { isLoading: priceIsLoading, isRefetching: priceIsRefetching } =
    useQuery({
      queryKey: ["fleetModelCustomerPrice"],
      queryFn: async () => {
        const fleetModelPrice = await getFleetModelCustomerPriceWithoutDetails(
          customer?.id,
          [parseFloat(data?.reference?.fleetModelId)]
        )
        setFleetModelPriceData(fleetModelPrice)
        return fleetModelPrice ?? null
      },
      refetchOnWindowFocus: false,
      enabled:
        showInfoModal &&
        data?.reference?.fleetModelId?.length > 0 &&
        orderRowType !== "Accessory",
      cacheTime: 60000 * 60 // 1 hour
    })

  const mainImageUrl = fleetModelData?.item
    ? fleetModelData?.item?.mainImageUrl
    : null

  // Price logic based on orderRowType
  let contractPrice = null
  let contractPriceDebit = priceDebitType(fleetModelPriceData?.debitType)

  if (
    orderRowType === "Rent" &&
    fleetModelPriceData?.rentalDiscount?.discountPrice
  ) {
    contractPrice = `${priceWithCurrencyHelper(
      fleetModelPriceData?.rentalDiscount?.discountPrice
    )} ${priceUnitHelper(fleetModelPriceData?.priceUnit)}`
  } else if (orderRowType === "Accessory") {
    contractPrice = `${priceWithCurrencyHelper(0)} ${priceUnitHelper(
      fleetModelPriceData?.priceUnit
    )}`
  } else if (
    orderRowType === "Purchase" &&
    fleetModelPriceData?.purchasePrice
  ) {
    contractPrice = `${priceWithCurrencyHelper(
      fleetModelPriceData?.purchasePrice
    )} ${priceUnitHelper(fleetModelPriceData?.priceUnit)}`
  } else {
    contractPrice = `${priceWithCurrencyHelper(
      fleetModelPriceData?.rentalDiscount?.discountPrice
    )} ${priceUnitHelper(fleetModelPriceData?.priceUnit)}`
  }

  return (
    <>
      {mainImageUrl && (
        <div className="nav-off-canvas-image-gallery">
          <div className="p-3">
            {fleetModelIsLoading || fleetModelIsRefetching ? (
              <Loader className="my-5" />
            ) : (
              <img className="img-fluid" src={mainImageUrl} alt="" />
            )}
          </div>
        </div>
      )}

      <div className="nav-off-canvas-data-row d-flex align-items-center justify-content-between">
        <div>{t("Name")}</div>
        <div className="font-weight-bold">
          {data?.reference?.itemName && data?.reference?.itemName}
        </div>
      </div>

      <div className="nav-off-canvas-data-row d-flex align-items-center justify-content-between">
        <div>{t("Amount")}</div>
        <div className="font-weight-bold">{data?.quantity}</div>
      </div>

      <div className="nav-off-canvas-data-row d-flex align-items-center justify-content-between">
        <div>{t("Type")}</div>
        <div className="font-weight-bold">
          {data?.orderRowType === 4
            ? typeMapping["ACCESSORIES"]
            : typeMapping[data?.reference?.type]}
        </div>
      </div>

      <div className="nav-off-canvas-data-row d-flex align-items-center justify-content-between">
        <div>{t("Date")}</div>
        <div className="font-weight-bold">
          {moment(data?.startTime).format("YYYY-MM-DD")}
        </div>
      </div>

      <div className="nav-off-canvas-data-row d-flex align-items-center justify-content-between">
        {data?.orderRowType === 2 ? (
          <div>{t("Purchased by")}</div>
        ) : (
          <div>{t("Rented by")}</div>
        )}
        <div className="font-weight-bold">{data?.rentalContact?.name}</div>
      </div>

      {existingReminder && Object.keys(existingReminder).length > 0 && (
        <div className="nav-off-canvas-data-row d-flex align-items-center justify-content-between">
          <div>{t("Planned end date")}</div>
          <div className="font-weight-bold">
            {moment(existingReminder?.reminderData?.date).format("YYYY-MM-DD")}
          </div>
        </div>
      )}
      <div className="nav-off-canvas-data-row d-flex align-items-center justify-content-between">
        <div>{t("Price")}</div>

        {priceIsLoading || priceIsRefetching ? (
          <div className="d-flex align-items-center justify-content-start">
            <Loader className="text-left" />
            <small className="d-block ml-2">{`${t("Loading price")}...`}</small>
          </div>
        ) : (
          <div className="font-weight-bold">
            {contractPrice && contractPrice}
          </div>
        )}
      </div>

      {!priceIsLoading && !priceIsRefetching && (
        <div className="nav-off-canvas-data-row d-flex align-items-center justify-content-between">
          <div>{t("Debiting")}</div>

          <div className="font-weight-bold">
            {orderRowType === "Purchase"
              ? t("Purchase")
              : contractPriceDebit && `${t("Rent")}/${contractPriceDebit}`}
          </div>
        </div>
      )}

      <div className="nav-off-canvas-data-row d-flex notice">
        <div className="bg-light w-100 text-black border border-0 rounded-0 p-2">
          <div className="d-flex align-items-center justify-content-start">
            <span className="mr-2">
              <i className="fe fe-info text-primary" />
            </span>
            <small>
              {t(
                "All prices are your contract prices, excluding VAT, excluding insurance and may differ from the final invoice."
              )}
            </small>
          </div>
        </div>
      </div>
    </>
  )
}
