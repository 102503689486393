import React, { useEffect, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { formatTimeAgo } from "../../utils/helpers"
import axios from "axios"
import { setUserSettings } from "../../utils/hooks"
import Storage from "../../utils/storage"
import { useLocation } from "react-router-dom"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const ProjectRemindersCard = ({ data = [], header = "" }) => {
  const { t } = useTranslation()

  const [updatedReminders, setUpdatedReminders] = useState([])
  const [updatingUserState, setUpdatingUserState] = useState(false)
  const reminders = data.sort((a, b) =>
    a.reminderData?.date > b.reminderData?.date ? 1 : -1
  )
  const perPage = 4
  const [next, setNext] = useState(perPage)
  const userState = Storage.get("userState") ?? null
  const location = useLocation()

  const handleLoadMore = () => {
    setNext(next + perPage)
  }

  const streamReminders = useCallback(async () => {
    let tempArr = []

    for (let reminder of data) {
      const projectId = reminder?.projectId
      const orderRowId = reminder?.orderRowId

      try {
        let streamOrderRowResponse = await axios.post(
          "/api/hll/streamOrderRow",
          {
            projectId: projectId,
            orderRowId: orderRowId
          }
        )

        if (streamOrderRowResponse?.data?.endTime === null) {
          tempArr.push(reminder)
        }
      } catch (error) {
        console.log("error" + error)
        setUpdatedReminders(data) // if error set to default data prop
      } finally {
        setUpdatedReminders(tempArr)
        setUpdatingUserState(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (updatingUserState) {
      setUserSettings({
        ...userState,
        reminders: updatedReminders
      })
      Storage.set(
        "userState",
        {
          ...userState,
          reminders: updatedReminders
        },
        "local"
      )

      setUpdatingUserState(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedReminders, updatingUserState, userState])

  //Streaming on router location change
  useEffect(() => {
    if (location && data) {
      console.log("streaming reminders..")
      streamReminders()
    }
  }, [data, location, streamReminders]) // trigger useEffect on location router

  return (
    <div className="card h-100">
      <div className="card-header">
        {header && <h4 className="card-header-title">{header}</h4>}
      </div>

      {reminders && reminders.length !== 0 && (
        <div className="card-body">
          <ul className="list-group list-group-flush my-n3">
            {reminders?.slice(0, next).map((item, index) => {
              const { date } = item?.reminderData

              const today = new Date()
              const nextDay = new Date(today)
              nextDay.setDate(today.getDate() + 1)

              const reminderDate = new Date(date)
              const reminderEndingDate = new Date(reminderDate)
              reminderEndingDate.setDate(reminderDate.getDate() + 1) // adding 1 day for passed date

              let badgeClass = "bg-light"
              const timeleft = formatTimeAgo(Date.parse(reminderEndingDate))

              let hasPassed =
                reminderEndingDate.toISOString() < today.toISOString() //reminderEndingDate  need to be reminderDate when we using push notifications

              if (hasPassed) {
                badgeClass = "bg-danger"
              }

              return (
                <li
                  key={item?.orderRowId}
                  className={`list-group-item border-top-0 border-left-0 border-right-0${
                    index === reminders.length - 1 ? " border-bottom-0" : ""
                  }`}>
                  <Link
                    to={{
                      pathname: item?.pathname
                    }}
                    className="d-block">
                    <div className="row align-items-center">
                      <div className="col-auto align-self-start">
                        <CustomSvgIcon
                          width={16}
                          height={16}
                          name="calender"
                          className="text-black"
                        />
                      </div>
                      <div className="col">
                        <p className="text-gray-700 my-0">
                          <span className="mr-2">
                            <small>
                              {reminderDate.toLocaleDateString("sv-SE")}
                            </small>
                          </span>
                          <span className={`badge ${badgeClass}`}>
                            <small>
                              {hasPassed ? t("End date has passed") : timeleft}
                            </small>
                          </span>
                        </p>
                        <p className="body-text font-weight-normal my-0">
                          {item?.data?.orderRow?.reference?.itemName}
                        </p>
                        <p className="text-gray-700 my-0">
                          <small>
                            {item?.data?.project?.number &&
                              item?.data?.project?.number}
                            {item?.data?.project?.name &&
                              ` ${item?.data?.project?.name}`}
                          </small>
                        </p>
                      </div>

                      <div className="col-auto">
                        <CustomSvgIcon
                          width={16}
                          height={16}
                          name="chevron-right"
                          className="text-muted"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              )
            })}
          </ul>
          {next < data?.length && (
            <div className="my-5">
              <button
                className="btn btn-link d-block p-0"
                onClick={handleLoadMore}>
                + {t("Load more")}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ProjectRemindersCard
