import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import SubMenuItem from "./SubMenuItem"
export default function MenuItem({ id, name, currentPath, slug, categories }) {
  const { t } = useTranslation()
  const location = useLocation()
  const { pathname } = location

  let isCurrent = currentPath === slug

  if (pathname.includes(slug)) {
    isCurrent = true
  }

  return (
    <>
      <div
        className={`d-block machine-category-menu__item mb-3${
          isCurrent ? " text-primary parent" : ""
        }`}>
        <Link
          to={{
            pathname: `/${t("customer")}/${t("machines")}/${slug}`
          }}>
          {name}
        </Link>
      </div>
      {isCurrent && categories && categories.length > 0 && (
        <div className="machine-category-menu mb-3">
          {categories.map((category) => {
            let visible = false

            if (pathname.includes(category?.slug) && category?.parent === id) {
              visible = true
            } else if (category?.parent === id) {
              visible = true
            }

            if (!visible) return null

            return (
              <SubMenuItem
                key={category.id}
                {...category}
                parentSlug={slug}
                currentPath={currentPath}
                categories={categories}
              />
            )
          })}
        </div>
      )}
    </>
  )
}
