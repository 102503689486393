import React from "react"
import { useRecoilValue } from "recoil"
import { newOrderInformation } from "./Store"
import { useTranslation } from "react-i18next"

const NewOrderInformation = () => {
  const newOrderInformationState = useRecoilValue(newOrderInformation)
  const { t } = useTranslation()

  const projectName = newOrderInformationState?.project?.name
    ? `${newOrderInformationState?.project?.name} ${newOrderInformationState?.project?.number}`
    : `${newOrderInformationState?.project?.number}`

  return (
    <>
      {newOrderInformationState?.customer?.name !== "" && (
        <div>
          <span className="d-block text-uppercase font-xs mb-1">
            {t("Client")}
          </span>
          {newOrderInformationState?.customer?.name}
        </div>
      )}
      {newOrderInformationState?.customer?.number !== "" && (
        <div className="mt-3">
          <span className="d-block text-uppercase font-xs mb-1">
            {t("Client number")}
          </span>
          {newOrderInformationState?.customer?.number}
        </div>
      )}

      {projectName !== "" && (
        <div className="mt-3">
          <span className="d-block text-uppercase font-xs mb-1">
            {t("Project")}
          </span>
          {projectName}
        </div>
      )}
      <div className="mt-3">
        <span className="d-block text-uppercase font-xs mb-1">
          {t("Reference")}
        </span>
        {`${newOrderInformationState?.reference?.firstName} ${newOrderInformationState?.reference?.lastName}`}
      </div>
      {newOrderInformationState?.pickupBy?.name &&
        newOrderInformationState?.pickupBy?.name !== "" && (
          <div className="mt-3">
            <span className="d-block text-uppercase font-xs mb-1">
              {t("Pickup by")}
            </span>
            {newOrderInformationState.pickupBy?.name}
          </div>
        )}
    </>
  )
}

export default NewOrderInformation
