import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { Form, FormControl } from "react-bootstrap"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const SearchForm = ({
  handleSearchSubmit,
  inputText,
  handleInputChange,
  disabled,
  showModalSheet,
  modalViewState = "machineSearch"
}) => {
  const { t } = useTranslation()
  const inputRef = useRef(null)

  const inputStyle = { paddingRight: "50px" }
  return (
    <div className="row align-items-center">
      <div className="col">
        <Form
          autoComplete="off"
          noValidate={false}
          className="position-relative returns-search-form"
          onSubmit={(event) => {
            event.preventDefault()

            if (
              modalViewState === "reference" ||
              modalViewState === "project"
            ) {
              return
            }

            handleSearchSubmit(event)
          }}>
          <FormControl
            ref={inputRef}
            className={`form-control form-control-lg${
              disabled || showModalSheet ? " disabled" : ""
            }`}
            type="text"
            name="search"
            placeholder={t("Search")}
            disabled={disabled || showModalSheet}
            onChange={handleInputChange}
            value={inputText}
            required
            enterKeyHint="Search"
            autoCapitalize="none"
            autoComplete="off"
            autoFocus
            spellCheck={false}
            style={inputStyle}
            autoCorrect="off"
          />

          <button
            className={`bg-transparent text-primary position-absolute${
              disabled || showModalSheet ? " disabled" : ""
            }`}
            style={{
              top: "50%",
              right: 20,
              transform: "translateY(-50%)",
              width: 20,
              height: 20
            }}
            type="submit"
            disabled={disabled || showModalSheet}>
            <CustomSvgIcon
              name="search"
              width={20}
              height={20}
              className={`text-muted`}
            />
          </button>
        </Form>
      </div>
    </div>
  )
}

export default SearchForm
