import React from "react"
import ProjectCreate from "../../components/ProjectCreate/ProjectCreate"
import { usePermissionCheck } from "../../utils/hooks"

import PageNotFound from "../PageNotFound/PageNotFound"

const CreateNewProject = () => {
  const hasAdminSitePermission = usePermissionCheck(
    "MinaSidor.PlatserAdministrera"
  )

  if (!hasAdminSitePermission) {
    return <PageNotFound noPermission={true} />
  }

  return <ProjectCreate />
}

export default CreateNewProject
