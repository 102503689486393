import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import ItemMeta from "./ItemMeta"
import { useNavigate } from "react-router-dom"
import { Accordion, AccordionContext } from "react-bootstrap"
import CustomAccordionToggle from "../../Common/CustomAccordionToggle"
import { availabilityColorMap } from "../../../utils/helpers"
import { useRecoilValue } from "recoil"
import { newOrderRows, newOrderInformation, modalView } from "../Store"
import OrderRowInput from "../OrderRowInput"

import { checkMissingPrice } from "../helpers"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const Item = ({
  title,
  index,
  meta = [],
  context = "existing",
  handleClick,
  showModalSheet,
  item,
  item: {
    customerOrderId,
    id,
    availability,
    isLocked,
    isMisc,
    lock,
    orderReturnInfo,
    orderRowId
  }
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentEventKey = useContext(AccordionContext)
  let isAccordionActive = currentEventKey === orderRowId
  const usesAvaliability = item?.availabilityIsUsed
  const newOrderRowsState = useRecoilValue(newOrderRows)
  const newOrderRowIndex = newOrderRowsState.findIndex((row) => row.id === id)
  const newOrderInformationState = useRecoilValue(newOrderInformation)
  const [itemNewName, setItemNewName] = useState("")
  const modalViewState = useRecoilValue(modalView)

  // availability
  let isDisabled = false
  let itemClasses = []
  if (!usesAvaliability) {
    isDisabled = false
  } else {
    if (availability === 0) {
      isDisabled = true
      itemClasses.push("is-disabled not-available")
    }
  }

  // If locked
  if (isLocked && context !== "order") {
    // You should always have the option to reach an order even if its locked.
    isDisabled = true
    itemClasses.push("is-disabled is-locked")
  } else if (lock?.isLocked && context !== "order") {
    // You should always have the option to reach an order even if its locked.
    isDisabled = true
    itemClasses.push("is-disabled is-locked")
  }

  // If "modalSheet" is open
  if (showModalSheet) {
    isDisabled = true
  }

  // Kontantkund/DirectPayment on customer search
  if (item?.customerType === 1 && context === "customer") {
    isDisabled = true
  }

  // if customer is blocked
  if (item?.isBlocked && context === "customer") {
    isDisabled = true
  }

  // Diffrent titles and flows based on modalViewState
  let itemTitle = ""
  let newOrderHeadFlow = false
  if (modalViewState === "customer") {
    newOrderHeadFlow = true
    itemTitle = title
  } else if (modalViewState === "project") {
    newOrderHeadFlow = true
    itemTitle = `${item.number} ${
      item.number !== "" && item.name !== "" ? " | " : ""
    } ${item.name}`
  } else if (modalViewState === "reference") {
    newOrderHeadFlow = true
    itemTitle = `${item?.firstName} ${item?.lastName}`
  } else if (isMisc && itemNewName) {
    itemTitle = itemNewName
  } else {
    itemTitle = title
  }

  // check if reference endDate are passed
  if (modalViewState === "reference") {
    if (!item?.endDate) {
      isDisabled = false
    } else {
      const now = new Date()

      if (now > new Date(item?.endDate)) {
        isDisabled = true
      } else {
        isDisabled = false
      }
    }
  }

  const missingPrice = checkMissingPrice(item)
  const miscAmount = newOrderRowsState.filter((row) => row.id === id) ?? []

  return (
    <div
      className={`d-block w-100 bg-white border-bottom${
        index === 0 ? " border-top" : ""
      } ${itemClasses.join(" ")}${isDisabled ? " is-disabled" : ""}`}
      onClick={() => {
        if (
          isDisabled ||
          context === "accordion" ||
          context === "preview" ||
          context === "existing"
        ) {
          return
        } else if (context === "order") {
          navigate(`/order/${t("edit")}/${customerOrderId}`)
        } else {
          handleClick(item, context)
        }
      }}
      style={{ cursor: !isDisabled ? "pointer" : "not-allowed" }}>
      <div className="p-3">
        <div className="row d-flex align-items-center justify-content-between">
          <div className="col">
            <div className="row">
              <div className="col">
                <span className="d-block font-weight-bold text-left">
                  {itemTitle && itemTitle}
                  {missingPrice && (
                    <i className="fe fe-alert-triangle text-danger ml-2" />
                  )}
                  {context === "machine" && isMisc && miscAmount.length > 0 && (
                    <span className="ml-2 badge bg-light">
                      {miscAmount.length}
                    </span>
                  )}
                </span>
              </div>
              {context === "order" && item?.customerOrderNumber && (
                <div className="col-auto p-0">
                  <span className="d-block text-right">
                    {item?.customerOrderNumber}
                  </span>
                </div>
              )}
            </div>

            {item?.creditLimit?.hasCreditLimit &&
              item?.creditLimit.value <= 0 && (
                <small className="d-block text-left text-danger">
                  {t("The customer lacks sufficient credit limit")}
                </small>
              )}

            {context === "reference" && isDisabled && (
              <p className="text-left mb-0 text-danger">
                <small>{t("The user is no longer authorized to rent")}</small>
              </p>
            )}
          </div>

          <div className="col-auto">
            {context === "existing" && (
              <div className="text-primary">
                <CustomSvgIcon name="lock" width={24} height={24} />
              </div>
            )}
            {context === "order" && (
              <div className="text-primary" style={{ fontSize: 24 }}>
                <CustomSvgIcon width={24} height={24} name="chevron-right" />
              </div>
            )}
            {context === "machine" && (
              <div className="d-flex align-items-center">
                {usesAvaliability && (
                  <div className="text-primary" style={{ fontSize: 24 }}>
                    <span
                      className={`machine-item-availability d-block mr-2 flex-shrink-0 availability--${availabilityColorMap(
                        availability
                      )}`}></span>
                  </div>
                )}
                {isLocked || lock?.isLocked ? (
                  <div className="text-primary">
                    <CustomSvgIcon name="lock" width={24} height={24} />
                  </div>
                ) : (
                  <button
                    disabled={isDisabled}
                    className={`${
                      newOrderRowIndex > -1 || isMisc
                        ? "bg-primary"
                        : "bg-white"
                    } p-0 rounded-circle border border-primary`}
                    style={{
                      fontSize: 16,
                      width: 22,
                      height: 22,
                      lineHeight: 1.3
                    }}>
                    {isMisc && <span className="fe fe-plus text-white"></span>}
                    {!isMisc && newOrderRowIndex > -1 && (
                      <span className="fe fe-check text-white"></span>
                    )}
                  </button>
                )}
              </div>
            )}
            {newOrderHeadFlow &&
              item?.customerType !== 1 &&
              !item.isBlocked && (
                <button
                  disabled={isDisabled}
                  className={`${
                    newOrderInformationState?.[modalViewState]?.id === id
                      ? "bg-primary"
                      : "bg-white"
                  } p-0 rounded-circle border border-primary`}
                  style={{
                    fontSize: 16,
                    width: 22,
                    height: 22,
                    lineHeight: 1.3
                  }}>
                  {newOrderInformationState?.[modalViewState]?.id === id && (
                    <span className="fe fe-check text-white"></span>
                  )}
                </button>
              )}
            {item?.customerType === 1 && context === "customer" && (
              <div className="text-primary" style={{ fontSize: 24 }}>
                <span className="fe fe-dollar-sign"></span>
              </div>
            )}

            {item?.isBlocked && context === "customer" && (
              <div className="text-danger" style={{ fontSize: 24 }}>
                <span className="fe fe-slash"></span>
              </div>
            )}
            {context === "accordion" && (
              <CustomAccordionToggle
                eventKey={orderRowId}
                className="w-100 d-flex align-items-center">
                <div className="ml-auto">
                  {isAccordionActive ? (
                    <CustomSvgIcon name="chevron-up" width={24} height={24} />
                  ) : (
                    <CustomSvgIcon name="chevron-down" width={24} height={24} />
                  )}
                </div>
              </CustomAccordionToggle>
            )}
            {context === "preview" && (
              <span className="ml-2 badge bg-light">{item.quantity}</span>
            )}
          </div>
        </div>

        {meta.length > 0 && (
          <ItemMeta context={context} item={item} meta={meta} />
        )}

        {orderReturnInfo && (
          <div
            className="mt-3 mb-2 text-muted text-left"
            style={{ fontSize: 14, lineHeight: 1.3 }}>
            {orderReturnInfo}
          </div>
        )}
      </div>
      {context === "accordion" && (
        <Accordion.Collapse eventKey={orderRowId}>
          <OrderRowInput
            item={item}
            newOrderRowIndex={newOrderRowIndex}
            itemNewName={itemNewName}
            setItemNewName={setItemNewName}
          />
        </Accordion.Collapse>
      )}
    </div>
  )
}

export default Item
