import React from "react"

const Form = ({ template, handleForm, hasCustomerProfileEditPermission }) => {
  const { register, errors, formData } = handleForm
  const { fields } = template

  const renderFields = (fields) => {
    return fields.map((field) => {
      const {
        type,
        title,
        name,
        required,
        instructions,
        fieldClassName,
        disabled,
        readOnly,
        scope
      } = field

      let defaultValue =
        typeof formData[name] !== "undefined" ? formData[name] : ""

      if (scope === "postalAdress") {
        defaultValue =
          typeof formData[scope][name] !== "undefined"
            ? formData[scope][name]
            : ""
      }

      let className =
        typeof fieldClassName !== "undefined"
          ? fieldClassName
          : "col-12 col-md-6 mb-3"

      switch (type) {
        case "hidden":
          return (
            <input
              key={name}
              type="hidden"
              name={name}
              defaultValue={defaultValue}
              {...register(name)}
            />
          )

        case "offset":
          return <div className={className} key={name} />

        case "header":
          return (
            <div className="col-12 my-4 no-gutter" key={name}>
              <h2 className="card-header-title text-truncate">{title}</h2>
            </div>
          )

        case "text":
        case "email":
          return (
            <div className={className} key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {required && "*"}
              </label>
              <input
                type={type}
                id={name}
                name={name}
                disabled={disabled || !hasCustomerProfileEditPermission}
                readOnly={readOnly || !hasCustomerProfileEditPermission}
                defaultValue={defaultValue}
                className={`form-control form-control-modal rounded-0${
                  disabled || readOnly || !hasCustomerProfileEditPermission
                    ? " disabled"
                    : ""
                }`}
                {...register(name, { required })}
              />
              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}
              {instructions && (
                <span className="text-muted font-xs mt-2">{instructions}</span>
              )}
            </div>
          )

        default:
          return <div className="col-12">Invalid field</div>
      }
    })
  }

  return renderFields(fields)
}

export default Form
