import React from "react"
import Search from "../../components/Returns/Search/Search"
import { usePermissionCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"

const Returns = () => {
  const hasPermission = usePermissionCheck("MinaSidor.SkapaRetur")

  if (!hasPermission) {
    return <PageNotFound noPermission={true} />
  }

  return (
    <>
      <div className="container-fluid container-fluid-max-width">
        <Search />
      </div>
    </>
  )
}

export default Returns
