import React from "react"
import { ReactComponent as MenuIcon } from "../../assets/icons/navigation-alt.svg"
import { ReactComponent as CogIcon } from "../../assets/icons/cog.svg"
import { ReactComponent as DepoIcon } from "../../assets/icons/depo.svg"
import { ReactComponent as ChevronLeftIcon } from "../../assets/icons/chevron-left.svg"
import { ReactComponent as ChevronRightIcon } from "../../assets/icons/chevron-right.svg"
import { ReactComponent as ChevronDownIcon } from "../../assets/icons/chevron-down.svg"
import { ReactComponent as ChevronUpIcon } from "../../assets/icons/chevron-up.svg"
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg"
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg"
import { ReactComponent as FileIcon } from "../../assets/icons/task-list-to-do.svg"
import { ReactComponent as DotNavigationIcon } from "../../assets/icons/dot-navigation.svg"
import { ReactComponent as CalenderIcon } from "../../assets/icons/calender.svg"
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-right.svg"
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrow-left.svg"
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg"
import { ReactComponent as ArrowTopIcon } from "../../assets/icons/arrow-up.svg"
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg"
import { ReactComponent as RemoveIcon } from "../../assets/icons/remove.svg"
import { ReactComponent as CheckCircleSelectedFilledIcon } from "../../assets/icons/check-circle-selected-filled.svg"
import { ReactComponent as CheckCircleSelectedIcon } from "../../assets/icons/check-circle-selected.svg"
import { ReactComponent as CheckCircleIcon } from "../../assets/icons/check-circle.svg"
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg"
import { ReactComponent as SettingsSliderIcon } from "../../assets/icons/settings-slider.svg"
import { ReactComponent as AlarmBellIcon } from "../../assets/icons/alarm-bell.svg"
import { ReactComponent as CartIcon } from "../../assets/icons/cart.svg"
import { ReactComponent as BinIcon } from "../../assets/icons/bin.svg"

export default function CustomSvgIcon({
  name,
  width,
  height,
  className = "",
  style
}) {
  const icons = {
    menu: <MenuIcon />,
    cog: <CogIcon />,
    depo: <DepoIcon />,
    "chevron-left": <ChevronLeftIcon />,
    "chevron-right": <ChevronRightIcon />,
    "chevron-down": <ChevronDownIcon />,
    "chevron-up": <ChevronUpIcon />,
    search: <SearchIcon />,
    lock: <LockIcon />,
    file: <FileIcon />,
    "dot-navigation": <DotNavigationIcon />,
    calender: <CalenderIcon />,
    "arrow-right": <ArrowRightIcon />,
    "arrow-left": <ArrowLeftIcon />,
    "arrow-down": <ArrowDownIcon />,
    "arrow-up": <ArrowTopIcon />,
    add: <AddIcon />,
    remove: <RemoveIcon />,
    "check-circle-selected-filled": <CheckCircleSelectedFilledIcon />,
    "check-circle-selected": <CheckCircleSelectedIcon />,
    "check-circle": <CheckCircleIcon />,
    close: <CloseIcon />,
    "settings-slider": <SettingsSliderIcon />,
    "alarm-bell": <AlarmBellIcon />,
    cart: <CartIcon />,
    trash: <BinIcon />,
    bin: <BinIcon />
  }

  return (
    <span
      className={`d-inline-block svg-icon-wrapper ${className}`}
      style={{
        width: width,
        height: height,
        ...style
      }}>
      {icons[name]}
    </span>
  )
}
