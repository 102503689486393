import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Storage from "../../../utils/storage"
import SearchForm from "../Form/SearchForm"
import Loader from "../../Loader/Loader"
import Alert from "../../Common/Alert"
import SearchCustomer from "../Common/Queries/SearchCustomer"
import CustomerProjects from "../Common/Queries/CustomerProjects"
import CustomerRentalContacts from "../Common/Queries/CustomerRentalContacts"
import Item from "../Common/Item"
import ActionFooter from "../../Common/ActionFooter"
import { useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
import { newOrderInformation, modalView } from "../Store"
import ModalSheet from "../../Common/ModalSheet"
import IdRequiredForm from "../Form/IdRequiredForm"
import ModalCreateForm from "./ModalCreateForm"
import Toast from "../../Common/Toast"
import InsuranceOptionForm from "../Form/InsuranceOptionForm"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const ModalCreateOrdeer = ({
  show,
  handleHideModal,
  setShowModalCreateOrder
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userState = Storage.get("userState") ?? null
  const regionId = userState?.internal?.region?.regionId ?? 3
  const [initData, setInitData] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState("")
  const [isSearched, setIsSearched] = useState(false)
  const [newOrderInformationState, setNewOrderInformationState] =
    useRecoilState(newOrderInformation)
  const [modalViewState, setModalViewState] = useRecoilState(modalView)
  const [showModalSheet, setShowModalSheet] = useState(false)
  const [showInsuranceOption, setShowInsuranceOption] = useState(false)
  const [continueDisabled, setContinueDisabled] = useState(true)
  const [inputText, setInputText] = useState("")
  const [showModalCreateForm, setShowModalCreateForm] = useState(false)
  const onlyCustomerCanCreateRentalContact =
    newOrderInformationState?.customer?.onlyCustomerCanCreateRentalContact

  const requiresIdForRentalCustomer =
    newOrderInformationState?.customer?.requiresIdForRentalCustomer

  const handleBack = (modalViewState) => {
    setError(false)
    setData([])
    setModalViewState(modalViewState)
    setShowModalSheet(false)
    setShowInsuranceOption(false)

    if (modalViewState === "customer") {
      getCustomers(newOrderInformationState[modalViewState]?.searchText)
      setInputText(newOrderInformationState[modalViewState]?.searchText)
    }

    if (modalViewState === "project") {
      getCustomerProjects()
    }
  }

  const handleInputChange = (event) => {
    const value = event.target.value

    if (modalViewState !== "customer") {
      filterData(value)
    }

    setInputText(value)
  }

  // Filter data based on search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim()
    if (lowercasedValue === "") {
      setData(initData)
    } else {
      const filteredData = search(lowercasedValue)
      setData(filteredData)
    }
  }

  const search = (searchText) => {
    let arrayOfMatchedObjects = data.filter((object) => {
      return JSON.stringify(object)
        .toString()
        .toLowerCase()
        .includes(searchText)
    })
    return arrayOfMatchedObjects
  }

  const handleSearchSubmit = async (event) => {
    const inputValue = event.target?.elements?.search?.value
    setIsSearched(false)
    setLoading(true)

    if (modalViewState === "project") {
      filterData(inputValue)
    } else {
      getCustomers(inputValue)
    }
  }

  const getCustomers = async (inputValue) => {
    setIsSearched(false)
    setLoading(true)

    try {
      const res = await SearchCustomer(inputValue, regionId)
      setIsSearched(true)
      setData(res)
      setInitData(res)
    } catch (err) {
      //console.log(err)
      setError(
        `${t("Something went wrong")}: ${err?.response?.data?.CorrelationId}`
      )
    } finally {
      setLoading(false)
    }
  }

  const getCustomerProjects = async () => {
    setIsSearched(false)
    setLoading(true)

    const customerID = newOrderInformationState?.customer?.id

    try {
      const res = await CustomerProjects(customerID, setError)
      setIsSearched(true)
      setData(res)
      setInitData(res)
    } catch (err) {
      //console.log(err)
      setError(
        `${t("Something went wrong")}: ${err?.response?.data?.CorrelationId}`
      )
    } finally {
      setLoading(false)
    }
  }

  const getCustomerRentalContacts = async () => {
    setIsSearched(false)
    setLoading(true)

    const customerID = newOrderInformationState?.customer?.id

    try {
      const res = await CustomerRentalContacts(customerID, setError)
      setIsSearched(true)
      setData(res)
      setInitData(res)
    } catch (err) {
      //console.log(err)
      setError(
        `${t("Something went wrong")}: ${err?.response?.data?.CorrelationId}`
      )
    } finally {
      setLoading(false)
    }
  }

  const handleModalNavigation = (context) => {
    let view = ""

    if (context === "project") {
      getCustomerProjects()
    } else if (context === "reference") {
      getCustomerRentalContacts()
      const insuranceOption = newOrderInformationState?.project?.insuranceOption

      if (insuranceOption === 0) {
        setShowInsuranceOption(true)
        setShowModalSheet(false)
      }
    } else {
      setModalViewState("machineSearch")
      navigate(`/order/${t("create")}`)
    }

    view = context
    setModalViewState(view)
    setData([])
    setInputText("")
    setIsSearched(false)
  }

  const handleClick = (item, context) => {
    setNewOrderInformationState((oldOrderInformationState) => {
      return {
        ...oldOrderInformationState,
        [context]: { ...item, searchText: inputText }
      }
    })

    if (context === "reference") {
      if (requiresIdForRentalCustomer) {
        setShowModalSheet(true)
        setShowInsuranceOption(false)
      }
    }
  }

  const onModalHide = () => {
    handleHideModal()
    setData([])
    setError(false)
    setInputText("")
    setIsSearched(false)
    setShowModalSheet(false)
    setShowInsuranceOption(false)
    setNewOrderInformationState({
      customer: null,
      project: null,
      reference: null
    })
  }

  const handleContinue = () => {
    if (modalViewState === "customer") {
      handleModalNavigation("project")
    } else if (modalViewState === "reference") {
      setModalViewState("machineSearch")
      navigate(`/order/${t("create")}`)
    } else {
      handleModalNavigation("reference")
    }
  }

  useEffect(() => {
    if (newOrderInformationState?.customer && modalViewState === "customer") {
      setContinueDisabled(false)
    } else if (
      newOrderInformationState?.project &&
      modalViewState === "project"
    ) {
      setContinueDisabled(false)
    } else if (
      newOrderInformationState?.reference &&
      modalViewState === "reference"
    ) {
      if (requiresIdForRentalCustomer) {
        setContinueDisabled(true)
        setShowModalSheet(true)
        setShowInsuranceOption(false)
      } else {
        setContinueDisabled(false)
      }
    } else if (error) {
      setContinueDisabled(false)
    } else {
      setContinueDisabled(true)
    }
  }, [
    modalViewState,
    newOrderInformationState,
    error,
    loading,
    requiresIdForRentalCustomer
  ])

  // check if customer is requiresIdForRentalCustomer
  useEffect(() => {
    if (newOrderInformationState?.customer) {
      setNewOrderInformationState((oldOrderInformationState) => {
        return {
          ...oldOrderInformationState,
          bypassSocialSecurityNumber: newOrderInformationState?.customer
            ?.requiresIdForRentalCustomer
            ? false
            : true
        }
      })
    }
  }, [newOrderInformationState?.customer, setNewOrderInformationState])

  return (
    <>
      <Toast />
      <ModalCreateForm
        show={showModalCreateForm}
        setShowModalCreateForm={setShowModalCreateForm}
        handleModalNavigation={handleModalNavigation}
      />
      <Modal
        show={show}
        fullscreen="true"
        onHide={() => {
          handleHideModal()
          setData([])
        }}
        backdrop="static"
        animation={false}
        className={`modal-scrollable${
          showModalSheet || showInsuranceOption ? " block-scroll" : ""
        }`}>
        <Modal.Header className="d-block p-3">
          <div className="position-relative">
            {modalViewState !== "customer" && (
              <button
                disabled={loading}
                onClick={() =>
                  handleBack(
                    modalViewState === "project" ? "customer" : "project"
                  )
                }
                className="bg-transparent position-absolute p-0 text-body"
                style={{ top: "50%", left: 0, transform: "translateY(-50%)" }}>
                <CustomSvgIcon
                  width={16}
                  height={16}
                  name="chevron-left"
                  className="text-white"
                />
              </button>
            )}
            <h3 className="header-title mt-3 mb-0 text-center">
              {modalViewState === "customer" && t("Search and select customer")}
              {modalViewState === "project" && t("Choose project")}
              {modalViewState === "reference" && (
                <>
                  <span className="d-block">{t("Choose reference")}</span>
                  {onlyCustomerCanCreateRentalContact && (
                    <small className="d-block text-danger mt-2">
                      {t(
                        "Customer has blocked the ability to add new contacts"
                      )}
                    </small>
                  )}
                </>
              )}
            </h3>
            <button
              onClick={onModalHide}
              className="bg-transparent position-absolute p-0 text-body"
              style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
              <CustomSvgIcon
                name="close"
                width={16}
                height={16}
                className="text-black"
              />
            </button>
          </div>
          <div className="row align-items-center g-0 mt-4">
            <div className="col">
              <SearchForm
                handleSearchSubmit={handleSearchSubmit}
                inputText={inputText}
                disabled={
                  !data ||
                  error ||
                  loading ||
                  showModalSheet ||
                  showInsuranceOption
                }
                handleInputChange={handleInputChange}
                showModalSheet={showModalSheet}
                modalViewState={modalViewState}
              />
            </div>
            {modalViewState !== "customer" && (
              <div className="col-auto ml-2">
                <button
                  disabled={
                    loading ||
                    showModalSheet ||
                    showInsuranceOption ||
                    (modalViewState !== "project" &&
                      onlyCustomerCanCreateRentalContact)
                      ? true
                      : false
                  }
                  onClick={() => {
                    setModalViewState(
                      modalViewState === "project"
                        ? "createProject"
                        : "createReference"
                    )
                    setShowModalCreateForm(true)
                  }}
                  className={`border rounded text-primary bg-white p-0${
                    loading ||
                    showModalSheet ||
                    showInsuranceOption ||
                    (modalViewState !== "project" &&
                      onlyCustomerCanCreateRentalContact)
                      ? " btn-disabled"
                      : ""
                  }`}
                  style={{ fontSize: 20, width: 50, height: 50 }}>
                  <CustomSvgIcon width={16} height={16} name="add" />
                </button>
              </div>
            )}
          </div>
        </Modal.Header>
        <Modal.Body className="text-center p-0 bg-white is-scrollable mx-0 pb-5">
          {loading && !error && <Loader className="my-4" />}
          {!loading && error && (
            <Alert
              className="mt-3"
              message={t("Something went wrong, please refresh current page.")}
            />
          )}

          {!loading && isSearched && data.length === 0 && (
            <Alert className="mt-3" message={t("Can't find anything")} />
          )}

          {!loading &&
            !error &&
            data.length > 0 &&
            data.map((item, index) => {
              let meta = ""
              let title = item.name
              if (modalViewState === "reference") {
                meta = [item?.dateOfBirth, item?.phone1]
              } else if (modalViewState === "project") {
                meta = [item.address.adressLine1, item.address.city]
              } else {
                meta = [item.number, item.accountName]
              }

              return (
                <Item
                  key={index}
                  context={modalViewState}
                  index={index}
                  title={title}
                  meta={meta}
                  item={item}
                  handleClick={handleClick}
                  showModalSheet={showModalSheet || showInsuranceOption}
                />
              )
            })}
        </Modal.Body>

        <ActionFooter>
          <button
            className={`btn btn-outline-secondary w-100 d-block mr-3${
              loading ? " btn-loading" : ""
            }`}
            disabled={loading}
            onClick={onModalHide}>
            {t("Close")}
          </button>
          <button
            className={`btn btn-primary w-100 d-block${
              loading ? " btn-loading" : ""
            }`}
            disabled={loading || continueDisabled}
            onClick={() => handleContinue()}>
            {t("Continue")}
          </button>
        </ActionFooter>
        <ModalSheet show={showModalSheet}>
          {modalViewState === "reference" && (
            <IdRequiredForm
              setShowModalSheet={setShowModalSheet}
              setContinueDisabled={setContinueDisabled}
            />
          )}
        </ModalSheet>
        <ModalSheet show={showInsuranceOption}>
          {modalViewState === "reference" && (
            <InsuranceOptionForm
              setShowInsuranceOption={setShowInsuranceOption}
            />
          )}
        </ModalSheet>
      </Modal>
    </>
  )
}

export default ModalCreateOrdeer
