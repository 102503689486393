import { atom } from "recoil"

export const machineState = atom({
  key: "machineState",
  default: []
})

export const incomingMachineState = atom({
  key: "incomingMachineState",
  default: []
})

export const depotState = atom({
  key: "depotState",
  default: []
})

export const viewState = atom({
  key: "viewState",
  default: "list" // "list", "confirmation", "summary"
})

export const pageTypeState = atom({
  key: "pageTypeState",
  default: "transfer" // "transfer", "incoming", "status"
})

export const errorState = atom({
  key: "errorState",
  default: ""
})
