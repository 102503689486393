import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import PageHeader from "../Common/PageHeader"
import { useOidcUser } from "@axa-fr/react-oidc"
import { useTranslation } from "react-i18next"
import { tableHeader } from "./helpers"
import User from "./User"
import ModalForm from "./Modal"
import ModalConfirm from "./ModalConfirm"
import { inviteFormTemplate, updateFormTemplate } from "./FormTemplate"
import TableSkeletonLoader from "../Common/TableSkeletonLoader"
import Storage from "../../utils/storage"
import { useMediaQuery } from "react-responsive"
import Toast from "../Common/Toast"
import { useOnClickOutside } from "../../utils/hooks"
import ModalSheet from "../Common/ModalSheet"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const Users = () => {
  const outsideClickHandlerRef = useRef()
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()

  const [showModalForm, setShowModalForm] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [initUsers, setInitUsers] = useState([])
  const [users, setUsers] = useState([])
  const [contacts, setContacts] = useState([])
  const [updatedUser, setUpdatedUser] = useState(null)
  const [updateAction, setUpdateAction] = useState("")
  const [reFetch, setReFetch] = useState(false)
  const [reFetchSettings, setReFetchSettings] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [showModalSheet, setShowModalSheet] = useState(false)
  const [modalSheetData, setModalSheetData] = useState(null)

  const userGroups = Storage.get("userGroups") ?? null

  const customer = Storage.get("customer", null, "local") ?? null

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(outsideClickHandlerRef, () => {
    setShowModalSheet(false)
    setModalSheetData(null)
  })

  const defaultModalFormState = {
    title: "",
    formTemplate: null,
    submitText: t("Save"),
    formData: null,
    action: ""
  }
  const [modalFormState, setModalFormState] = useState(defaultModalFormState)

  const defaultModalConfirmState = {
    data: null,
    modalTitle: "",
    heading: "",
    text: "",
    action: ""
  }
  const [modalConfirmState, setModalConfirmState] = useState(
    defaultModalConfirmState
  )

  // Handle form modal hide
  const handleModalFormHide = (responseData = null, reFresh = false) => {
    setShowModalForm(false)
    setModalFormState(defaultModalFormState)

    setReFetch(!reFetch)
    setShowModalSheet(false)
  }

  // Search on change
  const handleSearchChange = (e) => {
    e.preventDefault()
    const value = e.target.value
    setSearchText(value)
    filterData(value)
    setShowModalSheet(false)
  }

  // Filter data based on search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim()
    if (lowercasedValue === "") {
      setUsers(initUsers)
    } else {
      const filteredData = search(lowercasedValue)
      setUsers(filteredData)
    }
    setShowModalSheet(false)
  }

  const search = (searchText) => {
    let arrayOfMatchedObjects = users.filter((object) => {
      return JSON.stringify(object)
        .toString()
        .toLowerCase()
        .includes(searchText)
    })
    setShowModalSheet(false)
    return arrayOfMatchedObjects
  }

  // Edit
  const handleEdit = (data) => {
    setShowModalForm(true)
    setModalFormState({
      title: t("Edit contact"),
      formTemplate: updateFormTemplate,
      submitText: t("Save"),
      formData: data,
      action: "edit"
    })
    setShowModalSheet(false)
  }

  // Delete
  const handleDelete = (data) => {
    setShowModalConfirm(true)
    setModalConfirmState({
      data,
      modalTitle: t("Delete user"),
      heading: `${t("Do you really want to delete the user")} ${
        data.firstName
      } ${data.lastName}?`,
      text: t("This action can't be undone."),
      action: "delete"
    })
    setShowModalSheet(false)
  }

  // Invite
  const handleInvite = (data) => {
    setShowModalForm(true)
    setModalFormState({
      title: t("Invite as user of My Pages"),
      formTemplate: inviteFormTemplate,
      submitText: t("Invite"),
      formData: {
        customerId: oidcUser?.tid
      },
      action: "invite"
    })
    setShowModalSheet(false)
  }

  // Handle confirm modal hide
  const handleModalConfirmHide = (responseData, action = "") => {
    setShowModalConfirm(false)
    setModalConfirmState(defaultModalConfirmState)

    if (responseData) {
      if (action === "delete") {
        setUpdatedUser(responseData)
        setUpdateAction(action)
      } else {
        setReFetchSettings(!reFetchSettings)
      }
    }
  }

  useEffect(() => {
    let unmounted = false

    const request = async () => {
      const usersRequest = axios.post("/api/hll/customerUserAccounts", {
        tenantId: oidcUser?.tid
      })

      const contactsRequest = axios.post("/api/hll/customerRentalContacts", {
        customerId: customer?.id
      })

      setLoading(true)
      setReFetch(false)

      await axios
        .all([usersRequest, contactsRequest])
        .then(
          axios.spread((...responses) => {
            if (!unmounted) {
              setInitUsers(responses[0].data ?? [])
              setUsers(responses[0].data ?? [])
              setContacts(responses[1].data ?? [])
            }
          })
        )
        .catch((error) => {
          console.error(`Error code: ${error.response.data.CorrelationId}`)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (reFetch) {
      setUpdatedUser(null)
    }

    if (reFetch || oidcUser?.tid) {
      request()
    }

    return () => {
      unmounted = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser?.tid, reFetch])

  useEffect(() => {
    if (showModalSheet) {
      document.body.classList.add("disable-scroll")
    } else {
      document.body.classList.remove("disable-scroll")
    }
  }, [showModalSheet])

  return (
    <>
      <ModalConfirm
        show={showModalConfirm}
        onHandleHide={handleModalConfirmHide}
        {...modalConfirmState}
      />
      <ModalForm
        show={showModalForm}
        onHandleHide={handleModalFormHide}
        userGroups={userGroups}
        contacts={contacts}
        {...modalFormState}
      />
      <Toast />
      <div className="container-fluid container-fluid-max-width">
        <div className="data-list with-filters">
          <PageHeader
            preTitle={t("My pages")}
            title={t("Users")}
            button={{
              text: t("Invite user")
            }}
            onClickHandler={handleInvite}
          />
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card shadow-none">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="input-group input-group-flush input-group-merge input-group-reverse">
                      <input
                        className="form-control list-search"
                        type="search"
                        placeholder={t("Search")}
                        value={searchText}
                        onChange={handleSearchChange}
                        required
                      />
                      <span className="input-group-text">
                        <CustomSvgIcon
                          name="search"
                          width={20}
                          height={20}
                          className={`text-muted`}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="position-relative"
                style={{ transform: "translate3d(0,0,0)", zIndex: 2 }}>
                <div
                  className="table-responsive"
                  style={{ overflowY: "visible" }}>
                  <table className="table table-sm table-hover table-nowrap card-table">
                    <thead>
                      <tr>
                        {useMediaQuery({ query: "(max-width: 990px)" }) && (
                          <th></th>
                        )}
                        {tableHeader.map((header) => {
                          return (
                            <th
                              key={header.name}
                              className={`text-${header.alignment}`}>
                              {header.title !== "" ? header.title : ""}
                            </th>
                          )
                        })}
                      </tr>
                    </thead>
                    {loading ? (
                      <tbody>
                        <TableSkeletonLoader row={10} cells={4} />
                      </tbody>
                    ) : (
                      <>
                        <tbody className="list font-size-base">
                          {users &&
                            users.map((user) => {
                              return (
                                <User
                                  key={user.id}
                                  data={user}
                                  onHandleEdit={handleEdit}
                                  updateAction={updateAction}
                                  updatedUser={updatedUser}
                                  onHandleDelete={handleDelete}
                                  setShowModalSheet={setShowModalSheet}
                                  setModalSheetData={setModalSheetData}
                                />
                              )
                            })}
                        </tbody>
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={outsideClickHandlerRef}>
        {useMediaQuery({ query: "(max-width: 990px)" }) && showModalSheet && (
          <>
            <div
              className="modal-backdrop show"
              onClick={() => setShowModalSheet(false)}></div>
            <ModalSheet show={showModalSheet}>
              <div className="py-5 text-center mx-auto">
                <h3 className="mb-1">{`${modalSheetData.firstName} ${modalSheetData?.lastName}`}</h3>
                <p>
                  <small>{modalSheetData?.email}</small>
                </p>
                <button
                  className="d-block bg-transparent text-center mx-auto text-primary"
                  onClick={() => handleEdit(modalSheetData)}>
                  {t("Edit")}
                </button>
                <button
                  className="d-block bg-transparent text-center mx-auto text-primary"
                  onClick={() => handleDelete(modalSheetData)}>
                  {t("Delete contact")}
                </button>
              </div>
            </ModalSheet>
          </>
        )}
      </div>
    </>
  )
}

export default Users
