import React, { useEffect, useState } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { usePermissionCheck } from "../../utils/hooks"
import Loader from "../../components/Loader/Loader"
import Alert from "../../components/Common/Alert"
import PageNotFound from "../PageNotFound/PageNotFound"
import MachineItem from "../../components/Machines/MachineItem/MachineItem"

const Machine = (props) => {
  const hasPermission = usePermissionCheck("MinaSidor.SkapaRetur")
  const { t } = useTranslation()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorCode, setErrorCode] = useState("")
  const [data, setData] = useState(null)

  useEffect(() => {
    let unmounted = false
    setLoading(true)

    const requestMachine = async () => {
      await axios
        .post("/api/hll/fleetItem", {
          id
        })
        .then((response) => {
          if (!unmounted) {
            const data = response.data ?? null
            setData(data)
          }
        })
        .catch((err) => {
          //console.warn(err, "Error: api/hll/customerOrders")
          setErrorCode(err?.response?.data?.CorrelationId)
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    requestMachine()

    return () => {
      unmounted = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (!hasPermission) {
    return <PageNotFound noPermission={true} />
  }

  if (loading) {
    return <Loader className="mt-5 mx-auto text-center" />
  }

  if (!data) {
    if (error) {
      return (
        <Alert
          className="text-center p-5"
          message={`${t(
            "Something went wrong, please refresh current page."
          )}${errorCode}`}
        />
      )
    } else {
      return null
    }
  }

  return <MachineItem id={id} {...data} />
}

export default Machine
