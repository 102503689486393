import React, {
  createContext,
  useCallback,
  useContext,
  useReducer
} from "react"

const SET_MACHINE_SEARCH = "SET_MACHINE_SEARCH"
const SET_MACHINE_ITEM = "SET_MACHINE_ITEM"

const MachineContext = createContext()

const defaultState = {
  searchData: {
    searchText: "",
    offset: 0,
    currentPage: 0,
    data: [],
    pageInfo: { total: 0 }
  },
  machineItem: {
    id: "",
    fleetModelId: "",
    name: "",
    number: "",
    branchId: "",
    status: 0,
    availability: 0,
    orderReturnInfo: "",
    inspectionNote: {
      createdAt: "",
      createdBy: "",
      inspectionInfo: ""
    },
    inspection: [
      {
        inspectionType: 1,
        description: "",
        interval: 0,
        intervalType: 1,
        date: ""
      }
    ]
  }
}

export const MachineProvider = ({ children }) => {
  const memoizedReducer = useCallback((state, action) => {
    switch (action.type) {
      case SET_MACHINE_SEARCH: {
        const currentState = state

        currentState.searchData = Object.assign(state.searchData, action.data)

        return Object.assign({}, currentState)
      }

      case SET_MACHINE_ITEM: {
        const currentState = state

        currentState.machineItem = Object.assign(state.machineItem, action.data)

        return Object.assign({}, currentState)
      }

      default:
        return state
    }
  }, [])

  const [state, dispatch] = useReducer(memoizedReducer, defaultState)

  const setSearchData = (data) => dispatch({ type: SET_MACHINE_SEARCH, data })
  const setMachineItem = (data) => dispatch({ type: SET_MACHINE_ITEM, data })

  return (
    <MachineContext.Provider
      value={{
        searchData: state.searchData,
        machineItem: state.machineItem,
        setSearchData,
        setMachineItem
      }}>
      {children}
    </MachineContext.Provider>
  )
}

const useMachineContext = () => useContext(MachineContext)

export function useSearchData() {
  const { searchData, setSearchData } = useMachineContext()
  return [searchData, setSearchData]
}

export function useMachineItem() {
  const { machineItem, setMachineItem } = useMachineContext()
  return [machineItem, setMachineItem]
}

export default useMachineContext
