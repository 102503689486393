import { useTranslation } from "react-i18next"
import { useMachineCategories } from "../../Machines/Common/hooks"
import Card from "./Card"

export default function CategoryGrid() {
  const { t } = useTranslation()
  const { categories, isLoading } = useMachineCategories()

  return (
    <>
      {categories && categories?.length > 0 && (
        <section className="test mt-4">
          <h2 className="h4">{t("Our machines")}</h2>
          <ul className="category-grid mt-4 mb-6">
            {categories.map((category) => {
              const isVisible = category?.parent === 0

              if (!isVisible) return null

              return (
                <Card key={category.id} isLoading={isLoading} {...category} />
              )
            })}
          </ul>
        </section>
      )}
    </>
  )
}
