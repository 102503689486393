import React from "react"
import { useTranslation } from "react-i18next"
import Alert from "react-bootstrap/Alert"
import uuid from "react-uuid"
import Loader from "../Loader/Loader"
import Storage from "../../utils/storage"

import InventoryListItem from "./Common/Inventory/List"

export default function NewInventories({ loading, data, inventoryReady }) {
  const { t } = useTranslation()
  const userState = Storage.get("userState") ?? null

  return (
    <>
      <div className="row">
        <div className="col-12">
          {userState?.internal?.depot?.name && (
            <h1 className="h2">{`${t("Being inventoried right now")} ${t(
              "in"
            )} ${userState?.internal?.depot?.name}`}</h1>
          )}
        </div>

        {loading && <Loader className="mt-3" />}

        {data && data.length === 0 && !loading && inventoryReady && (
          <div className="col-12">
            <Alert variant="warning" className="text-primary">{`${t(
              "Right now there are no ongoing inventories"
            )}.`}</Alert>
          </div>
        )}
      </div>
      {!loading && data && data.length > 0 && inventoryReady && (
        <div className="row">
          {data.map((inventory) => {
            return <InventoryListItem key={uuid()} {...inventory} />
          })}
        </div>
      )}
    </>
  )
}
