import React, { useEffect, useState } from "react"
import axios from "axios"
import { Carousel, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import SearchForm from "../../Common/SearchForm"
import NewRentalItem from "./NewRentalItem"
import AccessoryItem from "./AccessoryItem"
import Loader from "../../Loader/Loader"
import Alert from "../../Common/Alert"
import Storage from "../../../utils/storage"
import moment from "moment"
import uuid from "react-uuid"
import MachineInspection from "../../Machines/MachineItem/Steps/MachineInspections"

import { itemStatus, getInspectionDiff } from "../../../utils/helpers"

import ModalSheet from "../../Common/ModalSheet"
import ControlValidation from "../../Common/ControlValidation"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const NewRentalModal = ({ open, onHideHandler, onSaveHandler }) => {
  const { t } = useTranslation()
  const [slideIndex, setSlideIndex] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [data, setData] = useState([])
  const [showModalSheet, setShowModalSheet] = useState(false)
  const [controlValidation, setControlValidation] = useState(false)
  const [controlValidationItem, setControlValidationItem] = useState(null)

  const [inspectionData, setInspectionData] = useState(null)
  const [inspectionModalOpen, setInspectionModalOpen] = useState(false)
  const [accessoryData, setAccessoryData] = useState([])
  const [loading, setLoading] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [itemDisabled, setItemDisabled] = useState(false)
  const [fleetModelsLoading, setFleetModelsLoading] = useState(false)
  const [selectedRental, setSelectedRental] = useState("")
  const [checkedItems, setCheckedItems] = useState([])
  const userState = Storage.get("userState") ?? null
  const regionId = userState?.internal?.region?.regionId ?? 3
  const internalCompanyId = userState?.internal?.region?.internalCompanyId ?? 3

  const handleSearch = (text) => {
    setSearchText(text)
    setCheckedItems([])
  }

  const handleSearchBlur = (value) => {
    setSearchText(value)
    setCheckedItems([])
  }

  const handleChecked = (checked, payLoad, pr) => {
    const temp = checkedItems
    const findIndex = checkedItems.findIndex((item) => item.id === payLoad.id)
    const { fleetModelId, offers, id, internalType } = JSON.parse(payLoad.data)

    const request = async () => {
      setFleetModelsLoading(true)

      if (internalType === "INDIVIDUAL") {
        let fleetItem = null

        fleetItem = await axios.post("/api/hll/fleetItem", {
          id
        })

        if (fleetItem && fleetItem?.status === 200) {
          let hasUpcomingInspections = false

          for (
            let index = 0;
            index < fleetItem.data.inspection.length;
            index++
          ) {
            const inspectionDiff = getInspectionDiff(
              fleetItem.data.inspection[index]
            )

            if (inspectionDiff?.days >= -30) {
              hasUpcomingInspections = true
            }
          }

          if (hasUpcomingInspections) {
            setInspectionData(fleetItem.data)
            setInspectionModalOpen(true)
          }
        }
      }

      await axios
        .post("/api/hll/fleetModels", {
          fleetModelId,
          regionId
        })
        .then((response) => {
          if (checked && findIndex === -1 && response?.data) {
            const responseOffers = response?.data?.item?.offers ?? []
            const defaultOfferObj =
              responseOffers.length > 0 &&
              responseOffers.filter((offer) => offer.isDefault === true)

            //Checking orderRowType
            let orderRowType = defaultOfferObj ? defaultOfferObj[0].type : 0
            if (orderRowType === 0 || orderRowType === 6) {
              orderRowType = 1
            }

            if (orderRowType === 5) {
              orderRowType = 0
            }

            temp.push({
              ...payLoad,
              ...response.data,
              ...{
                price:
                  defaultOfferObj.length > 0 ? defaultOfferObj[0].price : 0,
                orderRowType: orderRowType,
                agreedPrice: {
                  amount: 0,
                  type: "kr"
                },
                debitType:
                  defaultOfferObj.length > 0
                    ? defaultOfferObj[0].debitType
                    : null,
                offerType: defaultOfferObj.length > 0 ? defaultOfferObj : null,
                newName: ""
              }
            })
          } else {
            temp.splice(findIndex, 1)
          }
          setCheckedItems([...temp])
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => setFleetModelsLoading(false))
    }

    if (typeof offers === "undefined" && checked) {
      request()
    } else {
      if (checked && findIndex === -1) {
        temp.push(payLoad)
      } else {
        temp.splice(findIndex, 1)
      }

      setCheckedItems([...temp])
    }
  }

  const handleUpdateStore = async (item) => {
    const { id, name } = item

    setItemDisabled(true)

    await axios
      .post("/api/hll/accessoryQuery", {
        body: {
          articleId: {
            logicalOperator: "Or",
            predicate: "Equal",
            value: id
          },
          fleetItemId: {
            logicalOperator: "Or",
            predicate: "Equal",
            value: id
          },
          queryContext: {
            logicalOperator: "And",
            predicate: "Equal",
            value: "Order"
          }
        },
        regionId: regionId,
        internalCompanyId: internalCompanyId
      })
      .then((response) => {
        if (response?.data && response?.data.length > 0) {
          setAccessoryData(response.data)
          setSelectedRental(name)
          setSlideIndex(1)

          const temp = checkedItems

          response.data.forEach((data) => {
            if (!data.isPreSelected) {
              return
            }

            const offers = data.item?.offers ?? []
            const defaultOfferObj =
              offers.length !== 0 &&
              offers.filter((offer) => offer.isDefault === true)

            //Checking orderRowType
            let orderRowType = defaultOfferObj ? defaultOfferObj[0].type : 0
            if (orderRowType === 0 || orderRowType === 6) {
              orderRowType = 1
            }

            if (orderRowType === 5) {
              orderRowType = 0
            }

            const findIndex = checkedItems.findIndex(
              (item) => item.id === data.item.id
            )

            if (findIndex === -1) {
              temp.push({
                internalId: uuid(),
                id: data.item.id,
                articleId: data.item.articleId,
                name: data.item.name,
                item: data.item,
                quantity: data.quantity,
                description: "",
                startTime: moment().format("YYYY-MM-DD[T]hh:mm:ss.SSS[Z]"),
                isNew: true,
                orderRowType: orderRowType,
                price: defaultOfferObj ? defaultOfferObj[0].price : 0,
                agreedPrice: {
                  amount: 0,
                  type: "kr"
                },
                internalType: "ARTICLE",
                debitType: defaultOfferObj
                  ? defaultOfferObj[0].debitType
                  : null,
                isMisc:
                  data?.item?.isMisc && typeof data.item.isMisc !== "undefined"
                    ? data?.item?.isMisc
                    : false,
                offerType: defaultOfferObj ? defaultOfferObj : [],
                newName: ""
              })
            }
          })

          setCheckedItems([...temp])
        }
      })
      .catch((err) => {
        alert(t("Something went wrong, try again."))
        console.error("CorrelationId:", err?.response?.data?.CorrelationId)
      })
      .finally(() => {
        setItemDisabled(false)
      })
  }

  const handleRentalItemClick = async (item) => {
    const { type, availabilityIsUsed, availability } = item

    if (type === "article") {
      return
    }

    if (availabilityIsUsed && availability !== 1) {
      setControlValidation(true)
      setShowModalSheet(true)
      setControlValidationItem(item)
    } else {
      handleUpdateStore(item)
    }
  }

  const handleOnHide = () => {
    onHideHandler()
    setData([])
    setAccessoryData([])
    setSearchText("")
    setNotFound(false)
    setSlideIndex(0)
    setCheckedItems([])
    setInspectionData(null)
  }

  const handleInspectionModalHide = (clear = false) => {
    setInspectionData(null)
    setInspectionModalOpen(false)

    if (clear) {
      const findIndex = checkedItems.findIndex(
        (item) => item.id === inspectionData.id
      )
      const temp = checkedItems

      temp.splice(findIndex, 1)

      setCheckedItems([...temp])
    }
  }

  const handleControlValidationCancel = (id) => {
    const temp = checkedItems
    const findIndex = checkedItems.findIndex((item) => item.id === id)

    temp.splice(findIndex, 1)

    setCheckedItems([...temp])
  }

  useEffect(() => {
    let unmounted = false

    const requestOrders = async () => {
      const fleetItemQuery = axios.post("/api/hll/fleetItemQuery", {
        limit: 100,
        offset: 0,
        body: {
          fleetModelId: {
            logicalOperator: "Or",
            predicate: "StartsWith",
            value: searchText
          },
          number: {
            logicalOperator: "Or",
            predicate: "StartsWith",
            value: searchText
          },
          name: {
            logicalOperator: "Or",
            predicate: "Contains",
            value: searchText
          },
          regionId: {
            logicalOperator: "And",
            predicate: "Equal",
            value: regionId
          },
          // Check with Xlent
          status: {
            logicalOperator: "And",
            predicate: "Equal",
            value: itemStatus("Branch")
          }
        }
      })

      const articleQuery = axios.post("/api/hll/articleQuery", {
        body: {
          articleId: {
            logicalOperator: "Or",
            predicate: "StartsWith",
            value: searchText
          },
          articleNumber: {
            logicalOperator: "Or",
            predicate: "Contains",
            value: searchText
          },
          articleName: {
            logicalOperator: "Or",
            predicate: "Contains",
            value: searchText
          },
          regionId: {
            logicalOperator: "And",
            predicate: "Equal",
            value: regionId
          }
        }
      })

      await axios
        .all([fleetItemQuery, articleQuery])
        .then(
          axios.spread((...responses) => {
            if (!unmounted) {
              const fleetItems = responses[0].data.data ?? []
              const articles = responses[1].data.data ?? []

              if (fleetItems.length === 0 && articles.length === 0) {
                setNotFound(true)
              } else {
                const mapFleetItems = fleetItems.map((item) => {
                  return {
                    ...item,
                    internalType: "INDIVIDUAL"
                  }
                })

                const mapArticles = articles.map((item) => {
                  return {
                    ...item,
                    internalType: "ARTICLE"
                  }
                })

                setData(mapFleetItems.concat(mapArticles))
              }
            }
          })
        )
        .catch((err) => {
          console.error("CorrelationId:", err?.response?.data?.CorrelationId)
        })
        .finally(() => {
          setLoading(false)
          setSearchText("")
        })
    }

    if (searchText !== "") {
      setNotFound(false)
      setLoading(true)
      setData([])
      requestOrders()
    }

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionId, searchText])

  return (
    <>
      {inspectionData && inspectionData?.inspection.length !== 0 && (
        <Modal
          centered
          fullscreen="true"
          animation={false}
          show={inspectionModalOpen}
          onHide={() => handleInspectionModalHide(true)}
          backdrop="static"
          backdropClassName="backdrop-newrental-inspection">
          <Modal.Header className="d-block p-3">
            <div className="position-relative">
              <h3 className="header-title mt-3 mb-4 text-center">
                {t("Inspection is approaching")}
              </h3>
              <button
                onClick={() => handleInspectionModalHide(true)}
                className="bg-transparent position-absolute p-0 text-body"
                style={{ top: "-7px", right: 0 }}>
                <CustomSvgIcon
                  name="close"
                  width={16}
                  height={16}
                  className="text-black"
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <MachineInspection inspection={inspectionData.inspection} />
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex align-items-center justify-content-between flex-row-reverse">
              <button
                className="btn btn-primary w-100 d-block"
                onClick={() => handleInspectionModalHide(false)}>
                {t("Continue")}
              </button>
              <button
                className="btn btn-outline-secondary w-100 d-block mr-3"
                onClick={() => handleInspectionModalHide(true)}>
                {t("Cancel")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      <Modal
        centered
        fullscreen="true"
        animation={false}
        className={`modal-scrollable${showModalSheet ? " block-scroll" : ""}`}
        show={open}
        backdrop="static"
        onHide={handleOnHide}>
        {slideIndex === 0 && (
          <Modal.Header className="d-block p-3">
            <div className="position-relative">
              <h3 className="header-title mt-3 mb-4 text-center">
                {t("Add Article/Fleet Model")}
              </h3>
              <SearchForm
                searchText={searchText}
                onSubmitHandler={handleSearch}
                onBlurHandler={handleSearchBlur}
                disabled={showModalSheet}
              />
              <button
                onClick={handleOnHide}
                className="bg-transparent position-absolute p-0 text-body"
                style={{ top: "-7px", right: 0 }}>
                <CustomSvgIcon
                  name="close"
                  width={16}
                  height={16}
                  className="text-black"
                />
              </button>
            </div>
          </Modal.Header>
        )}

        {slideIndex === 1 && (
          <Modal.Header className="d-block p-3">
            <div className="position-relative">
              <h3 className="header-title mt-3 mb-3 text-center position-relative">
                {t("Select addons")}
                <button
                  className="bg-transparent p-0 position-absolute"
                  style={{ left: 0, top: "-3px" }}
                  onClick={() => {
                    setSlideIndex(0)
                  }}>
                  <CustomSvgIcon
                    width={16}
                    height={16}
                    name="chevron-left"
                    className="text-white"
                  />
                </button>
              </h3>

              <p className="mb-4 text-center">{selectedRental}</p>

              <button
                onClick={handleOnHide}
                className="bg-transparent position-absolute p-0 text-body"
                style={{ top: "-7px", right: 0 }}>
                <CustomSvgIcon
                  name="close"
                  width={16}
                  height={16}
                  className="text-black"
                />
              </button>
            </div>
          </Modal.Header>
        )}

        <Modal.Body className="p-3 bg-white is-scrollable mx-0">
          <Carousel
            interval={null}
            controls={false}
            touch={false}
            keyboard={false}
            indicators={false}
            slide={false}
            activeIndex={slideIndex}>
            <Carousel.Item interval={300}>
              {loading && <Loader />}

              {!loading && notFound && (
                <Alert
                  className="text-center"
                  message={t("No articles or fleet models found")}
                />
              )}

              {data.length > 0 &&
                data.map((item, index) => {
                  return (
                    <NewRentalItem
                      key={index}
                      isDisabled={itemDisabled}
                      item={item}
                      onItemClickHandler={() => handleRentalItemClick(item)}
                      onCheckedHandler={handleChecked}
                      checkedItems={checkedItems}
                      showModalSheet={showModalSheet}
                    />
                  )
                })}
            </Carousel.Item>
            <Carousel.Item interval={300}>
              {accessoryData.length > 0 &&
                accessoryData.map((data, index) => {
                  return (
                    <AccessoryItem
                      key={index}
                      item={data.item}
                      quantity={data.quantity}
                      isPreSelected={data.isPreSelected}
                      onCheckedHandler={handleChecked}
                    />
                  )
                })}
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
        <Modal.Footer className="mt-auto p-3">
          <div className="w-100">
            <button
              className="btn btn-primary w-100 d-block"
              disabled={
                checkedItems.length === 0 ||
                fleetModelsLoading ||
                itemDisabled ||
                showModalSheet
              }
              onClick={() => {
                onSaveHandler(checkedItems)
                setCheckedItems([])
                setData([])
                setAccessoryData([])
                setSlideIndex(0)
              }}>
              {t("Save")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <ModalSheet show={showModalSheet}>
        {controlValidation && (
          <ControlValidation
            setShowModalSheet={setShowModalSheet}
            item={controlValidationItem}
            handleUpdateStore={handleUpdateStore}
            handleCancel={handleControlValidationCancel}
          />
        )}
      </ModalSheet>
    </>
  )
}

export default NewRentalModal
