import { atom } from "recoil"

export const machineLists = atom({
  key: "machineLists",
  default: {
    lists: [],
    count: 0
  }
})

export const currentMachineList = atom({
  key: "currentMachineList",
  default: null
})
