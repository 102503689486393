import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useOrderContext from "../../context/ReturnOrder/Store"
import { useLocation } from "react-router-dom"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const OrderActions = ({
  btnNextText,
  btnNextDisabled,
  btnNextClickHandler,
  btnAddRentalClickHandler,
  isSummary = false,
  creditData,
  newRentalsData,
  loading,
  btnDateClickHandler,
  activeTab = "orderRows"
}) => {
  const { t } = useTranslation()

  const [proceed, setProceed] = useState(true)
  const [lockedCustomer, setLockedCustomer] = useState(false)
  const { rentalData, orderHeader } = useOrderContext()
  const { customerStatus } = orderHeader
  const { pathname } = useLocation()

  const newRentalsWithoutAddons =
    newRentalsData &&
    newRentalsData.filter((rental) => rental?.subType !== "ADDON")

  useEffect(() => {
    if (
      newRentalsWithoutAddons &&
      newRentalsWithoutAddons.length > 0 &&
      creditData?.hasCreditLimit
    ) {
      const creditLimitSum = creditData?.value
      let newRentalSum = 0

      let data = []
      let sum = 0

      newRentalsWithoutAddons &&
        newRentalsWithoutAddons.map((rental) => {
          const skipCreditCheck =
            rental?.item?.canBeAddedWhenCustomerIsBlocked ?? false
          if (!skipCreditCheck) {
            data.push(rental?.price * rental?.quantity)
          }

          return rental
        })
      for (let amount of Object.values(data)) {
        sum += amount
      }

      newRentalSum = sum

      if (newRentalSum === 0) {
        setProceed(true)
      } else {
        setProceed(newRentalSum < creditLimitSum)
      }
    }

    if (
      customerStatus === 1 &&
      newRentalsWithoutAddons &&
      newRentalsWithoutAddons.length > 0 // Only block customer if having new rentals on the return order
    ) {
      if (pathname.includes("summering") || pathname.includes("summery")) {
        setProceed(false)
        setLockedCustomer(true)
      }
    }
  }, [creditData, newRentalsWithoutAddons, customerStatus, pathname])

  return (
    <>
      <div className="order-footer position-fixed w-100 bg-white js-app-action-footer">
        <div className="container-fluid container-fluid-max-width">
          <div className="py-3">
            <div className="d-flex align-items-center justify-content-between">
              {!isSummary && (
                <>
                  <div>
                    <button
                      className="btn text-primary border d-flex align-items-center justify-content-center"
                      onClick={btnAddRentalClickHandler}
                      disabled={rentalData.length === 0}>
                      <CustomSvgIcon width={20} height={20} name="add" />
                    </button>
                  </div>
                  <div className="ml-3">
                    <button
                      className="btn border d-flex align-items-center justify-content-center"
                      onClick={btnDateClickHandler}
                      disabled={activeTab !== "orderRows"}>
                      <CustomSvgIcon width={20} height={20} name="calender" />
                    </button>
                  </div>
                </>
              )}

              {lockedCustomer && !proceed && (
                <div className="d-block">
                  <h4>{t("Information")}</h4>
                  {!loading && (
                    <div className="badge bg-soft-danger">
                      {t(
                        "The customer is blocked. The customer is not allowed to place orders"
                      )}
                    </div>
                  )}
                </div>
              )}

              {creditData && !proceed && !lockedCustomer && (
                <div className="d-block">
                  <h4>{t("Information")}</h4>
                  {!loading && (
                    <div className="badge bg-soft-danger">
                      {t("The customer lacks sufficient credit limit")}
                    </div>
                  )}
                </div>
              )}

              {proceed && !lockedCustomer && (
                <div className="ml-auto">
                  <button
                    className="btn btn-primary"
                    disabled={btnNextDisabled}
                    onClick={btnNextClickHandler}>
                    {btnNextText}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderActions
