import React, { useEffect, useState, useCallback, useRef, useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import Alert from "react-bootstrap/Alert"
import { useOidcUser } from "@axa-fr/react-oidc"
import { useTranslation } from "react-i18next"
import {
  inventoryView,
  newInventoryFind
} from "../../components/Inventory/Store"
import { useSetRecoilState, useRecoilState } from "recoil"
import Storage from "../../utils/storage"
import SearchForm from "../../components/Inventory/Common/Find/SearchForm"
import Filters from "../../components/Inventory/Common/Find/Filters"
import Tags from "../../components/Inventory/Common/Find/Tags"
import List from "../../components/Inventory/Common/Find/List"
import Overlay from "../../components/Inventory/Common/Overlay"
import ModalSheet from "../../components/Common/ModalSheet"
import { useOnClickOutside } from "../../utils/hooks"
import Loader from "../../components/Loader/Loader"
import Toaster from "../../components/Common/Toast"
import toast from "react-hot-toast"

import { getInventories, getFleetItems } from "../../utils/hooks"
import { usePermissionCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"
import CustomSvgIcon from "../../components/Common/CustomSvgIcon"

import { dateAndTimeNow } from "../../utils/helpers"

export default function Find() {
  const { t } = useTranslation()
  const hasInventoryPermission = usePermissionCheck("MinaSidor.Inventering")
  const fetchLimit = 500 // Limit of fleet items to fetch at once

  const userState = Storage.get("userState") ?? null

  const { oidcUser } = useOidcUser()
  const modalSheetRef = useRef()
  const setViewState = useSetRecoilState(inventoryView)
  const [newInventoryFindState, setNewInventoryFindState] =
    useRecoilState(newInventoryFind)

  const [searchText, setSearchText] = useState("")
  const [inventoriesIsLoaded, setInventoriesIsLoaded] = useState(false)
  const [inventoryInitFleetItems, setInitInventoryFleetItems] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [registrationLoading, setRegistrationLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const [error, setError] = useState(null)
  const [showModalSheet, setShowModalSheet] = useState(false)
  const [currentMachine, setCurrentMachine] = useState(null)
  const [showFilters, setShowFilters] = useState(false)
  const [isFiltering, setIsFiltering] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [recordStreaming, setRecordStreaming] = useState(false)

  const defaultSorting = useMemo(() => {
    return {
      key: "sort",
      sort: "numberASC"
    }
  }, [])

  const [sorting, setSorting] = useState(defaultSorting)

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(modalSheetRef, () => setShowModalSheet(false))

  // Handle search
  const handleSearch = async (text) => {
    setSearchText(text)

    if (text.length > 0) {
      setError(null)
      setStatus(null)

      const keyword = text // keyword to search for
      const keysToSearch = ["number", "name"] // keys to search in

      const filtered = inventoryInitFleetItems?.filter((entry) =>
        keysToSearch.some(
          (key) =>
            typeof entry[key] === "string" &&
            entry[key].toLowerCase().includes(keyword.toLowerCase())
        )
      )

      setData(filtered)
    }

    if (text.length === 0) {
      setData(inventoryInitFleetItems)
    }
  }

  // Handle search blur
  const handleSearchBlur = (value) => {
    setError(null)
    setStatus(null)
    setSearchText(value)
  }

  // Handle click on machine
  const handleClick = (fleetItem) => {
    setShowModalSheet(true)
    setCurrentMachine(fleetItem)
  }

  const handleSendInventoryForRegistration = async (currentMachine) => {
    setRegistrationLoading(true)

    const currentInventoryRecordData =
      newInventoryFindState?.inventoryRecordData?.find((item) => {
        return item?.fleetItemId === currentMachine?.id
      }) ?? null

    let body = {
      inventoryOccasionId: currentInventoryRecordData?.inventoryOccasionId,
      fleetItemNumber: currentMachine?.number,
      branchId: userState?.internal?.depot?.id,
      createdBy: `${oidcUser?.name} - ${oidcUser?.preferred_username}`
    }

    await axios
      .post("/api/hll/inventoryRegistrationAdd", {
        body: body
      })
      .then((response) => {
        if (response?.status !== 200) {
          setStatus(null)
          setError(null)
          toast.error(
            t(
              "Unfortunately, the machine could not be registered, please try again."
            )
          )

          window?.dataLayer?.push({
            event: "inventory_registration_control_error",
            company: userState?.internal?.region?.regionName ?? "",
            depot: userState?.internal?.depot?.name ?? "",
            createdAt: dateAndTimeNow(),
            userType: "internal"
          })

          return
        }

        setNewInventoryFindState((previousNewInventoryFindState) => {
          return {
            ...previousNewInventoryFindState,
            foundMachines: [
              ...previousNewInventoryFindState?.foundMachines,
              currentMachine
            ],
            inventoryRecordData: [
              ...previousNewInventoryFindState?.inventoryRecordData.filter(
                (item) => {
                  return item?.fleetItemId !== currentMachine?.id
                }
              )
            ],
            fleetItems: [
              ...previousNewInventoryFindState?.fleetItems.filter((item) => {
                return item?.id !== currentMachine?.id
              })
            ]
          }
        })

        setInitInventoryFleetItems((previous) => {
          return [
            ...previous.filter((item) => {
              return item?.id !== currentMachine?.id
            })
          ]
        })

        setData((previous) => {
          return [
            ...previous.filter((item) => {
              return item?.id !== currentMachine?.id
            })
          ]
        })

        toast.success(t("The machine is now registered"))

        window?.dataLayer?.push({
          event: "inventory_registration_control_success",
          company: userState?.internal?.region?.regionName ?? "",
          depot: userState?.internal?.depot?.name ?? "",
          createdAt: dateAndTimeNow(),
          userType: "internal"
        })
      })
      .catch((error) => {
        console.log(error)
        setStatus("error")
        setError(t("Something went wrong, try again."))
        toast.error(t("Something went wrong, try again."))
      })
      .finally(() => {
        setRegistrationLoading(false)
        setShowModalSheet(false)
        setCurrentMachine(null)
      })
  }

  // Request all inventory records
  const requestInventoryRecords = useCallback(async () => {
    setLoading(true)

    const filterQueryData = newInventoryFindState?.filterQueryData ?? null

    await axios
      .post("/api/hll/pointIntimeInventoryRecordQuery", {
        onlyNotFoundFleetItems:
          newInventoryFindState?.filters?.onlyNotFoundFleetItems,
        offset: 0,
        data: filterQueryData
      })
      .then(async (response) => {
        const data = response?.data?.data ?? []

        setNewInventoryFindState((previousInventoryFindState) => {
          return {
            ...previousInventoryFindState,
            inventoryRecordData: data
          }
        })

        setLoading(false)

        if (data && data.length > 0) {
          const recordIds =
            data && data.length > 0
              ? data?.map((item) => item?.fleetItemId)
              : []

          if (recordIds && recordIds.length > 0) {
            // Start fetching fleet items in batches
            setRecordStreaming(true)
            do {
              const fleetItems = await getFleetItems(
                recordIds.slice(0, fetchLimit),
                fetchLimit
              )

              if (fleetItems?.data?.length > 0) {
                let sortedData = fleetItems?.data.sort((a, b) => {
                  return a?.number?.localeCompare(b?.number)
                })

                setNewInventoryFindState((previousInventoryFindState) => {
                  return {
                    ...previousInventoryFindState,
                    fleetItems: [
                      ...sortedData,
                      ...previousInventoryFindState?.fleetItems
                    ]
                  }
                })

                setInitInventoryFleetItems((previous) => {
                  return [...sortedData, ...previous]
                })

                setData((previous) => {
                  return [...sortedData, ...previous]
                })
              }

              recordIds.splice(0, fetchLimit)
            } while (recordIds.length > 0)
          }
        } else {
          setNewInventoryFindState((previousInventoryFindState) => {
            return {
              ...previousInventoryFindState,
              inventoryRecordData: [],
              fleetItems: []
            }
          })
          setInitInventoryFleetItems([])
          setData([])
        }
      })
      .catch((err) => {
        console.log("error", err)
        setInitInventoryFleetItems([])
        setData([])
        setNewInventoryFindState((previousInventoryFindState) => {
          return {
            ...previousInventoryFindState,
            inventoryRecordData: [],
            fleetItems: []
          }
        })
        //setErrorCode(err?.response?.data?.CorrelationId)
        setError(true)
      })
      .finally(() => {
        setRecordStreaming(false)
      })
  }, [
    newInventoryFindState?.filterQueryData,
    newInventoryFindState?.filters?.onlyNotFoundFleetItems,
    setNewInventoryFindState
  ])

  // Request all inventories
  useQuery({
    queryKey: ["inventories"],
    queryFn: async () => {
      setLoading(true)
      setIsDisabled(false)
      const inventories = await getInventories()

      const searchingInventories = inventories.filter(
        (item) => item?.status === 3
      )

      if (searchingInventories && searchingInventories.length > 0) {
        setNewInventoryFindState((previousInventoryFindState) => {
          return {
            ...previousInventoryFindState,
            filters: {
              ...previousInventoryFindState?.filters,
              inventories: searchingInventories
            },
            filterQueryData: {
              ...previousInventoryFindState?.filterQueryData,
              inventoryOccasionId: {
                logicalOperator: "And",
                predicate: "Equal",
                values: searchingInventories?.map(
                  (item) => item?.inventoryOccasionId
                )
              }
            }
          }
        })
        setInventoriesIsLoaded(true)
      } else {
        // Can't find any inventories
        setIsDisabled(true)
        setLoading(false)
      }
      return inventories ?? null
    },
    refetchOnWindowFocus: false,
    enabled: !isFiltering && hasInventoryPermission,
    cacheTime: 60000 * 10 // 10 minutes
  })

  // Set view state to newInventory and request all machines
  useEffect(() => {
    setViewState("findInventory")
    setNewInventoryFindState((previousInventoryFindState) => {
      return {
        ...previousInventoryFindState,
        pageHeader: t("Find machines")
      }
    })
  }, [setViewState, setNewInventoryFindState, t])

  useEffect(() => {
    if (inventoriesIsLoaded && hasInventoryPermission) {
      requestInventoryRecords()
    }
  }, [inventoriesIsLoaded, requestInventoryRecords, hasInventoryPermission])

  useEffect(() => {
    if (isFiltering) {
      const filteredFleetItems = newInventoryFindState?.fleetItems?.filter(
        (item) => {
          return newInventoryFindState?.filters?.selectedCompanies.includes(
            item?.ownedByCompany
          )
        }
      )

      setData(filteredFleetItems)
      setInitInventoryFleetItems(filteredFleetItems)
    }
  }, [
    isFiltering,
    newInventoryFindState?.filters?.selectedCompanies,
    newInventoryFindState?.fleetItems
  ])

  if (!hasInventoryPermission) {
    return <PageNotFound />
  }

  return (
    <>
      <Toaster />
      {showModalSheet && currentMachine && <Overlay />}

      <div className="container-fluid container-fluid-max-width pt-4">
        <div className="d-flex align-items-center">
          <div className="col">
            <SearchForm
              searchText={searchText}
              onSubmitHandler={handleSearch}
              onChangeHandler={handleSearch}
              onBlurHandler={handleSearchBlur}
              disabled={loading || recordStreaming || error || isDisabled}
              status={status}
              setStatus={setStatus}
              error={error}
              setError={setError}
              loading={loading}
            />
          </div>
          <div className="col-auto ml-2 position-relative">
            <Filters
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              setIsFiltering={setIsFiltering}
              loading={loading || recordStreaming || error || isDisabled}
              data={data}
              setData={setData}
              setSorting={setSorting}
              sorting={sorting}
            />
            <button
              disabled={loading || recordStreaming || error || isDisabled}
              onClick={() => setShowFilters(!showFilters)}
              className={`btn btn-sm py-1 px-3 bg-light p-0 border-0 btn-rounded ml-2`}>
              <span className="d-flex align-items-center justify-content-center px-1 py-2">
                <CustomSvgIcon
                  name="settings-slider"
                  width={24}
                  height={24}
                  className="text-muted"
                />
              </span>

              <span
                className="d-block position-absolute badge badge-pill bg-primary rounded-circle"
                style={{
                  right: "-2px",
                  top: "-4px",
                  width: "14px",
                  height: "14px"
                }}
              />
            </button>
          </div>
        </div>
      </div>

      <div className="mt-3 py-3">
        <div className="container-fluid container-fluid-max-width">
          {!loading && !recordStreaming && <Tags />}
        </div>
      </div>

      {loading && !recordStreaming && (
        <div className="container-fluid container-fluid-max-lg mt-3">
          <div className="row">
            <div className="col-12 text-center">
              <Loader className="mt-3 mb-2" />
              <p>{`${t("Fetching registrations")}`}</p>
            </div>
          </div>
        </div>
      )}

      {recordStreaming && !loading && (
        <div className="container-fluid container-fluid-max-lg mt-3">
          <div className="row">
            <div className="col-12 text-center">
              <Loader className="mt-3 mb-2" />
              <div className="d-flex align-items-center justify-content-center text-muted">
                <span>{`${t("Retrieves list of missing individuals")}`}</span>
                {data?.length > 0 && (
                  <span className="ml-2 badge bg-light">{`${data?.length} ${t(
                    "of"
                  )} ${
                    newInventoryFindState?.inventoryRecordData.length
                  }`}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {!loading && !recordStreaming && !data.length && (
        <div className="container-fluid container-fluid-max-width mt-3">
          <div className="row">
            <div className="col-12">
              <Alert variant="warning" className="text-primary">
                {t("Can't find any machines in your selection")}.
              </Alert>
            </div>
          </div>
        </div>
      )}

      {!loading && data && data.length > 0 && (
        <div className="container-fluid container-fluid-max-width position-relative mt-3">
          <div className="row mb-5 pb-5">
            {data &&
              data.length > 0 &&
              data?.map((item, index) => {
                return (
                  <div className="col-12 px-0 px-sm-3" key={index}>
                    <List
                      key={index}
                      handleClick={handleClick}
                      item={item}
                      currentMachine={currentMachine}
                      recordStreaming={recordStreaming}
                    />
                  </div>
                )
              })}
          </div>
        </div>
      )}

      <div ref={modalSheetRef}>
        <ModalSheet
          show={showModalSheet && currentMachine}
          roundedBig={true}
          maxWidth={false}
          shadow={false}>
          <div className="position-relative mt-3">
            <h2 className="header-title mb-0 text-center px-3 mb-3">
              {t("Have you found?")}
            </h2>

            <p className="text-truncate text-muted d-block mb-1">
              <strong>{`${t("The machine is inventoried to")} ${
                userState?.internal?.depot?.name ??
                userState?.internal?.depot?.name
              }`}</strong>
            </p>
          </div>

          {currentMachine && (
            <>
              <div className="px-3 py-3 my-3">
                <div className="d-flex align-items-center">
                  <div className="col">
                    <div className="d-block font-weight-bold text-left">
                      {currentMachine?.number && currentMachine?.number}
                    </div>
                  </div>
                </div>
                <div className="row d-flex align-items-center3">
                  <div className="col">
                    <span className="d-block text-muted text-gray-700 text-left">
                      {currentMachine?.fleetModelName &&
                        currentMachine?.fleetModelName}
                    </span>
                  </div>
                  <div className="col-auto">
                    {/* <span className="d-block text-muted text-gray-700 text-left">
                      {currentMachine?.branchName && currentMachine?.branchName}
                    </span> */}
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="d-flex align-items-center border-top py-3">
            <button
              className="btn btn-outline-secondary w-100 d-block mr-3"
              onClick={() => {
                setCurrentMachine(null)
                setShowModalSheet(false)
              }}>
              {t("Cancel")}
            </button>
            <button
              className="btn btn-primary w-100 d-block"
              disabled={registrationLoading || error}
              onClick={() =>
                handleSendInventoryForRegistration(currentMachine)
              }>
              {registrationLoading ? (
                <div
                  className="spinner-border spinner-border-sm text-white"
                  role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                t("Yes")
              )}
            </button>
          </div>
        </ModalSheet>
      </div>
    </>
  )
}
