import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useMachineContext from "../../../context/Machine/Store"
import MachineActions from "./MachineActions"
import { useNavigate } from "react-router-dom"
import ConfirmModal from "./ConfirmModal"
import ControlNoteModal from "./ControlNoteModal"
import MachineItemDataRow from "./MachineItemDataRow"
import MachineItemDataBranchRow from "./MachineItemDataBranchRow"
import InspectionNotes from "./InspectionNotes"
import Inspection from "./Inspection"
import MachineSummary from "./MachineSummary"
import Storage from "../../../utils/storage"
import {
  machineStatus,
  availabilityColorMap,
  getInspectionDiff
} from "../../../utils/helpers"
import Toast from "../../Common/Toast"

const MachineItem = (props) => {
  const { t } = useTranslation()
  const { setMachineItem } = useMachineContext()
  const navigate = useNavigate()

  const {
    id,
    number,
    name,
    status,
    fleetModelName,
    availability,
    orderReturnInfo,
    inspectionNote,
    inspection,
    branchId,
    branchName,
    inTransport
  } = props

  const userState = Storage.get("userState") ?? null
  const depot = userState?.internal?.depot
  const [currentAvailability, setCurrentAvailability] = useState(
    parseInt(availability)
  )

  const [proceed, setProceed] = useState(
    currentAvailability === 0
      ? false
      : parseInt(availability) === parseInt(currentAvailability)
      ? false
      : true
  )
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [controlNoteModalOpen, setControlNoteModalOpen] = useState(false)
  const [newInspectionNotes, setNewInspectionNotes] = useState([])
  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const [nextStepIndex, setNextStepIndex] = useState(1)
  const [summary, setSummary] = useState(null)
  const currentDepotID = branchId ?? ""
  const currentBranchName = branchName ?? ""
  const [newDepot, setNewDepot] = useState({
    id: depot?.id,
    name: depot?.name
  })

  const onConfirmModalSave = (data) => {
    setSummary({
      ...{
        name,
        number
      },
      ...data
    })
  }

  const handleAvailability = (event) => {
    event.preventDefault()
    const value = parseInt(event.target.dataset.availability)
    setCurrentAvailability(value)
    setProceed(value !== availability)
  }

  useEffect(() => {
    setMachineItem(props)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    const currentLoggedInDepotID = depot?.id
    let hasUpcomingInspections = false

    if (currentLoggedInDepotID === currentDepotID && inspection.length !== 0) {
      for (let index = 0; index < inspection.length; index++) {
        const inspectionDiff = getInspectionDiff(inspection[index])
        if (inspectionDiff?.days >= -30) {
          hasUpcomingInspections = true
        }
      }

      setActiveStepIndex(hasUpcomingInspections ? 1 : 2)
      setNextStepIndex(2)
    } else if (
      currentLoggedInDepotID === currentDepotID &&
      inspection.length === 0
    ) {
      setActiveStepIndex(2)
      setNextStepIndex(2)
    } else if (
      currentLoggedInDepotID !== currentDepotID &&
      inspection.length !== 0
    ) {
      for (let index = 0; index < inspection.length; index++) {
        const inspectionDiff = getInspectionDiff(inspection[index])
        if (inspectionDiff?.days >= -30) {
          hasUpcomingInspections = true
        }
      }

      setActiveStepIndex(0)
      setNextStepIndex(hasUpcomingInspections ? 1 : 2)
    } else if (
      currentLoggedInDepotID !== currentDepotID &&
      inspection.length === 0
    ) {
      setActiveStepIndex(0)
      setNextStepIndex(2)
    }
  }, [inspection, currentDepotID, availability, depot.id])

  useEffect(() => {
    window.onbeforeunload = function () {
      return true
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [])

  const handleConfirmModalOpen = () => {
    if (proceed) {
      setConfirmModalOpen(true)
    }
  }

  const handleGoBack = () => {
    if (
      window.confirm(
        t("This action can't be undone. Nothing going to be saved!")
      )
    ) {
      navigate(-1)
    }
  }

  const handleControlNoteModalOpen = () => {
    setControlNoteModalOpen(true)
  }

  // Check machine status
  useEffect(() => {
    const disabledStatusArr = [-6, -7, -8, -9, -10]
    if (status < 0 && status >= -4) {
      //  Status 0,-1,-2,-3,-4 === 0
      setCurrentAvailability(parseInt(0))
    }
    if (disabledStatusArr.includes(parseInt(status))) {
      setCurrentAvailability(parseInt(0))
    }
  }, [status])

  // Check if machine is under transport
  useEffect(() => {
    if (inTransport) {
      setCurrentAvailability(0)
    }
  }, [inTransport])

  if (summary) {
    return (
      <MachineSummary
        currentAvailability={availability}
        currentBranchName={currentBranchName}
        summary={summary}
        setSummary={setSummary}
      />
    )
  }

  return (
    <>
      <Toast />
      <div className="container-fluid container-fluid-max-width has-action-footer machineItem">
        <ConfirmModal
          open={confirmModalOpen}
          setModalOpen={setConfirmModalOpen}
          currentAvailability={currentAvailability}
          activeStepIndex={activeStepIndex}
          inspection={inspection}
          currentDepotID={currentDepotID}
          currentBranchName={currentBranchName}
          setNewDepot={setNewDepot}
          nextStepIndex={nextStepIndex}
          onSavedHandler={onConfirmModalSave}
          newDepot={newDepot}
        />
        <ControlNoteModal
          fleetItemId={id}
          open={controlNoteModalOpen}
          setModalOpen={setControlNoteModalOpen}
          newInspectionNotes={newInspectionNotes}
          setNewInspectionNotes={setNewInspectionNotes}
        />
        <div className="row machine-data-row">
          <MachineItemDataRow label={t("Individual number")} value={number} />
          <MachineItemDataRow label={t("Name")} value={name} />

          {fleetModelName && (
            <MachineItemDataRow
              label={t("Group description")}
              value={fleetModelName}
            />
          )}

          {status >= 1 && !inTransport ? (
            <MachineItemDataBranchRow
              wrapperClass="col-12 divider--bottom"
              label={t("Status")}
              value={status >= 1 ? `${branchName}` : machineStatus(status)}
              status={status}
              branchId={branchId}
              branchName={branchName}
              availability={availability}
            />
          ) : (
            <MachineItemDataRow
              wrapperClass="col-12 divider--bottom"
              label={t("Status")}
              value={
                status >= 1 && !inTransport
                  ? `${branchName}`
                  : inTransport // If under ongoing transport
                  ? `${t("Transported to")} ${branchName}`
                  : machineStatus(status)
              }
            />
          )}
        </div>
        <div className="row machine-data-row">
          <div className="col-12 divider--bottom pt-4 pb-4">
            <p className="text-uppercase font-xs pb-0 mb-1">
              {t("Availability")}
            </p>
            <div
              className="btn-group btn-group-lg w-100 mt-2"
              role="group"
              aria-label="First group">
              <button
                type="button"
                className={`btn btn-white${
                  currentAvailability === 1
                    ? ` availability availability--${availabilityColorMap(
                        currentAvailability
                      )}`
                    : ""
                }`}
                disabled={currentAvailability === 0}
                data-availability={1}
                onClick={handleAvailability}>
                {t("Available")}
              </button>
              <button
                type="button"
                className={`btn btn-white${
                  currentAvailability === -1
                    ? ` availability availability--${availabilityColorMap(
                        currentAvailability
                      )}`
                    : ""
                }${currentAvailability === 0 ? ` disabled` : ""}`}
                disabled={currentAvailability === 0}
                data-availability={-1}
                onClick={handleAvailability}>
                {t("On control")}
              </button>
              <button
                type="button"
                className={`btn btn-white${
                  currentAvailability === 0
                    ? ` availability availability--${availabilityColorMap(
                        currentAvailability
                      )}`
                    : ""
                }`}
                disabled={true}
                data-availability={0}
                onClick={handleAvailability}>
                {t("Not Available")}
              </button>
            </div>
            {inspection.length !== 0 && (
              <div className="row mt-3">
                <Inspection data={inspection} />
              </div>
            )}
          </div>
        </div>

        {orderReturnInfo && (
          <div className="row machine-data-row">
            <div className="col-12 divider--bottom pt-4 pb-4">
              <p className="text-uppercase font-xs pb-0 mb-1">
                {t("Information")}
              </p>
              {orderReturnInfo}
            </div>
          </div>
        )}

        <div className="row machine-data-row">
          <div className="col-12 pt-4 pb-2">
            <p className="text-uppercase font-xs pb-0 mb-1">
              {t("Control note")}
            </p>
            <button
              onClick={handleControlNoteModalOpen}
              className="btn btn-white btn-outline w-100 mt-2">
              {t("Create new note")}
            </button>
          </div>

          {newInspectionNotes && <InspectionNotes notes={newInspectionNotes} />}
          {inspectionNote && (
            <InspectionNotes notes={inspectionNote.slice(0, 3)} />
          )}
        </div>

        <MachineActions
          proceed={proceed}
          handleGoBack={handleGoBack}
          handleOpenModal={handleConfirmModalOpen}
        />
      </div>
    </>
  )
}

export default MachineItem
