import React from "react"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

function SummaryItem({
  name,
  quantity,
  identifier,
  meta = "",
  description = "",
  price = "",
  priceUnit = "",
  orderReturnInfo = ""
}) {
  let formatedPrice = price

  if (price < 0) {
    formatedPrice = `${price} %`
  } else if (price === 0) {
    formatedPrice = `${price} kr${priceUnit ? `/${priceUnit}` : ""}`
  } else {
    formatedPrice = price
      ? `${price} kr${priceUnit ? `/${priceUnit}` : ""}`
      : ""
  }

  return (
    <div className="d-block p-0 w-100 order-item text-left text-reset">
      <div className="p-3 bg-white">
        <div className="d-flex align-items-center">
          <span className="font-weight-bold pr-3">{name}</span>
          <span className="badge bg-light ml-auto">
            {`${quantity}${priceUnit !== "" ? ` ${priceUnit}` : ""}`}
          </span>
        </div>
        <div className="w-100 bg-light rounded border border-light border-1 mt-3 d-flex align-items-center text-muted p-2 mt-2">
          <div className="text-muted">{`${identifier}${
            formatedPrice ? ` | ${formatedPrice}` : ""
          }`}</div>
          <div className="ml-auto">{meta}</div>
        </div>
        {orderReturnInfo && (
          <div className="w-100 mt-3 text-muted font-xs">
            <div>{orderReturnInfo}</div>
          </div>
        )}

        {description !== "" && (
          <div className="w-100 mt-3 d-flex align-items-center text-muted">
            <CustomSvgIcon
              name="file"
              width={20}
              height={20}
              className="mr-2 text-muted"
            />
            <div className="font-sm">{description}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SummaryItem
