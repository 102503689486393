import React from "react"
import { useUpdateCheck } from "react-update-notification"
import { useTranslation } from "react-i18next"

import Logo from "../assets/images/HLL_notext.svg"

import Overlay from "../components/Common/Overlay"

const NotificationContainer = () => {
  const { t } = useTranslation()
  const showOverlay = true
  const { status, reloadPage } = useUpdateCheck({
    type: "interval",
    interval: 10000
  })

  if (status === "checking" || status === "current") {
    return null
  }

  return (
    <>
      {showOverlay && <Overlay />}
      <div className="update-notification-popup center-center">
        <figure className="d-block mx-auto mb-3 mt-0 text-center">
          <img
            src={Logo}
            alt="Hyreslandslagets logotyp"
            style={{ width: "50px" }}
            className="logo"
          />
        </figure>
        <p>{t("There is a new version of Mitt HLL")}</p>
        <button
          type="button"
          className="btn btn-primary btn-block"
          onClick={reloadPage}>
          {t("Reload now")}
        </button>
      </div>
    </>
  )
}

export default NotificationContainer
