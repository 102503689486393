import React from "react"
import ChevronRight from "./svg/ChevronRight"
import MinusCircle from "./svg/MinusCircle"
import Money from "./svg/Money"
import PlusCircle from "./svg/PlusCircle"
import Spinner from "./svg/Spinner"
import Stop from "./svg/Stop"
import Confetti from "./svg/Confetti"

function Icon({ name = "", className = "" }) {
  const icons = {
    chevronRight: <ChevronRight className={className} />,
    money: <Money className={className} />,
    plusCircle: <PlusCircle className={className} />,
    minusCircle: <MinusCircle className={className} />,
    spinner: <Spinner className={className} />,
    stop: <Stop className={className} />,
    confetti: <Confetti className={className} />
  }

  if (!name || typeof icons[name] === "undefined") {
    return null
  }

  return icons[name]
}

export default Icon
