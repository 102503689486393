import React from "react"
import CustomSvgIcon from "../Common/CustomSvgIcon"
function DepotList({ data }) {
  const depots = data.sort((a, b) => (a.name > b.name ? 1 : -1))
  return (
    <>
      {depots.map((item, i) => (
        <div className="col-12 col-md-6 col-lg-4" key={item?.id}>
          <div className="card">
            <div className="card-body d-block">
              <div className="row align-items-center">
                <div className="col ml-n2">
                  <h3 className="mb-3 header-title text-truncate">
                    <a
                      className="d-flex align-items-between"
                      href={`https://www.google.se/maps/place/${item?.address?.adressLine1} ${item?.address?.postalCode} ${item?.address?.city}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      <span>{item.name && item.name}</span>

                      <div className="ml-auto">
                        <CustomSvgIcon
                          width={16}
                          height={16}
                          name="chevron-right"
                        />
                      </div>
                    </a>
                  </h3>

                  <div className="col mb-3">
                    <p className="text-body my-0">
                      {item.address &&
                        `${item?.address?.adressLine1}, ${item?.address?.postalCode} ${item?.address?.city}`}
                    </p>
                  </div>

                  <div className="d-flex align-items-between">
                    {item.phone && (
                      <p className="text-body text-muted mr-2 mr-auto" key={i}>
                        <a href={`tel:${item.phone}`} className="d-flex">
                          <span className="fe fe-phone d-inline-block mr-1"></span>
                          {item.phone}
                        </a>
                      </p>
                    )}

                    <p className="text-body text-muted ml-auto">
                      {item.email && (
                        <a href={`mailto:${item.email}`} className="d-flex">
                          <span className="fe fe-mail d-inline-block mr-1"></span>
                          {item.email}
                        </a>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
export default DepotList
