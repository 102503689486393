import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Form from "./Form/Form"
import { formTemplate } from "./Form/FormTemplate"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import Loader from "../Loader/Loader"

import Storage from "../../utils/storage"

import axios from "axios"
import Toaster from "../Common/Toast"
import toast from "react-hot-toast"
import { dateAndTimeNow, googleMapsDataToAddress } from "../../utils/helpers"

export default function ProjectCreate() {
  const { t } = useTranslation()
  const customer = Storage.get("customer", null, "local") ?? null
  const [loading, setLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [createdProject, setCreatedProject] = useState(null)
  const [addressData, setAddressData] = useState(null)
  const navigate = useNavigate()

  const [nameRequired, setNameRequired] = useState({
    value: true,
    message: t("Required field")
  })
  const [numberRequired, setNumberRequired] = useState({
    value: true,
    message: t("Required field")
  })

  const validation = () => {
    const nameValue = getValues("name")
    const numberValue = getValues("number")

    if (nameValue !== "" && numberValue === "") {
      setNameRequired({
        value: true,
        message: t("Required field")
      })
      setNumberRequired(null)
      setDisabledSubmit(false)
    } else if (nameValue === "" && numberValue !== "") {
      setNameRequired(null)
      setNumberRequired({
        value: true,
        message: t("Required field")
      })
      setDisabledSubmit(false)
    } else if (nameValue === "" && numberValue === "") {
      setNameRequired({
        value: true,
        message: t("Required field")
      })
      setNumberRequired({
        value: true,
        message: t("Required field")
      })
      setDisabledSubmit(true)
    } else if (nameValue !== "" && numberValue !== "") {
      setNameRequired(null)
      setNumberRequired(null)
      setDisabledSubmit(false)
    } else {
      setNameRequired({
        value: true,
        message: t("Required field")
      })
      setNumberRequired({
        value: true,
        message: t("Required field")
      })
      setDisabledSubmit(true)
    }
  }

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors }
  } = useForm({
    shouldUnregister: true
  })

  const handleForm = {
    register,
    errors,
    setError,
    getValues
  }

  const onSubmit = async (data) => {
    //console.log("onSubmit", data)
    let formattedData = {}

    setLoading(true)
    setIsDisabled(true)
    setCreatedProject(null)

    formattedData = {
      name: data?.name,
      number: data?.number,
      customerId: customer?.id,
      address: {
        adressLine1: "",
        adressLine2: "",
        adressLine3: "",
        postalCode: "",
        city: "",
        country: ""
      }
    }

    if (addressData) {
      formattedData.address = googleMapsDataToAddress(addressData)
      let route = ""
      let street_number = ""
      let postal_town = ""
      let postal_code = ""
      let country = ""
      const components = addressData["address_components"]

      components.map((component) => {
        const name = component["long_name"]
        const type = component["types"][0]

        switch (type) {
          case "route":
            route = name
            break

          case "street_number":
            street_number = name
            break

          case "postal_town":
            postal_town = name
            break

          case "postal_code":
            postal_code = name
            break

          case "country":
            country = name
            break
          default:
        }

        if (route !== "" && street_number !== "") {
          formattedData["address"].adressLine1 = `${route} ${street_number}`
        } else {
          formattedData["address"].adressLine1 = addressData?.name
        }

        formattedData["address"].postalCode = postal_code
        formattedData["address"].city = postal_town
        formattedData["address"].country = country

        return component
      })
    }

    await axios
      .post(`/api/hll/createProject`, {
        body: formattedData
      })
      .then((response) => {
        if (response.data) {
          setCreatedProject(response.data)

          window.dataLayer.push({
            event: "HLL_project_create",
            company: customer?.name,
            project: response?.data?.name,
            createdAt: dateAndTimeNow(),
            userType: "customer"
          })

          toast.success(t("The project is created"))
        }
      })
      .catch((error) => {
        console.error(error)
        toast.error(t("Something went wrong, try again."))
        setCreatedProject(null)
      })
      .finally(() => {
        setLoading(false)
        setIsDisabled(false)
      })
  }

  useEffect(() => {
    if (!loading && createdProject) {
      const { id, name, number, customerId } = createdProject
      navigate(`/${t("project")}/${customerId}/${id}`, {
        state: {
          customerId,
          id,
          name,
          number
        }
      }) // Go to single project...
    }
  }, [loading, createdProject, navigate, t])

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid container-fluid-max-width">
          <div className="row">
            <div className="col-12">
              <div className="header">
                <div className="header-body border-bottom-0 pb-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="header-pretitle">{t("My pages")}</h6>
                      <h1 className="header-title text-truncate">
                        {t("Create new project")}
                      </h1>
                    </div>
                    <div className="col-auto">
                      <button
                        className="btn btn-primary ml-auto mt-5 mt-md-0"
                        disabled={disabledSubmit}>
                        {t("Save")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          {loading && (
            <div className="row">
              <div className="col-2 text-center mx-auto">
                <Loader />
              </div>
            </div>
          )}
          {!loading && (
            <div className="row my-5">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-lg-8 order-2 order-lg-1">
                    <div className="card">
                      <div className="card-header">
                        <h2 className="card-header-title text-truncate">
                          {t("General information")}
                        </h2>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <Form
                            template={formTemplate}
                            handleForm={handleForm}
                            isDisabled={isDisabled}
                            setAddressData={setAddressData}
                            validation={validation}
                            nameRequired={nameRequired}
                            numberRequired={numberRequired}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 order-1 order-lg-2">
                    <h4>{t("Information")}</h4>
                    <p>
                      Projekt motsvarar de arbetsplatser som maskiner levereras
                      till och styr hur märkningen på fakturor ska se ut. Här
                      kan du enkelt skapa ett nytt projekt genom att fylla i
                      informationen i formuläret.
                    </p>
                    <p>
                      Det är obligatoriskt att ange antingen namn eller nummer
                      på projektet, det går även att ange båda två. Denna
                      information syns sedan på fakturan. Den adress som anges
                      kommer användas som leveransadress för kommande hyror, det
                      är därför viktigt att den är korrekt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </>
  )
}
