import React, { useState } from "react"
import axios from "axios"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import ActionFooter from "../../Common/ActionFooter"
import { useForm } from "react-hook-form"
import Icon from "../../Common/Icon"
import toast from "react-hot-toast"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const ModalOrderCopies = ({ show, setShowOrderCopiesModal, data }) => {
  const { t } = useTranslation()
  const [extraEmailVisible, setExtraEmailVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const branchId = data?.customerOrder?.customerOrderHead?.branchId
  const customerOrderId =
    data?.customerOrder?.customerOrderHead?.customerOrderId

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data) => {
    if (!data.branchId || !data.orderId) {
      toast.error(t("Missing parameters"))
      return
    }

    setLoading(true)

    let emails = [data.returnEmail]

    if (typeof data.returnEmailExtra !== "undefined") {
      emails.push(data.returnEmailExtra)
    }

    await axios
      .post("/api/hll/createOrderNoteNotification", {
        body: {
          orderNoteNotification: {
            branchId: data.branchId,
            orderId: data.orderId,
            recipientEmailAddresses: emails
          }
        }
      })
      .then((response) => {
        if (response.data && response.data.requestResult === 0) {
          toast.success(t("Order copies are submitted!"))
          setShowOrderCopiesModal(false)
        }
      })
      .catch((error) => {
        console.error(error)
        toast.error(
          `Something went wrong. Correlation ID: ${error?.response?.data?.CorrelationId}`
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      show={show}
      fullscreen="true"
      onHide={() => {
        setShowOrderCopiesModal(false)
      }}
      backdrop="static"
      animation={false}
      className="modal-scrollable">
      <Modal.Header className="d-block p-3">
        <div className="position-relative">
          <h3 className="header-title mt-3 mb-0 text-center">
            {t("Send extra order copy")}
          </h3>

          <button
            onClick={() => {
              setShowOrderCopiesModal(false)
            }}
            className="bg-transparent position-absolute p-0 text-body"
            style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
            <CustomSvgIcon
              name="close"
              width={16}
              height={16}
              className="text-black"
            />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="text-center p-3 bg-white is-scrollable mx-0 pb-5">
        <form
          className="py-3 modal-email-notification mb-3"
          onSubmit={handleSubmit(onSubmit)}
          id="order-notification">
          <input
            {...register("branchId")}
            type="hidden"
            name="branchId"
            value={branchId}
          />
          <input
            {...register("orderId")}
            type="hidden"
            name="orderId"
            value={customerOrderId}
          />

          <div className="input-group">
            <input
              name="returnEmail"
              type="email"
              placeholder={t("Email")}
              className={`form-control list-search${
                errors.returnEmail ? " is-invalid " : ""
              }`}
              aria-invalid={errors.returnEmail ? "true" : "false"}
              {...register("returnEmail", { required: true })}
            />
          </div>
          <div
            className={`input-group mt-3${
              !extraEmailVisible ? " d-none" : ""
            }`}>
            <input
              name="returnEmailExtra"
              type="email"
              placeholder={t("Email")}
              disabled={!extraEmailVisible}
              className={`form-control list-search${
                errors.returnEmailExtra ? " is-invalid " : ""
              }`}
              aria-invalid={errors.returnEmailExtra ? "true" : "false"}
              {...register("returnEmailExtra")}
            />
          </div>

          <div className="mt-3">
            <button
              type="button"
              className="d-flex w-100 align-items-center justify-content-center btn btn-outline-secondary"
              disabled={loading || extraEmailVisible}
              onClick={() => {
                setExtraEmailVisible(true)
              }}
              style={{
                fontWeight: 600,
                fontSize: 13,
                height: 50
              }}>
              <Icon name="plusCircle" />
              <span className="ml-2">{t("Add row")}</span>
            </button>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <ActionFooter>
          <button
            className="btn btn-primary w-100 d-block"
            type="submit"
            form="order-notification"
            disabled={loading}>
            {loading ? (
              <div
                className="spinner-grow spinner-grow-sm text-white position-relative"
                role="status"
                style={{ top: "-2px" }}>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              t("Send to selected addresses")
            )}
          </button>
        </ActionFooter>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalOrderCopies
