import React from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

function DirectPayment() {
  const { t } = useTranslation()

  return (
    <div className="container-fluid container-fluid-max-width">
      <div className="row justify-content-center">
        <div className="col-8 col-sm-7 col-md-6 col-lg-4 col-xl-3 py-5 text-center">
          <div className="mb-4">
            <span
              className="fe fe-dollar-sign text-primary"
              style={{ fontSize: 50 }}></span>
          </div>
          <h1 className="h3 mb-2">{t("Cash customer")}</h1>
          <p className="mb-4">
            {t(
              "The client is a cash customer and any orders needs to be handled with a computer."
            )}
          </p>
          <p>
            <Link
              to={`/${t("order")}`}
              className="btn btn-primary btn-sm font-weight-bold px-4 py-2">
              {t("Back")}
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default DirectPayment
