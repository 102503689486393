import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"

import ImageComingSoon from "../../../../assets/images/image-coming-soon.png"
import CustomSvgIcon from "../../../../components/Common/CustomSvgIcon"

export default function CategoryItem({
  id,
  name,
  slug,
  acf: { image_url: imageUrl }
}) {
  const { t } = useTranslation()
  const location = useLocation()
  let { pathname } = location

  return (
    <article
      data-id={`machine-category-${id}`}
      className={`d-flex flex-column machine-category-grid__item is-sub-category rounded p-4`}>
      <header>
        <h2 className="hyphenate text-black">
          <Link
            className="is-clickable text-black"
            to={{
              pathname: `${pathname}/${slug}`
            }}>
            {name}
          </Link>
        </h2>
      </header>
      {imageUrl && (
        <figure className="m-0 p-4 p-lg-0">
          <img
            src={imageUrl}
            alt={name}
            className="img-fluid rounded text-center mx-auto"
          />
        </figure>
      )}
      {!imageUrl && (
        <figure className="m-0 p-4 p-lg-0">
          <img
            src={ImageComingSoon}
            alt="Hyreslandslagets logotyp"
            className="logo"
          />
        </figure>
      )}
      <footer>
        <div className="d-flex align-items-center justify-content-start justify-content-lg-end">
          <span className="mr-2">{t("View machines")}</span>
          <div className="svg-icon-wrapper text-black ml-3">
            <CustomSvgIcon name="arrow-right" width={20} height={20} />
          </div>
        </div>
      </footer>
    </article>
  )
}
