import React, { useContext, useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import Icon from "../Common/Icon"
import { Accordion, AccordionContext } from "react-bootstrap"
import moment from "moment"
import "moment-timezone"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import sv from "date-fns/locale/sv"
import useOrderContext from "../../context/ReturnOrder/Store"
import AddonModal from "./AddonModal"
import CustomAccordionToggle from "../Common/CustomAccordionToggle"
import Storage from "../../utils/storage"
import axios from "axios"
import CustomSvgIcon from "../Common/CustomSvgIcon"
import toast from "react-hot-toast"

registerLocale("sv", sv)

function OrderItem({
  price,
  discount,
  siteId,
  id,
  description,
  quantity,
  reference,
  orderId,
  orderRowType,
  startTime,
  minimumRentalDays,
  sort,
  initialDate,
  orderReturnInfo
}) {
  const { t } = useTranslation()
  const currentEventKey = useContext(AccordionContext)
  const isAccordionActive = currentEventKey === id
  const [startDate, setStartDate] = useState(moment().toDate())
  const [amount, setAmount] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [modalData, setModalData] = useState([])
  const [addonModal, setAddonModal] = useState(false)
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const {
    deleteRental,
    setRental,
    rentalData,
    newRentalsData,
    setNewRental,
    deleteNewRental
  } = useOrderContext()
  const userState = Storage.get("userState") ?? null
  const regionId = userState?.internal?.region?.regionId ?? 3
  const internalCompanyId = userState?.internal?.region?.internalCompanyId ?? 3
  const cbRef = useRef()
  const datePickerRef = useRef()

  const days = (type, nr = 7) => moment()[type](nr, "d").toDate()
  const formatDate = () => {
    return `${datePickerRef.current.input.value}T00:00:00Z`
  }

  const updateOrderData = (action, quantity = 1) => {
    if (action === "add") {
      setRental({
        orderId,
        id,
        referenceId: reference.id,
        reference,
        description,
        quantity,
        siteId,
        price,
        discount,
        startTime,
        endTime: formatDate(),
        orderRowType,
        minimumRentalDays,
        sort,
        orderReturnInfo
      })

      setAmount(quantity)
    } else if (action === "remove") {
      deleteRental({
        id,
        orderId
      })

      deleteAddons()

      setAmount(0)
      cbRef.current.checked = false
    }
  }

  const handleOrderItemChange = ({ target: { checked } }) => {
    if (checked) {
      requestAccessories(reference.id)
    } else {
      updateOrderData("remove")
    }
  }

  const handleAmountClick = (type) => {
    const updatedAmount =
      type === "increment" ? Number(amount) + 1 : Number(amount) - 1

    setAmount(updatedAmount)

    handleInputChange({
      target: {
        value: updatedAmount
      }
    })
  }

  const handleInputChange = ({ target: { value } }) => {
    let updatedAmount = value

    if (updatedAmount > quantity) {
      updatedAmount = quantity
    } else if (updatedAmount < 0) {
      updatedAmount = 0
    }

    if (updatedAmount === 0) {
      cbRef.current.checked = false

      deleteRental({
        id,
        orderId
      })

      deleteAddons()
    }

    setAmount(updatedAmount)

    if (addonModal || quantity > 0) {
      setRental({
        orderId,
        id,
        referenceId: reference.id,
        description,
        reference,
        startTime,
        endTime: formatDate(),
        siteId,
        price,
        discount,
        quantity: updatedAmount,
        orderRowType: orderRowType,
        minimumRentalDays,
        sort,
        orderReturnInfo
      })
    }
  }

  const handleModalVisibility = (visible) => {
    setModalOpen(visible)
  }

  const requestAccessories = async (referenceId) => {
    setLoading(true)
    setModalData([])

    await axios
      .post("/api/hll/accessoryQuery", {
        body: {
          articleId: {
            logicalOperator: "Or",
            predicate: "Equal",
            value: referenceId
          },
          fleetItemId: {
            logicalOperator: "Or",
            predicate: "Equal",
            value: referenceId
          },
          queryContext: {
            logicalOperator: "And",
            predicate: "Equal",
            value: "Return"
          }
        },
        regionId: regionId,
        internalCompanyId: internalCompanyId
      })
      .then((response) => {
        const updatedAmount = amount > 0 ? amount : quantity

        setModalData(response.data)
        setAmount(updatedAmount)
        setAddonModal(false)

        setRental({
          orderId,
          id,
          referenceId: reference.id,
          description,
          reference,
          startTime,
          endTime: formatDate(),
          siteId,
          price,
          discount,
          quantity: updatedAmount,
          orderRowType: orderRowType,
          minimumRentalDays,
          sort,
          orderReturnInfo
        })

        cbRef.current.checked = true

        if (response.data.length > 0) {
          handleModalVisibility(true)
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.data?.message
        toast.error(errorMessage)
        // uncheck the checkbox
        cbRef.current.checked = false
      })
      .finally(() => {
        setLoading(false)
        setAddonModal(true)
      })
  }

  const handleModalSave = (addons, amount) => {
    if (addons) {
      const checkedAddons = addons.filter((addon) => addon.isChecked)
      const uncheckedAddons = addons.filter((addon) => !addon.isChecked)

      setNewRental(checkedAddons)

      if (uncheckedAddons) {
        uncheckedAddons.forEach(({ id }) => deleteNewRental({ id }))
      }
    }

    cbRef.current.checked = true

    setModalOpen(false)
    setAmount(amount)
  }

  const deleteAddons = () => {
    const addons = newRentalsData.filter(
      (newRental) => newRental?.referenceId === reference.id
    )

    if (addons) {
      addons.forEach(({ internalId }) => deleteNewRental({ internalId }))
    }
  }

  useEffect(() => {
    setStartDate(initialDate)

    // We need to manually set the datepicker selected value
    // in order to trigger the onChange event.
    datePickerRef.current.setSelected(initialDate)
  }, [initialDate])

  useEffect(() => {
    const rentalItem = rentalData.find((item) => {
      return item.id === id && item.orderId === orderId
    })

    if (rentalItem) {
      setAmount(rentalItem.quantity)
      setStartDate(moment(rentalItem.endTime).toDate())
      cbRef.current.checked = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="accordion--row order-item">
      <AddonModal
        referenceId={reference.id}
        orderId={orderId}
        modalVisibilityHandler={handleModalVisibility}
        modalSaveHandler={handleModalSave}
        amount={amount}
        open={modalOpen}
        setModalOpen={setModalOpen}
        data={modalData}
        isEdit={isEdit}
      />
      <div
        className={`d-block p-0 w-100 border-0 text-left text-reset${
          isAccordionActive ? " state--active" : ""
        }`}>
        <div className="p-3 pb-0 bg-white">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pr-3">
              <input
                className="form-check-input list-checkbox list-checkbox-theme"
                type="checkbox"
                ref={cbRef}
                disabled={loading}
                onClick={handleOrderItemChange}
              />
            </div>
            <CustomAccordionToggle
              eventKey={id}
              className="w-100 d-flex align-items-center text-body">
              <div className="mr-auto">
                <span className="font-weight-bold">{reference?.itemName}</span>
              </div>
              <div className="ml-auto">
                {isAccordionActive ? (
                  <CustomSvgIcon width={16} height={16} name="chevron-up" />
                ) : (
                  <CustomSvgIcon width={16} height={16} name="chevron-down" />
                )}
              </div>
            </CustomAccordionToggle>
          </div>
        </div>
        <div className="bg-white p-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="bg-light rounded border border-light border-1 w-100">
              <div className="row d-flex align-items-center justify-content-between g-0">
                <div className="col d-flex">
                  <div className="d-flex align-items-center text-muted text-gray-700 py-2 px-3 text-left">
                    <CustomSvgIcon
                      width={16}
                      height={16}
                      name="file"
                      className={`text-muted${
                        description || orderReturnInfo ? "" : " invisible"
                      }`}
                    />
                  </div>
                  <div className="mr-auto text-muted py-2">
                    {reference?.number}
                  </div>
                </div>
                <div className="col-auto pl-2">
                  <span className="d-block text-muted text-gray-700 py-2 px-3 border-left border-1 border-white py-2 px-3 text-right">
                    {`${amount === "" || amount < 0 ? 0 : amount} ${t(
                      "of"
                    )} ${quantity}`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accordion.Collapse eventKey={id}>
        <div className="p-3 pt-0 bg-white">
          {description && (
            <div className="p-3 border bg-white mb-3">{description}</div>
          )}

          {orderReturnInfo && <div className="mb-3">{orderReturnInfo}</div>}

          <div className="block">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="col pr-2">
                <label
                  htmlFor="date"
                  className="field__label d-block form-label mb-1 text-uppercase">
                  {t("Date")}
                </label>
                <div className="position-relative">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date)
                      setDatePickerOpen(false)
                      if (amount > 0) {
                        setRental({
                          orderId,
                          id,
                          referenceId: reference.id,
                          startTime,
                          endTime: moment(date).format("YYYY-MM-DD")
                        })
                      }
                    }}
                    dateFormat="yyyy-MM-dd"
                    minDate={days("subtract")}
                    maxDate={days("add")}
                    locale="sv"
                    ref={datePickerRef}
                    onClickOutside={() => setDatePickerOpen(false)}
                    onFocus={() => setDatePickerOpen(true)}
                    open={datePickerOpen}
                    readOnly={true}
                  />

                  <CustomSvgIcon
                    width={16}
                    height={16}
                    name="calender"
                    className={`text-muted pointer-none`}
                    style={{
                      position: "absolute",
                      right: 20,
                      top: 15
                    }}
                  />
                </div>
              </div>
              {reference.type !== "INDIVIDUAL" && (
                <div className="col pl-2">
                  <label
                    htmlFor="date"
                    className="field__label form-label mb-1 text-uppercase d-block">
                    {t("Amount to return")}
                  </label>

                  <div className="bg-white d-flex justify-content-between align-items-center order-input">
                    <button
                      className="bg-transparent p-0"
                      disabled={amount <= 0}
                      onClick={() => handleAmountClick("decrement")}>
                      <Icon name="minusCircle" />
                    </button>
                    <div>
                      <input
                        inputMode="decimal"
                        type="number"
                        className="h-100 form-control p-0 text-center border-0 appearence-none"
                        value={amount}
                        onChange={handleInputChange}
                      />
                    </div>
                    <button
                      className="bg-transparent p-0"
                      disabled={amount >= quantity}
                      onClick={() => handleAmountClick("increment")}>
                      <Icon name="plusCircle" />
                    </button>
                  </div>
                </div>
              )}
              <div className="col-12 mt-3 d-flex">
                <div className="row">
                  {amount > 0 && (
                    <div className="col">
                      <button
                        className="btn w-100 btn-danger"
                        onClick={() => {
                          updateOrderData("remove")
                        }}>
                        {t("Regret return")}
                      </button>
                    </div>
                  )}
                  <div className="col">
                    <button
                      className="btn w-100 btn-primary"
                      disabled={
                        (reference.type !== "INDIVIDUAL" && amount === 0) ||
                        loading
                      }
                      onClick={() => {
                        requestAccessories(reference.id)
                        setIsEdit(amount > 0 ? true : false)
                      }}>
                      {loading
                        ? t("Fetching addons...")
                        : amount === 0 || reference.type === "ARTICLE"
                        ? t("Add return")
                        : t("Change return")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  )
}

export default OrderItem
