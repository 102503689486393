import { multiDimensionalUnique } from "../../utils/helpers"

export function recipientsEmailMapping(items) {
  let mapping = []

  if (items.length === 0) {
    return
  }

  items.map((recipient) => {
    return recipient?.emails.map((email) => mapping.push(email))
  })

  let uniqueEmailArr = multiDimensionalUnique(mapping)

  return uniqueEmailArr.sort()
}

export function findDocumentTypesInArray(arr, email) {
  let documentTypes = []

  arr.map((item) => {
    return item?.emails && item?.emails.includes(email)
      ? documentTypes.push(item.documentType)
      : []
  })

  return documentTypes
}
