import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Storage from "../../utils/storage"
import Moment from "react-moment"
import "moment-timezone"
import { singleUrl } from "../../utils/helpers"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const ProjectCard = ({
  data = [],
  header = "",
  favorite = false,
  useLink = false,
  linkText = ""
}) => {
  const { t } = useTranslation()
  const customer = Storage.get("customer", null, "local") ?? null
  const perPage = 4
  const [next, setNext] = useState(perPage)

  const handleLoadMore = () => {
    setNext(next + perPage)
  }

  let projects = []

  if (favorite) {
    projects =
      data && data.length > 0
        ? data
            .sort((a, b) => (b.startTime > a.startTime ? 1 : -1))
            .filter(
              (project) => project.number !== customer?.corporateIdentityNumber
            )
            .splice(0, next)
        : []
  } else {
    projects = data && data.length > 0
  }

  return (
    <div className="card h-100">
      <div className="card-header">
        {header && <h4 className="card-header-title">{header}</h4>}
        {useLink && (
          <Link
            to={{
              pathname: `/${t("projects")}`,
              state: { favorites: favorite }
            }}
            className="small">
            {linkText === "" ? t("View all projects") : linkText}
          </Link>
        )}
      </div>

      {projects && projects.length !== 0 && (
        <div className="card-body">
          <ul className="list-group list-group-flush my-n3">
            {projects?.map((item) => (
              <li
                key={item.id}
                className={`list-group-item border-top-0 border-left-0 border-right-0`}>
                <Link
                  to={{
                    pathname: singleUrl(
                      `${t("project")}`,
                      item.customerId,
                      item.id
                    ),
                    customerId: item.customerId,
                    id: item.id,
                    name: item.name,
                    number: item.number
                  }}
                  className="d-block">
                  <div className="row align-items-center">
                    <div className="col-auto align-self-start">
                      <span className="fe fe-star" />
                    </div>
                    <div className="col">
                      <p className="body-text font-weight-normal my-0">
                        {item?.number && item.number}
                        {item?.name && ` ${item.name}`}
                      </p>

                      <p className="text-gray-700 my-0">
                        <small>
                          {t("Created at")}:{" "}
                          <Moment
                            locale="sv"
                            dateTime={item.startTime}
                            format="YYYY-MM-DD">
                            {item.startTime}
                          </Moment>
                        </small>
                      </p>
                    </div>

                    <div className="col-auto">
                      <CustomSvgIcon
                        width={16}
                        height={16}
                        name="chevron-right"
                        className="text-muted"
                      />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
          {next < data?.length && favorite && (
            <div className="my-5">
              <button
                className="btn btn-link d-block p-0"
                onClick={handleLoadMore}>
                + {t("Load more")}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ProjectCard
