import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { getInspectionDiff, inspectionType } from "../../../utils/helpers"

const Inspection = ({ data }) => {
  const { t } = useTranslation()
  const inspections = data ? data : null

  // Om det är mellan 0-30dagar så ha blå färg
  // Om det är mindre än 0, datum har passerat så visa röd färg

  return (
    <>
      {inspections.map((inspection, index) => {
        const inspectionDiff = getInspectionDiff(inspection)

        return (
          <Fragment key={index}>
            {inspectionDiff?.days >= -30 && (
              <div className="col-12">
                <div
                  className={`d-flex align-items-center alert alert-inspection${
                    inspectionDiff?.days >= 0 ? "--warning text-white" : ""
                  } p-2 font-xs mb-2`}
                  role="alert">
                  <span className="fe fe-alert-triangle mr-2"></span>
                  {`${inspectionType(inspection?.inspectionType)} ${t(
                    "latest on"
                  )} ${inspectionDiff?.dueDate.format("YYYY-MM-DD")}`}
                </div>
              </div>
            )}
          </Fragment>
        )
      })}
    </>
  )
}

export default Inspection
