import React, { useEffect, useState, useRef } from "react"
import axios from "axios"
import { Tabs, Tab } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import ActionFooter from "../Common/ActionFooter"
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil"
import {
  existingOrderRows,
  newOrderRows,
  view,
  modalView,
  orderInformation,
  creditLimit
} from "../../components/Order/Store"
import { useNavigate } from "react-router-dom"
import Item from "./Common/Item"
import OrderSummary from "./OrderSummary"
import OrderInformation from "./OrderInformation"
import { Accordion } from "react-bootstrap"
import ModalMachineSearch from "./Modal/ModalMachineSearch"
import {
  calculateOrderTotalPerRow,
  formatBodyForPost,
  previewItemMeta,
  checkMissingPrice
} from "./helpers"
import ModalSheet from "../Common/ModalSheet"
import ChangeDateForm from "./Form/ChangeDateForm"
import ValidationInfo from "./ValidationInfo"
import ModalOrderCopies from "./Modal/ModalOrderCopies"
import Toast from "../Common/Toast"
import { offerTypeMap } from "../../utils/helpers"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const Order = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState("newOrderRows")
  const [showModal, setShowModal] = useState(false)
  const [showOrderCopiesModal, setShowOrderCopiesModal] = useState(false)
  const existingOrderRowsState = useRecoilValue(existingOrderRows)
  const [newOrderRowsState, setNewOrderRowsState] = useRecoilState(newOrderRows)
  const orderInformationState = useRecoilValue(orderInformation)
  const [viewState, setViewState] = useRecoilState(view)
  const [showModalSheet, setShowModalSheet] = useState(false)
  const navigate = useNavigate()
  const setModalViewState = useSetRecoilState(modalView)
  const itemIsPreview =
    viewState === "previewExistingOrder" || viewState === "previewNewOrder"
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const onUnMount = useRef()
  const creditLimitState = useRecoilValue(creditLimit)
  const [showCreditLimitInfo, setShowCreditLimitInfo] = useState(false)
  const [isMissingPrice, setIsMissingPrice] = useState(false)

  const handleHideModal = () => {
    setShowModal(false)
  }

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleSave = async () => {
    setLoading(true)

    await axios
      .post("/api/hll/customerOrderRows", {
        customerOrderId: orderInformationState.customerOrderId,
        body: formatBodyForPost(newOrderRowsState)
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          setData(response.data)

          if (response.data.requestResult === 1) {
            setShowModalSheet(true)
          } else {
            setShowModalSheet(false)
            setViewState("summary")
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    window.onbeforeunload = function () {
      return true
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [])

  onUnMount.current = () => {
    if (newOrderRowsState.length > 0) {
      const deleteFleetItemLock = async (id) => {
        await axios.post("/api/hll/deleteFleetItemLock", {
          id
        })
      }

      newOrderRowsState.forEach((row) => {
        if (row.internalType !== "INDIVIDUAL") {
          return
        }

        deleteFleetItemLock(row.id)
      })

      setNewOrderRowsState([])
    }

    const deleteOrderLock = async () => {
      await axios
        .post("/api/hll/deleteOrderLock", {
          customerOrderId: orderInformationState.customerOrderId
        })
        .then(() => {
          console.info(
            `Order ${orderInformationState.customerOrderId} unlocked`
          )
        })
        .catch((error) => {
          console.error(`Error: ${error.response.data.CorrelationId}`)
        })
    }

    deleteOrderLock()
  }

  useEffect(() => {
    return () => {
      onUnMount.current()
    }
  }, [])

  useEffect(() => {
    let newOrderTotal = 0

    newOrderRowsState.forEach((row) => {
      if (row.quantity === 0) {
        return
      }

      newOrderTotal += calculateOrderTotalPerRow(row)
    })

    setShowCreditLimitInfo(newOrderTotal > creditLimitState.value)
  }, [creditLimitState.value, newOrderRowsState])

  useEffect(() => {
    const withoutPrice = newOrderRowsState.filter((row) => {
      return checkMissingPrice(row)
    })

    setIsMissingPrice(withoutPrice.length > 0)
  }, [newOrderRowsState])

  const customerStatus = orderInformationState?.customerStatus ?? null

  return (
    <div className="container-fluid container-fluid-max-width px-0 px-lg-2 has-action-footer">
      <Toast />
      {viewState !== "summary" && (
        <>
          <ModalMachineSearch
            show={showModal}
            handleHideModal={handleHideModal}
          />
          <Tabs
            justify
            fill
            id="uncontrolled-tab"
            activeKey={activeTab}
            onSelect={(key) => setActiveTab(key)}
            className="bg-white">
            <Tab
              eventKey="newOrderRows"
              tabClassName="w-100 bg-transparent"
              title={
                <div className="d-flex align-items-center justify-content-center">
                  {t("New")}
                  {newOrderRowsState.length > 0 && (
                    <span className="ml-2 badge bg-light">
                      {newOrderRowsState.length}
                    </span>
                  )}
                </div>
              }>
              <Accordion defaultActiveKey="0">
                {creditLimitState.hasCreditLimit &&
                  creditLimitState.value <= 0 && (
                    <div className="text-center p-4">
                      <i
                        className="fe fe-info d-block mb-2 text-primary"
                        style={{ fontSize: 50 }}></i>
                      <h3>{t("The customer lacks sufficient credit limit")}</h3>
                    </div>
                  )}

                {newOrderRowsState.length > 0 &&
                  newOrderRowsState.map((item, index) => {
                    let meta = [
                      item.reference.number,
                      `${item?.internalPrice} kr`
                    ]

                    if (item.priceUnit) {
                      meta[1] += `/${item.priceUnit}`
                    }

                    if (itemIsPreview) {
                      meta = previewItemMeta(item)
                    }

                    return (
                      <Item
                        key={item.orderRowId}
                        index={index}
                        title={item.reference.itemName}
                        meta={meta}
                        context={itemIsPreview ? "preview" : "accordion"}
                        item={item}
                      />
                    )
                  })}
              </Accordion>
            </Tab>
            <Tab
              eventKey="existingOrderRows"
              tabClassName="w-100 bg-transparent"
              title={
                <div className="d-flex align-items-center justify-content-center">
                  {t("Existing")}
                  <span className="ml-2 badge bg-light">
                    {existingOrderRowsState.length}
                  </span>
                </div>
              }>
              {existingOrderRowsState.length > 0 ? (
                existingOrderRowsState.map((item, index) => (
                  <Item
                    key={index}
                    index={index}
                    title={item.reference.itemName}
                    meta={[
                      item.reference?.number,
                      offerTypeMap(item?.orderRowType, "niceName"),
                      `${item.quantity} st`
                    ]}
                    item={item}
                  />
                ))
              ) : (
                <div className="p-4 text-center">
                  {t("No existing orders found")}
                </div>
              )}
            </Tab>

            <Tab
              eventKey="orderInfo"
              tabClassName="w-100 bg-transparent"
              title={
                <div className="d-flex align-items-center justify-content-center">
                  {t("Order info")}
                </div>
              }>
              <div className="p-4">
                <OrderInformation />
              </div>
            </Tab>
          </Tabs>
        </>
      )}
      {viewState === "summary" && (
        <>
          <ModalOrderCopies
            data={data}
            show={showOrderCopiesModal}
            setShowOrderCopiesModal={setShowOrderCopiesModal}
          />
          <OrderSummary data={data} />
        </>
      )}
      <ActionFooter>
        {showCreditLimitInfo && (
          <div className="badge bg-soft-danger d-block">
            {t("The customer lacks sufficient credit limit")}
          </div>
        )}

        {customerStatus === 1 && (
          <div className="badge bg-soft-danger d-block">
            {t(
              "The customer is blocked. The customer is not allowed to place orders"
            )}
          </div>
        )}

        {viewState === "viewOrder" &&
          !showCreditLimitInfo &&
          customerStatus !== 1 && (
            <>
              <button
                className={`border rounded text-primary bg-white p-0${
                  creditLimitState.hasCreditLimit && creditLimitState.value <= 0
                    ? " btn-disabled"
                    : ""
                }`}
                style={{ fontSize: 20, width: 40, height: 40 }}
                onClick={() => {
                  handleShowModal()
                  setModalViewState("machineSearch")
                }}
                disabled={
                  creditLimitState.hasCreditLimit && creditLimitState.value <= 0
                }>
                <CustomSvgIcon width={16} height={16} name="add" />
              </button>
              <button
                disabled={
                  newOrderRowsState.length === 0 ||
                  (creditLimitState.hasCreditLimit &&
                    creditLimitState.value <= 0)
                }
                className={`border rounded text-primary bg-white p-0 ml-3${
                  newOrderRowsState.length === 0 ||
                  (creditLimitState.hasCreditLimit &&
                    creditLimitState.value <= 0)
                    ? " btn-disabled"
                    : ""
                }`}
                style={{ fontSize: 20, width: 40, height: 40 }}
                onClick={() => setShowModalSheet(true)}>
                <CustomSvgIcon width={16} height={16} name="calender" />
              </button>
            </>
          )}

        {viewState !== "viewOrder" && (
          <button
            className="btn btn-outline-secondary mr-auto"
            disabled={loading}
            onClick={() => {
              if (viewState === "previewExistingOrder") {
                setViewState("viewOrder")
                setActiveTab("newOrderRows")
              } else {
                setShowOrderCopiesModal(true)
              }
            }}>
            {t(
              viewState === "previewExistingOrder"
                ? "Back"
                : "Send extra order copy"
            )}
          </button>
        )}

        <button
          className="btn btn-primary ml-auto"
          disabled={
            newOrderRowsState.length === 0 ||
            loading ||
            showCreditLimitInfo ||
            isMissingPrice
          }
          onClick={() => {
            if (viewState === "viewOrder") {
              setViewState("previewExistingOrder")
            } else if (viewState === "previewExistingOrder") {
              handleSave()
            } else {
              setViewState("createOrder")
              navigate("/order")
            }
          }}>
          {loading ? (
            <div
              className="spinner-grow spinner-grow-sm text-white position-relative"
              role="status"
              style={{ top: "-2px" }}>
              <span className="sr-only">Loading...</span>
            </div>
          ) : viewState === "viewOrder" ? (
            t("Preview")
          ) : viewState === "summary" ? (
            t("Close")
          ) : (
            t("Save")
          )}
        </button>
      </ActionFooter>
      <ModalSheet show={showModalSheet}>
        {viewState === "viewOrder" && (
          <ChangeDateForm setShowModalSheet={setShowModalSheet} />
        )}
        {viewState === "previewExistingOrder" && data && (
          <ValidationInfo data={data} setShowModalSheet={setShowModalSheet} />
        )}
      </ModalSheet>
    </div>
  )
}

export default Order
