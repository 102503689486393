import React, { useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Form, FormControl } from "react-bootstrap"
import { isMobile } from "react-device-detect"
import toast from "react-hot-toast"
import CustomSvgIcon from "../../../Common/CustomSvgIcon"

const SearchForm = ({
  searchText,
  setSearchText,
  onSubmitHandler,
  disabled,
  status,
  setStatus,
  error,
  setError,
  loading,
  dataAdded,
  inputRef
}) => {
  const toastDuration = 1000
  const animateDuration = 400
  const inputRefPlaceholder = useRef()
  const { t } = useTranslation()
  const [inputMode, setInputMode] = useState("numeric")

  const inputStyle = isMobile
    ? { paddingLeft: "25px", paddingRight: "25px" }
    : { paddingLeft: "0", paddingRight: "25px" }

  const handleInputChange = (event) => {
    setStatus(null)
    setError(null)
    setSearchText(event.target.value)
  }

  const handleClear = () => {
    setSearchText("")
    setStatus(null)
    setError(null)
    inputRef?.current?.focus()
  }

  useEffect(() => {
    if (status === "result") {
      setSearchText("")
    }

    if (status === "error") {
      toast.error(error, {
        duration: toastDuration
      })
    }

    if (status === "noResult") {
      toast.error(t("Can't find a machine with this number. Try again."), {
        duration: toastDuration
      })

      setTimeout(() => {
        if (inputRef?.current) {
          inputRefPlaceholder.current?.focus()
          inputRef.current?.focus()
        }
      }, animateDuration)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, inputRef, setSearchText, status, t])

  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current) {
        inputRefPlaceholder.current?.focus()
        inputRef.current?.focus()
      }
    }, animateDuration)
  }, [inputRef, dataAdded])

  return (
    <Form
      autoComplete="off"
      className="position-relative pb-3"
      onSubmit={(event) => {
        event.preventDefault()
        const searchInput = event?.target?.elements.searchDummie
        searchInput.focus()
        onSubmitHandler(searchText)
      }}>
      <div className="row">
        <div className="col-12">
          <div className="border-bottom border-gray-800 position-relative">
            <input
              type="text"
              name="searchDummie"
              ref={inputRefPlaceholder}
              inputMode="none"
              autoFocus={true}
              readOnly={true}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                pointerEvents: "none",
                width: "100%",
                height: 0,
                zIndex: -1,
                padding: 0,
                border: 0
              }}
            />
            <FormControl
              className={`form-control form-control-lg border-0${
                disabled ? " disabled" : ""
              }`}
              name="search"
              type="text"
              inputMode={inputMode}
              placeholder={t("Fill in the individual number")}
              ref={inputRef}
              onChange={handleInputChange}
              value={searchText}
              required
              enterKeyHint="send"
              autoCapitalize="none"
              autoComplete="off"
              spellCheck={false}
              style={inputStyle}
              autoCorrect="off"
              disabled={disabled}
              tabIndex={disabled ? -1 : 0}
              appearance="none"
            />
            {isMobile && (
              <button
                className="bg-transparent text-primary position-absolute pl-0"
                style={{ top: "50%", left: 0, transform: "translateY(-50%)" }}
                type="button"
                onClick={() => {
                  setInputMode(inputMode === "numeric" ? "search" : "numeric")
                }}>
                <i
                  className={`fe fe-${
                    inputMode === "numeric" ? "hash" : "type"
                  }`}
                  style={{ fontSize: 20 }}></i>
              </button>
            )}
            {error || status === "noResult" ? (
              <button
                disabled={disabled}
                className="btn btn-light btn-sm btn-rounded-circle text-body mx-2 position-absolute"
                style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}
                onClick={handleClear}>
                <CustomSvgIcon
                  name="close"
                  width={16}
                  height={16}
                  className="text-black"
                  style={{
                    top: 3
                  }}
                />
              </button>
            ) : (
              <button
                type="submit"
                className={`btn btn-sm btn-rounded-circle mx-2 position-absolute${
                  dataAdded ? " btn-success" : " btn-primary"
                }`}
                style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
                {loading && (
                  <div
                    className="spinner-border spinner-border-sm text-white"
                    role="status"></div>
                )}

                {dataAdded && !loading && <i className="fe fe-check"></i>}

                {!dataAdded && !loading && (
                  <CustomSvgIcon
                    name="arrow-up"
                    width={16}
                    height={16}
                    className="text-white"
                    style={{
                      top: 3
                    }}
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </Form>
  )
}

export default SearchForm
