import { usePermissionCheck } from "../../../utils/hooks"
import PageNotFound from "../../PageNotFound/PageNotFound"

import MachineLists from "../../../components/__Customer/MachineLists/MachineLists"

export default function CustomerMachineLists() {
  const hasHllInternalPermission = usePermissionCheck("MinaSidor.SkapaOrder")

  if (hasHllInternalPermission) {
    return <PageNotFound noPermission={true} />
  }

  return <MachineLists />
}
