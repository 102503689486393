import React from "react"
import { useTranslation } from "react-i18next"
import NavItem from "../NavItemSub"
import { usePermissionCheck } from "../../../utils/hooks"

import { ReactComponent as ChevronLeftIcon } from "../../../assets/icons/chevron-left.svg"

export default function CompanyProfile({ show, close, handleCloseNav }) {
  const { t } = useTranslation()

  //Billing method Read Permissons
  const hasInvoiceReadingPermission = usePermissionCheck(
    "MinaSidor.FakturametodLäs"
  )

  //DocumentRecipients Read Permissons
  const hasDocumentRecipientsReadPermission = usePermissionCheck(
    "MinaSidor.DokumentmottagareLäs"
  )

  return (
    <div
      className={`nav-off-canvas-body secondary company-profile py-4 js-app-off-canvas${
        show ? " company-profile-secondary-is-active" : ""
      }`}>
      <div className="d-flex">
        <div>
          <h3>
            <button className="goBack text-body" onClick={close}>
              <span
                className={`d-block svg-icon-wrapper text-primary text-black`}
                style={{ width: 16, height: 16 }}>
                <ChevronLeftIcon />
              </span>
            </button>
          </h3>
        </div>
        <div className="pl-4">
          <h3>{t("Company Profile")}</h3>
          <ul className="nav-off-canvas-nav-list m-0">
            <li>
              <NavItem
                text={t("Company information")}
                to={`/${t("company-profile")}`}
                clickHandler={handleCloseNav}
              />
            </li>

            {hasInvoiceReadingPermission && (
              <li>
                <NavItem
                  text={t("Fakturering")}
                  to={`/${t("company-profile")}/${t("billing")}`}
                  clickHandler={handleCloseNav}
                />
              </li>
            )}

            {hasDocumentRecipientsReadPermission && (
              <li>
                <NavItem
                  text={t("Document recipients")}
                  to={`/${t("company-profile")}/${t("document-recipients")}`}
                  clickHandler={handleCloseNav}
                />
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}
