import React from "react"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"

import { machineStatus } from "../../../../utils/helpers"

export default function List({ name, number, status }) {
  const { t } = useTranslation()
  return (
    <motion.div
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 50, opacity: 0 }}>
      <div className="d-block w-100 p-3 bg-white machine-item">
        <div className="d-block font-weight-bold text-left">
          {number ? number : t("No number")}
          {typeof status !== "undefined" && status < 0 && (
            <small className="font-weight-normal">{` ${machineStatus(
              status
            )}`}</small>
          )}
        </div>
        <div className="d-block text-muted text-gray-700 text-left">
          {name ? name : t("No name")}
        </div>
      </div>
    </motion.div>
  )
}
