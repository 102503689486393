import React from "react"
import uuid from "react-uuid"

function AccessoryItem({ item, quantity, onCheckedHandler, isPreSelected }) {
  return (
    <div className="pb-3 mb-3 border-bottom" style={{ cursor: "pointer" }}>
      <label
        htmlFor={item.id}
        className="d-block"
        style={{ cursor: "pointer" }}>
        <div className="d-flex items-center justify-content-between">
          <strong className="pr-5">{item.name}</strong>
          <div>
            <input
              id={item.id}
              type="checkbox"
              className="form-check-input list-checkbox list-checkbox-theme"
              value={JSON.stringify(item)}
              defaultChecked={isPreSelected}
              onChange={({ target: { checked, value } }) => {
                const { offers } = item
                const defaultOfferObj =
                  offers.length !== 0 &&
                  offers.filter((offer) => offer.isDefault === true)

                //Checking orderRowType
                let orderRowType = defaultOfferObj ? defaultOfferObj[0].type : 0
                if (orderRowType === 0 || orderRowType === 6) {
                  orderRowType = 1
                }

                if (orderRowType === 5) {
                  orderRowType = 0
                }

                onCheckedHandler(checked, {
                  internalId: uuid(),
                  id: item.id,
                  articleId: item.articleId,
                  name: item.name,
                  data: value,
                  internalType: "ARTICLE",
                  isNew: true,
                  quantity: quantity,
                  description: "",
                  orderRowType: orderRowType,
                  price: defaultOfferObj ? defaultOfferObj[0].price : 0,
                  agreedPrice: {
                    amount: 0,
                    type: "kr"
                  },
                  debitType: defaultOfferObj
                    ? defaultOfferObj[0].debitType
                    : null,
                  offerType: defaultOfferObj ? defaultOfferObj : null,
                  item,
                  newName: item?.newName ?? ""
                })
              }}
            />
          </div>
        </div>
        <div className="bg-light rounded border border-light border-1 mt-3">
          <span className="d-block text-muted text-gray-700 py-2 px-3 text-left">
            {item.articleNumber}
          </span>
        </div>
      </label>
    </div>
  )
}

export default AccessoryItem
