import axios from "axios"

const CustomerRentalContacts = async (id, setError) => {
  let data = []

  await axios
    .post("/api/hll/customerRentalContacts", {
      customerId: id
    })
    .then((response) => {
      data = response?.data ? response.data : []
    })
    .catch((err) => {
      console.log(err, "err")
      setError(true)
      data = err
    })

  return data
}

export default CustomerRentalContacts
