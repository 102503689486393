import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useOidcUser } from "@axa-fr/react-oidc"
import axios from "axios"

export default function ControlValidation({
  setShowModalSheet,
  item,
  handleUpdateStore,
  handleCancel = () => {}
}) {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleControledFleetItem = async () => {
    setLoading(true)

    const date = new Date()
    const createdAt = `${date.toLocaleDateString(
      "sv-se"
    )}T${date.toLocaleTimeString("sv-se")}Z`
    const createdBy = oidcUser?.profile?.name

    await axios
      .post("/api/hll/inspectionNote", {
        id: item.id,
        body: {
          createdAt,
          createdBy,
          inspectionInfo:
            "Mitt HLL: Kontrollen gjordes i samband med en uthyrning"
        }
      })
      .then(async (response) => {
        if (response.data && response.status === 200) {
          await axios
            .post("/api/hll/fleetItemEdit", {
              body: {
                id: item?.id,
                fleetModelId: item?.fleetModelId,
                name: item?.name,
                number: item?.number,
                branchId: item?.branchId,
                status: item?.status,
                branchName: item?.branchName,
                availability: 1
              }
            })
            .then((response) => {
              if (response.data && response.status === 200) {
                // console.log(response.data)
                setShowModalSheet(false)
                handleUpdateStore(item)
              } else {
                setError({
                  hasError: true,
                  errorMessage: t("Something went wrong")
                })
              }
            })
            .catch((error) => {
              console.error(error)
              setError({
                hasError: true,
                errorMessage: `Error code: ${error.response.data.CorrelationId}`
              })
            })
            .finally(() => {
              setLoading(false)
            })
        } else {
          setLoading(false)
          setError({
            hasError: true,
            errorMessage: t("Something went wrong")
          })
        }
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
        setError({
          hasError: true,
          errorMessage: t("Something went wrong")
        })
      })
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div
            className={`d-flex align-items-center alert alert-inspection--warning text-white p-2 font-xs mb-2`}
            role="alert">
            <span className="fe fe-alert-triangle mr-2"></span>
            {t("This machine need to be checked according to the system")}
          </div>
        </div>
      </div>
      {error?.hasError && (
        <p className="mt-3 text-danger">{error?.errorMessage}</p>
      )}
      <div className="d-flex align-items-center mt-3">
        <button
          className="btn btn-outline-secondary w-100 d-block mr-3"
          onClick={() => {
            handleCancel(item?.id)
            setError(null)
            setShowModalSheet(false)
          }}>
          {t("Cancel")}
        </button>
        <button
          className="btn btn-primary w-100 d-block"
          disabled={loading || error}
          onClick={() => handleControledFleetItem()}>
          {loading ? (
            <div
              className="spinner-border spinner-border-sm text-white"
              role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            t("Continue")
          )}
        </button>
      </div>
    </>
  )
}
