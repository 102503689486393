import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

export default function CategoryCard({ title, image, ancestors }) {
  const { t } = useTranslation()

  const ancestorPaths =
    ancestors &&
    ancestors.length > 0 &&
    ancestors.map((ancestor) => ancestor.slug)

  const urlPaths = ancestorPaths && ancestorPaths.join("/")

  return (
    <article className="category-card rounded p-4">
      <header>
        <h2 className="hyphenate">
          <Link
            className="is-clickable text-black"
            to={{
              pathname: `/${t("customer")}/${t("machines")}/${urlPaths}`
            }}>
            {title}
          </Link>
        </h2>
      </header>

      <figure className="category-card-image-frame d-flex justify-content-center mx-auto my-4">
        {image && (
          <img
            src={image}
            alt={title ? title : ""}
            className="category-card-image img-fluid rounded"
          />
        )}
      </figure>

      <footer>
        <div className="d-flex align-items-center justify-content-start justify-content-lg-end">
          <span className="mr-2">{t("Show machines")}</span>
          <div className="svg-icon-wrapper text-black ml-3">
            <span
              className="d-inline-block svg-icon-wrapper "
              style={{ width: "20px", height: "20px" }}>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_25_1286)">
                  <path
                    d="M16.9584 6.87231L21.5417 11.4556L16.9584 16.039"
                    stroke="#EB6400"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"></path>
                  <path
                    d="M21.5417 11.4556H0.458374"
                    stroke="#EB6400"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"></path>
                </g>
                <defs>
                  <clipPath id="clip0_25_1286">
                    <rect width="22" height="22" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
        </div>
      </footer>
    </article>
  )
}
