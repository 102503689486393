import React, { useState } from "react"

import axios from "axios"
import { useForm } from "react-hook-form"
import { billingAddressFormTemplate } from "./FormTemplate"

import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Loader from "../../../Loader/Loader"
import toast from "react-hot-toast"
import CustomSvgIcon from "../../../Common/CustomSvgIcon"

export default function BillingAddressModal({
  show,
  handleHideModal,
  billingMethod,
  setCustomerBillingMethod,
  customerID
}) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const { fields } = billingAddressFormTemplate

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    shouldUnregister: true,
    mode: "all"
  })

  const onSubmit = async (data) => {
    let formattedData = {}

    setErrorMsg("")
    setLoading(true)
    formattedData = {
      type: parseInt(data?.type),
      pdfInvoiceReceiverAddress: data?.pdfInvoiceReceiverAddress,
      billingAddress: {
        adressLine2: data?.adressLine2,
        adressLine3: data?.adressLine3,
        postalCode: data?.postalCode,
        city: data?.city
        //country: data?.country
      }
    }

    await axios
      .post("/api/hll/updateCustomerBillingMethod", {
        customerID: customerID,
        body: formattedData
      })
      .then((response) => {
        handleHideModal()
        setCustomerBillingMethod(response?.data)
        toast.success(t("Billing address is updated"))
      })
      .catch((err) => {
        console.warn(err, "Error: /api/hll/updateCustomerBillingMethod")
        setErrorMsg(t("Something went wrong, try again."))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const renderFields = (fields, billingMethod) => {
    return fields.map((field) => {
      const {
        title,
        type,
        name,
        instructionType,
        instructions,
        fieldClassName,
        required,
        disabled,
        readOnly
      } = field

      const billingAddress = billingMethod?.billingAddress

      let className =
        typeof fieldClassName !== "undefined"
          ? fieldClassName
          : "col-12 col-md-6 mb-3"

      let defaultValue = billingAddress[name]

      if (name === "type" || name === "pdfInvoiceReceiverAddress") {
        defaultValue = billingMethod[name]
      }

      switch (type) {
        case "hidden":
          return (
            <input
              key={name}
              type="hidden"
              name={name}
              defaultValue={defaultValue}
              {...register(name)}
            />
          )
        case "text":
        case "email":
          return (
            <div className={className} key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {required && "*"}
              </label>
              <input
                type={type}
                id={name}
                name={name}
                disabled={disabled}
                readOnly={readOnly}
                defaultValue={defaultValue}
                className={`form-control form-control-modal rounded-0${
                  disabled || readOnly ? " disabled" : ""
                }`}
                {...register(name, { required })}
              />
              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}
              {instructions && (
                <div
                  className={`mt-3 mb-0${
                    instructionType === "alert" ? " alert alert-warning" : ""
                  }`}>
                  <span
                    className={`${
                      instructionType === "alert" ? "" : "text-muted font-xs"
                    }`}>
                    {instructions}
                  </span>
                </div>
              )}
            </div>
          )

        case "textField":
          return (
            <div className={className} key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
              </label>
              <div id={name} className="text-read-only-box">
                defaultValue
              </div>
              {instructions && (
                <span className="text-muted font-xs mt-2">{instructions}</span>
              )}
            </div>
          )

        default:
          return null
      }
    })
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        handleHideModal()
      }}
      centered={true}
      backdrop="static"
      animation={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header className="d-block p-4 bkg-gray-100">
          <div className="position-relative">
            <h3 className="header-title mb-0 text-center">
              {t("Change billing address")}
            </h3>
            <button
              onClick={() => handleHideModal()}
              className="bg-transparent position-absolute p-0 text-body"
              style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
              <CustomSvgIcon
                name="close"
                width={16}
                height={16}
                className="text-black"
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center p-4 bg-white is-scrollable mx-0">
          {loading && <Loader />}
          {!loading && (
            <div className="row">{renderFields(fields, billingMethod)}</div>
          )}
          {errorMsg !== "" && <p className="text-danger">{errorMsg}</p>}
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top radius-1">
          <button
            disabled={loading}
            className="btn btn-outline-secondary"
            onClick={() => handleHideModal()}>
            {t("Cancel")}
          </button>
          <button type="submit" disabled={loading} className="btn btn-primary">
            {t("Save")}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
