import React from "react"
import { useOidcUser, useOidc } from "@axa-fr/react-oidc"
import { useTranslation } from "react-i18next"
import Logo from "../../assets/images/HLL_notext.svg"
import Storage from "../../utils/storage"
import { useLocation, useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import { useOidcIdToken } from "@axa-fr/react-oidc"
import {
  inventory,
  newInventory,
  inventoryView,
  newInventoryFind,
  inventoryAdministration,
  inventoryAdministrationCreate,
  inventoryAdministrationEdit
} from "./Store"

import NavAccount from "../Header/NavAccount"
import InventoryHeaderTitle from "./InventoryHeaderTitle"
import CustomSvgIcon from "../Common/CustomSvgIcon"

export default function InventoryHeader({ handleModal }) {
  const { t } = useTranslation()
  const { oidcUser } = useOidcUser()
  const { idToken } = useOidcIdToken()
  const { logout } = useOidc()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const userState = Storage.get("userState") ?? null
  const [viewState, setViewState] = useRecoilState(inventoryView)
  const [inventoryState, setInventoryState] = useRecoilState(inventory)
  const newInventoryState = useRecoilValue(newInventory)
  const newInventoryFindState = useRecoilValue(newInventoryFind)
  const inventoryAdministrationState = useRecoilValue(inventoryAdministration)
  const inventoryAdministrationCreateState = useRecoilValue(
    inventoryAdministrationCreate
  )

  const inventoryAdministrationEditState = useRecoilValue(
    inventoryAdministrationEdit
  )

  const perm = oidcUser?.perm
  const sub = oidcUser?.sub
  const isSubPaths =
    pathname === "/inventory/find" ||
    pathname.includes("/inventering/hitta") ||
    pathname.includes("/inventering/")

  const logoutCallback = React.useCallback(async () => {
    const logoutUrl = `${process.env.REACT_APP_AUTH_URL}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.REACT_APP_PUBLIC_URL}`
    await logout()
    document.location.href = logoutUrl
  }, [idToken, logout])

  const handleLogOut = () => {
    Storage.remove("userGroups")
    Storage.remove("customer", "local")
    Storage.remove("subsidiaries", "local")
    Storage.remove("userState", "local")
    Storage.remove("internalCompanies", "local")
    Storage.remove("internalRegions", "local")
    Storage.remove("depots", "local")
    logoutCallback()
  }

  const handleGoBack = () => {
    if (
      viewState === "inventoryAdministrationCreate" ||
      viewState === "inventoryAdministrationEdit"
    ) {
      setViewState("inventoryAdministration")
      navigate(`/${t("inventory")}/${t("administration")}`)
    } else {
      setViewState("initInventory")
      setInventoryState((previousInventoryState) => {
        return {
          ...previousInventoryState,
          pageHeader: t("Inventory"),
          depot: userState?.internal?.depot?.name ?? null
        }
      })
      navigate(`/${t("inventory")}`)
    }
  }

  return (
    <div className="app-header header bg-dark-theme shadow js-app-header">
      <div className="container-fluid container-fluid-max-width d-flex align-items-center justify-content-between h-100 py-1">
        {isSubPaths ? (
          <button
            className="border-0 bg-secondary rounded position-relative d-flex align-items-center btn-go-back justify-content-center"
            onClick={() => handleGoBack()}>
            <span className="d-flex align-items-center p-2">
              <CustomSvgIcon
                width={26}
                height={26}
                name="chevron-left"
                className="text-white"
              />
            </span>
          </button>
        ) : (
          <button
            style={{ border: 0, background: "transparent", outline: "none" }}
            className="d-flex align-items-center ml-0"
            aria-label={t("Inventory")}
            onClick={() => handleGoBack()}>
            <img src={Logo} alt="Hyreslandslagets logotyp" className="logo" />
          </button>
        )}

        {viewState === "initInventory" && (
          <InventoryHeaderTitle
            title={inventoryState ? inventoryState?.pageHeader : t("Inventory")}
            subTitle={inventoryState?.depot?.name}
          />
        )}

        {viewState === "newInventory" && (
          <InventoryHeaderTitle
            title={
              newInventoryState ? newInventoryState?.pageHeader : t("Inventory")
            }
            subTitle={newInventoryState?.depot?.name}
          />
        )}

        {viewState === "findInventory" && (
          <InventoryHeaderTitle
            title={
              newInventoryFindState
                ? newInventoryFindState?.pageHeader
                : t("Find machines")
            }
            subTitle={null}
          />
        )}

        {viewState === "inventoryAdministration" && (
          <InventoryHeaderTitle
            title={t("Inventory")}
            subTitle={
              inventoryAdministrationState
                ? inventoryAdministrationState?.pageHeader
                : ""
            }
          />
        )}

        {viewState === "inventoryAdministrationEdit" && (
          <InventoryHeaderTitle
            title={
              inventoryAdministrationEditState
                ? inventoryAdministrationEditState?.pageHeader
                : ""
            }
            subTitle={
              inventoryAdministrationEditState
                ? inventoryAdministrationEditState?.inventory?.name
                : ""
            }
          />
        )}

        {viewState === "inventoryAdministrationCreate" && (
          <InventoryHeaderTitle
            title={
              inventoryAdministrationCreateState
                ? inventoryAdministrationCreateState?.pageHeader
                : ""
            }
            subTitle={
              inventoryAdministrationCreateState
                ? inventoryAdministrationCreateState?.inventory?.name
                : ""
            }
          />
        )}

        <NavAccount
          perm={perm}
          profile={oidcUser}
          sub={sub}
          handleLogOut={handleLogOut}
          oidcUser={oidcUser}
          handleModal={handleModal}
        />
      </div>
    </div>
  )
}
