import React, { useState } from "react"
import { useAdminPermissionCheck } from "../../utils/hooks"
import { useTranslation } from "react-i18next"
//import { useQuery } from "@tanstack/react-query"

import PageNotFound from "../PageNotFound/PageNotFound"
import PageHeader from "../../components/Common/PageHeader"
import Settings from "../../components/Admin/Settings/Settings"

export default function AdminPage() {
  const { t } = useTranslation()
  const hasAdminPermission = useAdminPermissionCheck()
  const [settingState, setSettingState] = useState([])

  // fetch admin settings
  // useQuery({
  //   queryKey: ["adminSettings"],
  //   queryFn: async () => {
  //     const res = await fetch("/api/database/settings")
  //     const data = await res.json()
  //     setSettingState(data)
  //     return data
  //   },
  //   refetchIntervalInBackground: true,
  //   refetchOnWindowFocus: true
  // })

  const handleSettingState = async (data) => {
    setSettingState((oldSettingState) => {
      return {
        ...oldSettingState,
        data
      }
    })
    // await axios
    //   .post("/api/database/settings/update", data)
    //   .then((res) => {
    //     console.log(res, "res")
    //   })
    //   .catch((err) => {
    //     console.log(err, "err")
    //   })
  }

  if (!hasAdminPermission) {
    return <PageNotFound noPermission={true} />
  }

  return (
    <div className="container-fluid container-fluid-max-width">
      <PageHeader preTitle={t("Admin")} title={t("Settings")} />
      <Settings
        settings={settingState}
        handleSettingState={handleSettingState}
      />
    </div>
  )
}
