import React from "react"
import { useTranslation } from "react-i18next"

const HeaderTitle = ({ depot, pathname }) => {
  const { t } = useTranslation()

  if (pathname !== "/maskinflytt" && pathname !== "/inkommande-maskiner") {
    return null
  }

  return (
    <div
      className="header-main-title text-center position-absolute"
      style={{
        maxWidth: "50%"
      }}>
      <strong className="d-block">
        {pathname === "/maskinflytt"
          ? t("Machine transfer")
          : t("Incoming machines")}
      </strong>
      {depot && <span className="d-block text-truncate">{depot && depot}</span>}
    </div>
  )
}

export default HeaderTitle
