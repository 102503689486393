import React, { useEffect, useState } from "react"
import axios from "axios"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useRecoilState, useRecoilValue } from "recoil"
import { newOrderRows, modalView, orderInformation } from "../Store"
import Storage from "../../../utils/storage"
import SearchForm from "../Form/SearchForm"
import Loader from "../../Loader/Loader"
import Alert from "../../Common/Alert"
import SearchMachine from "../Common/Queries/SearchMachine"
import Item from "../Common/Item"
import ActionFooter from "../../Common/ActionFooter"
import ModalView from "./ModalView"
import { removeItemAtIndex, replaceItemAtIndex } from "../../../utils/helpers"
import { hasUpcomingInspections, mapOrderRow } from "../helpers"
import MachineInspection from "../../Machines/MachineItem/Steps/MachineInspections"
import ModalSheet from "../../Common/ModalSheet"
import ControlValidation from "../../Common/ControlValidation"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const ModalMachineSearch = ({ show, handleHideModal }) => {
  const { t } = useTranslation()
  const userState = Storage.get("userState") ?? null
  const regionId = userState?.internal?.region?.regionId ?? 3
  const internalCompanyId = userState?.internal?.region?.internalCompanyId ?? 3
  const [showModalSheet, setShowModalSheet] = useState(false)
  const [controlValidation, setControlValidation] = useState(false)
  const [controlValidationItem, setControlValidationItem] = useState(null)
  const [data, setData] = useState([])
  const [accessoryData, setAccessoryData] = useState([])
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState("")
  const [isSearched, setIsSearched] = useState(false)
  const [inputText, setInputText] = useState("")
  const [checked, setChecked] = useState([])
  const [newOrderRowsState, setNewOrderRowsState] = useRecoilState(newOrderRows)
  const [modalViewState, setModalViewState] = useRecoilState(modalView)
  const [subTitle, setSubTitle] = useState("")
  const [modalLoader, setModalLoader] = useState(false)
  const [inspectionData, setInspectionData] = useState(null)
  const [inspectionModalOpen, setInspectionModalOpen] = useState(false)
  const orderInformationState = useRecoilValue(orderInformation)
  const customerOrderId = orderInformationState?.customerOrderId

  const handleInputChange = (event) => {
    const value = event.target.value

    setInputText(value)
  }

  const handleSearchSubmit = async (event) => {
    const inputValue = event.target?.elements?.search?.value
    setIsSearched(false)
    setChecked([])
    try {
      const res = await SearchMachine(
        t,
        inputValue,
        customerOrderId,
        userState,
        regionId,
        setLoading,
        setError
      )

      setIsSearched(true)
      setData(res)
    } catch (err) {
      //console.log(err)
      setError(
        `${t("Something went wrong")}: ${err?.response?.data?.CorrelationId}`
      )
    }
  }

  const handleClick = async (item) => {
    if (item?.availability && item?.availability !== 1) {
      setControlValidation(true)
      setShowModalSheet(true)
      setControlValidationItem(item)
    } else {
      handleUpdateStore(item)
    }
  }

  const handleUpdateStore = async (item) => {
    const compareId = item.isMisc ? "orderRowId" : "id"

    const index = newOrderRowsState.findIndex(
      (row) => row[compareId] === item[compareId]
    )

    let mappedItem = mapOrderRow(
      item,
      item.internalType ?? "ACCESSORY",
      item?.offers ?? []
    )

    if (index < 0) {
      if (!item?.internalType || item.internalType === "ACCESSORY") {
        setNewOrderRowsState((oldOrderRows) => [...oldOrderRows, mappedItem])
        return
      }

      const { fleetModelId } = item

      setModalLoader(true)

      if (item.internalType === "INDIVIDUAL") {
        await axios.post("/api/hll/fleetItemLock", {
          id: item.id
        })
      }

      await axios
        .post("/api/hll/fleetModels", {
          fleetModelId,
          regionId
        })
        .then((response) => {
          if (response.data) {
            const findIndex = newOrderRowsState.findIndex(
              (row) => row[compareId] === item[compareId]
            )

            const orderRow = mapOrderRow(
              item,
              item.internalType,
              response.data?.item?.offers ?? []
            )

            if (findIndex > -1) {
              const updatedOrderRow = replaceItemAtIndex(
                newOrderRowsState,
                findIndex,
                orderRow
              )
              setNewOrderRowsState(updatedOrderRow)
            } else {
              setNewOrderRowsState((oldOrderRows) => [
                ...oldOrderRows,
                orderRow
              ])
            }
          }
        })

      await axios
        .post("/api/hll/accessoryQuery", {
          regionId: regionId,
          internalCompanyId: internalCompanyId,
          body: {
            articleId: {
              logicalOperator: "Or",
              predicate: "Equal",
              value: item.id
            },
            fleetItemId: {
              logicalOperator: "Or",
              predicate: "Equal",
              value: item.id
            },
            queryContext: {
              logicalOperator: "And",
              predicate: "Equal",
              value: "Order"
            }
          }
        })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setAccessoryData(response.data)
            setModalViewState("machineAccessories")
            setSubTitle(item.name)

            // Handle preselected item
            response.data.forEach((item) => {
              if (item.isPreSelected) {
                const itemIndex = newOrderRowsState.findIndex(
                  (row) => row.id === item.item.id
                )

                if (itemIndex < 0) {
                  setNewOrderRowsState((oldOrderRows) => [
                    ...oldOrderRows,
                    mapOrderRow(
                      item.item,
                      "ACCESSORY",
                      item.item.offers ?? [],
                      item.quantity
                    )
                  ])
                }
              }
            })
          }
        })
        .finally(() => {
          setModalLoader(false)

          if (
            item.internalType === "INDIVIDUAL" &&
            hasUpcomingInspections(item)
          ) {
            setInspectionData(item)
            setInspectionModalOpen(true)
          }
        })
    } else {
      if (item.isMisc) {
        return
      }

      const updateOrderRow = removeItemAtIndex(newOrderRowsState, index)
      setNewOrderRowsState(updateOrderRow)

      if (item.internalType === "INDIVIDUAL") {
        await axios.post("/api/hll/deleteFleetItemLock", {
          id: item.id
        })
      }
    }
  }

  const onModalHide = () => {
    handleHideModal()
    setData([])
    setAccessoryData([])
    setInputText("")
    setChecked([])
    setInspectionData(null)
    setInspectionModalOpen(false)
    setIsSearched(false)
  }

  useEffect(() => {
    setChecked(newOrderRowsState)
  }, [newOrderRowsState])

  const handleInspectionModalHide = async (clear = false) => {
    setInspectionData(null)
    setInspectionModalOpen(false)

    if (clear) {
      const orderRows = newOrderRowsState
      const updatedOrderRows = orderRows.filter((row) => !checked.includes(row))

      setNewOrderRowsState(updatedOrderRows)

      if (inspectionData.internalType === "INDIVIDUAL") {
        await axios.post("/api/hll/deleteFleetItemLock", {
          id: inspectionData.id
        })
      }

      onModalHide()
    }
  }

  return (
    <>
      {inspectionData && inspectionData?.inspection.length !== 0 && (
        <Modal
          show={inspectionModalOpen}
          onHide={() => handleInspectionModalHide(true)}
          className={`modal-md`}
          animation={false}
          fullscreen="true"
          backdropClassName="backdrop-newrental-inspection">
          <Modal.Header>
            <h3 className="header-title mt-3 mb-4 text-center">
              {t("Inspection is approaching")}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <MachineInspection inspection={inspectionData.inspection} />
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex align-items-center justify-content-between flex-row-reverse">
              <button
                className="btn btn-primary w-100 d-block"
                onClick={() => handleInspectionModalHide(false)}>
                {t("Continue")}
              </button>
              <button
                className="btn btn-outline-secondary w-100 d-block mr-3"
                onClick={() => handleInspectionModalHide(true)}>
                {t("Cancel")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      <Modal
        show={show}
        fullscreen="true"
        onHide={() => {
          handleHideModal()
          setData([])
          setAccessoryData([])
        }}
        backdrop="static"
        animation={false}
        className={`modal-scrollable${showModalSheet ? " block-scroll" : ""}`}>
        <Modal.Header className="d-block p-3">
          <div className="position-relative">
            {modalViewState === "machineAccessories" && (
              <button
                className="bg-transparent position-absolute p-0 text-body"
                style={{ top: "50%", left: 0, transform: "translateY(-50%)" }}
                onClick={() => {
                  setModalViewState("machineSearch")
                  setAccessoryData([])
                }}>
                <CustomSvgIcon
                  width={16}
                  height={16}
                  name="chevron-left"
                  className="text-white"
                />
              </button>
            )}

            <h3 className="header-title mt-3 mb-0 text-center">
              {modalViewState === "machineAccessories" && t("Select addons")}
              {modalViewState === "machineSearch" &&
                t("Search Machine/article")}
            </h3>
            {subTitle && modalViewState === "machineAccessories" && (
              <p className="mt-3 mb-0 text-center">{subTitle}</p>
            )}

            <button
              onClick={onModalHide}
              className="bg-transparent position-absolute p-0 text-body"
              style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
              <CustomSvgIcon
                name="close"
                width={16}
                height={16}
                className="text-black"
              />
            </button>
          </div>
          {modalViewState !== "machineAccessories" && (
            <div className="mt-4">
              <SearchForm
                handleSearchSubmit={handleSearchSubmit}
                inputText={inputText}
                handleInputChange={handleInputChange}
                disabled={showModalSheet}
              />
            </div>
          )}
        </Modal.Header>
        <Modal.Body
          className={`text-center p-0 bg-white is-scrollable mx-0 pb-5${
            modalLoader ? " modal-disabled" : ""
          }`}>
          {modalViewState === "machineSearch" && (
            <ModalView>
              <>
                {loading && !error && <Loader className="my-4" />}
                {!loading && error && (
                  <Alert
                    className="mt-3"
                    message={t(
                      "Something went wrong, please refresh current page."
                    )}
                  />
                )}

                {!loading && isSearched && data.length === 0 && (
                  <Alert className="mt-3" message={t("Can't find anything")} />
                )}

                {!loading &&
                  !error &&
                  data.length > 0 &&
                  data.map((item, index) => {
                    return (
                      <Item
                        key={index}
                        context="machine"
                        index={index}
                        title={item.name}
                        meta={[item.number]}
                        handleClick={handleClick}
                        item={item}
                        showModalSheet={showModalSheet}
                      />
                    )
                  })}
              </>
            </ModalView>
          )}
          {modalViewState === "machineAccessories" && (
            <ModalView>
              {accessoryData.length > 0 &&
                accessoryData.map((item, index) => {
                  return (
                    <Item
                      key={index}
                      context="machine"
                      index={index}
                      title={item.item.name}
                      handleClick={handleClick}
                      item={item.item}
                      meta={[item.item.articleNumber]}
                    />
                  )
                })}
            </ModalView>
          )}
        </Modal.Body>

        <Modal.Footer>
          <ActionFooter>
            <button
              className="btn btn-primary w-100 d-block"
              type="button"
              disabled={checked.length === 0}
              onClick={onModalHide}>
              {t("Add to order")}
            </button>
          </ActionFooter>
        </Modal.Footer>
      </Modal>

      <ModalSheet show={showModalSheet}>
        {modalViewState === "machineSearch" && controlValidation && (
          <ControlValidation
            setShowModalSheet={setShowModalSheet}
            item={controlValidationItem}
            handleUpdateStore={handleUpdateStore}
          />
        )}
      </ModalSheet>
    </>
  )
}

export default ModalMachineSearch
