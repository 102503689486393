import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { motion } from "framer-motion"
import Loader from "../Loader/Loader"
import Alert from "react-bootstrap/Alert"
import CustomSvgIcon from "../Common/CustomSvgIcon"

export default function InventoryFind({ loading, data, inventoryReady }) {
  const { t } = useTranslation()
  return (
    <div className="row mt-5">
      <div className="col-12">
        <h1 className="h2">{t("Help find individuals")}</h1>
      </div>

      {loading && <Loader className="mt-3" />}

      {data && data.length === 0 && !loading && inventoryReady && (
        <div className="col-12">
          <Alert variant="warning" className="text-primary">{`${t(
            "Right now there are no missing machines"
          )}.`}</Alert>
        </div>
      )}

      {!loading &&
        data &&
        data.length > 0 &&
        data.filter((item) => item.status === 3).length > 0 &&
        inventoryReady && (
          <div className="col-12">
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}>
              <div className="card">
                <Link
                  to={`/${t("inventory")}/${t("find")}`}
                  className="text-body">
                  <div className="card-body">
                    <div className="align-items-center row">
                      <div className="col">
                        <h3 className="mb-0">
                          {t("Go to list of missing machines")}
                        </h3>
                      </div>
                      <div className="col-auto">
                        <CustomSvgIcon
                          name="chevron-right"
                          width={20}
                          height={20}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </motion.div>
          </div>
        )}
    </div>
  )
}
