import React, { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import Storage from "../../utils/storage"
import ModalSheet from "../../components/Common/ModalSheet"
import axios from "axios"
import Overlay from "../../components/Inventory/Common/Overlay"
import { setUserSettings } from "../../utils/hooks"
import { inventoryView } from "../../components/Inventory/Store"
import { useSetRecoilState } from "recoil"
import { inventory, newInventory } from "../../components/Inventory/Store"
import { usePermissionCheck } from "../../utils/hooks"
import NewInventories from "../../components/Inventory/NewInventories"
import PageNotFound from "../PageNotFound/PageNotFound"
import InventoryFind from "../../components/Inventory/InventoryFind"
import ActionFooter from "../../components/Common/ActionFooter"
import { useNavigate } from "react-router-dom"
import CustomSvgIcon from "../../components/Common/CustomSvgIcon"

export default function Inventory() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userState = Storage.get("userState") ?? null
  const depotStorage = Storage.get("depots", null, "local") ?? null
  const internalRegionsStorage =
    Storage.get("internalRegions", null, "local") ?? []
  const depotsStorage = Storage.get("depots", null, "local") ?? []

  const hasInventoryPermission = usePermissionCheck("MinaSidor.Inventering")
  const hasInventoryAdminPermission = usePermissionCheck(
    "MinaSidor.inventeringAdministrera"
  )

  const setViewState = useSetRecoilState(inventoryView)
  const setCurrentInventoryState = useSetRecoilState(inventory)
  const setNewInventoryState = useSetRecoilState(newInventory)

  const [showModalSheet, setShowModalSheet] = useState(false)
  const internalDepots =
    depotStorage &&
    depotStorage
      .filter(
        (depot) => depot?.regionId === userState?.internal?.region?.regionId
      )
      .sort((a, b) => (a.name > b.name ? 1 : -1))

  const [inventoryData, setInventoryData] = useState([])
  const [inventoryFindData, setInventoryFindData] = useState([])
  const [inventoryReady, setInventoryReady] = useState(false)
  const [currentDepot, setCurrentDepot] = useState(
    userState?.internal?.depot ?? null
  )
  const [depotChanged, setDepotChanged] = useState(false)

  const [loading, setLoading] = useState(false)

  // Set new depot
  const handleSetCurrentDepot = (e) => {
    const id = e.target.value
    const depot = internalDepots.find((depot) => depot?.id === id)
    setCurrentDepot(depot)
  }

  // Save new depot
  const handleSaveCurrentDepot = () => {
    setUserSettings({
      ...userState,
      internal: {
        region: userState?.internal?.region ?? 3,
        depot: currentDepot
      }
    })
    Storage.set(
      "userState",
      {
        ...userState,
        internal: {
          region: userState?.internal?.region ?? 3,
          depot: currentDepot
        }
      },
      "local"
    )
    setCurrentInventoryState((previousInventoryState) => {
      return {
        ...previousInventoryState,
        depot: currentDepot
      }
    })

    setNewInventoryState((previousNewInventoryState) => {
      return {
        ...previousNewInventoryState,
        depot: currentDepot
      }
    })
  }

  // Request all inventories
  const requestInventories = useCallback(async () => {
    setLoading(true)

    await axios
      .get("/api/hll/inventoryOccasion")
      .then((response) => {
        const inventoriesData = response?.data.filter(
          (item) =>
            (item?.status === 2 &&
              item?.branchFilters.find(
                (branch) => branch?.branchId === userState?.internal?.depot?.id
              )) ||
            (item?.branchFilters?.length === 0 && item?.status === 2)
        ) // Only show ongoing inventories with the same depot as the user and status 2 OR if branchFilter is empty and have status 2 (Total inventory)

        const inventoriesFindData = response?.data.filter(
          (item) => item?.status === 3
        ) // Only show searching inventories

        setCurrentInventoryState((previousInventoryState) => {
          return {
            ...previousInventoryState,
            inventories: inventoriesData.sort((a, b) =>
              a.start > b.start ? 1 : -1
            )
          }
        })

        setCurrentInventoryState((previousInventoryState) => {
          return {
            ...previousInventoryState,
            searching: inventoriesFindData.sort((a, b) =>
              a.start > b.start ? 1 : -1
            )
          }
        })

        setInventoryData(inventoriesData)
        setInventoryFindData(inventoriesFindData)
      })
      .catch((err) => {
        console.log("error", err)
      })
      .finally(() => {
        setLoading(false)
        setInventoryReady(true)
        if (!depotChanged) {
          setShowModalSheet(true)
        }
      })
  }, [setCurrentInventoryState, userState?.internal?.depot?.id, depotChanged])

  function getDepotDefaultValue() {
    if (!currentDepot) return null
    return currentDepot?.id
  }

  // On load
  useEffect(() => {
    setViewState("initInventory")
    if (
      hasInventoryPermission &&
      internalRegionsStorage.length > 0 &&
      depotsStorage.length > 0
    ) {
      requestInventories()
    }
  }, [
    setViewState,
    requestInventories,
    hasInventoryPermission,
    internalRegionsStorage.length,
    depotsStorage.length
  ])

  if (!hasInventoryPermission) {
    return <PageNotFound />
  }

  return (
    <>
      {inventoryReady && showModalSheet && !depotChanged && <Overlay />}

      <div className="container-fluid container-fluid-max-width">
        <div className="pt-4">
          <NewInventories
            loading={loading}
            data={inventoryData}
            inventoryReady={inventoryReady}
          />
          <InventoryFind
            loading={loading}
            data={inventoryFindData}
            inventoryReady={inventoryReady}
          />
        </div>
      </div>

      {/* // need to logic and some permissions here */}
      <ActionFooter className="d-flex align-items-center justify-content-between">
        <div>
          {hasInventoryAdminPermission && (
            <button
              disabled={loading}
              className="btn btn-outline-secondary d-flex align-items-center text-primary"
              onClick={() => navigate(`/${t("inventory")}/administration`)}>
              <CustomSvgIcon name="cog" width={20} height={20} />
            </button>
          )}
        </div>
        <div>
          <button
            disabled={loading}
            className="btn btn-outline-secondary d-flex align-items-center text-primary"
            onClick={() => {
              setShowModalSheet(true)
            }}>
            <CustomSvgIcon name="depo" width={20} height={20} />
          </button>
        </div>
      </ActionFooter>
      {/* // need to logic and some permissions here */}

      <ModalSheet
        show={inventoryReady && showModalSheet}
        roundedBig={true}
        maxWidth={false}
        shadow={false}>
        <div className="position-relative mt-3">
          <h2 className="header-title mb-0 text-center px-3">
            {t("Are you at the right depot?")}
          </h2>
        </div>

        <div className="field my-4">
          {inventoryReady && internalDepots && internalDepots.length > 0 && (
            <select
              className="form-select"
              onChange={handleSetCurrentDepot}
              placeholder={t("Select depot")}
              defaultValue={getDepotDefaultValue()}>
              {internalDepots.map((item, i) => {
                return (
                  <option key={i} value={item?.id}>
                    {item?.name}
                  </option>
                )
              })}
            </select>
          )}

          <div className="text-center mt-4 border-top pt-4">
            <button
              className="btn btn-primary w-100"
              onClick={() => {
                setShowModalSheet(false)
                handleSaveCurrentDepot()

                if (currentDepot?.id === userState?.internal.depot?.id) {
                  setShowModalSheet(false)
                } else {
                  setInventoryReady(true)
                  setShowModalSheet(false)
                  setDepotChanged(true)
                }
              }}>
              {t("Yes! Here we go")}
            </button>
          </div>
        </div>
      </ModalSheet>
    </>
  )
}
