import React from "react"
import { pageTypeState } from "../Store"
import { useRecoilValue } from "recoil"
import { useTranslation } from "react-i18next"
import Search from "./Search"
import Depots from "../Transfer/Depots"

const Content = ({ type, handleModalHide, modalOpen, regionId }) => {
  const { t } = useTranslation()
  const pageType = useRecoilValue(pageTypeState)

  return (
    <>
      {type === "depot" ? (
        <Depots
          handleModalHide={handleModalHide}
          show={modalOpen}
          title={t("Move all to the following depot")}
        />
      ) : (
        <Search
          handleModalHide={handleModalHide}
          show={modalOpen}
          title={
            type === "search"
              ? pageType === "transfer"
                ? t("Add Machine/article")
                : t("Search machine")
              : t("Move all to the following depot")
          }
          regionId={regionId}
        />
      )}
    </>
  )
}

export default Content
