import React from "react"
import { useTranslation } from "react-i18next"
import Logo from "../../assets/images/HLL_notext.svg"
import parse from "html-react-parser"

const Maintenance = ({ content }) => {
  const { t } = useTranslation()

  return (
    <div className="maintenance mt-3" style={{ height: "100vh" }}>
      <div className="d-flex align-items-center justify-content-center w-100 h-100">
        <div className="w-100 p-4 m-0 text-center mx-auto">
          <img src={Logo} alt={t("My pages")} className="logo mb-3" />
          {content && parse(content)}
        </div>
      </div>
    </div>
  )
}

export default Maintenance
