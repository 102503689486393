import React from "react"
import { useTranslation } from "react-i18next"
export default function AdminSettings({ settings, handleSettingState }) {
  const { t } = useTranslation()
  const handleuseShortcuts = (e) => {
    const value = e.target.checked
    handleSettingState({
      useShortcuts: value
    })
  }

  return (
    <div>
      <h2 className="h4">{t("HLL Employees")}</h2>
      <form>
        <div className="form-group mb-4">
          <label
            className="field__label form-label mt-1 mb-1 text-uppercase text-muted"
            htmlFor="useShortcuts">
            {t("Shortcuts")}
          </label>
          <div className="form-text">
            {t("Enable shortcuts on dashboard for faster navigation.")}
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              name="useShortcuts"
              id="useShortcuts"
              checked={settings?.useShortcuts}
              onChange={(e) => handleuseShortcuts(e)}
            />
          </div>
        </div>
      </form>
    </div>
  )
}
