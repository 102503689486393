import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import CompanyProfileForm from "./Forms/CompanyProfileForm/Form"
import { companyProfileFormTemplate } from "./Forms/CompanyProfileForm/FormTemplate"
import { useForm } from "react-hook-form"
import { usePermissionCheck } from "../../utils/hooks"
import Storage from "../../utils/storage"

import axios from "axios"
import Toaster from "../Common/Toast"
import toast from "react-hot-toast"

const Profile = ({ data }) => {
  const { t } = useTranslation()
  const [isReady, setIsReady] = useState(false)
  const customer = Storage.get("customer", null, "local") ?? null

  //Permissions
  const hasCustomerProfileEditPermission = usePermissionCheck(
    "MinaSidor.KunduppgifterAdministrera"
  )

  const [editLoader, setEditLoader] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    shouldUnregister: true
  })

  const handleForm = {
    register,
    errors,
    setError,
    formData: data
  }

  const onSubmit = async (data) => {
    if (!hasCustomerProfileEditPermission) {
      return
    }

    let formattedData = {}

    setEditLoader(true)
    setIsDisabled(true)
    setIsReady(false)

    formattedData = {
      name: data?.name ?? "",
      number: data?.number ?? "",
      corporateIdentityNumber: data?.corporateIdentityNumber ?? "",
      accountName: data?.accountName ?? "",
      name2: data?.name ?? "", // updated from accountName
      email: data?.email ?? "",
      telephone: data?.telephone ?? "",
      mobileNumber: data?.mobileNumber ?? "",
      postalAdress: {
        adressLine1: data?.adressLine1 ?? "",
        adressLine2: data?.adressLine2 ?? "",
        adressLine3: data?.adressLine3 ?? "",
        postalCode: data?.postalCode ?? "",
        city: data?.city ?? ""
      }
    }

    await axios
      .post(`/api/hll/editCompanyProfile`, {
        customerId: customer?.id,
        body: formattedData
      })
      .then((response) => {
        if (response.data) {
          Storage.set("customer", response.data, "local")
          toast.success(t("Your company profile is updated"))
          setIsReady(true)
        }
      })
      .catch((error) => {
        console.error(error)
        toast.error(t("Something went wrong, try again."))
        setIsReady(false)
        setIsDisabled(false)
      })
      .finally(() => {
        setEditLoader(false)
      })
  }

  useEffect(() => {
    if (hasCustomerProfileEditPermission) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [hasCustomerProfileEditPermission])

  useEffect(() => {
    setTimeout(() => {
      if (isReady) {
        setIsDisabled(false)
        window.location.reload()
      }
    }, 1000)
  }, [isReady])

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <div className="header">
              <div className="header-body border-bottom-0 pb-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="header-pretitle">
                      {t("My pages")} - {t("Company Profile")}
                    </h6>
                    <h1 className="header-title text-truncate">
                      {t("Company information")}
                    </h1>
                  </div>
                  <div className="col-auto">
                    {hasCustomerProfileEditPermission && (
                      <button
                        className={`btn btn-primary ml-auto${
                          isDisabled ? " disabled" : ""
                        }`}
                        disabled={isDisabled}>
                        {editLoader ? (
                          <div
                            className="spinner-grow spinner-grow-sm text-white position-relative"
                            role="status"
                            style={{ top: "-2px" }}>
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          t("Save")
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div
              className="text-muted text-left alert alert-light rounded-0"
              role="alert">
              {t(
                "If you want to update any information, get in touch with us at"
              )}{" "}
              <a href="mailto:info@hllab.se">info@hllab.se</a>
            </div>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h2 className="card-header-title text-truncate">
                  {t("Company information")}
                </h2>
              </div>
              <div className="card-body">
                <div className="row">
                  <CompanyProfileForm
                    template={companyProfileFormTemplate}
                    handleForm={handleForm}
                    hasCustomerProfileEditPermission={
                      hasCustomerProfileEditPermission
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default Profile
