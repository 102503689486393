import { useRef, useEffect, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useRecoilValue, useResetRecoilState } from "recoil"
import Loader from "../Loader/Loader"

import { globalState } from "../../Store"

import Overlay from "../Common/Overlay"
import SearchResultCard from "./Search/SearchResultCard"
import SearchResultList from "./Search/SearchResultList"
import CustomSvgIcon from "../Common/CustomSvgIcon"

const LIMIT = 6

const Search = () => {
  const { t } = useTranslation()
  const searchInputRef = useRef(null)

  const { searchIsVisible } = useRecoilValue(globalState)
  const resetGlobalState = useResetRecoilState(globalState)

  const [searchString, setSearchString] = useState("")
  const [searchResult, setSearchResult] = useState(null)

  const handleOnChange = async (e) => {
    setSearchString(e.target.value)
  }

  const escFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        resetGlobalState()
      }
    },
    [resetGlobalState]
  )

  const { isFetching } = useQuery({
    queryKey: ["search"],
    queryFn: async () => {
      const res = await axios.post("/api/algolia/search", {
        searchValue: searchString
      })
      setSearchResult(res)
      return res
    },
    refetchOnWindowFocus: false,
    enabled: !!searchString && searchString.length > 2
  })

  useEffect(() => {
    if (searchIsVisible) {
      searchInputRef.current.focus()
    } else {
      setSearchString("")
      setSearchResult(null)
    }
  }, [searchIsVisible])

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)

    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [escFunction])

  const categories = searchResult?.data?.categories ?? []
  const products = searchResult?.data?.products ?? []

  return (
    <>
      {searchIsVisible && (
        <div onClick={() => resetGlobalState()}>
          <Overlay />
        </div>
      )}

      <div className={`search-bar${searchIsVisible ? " is-open" : ""}`}>
        <form
          id="search-bar-form"
          className="search-bar-form"
          role="search"
          action={`/${t("customer")}/${t("search")}`}>
          <div className="search-bar-field">
            <div className="search-bar-icon">
              <span
                className="search-bar-close-icon"
                onClick={() => resetGlobalState()}>
                <CustomSvgIcon
                  className=""
                  name="close"
                  width={15}
                  height={15}
                />
              </span>
            </div>
            <input
              ref={searchInputRef}
              className="search-bar-input"
              type="search"
              name="q"
              id="search-bar-input"
              value={searchString}
              aria-label="Sök efter maskin eller kategori"
              placeholder="Sök efter maskin eller kategori"
              autoComplete="off"
              autoCapitalize="off"
              onChange={(e) => handleOnChange(e)}
            />
            <button
              type="submit"
              className="search-bar-submit-button"
              aria-label="Sök">
              <CustomSvgIcon
                className=""
                name="search"
                width={18}
                height={18}
              />
            </button>
          </div>
        </form>

        <div
          className={`search-bar-box${searchResult ? " is-full-height" : ""}`}>
          {/* <div className="search-bar-backdrop"></div> */}
          <div className="search-bar-input-background"></div>

          <div className="search-bar-dropdown">
            {isFetching && <Loader />}

            {!isFetching && searchResult && (
              <div className="search-bar-results">
                <div className="search-results">
                  <div className="search-results-row">
                    <div className="search-results-col is-grow">
                      <div className="search-results-group">
                        <p className="search-results-label">{t("Machines")}</p>
                        {/* If search result */}
                        <div>
                          {products && products.length > 0 && (
                            <ol className="search-results-vstack">
                              {products.slice(0, LIMIT).map((item, index) => (
                                <SearchResultCard key={index} data={item} />
                              ))}
                            </ol>
                          )}
                          {products && products.length > 5 && (
                            <Link
                              className="search-bar-show-more"
                              replace={true}
                              reloadDocument={true}
                              to={{
                                pathname: `/${t("customer")}/${t("search")}`,
                                search: `?q=${encodeURIComponent(
                                  searchString
                                )}&tab=products`
                              }}>
                              {`+ ${products?.length} ${t(
                                "Search hits"
                              ).toLowerCase()}`}
                            </Link>
                          )}
                        </div>
                        {products && products.length === 0 && (
                          <p>{t("No machines found")}</p>
                        )}
                      </div>
                    </div>

                    <div className="search-results-col">
                      <div className="search-results-group">
                        <p className="search-results-label">
                          {t("Categories")}
                        </p>
                        <div>
                          {categories && categories.length > 0 && (
                            <ol className="search-results-vstack is-category">
                              {categories.slice(0, LIMIT).map((item, index) => (
                                <SearchResultList key={index} data={item} />
                              ))}
                            </ol>
                          )}
                          {categories && categories.length > 5 && (
                            <Link
                              className="search-bar-show-more"
                              replace={true}
                              reloadDocument={true}
                              to={{
                                pathname: `/${t("customer")}/${t("search")}`,
                                search: `?q=${encodeURIComponent(
                                  searchString
                                )}&tab=categories`
                              }}>
                              {`+ ${categories?.length} ${t(
                                "Search hits"
                              ).toLowerCase()}`}
                            </Link>
                          )}
                        </div>
                        {categories && categories.length === 0 && (
                          <p>{t("No categories found")}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default Search
