import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import axios from "axios"
import { useNavigate } from "react-router-dom"

import Loader from "../Loader/Loader"
import Alert from "../Common/Alert"
import Storage from "../../utils/storage"

import { setUserSettings } from "../../utils/hooks"

const InternalVerificationModal = ({
  showInternalRegionModal,
  setShowInternalRegionModal
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userState = Storage.get("userState") ?? null
  const [regions, setRegions] = useState([])
  const [depots, setDepots] = useState([])
  const [region, setRegion] = useState(
    userState?.internal?.region ? userState?.internal?.region : false
  )
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  const handleChangeInternalRegion = async (e) => {
    const region = e.target.value
    const parsedRegion = JSON.parse(region)
    setError(false)
    setErrorMsg("")
    setRegion(parsedRegion)

    // Set to userSettings
    setUserSettings({
      ...userState,
      internal: {
        region: parsedRegion,
        internalCompanyId: parsedRegion?.internalCompanyId ?? "3",
        depot: false
      }
    })

    Storage.set(
      "userState",
      {
        ...userState,
        internal: {
          region: parsedRegion,
          internalCompanyId: parsedRegion?.internalCompanyId ?? "3",
          depot: false
        }
      },
      "local"
    )

    const body = {
      regionId: {
        logicalOperator: "And",
        predicate: "Equal",
        value: parsedRegion.regionId
      },
      internalCompanyId: {
        logicalOperator: "And",
        predicate: "Equal",
        value: parsedRegion.internalCompanyId
      }
    }

    let data = []
    setLoading(true)
    setDepots([])

    await axios
      .post("/api/hll/internalDepots", {
        body: body
      })
      .then((response) => {
        data = response?.data?.data ? response.data.data : false
        if (data.length === 0) {
          setErrorMsg(t("Can't find any depots. Try another region"))
        }
        setDepots(data)
      })
      .catch((err) => {
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleChangeInternalDepot = (e) => {
    const depot = e.target.value
    const parsedDepot = JSON.parse(depot)
    setErrorMsg("")

    // Set to userSettings
    setUserSettings({
      ...userState,
      internal: {
        region: region,
        internalCompanyId: region?.internalCompanyId ?? "3",
        depot: parsedDepot
      }
    })

    Storage.set(
      "userState",
      {
        ...userState,
        internal: {
          region: region,
          internalCompanyId: region?.internalCompanyId ?? "3",
          depot: parsedDepot
        }
      },
      "local"
    )
    setShowInternalRegionModal(false)
    navigate(0)
  }

  useEffect(() => {
    const requestRegions = async () => {
      let data = []
      setRegions(false)
      setLoading(true)
      setError(false)

      await axios
        .post("/api/hll/internalRegions", {
          forUserOnly: true
        })
        .then((response) => {
          data = response?.data ? response.data : []
          setRegions(data)
        })
        .then(() => {
          if (userState?.internal?.region) {
            setDepots([])
            setLoading(true)
            const body = {
              regionId: {
                logicalOperator: "And",
                predicate: "Equal",
                value: userState?.internal?.region?.regionId
              }
            }
            axios
              .post("/api/hll/internalDepots", {
                body: body
              })
              .then((response) => {
                const result = response?.data?.data ? response.data.data : false
                setDepots(result)
                if (result.length === 0) {
                  setErrorMsg(t("Can't find any depots. Try another region"))
                }
              })
              .catch((err) => {
                setError(true)
              })
              .finally(() => {
                setLoading(false)
              })
          }
        })
        .catch((err) => {
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    requestRegions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  const internalRegions = regions && regions.length > 0 ? regions : null
  const internalDepots = depots ? depots : null

  return (
    <Modal
      show={showInternalRegionModal}
      centered
      backdrop="static"
      animation={false}>
      <Modal.Header className="d-block p-3">
        <div className="position-relative">
          <h3 className="header-title mt-3 mb-0 text-center">
            {t("Choose region and depot")}
          </h3>
        </div>
      </Modal.Header>

      <Modal.Body>
        {internalRegions && (
          <div className="field col-12 mb-3">
            <label className="field__label form-label mb-1 text-uppercase">
              {t("Regions")}
            </label>
            <select
              className="form-select"
              onChange={handleChangeInternalRegion}
              defaultValue={region ? JSON.stringify(region) : ""}>
              <option value="" disabled>
                {t("Choose")}
              </option>
              {internalRegions.map((item, i) => (
                <option key={i} value={JSON.stringify(item)}>
                  {item.regionName}
                </option>
              ))}
            </select>
          </div>
        )}

        {internalDepots.length > 0 && (
          <div className="field col-12 mb-3">
            <label className="field__label form-label mb-1 text-uppercase">
              {t("Depot")}
            </label>
            <select
              className="form-select"
              onChange={handleChangeInternalDepot}>
              <option value="">{t("Choose")}</option>
              {internalDepots
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((item, i) => (
                  <option key={i} value={JSON.stringify(item)}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        )}

        {loading && (
          <div className="d-flex align-items-center justify-content-center my-3 flex-grow-1">
            <Loader />
          </div>
        )}

        {errorMsg && !loading && (
          <Alert className="text-center my-4" message={errorMsg} />
        )}

        {error && (
          <Alert
            className="text-center my-4"
            message={t("Something went wrong, please refresh current page.")}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default InternalVerificationModal
