import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import CustomSvgIcon from "../../../Common/CustomSvgIcon"

export default function List({ inventoryOccasionId, name }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <motion.div
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 50, opacity: 0 }}>
      <div className="col-12">
        <button
          className="bg-transparent d-block w-100 m-0 p-0 text-left text-body"
          onClick={() =>
            navigate(
              `/${t("inventory")}/${t("registration")}/${encodeURIComponent(
                inventoryOccasionId
              )}`,
              { state: { inventoryOccasionId, name } }
            )
          }>
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center ">
                <div className="col">
                  <h3 className="mb-1">{name && name}</h3>
                </div>
                <div className="col-auto">
                  <CustomSvgIcon name="chevron-right" width={20} height={20} />
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
    </motion.div>
  )
}
