import React from "react"
import { useTranslation } from "react-i18next"
import ProjectList from "../../components/ProjectList/ProjectList.js"
import Storage from "../../utils/storage"
import { useLocation, useNavigate } from "react-router-dom"
import { usePermissionCheck } from "../../utils/hooks"

import Toaster from "../../components/Common/Toast"
import PageNotFound from "../PageNotFound/PageNotFound"

const Projects = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location?.pathname
  const customer = Storage.get("customer", null, "local") ?? null
  const hasHLLInternPermission = usePermissionCheck("MinaSidor.SkapaRetur")
  const hasAdminSitePermission = usePermissionCheck(
    "MinaSidor.PlatserAdministrera"
  )

  const hasCustomerSitePermission = usePermissionCheck(
    "MinaSidor.KundsPlatsLäs"
  )

  if (!hasCustomerSitePermission || hasHLLInternPermission) {
    return <PageNotFound noPermission={true} />
  }

  return (
    <>
      <Toaster />
      <div className="container-fluid container-fluid-max-width">
        <div className="row">
          <div className="col-12">
            <div className="header">
              <div className="header-body border-bottom-0 pb-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="header-pretitle">{t("My pages")}</h6>

                    <h1 className="header-title text-truncate">
                      {t("Project")}
                    </h1>
                  </div>
                  <div className="col-auto">
                    {hasAdminSitePermission && (
                      <button
                        className="btn btn-primary mr-3 ml-auto"
                        onClick={() =>
                          navigate(`${currentPath}/${t("create")}`)
                        }>
                        {t("New project")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {customer && (
          <div className="row mb-5">
            <div className="col-12">
              <ProjectList />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Projects
