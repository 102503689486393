import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Storage from "../../../utils/storage"
import SearchForm from "../Form/SearchForm"
import Loader from "../../Loader/Loader"
import Alert from "../../Common/Alert"
import SearchOrder from "../Common/Queries/SearchOrder"
import Item from "../Common/Item"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const ModalOrderSearch = ({ show, handleHideModal }) => {
  const { t } = useTranslation()
  const userState = Storage.get("userState") ?? null
  const regionId = userState?.internal?.region?.regionId ?? 3
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState("")
  const [isSearched, setIsSearched] = useState(false)
  const [inputText, setInputText] = useState("")

  const handleInputChange = (event) => {
    const value = event.target.value

    setInputText(value)
  }

  const handleSearchSubmit = async (event) => {
    const inputValue = event.target?.elements?.search?.value
    setIsSearched(false)

    try {
      const res = await SearchOrder(
        t,
        inputValue,
        regionId,
        setLoading,
        setError
      )

      setIsSearched(true)
      setData(res)
    } catch (err) {
      //console.log(err)
      setError(
        `${t("Something went wrong")}: ${err?.response?.data?.CorrelationId}`
      )
    } finally {
      setLoading(false)
    }
  }

  const onModalHide = () => {
    handleHideModal()
    setData([])
    setInputText("")
  }

  return (
    <Modal
      show={show}
      fullscreen="true"
      onHide={() => {
        handleHideModal()
        setData([])
      }}
      backdrop="static"
      animation={false}
      className="modal-scrollable">
      <Modal.Header className="d-block p-3">
        <div className="position-relative">
          <h3 className="header-title mt-3 mb-0 text-center">
            {t("Search order")}
          </h3>
          <button
            onClick={onModalHide}
            className="bg-transparent position-absolute p-0 text-body"
            style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
            <CustomSvgIcon
              name="close"
              width={16}
              height={16}
              className="text-black"
            />
          </button>
        </div>
        <div className="mt-4">
          <SearchForm
            handleSearchSubmit={handleSearchSubmit}
            inputText={inputText}
            handleInputChange={handleInputChange}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="text-center p-0 bg-white is-scrollable mx-0 pb-5">
        {loading && !error && <Loader className="my-4" />}
        {!loading && error && (
          <Alert
            className="mt-3"
            message={t("Something went wrong, please refresh current page.")}
          />
        )}

        {!loading && isSearched && data.length === 0 && (
          <Alert className="mt-3" message={t("Can't find anything")} />
        )}

        {!loading &&
          !error &&
          data.length > 0 &&
          data.map((item, index) => {
            return (
              <Item
                key={index}
                context="order"
                index={index}
                title={item.customerName}
                meta={[
                  item.siteNumber ? item.siteNumber : null,
                  item.siteName ?? ""
                ]}
                item={item}
              />
            )
          })}
      </Modal.Body>
    </Modal>
  )
}

export default ModalOrderSearch
