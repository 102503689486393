import { useTranslation } from "react-i18next"
import { priceWithCurrencyHelper } from "../../../../utils/helpers"
export default function PurchaseCostDetails({ accessories, services }) {
  const { t } = useTranslation()

  function calculateAccessoriesPurchaseCost(arr) {
    // vars
    let total = 0

    const data = arr && arr.length > 0 ? arr : null

    if (!data) {
      return total
    }

    data?.map((item) => {
      if (item?.orderRowType === 2 || item?.orderRowType === 3) {
        total += item?.cost * item?.quantity ?? 0
      }

      return item
    })

    return total
  }

  function calculateServicesPurchaseCost(arr) {
    // vars
    let total = 0

    const data = arr && arr.length > 0 ? arr : null

    if (!data) {
      return total
    }

    data?.map((item) => {
      if (item?.orderRowType === 2 || item?.orderRowType === 3) {
        total += item?.cost * item?.quantity ?? 0
      }

      return item
    })

    return total
  }

  const accessoriesPurchaseCost = calculateAccessoriesPurchaseCost(accessories)
  const servicesPurchaseCost = calculateServicesPurchaseCost(services)

  return (
    <div className="mb-3 text-muted mr-4">
      <div className="d-flex justify-content-between align-items-center mt-1 text-muted">
        <div>{t("Service costs")}</div>
        <div>{`${priceWithCurrencyHelper(servicesPurchaseCost ?? 0)}`}</div>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-1 text-muted">
        <div>{t("Articles and consumables")}</div>
        <div>{`${priceWithCurrencyHelper(accessoriesPurchaseCost ?? 0)}`}</div>
      </div>
    </div>
  )
}
