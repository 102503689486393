import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import axios from "axios"
import { useTranslation } from "react-i18next"
import SkeletonLoader from "../../../components/Common/SkeletonLoader"

import { useQuery } from "@tanstack/react-query"

import CategoryCard from "./CategoryCard"
import ProductCard from "./ProductCard"

export default function Search() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const q = searchParams.get("q")
  const tab = searchParams.get("tab")
  const [currentTab, setCurrentTab] = useState(tab ?? "products")

  const { data: searchResult, isLoading } = useQuery({
    queryKey: ["search"],
    queryFn: async () => {
      const res = await axios.post("/api/algolia/search", {
        searchValue: decodeURIComponent(q)
      })
      return res
    },
    refetchOnWindowFocus: false,
    enabled: !!q
  })

  const products = searchResult?.data?.products ?? []
  const categories = searchResult?.data?.categories ?? []
  return (
    <div className="container-fluid container-fluid-max-width">
      <div className="row">
        <div className="header">
          <div className="header-body border-bottom-0 pb-0">
            <div className="row align-items-center">
              <div className="col">
                <h6 className="header-pretitle">{t("My HLL")}</h6>
                <h1 className="header-title">
                  {t("Search results on")}{" "}
                  <span className="h1 mb-0 text-orange">{`"${decodeURIComponent(
                    q
                  )}"`}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="header mb-3 mb-md-4">
          {isLoading && <SkeletonLoader />}

          {!isLoading && (
            <div className="header-body pt-0">
              <div className="row align-items-center">
                <div className="col">
                  <ul className="nav nav-tabs nav-overflow header-tabs">
                    <li className="nav-item">
                      <button
                        id="products-tab"
                        className={`d-flex nav-link text-nowrap align-items-center bg-transparent${
                          currentTab === "products" ? " active" : ""
                        }`}
                        data-name="products"
                        onClick={() => setCurrentTab("products")}>
                        {t("Machines")}
                        <span className="ml-2 badge bg-light">
                          {products?.length}
                        </span>
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        id="categories-tab"
                        className={`d-flex nav-link text-nowrap align-items-center bg-transparent${
                          currentTab === "categories" ? " active" : ""
                        }`}
                        data-name="categories"
                        onClick={() => setCurrentTab("categories")}>
                        {t("Categories")}
                        <span className="ml-2 badge bg-light">
                          {categories?.length}
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>

        <main className="col-12">
          {currentTab === "products" && (
            <div className="auto-grid">
              {!isLoading &&
                products &&
                products.length > 0 &&
                products.map((item, index) => {
                  return <ProductCard key={index} {...item} />
                })}

              {!isLoading && products && products.length === 0 && (
                <p>{t("No search results found")}</p>
              )}
            </div>
          )}

          {currentTab === "categories" && (
            <div className="auto-grid">
              {!isLoading &&
                categories &&
                categories.length > 0 &&
                categories.map((item, index) => {
                  return <CategoryCard key={index} {...item} />
                })}

              {!isLoading && categories && categories.length === 0 && (
                <p>{t("No search results found")}</p>
              )}
            </div>
          )}
        </main>
      </div>
    </div>
  )
}
