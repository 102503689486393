import React, { useState, useEffect } from "react"
import Toaster from "../Common/Toast"
import { useTranslation } from "react-i18next"
import axios from "axios"
import Storage from "../../utils/storage"
import TableSkeletonLoader from "../Common/TableSkeletonLoader"
import DocumentRecipient from "../CompanyProfile/Forms/DocumentRecipients/DocumentRecipient"
import ModalUpdate from "../CompanyProfile/Forms/DocumentRecipients/ModalUpdate"
import ModalConfirm from "../CompanyProfile/Forms/DocumentRecipients/ModalConfirm"
import toast from "react-hot-toast"
import {
  recipientsEmailMapping,
  findDocumentTypesInArray
} from "../CompanyProfile/helpers"
import { Link, useLocation } from "react-router-dom"
import OutStandingRentSettingsForm from "./OutStandingRentSettingsForm"

import Alert from "../Common/Alert"
import CustomSvgIcon from "../Common/CustomSvgIcon"

export default function ProjectDocumentRecipients(props, state) {
  const { t } = useTranslation()
  const location = useLocation()
  const { id, customerDocumentRecipients } = props
  const { name, number } = location?.state
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [modalEditData, setModalEditData] = useState(null)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [existingEmail, setExistingEmail] = useState(false)
  const [recipients, setRecipients] = useState([])
  const [projectData, setProjectData] = useState([])
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  // Adding email array to state from the documentRecipients list
  const [recipientsEmails, setRecipientsEmails] = useState([])
  const customer = Storage.get("customer", null, "local") ?? null

  // Search on change
  const handleSearchChange = (e) => {
    e.preventDefault()
    const value = e.target.value

    setSearchText(value)
    filterData(value)
  }

  // Filter data based on search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim()
    if (lowercasedValue === "") {
      setRecipientsEmails(
        recipientsEmailMapping(recipients.concat(customerDocumentRecipients))
      )
    } else {
      const filteredData = search(lowercasedValue)
      setRecipientsEmails(filteredData)
    }
  }

  // Do a search absed on searchText
  const search = (searchText) => {
    const emails = recipientsEmailMapping(
      recipients.concat(customerDocumentRecipients)
    )
    let arrayOfMatchedEmails = emails.filter((email) => {
      return JSON.stringify(email).toString().toLowerCase().includes(searchText)
    })
    return arrayOfMatchedEmails
  }

  const handleHideEditModal = () => {
    setShowModalUpdate(false)
    setModalEditData(null)
    setExistingEmail(false)
  }

  const handleShowEditModal = (recipientData) => {
    setShowModalUpdate(true)
    setModalEditData(recipientData)
    setExistingEmail(false)
  }

  const handleShowConfirmModal = (recipientData) => {
    setShowModalConfirm(true)
    setModalEditData(recipientData)
  }

  const handleHideConfirmModal = () => {
    setShowModalConfirm(false)
    setModalEditData(null)
  }

  const filterEmailFromDocumentTypes = (emailToRemove) => {
    return recipients.map((recipient) => {
      const emails = recipient.emails.filter((email) => email !== emailToRemove)

      return {
        ...recipient,
        emails
      }
    })
  }

  const handleUpdateDocumentRecipient = async (updatedData) => {
    let postData = []

    const { documentTypes, email, oldEmail } = updatedData
    setExistingEmail(false)

    const skipExistingEmailValidation = oldEmail === email && modalEditData

    const findExistingEmails = recipients
      .concat(customerDocumentRecipients)
      .filter((recipient) => recipient.emails.includes(email))

    if (!skipExistingEmailValidation && findExistingEmails.length > 0) {
      setExistingEmail(true)
      return
    }

    if (modalEditData) {
      // Edit
      const updatedEmail = oldEmail !== email ? email : oldEmail
      const updatedRecipients = filterEmailFromDocumentTypes(oldEmail)

      documentTypes.forEach((document) => {
        const index = updatedRecipients.findIndex(
          (type) => Number(type.documentType) === Number(document)
        )

        if (index > -1) {
          updatedRecipients[index].emails.push(updatedEmail)
        } else {
          updatedRecipients.push({
            documentType: Number(document),
            emails: [updatedEmail]
          })
        }
      })

      postData = updatedRecipients
    } else {
      // Add new
      if (recipients.length === 0) {
        documentTypes.map((type) => {
          postData.push({
            documentType: Number(type),
            emails: [email]
          })
          return type
        })
      } else {
        for (let index = 0; index < documentTypes.length; index++) {
          if (
            Number(recipients[index]?.documentType) ===
            Number(documentTypes[index])
          ) {
            recipients[index].emails.push(email)
          } else {
            recipients.push({
              documentType: Number(documentTypes[index]),
              emails: [email]
            })
          }
        }

        postData = recipients
      }
    }

    handleUpdateRequest(postData, modalEditData ? "edit" : "create")
  }

  const handleDelete = (data) => {
    const updatedRecipients = filterEmailFromDocumentTypes(data.email)

    let deleteAll = true

    updatedRecipients.forEach((recipient) => {
      if (recipient.emails.length > 0) {
        deleteAll = false
      }
    })

    setShowModalConfirm(false)

    if (deleteAll) {
      handleUpdateRequest([], "delete")
    } else {
      handleUpdateRequest(updatedRecipients, "delete")
    }
  }

  const handleUpdateRequest = async (body, action = "") => {
    setLoading(true)
    await axios
      .post("/api/hll/projectDocumentRecipients", {
        siteId: id,
        body
      })
      .then((response) => {
        if (response.status === 200) {
          setRecipients(response?.data?.documentRecipients)

          setRecipientsEmails(
            recipientsEmailMapping(response?.data?.documentRecipients)
          )

          setProjectData(response?.data)

          window.dataLayer.push({
            event: `HLL_project_document_recipients_${action}`,
            company: customer?.name,
            projectName: `${response?.data?.name} ${response?.data?.number}`,
            userType: "customer"
          })

          toast.success(t("Document recipients updated"))
        }
      })
      .catch((error) => {
        console.error(error)
        toast.error(t("Something went wrong, try again."))
      })
      .finally(() => {
        setLoading(false)
        setShowModalUpdate(false)
        setModalEditData(null)
      })
  }

  useEffect(() => {
    setError(false)
    setLoading(true)

    const requestProjectRecipients = async () => {
      await axios
        .post("/api/hll/project", { id })
        .then((response) => {
          setProjectData(response?.data)
          setRecipients(response?.data?.documentRecipients)
        })
        .catch((err) => {
          //console.log(err, "Error: api/hll/project")
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (customerDocumentRecipients) {
      requestProjectRecipients()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDocumentRecipients, id, props])

  useEffect(() => {
    setRecipientsEmails(
      recipientsEmailMapping(recipients.concat(customerDocumentRecipients))
    )
  }, [customerDocumentRecipients, recipients])

  return (
    <>
      <Toaster />
      <ModalConfirm
        show={showModalConfirm}
        data={modalEditData}
        handleHideConfirmModal={handleHideConfirmModal}
        handleDelete={handleDelete}
      />
      <ModalUpdate
        show={showModalUpdate}
        data={modalEditData}
        handleHideEditModal={handleHideEditModal}
        handleUpdateDocumentRecipient={handleUpdateDocumentRecipient}
        loading={loading}
        existingEmail={existingEmail}
      />
      <div className="row mb-5">
        <div className="col-12">
          <div className="header">
            <div className="header-body">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="header-pretitle">
                    {t("Project")} / {name && name} {number && number}
                  </h6>
                  <h1 className="header-title text-truncate">
                    {t("Document recipients")}
                  </h1>
                  {error && !loading && (
                    <div className="row mt-2">
                      <div className="col-12">
                        <Alert
                          className="text-left"
                          message={t(
                            "Something went wrong, please refresh current page."
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {!error && (
                  <div className="col-auto mt-2 mt-md-0">
                    <button
                      className={`btn btn-primary ml-auto`}
                      onClick={() => setShowModalUpdate(true)}>
                      {t("Add a recipient")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!error && (
        <div className="row my-5">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-lg-8 order-1">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                          <input
                            className={`form-control list-search${
                              !recipientsEmails || loading ? " is-disabled" : ""
                            }`}
                            type="search"
                            disabled={!recipientsEmails || loading}
                            placeholder={t("Search")}
                            value={searchText}
                            onChange={handleSearchChange}
                          />
                          <span className="input-group-text pl-3">
                            <CustomSvgIcon
                              name="search"
                              width={20}
                              height={20}
                              className={`text-muted`}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!recipientsEmails && (
                    <div className="card-body text-center">
                      {t("Can't find anything")}
                    </div>
                  )}

                  {recipientsEmails && (
                    <div className="table-responsive">
                      <table className="table table-sm table-hover table-nowrap card-table">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="col-12 col mr-auto text-left text-gray-700">
                              {t("Email")}
                            </th>
                            <th
                              scope="col"
                              className="col-12 col text-left text-gray-700">
                              {t("Document type")}
                            </th>
                          </tr>
                        </thead>
                        {loading ? (
                          <tbody>
                            <TableSkeletonLoader rows={5} cells={3} />
                          </tbody>
                        ) : (
                          <tbody className="list font-size-base">
                            {recipientsEmails &&
                              recipientsEmails.map((email, index) => {
                                let isClientRecipient =
                                  customerDocumentRecipients.find((recipient) =>
                                    recipient.emails.includes(email)
                                  )
                                    ? true
                                    : false

                                const documentTypes = findDocumentTypesInArray(
                                  isClientRecipient
                                    ? customerDocumentRecipients
                                    : recipients,
                                  email
                                )

                                return (
                                  <DocumentRecipient
                                    key={index}
                                    email={email}
                                    documentTypes={documentTypes}
                                    handleShowEditModal={handleShowEditModal}
                                    handleDelete={handleDelete}
                                    isLocked={isClientRecipient}
                                    handleShowConfirmModal={
                                      handleShowConfirmModal
                                    }
                                    handleHideConfirmModal={
                                      handleHideConfirmModal
                                    }
                                  />
                                )
                              })}
                          </tbody>
                        )}
                      </table>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4 order-2">
                {!loading && (
                  <>
                    <OutStandingRentSettingsForm
                      projectData={projectData}
                      setProjectData={setProjectData}
                    />

                    <h4>{t("Edit client recipients")}</h4>
                    <p className="mb-2">
                      {t(
                        "If you want to edit persons that are document recipients on a client level, go to your company profile."
                      )}
                    </p>

                    <p>
                      <Link
                        className="text-primary"
                        to={`${t("/company-profile/document-recipients")}`}>
                        {t("Edit document recipients")}
                      </Link>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
