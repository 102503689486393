import React, { useState } from "react"
import { Form, FormControl } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

const Search = ({ onChangeHandler, setSearchText, searchText }) => {
  const [inputText, setInputText] = useState(searchText)
  const { t } = useTranslation()

  return (
    <div className="px-3 px-lg-0 mb-3">
      <Form
        autoComplete="off"
        noValidate={false}
        className="position-relative"
        onSubmit={(event) => {
          event.preventDefault()
        }}>
        <FormControl
          className="form-control form-control-lg"
          type="text"
          name="search"
          placeholder={t("Search after incoming machine")}
          required
          enterKeyHint="Search"
          autoCapitalize="none"
          autoComplete="off"
          value={inputText}
          onChange={(e) => {
            onChangeHandler(e.target.value)
            setInputText(e.target.value)
          }}
          spellCheck={false}
          style={{
            paddingLeft: "15px",
            paddingRight: "50px"
          }}
          autoCorrect="off"
        />

        <button
          className="bg-transparent text-primary position-absolute"
          style={{
            top: "50%",
            right: 20,
            transform: "translateY(-50%)",
            width: 20,
            height: 20
          }}
          type="button"
          onClick={() => {
            if (inputText !== "") {
              setSearchText("")
              setInputText("")
              onChangeHandler("")
            }
          }}>
          <CustomSvgIcon
            name="search"
            width={20}
            height={20}
            className="text-muted"
          />
        </button>
      </Form>
    </div>
  )
}

export default Search
