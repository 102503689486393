import React, { useState, useEffect } from "react"
import axios from "axios"
import { Alert } from "react-bootstrap"
import CustomSvgIcon from "../Common/CustomSvgIcon"

import parse from "html-react-parser"
import { CookiesProvider, useCookies } from "react-cookie"

import { usePermissionCheck } from "../../utils/hooks"

const typeMapping = {
  info: "info",
  greeting: "primary",
  warning: "danger",
  service: "warning"
}

const AlertElement = ({ index, title, status, acf, id }) => {
  const [isActive, setIsActive] = useState(false)
  const renderedTitle = title?.rendered
  const namespace = "HLL"
  const cookieName = `${namespace}_notification_${id}`

  const today = new Date()
  let dateVisibility = false

  const [cookies, setCookie] = useCookies([])
  const [showNotification, setShowNotification] = useState(true)

  if (acf?.notification_visibility === "date") {
    const startDate = new Date(acf?.notification_start_date)
    const endDate = new Date(acf?.notification_end_date)

    if (today >= startDate && today <= endDate) {
      dateVisibility = true
    }
  }

  const currentCookies = Object.keys(cookies)
  const hasReturnPermission = usePermissionCheck("MinaSidor.SkapaRetur")

  const handleHideNotification = () => {
    setCookie(cookieName, true, { path: "/" })
    setShowNotification(false)
  }

  if (Boolean(currentCookies.includes(cookieName))) {
    return null
  }

  if (status !== "publish") {
    return null
  }

  if (acf?.notification_visibility === "date" && !dateVisibility) {
    return null
  }

  if (acf?.notification_permissions !== "all" && !hasReturnPermission) {
    return null
  }

  if (!acf?.notification_visible_on_my_pages) {
    return null
  }

  return (
    <Alert
      key={index}
      data-id={id}
      variant={
        typeMapping[acf?.notification_type]
          ? typeMapping[acf.notification_type]
          : "info"
      }
      className={`mb-0 rounded-0${showNotification ? " d-block" : " d-none"}`}>
      <div
        className={`alert--header d-flex align-items justify-content-between${
          typeMapping[acf?.notification_type] === "warning" ? "" : " text-white"
        }`}>
        <div>
          {renderedTitle && (
            <p className="d-flex align-items-center">
              <strong>{renderedTitle}</strong>
              {acf?.notification_message && (
                <button
                  className={`d-inline${
                    typeMapping[acf?.notification_type] === "warning"
                      ? ""
                      : " text-white"
                  }`}
                  onClick={() => setIsActive(!isActive)}
                  style={{
                    background: "transparent",
                    border: "none",
                    paddingLeft: "1rem"
                  }}>
                  {isActive ? (
                    <>
                      <small className="d-flex align-items-center">
                        <span className="d-none d-md-inline">Läs mer</span>{" "}
                        <CustomSvgIcon
                          name="chevron-up"
                          width={12}
                          height={12}
                          className="text-black ml-2"
                        />
                      </small>
                    </>
                  ) : (
                    <>
                      <small className="d-flex align-items-center">
                        <span className="d-none d-md-inline">Läs mer</span>{" "}
                        <CustomSvgIcon
                          name="chevron-down"
                          width={12}
                          height={12}
                          className="text-black ml-2"
                        />
                      </small>
                    </>
                  )}
                </button>
              )}
            </p>
          )}
        </div>
        <div className="ml-auto">
          <button
            className={`${
              typeMapping[acf?.notification_type] === "warning"
                ? ""
                : " text-white"
            }`}
            onClick={handleHideNotification}
            style={{
              background: "transparent",
              border: "none",
              paddingLeft: "1rem"
            }}>
            <div className="svg-icon-wrapper text-black mr-3">
              <CustomSvgIcon
                name="close"
                width={16}
                height={16}
                className="text-black ml-2"
              />
            </div>
          </button>
        </div>
      </div>

      {acf?.notification_message && (
        <div
          className={`expandable-content${
            typeMapping[acf?.notification_type] === "warning"
              ? ""
              : " text-white"
          }${isActive ? " is-active" : ""}`}>
          {parse(acf.notification_message)}
        </div>
      )}
    </Alert>
  )
}

export const PushNotifications = () => {
  const [apiData, setApiData] = useState([])
  const [loading, setLoading] = useState(false)

  const getNotification = async () => {
    await axios
      .post("/api/hll/pushNotifications")
      .then((response) => {
        if (response?.data) {
          setLoading(false)
          setApiData(response.data)
        }
      })
      .catch((err) => {
        console.error(err.message)
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getNotification()
  }, [])

  //Real time update...
  // useEffect(() => {
  // 	getNotification();
  // 	if (getNotification.length) getNotification();
  // }, [getNotification]);

  return (
    <>
      <CookiesProvider>
        {apiData.length > 0 && (
          <div className="notifications Hll-Push-Notifications-App">
            {apiData.map((data, index) => {
              return <AlertElement key={index} {...data} />
            })}

            {loading && (
              <div
                className="spinner-border"
                role="status"
                style={{ fontSize: "0.75rem" }}></div>
            )}
          </div>
        )}
      </CookiesProvider>
    </>
  )
}
export default PushNotifications
