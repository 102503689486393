import React, { useState } from "react"
import { usePermissionCheck, useCompanyCheck } from "../../utils/hooks"
import PageNotFound from "../PageNotFound/PageNotFound"
import Users from "../../components/Users/Users"
import Storage from "../../utils/storage"

const UsersPage = () => {
  const hasUserPermission = usePermissionCheck("Säkerhet.AnvändareGruppAdmin")
  const hasHLLInternPermission = usePermissionCheck("MinaSidor.SkapaRetur")
  const companyPermissions = useCompanyCheck()

  const [customer] = useState(
    Storage.get("customer") ? Storage.get("customer") : false
  )
  const { companyType } = customer

  if (!hasUserPermission) {
    return <PageNotFound noPermission={true} />
  }

  if (!companyPermissions) {
    return <PageNotFound noPermission={true} />
  }

  if (hasHLLInternPermission) {
    return <PageNotFound noPermission={true} />
  }

  if (companyType === 1) {
    return <PageNotFound noPermission={true} />
  }

  return <Users />
}

export default UsersPage
