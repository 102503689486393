import ImageComingSoon from "../../../../assets/images/image-coming-soon.png"

export default function Item({ acf, title }) {
  const image = acf?.image ?? null

  return (
    <div className="machine-grid__item border rounded d-flex align-items-center justify-content-center">
      <figure className="m-0">
        {image ? (
          <img src={image} alt={title?.rendered} className="img-fluid" />
        ) : (
          <img
            src={ImageComingSoon}
            alt={title?.rendered}
            className="img-fluid"
          />
        )}
      </figure>
    </div>
  )
}
