import React from "react"
import ProjectRentalList from "../../components/ProjectRentalList/ProjectRentalList.js"

const Content = ({
  data,
  loading,
  activeType,
  allChecked,
  setAllChecked,
  checkedItems,
  setCheckedItems
}) => {
  return (
    <div className="tab-content">
      <div
        className={`tab-pane fade${
          activeType === "rented" ? " show active" : ""
        }`}
        id="rented"
        role="tabpanel"
        aria-labelledby="rented-tab">
        <ProjectRentalList
          project={data?.meta}
          data={data?.rented}
          loading={loading}
          activeType={activeType}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
        />
      </div>

      <div
        className={`tab-pane fade${
          activeType === "returned" ? " show active" : ""
        }`}
        id="returned"
        role="tabpanel"
        aria-labelledby="returned-tab">
        <ProjectRentalList
          project={data?.meta}
          data={data?.returned}
          loading={loading}
          activeType={activeType}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
        />
      </div>

      <div
        className={`tab-pane fade${
          activeType === "purchase" ? " show active" : ""
        }`}
        id="purchase"
        role="tabpanel"
        aria-labelledby="purchase-tab">
        <ProjectRentalList
          project={data?.meta}
          data={data?.purchase}
          loading={loading}
          activeType={activeType}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
        />
      </div>

      <div
        className={`tab-pane fade${activeType === "all" ? " show active" : ""}`}
        id="all"
        role="tabpanel"
        aria-labelledby="all-tab">
        <ProjectRentalList
          project={data?.meta}
          data={data?.all}
          loading={loading}
          activeType={activeType}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
        />
      </div>
    </div>
  )
}

export default Content
