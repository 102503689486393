import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

const SearchResultList = ({ data }) => {
  const { t } = useTranslation()

  const { title, ancestors } = data

  const ancestorPaths =
    ancestors &&
    ancestors.length > 0 &&
    ancestors.map((ancestor) => ancestor.slug)

  const urlPaths = ancestorPaths && ancestorPaths.join("/")

  return (
    <li tabIndex="0">
      <Link
        replace={true}
        reloadDocument={true}
        to={{
          pathname: `/${t("customer")}/${t("machines")}/${urlPaths}`
        }}
        className="search-results-link">
        <p className="search-results-title">{title}</p>
        {/* {title && <p className="search-results-subtitle">{title}</p>} */}
      </Link>
    </li>
  )
}

export default SearchResultList
